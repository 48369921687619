import { appPosToLatLng, geoJsonToAppPos } from '../../utils/Map';
import React from 'react';
import { get } from 'lodash-es';
import MarkerWithLabel from './MarkerWithLabel';
import {
  IMAGE_MAX_HEIGHT,
  IMAGE_MAX_WIDTH,
  MarkerImageCommentLabel,
  PADDING,
} from './MarkerImageComment';
import './style.css';

const POINT_COUNT_PADDING = 9;

type Props = {
  cluster: any;
  onClick: (...args: Array<any>) => void;
  map: google.maps.Map;
  zIndex?: number;
};

export default function MarkerImageCommentCluster({ cluster, onClick, map, zIndex }: Props) {
  const pointCount = get(cluster, 'properties.point_count');
  const marker = get(cluster, 'properties.marker');
  const comment = marker.comment;
  const width = get(comment, 'image.dimensions.width', null);
  const height = get(comment, 'image.dimensions.height', null);
  const ratio = width / height;
  let scaledWidth = IMAGE_MAX_WIDTH;
  let scaledHeight = IMAGE_MAX_HEIGHT;
  if (ratio > IMAGE_MAX_WIDTH / IMAGE_MAX_HEIGHT) {
    scaledHeight = Math.floor(IMAGE_MAX_WIDTH / ratio);
  } else {
    scaledHeight = Math.min(height, scaledHeight);
    scaledWidth = ratio * scaledHeight;
  }
  return (
    <MarkerWithLabel
      key={cluster.key}
      position={appPosToLatLng(geoJsonToAppPos(cluster.geometry.coordinates)) as any}
      noTriangle={true}
      onClick={onClick}
      width={scaledWidth + 2 * PADDING + POINT_COUNT_PADDING}
      height={scaledHeight + 2 * PADDING + POINT_COUNT_PADDING}
      labelClass="marker-label marker-image-comment-cluster"
      map={map}
      zIndex={zIndex}
    >
      <MarkerImageCommentLabel
        comment={marker.comment}
        containerStyle={styles.markerImageCommentContainer}>
        <div className="point-count">{pointCount}</div>
      </MarkerImageCommentLabel>
    </MarkerWithLabel>
  );
}

const styles = {
  markerImageCommentContainer: {
    paddingTop: POINT_COUNT_PADDING,
    paddingRight: POINT_COUNT_PADDING,
  },
};
