import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as constants from '../../styles/style';
import FormLabel from './FormLabel';
import FormRow from './FormRow';

// TODO: improve typings
type Props = any;
type State = any;

export default class FormPositionRow extends PureComponent<Props, State> {
  static propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
  };

  render() {
    const { value, label } = this.props;

    let renderValue, renderLabel;

    if (value) {
      const latLng = value.latitude.toFixed(6) + ',' + value.longitude.toFixed(6);
      const url = `http://maps.google.com/maps?q=${latLng}`;

      renderValue = (
        <a style={{ color: constants.FJNEWGREEN }} href={url} target="_blank" rel="noreferrer">
          {latLng.split(',').join(', ')}
        </a>
      );
    }

    renderLabel = <FormLabel label={label} inputValue={value} />;

    return <FormRow value={renderValue} label={renderLabel} />;
  }
}
