import * as React from 'react';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
import ModalList from '../Modal/ModalList';
import copyToClipboard from 'clipboard-copy';

type Props = {
  field: {
    name: string;
    position: {
      latitude: number;
      longitude: number;
    };
    land_id: string;
    notes: string;
  };
};
const iconStyle = {
  width: 30,
  fontSize: 22,
  color: constants.FJNAVCOLOR,
  textAlign: 'center' as 'center',
  marginRight: 10,
};

export default class FieldInfoCoordinatesSubmenu extends React.PureComponent<Props> {
  getData() {
    const data = [
      {
        label: I18n.t('directions'),
        value: 'navigate',
        icon: (
          <Icon
            name="ios-compass-outline"
            style={{ ...iconStyle, ...{ fontSize: 30 } }}
          />
        ),
      },
      {
        label: I18n.t('copy'),
        value: 'copy',
        icon: (
          <Icon
            name="ios-copy-outline"
            style={{ ...iconStyle, ...{ fontSize: 30 } }}
          />
        ),
      },
    ];

    return data;
  }

  navigate() {
    const { field } = this.props;
    const coordinates = field.position;
    window.open(
      `http://www.google.com/maps/place/${coordinates.latitude},${coordinates.longitude}`,
      '_blank',
    );
  }

  copy() {
    const { field } = this.props;
    const coordinates = field.position;
    const msg =
      coordinates.latitude.toFixed(6) + ',' + coordinates.longitude.toFixed(6);
    copyToClipboard(msg);
  }

  render() {
    const { field } = this.props;
    const coordinates = field.position;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
        }}>
        <ModalList
          modalContainerStyle={{ flex: 1 }}
          data={this.getData() as any}
          onClick={({ value }) => {
            if (this[value]) {
              this[value]();
            }
          }}
          className={'withIcon'}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <span
              style={{
                ...constants.styles.stdSize,
                ...{ color: constants.FJNEWGREEN },
              }}>
              {coordinates.latitude.toFixed(6)},{' '}
              {coordinates.longitude.toFixed(6)}
            </span>
          </div>
        </ModalList>
      </div>
    );
  }
}
