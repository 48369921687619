import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Loading } from '../Loading/Loading';
import I18n from '../../language/i18n';
import { map } from 'lodash-es';
import copyToClipboard from 'clipboard-copy';
import * as companyActions from 'farmerjoe-common/lib/actions/company';
import { updateKey } from '../../actions/apiKeys';
import { firestoreConnect } from 'react-redux-firebase';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import * as companySelectors from 'farmerjoe-common/lib/selectors/companies';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import type { ApiKey, Company, Profile } from '../../flowTypes';
import NavbarBasic from '../Common/NavbarBasic';
import './style.css';
import Icon from '../Common/Icon';
import Form from './Form';
import * as constants from '../../styles/style';
import { getApiKeys } from 'farmerjoe-common/lib/selectors/apiKeys';
import { EditButton } from '../Common/EditButton';
import RSwitch from '../Common/RSwitch/RSwitch';
import { outputDate } from 'farmerjoe-common';

type Props = {
  apiKeys?: Array<Record<string, any>>;
  loadingApiKeys?: boolean;
  company?: Company;
  profile?: Profile;
  actions?: Record<string, any>;
};

type State = {
  showCreateForm: boolean;
  editKey?: ApiKey | null;
};

class ApiKeys extends React.Component<Props, State> {
  state: State = {
    showCreateForm: false,
  };

  render() {
    const { apiKeys, loadingApiKeys } = this.props;

    let content: any = null;
    if (loadingApiKeys) {
      content = (
        <div>
          <Loading />
        </div>
      );
    } else {
      content = [
        <table className="table table-striped" key="table">
          <thead>
            <tr>
              <th>{I18n.t('apiKeys.name')}</th>
              <th>{I18n.t('date')}</th>
              <th>{I18n.t('apiKeys.user')}</th>
              <th>{I18n.t('apiKeys.key')}</th>
              <th>{I18n.t('apiKeys.enabled')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {map(apiKeys, (keyObject, i) => {
              const { name, created_on, key, disabled, created_by } = keyObject;
              return (
                <tr key={i}>
                  <td>{name}</td>
                  <td>{outputDate(created_on)}</td>
                  <td>{created_by ? created_by.name : null}</td>
                  <td>
                    <div className="api-key">
                      <input
                        className="form-control"
                        type="text"
                        value={key}
                        onChange={e => e}
                        onClick={(e: any) => {
                          e.target.select();
                          copyToClipboard(key);
                        }}
                      />
                    </div>
                  </td>
                  <td className="enable-switch-column">
                    <RSwitch
                      name={'activate-' + key}
                      onChange={e =>
                        this.props.actions?.updateKey({
                          ...keyObject,
                          disabled: !disabled,
                        })
                      }
                      value={!disabled}
                    />
                  </td>
                  <td className="edit-button-column">
                    <div className="edit-button">
                      <EditButton
                        onClick={() =>
                          this.setState({
                            showCreateForm: true,
                            editKey: keyObject,
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
            {!apiKeys?.length
              ? (
              <tr className="no-keys">
                <td colSpan={6}>{I18n.t('apiKeys.noKeys')}</td>
              </tr>
                )
              : null}
          </tbody>
        </table>,
      ];
    }

    return (
      <div className="api-keys">
        <NavbarBasic
          title={I18n.t('apiKeys.header')}
          rightText={<Icon name="plus" iconType={'fj'} />}
          onRight={() => {
            this.setState({ showCreateForm: true, editKey: null });
          }}
        />

        <div className="wrapper">
          <p>{I18n.t('apiKeys.description')}</p>
          {content}
        </div>

        {this.state.showCreateForm
          ? (
          <Form
            show={this.state.showCreateForm}
            onClose={() =>
              this.setState({ showCreateForm: false, editKey: null })
            }
            apiKey={this.state.editKey as any}
          />
            )
          : null}
      </div>
    );
  }
}

const getApiQuery = companyId => {
  return {
    collection: 'apiKeys',
    where: [['company_id', '==', companyId]],
  };
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = companySelectors.getCompany(state.firestore.data, openCompany);
  return {
    profile: state.firebase.profile,
    company: company,
    apiKeys: getApiKeys(state, openCompany),
    loadingApiKeys: !hasLoaded([getApiQuery(openCompany) as any], state),
  };
};

export default compose<typeof ApiKeys>(
  connect(
    selector,
    dispatch => ({
      actions: bindActionCreators(
        {
          ...companyActions,
          updateKey,
        },
        dispatch,
      ),
    }),
  ),
  firestoreConnect(props => {
    return [getApiQuery(props.company.key)];
  }),
)(ApiKeys);
