import {
  getGroupsQuery,
  getExternalGroupQuery,
} from 'farmerjoe-common/lib/utils/firestoreRedux/Groups';
import { getCompanyQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Companies';

export const getGroupsQueries = (
  companyId: string,
  companyProfile,
  groupType: string,
  externalGroupIds: string[],
  storeAs?: string,
) => {
  const paths: any = [];
  paths.push(
    ...[
      getCompanyQuery(companyId),
      {
        ...getGroupsQuery(companyId, companyProfile, groupType as any),
        storeAs,
      },
    ],
  );

  if (externalGroupIds.length) {
    externalGroupIds.forEach((groupId) => {
      paths.push(getExternalGroupQuery(groupId));
    });
  }

  return paths;
};
