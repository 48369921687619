import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { browserLanguage, changeLocale } from './i18n';
import { isLocaleLoaded } from '@farmerjoeorg/farmerjoe-language';
import { get } from 'lodash-es';

class LocaleLoader extends React.Component<
  {
    children?: React.ReactNode;
    locale: string;
  },
  {
    loading: boolean;
  }> {
  constructor(props) {
    super(props);
    const locale = props.locale;
    this.state = {
      loading: !isLocaleLoaded(locale),
    };
    changeLocale(locale).then(
      () => {
        this.setState({ loading: false });
      },
      e => {
        console.error(`error while loading locale ${locale}`, e);
        this.setState({ loading: false });
      },
    );
  }

  componentWillUpdate(nextProps) {
    if (this.props.locale !== nextProps.locale) {
      const locale = nextProps.locale;
      this.setState({ loading: true });
      changeLocale(locale).then(
        () => {
          this.setState({ loading: false });
        },
        e => {
          console.error(`error while loading locale ${locale}`, e);
          this.setState({ loading: false });
        },
      );
    }
  }

  render() {
    const { children } = this.props;
    return this.state.loading ? null : children;
  }
}

export default compose(
  connect(state => ({
    locale: get(state, 'firebase.profile.language') || browserLanguage(),
  })),
)(LocaleLoader);
