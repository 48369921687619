import { Marker } from '@react-google-maps/api';
import * as React from 'react';
import imgSpotlightGreen from '../../public/images/spotlight-green.png';

export default function(props) {
  return (
    <Marker
      icon={
        new (window as any).google.maps.MarkerImage(
          imgSpotlightGreen,
          new (window as any).google.maps.Size(27, 43),
          new (window as any).google.maps.Point(0, 0),
          new (window as any).google.maps.Point(14, 43),
        )
      }
      {...props}
    />
  );
}
