import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import * as companySelectors from "farmerjoe-common/lib/selectors/companies";
import {
  getFieldsQueries,
  getAbandonedFieldsQuery,
} from "farmerjoe-common/lib/utils/firestoreRedux/Fields";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";

import withRouter from "../components/Router/withRouter";
import WithFieldCollaborators from "./HOC/WithFieldCollaborators";
import WithSharedFields from "./HOC/WithSharedFields";
import WithFieldViews from "./HOC/WithFieldViews";
import { FieldState } from "farmerjoe-common/lib/flow/types";

const selector = (state, ownProps) => {
  const { companyId, fieldState } = ownProps;
  const openCompany = companyId || selectors.getOpenCompanyId(state);
  const company = companySelectors.getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const queries = ownProps.fieldState === FieldState.Active ? [
    ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Active),
    ...getAbandonedFieldsQuery(openCompany),
  ] : [
    ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Archived),
  ];

  return {
    openCompany,
    company,
    myCompanyProfile,
    loading: !hasLoaded(queries, state),
    browsingGroup,
    fieldState,
  };
};

export default compose<React.ComponentClass<any>>(
  WithFieldViews,
  WithFieldCollaborators,
  WithSharedFields,
  connect(selector),
  firestoreConnect(props => {
    const { myCompanyProfile, browsingGroup, openCompany, fieldState } = props;
    if (openCompany && fieldState) {
      return buildFieldsQueries(openCompany, myCompanyProfile, browsingGroup, fieldState);
    }
    return [];
  }),
  withRouter,
);

const buildFieldsQueries = (
  companyId: string,
  myCompanyProfile,
  browsingGroup: string,
  fieldState: FieldState,
) => {
  if (!companyId) {
    return [];
  }

  switch(fieldState) {
  case FieldState.Active:
    return [
      ...getFieldsQueries(companyId, myCompanyProfile, browsingGroup, FieldState.Active),
      ...getAbandonedFieldsQuery(companyId),
    ];
  case FieldState.Archived:
    return [
      ...getFieldsQueries(companyId, myCompanyProfile, browsingGroup, FieldState.Archived),
    ];
  default:
    return [];
  }
};
