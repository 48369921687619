import * as React from 'react';
import { connect } from 'react-redux';
import { ActivationCheckLine } from '../../components/Activation/ActivationCheckLine';
import { isProfileCompleted, isEmailCredentials } from '../../utils/auth';

interface Props {
  auth: any; // FirebaseAuth
  profile: any; // Profile
  signup: any;
  credentialsProvider: string;
};

const ActivationCheck = (props: Props) => {
  const { auth: { email: authEmail, emailVerified }, profile: { email: profileEmail }, credentialsProvider, signup } = props;
  const email = isEmailCredentials(credentialsProvider) ? authEmail : profileEmail;
  if (isProfileCompleted(signup) && email && !emailVerified) {
    return <ActivationCheckLine />;
  }
  return null;
};

const selector = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    credentialsProvider: state.credentialsProvider.provider,
    signup: state.signup,
  };
};

export default connect(
  selector,
  () => ({}),
)(ActivationCheck as any);
