import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Modal from 'react-bootstrap-modal';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getSortedFields } from 'farmerjoe-common/lib/selectors/fields';
import * as analysisActions from 'farmerjoe-common/lib/actions/analysis';
import { getFieldsQueries } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { getCompanyQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Companies';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { FieldState } from 'farmerjoe-common/lib/flow/types';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';

import FieldsList from '../Fields/CropFieldsList';
import I18n from '../../language/i18n';

// TODO: improve typings
type Props = any;
type State = any;

class FormFieldSelect extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object,
    openView: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  };

  render() {
    const { sortedFields, filter, openView } = this.props;

    return [
      <Modal.Body onClick={e => e.stopPropagation()} key={0}>
        <FieldsList
          fields={sortedFields}
          filter={filter}
          onClick={key => {
            this.props.actions.analysisField(key);
            openView('CreateAnalysisForm');
          }}
        />
      </Modal.Body>,
      <Modal.Footer onClick={e => e.stopPropagation()} key={1}>
        <div className="d-flex flex-grow-1">
          <button
            className="btn btn-secondary"
            onClick={this.goBack.bind(this)}>
            {I18n.t('back')}
          </button>
        </div>
      </Modal.Footer>,
    ];
  }

  goBack() {
    this.props.actions.analysisField(null);
    this.props.goBack();
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  // @ts-ignore
  const sortedFields = get(getSortedFields(state, openCompany), 'sections', []);

  const loading = !hasLoaded(
    [
      ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Active),
      getCompanyQuery(openCompany),
    ],
    state,
  );

  return {
    openCompany,
    sortedFields,
    filter: state.filtersByCompany[openCompany],
    loading,
    browsingGroup,
    myCompanyProfile,
  };
};

const wrappedFormFieldSelect = firestoreConnect(props => {
  const companyId = props.openCompany;
  const { browsingGroup, myCompanyProfile } = props;

  return [
    ...getFieldsQueries(companyId, myCompanyProfile, browsingGroup, FieldState.Active),
    getCompanyQuery(companyId),
  ];
})(FormFieldSelect);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFormFieldSelect);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...analysisActions,
        },
      ),
      dispatch,
    ),
  };
}
