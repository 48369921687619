import React, { PureComponent } from "react";
import { bindActionCreators, compose } from "redux";
import { get } from "lodash-es";
import { connect } from "react-redux";

import { showMapFields, updateUi } from "farmerjoe-common/lib/actions/ui";
import { isAdmin } from "farmerjoe-common";
import { getUi } from "farmerjoe-common/lib/selectors/drawUi";
import { getFeature } from "farmerjoe-common/lib/selectors/features";
import {
  getOpenCompanyId,
  getOpenFieldId,
} from "farmerjoe-common/lib/selectors/selectors";

import MapSearch from "./MapSearch";
import MapControl, {
  ControlButton,
  ControlContainer,
  LEFT_TOP,
  RIGHT_BOTTOM,
  TOP_CENTER,
  TOP_LEFT,
} from "./MapControl";
import MapFilter from "./MapFilter";
import MapMarkerTitles from "./MapMarkerTitles";
import MapCenterOnUserPosition from "./MapCenterOnUserPosition";
import MapCenterOnMarkers from "./MapCenterOnMarkers";
import CropAgeFilter from "./CropAgeFilter";
import Icon from "../Common/Icon";
import { isValidPosition } from "../../utils/Map";
import I18n from "../../language/i18n";
import type { LatLng, Marker, Profile } from "../../flowTypes";
import { classes } from "../../utils/dom";

import "./style.css";

type Props = {
  polygon: Array<LatLng> | null | undefined;
  fieldPosition: LatLng | null | undefined;
  onShowCreateForm: () => void;
  activeTool: string | null;
  showMapFields?: boolean;
  markers: Array<Marker>;
  actions?: Record<string, any>;
  myCompanyProfile: Profile;
  ui?: Record<string, any>;
  hasBonitur?: boolean;
  openField?: any;
  filter?: any;
  mapRef: google.maps.Map;
  onTrafficLayerPress: () => void;
  showTrafficLayer: boolean;
};

type State = {
  showFilters: boolean;
};

class FieldsMapControls extends PureComponent<Props, State> {
  state = {
    showFilters: false,
  };

  mapClickListener: (() => void) | null | undefined;

  componentDidMount() {
    if (
      this.props.filter && (
        this.props.filter.search ||
      this.props.filter.cropAgeMin ||
      this.props.filter.cropAgeMax
      )
    ) {
      this.setState({ showFilters: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filter && this.props.filter && (
        prevProps.filter.search !== this.props.filter.search ||
        prevProps.filter.cropAgeMin !== this.props.filter.cropAgeMin ||
        prevProps.filter.cropAgeMax !== this.props.filter.cropAgeMax
      )
    ) {
      this.setState({ showFilters: true });
    }
  }

  componentWillUnmount() {
    if (this.mapClickListener) {
      (window as any).google.maps.event.removeListener(this.mapClickListener);
    }
  }

  render() {
    const {
      onShowCreateForm,
      activeTool,
      fieldPosition,
      polygon,
      showMapFields,
      markers,
      myCompanyProfile,
      ui,
      hasBonitur,
      openField,
      filter,
      mapRef,
    } = this.props;

    const showFilters = this.state.showFilters;

    return (
      <React.Fragment>
        <MapControl
          position={TOP_LEFT}
          mergeControls={false}
          className="fields-map-controls-toolbar-container"
          map={mapRef}
          style={{ zIndex: 2 }}>
          <ControlContainer className="fields-map-controls-toolbar map-toolbar-container map-toolbar-horizontal">
            <ControlButton
              className={classes(
                "control-button-search",
                showMapFields && "active",
              )}
              onClick={() => this.props.actions?.showMapFields(!showMapFields)}>
              <Icon iconType={"fj"} name={"fields"} />
              <span className="control-button-subtitle">
                {I18n.t("field.fields")}
              </span>
            </ControlButton>
            <MapSearch />
            <ControlButton
              className={classes(
                "control-button-search",
                showFilters && "active",
              )}
              onClick={() => this.setState({ showFilters: !showFilters })}>
              <Icon iconType={"fa"} name={"filter"} />
              <span className="control-button-subtitle">
                {I18n.t("filter")}
              </span>
            </ControlButton>
          </ControlContainer>
        </MapControl>
        <MapControl
          position={TOP_CENTER}
          style={{ display: "flex", left: 0, right: 0, zIndex: 1 }}
          map={mapRef}
          mergeControls={false}>
          <ControlContainer
            className={classes(
              "fields-map-filter-container map-toolbar-container",
              showFilters && "show",
            )}>
            <MapFilter />
            <MapMarkerTitles />
            {get(filter, "markerTitle") === "cropAge"
              ? (
                <CropAgeFilter />
              )
              : null}
          </ControlContainer>
        </MapControl>
        <MapControl
          position={LEFT_TOP}
          map={mapRef}>
          {activeTool === "draw"
            ? (
              <ControlContainer className="map-toolbar-container fields-map-toolbar">
                {fieldPosition &&
              isValidPosition(fieldPosition) &&
              (!polygon || polygon.length >= 3) &&
              activeTool === "draw" &&
              isAdmin(myCompanyProfile as any)
                  ? (
                    <ControlButton
                      className="create-field-button"
                      onClick={onShowCreateForm}>
                      <Icon iconType={"fj"} name={"plus"} />{" "}
                      {I18n.t("field.createNow")}
                    </ControlButton>
                  )
                  : null}
              </ControlContainer>
            )
            : null}
        </MapControl>
        <MapControl
          position={RIGHT_BOTTOM}
          map={mapRef}
          mergeControls={false}>
          <ControlContainer className="map-toolbar">
            <MapCenterOnUserPosition map={mapRef} />
            <MapCenterOnMarkers map={mapRef} markers={markers} />
            <ControlButton
              title={I18n.t("traffic")}
              className={classes(
                "map-toolbar-container vertical",
                this.props.showTrafficLayer && "active",
              )}
              onClick={() => this.props.onTrafficLayerPress()}>
              <Icon iconType={"fa"} name={"cars"} />
            </ControlButton>
          </ControlContainer>
        </MapControl>
        {openField
          ? (
            <MapControl
              position={RIGHT_BOTTOM}
              map={mapRef}
              mergeControls={false}>
              <ControlContainer className="map-toolbar-container vertical">
                <ControlButton
                  className={classes(ui?.markerCommentsVisible && "active")}
                  onClick={() =>
                    this.props.actions?.updateUi("fieldsMap", {
                      markerCommentsVisible: !ui?.markerCommentsVisible,
                    })
                  }>
                  <Icon name={"image"} iconType={"fal"} />
                </ControlButton>
                {hasBonitur
                  ? (
                    <ControlButton
                      className={classes(ui?.bonitursVisible && "active")}
                      onClick={() =>
                        this.props.actions?.updateUi("fieldsMap", {
                          bonitursVisible: !ui?.bonitursVisible,
                        })
                      }>
                      <Icon name={"analytics"} iconType={"fal"} />
                    </ControlButton>
                  )
                  : null}
              </ControlContainer>
            </MapControl>
          )
          : null}
      </React.Fragment>
    );
  }
}

export default compose<typeof FieldsMapControls>(
  connect(
    (state: any) => ({
      showMapFields: state.showMapFields,
      ui: getUi(state, "fieldsMap"),
      hasBonitur: getFeature(state, getOpenCompanyId(state), "bonitur"),
      openField: getOpenFieldId(state),
      filter: state.filtersByCompany[getOpenCompanyId(state)],
    }),
    function mapDispatchToProps(dispatch) {
      return {
        actions: bindActionCreators(
          {
            showMapFields,
            updateUi,
          },
          dispatch,
        ),
      };
    },
  ),
)(FieldsMapControls);
