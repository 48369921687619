import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getGroupQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Groups";
import { getGroup } from "farmerjoe-common/lib/selectors/groups";
import { editGroup, openGroup } from "farmerjoe-common/lib/actions/group";
import { isNotMainGroup } from "farmerjoe-common/lib/utils/firestoreRedux/Utils";
import { GroupType } from "farmerjoe-common/lib/flow/types";

import GroupComponent from "../../components/Group/GroupComponent";
import withRouter, { FjRouteChildrenProps } from "../../components/Router/withRouter";
import { Group, TableListViewState } from "../../flowTypes";
import { getGroupsView } from "../../actions/groupsTable";

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  loading: boolean;
  group: Group;
  openCompany: string;
  viewType: TableListViewState;
  editable: boolean;
};

/**
 * Typings for reducers from the redux store
 */
type DispatchProps = {
  actions: {
    editGroup: typeof editGroup;
    openGroup: typeof openGroup;
  };
};

type OwnProps = {
  groupId: string;
  type: GroupType;
};

type Props = StateProps & DispatchProps & OwnProps & FjRouteChildrenProps;

class SingleGroup extends React.PureComponent<Props> {
  componentWillUnmount() {
    this.props.actions.openGroup(null as any);
  }

  componentDidUpdate(prevProps) {}

  render() {
    const { loading, group, viewType } = this.props;

    if (!group) {
      return null;
    }

    return (
      <GroupComponent
        loading={loading}
        group={group}
        editGroup={this.props.actions.editGroup}
        onClose={this.closeGroup.bind(this)}
        isFloating={viewType === TableListViewState.Table}
        editable={this.props.editable}
        openCompany={this.props.openCompany}
      />
    );
  }

  private closeGroup() {
    const { openCompany, type } = this.props;
    this.props.actions.openGroup(null as any);
    this.props.history.push(`/company/${openCompany}/${type}`);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editGroup,
          openGroup,
        },
      ),
      dispatch,
    ),
  };
}

function selector(state, ownProps: OwnProps): StateProps {
  const { groupId, type } = ownProps;
  const openCompany = selectors.getOpenCompanyId(state);
  const group = getGroup(state, openCompany, groupId);
  const hasLoadedEverything = hasLoaded([`groups/${groupId}`], state);
  const viewType = getGroupsView(state, type, openCompany);

  return {
    loading: !hasLoadedEverything,
    group: group,
    openCompany,
    viewType,
    editable: group ? openCompany === group.company_id : true,
  };
};

const wrappedSingleGroup = firestoreConnect(props => {
  if (isNotMainGroup(props.groupId)) {
    return [
      getGroupQuery(props.groupId),
    ];
  }
  return [];
})(SingleGroup);

export default compose<React.ComponentClass<OwnProps>>(
  connect<StateProps, DispatchProps, OwnProps>(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedSingleGroup);
