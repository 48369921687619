import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './style.css';

import { classes } from '../../utils/dom';

// TODO: improve typings
type Props = any;
type State = any;

export default class FormRow extends PureComponent<Props, State> {
  static propTypes = {
    value: PropTypes.object,
    label: PropTypes.object,
    className: PropTypes.string,
  };

  render() {
    const { value, label, className } = this.props;
    return (
      <div className={classes('form-row', className)}>
        {label}
        {value}
      </div>
    );
  }
}
