import React from "react";
import { AutoSizer, MultiGrid } from "react-virtualized/dist/commonjs/index";
import Draggable from "react-draggable";

import { Loading } from "../../Loading/Loading";
import { CellRenderer } from "../../Table/flow";
import TableExport from "../../Table/TableExport";
import { classes } from "../../../utils/dom";

import "../../Table/style.css";
import "./style.css";

export type GroupsTableColumnDefinition = {
  name: string;
  headerLabel: string;
  style?: any;
};

type TableProps = {
  loading: boolean;
  auth?: any;
  emptyView: JSX.Element;
  onRowClick: (rowId: string) => void;
  exportFileName: string;
  columns: GroupsTableColumnDefinition[];

  /**
   * Matched by the "id" field.
   */
  selectedRowId: string;
  tableRowData: Record<string, any>[];
};

type TableState = {
  sortColumn: {
    index: number;
    desc: boolean;
  };
};

export default class Table extends React.PureComponent<TableProps, TableState> {
  private tableData: Record<string, any>[] = [];
  private tableExport = React.createRef<TableExport>();
  private lastGridDimensions: any;
  private gridRef = React.createRef<MultiGrid>();
  private cellWidth: number[] = [];
  private readonly columnNames: string[];
  private readonly columnHeaders: string[];
  private readonly columnCount: number;

  state = {} as TableState;

  constructor(props: TableProps) {
    super(props);

    const { columns } = this.props;
    this.columnNames = columns.map(column => column.name);
    this.columnHeaders = columns.map(column => column.headerLabel);
    this.columnCount = columns.length;
  }

  initializeCellWidth(width: number, columnsNumber: number) {
    for (let i = 0; i < columnsNumber; i++) {
      this.cellWidth.push(Math.max(150, width / columnsNumber));
    }
  }

  render() {
    const { loading, emptyView, exportFileName, tableRowData, selectedRowId } = this.props;
    const { columnNames, columnCount } = this;

    if (loading) {
      return <Loading />;
    }

    this.tableData = applySorting(tableRowData, this.state?.sortColumn, columnNames);
    if (this.tableData.length === 0) {
      return emptyView;
    }
    const propsForUpdating = {
      selectedRowId,
    };

    const rowCount = this.tableData.length + 1; // rows + header

    return (
      <div className="table-container">
        <AutoSizer
          key="autosizer"
          onResize={this.recomputeGridSizeIfDimensionsAreUpdated.bind(this)}
        >
          {({ width, height }) => {
            if (this.cellWidth.length === 0) {
              this.initializeCellWidth(width, columnCount);
            }
            return (<MultiGrid
              ref={this.gridRef}
              cellRenderer={this.renderCell.bind(this)}
              columnCount={columnCount}
              columnWidth={({ index }) => this.cellWidth[index]}
              rowCount={rowCount}
              rowHeight={38}
              height={height}
              width={width}
              fixedRowCount={1}
              // for updating the table when some prop changes
              {...propsForUpdating}
            />);
          }}
        </AutoSizer>
        <TableExport
          ref={this.tableExport}
          cellRenderer={this.renderCell.bind(this)}
          fileName={exportFileName}
        />
      </div>
    );
  }

  exportData() {
    if (this.tableExport.current && this.tableData) {
      const rowCount = this.tableData.length + 1;
      this.tableExport.current.exportData(
        this.columnCount,
        rowCount,
      );
    }
  }

  private renderCell(options: CellRenderer) {
    if (options.rowIndex === 0) {
      return this.renderHeaderCell(options);
    } else {
      return this.renderColumnCell(options);
    }
  }

  private resizeRow = ({ key, deltaX }) => {
    this.cellWidth[key] += deltaX;
    this.gridRef.current?.recomputeGridSize();
  };

  private renderHeaderCell(options: CellRenderer) {
    const { key, columnIndex, style } = options;
    const { sortColumn } = this.state;
    const cellClasses = ["header-cell"];
    if (columnIndex === 0) {
      cellClasses.push("cell-column-0");
    }
    return (
      <React.Fragment key={key}>
        <div
          className={classes(...cellClasses)}
          style={style}
          key={key}
          onClick={() => this.onHeaderClick(columnIndex)}
        >
          {this.getHeaderText(options.columnIndex)}
          {sortColumn && sortColumn.index === columnIndex ? (
            <span className={`sorting ${this.state.sortColumn.desc ? "sorting-desc" : "sorting-asc"}`}
            />) : null}
          <Draggable
            axis="x"
            defaultClassName="DragHandle"
            defaultClassNameDragging="DragHandleActive"
            onDrag={(event, { deltaX }) =>
              this.resizeRow({
                key: columnIndex,
                deltaX,
              })
            }
            position={{ x: 0, y: 0 }}
          >
            <span className="DragHandleIcon drag-handle"></span>
          </Draggable>
        </div>
      </React.Fragment>
    );
  }

  private renderColumnCell(options: CellRenderer) {
    const { key, columnIndex, style } = options;

    // adding one as the very first row is column headers
    const rowIndex = options.rowIndex - 1;

    const isRowSelected = this.tableData[rowIndex].id === this.props.selectedRowId;
    const customColumnStyle = this.props.columns[columnIndex].style || {};

    const cellContent = this.getCellContent(this.tableData[rowIndex], columnIndex);
    const cellClasses = ["cell"];
    if (columnIndex === 0) {
      cellClasses.push("cell-column-0");
    }
    if (isRowSelected) cellClasses.push("selected");

    return (
      <div
        className={classes(...cellClasses)}
        style={{
          ...style,
          ...customColumnStyle,
        }}
        key={key}
        onClick={() => this.onCellClick(options.rowIndex)}
      >
        {cellContent}
      </div>
    );
  }

  private onCellClick(rowIndex: number) {
    const { onRowClick } = this.props;
    if (!onRowClick || !this.tableData) {
      return;
    }
    if (rowIndex === 0) {
      // header row is clicked
      return;
    }

    const rowIdxExcludingHeader = rowIndex - 1;
    const rowId = this.tableData[rowIdxExcludingHeader].id;
    onRowClick(rowId);
  }

  private onHeaderClick = (sortColumnIndex) => {
    this.setState({ sortColumn: { index: sortColumnIndex, desc: !this.state?.sortColumn?.desc && sortColumnIndex === this.state?.sortColumn?.index } });
    this.gridRef.current?.recomputeGridSize();
  };

  private getHeaderText(columnIndex: number) {
    const { columnHeaders } = this;
    if (columnIndex >= 0 && columnIndex < columnHeaders.length) {
      return columnHeaders[columnIndex];
    }
    throw new Error("Unexpected column index: " + columnIndex);
  }

  private getCellContent(row: Record<string, any>, columnIndex: number) {
    const { columnNames } = this;
    if (columnIndex >= 0 && columnIndex < columnNames.length) {
      return row[columnNames[columnIndex]];
    }
    throw new Error("Unexpected column index: " + columnIndex);
  }

  private recomputeGridSizeIfDimensionsAreUpdated(dimensions) {
    if (this.lastGridDimensions !== dimensions) {
      this.lastGridDimensions = dimensions;
      this.gridRef.current?.recomputeGridSize();
    }
  }
}

function applySorting(tableRowData: Record<string, any>[], sortColumn, columnNames: string[]): Record<string, any>[] {
  const results = [...tableRowData];
  if (sortColumn) {
    results.sort((a, b) => {
      const fa = changeToLowerCaseIfString(a[columnNames[sortColumn.index]]);
      const fb = changeToLowerCaseIfString(b[columnNames[sortColumn.index]]);

      if (fa < fb) {
        return sortColumn.desc ? 1 : -1;
      }
      if (fa > fb) {
        return sortColumn.desc ? -1 : 1;
      }
      return 0;
    });
  }
  return results;
}

function changeToLowerCaseIfString(input: any) {
  if (typeof input === "string") {
    return input.toLowerCase();
  }
  return input;
}
