import moment from 'moment/moment';
import * as constants from '../../styles/style';
import I18n from '../../language/i18n';
import FormInput from '../templates/FormInput';
import { outputDate } from 'farmerjoe-common';

export function defaultOptions(label, required = false) {
  return {
    label: label + (required ? ' *' : ''),
    autoCorrect: false,
    factory: FormInput,
    placeholderTextColor: constants.FJLABEL,
  };
}

/**
 * Default formating for tcomb date field
 * @param date
 * @returns {string}
 */
export function defaultDateFormat(date) {
  const inDays = moment()
    .startOf('days')
    .diff(moment(date).startOf('day'), 'days');

  if (inDays === 0) {
    return I18n.t('moments.today');
  }

  if (inDays === 1) {
    return I18n.t('moments.days_ago', { count: inDays });
  }
  if (inDays > 0) {
    return `${outputDate(date)} (${I18n.t('moments.days_ago', {
      count: inDays,
    })})`;
  }

  if (inDays < 0) {
    return `${outputDate(date)} (${I18n.t('moments.in_days', {
      count: Math.abs(inDays),
    })})`;
  }
}
