import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { get, keys } from "lodash-es";

import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import * as companySelectors from "farmerjoe-common/lib/selectors/companies";
import { getColor } from "farmerjoe-common/lib/utils/Colors";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getFieldsQueries } from "farmerjoe-common/lib/utils/firestoreRedux/Fields";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { searchForFieldAmongTheUniverse, getMarkers } from "farmerjoe-common/lib/selectors/fields";
import { showMapFields } from "farmerjoe-common/lib/actions/ui";
import { getUi } from "farmerjoe-common/lib/selectors/drawUi";
import { commentsToMarkers } from "farmerjoe-common/lib/utils/Comment";
import { getComments } from "farmerjoe-common/lib/selectors/comments";
import { formsToMarkers } from "farmerjoe-common/lib/utils/Bonitur";
import { getBoniturisImageKey, getFormSubmissionsForField } from "farmerjoe-common/lib/selectors/forms";
import { getFormSubmissionsForFieldQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Forms";
import type { CommentMarker } from "farmerjoe-common/lib/flow/types";
import { FieldState } from "farmerjoe-common/lib/flow/types";
import { TrafficLayer } from "@react-google-maps/api";


import Polygon from "./Polygon";
import FieldsMapControls from "./FieldsMapControls";
import MarkerComments from "./MarkerComments";
import MarkerBonitur from "./MarkerBonitur";
import ModalMap from "./ModalMap";
import PolygonAndMarkerPlacement from "./PolygonAndMarkerPlacement";
import { Loading } from "../Loading/Loading";
import CreateField from "../Field/CreateField";
import BoniturDialog from "../Bonitur/BoniturDialog";
import withRouter from "../Router/withRouter";

import * as mapActions from "../../actions/map";
import type { Company, Field, LatLng, Marker, Profile } from "../../flowTypes";
import {
  mapPosToAppPos,
  getPolygonOptions,
  preparePolygonPath, 
  memoizedMarkers,
} from "../../utils/Map";
import { getFieldPath } from "../../utils/page";
import Comments from "../../containers/Comments";


type Props = {
  company?: Company;
  fields?: Array<Field>;
  actions?: Record<string, any>;
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  loading?: boolean;
  showMapFields?: boolean;
  markers?: Array<Marker>;
  history?: Record<string, any>;
  myCompanyProfile?: Profile;
  ui?: Record<string, any>;
  commentMarkers?: CommentMarker[];
  formMarkers?: any[];
  forceRerenderProp?: any;
};

type State = {
  polygon?: Array<LatLng> | null;
  fieldPosition?: LatLng | null;
  areaSize?: Record<string, any> | null;
  hideMarkers: boolean;
  showCreateForm: boolean;
  polygonComponentKey: number;
  activeTool: string | null;
  hideDistanceMarkers: boolean;
  mapRef?: google.maps.Map;
  showModal: boolean;
  selectedSchemaId: string;
  selectedCommentId: string;
  showTrafficLayer: boolean;
};
const containerStyle = { flex: 1, height: "auto" };

class FieldsMap extends React.PureComponent<Props, State> {
  state: State = {
    polygon: null,
    fieldPosition: null,
    areaSize: null,
    hideMarkers: false,
    showCreateForm: false,
    polygonComponentKey: 0,
    activeTool: "pan",
    hideDistanceMarkers: true,
    showModal: false,
    selectedSchemaId: "",
    selectedCommentId: "",
    showTrafficLayer: false,
  };

  render() {
    const {
      center,
      zoom,
      loading,
      markers,
      company,
      myCompanyProfile,
      ui,
      commentMarkers,
      formMarkers,
    } = this.props;

    if (!company) {
      return null;
    }

    const mapMarkers = memoizedMarkers(markers, company);

    return loading ? (
      <Loading />
    ) : (
      <ModalMap
        position={center ? mapPosToAppPos(center) : this.props.company?.position}
        zoom={zoom as number}
        markers={mapMarkers}
        containerStyle={containerStyle}
        fitBounds={!center}
        /* disabled this callback as it is causing the dragging to be interrupted unexpectedly */
        // onUpdateView={this.onUpdateView}
        hideMarkers={this.state.hideMarkers}
        hideDistanceMarkers={this.state.hideDistanceMarkers}
        isMapPage={true}
        onMarkerPress={this.onMarkerPress}
        onMapRef={this.onMapRef}
        hideControlOverlay={true}
      >
        {this.renderMapDependentChildrenIfMapReady()}
        {renderCropPolygons(this.props.markers, this.state.mapRef)}
        {this.state.showTrafficLayer && <TrafficLayer options={{ autoRefresh: true }} />}
      </ModalMap>
    );
  }

  private onTrafficLayerPress = () => {
    this.setState({ showTrafficLayer: !this.state.showTrafficLayer });
  };

  private renderMapDependentChildrenIfMapReady() {
    const {
      markers,
      company,
      myCompanyProfile,
      ui,
      commentMarkers,
      formMarkers,
    } = this.props;

    const mapRef = this.state.mapRef;
    const { showModal, selectedSchemaId, selectedCommentId } = this.state;

    if (!company || !mapRef) {
      return null;
    }

    return (
      <>
        <PolygonAndMarkerPlacement
          map={mapRef}
          polygonDrawing={true}
          polygon={this.state.polygon}
          onChange={this.onPolygonChange}
          center={this.state.fieldPosition}
          defaultActiveTool="pan"
          hideMarkers={this.state.hideMarkers}
          onHideMarkers={this.onHideMarkers}
          key={this.state.polygonComponentKey}
          onChangeTool={this.onChangeTool}
          hideDistanceMarkers={this.state.hideDistanceMarkers}
          onHideDistanceMarkers={this.onHideDistanceMarkers}
        />
        <FieldsMapControls
          mapRef={mapRef}
          activeTool={this.state.activeTool}
          fieldPosition={this.state.fieldPosition}
          polygon={this.state.polygon}
          onShowCreateForm={this.onShowCreateForm}
          markers={markers as any}
          myCompanyProfile={myCompanyProfile as any}
          onTrafficLayerPress={this.onTrafficLayerPress}
          showTrafficLayer={this.state.showTrafficLayer}
        />
        {ui?.markerCommentsVisible
          ? (
            <MarkerComments map={mapRef} markers={commentMarkers as any} />
          )
          : null}
        {ui?.bonitursVisible
          ? formMarkers?.map(item => {
            return <MarkerBonitur
              map={mapRef as google.maps.Map}
              key={item.key}
              marker={{ type: item.type, key: item.key, position: item.position, title: item.form.meta.group_name }}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  showModal: true,
                  selectedSchemaId: item.form.schema_id,
                  selectedCommentId: item.form.key,
                });
              }}
            />;
          })
          : null}
        {(showModal)
          ? (
            <BoniturDialog
              formId={selectedCommentId}
              formSchemaId={selectedSchemaId}
              onClose={() => this.setState({ showModal: false })}
            />)
          : null}
        {this.state.showCreateForm ? (
          <CreateField
            show={this.state.showCreateForm}
            onClose={reason => {
              if (reason === "saved") {
                return this.setState({
                  showCreateForm: false,
                  polygonComponentKey: this.state.polygonComponentKey + 1, // force the polygon component to reset it's state
                  polygon: null,
                  fieldPosition: null,
                });
              } else {
                this.setState({ showCreateForm: false });
              }
            }}
            fieldValues={{
              position: this.state.fieldPosition
                ? { ...(this.state.fieldPosition as any) }
                : { latitude: null, longitude: null },
              polygon: this.state.polygon as any,
              areaSize: this.state.areaSize as any,
            }}
          />
        ) : null}
      </>
    );
  }


  onPolygonChange = value => {
    const { center, areaSize, polygon } = value;
    this.setState({
      fieldPosition: center,
      areaSize,
      polygon,
    });
  };

  onHideMarkers = () => this.setState({ hideMarkers: !this.state.hideMarkers });

  onChangeTool = tool => this.setState({ activeTool: tool });

  onHideDistanceMarkers = () =>
    this.setState({ hideDistanceMarkers: !this.state.hideDistanceMarkers });

  onUpdateView = (center, zoom) => this.props.actions?.setMapView(center, zoom);

  onMarkerPress = marker => {
    if (marker) {
      this.props.actions?.openField(marker.key);
      this.props.history?.push(getFieldPath(this.props.company?.key as any, marker.key));
    }
  };

  onShowCreateForm = () => this.setState({ showCreateForm: true });

  onMapRef = (map: google.maps.Map) => {
    this.setState({ mapRef: map });
  };
}

export default compose<typeof FieldsMap>(
  connect((state: any) => {
    const user = state.firebase.profile;
    const openCompany = selectors.getOpenCompanyId(state);
    const openField = selectors.getOpenFieldId(state);
    const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openField);
    return {
      field,
    };
  }),
  Comments,
  connect(
    (state: any, ownProps: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      const company = companySelectors.getCompany(
        state.firestore.data,
        openCompany,
      );
      const browsingGroup = getBrowsingGroupKey(state, openCompany);
      const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
        state,
        openCompany,
      );
      const markers = getMarkers(state, openCompany, state.firebase.auth.uid, {
        cropAge: true,
      });
      const ui = getUi(state, "fieldsMap");
      const openField = selectors.getOpenFieldId(state);
      const comments = openField ? getComments(state, openField, null) : [];
      const forms = ownProps.field
        ? getFormSubmissionsForField(
          state,
          openCompany,
          browsingGroup,
          ownProps.field.key,
          ownProps.field.activeCrop.key,
        )
        : [];

      const boniturImages: any[] = [];
      forms.forEach(form => {
        const boniturImagesKeys = getBoniturisImageKey(state, { formSchemaId: form.schema_id });
        keys(form?.formValues).forEach(key => {
          if (boniturImagesKeys.includes(key)) {
            const img = new Image();
            img.src = form.formValues[key]?.uri;
            boniturImages.push({ key: form.key, comment: { key: form.comment_id, type: "user.image", extraData: { image: form.formValues[key] }, image: { dimensions: { width: img.width, height: img.height }, ...form.formValues[key] } }, position: form.position });
          }
        });
      });
      const commentMarkers = [...commentsToMarkers(comments), ...boniturImages.filter(item => item.position)];
      return {
        loading: !hasLoaded(
          [
            ...getFieldsQueries(openCompany, myCompanyProfile, browsingGroup, FieldState.Active),
          ],
          state,
        ),
        company: company,
        markers: markers,
        center: get(state, "map.center"),
        zoom: get(state, "map.zoom"),
        myCompanyProfile,
        ui,
        commentMarkers,
        formMarkers: formsToMarkers(forms),
        openCompany,
        browsingGroup,
      };
    },
    function mapDispatchToProps(dispatch) {
      return {
        actions: bindActionCreators(
          {
            ...fieldActions,
            ...mapActions,
            showMapFields,
          },
          dispatch,
        ),
      };
    },
  ),
  firestoreConnect(props => {
    const { field, openCompany, browsingGroup } = props;
    const paths: any[] = [];
    if (field) {
      paths.push(
        getFormSubmissionsForFieldQuery(
          openCompany,
          browsingGroup,
          field.key,
          field.activeCrop.key,
        ),
      );
    }
    return paths;
  }),
  withRouter,
)(FieldsMap);

const renderCropPolygons = (markers, mapRef) => {
  const polygons = markers.map((marker, idx) => {
    const shouldRender = get(marker, "activeCrop.markedArea", false);
    if (!shouldRender) {
      return null;
    }
    const markedArea = get(marker, "activeCrop.markedArea");
    const polygonPath = preparePolygonPath(markedArea.polygon);
    const polygonOptions = getPolygonOptions({
      color: getColor(get(marker, "activeCrop.color", "noCrop")),
    });

    return (
      <Polygon
        key={marker.activeCrop.key + "-" + idx}
        path={polygonPath}
        options={polygonOptions}
        map={mapRef}
        onClick={() => {}}
      />
    );
  }).filter(p => p);

  return polygons;
};

