import * as React from 'react';

import Icon, { FjIconType } from './Icon';

import * as constants from '../../styles/style';
import { classes } from '../../utils/dom';
import './style.css';

type Props = {
  showIcon: boolean;
  icon?: string;
  text: string;
  iconType?: FjIconType;
  iconStyle?: any;
  className?: any;
  containerStyle?: React.CSSProperties;
};
export default class IconHeader extends React.PureComponent<Props> {
  static defaultProps = {
    showIcon: true,
    iconType: 'ion',
  };

  render() {
    const { showIcon, icon, text, iconType, iconStyle } = this.props;
    let renderedIcon: any = null;

    let style = {
      marginRight: 10,
      fontSize: 17,
      marginTop: 2,
      color: constants.FJMUTED,
    };
    if (iconStyle) {
      style = iconStyle;
    }

    if (showIcon) {
      renderedIcon = <Icon iconType={iconType} style={style} name={icon as any} />;
    }

    return (
      <div className={classes('icon-header', this.props.className)}>
        {renderedIcon}
        <span style={{ fontWeight: 'bold', color: constants.FJMUTED }}>
          {text}
        </span>
      </div>
    );
  }
}
