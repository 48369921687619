import { numberTransformer } from '../tcomb/transformers/transformers';

/**
 * Calculate the kg/ha
 *
 * @param input
 */
export function calculateKg(kg, percent) {
  kg = Number(numberTransformer.parse(kg));
  percent = Number(numberTransformer.parse(percent));

  if (isNaN(kg) || isNaN(percent)) {
    return 0;
  }

  return Number((kg * (percent / 100)).toFixed(1));
}
