import React, { useState, useEffect } from 'react';
import firebase from '../../data/firebase';
import I18n from '../../language/i18n';
import { LoadingIcon } from '../Loading/Loading';
import { inviteSupport } from '../../data/queries/support';
import './style.css';


export const InviteSupportButton = (props) => {
  const { companyId } = props;
  const [apiRunning, setApiRunning] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    if (apiRunning) {
      const doRequest = async () => {
        const user = firebase.auth().currentUser;
        const token = await user?.getIdToken(true);
        return inviteSupport(token, companyId)
          .then(() => {
            setApiRunning(false);
            setApiSuccess(true);
          }).catch((e) => {
            setApiRunning(false);
            setApiSuccess(false);
            setApiError(e);
          });
      };
      doRequest().catch(console.log);
    }
  }, [apiRunning, companyId]);


  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <button
        className={'btn btn-primary'}
        onClick={() => setApiRunning(true)}
        style={{ marginRight: 10 }}>
        {
          apiRunning
          ? (<LoadingIcon style={{ height: 22 }} />)
          : (I18n.t('company.inviteSupport'))
        }
      </button>
      {
        apiSuccess && !apiRunning
        ? (
          <p>{I18n.t('company.supportSuccessfullyInvited')}</p>
        )
        : null
      }
      {
        !apiSuccess && !apiRunning && apiError
        ? (
          <p>Error occured: { apiError }</p>
        )
        : null
      }
    </div>
  );
};
