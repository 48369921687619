import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionAnalysis from 'farmerjoe-common/lib/actions/analysis';
import I18n from '../../language/i18n';
import { isAdmin } from 'farmerjoe-common/lib/utils/User';
import CancelledAnalysisInfo from './CancelledAnalysisInfo';
import AlertBox from '../Common/AlertBox';
import { AlertConfirmDialog } from '../Dialog/Dialog';
import { AnalysisState as AnalysisStateTypes } from 'farmerjoe-common/lib/flow/types';

const DIALOG_CONFIRM_VALUE = 'ok';

// TODO: improve typings
type Props = any;
type State = any;

class AnalysisState extends Component<Props, State> {
  static propTypes = {
    analysis: PropTypes.object.isRequired,
    myCompanyProfile: PropTypes.object.isRequired,
  };

  state = { confirm: null };

  render() {
    const { analysis, myCompanyProfile } = this.props;

    let status: any = null;

    // The analysis is cancelled
    if (analysis.state === AnalysisStateTypes.Cancelled) {
      status = <CancelledAnalysisInfo analysis={analysis} key={0} />;
    }

    if (analysis.state === AnalysisStateTypes.Inbox) {
      status = (
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            display: 'flex',
          }}
          key={0}>
          <button
            className={'btn btn-danger'}
            style={{ width: 200 }}
            onClick={() => {
              this.setState({
                confirm: {
                  title: I18n.t('analysis.cancelAnalysis'),
                  children: I18n.t('analysis.doYouReallyWantToCancelAnalysis'),
                  buttons: [
                    { label: I18n.t('cancel'), value: 'cancel' },
                    {
                      label: I18n.t('yes'),
                      value: DIALOG_CONFIRM_VALUE,
                      className: 'btn-danger',
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_CONFIRM_VALUE) {
                      this.props.actions.cancelAnalysis(analysis);
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
            }}>
            {I18n.t('analysis.cancelAnalysis')}
          </button>
        </div>
      );
    }

    // Analysis is ordered and lab has agreed to take the order
    if (analysis.state === AnalysisStateTypes.Inbox && analysis.lab_accepted === true) {
      status = (
        <div
          style={{
            marginTop: 20,
            marginBottom: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
          key={0}>
          <AlertBox
            boxStyle={{ borderRadius: 5 }}
            text={I18n.t('analysis.acceptedFromLabAndCantBeCancelled')}
            type={'success'}
          />
        </div>
      );
    }

    if (!isAdmin(myCompanyProfile)) {
      status = null;
    }
    return [
      status,
      <AlertConfirmDialog
        {...this.state.confirm}
        show={!!this.state.confirm}
        key={1}
      />,
    ];
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...actionAnalysis,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  (state: any, ownProps: any) => {
    const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
      state,
      ownProps.analysis.company_id,
    );
    return {
      myCompanyProfile,
    };
  },
  mapDispatchToProps,
)(AnalysisState);
