import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';
import { openFieldSharingRequest } from 'farmerjoe-common/lib/actions/fieldSharing';
import { openField } from 'farmerjoe-common/lib/actions/field';

import { classes } from '../../utils/dom';
import withRouter from '../Router/withRouter';
import FieldInfo from './FieldInfo';

const FieldSharingRequestListItem = (props) => {
  const { fieldSharingRequest, openCompany, selected, actions, history } = props;
  const { field, key: requestId } = fieldSharingRequest;
  const { activeCrop: crop } = field;

  return (
    <div
      key={requestId}
      className={classes('list-item', selected && 'selected')}
      style={{
        backgroundColor: getColor(crop.color),
        cursor: 'pointer',
      }}
      onClick={() => {
        actions.openField(field.key);
        actions.openFieldSharingRequest(requestId);
        history.push(
          `/company/${openCompany}/field-sharing-requests/${requestId}`,
        );
      }}
    >
      <FieldInfo fieldSharingRequest={fieldSharingRequest} />
    </div>
  );
};

export default compose<typeof FieldSharingRequestListItem>(
  connect(
    null,
    dispatch => ({
      actions: bindActionCreators(
        {
          openFieldSharingRequest,
          openField,
        },
        dispatch,
      ),
    }),
  ),
  withRouter,
)(FieldSharingRequestListItem);
