import React from "react";
import { cloneDeep } from "lodash-es";
import t from "tcomb-form";


import FormInput from "../templates/FormInput";
import HiddenComponent from "../templates/HiddenComponent";
import modalCountry from "../templates/Country/Country";
import modalCountryTel from "../templates/Country/CountryTel";
import { positionTemplate } from "../templates/Position";
import Email from "../validators/email";
import { latitude, longitude } from "../validators/coordinates";
import { numberTransformer, latLonTransformer, whiteSpaceTrimmerTransformer } from "../transformers/transformers";
import stylesheet from "../stylesheets/style";
import I18n from "../../language/i18n";


import * as constants from "../../styles/style";

const model = ()  => {
  return t.struct({
    name: t.String,
    group_number: t.maybe(t.String),
    email: t.maybe(Email),
    street: t.maybe(t.String),
    zip: t.maybe(t.String),
    city: t.maybe(t.String),
    country: t.maybe(t.String),
    tel: t.maybe(t.String),
    position: t.struct({
      latitude: latitude,
      longitude: longitude,
    }),
    ggn_number: t.maybe(t.String),
    qs_number: t.maybe(t.String),
    notes: t.maybe(t.String),
    contact: t.struct({
      name: t.maybe(t.String),
      email: t.maybe(t.String),
      tel: t.maybe(t.String),
    }),
    type: t.String,
  });
};

const multiline = cloneDeep(stylesheet);
multiline.textbox.normal = {
  ...multiline.textbox.normal,
  height: "auto",
};

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

function template(locals, PolygonComponent) {
  // in locals.inputs you find all the rendered fields
  return (
    <div>
      {locals.inputs.name}
      {locals.inputs.group_number}
      {locals.inputs.street}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}>
        <div style={{ width: 150 }}>{locals.inputs.zip}</div>
        <div style={{ flex: 1 }}>{locals.inputs.city}</div>
      </div>
      {locals.inputs.country}
      {PolygonComponent}
      <div style={{}}>{locals.inputs.position}</div>
      {locals.inputs.tel}
      <div style={{marginTop: "15px"}}>
        {locals.inputs.email}
      </div>
      {locals.inputs.ggn_number}
      {locals.inputs.qs_number}
      {locals.inputs.notes}
      {locals.inputs.type}
      <div style={{ marginTop: 20 }}>
        <span style={{ fontSize: 18 }}>
          {I18n.t("producers.contactPerson")}
        </span>
        {locals.inputs.contact}
      </div>
    </div>
  );
}

const options = function({ PolygonComponent, type }) {
  const langProp = type === "producer" ? "producers" : "traders";

  return {
    template: locals => template(locals, PolygonComponent),
    stylesheet: stylesheet,
    auto: "none",
    fields: {
      name: {
        label: `${I18n.t(`${langProp}.name`)} *`,
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
      },
      group_number: {
        label: I18n.t(`${langProp}.number`),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
      },
      email: {
        label: `${I18n.t("email")}`,
        placeholderTextColor: constants.FJLABEL,
        keyboardType: "email-address",
        factory: FormInput,
        transformer: whiteSpaceTrimmerTransformer,
      },
      street: {
        label: I18n.t("street"),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
      },
      zip: {
        label: I18n.t("zip"),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
      },
      city: {
        label: I18n.t("city"),
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
      },
      country: {
        label: I18n.t("country"),
        factory: modalCountry,
        placeholderTextColor: constants.FJLABEL,
      },
      tel: {
        label: I18n.t("mobile_phone"),
        config: {
          placeholder: I18n.t("mobile_phone"),
        },
        factory: modalCountryTel,
        testID: "testFieldTel",
      },

      position: {
        label: `${I18n.t(`${langProp}.location`)}`,
        template: positionTemplate,
        fields: {
          latitude: {
            label: I18n.t("latitude"),
            factory: FormInput,
            transformer: latLonTransformer,
          },
          longitude: {
            label: I18n.t("longitude"),
            factory: FormInput,
            transformer: latLonTransformer,
          },
        },
      },
      ggn_number: {
        label: `${I18n.t("ggn_gln_number")}`,
        placeholderTextColor: constants.FJLABEL,
        factory: FormInput,
      },
      qs_number: {
        label: `${I18n.t("company.qs_number")}`,
        placeholderTextColor: constants.FJLABEL,
        factory: FormInput,
      },
      notes: {
        label: I18n.t("notes"),
        placeholder: I18n.t("notes"),
        stylesheet: multiline,
        textAlignVertical: "bottom",
        placeholderTextColor: constants.FJLABEL,
        factory: FormInput,
      },
      contact: {
        fields: {
          name: {
            label: `${I18n.t("name")}`,
            factory: FormInput,
            transformer: numberTransformer,
            placeholderTextColor: constants.FJLABEL,
          },
          email: {
            label: `${I18n.t("email")}`,
            placeholderTextColor: constants.FJLABEL,
            keyboardType: "email-address",
            factory: FormInput,
          },
          tel: {
            label: I18n.t("mobile_phone"),
            config: {
              placeholder: I18n.t("mobile_phone"),
            },
            factory: modalCountryTel,
            testID: "testFieldTelContact",
          },
        },
      },
      type: {
        type: "hidden",
        factory: HiddenComponent,
      },
    },
  };
};

export default { model, options };
