import * as React from "react";
import t from "tcomb-form";
import { classes } from "../../utils/dom";
import "./style.css";
import RSwitch from "../../components/Common/RSwitch/RSwitch";
import { get } from "lodash-es";

const Component = t.form.Component;

export default class Boolean extends Component {
  getTemplate() {
    return locals => {
      if (locals.value === void 0 || locals.value === null) {
        setTimeout(() => {
          // react complains if the onchange is called directly in the render
          locals.onChange(false);
        }, 0);
      }

      const desc = get(locals, "config.desc", null);

      return (
        <div className={classes("boolean", locals.hasError && "error")}>
          <label htmlFor={this.props.ctx?.name}>
            {locals.label}
            {desc && <div className={"small text-muted"}>{desc}</div>}
          </label>

          <RSwitch
            name={this.props.ctx?.name as any}
            onChange={locals.onChange}
            value={!!locals.value}
          />
        </div>
      );
    };
  }
}
