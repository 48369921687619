import * as React from 'react';
import { reorderMapEvent } from '../../utils/Map';
import { Polygon as PolygonComponent } from '@react-google-maps/api';

type Props = {
  map: google.maps.Map;
  onClick: (...args: Array<any>) => any;
  path: any;
  options: any;
};

export default class Polygon extends React.Component<Props> {
  render() {
    const { onClick, map, ...restProps } = this.props;

    if (!map) return null;
    return (
      <PolygonComponent
        onClick={reorderMapEvent(map, 'click', onClick)}
        {...restProps}
      />
    );
  }
}
