import * as React from 'react';

import Icon from './Icon';
import './NewButton.css';

export const CreateNewButton = ({ onClick }) => {
  return (
    <div
      className="top-bar-button material-round"
      onClick={() => onClick()}
      key="create-new">
      <Icon name="plus" iconType={'fa'} />
    </div>
  );
};
