import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { keyBy, map, orderBy } from "lodash-es";

import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";
import {
  Notification as NotificationType,
  OrderByDirection,
  Profile,
} from "farmerjoe-common/lib/flow/types";
import { firestoreRef } from "redux-firestore/lib/utils/query";
import { getAllNotificationsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Notifications";

import Notification from "./Notification";
import FlatList from "../Common/FlatList";
import NoResults from "../Common/NoResults";
import { Loading } from "../Loading/Loading";
import I18n from "../../language/i18n";

type Props = {
  firestore?: Record<string, any>;
  firebase?: Record<string, any>;
  openCompany?: string;
  browsingGroup?: string;
  profile?: Profile;
  onClickItem?: (arg0: NotificationType) => void;
};

type State = {
  lastItem: NotificationType | null;
  loading: boolean;
  loadingMore: boolean;
  hasMore: boolean;
  items: Array<NotificationType>;
  itemsCache: Record<string, NotificationType>;
};
const limit = 10;

class NotificationList extends React.Component<Props, State> {
  state: State = {
    lastItem: null,
    loading: true,
    loadingMore: false,
    items: [],
    hasMore: false,
    itemsCache: {},
  };

  listeners: any[] = [];

  componentDidMount() {
    const { lastItem } = this.state;

    if (!lastItem) {
      this.loadMoreNotifications(true);
    }
  }

  componentWillUnmount() {
    this.clearListeners();
  }

  render() {
    const { items } = this.state;

    return (
      <div className="notification-list-container">
        {items.length
          ? (
            <FlatList
              className="notification-list"
              data={items}
              onEndReached={this.onEndReached}
              endReachedThreshold={0.8}
              ListFooterComponent={this.renderFooter}
              renderItem={this.renderItem}
            />
          )
          : (
            <NoResults text={I18n.t("notifications.noNotifications")} />
          )}
      </div>
    );
  }

  renderItem = ({ item }) => (
    <Notification notification={item} onClick={this.props.onClickItem} />
  );

  renderFooter = () => {
    const { loadingMore } = this.state;
    return (
      <div>
        {loadingMore
          ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}>
              <Loading scale={1.0} size={"small"} />
            </div>
          )
          : null}
      </div>
    );
  };

  onEndReached = () => {
    if (this.state.hasMore && !this.state.loading && !this.state.loadingMore) {
      this.setState(
        {
          loadingMore: true,
        },
        () => this.loadMoreNotifications(),
      );
    }
  };

  clearListeners() {
    this.listeners.forEach((listener: any) => {
      listener && listener();
    });
    this.listeners = [];
  }

  loadMoreNotifications(reset = false) {
    const { firebase, openCompany, profile } = this.props;
    const { lastItem } = this.state;

    if (reset) {
      this.setState({ loading: true, items: [], itemsCache: {} });
      this.clearListeners();
    }

    const notificationsQuery = (getAllNotificationsQuery as any)(
      profile?.uid,
      openCompany,
    );
    notificationsQuery.limit = limit;
    if (!reset && lastItem) {
      notificationsQuery.startAfter = lastItem.created;
    }
    const query = firestoreRef(firebase, notificationsQuery);
    const callback = querySnap => {
      const docs = querySnap.docs.map(doc => doc.data());
      const docsObject = keyBy(docs, "key");

      const itemsCache = { ...this.state.itemsCache, ...docsObject };

      querySnap.docChanges().forEach(function(change) {
        // Remove a deleted item from the object
        if (change.type === "removed") {
          const removedDoc = change.doc.data();
          delete itemsCache[removedDoc.key];
        }
      });

      this.setState({
        hasMore: true,
        loading: false,
        loadingMore: false,
        itemsCache: itemsCache,
        items: orderBy(
          map(itemsCache, value => value),
          item => new Date(item.created),
          [OrderByDirection.Desc],
        ),
      });

      if (docs[docs.length - 1]) {
        this.setState({ lastItem: docs[docs.length - 1] });
      }

      if (docs.length < limit) {
        this.setState({ hasMore: false });
      }
    };

    const onError = error => {
      this.setState({ loading: false });
    };

    this.listeners.push(query.onSnapshot(callback, onError));
  }
}

export default compose<typeof NotificationList>(
  connect((state: any, ownProps: any) => {
    const openCompany = ownProps.openCompany || getOpenCompanyId(state);
    const profile = state.firebase.profile;

    return {
      openCompany,
      profile,
    };
  }),
  firestoreConnect(),
)(NotificationList);
