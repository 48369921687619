import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Dialog, { AlertDialog } from '../Dialog/Dialog';
import I18n from '../../language/i18n';
import ApiKeyModel from '../../tcomb/models/apiKey';
import type { ApiKey } from '../../flowTypes';
import firebase from '../../data/firebase';
import { generateKey } from '../../data/queries/api';
import { updateKey } from '../../actions/apiKeys';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { captureException } from '../../utils/sentry';
import { Loading } from '../Loading/Loading';

const t = require('tcomb-form');
const Form = t.form.Form;

type Props = {
  apiKey?: ApiKey;
  openCompany: string;
  show?: boolean;
  onClose?: (...args: Array<any>) => any;
  actions: Record<string, any>;
};

type State = {
  apiKey: ApiKey | any;
  alertMessage: string | null;
  alertTitle: string | null;
  showLoading: boolean;
};

class ApiKeyForm extends React.Component<Props, State> {
  state = {
    apiKey: this.props.apiKey || { ip_filtering: false },
    alertMessage: null,
    alertTitle: null,
    showLoading: false,
  };

  render() {
    const { onClose, show, apiKey } = this.props;
    if (!show) {
      return null;
    }
    const editMode = !!apiKey;
    return (
      <Dialog
        className="api-keys-dialog"
        show={show}
        onClose={() => {
          if (!this.state.showLoading) {
            onClose && onClose();
          }
        }}
        title={editMode ? I18n.t('edit') : I18n.t('apiKeys.generate')}
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="btn btn-secondary ml-auto"
              onClick={onClose}
              disabled={this.state.showLoading}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}
              disabled={this.state.showLoading}>
              {editMode ? I18n.t('save') : I18n.t('apiKeys.generate')}
            </button>
          </div>
        }>
        {this.state.showLoading ? <Loading /> : null}
        <Form
          ref="form"
          type={ApiKeyModel.model()}
          options={() => ApiKeyModel.options()}
          value={this.state.apiKey}
          onChange={this.onChange.bind(this)}
          key="form"
        />
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
          key="alert"
        />
      </Dialog>
    );
  }

  onSave() {
    let value = (this.refs.form as any).getValue();
    const { openCompany, apiKey } = this.props;
    const editMode = !!apiKey;

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t('please_correct_your_entry'),
        alertTitle: I18n.t('error'),
      });
      return;
    }

    // do this so we can change props
    value = JSON.parse(JSON.stringify(value));

    if (value.ip_filtering) {
      // make sure it's not null
      value.ip_filter = value.ip_filter || '';
    }

    if (editMode) {
      this.setState({ showLoading: true });
      this.props.actions
        .updateKey({ ...this.state.apiKey, ...value })
        .then(() => {
          this.setState({ showLoading: false });
          this.props.onClose && this.props.onClose();
        })
        .catch(e => {
          console.error(e);
          this.setState({
            alertTitle: I18n.t('error'),
            alertMessage: e.message,
            showLoading: false,
          });
          captureException(e);
        });
    } else {
      const user = firebase.auth().currentUser;
      this.setState({ showLoading: true });
      user?.getIdToken(true)
        .then(token => {
          return generateKey(token, {
            company_id: openCompany,
            user_id: user.uid,
            ...value,
          });
        })
        .then(() => {
          this.setState({ showLoading: false });
          this.props.onClose && this.props.onClose();
        })
        .catch(e => {
          console.error(e);
          this.setState({
            alertTitle: I18n.t('error'),
            alertMessage: e.message,
            showLoading: false,
          });
          captureException(e);
        });
    }
  }

  onChange(value) {
    this.setState({
      apiKey: {
        ...this.state.apiKey,
        ...value,
      },
    });
  }
}

export default compose(
  connect(
    (state: any) => ({
      openCompany: selectors.getOpenCompanyId(state),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          updateKey,
        },
        dispatch,
      ),
    }),
  ),
)(ApiKeyForm);
