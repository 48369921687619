import React from 'react';

import * as constants from '../../styles/style';
import DatePicker from 'rc-calendar/lib/Picker';

import moment from 'moment';
import I18n, { getCurrentLocaleData } from '../../language/i18n';

import t from 'tcomb-form';
import FixedCalendar from '../utils/FixedCalendar';
import './style.css';
import { get } from 'lodash-es';

const Component = t.form.Component;

type ModalDateComponentProps = t.ComponentProps & {
  locals?: any;
};

/**
 * TODO: find a way to leave only this and remove the other ModalDate template
 */
class ModalDateComponent extends React.Component<ModalDateComponentProps> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  private disabledDate(current) {
    const { locals } = this.props;
    const currentDateValue = current && current.valueOf();

    if (
      locals.config.minimumDate &&
      currentDateValue <
        moment(locals.config.minimumDate).valueOf()
    ) {
      return true;
    }

    if (
      locals.config.maximumDate &&
      currentDateValue >
        moment(locals.config.maximumDate).valueOf()
    ) {
      return true;
    }

    return false;
  }

  render() {
    const locals = this.props.locals;
    const controlLabelStyleError: any = null;

    // locals.value = null
    let formattedValue = String(locals.value);

    let disabled = false;

    if (locals.config) {
      if (locals.config.format) {
        formattedValue = locals.config.format(locals.value);
      }

      if (locals.config.disabled) {
        disabled = true;
      }
    }

    const label = locals.label
      ? (
      <span style={{ ...controlLabelStyleError, fontWeight: 'bolder' }}>{locals.label}</span>
        )
      : null;

    let container: any = null;

    if (!formattedValue) {
      formattedValue = I18n.t('datePleaseSelect');
    }

    if (disabled) {
      return (
        <div>
          {label}
          <span style={{ ...constants.styles.stdSize, ...{ marginTop: 0 } }}>
            {formattedValue}
          </span>
        </div>
      );
    }

    container = (
      <div className="modal-date-factory">
        <span className={'mb-1'} key={0}>
          {label}
        </span>
        <DatePicker
          placement={'bottom'}
          align={{ points: ['tc', 'bc'] }}
          calendar={
            <FixedCalendar
              showWeekNumber={false}
              locale={get(getCurrentLocaleData(), 'calendar.default')}
              showToday
              showDateInput={false}
              format={'YYYY-MM-DD'}
              showOk={false}
              disabledDate={this.disabledDate.bind(this)}
            />
          }
          defaultValue={
            locals.value != null
              ? moment(locals.value).locale(I18n.locale)
              : null
          }
          onChange={date => locals.onChange(date.toDate())}>
          {({ value }) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 0,
                  paddingBottom: 0,
                  borderWidth: 1,
                  borderColor: locals.hasError
                    ? constants.FJAPPLERED
                    : constants.FJNEWGREEN,
                  borderRadius: 5,
                  backgroundColor: locals.hasError
                    ? constants.FJAPPLERED
                    : constants.FJNEWGREEN,
                  cursor: 'pointer',
                  // backgroundColor: '#EEE'
                }}>
                <span
                  style={{ ...{ color: '#FFF', padding: 3, paddingTop: 6 } }}>
                  {formattedValue}
                </span>
              </div>
            );
          }}
        </DatePicker>
      </div>
    );

    return container;
  }
}

export default class modalDateFactory extends Component {
  onSelected(color) {
    this.setState({ value: color });

    // Let other props know about the new color
    if (this.props.onChange) this.props.onChange(color);
  }

  getTemplate() {
    return locals => {
      return (
        <div>
          <ModalDateComponent locals={locals} />
        </div>
      );
    };
  }
}
