import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { outputDate } from "farmerjoe-common";
import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getFieldMarkers } from "farmerjoe-common/lib/selectors/markers";

import * as actionCreators from "farmerjoe-common/lib/actions/actions";
import I18n from "../../../language/i18n";
import * as constants from "../../../styles/style";

import IconHeader from "../../Common/IconHeader";
import FormTextRow from "../../Common/FormTextRow";
import ViewOnlyMarkMapArea from "../../Common/ViewOnlyMarkMapArea";
import Dialog from "../../Dialog/Dialog";

// TODO: improve typings
type Props = any;
type State = any;

class WaitTime extends Component<Props | TCommentMarkedArea, State> {
  render() {
    const { comment, field } = this.props;

    return (
      <div>
        <IconHeader
          icon="shield"
          text={"Pflanzenschutz"} // TODO: Translations
          showIcon={true}
          iconStyle={{
            fontSize: 12,
            marginRight: 10,
            color: constants.FJMUTED,
          }}
          iconType="far"
        />

        <FormTextRow
          label={I18n.t("waittime.pesticide")}
          value={comment.text.template}
          className={"wait-time-pesticide-name"}
        />
        <FormTextRow
          label={I18n.t("waittime.waiting_period_in_days")}
          value={comment.text.wait_time}
        />
        <FormTextRow
          label={I18n.t("waittime.application_day")}
          value={outputDate(comment.text.applied_on)}
        />
        {comment.text.notes ? (
          <FormTextRow
            label={I18n.t("notes")}
            value={comment.text.notes}
          />
        ): null }
        {comment.text.sprayer ? (
          <FormTextRow
            label={I18n.t("waittime.sprayer")}
            value={comment.text.sprayer}
          />
        ): null }
        {comment.text.markedArea ? (
          <>
            <FormTextRow
              label={I18n.t("markedArea")}
              value={
                <CommentMarkedArea field={field} comment={comment} />
              }/>
            <FormTextRow
              label={I18n.t("field.area")}
              value={comment.text.markedArea.areaSize + " ha"}
            />
          </>
        ) : null}
      </div>
    );
  }
}

type TPoint = {
  latitude: number;
  longitude: number;
};

type TCommentMarkedArea = {
  field: {
    polygon: TPoint[];
  };
  comment: {
    text: {
      markedArea: {
        center: TPoint;
        areaSize: number;
        polygon: TPoint[];
      };
    };
  };
};

const CommentMarkedArea = ({ field, comment }: TCommentMarkedArea) => {
  const [ enlargeMap, setEnlargeMap ] = useState(false);
  if (!field) {
    return null;
  }

  const { text: { markedArea }  } = comment;

  if (!markedArea) {
    return null;
  }

  const markers = getFieldMarkers([field]);

  return (
    <div>
      <div style={{height: "300px"}}>
        <ViewOnlyMarkMapArea
          position={markedArea.center}
          markers={markers}
          pinPosition={markedArea.center}
          polygon={field.polygon}
          currentMarkedArea={markedArea}
          mapContainerStyle={{ height: "300px" }}
          googleMapsOptions={{
            disableDefaultUI: false,
          }}
          enlargeMap={true}
          onEnlargeMap={() => setEnlargeMap(true)}
        />
      </div>
      <div>
        {enlargeMap ? (
          <Dialog
            show={enlargeMap}
            title={
              <div className="header">
                <span>{I18n.t("markedArea")}</span>
              </div>
            }
            onClose={() => setEnlargeMap(false)}
            className="modal-xl"
            footer={
              <div className="d-flex flex-grow-2">
                <button
                  className="btn btn-primary"
                  onClick={() => setEnlargeMap(false)}
                  disabled={false}>
                  {I18n.t("done")}
                </button>
              </div>
            }>
            <div style={{ backgroundColor: "#000" }}>
              <ViewOnlyMarkMapArea
                position={markedArea.center}
                markers={markers}
                pinPosition={markedArea.center}
                polygon={field.polygon}
                currentMarkedArea={markedArea}
                mapContainerStyle={{ height: "70vh" }}
                googleMapsOptions={{
                  disableDefaultUI: false,
                }}
              />
            </div>
          </Dialog>
        ) : null}
      </div>
    </div>
  );
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const user = state.firebase.profile;
  const comment = ownProps.comment;
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, comment.field_id);
  return {
    comment,
    field,
  };
};

export default connect(
  selector,
  actionCreators,
)(WaitTime);
