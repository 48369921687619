import * as React from 'react';
import I18n from '../../../language/i18n';
import Box from '../../../components/Common/Box/Box';
import FormTextRow from '../../../components/Common/FormTextRow';
import Text from '../../../components/Common/Text';
import { isPlainObject, get } from 'lodash-es';
import moment from 'moment';

import FirebaseImage from '../../../components/Common/FirebaseImage';
import { outputDate } from 'farmerjoe-common';

const formatPrice = (key, option) => {
  return (
    <div
      key={key}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        display: 'flex',
      }}>
      <Text>{option.title}</Text>
      <Text>{option.price}</Text>
    </div>
  );
};
const formatValue = (value, fieldConfig, key) => {
  if (!fieldConfig) {
    return null;
  }

  if (fieldConfig.type === 'enum') {
    let options = value;
    if (!Array.isArray(options)) {
      options = [value];
    }

    return options.map(option => {
      const enumVal = get(fieldConfig, `enums.${option}`);

      if (isPlainObject(enumVal)) {
        return formatPrice(`${key}-${option}`, enumVal);
      }

      // if we don't have a matched value for this enum, then return the raw value from the db
      if (typeof enumVal === 'undefined') {
        return <span key={`${key}-${option}`}>{String(value)}</span>;
      }

      return <span key={`${key}-${option}`}>{String(enumVal)}</span>;
    });
  }

  if (fieldConfig.type === 'date') {
    return outputDate(value);
  }

  if (fieldConfig.type === 'string') {
    // If we have a value output it as a string
    if (value) {
      return String(value);
    }

    // if we have '', then return null. No need to output this field
    return null;
  }

  if (fieldConfig.type === 'boolean') {
    const truthy = get(fieldConfig, 'display.truthy', I18n.t('yes'));
    const falsy = get(fieldConfig, 'display.truthy', I18n.t('no'));

    return value === true ? truthy : falsy;
  }

  if (fieldConfig.type === 'image' && value.ref) {
    return <FirebaseImage imageRef={value.ref} />;
  }

  return value;
};

export const render = (form, config) => {
  const currentLocale = I18n.locale;

  const formConfig =
    config.translations[currentLocale] ||
    config.translations[config.defaultLanguage];

  return formConfig.order.map(blockName => {
    const block = get(formConfig, `blocks.${blockName}`);

    if (block) {
      // if the bloc is a field itself, render it
      if (!block.fields) {
        const field = block;

        const fieldValue = get(form, `${blockName}`);

        const renderedField = renderField(blockName, fieldValue, field);

        if (renderedField) {
          return renderBox(block.header, renderedField, blockName);
        }

        // if the block doesn't have a field we need to return here
        // otherwise we'll get into troubles
        return null;
      }

      // if we are dealing with fields, render them inside of a block
      const fields = block.order
        .map(fieldName => {
          const field = get(block, `fields.${fieldName}`);
          const fieldValue = get(form, `${blockName}.${fieldName}`);

          return renderField(fieldName, fieldValue, field);
        })
        .filter(field => field !== null);

      if (fields.length) {
        return renderBox(block.header, fields, blockName);
      }

      return null;
    }

    return null;
  });
};

const renderField = (key, fieldValue, field) => {
  // we don't have a field config, then return here
  if (typeof field === 'undefined') {
    return null;
  }

  if (typeof fieldValue !== 'undefined') {
    const formattedValue = formatValue(fieldValue, field, key);

    // The user didn't fill those values
    if (formattedValue === null) {
      return null;
    }

    if (Array.isArray(formattedValue) && formattedValue.length) {
      return <div key={key}>{formattedValue}</div>;
    }

    return <FormTextRow key={key} value={formattedValue} label={field.label} />;
  }

  return null;
};
const renderBox = (header, content, key) => {
  return <Box key={key} header={header} content={content} />;
};
