import * as React from 'react';
import { classes } from '../../utils/dom';
import type { GroupCellProps } from './flow';

export default class GroupCell extends React.PureComponent<GroupCellProps> {
  div: any = React.createRef();

  componentDidMount() {
    this.updateTitleAttribute();
  }

  componentDidUpdate() {
    this.updateTitleAttribute();
  }

  updateTitleAttribute() {
    const el = this.div.current;
    if (el) {
      const text = this.getTextContent();
      if (text != null) {
        el.setAttribute('title', text);
      }
    }
  }

  render() {
    const { column, columnClassName, style, row, isLastColumn } = this.props;

    if (column.GroupCell) {
      return (
        <div
          className={classes('group-cell', columnClassName)}
          style={style}
          ref={this.div}>
          {column.GroupCell(row)}
        </div>
      );
    } else {
      // empty cells in the bottom right grid
      return (
        <div
          className={classes(
            'group-cell',
            columnClassName,
            isLastColumn && 'cell-column-last',
          )}
          style={style}
          ref={this.div}
        />
      );
    }
  }

  getText() {
    const { column, row } = this.props;
    let text: any = null;

    if ((column as any).getGroupDataText) {
      text = (column as any).getGroupDataText(row);
    } else {
      text = this.getTextContent();
    }
    return text;
  }

  getTextContent() {
    const el = this.div.current;

    if (el) {
      return el.textContent;
    }
    return null;
  }
}
