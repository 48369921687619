import React from "react";
import { Field, Formik, Form } from "formik";

import { UserRole, UserState, GroupType, NOTIFICATION_TYPE_EMAIL } from "farmerjoe-common/lib/flow/types";

import Box from "../Common/Box/Box";
import Text from "../Common/Text";
import FormikField from "../Common/Formik/Field";
import SegmentedControl from "../Common/Formik/SegmentedControl";
import BooleanSegmentedControl from "../Common/Formik/BooleanSegmentedControl";
import FieldsSelection from "../../containers/Employees/FieldsSelection";
import UsersSelectionForm from "../../containers/Employees/UsersSelectionForm";
import { isAdmin } from "farmerjoe-common/lib/utils/User";

import I18n from "../../language/i18n";
import * as constants from "../../styles/style";

type Props = {
  handleSubmit: (...args: Array<any>) => any;
  bindSubmitForm?: (...args: Array<any>) => any;
  validate: (...args: Array<any>) => any;
  initialValues: any;
  innerRef?: any;
  groupType?: GroupType;
  myCompanyProfile?: any;
};

class EmployeeForm extends React.PureComponent<Props> {
  render() {
    const { handleSubmit, validate, initialValues, groupType, myCompanyProfile } = this.props;
    const initialFormValues = getInitialFormValues(initialValues, myCompanyProfile);

    return (
      <Formik
        ref={this.props.innerRef}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={initialFormValues}
        validate={values => {
          const validation = validate(values);

          return validation;
        }}
        onSubmit={(values, bag) => {
          handleSubmit(values, bag);
        }}>
        {({ isSubmitting, submitForm, values, errors, setFieldValue }) => {
          const active = !values.state || values.state !== UserState.Deactivated;
          const headerTitle = this.getHeaderTitle(values.role, groupType);

          return (
            // @ts-ignore
            <Form className={"employee form mb-5"}>
              <Box
                header={headerTitle}
                content={
                  <div>
                    {values.state === UserState.Deactivated
                      ? (
                        <div
                          className={
                            "d-flex flex-row justify-content-center mb-2"
                          }>
                          <div
                            style={{
                              color: constants.FJAPPLERED,
                              textAlign: "center",
                              flex: 1,
                            }}>
                            {I18n.t("employees.employeeIsDeactivated")}
                          </div>
                        </div>
                      )
                      : null}

                    <FormikField
                      disabled={!active}
                      type="input"
                      name="name"
                      className={"form-control"}
                      label={`${I18n.t("first_name")}, ${I18n.t("last_name")} *`}
                      error={errors.name as any}
                      value={values.name}
                    />

                    <FormikField
                      disabled={!active}
                      type="email"
                      name="email"
                      className={"form-control"}
                      label={`${I18n.t("email")} *`}
                      error={errors.email as any}
                      value={values.email}
                    />

                    <FormikField
                      disabled={!active}
                      type="input"
                      name="department"
                      className={"form-control"}
                      label={I18n.t("acl.department")}
                      value={values.department}
                    />
                  </div>
                }
              />

              {values.state !== UserState.Deactivated ? (
                <div>
                  {![UserRole.Advisor].includes(values.role) ? (
                    <Box
                      header={I18n.t("acl.rights")}
                      content={
                        <div
                          className={
                            "d-flex flex-row justify-content-center roles-select"
                          }>
                          <Field
                            name="role"
                            component={SegmentedControl}
                            options={[
                              {
                                text: I18n.t("acl.role.admin"),
                                value: UserRole.Admin,
                              },
                              {
                                text: I18n.t("acl.role.standard"),
                                value: UserRole.Standard,
                              },
                            ]}
                            help={
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginTop: 5,
                                  marginBottom: 0,
                                  justifyContent: "space-between",
                                }}>
                                <Text
                                  style={{
                                    ...constants.styles.extraSmall,
                                    ...{
                                      textAlign: "center",
                                      flex: 1,
                                      color: constants.FJMUTEDLIGHT,
                                    },
                                  }}>
                                  {I18n.t("acl.role.allrights")}
                                </Text>
                                <Text
                                  style={{
                                    ...constants.styles.extraSmall,
                                    ...{
                                      textAlign: "center",
                                      flex: 1,

                                      color: constants.FJMUTEDLIGHT,
                                    },
                                  }}>
                                  {I18n.t("acl.role.restricted")}
                                </Text>
                              </div>
                            }
                          />
                        </div>
                      }
                    />
                  ) : null}

                  {[UserRole.Standard, UserRole.Advisor].includes(values.role)
                    ? (
                      <div>
                        <Box
                          header={I18n.t("field.fields")}
                          content={
                            <div>
                              <Field
                                name={"views.fields"}
                                label={I18n.t("acl.view")}
                                component={BooleanSegmentedControl}
                                optionLabels={{
                                  falsy: I18n.t("acl.fields.selected"),
                                  truthy: I18n.t("acl.fields.all"),
                                }}
                              />

                              {values.views.fields
                                ? null
                                : (
                                  <FieldsSelection
                                    fields={values.fields}
                                    error={!!errors.fields}
                                    onChange={change => {
                                      setFieldValue("fields", change);
                                    }}
                                  />
                                )}

                              <div>
                                <Text className="acl-text">
                                  {I18n.t("acl.fields.canCreateEdit")}
                                </Text>
                                <Field
                                  name={"acl.field.create"}
                                  className={"crop_create_edit"}
                                  label={I18n.t("acl.fields.create")}
                                  component={BooleanSegmentedControl}
                                />
                                <Field
                                  name={"acl.field.edit"}
                                  className={"crop_create_edit"}
                                  label={I18n.t("acl.fields.edit")}
                                  component={BooleanSegmentedControl}
                                />
                              </div>
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("crop.crop")}
                          content={
                            <div>
                              <Field
                                name={"views.crops"}
                                component={BooleanSegmentedControl}
                                label={I18n.t("acl.view")}
                                optionLabels={{
                                  falsy: I18n.t("acl.crops.active"),
                                  truthy: I18n.t("acl.crops.all"),
                                }}
                              />
                              <div>
                                <Field
                                  className={"crop_create_edit"}
                                  component={BooleanSegmentedControl}
                                  label={I18n.t("acl.crops.seeVariety")}
                                  name={'acl.crop["get.variety"]'}
                                />
                              </div>

                              <div>
                                <Text className="acl-text">
                                  {I18n.t("acl.crops.canCreateEditHarvest")}
                                </Text>
                                <Field
                                  name={"acl.crop.create_edit"}
                                  className={"crop_create_edit"}
                                  label={`${I18n.t("acl.crops.planPlantEdit")}`}
                                  component={BooleanSegmentedControl}
                                />
                                <Field
                                  name={"acl.crop.delete"}
                                  label={I18n.t("acl.delete")}
                                  component={BooleanSegmentedControl}
                                />
                                <Field
                                  name={'acl.crop["edit.harvest"]'}
                                  label={I18n.t("acl.crops.harvest")}
                                  component={BooleanSegmentedControl}
                                />
                              </div>
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("acl.comments.comments")}
                          content={
                            <div>
                              <Text className="acl-text">
                                {I18n.t("acl.comments.standardUserRights")}
                              </Text>
                              <Field
                                name={"views.comments"}
                                component={BooleanSegmentedControl}
                                label={I18n.t("acl.view")}
                                optionLabels={{
                                  falsy: `${I18n.t("acl.comments.selected")}`,
                                  truthy: `${I18n.t("acl.comments.all")}`,
                                }}
                              />
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("acl.users.users")}
                          content={
                            <div>
                              <Text className="acl-text">
                                {I18n.t("acl.users.userRight")}
                              </Text>

                              <Field
                                name={"views.users"}
                                label={I18n.t("acl.view")}
                                component={BooleanSegmentedControl}
                                optionLabels={{
                                  falsy: I18n.t("acl.users.selected"),
                                  truthy: I18n.t("acl.users.all"),
                                }}
                              />
                              {values.views.users
                                ? null
                                : (
                                  <UsersSelectionForm
                                    users={values.users}
                                    error={!!errors.users}
                                    onChange={change => {
                                      setFieldValue("users", change);
                                    }}
                                    groupType={"employee"}
                                  />
                                )}
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("acl.producers.producers")}
                          content={
                            <div>
                              <Text className="acl-text">
                                {I18n.t("acl.producers.producerRight")}
                              </Text>

                              <Field
                                name={"views.producers"}
                                label={I18n.t("acl.view")}
                                component={BooleanSegmentedControl}
                                optionLabels={{
                                  falsy: I18n.t("acl.producers.selected"),
                                  truthy: I18n.t("acl.producers.all"),
                                }}
                              />
                              {values.views.producers
                                ? null
                                : (
                                  <UsersSelectionForm
                                    users={values.producers}
                                    error={!!errors.producers}
                                    onChange={change => {
                                      setFieldValue("producers", change);
                                    }}
                                    groupType={"producer"}
                                  />
                                )}
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("acl.traders.traders")}
                          content={
                            <div>
                              <Text className="acl-text">
                                {I18n.t("acl.traders.traderRight")}
                              </Text>

                              <Field
                                name={"views.traders"}
                                label={I18n.t("acl.view")}
                                component={BooleanSegmentedControl}
                                optionLabels={{
                                  falsy: I18n.t("acl.traders.selected"),
                                  truthy: I18n.t("acl.traders.all"),
                                }}
                              />
                              {values.views.traders
                                ? null
                                : (
                                  <UsersSelectionForm
                                    users={values.traders}
                                    error={!!errors.traders}
                                    onChange={change => {
                                      setFieldValue("traders", change);
                                    }}
                                    groupType={"trader"}
                                  />
                                )}
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("waittime.waitTime")}
                          content={
                            <div>
                              <Text className="acl-text">
                                {I18n.t("acl.waittime.description")}
                              </Text>

                              <Field
                                name={"acl.waittime.create"}
                                label={I18n.t("acl.create")}
                                component={BooleanSegmentedControl}
                              />
                              <Field
                                name={'acl.waittime["edit.own"]'}
                                label={I18n.t("acl.edit")}
                                component={BooleanSegmentedControl}
                              />
                              <Field
                                name={'acl.waittime["delete.own"]'}
                                label={I18n.t("acl.delete")}
                                component={BooleanSegmentedControl}
                              />
                            </div>
                          }
                        />

                        <Box
                          header={I18n.t("fertilizer.fertilization")}
                          content={
                            <div>
                              <Text className="acl-text">
                                {I18n.t("acl.fertilizing.description")}
                              </Text>

                              <Field
                                name={"acl.fertilizing.create"}
                                label={I18n.t("acl.create")}
                                component={BooleanSegmentedControl}
                              />
                              <Field
                                name={'acl.fertilizing["edit.own"]'}
                                label={I18n.t("acl.edit")}
                                component={BooleanSegmentedControl}
                              />
                              <Field
                                name={'acl.fertilizing["delete.own"]'}
                                label={I18n.t("acl.delete")}
                                component={BooleanSegmentedControl}
                              />
                            </div>
                          }
                        />
                      </div>
                    )
                    : (
                      <Box
                        header={I18n.t("acl.CRUD")}
                        content={
                          <div>
                            <div>{I18n.t("field.fields")}</div>
                            <div>{I18n.t("acl.crops.all")}</div>
                            <div>{I18n.t("notes")}</div>
                            <div>{I18n.t("waittime.waitTime")}</div>
                            <div>{I18n.t("fertilizer.fertilization")}</div>
                            <div>{I18n.t("producers.plural")}</div>
                            <div>{I18n.t("fieldSharing.employeesForm.aclShareField")}</div>
                          </div>
                        }
                      />
                    )}
                </div>
              ) : null}
              <div>
                <Box
                  header={I18n.t("notifications.header")}
                  content={
                    <div>
                      <Text className={"acl-text"}>
                        {I18n.t("acl.notifications.email.header")}
                      </Text>
                      <Field
                        name={"notifications.email"}
                        label={"Email"}
                        component={BooleanSegmentedControl}
                      />
                    </div>
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  private getHeaderTitle(role, groupType?: GroupType) {
    if (groupType === "producer") {
      return I18n.t("producers.formTitle");
    }
    if (role === "advisor") {
      return I18n.t("acl.role.advisor");
    } else {
      return I18n.t("employee");
    }
  }
}

// XXX:
// This is a helper method to check if the logged in user
// has enabled notifications from the employee
const checkIfNotificationsAreEnabled = (
  loggedInUserData,
  employeeId,
  notificationType,
) => {
  if (!loggedInUserData) {
    return false;
  }

  if (!employeeId) {
    return false;
  }

  const notificationsEnabled = !!loggedInUserData.notifications;
  return notificationsEnabled
    ? !!loggedInUserData.notifications.filter(
      (n) => n.from === employeeId && n.type === notificationType,
    ).length
    : false;
};

// XXX:
// This is a helper method to check if the user has producer/ trader acl
const getInitialGroupsAcls = (
  myCompanyProfile,
) => {
  if (!myCompanyProfile) {
    return {
      traders: true,
      producers: true,
    };
  }

  const { views } = myCompanyProfile;

  return {
    traders: views.traders || true,
    producers: views.producers || true,
  };
};

const getInitialFormValues = (initialValues, myCompanyProfile) => {
  const values = {
    ...initialValues,
    ...{
      notifications: {
        email: checkIfNotificationsAreEnabled(
          myCompanyProfile,
          initialValues.key,
          NOTIFICATION_TYPE_EMAIL,
        ),
      },
    },
  };

  if (isAdmin(initialValues)) {
    return values;
  }

  return {
    ...values,
    ...{
      views: {
        ...values.views,
        ...getInitialGroupsAcls(initialValues),
      },
    },
  };
};


export default React.forwardRef((props: Props, ref) => (
  <EmployeeForm innerRef={ref} {...props} />
));
