import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { filters } from "farmerjoe-common/lib/actions/actions";
import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as employeeCreators from "farmerjoe-common/lib/actions/employee";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { getCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Companies";
import {
  getCompanyGroupProfileForLoggedInUser,
  getGroupUsers,
  userSearchWord,
  userState,
} from "farmerjoe-common/lib/selectors/user";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getUsersQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Users";

import { Loading } from "../../components/Loading/Loading";
import EmployeesComponent from "../../components/Employees/Employees";

type Props = {
  loading: boolean;
};

class Employees extends React.PureComponent<Props> {
  render() {
    const { loading } = this.props;

    if (loading) {
      return <Loading />;
    }

    return <EmployeesComponent {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const users = getGroupUsers(state, openCompany, browsingGroup);
  const search = userSearchWord(state, openCompany);

  const hasLoadedEverything = hasLoaded([getCompanyQuery(openCompany), getUsersQuery(openCompany, browsingGroup)], state);

  return {
    auth: state.firebase.auth,
    browsingGroup: browsingGroup,
    company,
    email: state.firebase.profile.email,
    filter: userState(state, openCompany),
    loading: !hasLoadedEverything,
    myCompanyProfile,
    openCompany,
    search,
    users,
  };
};

const wrappedEmployees = firestoreConnect(props => {
  const { browsingGroup } = props;
  return [
    getCompanyQuery(props.openCompany),
    getUsersQuery(props.openCompany, browsingGroup),
  ];
})(Employees);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedEmployees);
