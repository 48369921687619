import React, { PureComponent } from 'react';

// TODO: improve typings
type Props = any;
type State = any;

export default class ListItem extends PureComponent<Props, State> {
  render() {
    const { item, style } = this.props;

    if (!item) {
      return null;
    }

    return (
      <div
        key={item.cca2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          ...style,
        }}>
        <span>{item.name}</span>
      </div>
    );
  }
}
