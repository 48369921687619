import ModalDateFactory from '../../../../tcomb/templates/ModalDateFactory';
import { defaultDateFormat } from '../../../../tcomb/utils/options';
import { set, get, cloneDeep } from 'lodash-es';
import moment from 'moment';
import stylesheet from '../../../../tcomb/stylesheets/style';

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

const minDates = {
  today: moment().toDate(),
};

export const option = (fieldConfig, fieldName) => {
  const field = {
    label: fieldConfig.label,
    factory: ModalDateFactory,
    stylesheet: formGroupOuter,

    config: {
      mode: get(fieldConfig, 'config.mode', 'date'),
      format: date => defaultDateFormat(date),
    },
  };

  const confMinDate = get(fieldConfig, 'config.minimumDate');

  if (confMinDate && minDates[confMinDate]) {
    set(field, 'config.minimumDate', minDates[confMinDate]);
  }

  if (fieldName === 'probe_date') {
    set(field, 'config.startDate', true);
  }

  if (fieldName === 'result_on') {
    set(field, 'config.endDate', true);
  }

  return field;
};
