import * as React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import type { Employee as CompanyUser } from '../../flowTypes';
import { get } from 'lodash-es';
import * as constants from '../../styles/style';
import { UserState } from 'farmerjoe-common/lib/flow/types';

type Props = {
  user: CompanyUser;
  isOnline?: boolean;
};

/**
 * Shows an indicator if the user is online or not
 *
 * It treats users that are not in the company as non-online
 */
class OnlineIndicator extends React.Component<Props> {
  render() {
    const { user, isOnline } = this.props;
    // let invited: any = null

    let isOnlineColor = isOnline
      ? constants.FJAPPLEGREEN
      : constants.FJMUTEDLIGHTER;

    if (user.invitation === true) {
      isOnlineColor = constants.FJAPPLEORANGE;
    }

    if (user.state === UserState.Deactivated) {
      isOnlineColor = constants.FJAPPLERED;
    }

    return (
      <div
        style={{
          position: 'absolute',
          bottom: 2,
          right: 2,
          width: 12,
          height: 12,
          borderRadius: 6,
          zIndex: 2,
          borderColor: '#FFF',
          borderWidth: 1,
          backgroundColor: isOnlineColor,
        }}
      />
    );
  }
}

const wrappedOnlineIndicator = firebaseConnect(props => {
  if (!props.user) {
    return [];
  }

  const { user } = props;
  // on firestore we are allowed to use use @ in keys
  // our invitations are the user's email. If we try to do a query for firebase with this, then we get an exception...
  if (String(user.key).includes('@')) {
    return [];
  }
  return [
    {
      path: '/presence/' + props.user.key,
    },
  ];
})(OnlineIndicator);

export default (connect(
  (state, ownProps: any) => {
    const isOnline =
      get(
        state,
        `firebase.data.presence.${ownProps.user.key}.state`,
        'offline',
      ) === 'online';
    return {
      user: ownProps.user,
      isOnline: isOnline,
    };
  },
  () => ({}),
)(wrappedOnlineIndicator) as any as (typeof OnlineIndicator));
