import React from "react";
import { Link } from "react-router-dom";
import { get } from "lodash-es";

import { getUsage } from "farmerjoe-common/lib/requests/billing";
import { SUBSCRIPTION_PLAN_QUOTA_REACHED } from "farmerjoe-common/lib/constants/billing";

import Map from "../Map/Map";
import { EditButton } from "../Common/EditButton";
import Icon from "../Common/Icon";
import NavbarBasic from "../Common/NavbarBasic";
import GlobalUserSearch from "../GlobalUserSearch/GlobalUserSearch";
import { AlertDialog } from "../Dialog/Dialog";
import { Loading } from "../Loading/Loading";

import GroupEmployees from "../../containers/Groups/GroupEmployees";
import CreateGroup from "../../containers/Groups/Form";

import * as constants from "../../styles/style";
import I18n from "../../language/i18n";
import { getCountryName } from "../../utils/Countries";
import type { Group } from "../../flowTypes";
import { getUidToken } from "../../utils/auth";

type Props = {
  loading: boolean;
  group: Group;
  editGroup: (key: any) => any;
  onClose: () => void;
  isFloating: boolean;
  editable: boolean;
  openCompany: string;
};

type State = {
  showCreateForm: boolean;
  showCreateEmployeeDialog: boolean;
  loading: boolean;
  alertDialog: {
    isVisible: boolean;
    title?: string;
    message?: string | React.ReactNode;
  };
};

class GroupComponent extends React.PureComponent<Props, State> {
  state = {
    showCreateForm: false,
    showCreateEmployeeDialog: false,
    loading: false,
    alertDialog: {
      isVisible: false,
      title: "",
      message: "",
    },
  };

  onRight = () => {
    const { group } = this.props;
    this.props.editGroup(group.key);
    this.setState({ showCreateForm: true });
  };

  canEdit() {
    return this.props.editable;
  }

  render() {
    const { group } = this.props;
    const contactName = get(group, "contact.name");
    const contactEmail = get(group, "contact.email");
    const contactPhone = get(group, "contact.tel");

    const marker = {
      key: group.key,
      position: group.position,
      type: "company",
      title: group.name,
    };

    if (!group) {
      return null;
    }

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className="profile-wrapper">
        <NavbarBasic
          title={group.name}
          // leftText={<Icon iconType={'fj'} name="arrow_left" style={{fontSize: 20, color: constants.FJNAVCOLOR}}/>}
          leftButton={this.renderCloseButtonIfNeeded()}
          rightButton={this.renderEditButtonIfEditingAllowed()}
        />
        <div className={"scrollable-content"}>
          <div style={{ height: 150, position: "relative" }}>
            <Map
              key={group.key}
              mapStyles={{}}
              position={group.position}
              filterNotACrop={false}
              zoomedIn={true}
              // navigate={true}
              markers={[marker]}
            />
          </div>

          <div style={{ flex: 1 }}>
            <div
              style={{
                paddingBottom: 20,
                paddingTop: 20,
                backgroundColor: "#FFF",
              }}>
              <div
                style={{
                  backgroundColor: "#FFF",
                  padding: 20,
                  paddingBottom: 0,
                }}>
                <span style={constants.styles.headerText}>{group.name}</span>
                <div style={{ marginTop: 20 }}>
                  {(group as any).group_number
                    ? (
                      <React.Fragment>
                        <span>{(group as any).group_number}</span>
                        <br />
                      </React.Fragment>
                    )
                    : null}

                  <span>{group.street}</span>
                  <br />
                  <span>
                    {group.zip} {group.city}
                  </span>
                  <br />
                  <span>{getCountryName(group.country)}</span>
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#FFF",
                  paddingLeft: 20,
                  marginTop: 10,
                  marginBottom: 10,
                }}>
                {this.renderEmailLine(group.email)}
                {this.renderPhoneLine(group.tel)}
                {this.renderGGNNumber(group.ggn_number)}
                {this.renderQSNumber(group.qs_number)}
                {this.renderNotes(group.notes)}
              </div>

              {contactName || contactEmail || contactPhone
                ? (
                  <div style={{ marginLeft: 20, marginTop: 20 }}>
                    <div style={constants.styles.strong}>
                      {I18n.t("producers.contactPerson")}
                    </div>
                    {contactName && (
                      <div style={{ paddingTop: 5 }}>{contactName}</div>
                    )}
                    {contactEmail && this.renderEmailLine(contactEmail)}
                    {contactPhone && this.renderPhoneLine(contactPhone)}
                  </div>
                )
                : null}
            </div>

            {(group as any).type === "producer" && this.renderEmployees()}
          </div>
        </div>
        {this.state.showCreateForm
          ? (
            <CreateGroup
              show={this.state.showCreateForm}
              type={(group as any).type}
              onClose={() => this.setState({ showCreateForm: false })}
            />
          )
          : null}

        {this.state.showCreateEmployeeDialog
          ? (
            <GlobalUserSearch
              show={this.state.showCreateEmployeeDialog}
              groupType={(group as any).type}
              onClose={() => this.setState({ showCreateEmployeeDialog: false })}
            />
          )
          : null}
      </div>
    );
  }

  renderPhoneLine(phone: string) {
    return (
      <a href={`tel:${phone}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Icon
            name={"ios-call-outline"}
            style={{ fontSize: 18, color: constants.FJNEWGREEN, marginTop: 3 }}
          />
          <span style={{ marginLeft: 5, color: constants.FJNEWGREEN }}>
            {phone}
          </span>
        </div>
      </a>
    );
  }

  renderEmailLine(email: string) {
    if (!email) {
      return null;
    }

    return (
      <a href={`mailto:${email}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}>
          <Icon
            name={"ios-mail-outline"}
            style={{ fontSize: 18, color: constants.FJNEWGREEN, marginTop: 3 }}
          />
          <span style={{ marginLeft: 5, color: constants.FJNEWGREEN }}>
            {email}
          </span>
        </div>
      </a>
    );
  }

  renderGGNNumber(ggn_number: string | undefined) {
    if (!ggn_number) {
      return null;
    }

    return (
      <div
        style={{ backgroundColor: "#FFF", marginTop: 10 }}>
        <span style={{ display: "block" }}>
          {I18n.t("ggn_gln_number")}: {ggn_number}
        </span>
      </div>
    );
  }

  renderQSNumber(qs_number: string | undefined) {
    if (!qs_number) {
      return null;
    }

    return (
      <div
        style={{ backgroundColor: "#FFF", marginTop: 10 }}>
        <span style={{ display: "block" }}>
          {I18n.t("company.qs_number")}: {qs_number}
        </span>
      </div>
    );
  }

  renderNotes(notes: string | undefined) {
    if (!notes) {
      return null;
    }

    return (
      <div
        style={{ backgroundColor: "#FFF", marginTop: 10 }}>
        <span style={constants.styles.muted}>{I18n.t("notes")}:</span>
        <p>{notes}</p>
      </div>
    );
  }

  renderEmployees() {
    const { group } = this.props;
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#fbfbfb",
            borderTopColor: constants.FJMUTEDDARK,
            borderTopWidth: 1,
            alignItems: "center",
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
          }}>
          {this.canEdit() ? (
            <>
              <div style={{ fontSize: 18 }}>{I18n.t("producers.invite")}</div>
              <div
                className="top-bar-button"
                onClick={() => this.onCreateEmployeeDialog()}>
                <Icon name="plus" iconType={"fj"} />
              </div>
            </>
          ) : null}
        </div>

        <div style={{ backgroundColor: "#FFF", margin: 20 }}>
          <GroupEmployees groupId={group.key} />
        </div>
        <AlertDialog
          show={this.state.alertDialog.isVisible}
          onClose={this.closeAlert.bind(this)}
          title={this.state.alertDialog.title}
          children={this.state.alertDialog.message}
          key="alert"
        />
      </div>
    );
  }

  async onCreateEmployeeDialog() {
    const uidToken = await getUidToken();
    this.setState({ loading: true });
    return getUsage(uidToken, this.props.openCompany)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Unexpected error occured! Try again!");
        }
        const content = await response.json();
        if (content.totalUsers >= content.maxAllowedUsers) {
          throw new Error(SUBSCRIPTION_PLAN_QUOTA_REACHED);
        }
        this.setState({ showCreateEmployeeDialog: true });
      })
      .catch(error => {
        this.handleCheckUsageLimitsError(error);
        this.setState({ showCreateEmployeeDialog: false });
      })
      .finally(() => this.setState({ loading: false }));
  }

  private handleCheckUsageLimitsError(error: Error) {
    if (error.message === SUBSCRIPTION_PLAN_QUOTA_REACHED) {
      const message = <div>
        <div> {I18n.t("billing.addMoreSeats")} </div>
        <Link
          key={"billing"}
          to={`/company/${this.props.openCompany}/billing`}
          onClick={this.closeAlert.bind(this)}
        >
          {I18n.t("billing.editSeats")}&#8594;
        </Link>
      </div>;
      this.setState({ showCreateEmployeeDialog: false });
      this.showAlert(I18n.t("billing.addSeats"), message);
      return;
    }

    this.setState({ showCreateEmployeeDialog: false });
    this.showAlert(I18n.t("error"), error.message);
  }

  private showAlert(title: string, message: string | React.ReactNode) {
    this.setState({
      alertDialog: { isVisible: true, title, message },
    });
  }

  private closeAlert() {
    this.setState({
      alertDialog: { isVisible: false },
    });
  }

  private renderEditButtonIfEditingAllowed() {
    if (!this.canEdit()) return null;

    return (<EditButton onClick={this.onRight.bind(this)} />);
  }

  private renderCloseButtonIfNeeded() {
    if (!this.props.isFloating) return null;

    return (
      <div
        className="top-bar-button gray-text"
        onClick={this.props.onClose.bind(this)}>
        <Icon iconType="fa" name="times" style={{ fontSize: 25 }} />
      </div>
    );
  }
}

export default GroupComponent;
