import React from 'react';
import { Loading } from '../../Loading/Loading';
import I18n from '../../../language/i18n';

const ModalConfirm = ({
    receiverCompany,
    userCompany,
    field,
    actions,
    userProfile,
    setCurrentDialog,
    isLoading,
    setIsLoading,
}) => {

  if (isLoading) {
    return <Loading />;
  }

  const onClick = () => {
    setIsLoading(true);
    return actions
      .createNewShareFieldRequest(
        receiverCompany,
        field,
        userCompany,
        userProfile,
      )
      .then(() => {
        setIsLoading(false);
        setCurrentDialog('SUCCESS');
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error while trying to share field: ', error);
        setCurrentDialog('FAILURE');
      });
  };

  return (
    <div>
      <div className='modal-body-content'>
        <p>{I18n.t('fieldSharing.seachDialogueQuestionDialogue', { companyName: receiverCompany.name })}</p>
      </div>
      <div className="d-flex flex-grow-1">
        <button className="ml-auto btn btn-secondary" onClick={() => setCurrentDialog('INIT')}>
          {I18n.t('no')}
        </button>
        <button
          className="btn btn-primary"
          style={{marginLeft: '5px'}}
          onClick={onClick}
        >
          {I18n.t('yes')}
        </button>
      </div>
    </div>
  );
};

export default ModalConfirm;
