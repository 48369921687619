import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { isEqual, throttle } from "lodash-es";
import PropTypes from "prop-types";
import moize from "moize";

import { orderWaitTimesByFieldId } from "farmerjoe-common/lib/selectors/waittimes";
import { filters } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";

import { GoogleMap } from "./Map";
import MapMarkers from "./MapMarkers";
import CurrentLocationMarker from "./CurrentLocationMarker";
import MapControlOverlay from "./MapControlOverlay";
import MarkerWorkers from "./MarkerWorkers";
import MapControl, { CENTER } from "./MapControl";

import {
  appPosToLatLng,
  appPosToMapPos,
  getBounds,
  isValidPosition,
  latLngToAppPos,
  latLngToMapPos,
  mapPosToAppPos,
} from "../../utils/Map";
import { Loading } from "../Loading/Loading";
import { GMLatLngLiteral, Marker as MarkerType } from "../../flowTypes";

const mapOptions = { gestureHandling: "greedy" };

// TODO: improve typings
type Props = {
  onMapRef?: (map: google.maps.Map) => void;
  position?: any;
  zoom?: number;
  userPosition?: any;
  onUpdateView?: (position: GMLatLngLiteral, zoom: number) => any;
  markers?: MarkerType[];
  zoomedIn?: boolean;
  hideSearch?: any;
  filter?: any;
  waitTimes?: any;
  options?: any;
  containerStyle?: any;
  noTabIndex?: boolean;
  hideMarkers?: boolean;
  hideDistanceMarkers?: boolean;
  hideControls?: boolean;
  hideResetFilter?: boolean;
  ignoreFilter?: boolean;
  isMapPage?: boolean;
  onMarkerPress?: (marker: MarkerType) => void;
  displayFilter?: boolean;
  displaySettings?: boolean;
  hideControlOverlay?: boolean;
  fitBounds?: any;
  actions?: any;
  openCompany?: any;
  baseColors?: boolean;
  children?: React.ReactNode;
};
type State = {
  mapRef?: google.maps.Map;
  mapType: google.maps.MapTypeId;
  showsUserLocation: boolean;
  showNotACropOptions: boolean;
  onFocus: boolean;
  didSetCenter: boolean;
  showMarkers: boolean;
  zoom: number;
  center: google.maps.LatLngLiteral;

  openMarker?: MarkerType; // seems it's never assigned
};

class ModalMap extends Component<Props, State> {
  private userChangedView?: boolean;
  private updatingMapPosition?: boolean;
  private fitBounds?: boolean;
  onClick?: (...args: Array<any>) => any;

  static propTypes = {
    position: PropTypes.object,
    markers: PropTypes.array.isRequired,
    displayFilter: PropTypes.bool,
    zoomedIn: PropTypes.bool,
    hideSearch: PropTypes.bool,
    onUpdateView: PropTypes.func,
    zoom: PropTypes.number,
    hideControls: PropTypes.bool,
    hideControlOverlay: PropTypes.bool,
  };

  static defaultProps = {
    fitBoundaries: false,
    displayFilter: true,
    displaySettings: true,
    zoomedIn: false,
    hideSearch: false,
    fitBounds: true,
    hideControls: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...ModalMap.getCenterAndZoom(this.props, true),
      mapType: "satellite" as any,
      showsUserLocation: true,
      showNotACropOptions: false,
      onFocus: false,
      didSetCenter: false,
      showMarkers: false,
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      !isEqual(this.props.position, nextProps.position) ||
      this.props.zoom !== nextProps.zoom ||
      (!isEqual(this.props.userPosition, nextProps.userPosition) &&
        !this.userChangedView)
    ) {
      const centerAndZoom = ModalMap.getCenterAndZoom(nextProps, false);
      this.setState(centerAndZoom);

      const map = this.state.mapRef;

      if (
        map &&
        (map.getZoom() !== centerAndZoom.zoom ||
          !isEqual(
            latLngToMapPos(map.getCenter() as any),
            centerAndZoom.center,
          ))
      ) {
        this.updatingMapPosition = true;
        map.setZoom(centerAndZoom.zoom);
        map.panTo(
          appPosToLatLng(mapPosToAppPos(centerAndZoom.center)) as any,
        );
        this.updatingMapPosition = false;
      }
    }
  }

  componentWillUnmount() {
    if (this.state.mapRef && this.state.mapRef.getDiv()) {
      this.state.mapRef.getDiv().removeEventListener("wheel", this.onUserChangedView);
    }
  }

  static getCenterAndZoom(props, fitBounds) {
    let center = props.position;
    let zoom = props.zoom != null ? props.zoom : 12;
    if (fitBounds && props.fitBounds && props.markers.length) {
      // this will only set the center; fitBounds is called after map is rendered to set the proper zoom level as well
      if (props.fitBounds.getNorthEast) {
        const boundsCenter = props.fitBounds.getCenter();
        if (boundsCenter) {
          center = latLngToAppPos(boundsCenter);
        }
      } else if (window.google) {
        const positions = props.markers.map(marker => marker.position).filter(p => p);
        const boundsCenter = getBounds(positions).getCenter();
        if (boundsCenter) {
          center = latLngToAppPos(boundsCenter);
        }
      }
    }
    if (!isValidPosition(center)) {
      center = props.userPosition;
    }
    if (!isValidPosition(center)) {
      center = { latitude: 0, longitude: 0 };
      zoom = 1;
    }
    return { center: appPosToMapPos(center), zoom };
  }

  onUpdateView = () => {
    if (this.state.mapRef) {
      const center = latLngToMapPos(this.state.mapRef.getCenter() as any);
      const zoom = this.state.mapRef.getZoom() as number;
      if (this.state.zoom !== zoom || !isEqual(center, this.state.center)) {
        // this.setState({
        //   center,
        //   zoom
        // })
        if (this.props.onUpdateView) {
          this.props.onUpdateView(center, zoom);
        }
      }
    }
  };

  onUserChangedView = () => {
    if (!this.updatingMapPosition) {
      this.userChangedView = true;
    }
  };

  throttledOnUpdateView = throttle(this.onUpdateView, 1000);

  render() {
    let {
      markers,
      zoomedIn,
      hideSearch,
      filter: { mapType },
      waitTimes,
      containerStyle,
      noTabIndex,
      children,
      options,
    } = this.props;

    // map type is not always set
    if (!mapType) {
      mapType = "satellite";
    }

    const markerComponents = (this.state.showMarkers && this.state.mapRef)
      ? [
        <MapMarkers
          map={this.state.mapRef as google.maps.Map}
          markers={markers as any}
          waittimes={waitTimes}
          openMarker={this.state.openMarker}
          zoomedIn={zoomedIn}
          onMarkerPress={this.props.onMarkerPress}
          hideMarkers={this.props.hideMarkers}
          hideDistanceMarkers={this.props.hideDistanceMarkers}
          key="map-markers"
          hideControls={this.props.hideControls}
          hideResetFilter={this.props.hideResetFilter}
          ignoreFilter={this.props.ignoreFilter}
          isMapPage={this.props.isMapPage}
          baseColors={this.props.baseColors}
        />,
        <MarkerWorkers key="marker-workers" map={this.state.mapRef} />,
      ]
      : null;

    return (
      <GoogleMap
        mapRef={this.onMapRef}
        mapContainerClassName="map-container"
        mapContainerStyle={this.createContainerStyle(containerStyle)}
        options={options ? { ...mapOptions, options } : mapOptions}
        defaultCenter={this.state.center}
        defaultZoom={this.state.zoom}
        onDrag={this.onUserChangedView}
        onBoundsChanged={this.throttledOnUpdateView}
        onClick={this.onClick}
        mapTypeId={mapType}
        noTabIndex={noTabIndex}
        onMapTypeIdChanged={this.onMapTypeIdChanged}>
        {/* <SpiderfiedMarkerClusterer */}
        {/* basicFormatEvents={true} */}
        {/* spiralFootSeparation={49} */}
        {/* spiralLengthFactor={7} */}
        {/* spiralLengthStart={20} */}
        {/* > */}
        {markerComponents}
        {/* </SpiderfiedMarkerClusterer> */}
        <CurrentLocationMarker />
        {(!this.props.hideControls && !this.props.hideControlOverlay && this.state.mapRef)
          ? (
            <MapControlOverlay
              displayFilter={this.props.displayFilter}
              displaySettings={this.props.displaySettings}
              hideSearch={hideSearch}
              markers={markers}
              map={this.state.mapRef}
            />
          )
          : null}
        {(!this.state.showMarkers && this.state.mapRef)
          ? (
            <MapControl position={CENTER} map={this.state.mapRef}>
              <Loading />
            </MapControl>
          )
          : null}
        {children}
      </GoogleMap>
    );
  }

  onMapRef = (ref: google.maps.Map) => {
    if (!this.state.mapRef) {
      this.setState({ mapRef: ref });
      const map = ref as any as google.maps.Map;

      if (this.props.fitBounds && !this.fitBounds) {
        let bounds;
        if (this.props.fitBounds.getNorthEast) {
          bounds = this.props.fitBounds;
        } else {
          const markerPositions = this.props.markers?.map(
            marker => marker.position,
          ).filter(p =>p);
          if (markerPositions?.length) {
            bounds = getBounds(markerPositions);
          }
        }
        if (bounds) {
          (window as any).google.maps.event.addListenerOnce(
            map,
            "zoom_changed",
            () => {
              if ((map.getZoom() as number) > 20) {
                this.updatingMapPosition = true;
                map.setZoom(20);
                this.updatingMapPosition = false;
              }
            },
          );
          ref.fitBounds(bounds);
        }
        this.fitBounds = true;
      }

      let addedListener = false;
      (window as any).google.maps.event.addListenerOnce(
        map,
        "tilesloaded",
        () => {
          this.setState({ showMarkers: true });

          if (!addedListener && ref.getDiv()) {
            ref.getDiv().addEventListener("wheel", this.onUserChangedView, {
              passive: true,
            });
          }
        },
      );
      if (ref.getDiv()) {
        ref
          .getDiv()
          .addEventListener("wheel", this.onUserChangedView, { passive: true });
        addedListener = true;
      }

      if (this.props.onMapRef) {
        this.props.onMapRef(ref);
      }
    }
  };

  onMapTypeIdChanged = type => {
    this.props.actions.filters(this.props.openCompany, {
      mapType: type,
    });
  };

  createContainerStyle = moize.simple((containerStyle): React.CSSProperties => {
    return { height: "calc(100vh - 60px - 58px)", ...containerStyle };
  });
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
        },
      ),
      dispatch,
    ),
  };
};

const defaultFilter = { search: "", mapType: "satellite" };
const selector = (state) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    // @ts-ignore
    waitTimes: orderWaitTimesByFieldId(state, openCompany),
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
    userPosition: state.userPosition,
  };
};

export default compose<typeof ModalMap>(
  connect(
    selector,
    mapDispatchToProps,
  ),
)(ModalMap);
