import * as React from 'react';
import I18n from '../../language/i18n';
import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';
import type { Company, Employee } from '../../flowTypes';
import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
import { AlertConfirmDialog, AlertDialog } from '../Dialog/Dialog';
import { isString } from 'lodash-es';
import { outputDate } from 'farmerjoe-common';

const DIALOG_CONFIRM_RESULT = 'ok';

type Props = {
  user: Employee;
  actions: {
    sendInvitation: typeof employeeCreators.sendInvitation;
    updateEmployee: typeof employeeCreators.updateEmployee;
    deleteEmployee: (...args: Array<any>) => any;
  };
  company: Company;
};

type State = {
  confirm: Record<string, any> | null;
  alertMessage: string | null;
  alertTitle: string | null;
};

export default class Invite extends React.PureComponent<Props, State> {
  state = {
    confirm: null,
    alertMessage: null,
    alertTitle: null,
  };

  render() {
    const { user } = this.props;

    let invited: any = null;
    if (
      (user.active !== true && !(user as any).accepted_on) ||
      (user.active === false && user.invitation === true)
    ) {
      invited = (
        <span
          style={{
            ...constants.styles.small,
            ...constants.styles.muted,
            ...constants.styles.italic,
            ...{
              marginTop: 5,
              flex: 1,
            },
          }}>
          {I18n.t('employees.invitationSentOn', {
            date: outputDate(user.invited_on),
          })}
        </span>
      );

      if (user.rejected) {
        invited = (
          <div>
            <span
              style={{
                ...constants.styles.small,
                ...constants.styles.muted,
                ...constants.styles.italic,
                ...{
                  marginTop: 5,
                  color: constants.FJAPPLERED,
                },
              }}>
              {I18n.t('employees.invitrationRejectedOn', {
                date: outputDate(user.invited_on),
              })}
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({
                    confirm: {
                      title: I18n.t('invites.resend'),
                      children: I18n.t('invites.resendDoYouReally'),
                      buttons: [
                        { label: I18n.t('cancel'), value: 'cancel' },
                        {
                          label: I18n.t('yes'),
                          value: DIALOG_CONFIRM_RESULT,
                          className: 'btn-danger',
                        },
                      ],
                      onClose: result => {
                        if (result === DIALOG_CONFIRM_RESULT) {
                          const { actions, company } = this.props;
                          actions.sendInvitation(company, user);
                          actions.updateEmployee(company, {
                            ...user,
                            rejected: false,
                          });
                        }
                        this.setState({ confirm: null });
                      },
                    },
                  });
                }}>
                <Icon name="ios-refresh-circle-outline" />{' '}
                {I18n.t('invites.resend')}
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  this.setState({
                    confirm: {
                      title: I18n.t('delete'),
                      children: I18n.t('invites.deleteDoYouReally'),
                      buttons: [
                        { label: I18n.t('cancel'), value: 'cancel' },
                        {
                          label: I18n.t('yes'),
                          value: DIALOG_CONFIRM_RESULT,
                          className: 'btn-danger',
                        },
                      ],
                      onClose: result => {
                        if (result === DIALOG_CONFIRM_RESULT) {
                          const { actions, company } = this.props;
                          actions
                            .deleteEmployee(company.key, user)
                            .catch(message => {
                              if (isString(message)) {
                                this.setState({
                                  alertMessage: message,
                                  alertTitle: I18n.t('error'),
                                });
                              }
                            });
                        }
                        this.setState({ confirm: null });
                      },
                    },
                  });
                }}>
                <Icon name="ios-trash-outline" /> {I18n.t('delete')}
              </button>
            </div>
            <AlertDialog
              show={!!this.state.alertMessage}
              onClose={() =>
                this.setState({ alertMessage: null, alertTitle: null })
              }
              title={this.state.alertTitle}
              children={this.state.alertMessage}
            />
            <AlertConfirmDialog
              {...this.state.confirm}
              show={!!this.state.confirm}
              key={4}
            />
          </div>
        );
      }
    }

    return invited;
  }
}
