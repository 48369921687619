import React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import hoistStatics from 'hoist-non-react-statics';

/**
 * HOC that loads field views for the company
 *
 *
 * @param WrappedComponent
 */
const WithFieldViews = (WrappedComponent) => {
  const FieldViewsLoader = (props) => {
    return <WrappedComponent {...props} />;
  };

  const connectedToFirestore = firestoreConnect((props) => {
    const { openCompany } = props;

    // TODO: extract to farmerjoe-common
    if (openCompany) {
      return [
        {
          collection: 'fieldViews',
          doc: openCompany,
          storeAs: `fieldViews/${openCompany}`,
        },
      ];
    }

    return [];
  })(FieldViewsLoader);

  return hoistStatics(connectedToFirestore, WrappedComponent);
};

export default WithFieldViews;
