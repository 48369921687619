import { flattenDeep, isString, isArray } from 'lodash-es';

export function classes(...classes: Array<any>): string {
  return classes
    .map(c => (isArray(c) ? flattenDeep(c) : c))
    .filter(isString)
    .join(' ');
}

// matches polyfill
let matches = Element.prototype.matches;
if (!matches) {
  matches = // $FlowFixMe
    (Element.prototype as any).matchesSelector || // $FlowFixMe
    (Element.prototype as any).mozMatchesSelector || // $FlowFixMe
    (Element.prototype as any).msMatchesSelector || // $FlowFixMe
    (Element.prototype as any).oMatchesSelector || // $FlowFixMe
    Element.prototype.webkitMatchesSelector ||
    function(this: any, s) {
      // $FlowFixMe
      const matches = (this.document || this.ownerDocument).querySelectorAll(s);
      let i = matches.length;
      while (--i >= 0 && matches.item(i) !== this) {}
      return i > -1;
    };
}

export const matchesSelector = function(el: Node, selector: string) {
  return matches.call(el, selector);
};

export function findAncestor(
  el: Node,
  predicate: string | ((arg0: Node) => boolean),
) {
  let _el: any = el; // $FlowFixMe
  const _predicate =
    typeof predicate === 'string'
      ? e => matchesSelector(e, predicate)
      : predicate;
  while (
    (_el = _el.parentNode) &&
    _el instanceof HTMLElement &&
    !_predicate(_el)
  ) {
    // nothing
  }
  if (!(_el instanceof HTMLElement)) {
    _el = null;
  }
  return _el;
}

export function getScrollableAncestor(el: Node) {
  return (
    findAncestor(el, (el: any) => {
      const style = window.getComputedStyle(el);
      return style.overflowY === 'auto' || style.overflowY === 'scroll';
    }) || document.documentElement
  );
}

export function getScrollableAncestors(el: Node): Array<Node> {
  const ancestors: any[] = [];
  let node = el;
  while (node) {
    node = findAncestor(node, (el: any) => {
      const style = window.getComputedStyle(el);
      return style.overflowY === 'auto' || style.overflowY === 'scroll';
    });
    if (node) {
      ancestors.push(node);
    }
  }
  return ancestors;
}

export function isVisible(el: HTMLElement) {
  return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
}

let horizontalScrollbarHeight;

export function horizontalScrollbarSize(recalc: boolean | null | undefined) {
  if (
    (!horizontalScrollbarHeight && horizontalScrollbarHeight !== 0) ||
    recalc
  ) {
    const scrollDiv = document.createElement('div');

    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';

    document.body.appendChild(scrollDiv);
    horizontalScrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
    document.body.removeChild(scrollDiv);
  }

  return horizontalScrollbarHeight;
}

let verticalScrollbarHeight;

export function verticalScrollbarSize(recalc: boolean | null | undefined) {
  if ((!verticalScrollbarHeight && verticalScrollbarHeight !== 0) || recalc) {
    const scrollDiv = document.createElement('div');

    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '50px';
    scrollDiv.style.height = '50px';
    scrollDiv.style.overflow = 'scroll';

    document.body.appendChild(scrollDiv);
    verticalScrollbarHeight = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
  }

  return verticalScrollbarHeight;
}

export function downloadContent(content, fileName, mimeType) {
  const a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  // $FlowFixMe
  if ((navigator as any).msSaveBlob) {
    // IE10
    (navigator as any).msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName,
    );
  } else if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      }),
    );
    a.setAttribute('download', fileName);
    // $FlowFixMe
    document.body.appendChild(a);
    a.click();
    // $FlowFixMe
    document.body.removeChild(a);
  } else {
    window.location.href =
      'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
  }
}
