import * as React from 'react';
import { useState } from 'react';
import { FJAPPLERED, FJWHITE } from '../../styles/style';
import Icon from '../../components/Common/Icon';
import I18n from '../../language/i18n';
import './style.css';

import { ActivationViewDialog } from './ActivationViewDialog';

export function ActivationCheckLine() {
  const [show, setShow] = useState(false);
  return <>
    <a
      key={'activation-line'}
      style={{
        backgroundColor: FJAPPLERED,
      }}
      onClick={e => {
        e.preventDefault();
        setShow(true);
      }}
      className={
        'd-flex flex-row justify-content-center align-items-center fj-pointer fj-activation-line'
      }>
      <div
        className={'d-flex flex-row justify-content-center align-items-center'}>
        <Icon
          name={'exclamation-triangle'}
          iconType={'fal'}
          style={{ color: FJWHITE, marginRight: 10, fontSize: 20 }}
        />
        <div>
          <div style={{ color: FJWHITE }}>
            {I18n.t('activation.accountIsUnverified')}{' '}
            {I18n.t('activation.pleaseCheckMail')}
          </div>
        </div>
      </div>
    </a>
    <ActivationViewDialog
      key={'activation-view-dialog'}
      show={show}
      onClose={() => {
        setShow(false);
      }}
    />
  </>;
}
