import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';

import { searchForFieldAmongTheUniverse } from 'farmerjoe-common/lib/selectors/fields';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { orderWaitTimesByFieldId } from 'farmerjoe-common/lib/selectors/waittimes';
import { editField } from 'farmerjoe-common/lib/actions/field';
import { age } from 'farmerjoe-common/lib/utils/Crop';

import Icon from '../Common/Icon';
import Waittime from '../WaitTime/Waittime';
import Badge from '../FieldMark/Badge';

import * as constants from '../../styles/style';
import I18n from '../../language/i18n';
import './style.css';

// import type { Field, WaitTime } from 'farmerjoe-common'

const styles = {
  info: {
    ...constants.styles.extraSmall,
    color: constants.FJMUTED,
    marginRight: 15,
  },
};

type Props = {
  field: any; // Field
  waitTimes: any[]; // WaitTime[]
  leftButton: React.ReactNode;
  onClick: () => {};
  rightButton: React.ReactNode;
};

type State = {
  open: boolean;
  width: null | number;
};

class Navbar extends React.PureComponent<Props, State> {
  state = { open: false, width: null };

  static defaultProps = {
    showEdit: true,
  };

  render() {
    const { field, waitTimes } = this.props;

    if (!field) {
      return null;
    }

    const title = field.name;
    const { analysesCount } = this.props.field.activeCrop;

    const waitTime = waitTimes[field.key]
      ? (
      <Waittime
        key={'waittime'}
        iconStyle={{ fontSize: 11, padding: 0 }}
        allowFontScaling={false}
        waitTimes={waitTimes[field.key]}
      />
        )
      : null;

    let subtitle = [
      <span key="ha" style={styles.info}>
        {field.size || 0} ha
      </span>,
      <span key="sown_on" style={{ ...styles.info, marginRight: 20 }}>
        {I18n.t('x_days', { days: age(field.activeCrop.sown_on) })}
      </span>,
      <Icon
        key="info"
        name={'ios-information-circle-outline'}
        style={{
          marginRight: 20,
          fontSize: 16,
          marginTop: 3,
          color: constants.FJMUTED,
        }}
      />,
    ];

    if (analysesCount) {
      subtitle = [
        ...subtitle,
        <div key="analysis">
          <span style={styles.info}>
            <Icon
              name="analysis"
              iconType={'fj'}
              style={{ fontSize: 10, color: constants.FJMUTED }}
            />{' '}
            {analysesCount}
          </span>
        </div>,
      ];
    }

    if (waitTime) {
      subtitle = [...subtitle, waitTime];
    }
    return (
      <div
        className="page-card-top-bar-container field-navbar"
        style={{ ...constants.styles.navBar }}>
        {this.props.leftButton}
        <div
          className={'page-card-top-bar-title-container d-flex flex-column'}
          onClick={() => {
            this.setState({ open: !this.state.open });
            this.props.onClick();
          }}>
          <div
            className={
              'page-card-top-bar-title d-flex flex-row justify-content-center align-items-center'
            }>
            {this.renderMark()}

            <span className={'page-card-top-bar-title-text'}>{title}</span>
          </div>
          <div
            className={
              'page-card-top-bar-subtitle d-flex flex-row justify-content-center align-items-center'
            }>
            {subtitle}
          </div>
        </div>
        {this.props.rightButton}
      </div>
    );
  }

  renderMark() {
    const mark = this.props.field.activeCrop.mark;
    if (mark) {
      const reason = get(mark, 'reason');
      return (
        <span className="badge-container" title={reason || ''}>
          <Badge mark={mark} />
        </span>
      );
    } else {
      return null;
    }
  }
}

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompanyId = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const field =
    ownProps.field || searchForFieldAmongTheUniverse(state, openCompanyId, user.uid, openField);
  return {
    field,
    waitTimes: orderWaitTimesByFieldId(state, field.company_id),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editField,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  selector,
  mapDispatchToProps,
)(Navbar as any);
