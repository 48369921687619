import * as React from 'react';
import * as constants from '../../styles/style';
import I18n, { tWithComponent } from '../../language/i18n';
import Modal from 'react-bootstrap-modal';
import type { Analysis } from '../../flowTypes';
import { noLabelingReminder } from 'farmerjoe-common/lib/utils/Analyses';

type Props = {
  children?: React.ReactNode;
  analysis: Analysis;
};

export default function OrderSuccess({
  analysis: { labSelected: lab, formConfig },
}: Props) {
  const { styles } = constants;
  return (
    <Modal.Body onClick={e => e.stopPropagation()} key="body">
      <div style={{ flex: 1 }}>
        <div
          style={{
            alignItems: 'center',
            marginTop: 70,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <div
            style={{
              ...constants.styles.strong,
              ...constants.styles.big,
              ...constants.styles.alignCenter,
              color: constants.FJAPPLERED,
              marginBottom: 20,

            }}>
            {noLabelingReminder(formConfig) ? null : I18n.t('analysis.dontForgetToLabelProbe')}
          </div>
        </div>

        <div style={{ marginTop: 30 }}>

          <div
            style={{
              ...styles.strong,
              ...styles.alignCenter,
              ...{ fontSize: 20, marginBottom: 20 },
            }}>
            {tWithComponent('analysis.thankYouForOrder', [
              <span
                key="thankyou"
                style={{
                  ...styles.strong,
                  ...styles.alignCenter,
                  ...{ fontSize: 20 },
                }}>
                {I18n.t('analysis.thankYou')}
              </span>,
            ])}
          </div>
        </div>

        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}>
          <img
            style={{ width: lab.logo.width, height: lab.logo.height }}
            src={lab.logo.uri}
            alt=""
          />
        </div>
      </div>
    </Modal.Body>
  );
}
