import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { firestoreRef } from "redux-firestore/lib/utils/query";

import { filters } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { openField } from "farmerjoe-common/lib/actions/field";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { getLatestActivitiesFilter } from "farmerjoe-common/lib/selectors/filters";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { OrderByDirection } from "farmerjoe-common/lib/flow/types";
import { getLatestActivityForUser } from "farmerjoe-common/lib/utils/firestoreRedux/Comments";
import { isAdmin, canDo } from "farmerjoe-common/lib/utils/User";

import { Loading } from "../../components/Loading/Loading";

import { keyBy, map, orderBy } from "lodash-es";
import LatestActivitiesList from "../../components/LatestActivities/LatestActivitiesList";
import withRouter from "../../components/Router/withRouter";

const limit = 10;

/* eslint-disable no-script-url */

type Props = {
  firebase?: any;
  company?: any;
  uid: string;
  history?: string[];
  openCompany?: any;
  filters?: any;
  actions?: any;
  companyId?: string;
  tabLabel?: any;
  myCompanyProfile?: any;
};

type State = {
  lastItem: any;
  loading: boolean;
  loadingMore: boolean;
  items: any[];
  itemsCache: any;
  range: any;
  period: any;
  modalVisible: boolean;
  onlyImages: boolean;
  hasMore: boolean;
};

class LatestActivitiesContainer extends Component<Props, State> {
  state: State = {
    lastItem: null,
    loading: true,
    loadingMore: false,
    items: [],
    itemsCache: {},
    range: null,
    period: null,
    modalVisible: false,
    onlyImages: false,
    hasMore: false,
  };

  listeners: any = [];

  componentDidMount() {
    const { company } = this.props;
    const { lastItem } = this.state;

    if (!lastItem && company) {
      this.loadMoreComments(true);
    }
  }

  componentWillUnmount() {
    this.clearListeners();
  }

  clearListeners() {
    this.listeners.forEach((listener: any) => {
      listener && listener();
    });
    this.listeners = [];
  }

  componentDidUpdate(prevProps) {
    const { company } = this.props;
    if (
      company &&
      (prevProps.filters !== this.props.filters ||
        prevProps.uid !== this.props.uid)
    ) {
      this.loadMoreComments(true);
    }
  }

  loadMoreComments(reset = false) {
    const { firebase, company, uid } = this.props;
    const { lastItem } = this.state;

    if (reset) {
      this.setState({ loading: true, items: [], itemsCache: {} });
      this.clearListeners();
    }

    const callback = querySnap => {
      const docs = querySnap.docs.map(doc => doc.data());
      const docsObject = keyBy(docs, "key");

      const itemsCache = { ...this.state.itemsCache, ...docsObject };

      querySnap.docChanges().forEach((change) =>  {
        // Remove a deleted item from the object
        if (change.type === "removed") {
          const removedDoc = change.doc.data();
          delete itemsCache[removedDoc.key];
        }
      });

      this.setState({
        hasMore: true,
        loading: false,
        loadingMore: false,
        itemsCache: itemsCache,
        items: orderBy(
          map(itemsCache, value => value),
          item => {
            try {
              return item.created.toDate();
            } catch(e) {
              return null;
            }
          },
          [OrderByDirection.Desc],
        ),
      });

      if (docs[docs.length - 1]) {
        this.setState({ lastItem: docs[docs.length - 1] });
      }

      if (docs.length < limit) {
        this.setState({ hasMore: false });
      }
    };

    const onError = error => {
      console.log(error);
      this.setState({ loading: false });
    };

    const query = getLatestActivityForUser(company.key, uid, lastItem, reset, limit);
    const firestoreQuery = firestoreRef(firebase, query);
    this.listeners.push(firestoreQuery.onSnapshot(callback, onError));
  }

  render() {
    const { items, loading, loadingMore } = this.state;
    const { company } = this.props;

    if (!company) {
      return <Loading />;
    }

    return (
      <div style={{ flex: 1, display: "flex" }}>
        {loading
          ? (
            <Loading />
          )
          : (
            <div style={{ flex: 1, display: "flex" }}>
              <LatestActivitiesList
                onEndReached={() => {
                  if (
                    this.state.hasMore &&
                  !this.state.loading &&
                  !this.state.loadingMore
                  ) {
                    this.setState(
                      {
                        loadingMore: true,
                      },
                      () => this.loadMoreComments(),
                    );
                  }
                }}
                items={items}
                loadingMore={loadingMore}
                onCommentPress={comment => {
                  this.props.actions.openField(comment.field_id);
                  this.props.history?.push(
                    `/company/${this.props.openCompany}/latest/${comment.field_id}`,
                  );
                }}
                showCropVariety={isAdmin(this.props.myCompanyProfile) || canDo("get.variety", "crop", this.props.myCompanyProfile)}
              />
            </div>
          )}
      </div>
    );
  }
}

const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const filters = getLatestActivitiesFilter(state, openCompany);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  return {
    company,
    filters,
    openCompany,
    browsingGroup,
    myCompanyProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
          openField,
        },
      ),
      dispatch,
    ),
  };
};;

export default compose<typeof LatestActivitiesContainer>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    return [];
  }),
  withRouter,
)(LatestActivitiesContainer);
