import React from "react";
import { SUBSCRIPTION_FEATURES } from "./constants";
import Icon from "../Common/Icon";
import I18n from "../../language/i18n";

const getUsersLabel = (users: number, subscription) => {
  const subscriptionKey = subscription.key;
  switch(subscriptionKey) {
  case "2023_team":
    return `${users}+ ${I18n.t("billing.seats")}`;
  case "2023_enterprise":
    return I18n.t("billing.unlimitedSeats");
  default:
    return `${users} ${I18n.t("billing.seats")}`;
  }
};

const getSubscriptionPrice = (subscription, isCurrent, currentSubscriptionInReview) => {
  if (subscription.key === "2023_enterprise" && currentSubscriptionInReview) {
    return (
      <div>
        <div style={{
          color: "rgb(138,180,88)",
          fontSize: "32px",
        }}>
        </div>
      </div>
    );
  }

  if (subscription.key === "2023_enterprise" && isCurrent && currentSubscriptionInReview) {
    return (
      <div>
        <div style={{
          color: "rgb(138,180,88)",
          fontSize: "32px",
        }}>
        </div>
      </div>
    );
  }

  if (subscription.key === "2023_enterprise" && isCurrent) {
    return (
      <div>
        <div style={{
          color: "rgb(138,180,88)",
          fontSize: "32px",
        }}>
        </div>
      </div>
    );
  }

  if (subscription.key === "2023_enterprise") {
    return (
      <div>
        <div style={{
          color: "rgb(138,180,88)",
          fontSize: "32px",
        }}>
          <Icon
            name="md-chatbubbles"
            iconType={"ion"}
          />
        </div>
      </div>
    );
  }
  
  if (subscription.key === "2023_team") {
    return (
      <div>
        <div style={{
          color: "rgb(138,180,88)",
          fontSize: "32px",
        }}>
          {subscription.price}&#8364;
        </div>
        <div
          style={{
            fontSize: "12px",
            marginBottom: "20px",
          }}
        >
          {I18n.t("billing.perSeatPerMonth")}  <br />
          {`(${I18n.t("billing.netPrice")})`}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{
        color: "rgb(138,180,88)",
        fontSize: "32px",
      }}>
        {subscription.price}&#8364;
      </div>
      <div
        style={{
          fontSize: "12px",
          marginBottom: "20px",
        }}
      >
        {I18n.t("billing.perSeatPerMonth")}
      </div>
    </div>
  );
};

const SubscriptionPlan = (props) => {
  const { subscriptionPlan, isCurrent, style, onSelect, getUpgradeLabel, showLabel, currentSubscriptionInReview } = props;

  const features = SUBSCRIPTION_FEATURES[subscriptionPlan.key].map((feature, idx) => {
    return (
      <li key={idx}>
        <div style={{display: "flex"}}>
          <span
            style={{
              color: "rgb(138,180,88)",
              marginRight: "5px",
              flexShrink: 0,
            }}
          >
      &#10004;
          </span>
          <div>
            {feature}
          </div>
        </div>
      </li>
    );
  });
  return (
    <div
      style={{
        border: "1px solid rgb(138,180,88)",
        borderRadius: "20px",
        padding: "20px",
        position: "relative",
        margin: "0 1em",
        display: "grid",
        ...style,
      }}
    >
      {isCurrent && !currentSubscriptionInReview ? (
        <div style={{
          position: "absolute",
          color: "rgb(138,180,88)",
          fontSize: "32px",
          top: -25,
          left: "30%",
          background: "#fff",

        }}>
          {I18n.t("current")}
        </div>
      )
        : null
      }
      <div style={{
        color: "rgb(138,180,88)",
        fontWeight: "bold",
      }}>
        {subscriptionPlan.name}
      </div>
      <div
        style={{
          fontWeight: "bold",
          fontSize: "12px",
          marginBottom: "20px",
        }}
      >
        { getUsersLabel(subscriptionPlan.users, subscriptionPlan) }
      </div>
      { getSubscriptionPrice(subscriptionPlan, isCurrent, currentSubscriptionInReview) }
      {!isCurrent && showLabel && !currentSubscriptionInReview ? (
        <div>
          <div style={{
            display: "flex",
            justifyContent: "center",
          }}>
            <button
              style={{
                textTransform: "uppercase",
                cursor: "pointer",
              }}
              className={"btn btn-primary"}
              onClick={onSelect}
            >
              {getUpgradeLabel()}
            </button>
          </div>
        </div>
      )
        : <div></div>
      }
      <div style={{}}>
        <ul style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
        }}>
          {features}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
