import * as React from 'react';
import * as constants from '../../styles/style';

type Props = {
  text: any;
  type?: 'success' | 'alert';
  boxStyle?: Record<string, any>;
};

/**
 * Display a red alert box/div on the screen
 */
export default class AlertBox extends React.PureComponent<Props> {
  static defaultProps = {
    type: 'alert',
  };

  render() {
    const { text, type, boxStyle } = this.props;

    const color =
      type === 'success' ? constants.FJAPPLEGREEN : constants.FJAPPLERED;
    let content: any = null;

    if (React.isValidElement(text)) {
      content = <div style={{ padding: 5 }}>{text}</div>;
    } else {
      content = (
        <div
          style={{
            color: constants.FJWHITE,
            textAlign: 'center',
            fontWeight: 'bold',
            padding: 10,
          }}>
          {text}
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: color, margin: 10, ...boxStyle }}>
        {content}
      </div>
    );
  }
}
