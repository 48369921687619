import React from "react";

import { get } from "lodash-es";
import t from "tcomb-form";

import I18n from "../../language/i18n";
import Icon from "../../components/Common/Icon";

import firebase from "../../data/firebase";
import fbase from "firebase/app";


import { Loading } from "../../components/Loading/Loading";
import ImagePicker from "../../components/ImagePicker/ImagePicker";
import { classes } from "../../utils/dom";
import { AlertDialog } from "../../components/Dialog/Dialog";

const Component = t.form.Component;

type State = t.ComponentState & {
  imageUploaded: boolean;
  imageObj: any;
  alertTitle?: null | string;
  alertMessage?: null | string;
};

class tImage extends Component<t.ComponentProps, State> {
  state = {
    imageUploaded: false,
    imageObj: {},
    alertTitle: null,
    alertMessage: null,
  };

  fileRef: fbase.storage.Reference | null = null;

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.imageUploaded !== nextState.imageUploaded ||
      this.state.imageObj ||
      nextState.imageObj
    ) {
      return true;
    }

    return super.shouldComponentUpdate(nextProps, nextState, void 0);
  }

  deleteImage() {
    this.setState({
      imageUploaded: false,
      imageObj: {},
    });

    if (this.props.onChange) this.props.onChange(null);

    this.deleteFileRef();
  }

  deleteFileRef() {
    if (this.fileRef) {
      this.fileRef
        .delete()
        .then(() => {})
        .catch(e => {
          console.log(e);
        });
    }
  }

  saveImage(image) {
    const {
      options: {
        config: { company_id },
      },
    } = this.props;

    const fileName = image.name;

    this.deleteFileRef();

    this.setState((prevState, props) => ({
      imageUploaded: false,
      imageObj: {},
    }));

    const storageRef = firebase.storage().ref();

    this.fileRef = storageRef.child(`analysis/${company_id}/${fileName}`);

    this.fileRef
      .put(image.image)
      .then(snap => {
        return this.fileRef?.getDownloadURL().then(downloadURL => {
          const imageObj = {
            imagePath: downloadURL,
            ref: snap.metadata.fullPath,
          };

          // Image is not uploaded
          this.setState({
            imageUploaded: true,
            imageObj,
          });

          if (this.props.onChange) this.props.onChange(imageObj);
        });
      })
      .catch(err =>
        this.setState({
          alertTitle: I18n.t("uploadError"),
          alertMessage: I18n.t("theImageCouldntBeUploaded", { error: err }),
        }),
      );
  }

  getTemplate() {
    return locals => {
      let { imageObj, imageUploaded } = this.state;

      const { value } = locals;

      let imagePath = get(imageObj, "imagePath");

      const error = locals.hasError;

      if (!imagePath && get(value, "imagePath")) {
        imageUploaded = true;
        imagePath = value.imagePath;
      }

      let image: any = null;
      if (imagePath) {
        image = (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <img
              style={{
                display: "flex",
                margin: 15,
                maxWidth: 300,
                maxHeight: 200,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
              src={imagePath}
              alt=""
            />
          </div>
        );
        if (!imageUploaded) {
          image = (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: 15,
              }}>
              <img
                style={{
                  display: "flex",
                  maxWidth: 300,
                  maxHeight: 200,
                  justifyContent: "center",
                  filter: "blur(3px)",
                }}
                src={imagePath}
                alt=""
              />
              <Loading />
            </div>
          );
        }
      }

      return (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <ImagePicker
              onPick={this.saveImage.bind(this)}
              cropping={true}
              width={300}
              height={400}
              imageQuality={0.5}>
              <button
                className={classes(
                  "btn",
                  error ? "btn-danger" : "btn-primary",
                )}>
                <Icon
                  name="ios-camera-outline"
                  style={{
                    marginRight: 10,
                    height: 25,
                    lineHeight: "21px",
                    fontSize: 35,
                    paddingTop: 5,
                  }}
                />
                {I18n.t("uploadPhoto")}
              </button>
            </ImagePicker>
          </div>
          {image}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            {imageUploaded
              ? (
                <div style={{ marginLeft: 5 }}>
                  <button
                    className={"btn btn-danger"}
                    onClick={() => {
                      this.deleteImage();
                    }}>
                    <Icon
                      name="ios-close-outline"
                      style={{
                        marginRight: 10,
                        lineHeight: "30px",
                        height: 25,
                        fontSize: 35,
                      }}
                    />
                    {I18n.t("deletePhoto")}
                  </button>
                </div>
              )
              : null}
          </div>
          <AlertDialog
            show={!!this.state.alertMessage}
            onClose={() =>
              this.setState({ alertMessage: null, alertTitle: null })
            }
            title={this.state.alertTitle}
            children={this.state.alertMessage}
          />
        </div>
      );
    };
  }
}

export default tImage;
