import { get } from 'lodash-es';
import { UserObj } from 'farmerjoe-common/lib/utils/User';

export const updateKey = keyObject => {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const state = getState();
    const modifiedBy = get(state, 'firebase.auth');
    const db = firebase.firestore();

    const object = {
      ...keyObject,
      modified_by: UserObj(modifiedBy),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };

    return db
      .collection('apiKeys')
      .doc(keyObject.key)
      .update(object);
  };
};
