import * as React from 'react';
import I18n from '../../language/i18n';
import { FJWHITE } from '../../styles/style';
import AlertBox from '../Common/AlertBox';

type Props = {
  type: 'employee' | 'analysis' | 'invite';
};

export const NotActivatedWarning = ({ type }: Props) => {
  const warnings = {
    employee: `${I18n.t('activation.accountIsUnverified')} ${I18n.t(
      'activation.cantAddUsers',
    )} `,
    analysis: `${I18n.t('activation.accountIsUnverified')} ${I18n.t(
      'activation.cantOrderAnalyses',
    )} `,
    invite: `${I18n.t('activation.accountIsUnverified')} ${I18n.t(
      'activation.cantAcceptInvitations',
    )} `,
  };

  return (
    <div>
      <AlertBox
        boxStyle={{ margin: 0 }}
        text={
          <div
            style={{
              padding: 10,
              color: FJWHITE,
            }}>
            {warnings[type]}
          </div>
        }
        type={'alert'}
      />
    </div>
  );
};
