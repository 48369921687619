import React from 'react';

type EmployeeInfoBalloonProps = {
  user: {
    name: string;
    phoneNumber: string;
    email: string;
  };
};

const EmployeeInfoBalloon = (props: EmployeeInfoBalloonProps) => {
  const { user: { name, phoneNumber, email }} = props;

  const info = [name, phoneNumber, email].map((value, idx) => {
    return <DataRow value={value} key={idx} />;
  });

  return (
    <div
      style={{
        display: 'inline-block',
        background: 'white',
        padding: '5px',
        borderRadius: '10px',
        borderColor: '#c8c8c8',
        borderWidth: '0.5px',
      }}
    >
      {info}
    </div>
  );
};

type DataRowProps = {
  value: any;
};

const DataRow = (props: DataRowProps) => {
  const { value } = props;
  return (
    <div
      style={{
        textAlign: 'left',
        margin: '5px',
      }}
    >
      <span>{value}</span>
    </div>
  );
};

export default EmployeeInfoBalloon;
