import * as React from 'react';
import Icon from '../Common/Icon';
import { FJBROWN } from '../../styles/style';
import I18n from '../../language/i18n';

type Props = {
  passwordType: boolean;
  onPress: (value: boolean) => any;
};

class ShowHidePassword extends React.PureComponent<Props> {
  render() {
    const { passwordType } = this.props;
    return (
      <div
        title={
          passwordType
            ? I18n.t('password.showPassword')
            : I18n.t('password.hidePassword')
        }
        style={{
          position: 'absolute',
          right: 15,
          top: 5,
          cursor: 'pointer',
          color: FJBROWN,
        }}
        onClick={() => {
          this.props.onPress(!passwordType);
        }}>
        <Icon name={passwordType ? 'eye-slash' : 'eye'} iconType={'fa'}/>
      </div>
    );
  }
}

export default ShowHidePassword;
