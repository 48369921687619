import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import I18n from '../../language/i18n';
import NoResults from '../Common/NoResults';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { filters } from 'farmerjoe-common/lib/actions/actions';

type Props = {
  openCompany?: string;
  actions?: Record<string, any>;
  tabLabel?: string;
};

function NoResultsResetFilter({ openCompany, actions }: Props) {
  return (
    <div style={{ flex: 1 }}>
      <NoResults text={I18n.t('noSearchResults')} />
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary"
          onClick={() =>
            actions?.filters(openCompany, {
              search: '',
              showCrops: [0, 1, 2],
            })
          }
          style={{ minWidth: 250, borderRadius: 25, marginTop: 10 }}>
          {I18n.t('resetFilter')}
        </button>
      </div>
    </div>
  );
}

export default compose<typeof NoResultsResetFilter>(
  connect(
    (state: any) => ({
      openCompany: getOpenCompanyId(state),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          filters,
        },
        dispatch,
      ),
    }),
  ),
)(NoResultsResetFilter);
