import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from '../../language/i18n';
import { AlertConfirmDialog } from '../Dialog/Dialog';
import { classes } from '../../utils/dom';

type Props = {
  buttonText: string;
  alertTitle: string;
  alertMessage: string;
  onDelete?: (...args: Array<any>) => any;
  className?: string;
};

type State = {
  showDialog: boolean;
};

export default class DeleteButton extends Component<Props, State> {
  static propTypes = {
    buttonText: PropTypes.string,
    alertTitle: PropTypes.string,
    alertMessage: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
  };

  static get defaultProps(): Props {
    return {
      buttonText: I18n.t('delete'),
      alertTitle: I18n.t('deleteElement'),
      alertMessage: I18n.t('areYouSureYouWantToDeleteElement'),
    };
  }

  state = {
    showDialog: false,
  };

  render() {
    const { className } = this.props;
    return [
      <button
        key={0}
        className={classes('btn btn-danger', className)}
        onClick={() => this.setState({ showDialog: true })}>
        {this.props.buttonText}
      </button>,
      <AlertConfirmDialog
        key={1}
        show={this.state.showDialog}
        onClose={reason => {
          if (reason === 'yes') {
            this.delete();
          }
          this.setState({ showDialog: false });
        }}
        title={this.props.alertTitle}>
        {this.props.alertMessage}
      </AlertConfirmDialog>,
    ];
  }

  delete() {
    if (this.props.onDelete) this.props.onDelete();
  }
}
