import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash-es";
import { firestoreConnect } from "react-redux-firebase";
import { bindActionCreators, compose } from "redux";
import t from "tcomb-form";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { UserObj } from "farmerjoe-common/lib/utils/User";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { getEditGroupId, getGroup } from "farmerjoe-common/lib/selectors/groups";
import { editGroup, openGroup } from "farmerjoe-common/lib/actions/group";
import {
  getCompanyGroupProfileForLoggedInUser,
  getGroupUsers,
} from "farmerjoe-common/lib/selectors/user";
import withGeocoderFilledProps from "farmerjoe-common/lib/tcomb/formAddons/withGeocoderFilledProps";
import withAutoFilledPhoneNumber from "farmerjoe-common/lib/tcomb/formAddons/withAutoFilledPhoneNumber";
import { GroupType } from "farmerjoe-common/lib/flow/types";

import "./Form.css";
import DeleteButton from "../../components/Common/DeleteButton";
import Dialog, { AlertDialog } from "../../components/Dialog/Dialog";
import ModalMap from "../../components/Map/ModalMap";
import PinPositionMarker from "../../components/Map/PinPositionMarker";
import PolygonAndMarkerPlacementMapDialog from "../../components/Map/PolygonAndMarkerPlacementMapDialog";
import withRouter from "../../components/Router/withRouter";

import groupModel from "../../tcomb/models/group";
import { numberTransformer } from "../../tcomb/transformers/transformers";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import { appPosToLatLng, isValidPosition } from "../../utils/Map";

const styles = constants.styles;

const defaultGroup = {
  name: "",
  group_number: "",
  email: "",
  street: "",
  zip: "",
  city: "",
  country: "",
  tel: "",
  position: {
    latitude: null,
    longitude: null,
  },
  contact: {
    name: "",
    email: "",
    isRealUser: false,
  },
};

interface Props {
  group?: any;
  actions?: any;
  firebase?: any;
  auth?: any;
  company?: any;
  users?: any;
  editMode?: any;
  history?: any[];
  show?: any;
  onClose?: () => any;
  type?: GroupType;
}

interface State {
  country?: any;
  alertMessage?: string | null;
  alertTitle?: string | null;
  position?: any;
  modalVisible?: boolean;
  key?: string;
}

class FormGroup extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object,
  };

  static defaultProps = {
    group: defaultGroup,
  };

  private groupFormRef = React.createRef();

  constructor(props, context) {
    super(props, context);

    const { myCompanyProfile, type } = props;

    const state = {
      type,
      ...this.props.group,
    };

    if (myCompanyProfile.country) {
      state.country = myCompanyProfile.country;
    }

    this.state = state;
  }

  componentWillUnmount(): void {
    this.props.actions.editGroup(null);
  }

  /**
   * Handle saving when the user clicks the "save" button
   */
  async onRight() {
    let value = (this.groupFormRef.current as any).getValue();
    let {
      firebase,
      auth,
      company,
      group,
      editMode,
    } = this.props;

    // If value is null, then the form has errors
    if (value === null) {
      // Alert.alert(I18n.t('please_correct_your_entry'))
      this.setState({
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    // structs come out as Struct custom objects which firestore doesn't like so turn them into plain objects
    value = JSON.parse(JSON.stringify(value));

    group = {
      ...group,
      ...value,
      name: value.name.trim(),
    };

    const db = firebase.firestore();
    const ref = db.collection("groups").doc();
    const batch = db.batch();

    if (editMode) {
      group = {
        ...group,
        modified_by: UserObj(auth),
        modified: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const groupRef = firebase
        .firestore()
        .collection("groups")
        .doc(group.key);

      batch.update(groupRef, group);
    } else {
      group = {
        ...group,
        key: ref.id,
        company_id: company.key,
        created_by: UserObj(auth),
        created: firebase.firestore.FieldValue.serverTimestamp(),
        modified: firebase.firestore.FieldValue.serverTimestamp(),
      };

      batch.set(ref, group);
    }

    batch
      .commit()
      .catch(e => {
        console.log(e);
      });

    if (editMode) {
      this.props.actions.editGroup(null);
      this.goBack();
      return;
    }

    this.props.actions.editGroup(null);
    this.props.actions.openGroup(group.key);
    this.props.history?.push(
      `/company/${company.key}/${group.type}/${group.key}`,
    );
    this.goBack();
  }

  onChange(value) {
    this.setState({
      ...this.state,
      ...value,
    });
  }

  onPolygonChange({ polygon, areaSize, center }) {
    this.setState({
      position: center,
    });
  }

  goBack() {
    this.props.actions.editGroup(null);
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    const { show, onClose, group, type } = this.props;
    const isProducer = type === "producer";
    const editing = !!get(group, "key");
    const box = {
      ...styles.box,
      paddingLeft: 0,
      paddingRight: 0,
    };

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={
          editing
            ? I18n.t(`${isProducer ? "producers" : "traders"}.edit`)
            : I18n.t(`${isProducer ? "producers" : "traders"}.add`)
        }
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              onClick={this.goBack.bind(this)}>
              {I18n.t("cancel")}
            </button>
            <button
              className="btn btn-primary"
              onClick={this.onRight.bind(this)}>
              {I18n.t("save")}
            </button>
          </div>
        }
        dialogClassName="producer-form-dialog"
        className="form">
        <div style={{ flex: 1, flexGrow: 1 }}>
          <div style={{ flex: 1, flexGrow: 1 }}>
            <div>
              <div style={{ ...box }}>
                {this.renderGroupForm()}
                {editing ? (
                  <div
                    className={"d-flex flex-row justify-content-center"}>
                    <DeleteButton
                      onDelete={() => {
                        if (this.props.onClose) this.props.onClose();
                        const { firebase, actions } = this.props;
                        const db = firebase.firestore();
                        actions.editGroup(null);
                        actions.openGroup(null);

                        const batch = firebase.firestore().batch();
                        const groupRef = db
                          .collection("groups")
                          .doc(this.state.key);

                        // mark the company for deletion
                        batch.delete(groupRef);

                        batch.commit();
                      }}
                      buttonText={I18n.t(`${isProducer ? "producers" : "traders"}.delete`)}
                      alertTitle={I18n.t(`${isProducer ? "producers" : "traders"}.delete`)}
                      alertMessage={I18n.t(`${isProducer ? "producers.doYouWantToDeleteProducer" : "traders.doYouWantToDelete"}`)}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
        >
          {this.state.alertMessage}
        </AlertDialog>
      </Dialog>
    );
  }

  private renderGroupForm() {
    const { type } = this.props;
    return <div style={{ padding: 20 }}>
      <GroupForm
        ref={this.groupFormRef}
        type={groupModel.model()}
        options={() =>
          groupModel.options({
            PolygonComponent: this.renderPolygonComponent(),
            type: type,
          })
        }
        value={this.state}
        onChange={this.onChange.bind(this)}
        geocoderAutoFilledProps={["position", "country"]}
      />
    </div>;
  }

  renderPolygonComponent() {
    // position can have strings or numbers inside so make sure we have the number version here
    const position = this.state.position
      ? {
        latitude: numberTransformer.parse(
          String(this.state.position.latitude),
        ),
        longitude: numberTransformer.parse(
          String(this.state.position.longitude),
        ),
      }
      : null;
    const validPosition = isValidPosition(position);
    return [
      <div
        onClick={() => this.setState({ modalVisible: true })}
        key="preview-map">
        <ModalMap
          markers={[]}
          position={validPosition ? position : null}
          zoom={16}
          hideControls={true}
          containerStyle={{ height: "180px" }}
          noTabIndex={true}
          options={{
            disableDefaultUI: true,
            gestureHandling: "none",
            zoomControl: false,
          }}>
          {validPosition
            ? (
              <PinPositionMarker position={appPosToLatLng(position as any)} />
            )
            : null}
        </ModalMap>
      </div>,
      <PolygonAndMarkerPlacementMapDialog
        key="polygonandmarkerplacement"
        position={validPosition ? position : null}
        title={I18n.t("position_company")}
        label={`${I18n.t("position_company")}`}
        onPolygonChange={this.onPolygonChange.bind(this)}
        noTabIndex={true}
        modalVisible={this.state.modalVisible}
        onOpen={() => this.setState({ modalVisible: true })}
        onClose={() => this.setState({ modalVisible: false })}
      />,
    ];
  }
}

const wrappedFormGroup = firestoreConnect()(FormGroup);
const selector = (state, ownProp) => {
  const open = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, open);
  const groupId = getEditGroupId(state);
  const group = getGroup(state, open, groupId);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(state, open);
  const type = ownProp.type;
  return {
    group: group,
    type: type,
    myCompanyProfile: myCompanyProfile,
    editMode: !!getEditGroupId(state),
    users: getEditGroupId(state) ? getGroupUsers(state, open, groupId) : [],
    company: company,
    auth: state.firebase.profile,
    required: ownProp.required ? ownProp.required : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editGroup,
          openGroup,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof FormGroup>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedFormGroup);

const GroupForm = compose<typeof t.form.Form>(
  withAutoFilledPhoneNumber,
  withGeocoderFilledProps,
)(t.form.Form) as any;
