import * as React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import {
  includes,
  keys,
  map,
  without,
  sortBy,
  entries,
  groupBy,
} from 'lodash-es';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import { getFieldTableStateForCompany } from 'farmerjoe-common/lib/selectors/user';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getFeature } from 'farmerjoe-common/lib/selectors/features';
import { isNotMainGroup } from 'farmerjoe-common/lib/utils/firestoreRedux/Utils';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getFormSchemasQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { getFormSchemas } from 'farmerjoe-common/lib/selectors/forms';

import { columnTypes } from './columns';
import { boniturColumnIdRegex } from './Bonitur/util';
import '../style.css';
import Dialog from '../../Dialog/Dialog';
import Icon from '../../Common/Icon';

import I18n from '../../../language/i18n';
import type { FieldTableState } from '../../../flowTypes';
import { saveFieldTableState } from '../../../actions/fieldTable';
import { resolve } from '../../../utils/utils';

type Props = {
  onClose: (...args: Array<any>) => any;
  show: boolean;
  fieldTableState?: Record<string, FieldTableState>;
  actions?: Record<string, any>;
  openCompany?: string;
  tableKey: string;
  producersOn?: boolean;
  tradersOn?: boolean;
  browsingGroup?: string;
  boniturOn?: boolean;
  formSchemas?: Array<Record<string, any>>;
};

type State = {
  fieldTableState?: Record<string, FieldTableState>;
};

class TableColumnConfigurator extends React.Component<Props, State> {
  state = {
    fieldTableState: this.props.fieldTableState,
  };

  render() {
    const {
      show,
      onClose,
      tableKey,
      producersOn,
      tradersOn,
      browsingGroup,
      boniturOn,
    } = this.props;
    const removeColumns = [
      'cropName',
      tableKey === 'current' ? 'cropHarvested_on' : null,
    ];

    if (!producersOn || (producersOn && isNotMainGroup(browsingGroup as any))) {
      removeColumns.push('producerName');
    }
    if (!producersOn || (producersOn && isNotMainGroup(browsingGroup as any))) {
      removeColumns.push('producerEmail');
    }

    if (!tradersOn || (producersOn && isNotMainGroup(browsingGroup as any))) {
      removeColumns.push('trader');
    }

    const columnTypes = this.getColumnTypes();
    const availableColumns = sortBy(
      entries(
        without(keys(columnTypes), ...removeColumns).reduce((acc, id) => {
          acc[id] = columnTypes[id];
          return acc;
        }, {}),
      ),
      ([id, column]) =>
        column.HeaderTableConfig
          ? resolve(column.HeaderTableConfig)
          : resolve(column.Header),
    );

    const grouppedColumns = groupBy(availableColumns, ([id, column]) =>
      boniturColumnIdRegex.test(id) ? 'boniturs' : 'other',
    );

    return (
      <Dialog
        show={show}
        onClose={onClose}
        dialogClassName={'table-columns-dialog'}
        title={I18n.t('columnConfigurator.title')}
        footer={
          <div className="buttons">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t('save')}
            </button>
          </div>
        }>
        <div style={{ paddingRight: 10 }}>
          <p style={{ minHeight: 80 }}>
            {I18n.t('columnConfigurator.possibleColumns')}
          </p>
          <ul className="possible-columns list-style-none">
            {map(grouppedColumns.other, ([id, column]) => (
              <ColumnItem
                key={id}
                id={id}
                column={column}
                fieldTableState={this.state.fieldTableState}
                tableKey={tableKey}
                toggleColumn={this.toggleColumn}
              />
            ))}
          </ul>
          {boniturOn
            ? (
            <div>
              <hr />
              <ul className="possible-columns list-style-none">
                {map(grouppedColumns.boniturs, ([id, column]) => (
                  <ColumnItem
                    key={id}
                    id={id}
                    column={column}
                    fieldTableState={this.state.fieldTableState}
                    tableKey={tableKey}
                    toggleColumn={this.toggleColumn}
                  />
                ))}
              </ul>
            </div>
              )
            : null}
        </div>
        <div>
          <p style={{ minHeight: 80 }}>
            {I18n.t('columnConfigurator.chosenColumns')} <br />
            <small className={'text-muted font-italic'}>
              Reihenfolge durch ziehen verändern.
            </small>
          </p>

          <ChosenColumns
            ids={(this.state.fieldTableState as any)[tableKey].columnIds}
            onRemove={this.toggleColumn.bind(this)}
            onSortEnd={({ oldIndex, newIndex }) => {
              this.moveColumn(oldIndex, newIndex);
            }}
            useDragHandle={true}
            lockAxis="y"
            helperClass="sortable-helper"
            columnTypes={columnTypes}
          />
        </div>
      </Dialog>
    );
  }

  toggleColumn = id => {
    const columnIds = (this.state.fieldTableState as any)[this.props.tableKey].columnIds;
    const columnTypes = this.getColumnTypes();
    this.setState({
      fieldTableState: {
        ...this.state.fieldTableState,
        [this.props.tableKey]: {
          ...(this.state.fieldTableState as any)[this.props.tableKey],
          columnIds: (includes(columnIds, id)
            ? without(columnIds, id)
            : [...columnIds, id]
          ).filter(id => columnTypes[id]),
        },
      },
    });
  };

  moveColumn(oldIndex, newIndex) {
    const columnIds = (this.state.fieldTableState as any)[this.props.tableKey].columnIds;
    const columnTypes = this.getColumnTypes();
    this.setState({
      fieldTableState: {
        ...this.state.fieldTableState,
        [this.props.tableKey]: {
          ...(this.state.fieldTableState as any)[this.props.tableKey],
          columnIds: arrayMove(columnIds, oldIndex, newIndex).filter(
            (id: any) => columnTypes[id],
          ),
        },
      },
    });
  }

  onSave() {
    const { onClose } = this.props;
    this.props.actions?.saveFieldTableState(
      this.props.openCompany,
      this.state.fieldTableState,
    );
    onClose();
  }

  getColumnTypes() {
    if (this.props.boniturOn) {
      const types: any = { ...columnTypes };
      this.props.formSchemas?.forEach(schema => {
        const id = `bonitur:${schema.key}`;
        types[id] = {
          id,
          Header: schema.name,
          bonitur: true,
        };
      });
      return types;
    } else {
      return columnTypes;
    }
  }
}

const DragHandle = SortableHandle(() => (
  <span className="drag-handle" title={I18n.t('columnConfigurator.dragHere')}>
    <Icon iconType={'fal'} name={'ellipsis-h'} />
  </span>
));

type SortableItemProps = {
  id: string;
  onRemove: (id:any)=>any;
  columnTypes?: any;
};
const SortableItem = SortableElement<SortableItemProps>(({ id, onRemove, columnTypes }) => (
  <li>
    {columnTypes[id].fixed == null
      ? (
      <DragHandle />
        )
      : (
      <span className="drag-handle-spacer" />
        )}
    <span className="name">
      {columnTypes[id].HeaderTableConfig
        ? resolve(columnTypes[id].HeaderTableConfig)
        : resolve(columnTypes[id].Header)}
    </span>
    {columnTypes[id].fixed == null && !['cropName'].includes(id)
      ? (
      <a
        className="btn btn-danger remove-button"
        href="javascript: void 0"
        onClick={() => onRemove && onRemove(id)}
        title={I18n.t('delete')}>
        <Icon iconType="fa" name="times" />
      </a>
        )
      : null}
  </li>
));

type SortableContainerProps = {
  ids?: string[];
  onRemove: (id:any)=>any;
  columnTypes?: any;
};
const ChosenColumns = SortableContainer<SortableContainerProps>(({ ids, onRemove, columnTypes }) => {
  return (
    <ul className="chosen-columns list-style-none">
      {ids?.map((id, index) =>
        columnTypes[id]
          ? (
          <SortableItem
            key={id}
            index={index}
            id={id}
            onRemove={onRemove}
            columnTypes={columnTypes}
          />
            )
          : null,
      )}
    </ul>
  );
});

const ColumnItem = ({
  id,
  column,
  fieldTableState,
  toggleColumn,
  tableKey,
}) => (
  <li>
    <input
      type="checkbox"
      name={`column-${id}`}
      id={`column-${id}`}
      checked={includes(fieldTableState[tableKey].columnIds, id)}
      onChange={e => toggleColumn(id)}
    />
    <label htmlFor={`column-${id}`} className="name">
      {column.HeaderTableConfig
        ? resolve(column.HeaderTableConfig)
        : resolve(column.Header)}
    </label>
  </li>
);

export default compose<typeof TableColumnConfigurator>(
  connect(
    (state: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      const producersOn = getFeature(state, openCompany, 'producers') === true;
      const tradersOn = getFeature(state, openCompany, 'traders') === true;
      const browsingGroup = (getBrowsingGroupKey as any)(state);
      const boniturOn = getFeature(state, openCompany, 'bonitur') === true;
      const formSchemas = getFormSchemas(state);

      return {
        openCompany,
        fieldTableState: getFieldTableStateForCompany(state, openCompany),
        producersOn,
        tradersOn,
        browsingGroup,
        boniturOn,
        formSchemas,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          saveFieldTableState,
        },
        dispatch,
      ),
    }),
  ),
  firestoreConnect(({ openCompany, boniturOn }) => {
    const paths: any[] = [];
    if (boniturOn) {
      paths.push(getFormSchemasQuery(openCompany));
    }
    return paths;
  }),
)(TableColumnConfigurator);
