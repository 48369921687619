import React, { useState } from "react";
import { connect } from "react-redux";
import { get } from "lodash-es";

import { numberTransformer } from "../../tcomb/transformers/transformers";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { getMarkersForFieldShare, getFieldSharingRequests } from "farmerjoe-common/lib/selectors/fields";
import * as companySelectors from "farmerjoe-common/lib/selectors/companies";
import { getColor } from "farmerjoe-common/lib/utils/Colors";

import ModalMap from "../Map/ModalMap";
import Polygon from "../Map/Polygon";
import {
  isValidPosition ,
  getPolygonOptions,
  preparePolygonPath,
  memoizedMarkers,
} from "../../utils/Map";

type Props = {
    fieldSharingRequestKey: string;
    fieldSharingRequest?: any;
    openCompany: string;
    myCompanyProfile: any;
    markers: any;
    field: any;
    company: any;
};

const FieldSharingRequest = (props: Props) => {
  const {
    fieldSharingRequest,
    openCompany,
    company,
    markers,
    field,
  } = props;

  const [ mapRef, setMapRef ] = useState();

  if (!fieldSharingRequest) {
    return null;
  }

  const inputPosition = field.position;
  const position = inputPosition
    ? {
      latitude: numberTransformer.parse(
        String(inputPosition.latitude),
      ),
      longitude: numberTransformer.parse(
        String(inputPosition.longitude),
      ),
    }
    : null;
  const validPosition = isValidPosition(position);

  const mapMarkers = memoizedMarkers(markers, company);
  const useBaseColors = get(field, "activeCrop.markedArea", false);

  return (
    <ModalMap
      zoomedIn={true}
      markers={mapMarkers}
      position={validPosition ? position : null}
      zoom={4}
      noTabIndex={true}
      containerStyle={{ width: "100%" }}
      hideSearch={true}
      openCompany={openCompany}
      isMapPage={true}
      onMapRef={map => setMapRef(map as any)}
      baseColors={useBaseColors}
    >
      {renderMarkedAreaPolygons(field, mapRef)}
    </ModalMap>
  );
};


const renderMarkedAreaPolygons = (field, mapRef) => {
  let polygons: any[] = [];
  if (field.baseField) {
    // render all polygons;
    const usedCropArea = get(field, "usedCropArea", null);
    if (!usedCropArea) {
      return null;
    }

    polygons = [...polygons, ...Object.keys(usedCropArea).map((key, idx) => {
      const { markedArea, color = "noCrop" } = usedCropArea[key];
      const polygonPath = preparePolygonPath(markedArea.polygon);
      const polygonOptions = getPolygonOptions({
        color: getColor(color),
      });

      return (
        <Polygon
          key={`${key}-{idx}`}
          path={polygonPath}
          options={polygonOptions}
          map={mapRef}
          onClick={() => {}}
        />
      );
    })];
  }

  // field has crop that is not occuppying the whole area
  const shouldRender = get(field, "activeCrop.markedArea", false);
  if (shouldRender) {

    const markedArea = get(field, "activeCrop.markedArea");
    const polygonPath = preparePolygonPath(markedArea.polygon);
    const polygonOptions = getPolygonOptions({
      color: getColor(get(field, "activeCrop.color", "noCrop")),
    });

    const polygon = 
    <Polygon
      key={field.activeCrop.key}
      path={polygonPath}
      options={polygonOptions}
      map={mapRef}
      onClick={() => {}}
    />;

    polygons = [...polygons, polygon];
  }

  return polygons;
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const fieldSharingRequests = getFieldSharingRequests(state, openCompany);
  const currentRequest = fieldSharingRequests.find((request) => request.key === ownProps.fieldSharingRequestKey);
  const field = get(currentRequest, "field", null);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const company = companySelectors.getCompany(
    state.firestore.data,
    openCompany,
  );

  // @ts-ignore
  const markers = getMarkersForFieldShare(state, openCompany, field);

  return {
    openCompany,
    myCompanyProfile,
    company,
    markers,
    fieldSharingRequest: currentRequest,
    field,
  };
};

export default connect(selector, null)(FieldSharingRequest);
