import React from 'react';
import stylesheet from '../stylesheets/style';
import I18n from '../../language/i18n';
import ColorPicker from '../templates/ColorPicker';
import EmojiPicker from '../templates/EmojiPicker';
import { cloneDeep } from 'lodash-es';
import t from 'tcomb-form';
import { getColorsForPicker } from 'farmerjoe-common/lib/utils/Colors';
import FormInput from '../templates/FormInput';

const model = t.struct({
  reason: t.maybe(t.String),
  color: t.maybe(t.String),
  emoji: t.maybe(t.String),
});

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

const options = function() {
  return {
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      reason: {
        config: {
          multiline: true,
        },
        label: `${I18n.t('fieldMark.reason')}`,
        factory: FormInput,
      },
      color: {
        label: `${I18n.t('fieldMark.selectColorOrEmoji')} *`,
        factory: ColorPicker,
        config: {
          colors: getColorsForPicker({
            red: [254, 148, 138],
            orange: [250, 206, 133],
            yellow: [251, 242, 127],
            darkGreen: [111, 174, 116],
            blue: [102, 173, 255],
            pink: [219, 97, 244],
          }),
        },
      },
      emoji: {
        // label:      `${I18n.t('fieldMark.selectEmoji')} *`,
        factory: EmojiPicker,
      },
    },
  };
};

export default { model, options };
