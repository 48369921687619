import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { getFirebase, reactReduxFirebase } from "react-redux-firebase";
import { reduxFirestore } from "redux-firestore";
import createDebounce from "redux-debounced";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { cloneDeep, each, get, set } from "lodash-es";
import { actionTypes as firestoreActionTypes } from "redux-firestore/src/constants";

import combinedReducers from "farmerjoe-common/lib/reducers/reducers";
import { mergeStateEnhancer } from "farmerjoe-common/lib/reducers/enhancers";

import fire from "../data/firebase";
import { browserLanguage } from "../language/i18n";
import { captureException } from "../utils/sentry";

const ENV_PRODUCTION = "production";

const middleware = [createDebounce(), thunk.withExtraArgument(getFirebase)];

const rrfConfig = {
  updateProfileOnLogin: true,
  userProfile: "users",
  useFirestoreForProfile: true,
  oneListenerPerPath: true,
  logListenerError: process.env.NODE_ENV !== ENV_PRODUCTION,
  keysToRemoveFromAuth: [
    "appName",
    "apiKey",
    "authDomain",
    "redirectEventId",
    "stsTokenManager",
    "uid",
    "providerData",
    "providerId",
    "refreshToken",
  ],
  /**
   * Executed after a registration. We grab the data and add it to the user's profile
   * /users/{userid}/
   *
   * @param userData
   * @param profile
   * @returns {{uid: *, email: *, displayName: *, firstname: *, lastname: *}}
   */
  profileFactory: (userData, profile) => {
    let { uid, email, displayName, language } = userData.user;
    const { firstname = null, lastname = null, phoneNumber } = profile;
    if (!displayName && profile.displayName) {
      displayName = profile.displayName;
    }

    // If we don't have a language for the user try to guess it
    if (!language) {
      language = browserLanguage();
    }

    return {
      uid,
      email,
      displayName,
      firstname,
      lastname,
      phoneNumber,
      language,
    };
  },
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "firebase",
    "firestore",
    "reviewRequested",
    "byIds",
    "dismissedUpdates",
    "serviceWorkerUpdateAvailable",
  ],
  transforms: [
    createTransform(
      state => state,
      (imageUploadQueue, key) => {
        if (imageUploadQueue) {
          // dates get serialized as strings and we need to deserialize them properly
          imageUploadQueue = cloneDeep(imageUploadQueue);
          each(imageUploadQueue, upload => {
            if (upload.created) {
              upload.created = new Date(upload.created);
            }
            const created = get(upload, "comment.created");
            if (created) {
              set(upload, "comment.created", new Date(created));
            }
            const modified = get(upload, "comment.modified");
            if (modified) {
              set(upload, "comment.modified", new Date(modified));
            }
          });
          return imageUploadQueue;
        } else {
          return imageUploadQueue;
        }
      },
      { whitelist: ["imageUploadQueue"] },
    ),
  ],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export default function configureStore(
  initialState = { locationPermission: false },
) {
  const composeEnhancers =
    (process.env.NODE_ENV !== ENV_PRODUCTION &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const createStoreWithFirebase = composeEnhancers(
    replaceStateEnhancer(),
    listenerErrorLoggingEnhancer(),
    mergeStateEnhancer(),
    reactReduxFirebase(fire, rrfConfig), // firebase instance as first argument
    reduxFirestore(fire as any),
    applyMiddleware(...middleware),
  )(createStore);

  const store = createStoreWithFirebase(persistedReducer, initialState);

  const persistor = persistStore(store);

  return { store, persistor };
}

/**
 * adds possibility to replace whole state; used for printing
 */
const replaceStateEnhancer = () => next => (
  reducer,
  initialState,
  middleware,
) => {
  const higherState = {
    state: initialState,
  };
  const newReducer = function(state = {}, action) {
    switch (action.type) {
    case "REPLACE_WHOLE_STATE":
      higherState.state = action.payload;
      return higherState.state;
    default:
      return reducer(state, action);
    }
  };
  return next(newReducer, initialState, middleware);
};

/**
 * adds logging for LISTENER_ERRORs
 */
const listenerErrorLoggingEnhancer = () => next => (
  reducer,
  initialState,
  middleware,
) => {
  const newReducer = function(state = {}, action) {
    switch (action.type) {
    case firestoreActionTypes.LISTENER_ERROR:
    case firestoreActionTypes.ERROR:
    case firestoreActionTypes.CLEAR_ERROR:
    case firestoreActionTypes.CLEAR_ERRORS:
    case firestoreActionTypes.GET_FAILURE:
    case firestoreActionTypes.SET_FAILURE:
    case firestoreActionTypes.ADD_FAILURE:
    case firestoreActionTypes.UPDATE_FAILURE:
    case firestoreActionTypes.DELETE_FAILURE:
    case firestoreActionTypes.ON_SNAPSHOT_FAILURE:
    case firestoreActionTypes.TRANSACTION_FAILURE:
      try {
        // we create and error and override it's properties because sentry doesn't like non-errors in captureException
        const error = new Error();
        error.message = get(action, "payload.message", action.type);
        error.name = get(action, "payload.name", "Error");
        captureException(error, {
          action,
          stack: get(action, "payload.stack"),
        });
      } catch (e) {}
      return reducer(state, action);
    default:
      return reducer(state, action);
    }
  };
  return next(newReducer, initialState, middleware);
};
