import * as React from "react";
import * as employeeCreators from "farmerjoe-common/lib/actions/employee";
import type { Company, Employee as CompanyUser, User } from "../../flowTypes";
import Icon from "../Common/Icon";
import Avatar from "../../containers/Avatar";
import Role from "./Role";
import Invite from "./Invite";
import OnlineIndicator from "./OnlineIndicator";
import CircleUrlButtonWithIcon from "../Common/CircleUrlButtonWithIcon";

type Props = {
  actions: {
    sendInvitation: typeof employeeCreators.sendInvitation;
    updateEmployee: typeof employeeCreators.updateEmployee;
    deleteEmployee: typeof employeeCreators.deleteEmployee;
    openEmployee: typeof employeeCreators.openEmployee;
  };
  user: CompanyUser;
  myCompanyProfile: CompanyUser;
  isOnline?: boolean;
  auth?: User;
  company: Company;
  selected?: boolean;
  history?: Record<string, any>;
};

export default class EmployeeContent extends React.PureComponent<Props> {
  render() {
    const { user, company, actions } = this.props;
    const invited: any = null;

    return (
      <div style={{ flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <div
            style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
            <div style={{ position: "relative" }}>
              <OnlineIndicator user={user} />
              <Avatar
                uid={user.key}
                size={"medium"}
                style={{ borderRadius: 30, backgroundColor: "#EEE" }}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}>
                  <span style={{ fontWeight: "bold" }}>{user.name}</span>
                  {invited
                    ? (
                      <Icon
                        name={"ios-mail-outline"}
                        style={{
                          fontSize: 25,
                          marginLeft: 5,
                          height: 20,
                          lineHeight: "22px",
                        }}
                      />
                    )
                    : null}
                </div>
                <Role user={user} />
              </div>
              <div>
                {user.phoneNumber
                  ? (
                    <CircleUrlButtonWithIcon
                      url={`tel:${user.phoneNumber}`}
                      iconName={"ios-call"}
                    />
                  )
                  : null}
              </div>
            </div>
            <Invite user={user} actions={actions} company={company} />
          </div>
        </div>
      </div>
    );
  }
}
