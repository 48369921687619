import * as React from 'react';
import { matchPath, Redirect } from 'react-router';
import PropTypes from 'prop-types';

type Props = {
  children?: React.ReactNode;
  location?: Record<string, any>;
};

export default function CachingSwitch(props: Props, context: Record<string, any>) {
  const { route } = context.router;
  const { children } = props;
  const location = props.location || route.location;

  let match;
  let redirect: any = null;
  React.Children.forEach(children, element => {
    if (match == null && React.isValidElement(element)) {
      if (redirect == null && element.type === Redirect) {
        redirect = element;
        return;
      }

      const { path: pathProp, exact, strict, sensitive, from } = element.props;
      const path = pathProp || from;

      match = matchPath(
        location.pathname,
        { path, exact, strict, sensitive },
        route.match,
      );
    }
  });

  return !match
    ? redirect
    : React.Children.toArray(children).filter(
      (element: any) => element.type !== Redirect,
    );
}

CachingSwitch.contextTypes = {
  router: PropTypes.object,
};
