import React from "react";
import { reverse } from "lodash-es";
import moment from "moment";

import { convertStringToRange, getTranslatedDateRange } from "farmerjoe-common/lib/utils/Date";

import Icon from "../Common/Icon";
import DropDown from "../DropDown/DropDown";
import FixedRangeCalendar from "../../tcomb/utils/FixedRangeCalendar";
import * as constants from "../../styles/style";

import "./style.css";

type Props = {
  date: string;
  onSave: (arg0: string) => void;
  ranges?: Array<{
    value: string;
    label: string;
  }>;
};

type State = {
  customDate: boolean;
};

export default class DateRangeFilter extends React.Component<Props, State> {
  static defaultProps = {
    ranges: [
      { value: "custom", label: "Benutzerdefiniert" },
      { value: "last:7", label: "Letzte 7 Tage" },
      { value: "last:30", label: "Letzte 30 Tage" },
      { value: "last:90", label: "Letzte 90 Tage" },
      { value: "last:365", label: "Letzte 365 Tage" },
    ],
  };

  dropDown: any = React.createRef();

  state = {
    customDate: this.props.date.startsWith("range:"),
  };

  render() {
    const { date, ranges } = this.props;
    const customDate = this.state.customDate;

    return (
      <DropDown
        ref={this.dropDown}
        button={
          <div className="date-range-filter-trigger">
            <Icon name={"calendar-alt"} iconType={"fal"} />
            <div>
              <span>{getTranslatedDateRange(date)}</span>
            </div>

            <Icon name={"filter"} iconType={"fal"} />
          </div>
        }
        menuClassName={"date-range-filter-menu"}
        menuPosition={"dropdown-menu-left"}
        menuAnimation={"scale-up-left"}
        className={"date-range-filter"}
        showInPortal={true}>
        <ul className="options list-style-none">
          {ranges?.map(({ value, label }, i) => {
            const active =
              (value === "custom" && customDate) ||
              (date === value && !customDate);
            return (
              <li key={i}>
                <a
                  key={"options" + value}
                  className="dropdown-item"
                  href="javascript: void 0"
                  onClick={this.onOptionClick.bind(this, value)}>
                  <div className={`fj-label-no-icon ${active ? "active" : ""}`}>
                    {label}
                  </div>
                  <div className={active ? "visible" : "invisible"}>
                    <Icon
                      name={"md-checkmark"}
                      style={{
                        ...constants.styles.buttonMenuText,
                        ...constants.styles.strong,
                        ...{ marginLeft: 5 },
                      }}
                    />
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
        {customDate
          ? (
            <FixedRangeCalendar
              showToday={false}
              showDateInput={false}
              selectedValue={
                customDate
                  ? reverse(convertStringToRange(date)).map(date => moment(date))
                  : []
              }
              onSelect={this.onSelectRange}
            />
          )
          : null}
      </DropDown>
    );
  }

  onOptionClick = value => {
    if (value === "custom") {
      this.setState({ customDate: true });
    } else {
      this.setState({ customDate: false }, () => this.save(value));
      this.dropDown.current.toggle(false);
    }
  };

  save = (value: string) => {
    if (this.props.onSave) {
      this.props.onSave(value);
    }
  };

  onSelectRange = ([start, end]: [moment.Moment, moment.Moment]) => {
    this.save(
      "range:" + start.format("YYYY-MM-DD") + "#" + end.format("YYYY-MM-DD"),
    );
  };
}
