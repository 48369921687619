import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import ButtonCamera from './ButtonCamera'

import I18n from '../../language/i18n';
import ButtonCamera from './ButtonCamera';
import ImagePicker from '../ImagePicker/ImagePicker';

// TODO: improve typings
type Props = any;
type State = any;

export default class FormRow extends PureComponent<Props, State> {
  static propTypes = {
    cover: PropTypes.string,
    onClick: PropTypes.func,
    showUploadOption: PropTypes.bool,
    onPickImage: PropTypes.func,
    pickerSettings: PropTypes.object,
  };

  static defaultProps = {
    showUploadOption: true,
  };

  render() {
    let {
      cover,
      onClick,
      onPickImage,
      pickerSettings,
      showUploadOption,
    } = this.props;

    cover = cover
      ? (
      <div
        style={{
          background: `#7EB549 url(${cover
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')}) no-repeat center center`,
          backgroundSize: 'cover',
          height: 150,
          paddingTop: 20,
          position: 'relative',
        }}>
        {showUploadOption
          ? (
          <ImagePicker onPick={onPickImage} {...pickerSettings}>
            <ButtonCamera onClick={() => onClick && onClick()} />
          </ImagePicker>
            )
          : null}
      </div>
        )
      : (
      <div
        className={'d-flex flex-direction-column justify-content-center pt-2'}
        style={{ height: 150, backgroundColor: '#7EB549' }}>
        {showUploadOption
          ? (
          <div>
            <span style={{ color: '#FFF' }}>
              {I18n.t('cover.showCollegesWhatIsImportantToYou')}
            </span>
            <div
              className={
                'd-flex align-items-center justify-content-center flex-direction-column'
              }>
              <ImagePicker onPick={onPickImage} {...pickerSettings}>
                <button
                  className="btn btn-secondary"
                  style={{
                    borderColor: '#FFF',
                    paddingTop: 3,
                    paddingBottom: 3,
                    marginTop: 10,
                  }}
                  onClick={() => onClick && onClick()}>{`+ ${I18n.t(
                  'cover.photo',
                )}`}</button>
              </ImagePicker>
            </div>
          </div>
            )
          : null}
      </div>
        );

    return cover;
  }
}
