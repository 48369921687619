import * as React from 'react';
import I18n from '../../language/i18n';
import { Loading } from '../Loading/Loading';
import './style.css';
type Props = {
  validationInProgress?: boolean;
};
export const ActivationLoadingScreen = ({
  validationInProgress = false,
}: Props) => {
  return (
    <div
      style={{
        zIndex: 9999,
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.7)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
      }}>
      <div className="d-flex flex-column justify-content-center align-items-center fj-activation-loading">
        <Loading />
        {validationInProgress && (
          <div>{I18n.t('activation.waitValidatingAccount')}</div>
        )}
      </div>
    </div>
  );
};
