import { UserObj } from "farmerjoe-common/lib/flow/types";
import * as React from "react";

/* eslint-disable */

/**
 * Export this as we often need to define style props
 */
export type Employee = {
  /**
   * If the user is not admin, than this determines
   * whether he can create/edit/delete fertilizings, waittimes, crops and fields
   */
  acl?: {
    field: {
      create: boolean,
      edit: boolean,
    }
    fertilizing: {
      create: boolean;
      'delete.own': boolean;
      'edit.own': boolean;
    };
    waittime: {
      create: boolean;
      'delete.own': boolean;
      'edit.own': boolean;
    };
    crop: {
      create_edit: boolean;
      delete: boolean;
      'edit.harvest': boolean;
    };
  };

  /**
   * Is the user active in the company
   */
  active: boolean;

  /**
   * The user's responsibility in the company
   */
  department: string;

  /**
   * The user's email
   */
  email: string;

  /**
   * If we are dealing with a standard user that should have restricted access (only to specific fields)
   * than here we keep a list of the fields he should have access to
   */
  fields?: Record<string, boolean>;

  /**
   * Is this an invitation to join the company
   */
  invitation: boolean;

  /**
   * the user's uid
   */
  key: string;

  /**
   * The name of the user in the company
   */
  name: string;

  /**
   * The user's phone number
   */
  phoneNumber?: string;

  /**
   * Rejected
   */
  rejected?: boolean;

  /**
   * Date the invitation was rejected
   */
  rejected_on?: Date;

  /**
   * The role of the user in the company
   */
  role: string;

  /**
   * Invitation date
   */
  invited_on: Date;

  /**
   * User who invited the user
   */
  invited_by: User;

  /**
   * modified date
   */
  modified: Date;

  /**
   * who modified the user
   */
  modified_by: User;

  /**
   * If the user is not admin, than this determines what he is
   * able to see
   */
  views?: {
    comments: boolean;
    fields: boolean;
    crops: boolean;
    users: boolean;
  };

  /**
   * -1 deactivated
   * 0 active
   */
  state: number;

  /**
   * The group the employee is a member of
   */
  group_id: string;

  /**
   * A cache of the group name
   */
  group_name: string;
};
export type Company = {
  key: string;
  name: string;
  email: string;
  company_number?: string;
  ggn_number?: string;
  qs_number?: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  tel: string;
  notes?: string;
  position: LatLng;
};

/**
 * A group in the context of FJ is a producer
 * A producer user has access only to the group's fields and comments
 */
export type Group = {
  key: string;
  company_id: string;
  name: string;
  email: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  tel: string;
  notes?: string;
  position: LatLng;
  created: Date;
  created_by: User;
  modified: Date;
  modified_by: User;
  contact: {
    email: string;
    isRealUser: boolean;
    name: string;
  };
  ggn_number?: string;
  qs_number?: string;
};

/**
 * A single location on the map
 */
export type LatLng = {
  latitude: number;
  longitude: number;
};

/**
 * a representation of a google maps marker
 */
export type Marker = {
  key: string;

  /**
   * The color of the marker
   */
  color?: string;
  title: string;
  position: LatLng;
  activeCrop?: Crop;
  size?: number;
  type: string;
  polygon?: Array<LatLng>;
  areaSize?: number;
};

export type MarkerWithClick = Marker & {
  onClick?: (...args: any) => any;
}

/**
 * A crop type
 */
export type Crop = {
  /**
   * Maybe type or sort was the correct word, but we started with art :(
   */
  art: string;

  /**
   * A color name that we map to a HEX value
   */
  color: string;

  /**
   * The company id this crop is associated with
   */
  company_id: string;
  created: Date;
  created_by: User;
  field_id: string;
  harvested_on?: Date;
  key: string;
  name: string;

  /**
   * Indicates if the crop is:
   * 0: Without crop - default for new crops
   * 1: Aktiv on the field
   * 2: planned
   */
  not_a_crop: number;
  sown_on: Date;
  state: number;
  type: string;

  /**
   * The last comment on the field
   */
  lastComment: Comment;

  /**
   * The number of analyses made on the field
   */
  analysesCount: number;
  mark: FieldMark | null | undefined;
};

export type HarvestedCrop = Crop & { field?: Field };


export type Field = {
  key: string;

  /**
   * the company id this field belongs to
   */
  company_id: string;

  /**
   * created date
   */
  created: Date;
  created_by: User;
  modified: Date;
  modified_by: User;
  name: string;
  size: number;
  position: LatLng;
  polygon?: LatLng[];
  activeCrop: Crop;
  notes: string;

  /**
   * 1: active
   * -1: archived
   */
  state: number;
  group_id: string;
  groupMeta?: {
    name: string;
    key: string;

    /**
     * ISO-2 string of the group
     */
    country: string;
  };
  trader_id: string;
  traderMeta?: {
    name: string;
    key: string;

    /**
     * ISO-2 string of the group
     */
    country: string;
  };

  /**
   * Field number from other systems
   */
  land_id: string;

  /**
   * Utility flag to mark the field when being shared as accepted or not
   */
  accepted?: boolean;

  /**
   * When the field is shared, we store here the companies that have access
   * */
  collaborators?: string[];

  activeCrops?: any[];
};

export type CommentContent = {
  languageConstant: string;
  data: {};
};

export type Comment = {
  active_crop_uid: string;
  key: string;
  text: string | CommentContent | any;
  created: Date;
  created_by: User;
  modified: Date;
  modified_by: User;

  /**
   * The type of comment we are dealing with
   */
  type: string;
  foreign_id: null | string;
  fieldMeta: {
    name: string;
  };
  cropMeta: {
    name: string;
    sown_on: Date;
    art: string;
    color: string;
  };
  image: {
    uri: string;
    dimensions: {
      width: number;
      height: number;
    };
    placeholder: boolean;
  };
  file: {
    uri: string;
  }
};
export type Analysis = {
  /**
   * short unique number of the analysis
   */
  analyse_number: string;
  company: Company;
  company_address: {
    city: string;
    country: string;
    email: string;
    name: string;
    street: string;
    tel: string;
    zip: string;
  };

  /**
   * the company id
   */
  company_id: string;

  /**
   * created
   */
  created: Date;
  created_by: User;
  field: Field;

  /**
   * the field id
   */
  field_id: string;
  form: {
    compost: {};
    crop: {
      color: string;
      name: string;
      notes: string;
      pre_crop: string;
      sown_on: string;
    };
    offers: {
      fertilizer_need: boolean;
      catch_crop?: string;
      current_fertilizer?: number;
      frozen?: string;
      humus?: boolean;
      incorporation?: boolean;
      organic_fertilizer_years?: {
        '1': OrganicFertilizerYear;
        '2': OrganicFertilizerYear;
        '3': OrganicFertilizerYear;
        '4': OrganicFertilizerYear;
      };
      probe_date: Date;
      result_on: Date;
      service: string;
      serviceA: string;
    };
  };
  formConfig?: any;

  /**
   * The invoice address
   */
  invoice_address: {
    city: string;
    country: string;
    name: string;
    street: string;
    zip: string;
  };
  key: string;

  /**
   * The offer selected by the user
   */
  labOffer: string;
  labSelected: any;
  photos?: {
    label: string;
  };

  /**
   * 0: mail to laboratory not sent
   * 1: mail to laboratory sent
   */
  sentMail: number;

  /**
   * 0: analysis not yet complete
   * 1: analysis complete
   * -1: analysis cancelled
   */
  state: 0;

  /**
   * The date a probe was taken
   */
  lab_probe_taken_on: Date;

  /**
   * the date the lab accepted the analysis
   */
  lab_accepted_on: Date;
  result?: {
    file: File;
  };
};
export type Fertilizing = {
  key: string;
  active_crop_uid: string;
  company_id: string;
  created: Date;
  field_id: string;
  n: {
    kg: number;
    percentage?: string;
  };
  p2o5: {
    kg: number;
    percentage?: string;
  };
  mg: {
    kg: number;
    percentage?: string;
  };
  k2o: {
    kg: number;
    percentage?: string;
  };
  s: {
    kg: number;
    percentage?: string;
  };
  modified: Date;
};
type OrganicFertilizerYear = {
  art: string;
  kgha: number;
};

/**
 * The minimum data for a User Object
 *
 * Saved as created_by or modified_by data
 */
export type User = {
  uid: string;
  name: string;
  email: string;
  phoneNumber?: string;
};

/**
 * The invitation object
 */
export type invitation = {
  /**
   * The company attributes
   */
  company: {
    name: string;
    street?: string | null;
    zip: string | null;
    city: string | null;
    country: string | null;
  };

  /**
   * The company key
   */
  key: string;

  /**
   * role in the company
   */
  role: string;

  /**
   * invited on date
   */
  invited_on: Date;

  /**
   * The user who sent the invitation
   */
  invited_by: User;
};

/**
 * individual object within a modalList
 */
export type ModelListDataObject = {
  /**
   * the label in the list
   */
  label: string | React.ReactNode;

  /**
   * testID for the touchableOpacity
   */
  testID?: string

  /**
   * The value for the label
   */
  value: any;

  /**
   * The native option Specifies whether or not the option is calling native code
   * If the option would trigger a native library, then we handle the
   * closing of the Modal on iOS differently. For more info check the class docs
   */
  native?: boolean;

  /**
   * If used with the withIcon option, than this is the ICON to display on the left
   */
  icon?: typeof React.Component;

  /**
   * The labelStyle if an option needs custom stylign
   */
  labelStyle?: Record<string, Record<string, any>>;
};
export type Profile = {
  isSuperAdmin?: boolean;
  isLabUser?: boolean;
  isLabAdminUser?: boolean;
  displayName: string;
  email: string;
  firstname: string;
  language: string;
  lastname: string;
  uid: string;
  isEmpty: boolean;
  isLoaded: boolean;
  phoneNumber: string;
  labId?: string;
  photoURL?: string;
  companies?: Record<string, any>;
  role: string;
};

export type AnalysisCost = {
  currency: string;
  grossPrice: number;
  netPrice: number;
  vat: number;
  vatSum: number;
};
export type File = {
  created: string;
  downloadURL: string;
  fullPath: string;
  name: string;
};
export type ApiKey = {
  created_by: User;
  company_id: string;
  created_on: Date;
  disabled: boolean;
  key: string;
  ip_filtering?: boolean;
  ip_filter?: string;
  name: string;
};
export type ApiLog = {
  key_id: string | null;
  user_id: string | null;
  company_id: string | null;
  date: Date;
  response: string | null;
  execution_time_ns: number;
  status_code: number;
  ip_address: string;
  url: string;
  user_agent: string | null;
  version: string | number;
  method: string;
};
export type FieldTableState = {
  columnIds: Array<string>;
  columnWidths: Record<string, number>;
  sorting: Array<{
    id: string;
    desc: boolean;
  }>;
};
export type GMLatLng = {
  lat: () => number;
  lng: () => number;
};
export type GMMarker = {
  getPosition: () => GMLatLng;
  setPosition: (arg0: GMLatLng) => void;
  setMap: (arg0: Record<string, any> | null) => void;
  setDraggable: (arg0: boolean) => void;
  setZIndex: (arg0: number) => void;
  setVisible: (arg0: boolean) => void;
};
export type GMLatLngLiteral = {
  lat: number;
  lng: number;
};
export type GMPoint = {
  x: number;
  y: number;
};
export type Region = {
  latitude: number;
  longitude: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
};
export type GMBounds = {
  contains(latLng: GMLatLng): boolean;
  equals(other: GMBounds): boolean;
  extend(point: GMLatLng | GMLatLngLiteral): GMBounds;
  getCenter(): GMLatLng;
  getNorthEast(): GMLatLng;
  getSouthWest(): GMLatLng;
  intersects(other: GMBounds): boolean;
  isEmpty(): boolean;
  toJSON(): Record<string, any>;
  toSpan(): GMLatLng;
  toString(): string;
  toUrlValue(precision?: number): string;
  union(other: GMBounds): GMBounds;
};

/**
 * The redux app state of the app
 */
export type State = {
  /**
   * the open companyId
   */
  readonly openCompanyId: string;

  /**
   * The companyid that is currently being edited
   */
  readonly editCompanyId: null | string;

  /**
   * The currently open group id
   */
  readonly openGroupId: null | string;
  readonly filtersByCompany: Readonly<Record<string, {
    search?: string;
    producerSearch: string;
  }>>;

  /**
   * What group the user is browsing as
   * Can be empty if the user hasn't extra selected a group
   */
  readonly browseAsGroup: {} | Readonly<Record<string, string>>;

  /**
   * The 4 last groups used in the form
   */
  readonly lastUsedGroup: {} | Readonly<Record<string, string[]>>;

  /**
   * The group that we are currently editing
   */
  readonly editGroup: null | string;

  /**
   * The currently open field
   */
  readonly openFieldId: null | string;

  /**
   * The field that is currently being edited
   */
  readonly editFieldId: null | string;

  /**
   * The waittime that is currently being edited
   */
  readonly editWaitTime: null | string;

  /**
   * The comment that is currently being edited
   */
  readonly editCommentId: null | string;

  /**
   * The fertilizing that is currently being edited
   */
  readonly editFertilizing: null | string;

  /**
   * The employee that is being edited
   */
  readonly editEmployee: null | string;

  /**
   * The data coming from firestore
   */
  readonly firestore: {
    readonly data: {
      readonly userPermissions?: null | {
        readonly isActiveInCompanies: string[];
        // TODO: move company ids into separate field so we have better type definition
        readonly [companyId: string]: Readonly<Record<string, Employee>> | string[];
      };
    };
  };
};

/**
 * Waittime definition
 */
export type WaitTime = {
  /**
   * Whether or not the time is stil lactive
   */
  active: boolean;

  /**
   * Date the time was applied
   */
  applied_on: Date;
  company_id: string;

  /**
   * Date the user created the waittime
   */
  created: Date;
  created_by: UserObj;

  /**
   * The crop this waittime is applied on
   */
  crop_id: string;

  /**
   * The date this waittime should stop to be active
   */
  ends_on: Date;

  /**
   * whether or not it was stored as favorite
   */
  favorite: boolean;
  field_id: string;
  key: string;
  template: string;

  /**
   * The day to wait
   */
  wait_time: number;
};
export type reduxFirestoreMultipleQuery = {
  collection: string;
  where: [string, string, string | boolean | number][];
  byIds: string;
};
export type reduxFirestoreSingleQuery = {
  collection: string;
  doc: string;
};
export type reduxFirestoreQuery = reduxFirestoreMultipleQuery | reduxFirestoreSingleQuery;

/**
 * Flow type for the tcomb locals object
 */
export type tCombLocals = {
  path: string;
  hasError: boolean;
  label: string;
  onChange: (value: string | null) => void;
  config: Record<string, any>;
  value: string;
  hidden: boolean;
};
export type FieldMark = {
  reason: string;
  color: string | null | undefined;
  emoji?: string;
};
export type GeoJsonCoord = [number, number];
export type GeoJsonPoint = {
  type: "Point";
  coordinates: GeoJsonCoord;
};
export type MarkerFeature = {
  type: "Feature";
  geometry: GeoJsonPoint;
  properties: {
    marker: Marker;
  };
};
export type ClusterFeature = {
  type: "Feature";
  geometry: GeoJsonPoint;
  id: number;
  properties: {
    cluster: boolean;
    cluster_id: number;
    point_count: number;
    point_count_abbreviated: number;
    groupId: string;
  };
};
export type MapFilter = {
  search?: string;
  mapType?: string;
  showCrops: Array<number>;
  markerTitle: string;
};

export enum TableListViewState {
  Classic = 'classic',
  Table = 'table',
};

export enum KeyCodes {
  Enter = 13,
};

export type BoniturTextColorScheme = {
  color: string;
  condition: Array<any>;
};

export type FormikFormValueObject = {
  [x: string]: string | number;
};
