import * as React from 'react';
import FormLabel from './FormLabel';
import FormRow from './FormRow';
import Text from './Text';

type Props = {
  value: any;
  label: string;
  className?: string;
};

export default class FormTextRow extends React.PureComponent<Props> {
  render() {
    const { value, label, className } = this.props;

    let renderValue, renderLabel;
    if (React.isValidElement(value)) {
      renderValue = value;
    } else {
      renderValue = <Text style={{ flexShrink: 2 }}>{value}</Text>;
    }

    renderLabel = <FormLabel label={label} inputValue={value} />;

    return (
      <FormRow value={renderValue} label={renderLabel} className={className} />
    );
  }
}
