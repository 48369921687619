import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCompany } from 'farmerjoe-common/lib/selectors/companies';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import { addKeyToObj } from '../../data/util';
import moment from 'moment';
import * as labActions from 'farmerjoe-common/lib/actions/labs';
import * as analysisActions from 'farmerjoe-common/lib/actions/analysis';

import { Loading } from '../Loading/Loading';

import Labs from './Labs';

import { isLoaded, firestoreConnect } from 'react-redux-firebase';

import { get } from 'lodash-es';
import Modal from 'react-bootstrap-modal';

import { getFeature } from 'farmerjoe-common/lib/selectors/features';

// TODO: improve typings
type Props = any;
type State = any;

class FormSelectLab extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object,
    openView: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      labSelected: '',
      labOffer: '',
    };
  }

  render() {
    const { labs, loaded, actions, fromField } = this.props;

    if (!loaded) {
      return <Loading />;
    }

    const content = (
      <div>
        <Labs
          labs={labs}
          onClick={(lab, labOffer) => {
            actions.selectedLab(lab);
            actions.selectedOffer(labOffer);

            if (fromField) {
              this.props.openView('CreateAnalysisForm');
            } else {
              // what forms do we have for this analysis?
              const analysisOptions = Object.keys(
                get(lab, `analysisTypes.${labOffer}`),
              ).filter(key => key !== 'title');

              // Soil: Use multi select in case of Soil
              if (labOffer === 'soil') {
                this.props.openView('FormFieldMultiSelect');
                return;
              }
              // If we have more than 1 option, let's show the select form screen
              else if (analysisOptions.length > 1) {
                this.props.openView('FormSelectResidueType');

                return;
              }

              // just navigate to the field selection, the form has just 1 option
              this.props.openView('FormFieldSelect');
            }
          }}
        />
      </div>
    );

    return (
      <Modal.Body onClick={e => e.stopPropagation()}>
        <div style={{ flex: 1, paddingTop: 10 }}>{content}</div>
      </Modal.Body>
    );
  }
}

const selector = (state, ownProps) => {
  const { labs = [] } = state.firestore.ordered;
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const fromField = !!state.analysisFromField;
  const showTestLabs = getFeature(state, openCompany, 'testLabs') === true;

  const filteredLabs = showTestLabs ? labs : labs.filter(lab => !lab.testLab);

  return {
    fromField: fromField,
    labs: filteredLabs,
    company: addKeyToObj(openCompany, company),
    loaded: isLoaded(labs),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...labActions,
          ...analysisActions,
        },
      ),
      dispatch,
    ),
  };
}

const wrappedFormSelectLab = firestoreConnect(props => {
  return [
    {
      collection: 'labs',
    },
  ];
})(FormSelectLab);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFormSelectLab);
