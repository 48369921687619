import React from "react";
import t from "tcomb-form";

import FertilizerTextboxRowTable from "../templates/FertilizerTextboxRowTable";
import Favorite from "../templates/Favorite";
import TextBoxWithAddMinusButtons from "../templates/TextBoxWithAddMinusButtons";
import FormInput from "../templates/FormInput";
import ModalDate from "../templates/ModalDate";
import { defaultDateFormat } from "../utils/options";
import { numberTransformer, dateTransformer } from "../transformers/transformers";
import { latitude, longitude } from "../validators/coordinates";

import Text from "../../components/Common/Text";
import Expandable from "../../components/Common/Expandable";
import I18n from "../../language/i18n";

import * as constants from "../../styles/style";
import stylesheet from "../stylesheets/style";

const tPosition = t.struct({
  latitude: latitude,
  longitude: longitude,
});

// Our Fertilizing model
const model = t.struct({
  name: t.String,
  kg: t.Number,
  n: t.maybe(t.Number),
  p2o5: t.maybe(t.Number),
  k2o: t.maybe(t.Number),
  s: t.maybe(t.Number),
  mg: t.maybe(t.Number),
  favorite: t.Boolean,
  applied_on: t.Date,
  advancedSettings: t.maybe(t.struct({
    applyToFields: t.maybe(t.list(t.String)),
    markedArea: t.maybe(t.list(tPosition)),
  })),
});

const styles = {
  label: {
    position: "absolute",
    right: 5,
    bottom: 5,
    color: "#CCCCCC",
    fontSize: 18,
  },
  inputView: {
    borderBottomWidth: 1,
    borderBottomColor: constants.FJBORDERCOLOR,
  },
  inputField: {
    height: 36,
  },
  inputFieldTable: {
    height: 36,
    textAlign: "right",
    color: "#000",
    paddingRight: 10,
  },
  column: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeaderRow: {
    width: 50,
    marginRight: 10,
  },
  tableHeader: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  inputFieldLabel: {
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 18,
  },
  inputFieldPercent: {
    width: 80,
    // backgroundColor: 'rgb(0,122,255)',
    // alignItems: 'flex-end',
    // paddingLeft: 10, paddingRight: 10
    borderColor: "rgb(0,122,255)",
    // borderWidth: 1,
    borderBottomWidth: 2,
    marginBottom: -1,
    paddingBottom: 1,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#CCCCCC",
    alignItems: "center",
    borderBottomStyle: "solid",
  },
};

function template(locals) {
  // in locals.inputs you find all the rendered fields
  return (
    <div>
      <div className="d-flex">
        {locals.inputs.name}
        {locals.inputs.favorite}
      </div>

      {locals.inputs.kg}

      <div className="fertilizings mt-2 mb-3">
        <div className="text-center text-muted mb-3">
          {I18n.t("fertilizer.enter_nutrient_ratio")}
        </div>
        <div className="fertilizings-table" style={{ border: "none" }}>
          <div className="header-row" />
          <div className="input-field-percent" style={{ borderBottomWidth: 0 }}>
            <Text className="header">%</Text>
          </div>
          <div className="column">
            <Text className="header">+ kg/ha</Text>
          </div>
          <div className="column">
            <Text className="header">&sum;/ha</Text>
          </div>
        </div>

        <div>{locals.inputs.n}</div>
        <div>{locals.inputs.p2o5}</div>
        <div>{locals.inputs.k2o}</div>
        <div>{locals.inputs.s}</div>
        <div>{locals.inputs.mg}</div>
      </div>

      {locals.inputs.applied_on}
      {locals.inputs.advancedSettings}
    </div>
  );
}

const options = function(component) {
  const { state, fieldsSelector, markAreaComponent, sum } = component;

  return {
    template: locals => template(locals),
    stylesheet: stylesheet,
    auto: "none",
    fields: {
      name: {
        label: `${I18n.t("fertilizer.fertilizer")} *`,
        returnKeyType: "next",
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () => {
          component.refs.form.getComponent("kg").refs.input.refs.input.focus();
        },
      },

      kg: {
        label: "kg/ha *",
        returnKeyType: "next",
        blurOnSubmit: false,
        factory: TextBoxWithAddMinusButtons,
        autoCorrect: false,
        transformer: numberTransformer,
        onSubmitEditing: () =>
          component.refs.form.getComponent("n").refs.input.focus(),
      },

      n: {
        label: "N",
        returnKeyType: "next",
        blurOnSubmit: false,
        template: FertilizerTextboxRowTable,
        autoCorrect: false,
        transformer: numberTransformer,
        config: {
          first: true,
          sum: sum.n.kg,
          kg: state.kg,
          styles,
        },
        onSubmitEditing: () =>
          component.refs.form.getComponent("p2o5").refs.input.focus(),
      },

      p2o5: {
        label: "P₂O₅",
        returnKeyType: "next",
        blurOnSubmit: false,
        template: FertilizerTextboxRowTable,
        autoCorrect: false,
        transformer: numberTransformer,
        config: {
          sum: sum.p2o5.kg,
          kg: state.kg,
          styles,
        },
        onSubmitEditing: () =>
          component.refs.form.getComponent("k2o").refs.input.focus(),
      },

      k2o: {
        label: "K₂O",
        returnKeyType: "next",
        blurOnSubmit: false,
        template: FertilizerTextboxRowTable,
        transformer: numberTransformer,
        autoCorrect: false,
        config: {
          sum: sum.k2o.kg,
          kg: state.kg,
          styles,
        },
        onSubmitEditing: () =>
          component.refs.form.getComponent("s").refs.input.focus(),
      },

      s: {
        label: "S",
        returnKeyType: "next",
        blurOnSubmit: false,
        template: FertilizerTextboxRowTable,
        autoCorrect: false,
        config: {
          sum: sum.s.kg,
          kg: state.kg,
          styles,
        },
        transformer: numberTransformer,
        onSubmitEditing: () =>
          component.refs.form.getComponent("mg").refs.input.focus(),
      },

      mg: {
        label: "Mg",
        returnKeyType: "next",
        blurOnSubmit: false,
        template: FertilizerTextboxRowTable,
        autoCorrect: false,
        transformer: numberTransformer,
        config: {
          last: true,
          sum: sum.mg.kg,
          kg: state.kg,
          styles,
        },
        onSubmitEditing: () =>
          component.refs.form.getComponent("n").refs.input.focus(),
      },
      favorite: {
        config: {
          label: `${I18n.t("save_as_favorite")}`,
        },
        template: Favorite,
      },
      applied_on: {
        label: I18n.t("waittime.application_day"),
        transformer: dateTransformer,
        config: {
          hideBorderTop: false,
          format: date => defaultDateFormat(date),
          mode: "date",
        },
        template: ModalDate,
      },
      advancedSettings: {
        label: I18n.t("advancedSettings"),
        template: locals => templateAdvancedSettings(locals, fieldsSelector, markAreaComponent),
      },
    },
  };
};

const templateAdvancedSettings = (locals, fieldsSelector, markAreaComponent) => {
  return (
    <div>
      <Expandable label={locals.label}>
        {fieldsSelector()}
        {markAreaComponent()}
      </Expandable>
    </div>
  );
};

export default { model, options };
