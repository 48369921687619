import React from 'react';

import { get, includes, isString, orderBy, without } from 'lodash-es';
import { classes } from '../../utils/dom';
import './style.css';

/* eslint-disable no-script-url */

export default function segmentedcontrol(locals) {
  const order = get(locals, 'config.order', true);
  const multiSelect = get(locals, 'config.multiSelect', false);
  let options = locals.options.map(a =>
    !isString(a) ? a : { text: a, value: a },
  );
  if (order) {
    options = orderBy(options, ['value']);
  }

  let label = get(locals, 'config.label', locals.label) || null;

  const containerStyle =
    get(locals, 'containerStyle') || get(locals, 'config.containerStyle');
  const optionContainerStyle =
    get(locals, 'optionContainerStyle') ||
    get(locals, 'config.optionContainerStyle');
  const optionStyle =
    get(locals, 'optionStyle') || get(locals, 'config.optionStyle');

  if (label) {
    label = <div style={{ marginBottom: 5, fontWeight: 'bolder' }}>{label}</div>;
  }

  // noinspection EqualityComparisonWithCoercionJS
  return (
    <div
      className={classes(
        'segmented-control-wrapper-1',
        locals.className,
        locals.hasError && 'error',
        locals.isDisabled && 'isDisabled',
      )}>
      {label ? <div className="segmented-control-label">{label}</div> : null}
      <div className="segmented-control-wrapper-2">
        <div
          className={classes('segmented-control-container')}
          style={containerStyle}>
          <div className="list-group segmented-control">
            {options.map(({ text, value }, i) => (
              <a
                href="javascript:void 0"
                key={i}
                className={classes(
                  'list-group-item',
                  multiSelect
                    ? includes(locals.value, value) && 'active'
                    : // eslint-disable-next-line eqeqeq
                    locals.value == value && 'active', // needs to be == and not ===
                )}
                style={optionContainerStyle}
                onClick={() => {
                  locals.onChange(
                    multiSelect && includes(locals.value, value)
                      ? without(locals.value, value)
                      : multiSelect
                        ? (locals.value || []).concat([value])
                        : value,
                  );
                }}
                title={text}>
                <span style={optionStyle}>{text}</span>
              </a>
            ))}
          </div>
          {get(locals, 'config.help') || null}
        </div>
      </div>
    </div>
  );
}
