import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import I18n from '../../language/i18n';
import AlertBox from '../Common/AlertBox';
import { outputDate } from 'farmerjoe-common';

// TODO: improve typings
type Props = any;
type State = any;

class CancelledAnalysisInfo extends PureComponent<Props, State> {
  static propTypes = {
    analysis: PropTypes.object.isRequired,
  };

  render() {
    const { analysis } = this.props;

    return (
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          display: 'flex',
        }}>
        <AlertBox
          boxStyle={{ borderRadius: 5 }}
          text={
            <div>
              <div
                style={{
                  textAlign: 'center',
                  color: '#FFF',
                  fontSize: 18,
                  fontWeight: 'bold',
                }}>
                {I18n.t('analysis.analysisCancelledOn', {
                  date: outputDate(analysis.cancelled_on),
                  time: outputDate(analysis.cancelled_on, 'HH:mm'),
                  user: analysis.cancelled_by ? analysis.cancelled_by.name : '',
                })}
              </div>
              {analysis.cancelled_reason
                ? (
                <div
                  style={{
                    textAlign: 'center',
                    color: '#FFF',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}>
                  {I18n.t('analysis.cancelReasonLab', {
                    reason: analysis.cancelled_reason,
                  })}
                </div>
                  )
                : null}
            </div>
          }
          type={'alert'}
        />
      </div>
    );
  }
}

export default CancelledAnalysisInfo;
