import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { get } from "lodash-es";
import PropTypes from "prop-types";

import { filters } from "farmerjoe-common/lib/actions/actions";
import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getUsersQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Users";
import {
  getCompanyGroupProfileForLoggedInUser,
  getGroupUsers,
  userState,
} from "farmerjoe-common/lib/selectors/user";
import * as employeeCreators from "farmerjoe-common/lib/actions/employee";
import { UserRole, UserState } from "farmerjoe-common/lib/flow/types";
import { getUsage } from "farmerjoe-common/lib/requests/billing";
import { SUBSCRIPTION_PLAN_QUOTA_REACHED } from "farmerjoe-common/lib/constants/billing";

import Table from "./Table";
import TabBar from "./TabBar";
import "../style.css";
import { canAddNewEmployees, convertUserObjects } from "../utils";
import { Loading } from "../../Loading/Loading";
import ScrollableTabView from "../../Common/ScrollableTabView";
import withRouter from "../../Router/withRouter";
import GlobalUserSearch from "../../GlobalUserSearch/GlobalUserSearch";
import NoResults from "../../Common/NoResults";
import { AlertDialog } from "../../Dialog/Dialog";
import I18n from "../../../language/i18n";
import * as constants from "../../../styles/style";
import { getUidToken } from "../../../utils/auth";

type Props = {
  company?: any;
  myCompanyProfile?: any;
  filter?: any;
  actions?: {
    openEmployee: typeof employeeCreators.openEmployee;
    filters: typeof filters;
  };
  users?: any[];
  loading?: boolean;
  history?: any;
  auth?: any;
  browsingGroup?: string;
  selectedEmployee?: string;
};

type State = {
  showCreateForm: boolean;
  filter: string;
  renderMap: boolean;
  company: any;
  render: boolean;
  showModalMap: boolean;
  role: string;
  alertDialog: {
    isVisible: boolean;
    title?: string;
    message?: string | React.ReactNode;
  };
  loading: boolean;
};

type EmployeeTableProps = {
  tabLabel: string;
  tableKey: string;
  users: any[];
  noResultsText: string;
  ref: React.RefObject<Table>;
};

class EmployeesTable extends PureComponent<Props, State> {
  static contextTypes = {
    router: PropTypes.object,
  };

  private employeesTableRef = React.createRef<Table>();
  private advisorsTableRef = React.createRef<Table>();
  private deactivatedUsersTableRef = React.createRef<Table>();

  // these constants are instance variables because
  // translations service takes time to initialize
  private TAB_LABEL_EMPLOYEES = I18n.t("employees.plural");
  private TAB_LABEL_ADVISORS = I18n.t("employees.advisors");
  private TAB_LABEL_DEACTIVATED_USERS = I18n.t("employees.deactivated");

  private selectedTabLabel: string = this.TAB_LABEL_EMPLOYEES;

  state: State = {
    filter: "active",
    renderMap: false,
    company: this.props.company,
    render: false,
    showCreateForm: false,
    showModalMap: false,
    role: "",
    alertDialog: {
      isVisible: false,
    },
    loading: false,
  };

  async onShowCreateForm() {
    const { company } = this.props;
    const uidToken = await getUidToken();
    this.setState({ loading: true });
    return getUsage(uidToken, company.key)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Unexpected error occured! Try again!");
        }
        const content = await response.json();
        if (content.totalUsers >= content.maxAllowedUsers) {
          throw new Error(SUBSCRIPTION_PLAN_QUOTA_REACHED);
        }
        this.setState({ showCreateForm: true });
      })
      .catch(error => {
        this.handleCheckUsageLimitsError(error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  private handleCheckUsageLimitsError(error: Error) {
    if (error.message === SUBSCRIPTION_PLAN_QUOTA_REACHED) {
      const message = <div>
        <div> {I18n.t("billing.addMoreSeats")} </div>
        <Link
          key={"billing"}
          to={`/company/${this.props.company.key}/billing`}
          onClick={this.closeAlert.bind(this)}
        >
          {I18n.t("billing.editSeats")} &#8594;
        </Link>
      </div>;
      this.setState({ showCreateForm: false });
      this.showAlert(I18n.t("billing.addSeats"), message);
      return;
    }

    this.setState({ showCreateForm: false });
    this.showAlert(I18n.t("error"), error.message);
  }

  private showAlert(title: string, message: string | React.ReactNode) {
    this.setState({
      alertDialog: { isVisible: true, title, message },
    });
  }

  private closeAlert() {
    this.setState({
      alertDialog: { isVisible: false },
    });
  }

  render() {
    const { company, myCompanyProfile, filter, auth, browsingGroup } = this.props;

    if (this.state.loading) {
      return <Loading />;
    }

    const styles = constants.styles;
    const showNew = canAddNewEmployees(myCompanyProfile, auth);

    return (
      <div className="employees-table">
        <div style={styles.containerColumn}>
          <ScrollableTabView
            onChangeTab={({ name }) => {
              this.selectedTabLabel = name;
              // if on the advisors tab set the role to advisor
              if (name === this.TAB_LABEL_ADVISORS) {
                this.setState({ role: UserRole.Advisor });
                return;
              }

              if (this.state.role !== "") {
                this.setState({ role: "" });
              }
            }}
            renderTabBar={() => (
              <TabBar
                showNew={showNew}
                onShowCreateForm={() => {
                  this.onShowCreateForm();
                }}
                search={get(filter, "search") || ""}
                company={company}
                onSearch={text =>
                  this.props.actions?.filters(company.key, {
                    userSearch: text,
                  })
                }
                tab={this.state.filter}
                onExportClick={this.onExportBtnClick.bind(this)}
              />
            )}>
            {this.renderUserTables()}
          </ScrollableTabView>
        </div>

        {this.state.showCreateForm
          ? (
            <GlobalUserSearch
              show={this.state.showCreateForm}
              onClose={() => this.setState({ showCreateForm: false })}
              groupType="employee"
            />
          )
          : null}
        <AlertDialog
          show={this.state.alertDialog.isVisible}
          onClose={this.closeAlert.bind(this)}
          title={this.state.alertDialog.title}
          children={this.state.alertDialog.message}
          key="alert"
        />
      </div>
    );
  }

  private renderUserTables() {
    const users = convertUserObjects(this.props.users, this.props.auth.uid);
    const employees = users.filter(
      user => user.state !== UserState.Deactivated && user.role !== UserRole.Advisor,
    );
    const advisors = users.filter(
      user => user.state !== UserState.Deactivated && user.role === UserRole.Advisor,
    );
    const deactivated = users.filter(user => user.state && user.state === UserState.Deactivated);

    const usersTablesProps: EmployeeTableProps[] = [
      {
        users: employees,
        tabLabel: this.TAB_LABEL_EMPLOYEES,
        tableKey: "employees-table",
        noResultsText: I18n.t("noSearchResults"),
        ref: this.employeesTableRef,
      },
      {
        users: advisors,
        tabLabel: this.TAB_LABEL_ADVISORS,
        tableKey: "advisors-table",
        noResultsText: I18n.t("employees.noAdvisors"),
        ref: this.advisorsTableRef,
      },
      {
        users: deactivated,
        tabLabel: this.TAB_LABEL_DEACTIVATED_USERS,
        tableKey: "deactivated-users-table",
        noResultsText: I18n.t("employees.noDeactivatedUsers"),
        ref: this.deactivatedUsersTableRef,
      },
    ];

    return usersTablesProps.map(this.renderUserTable.bind(this));
  }

  private renderUserTable(tableProps: EmployeeTableProps) {
    const {
      auth,
      loading,
      selectedEmployee,
    } = this.props;

    const { users, tabLabel, tableKey, noResultsText, ref } = tableProps;

    return (
      <Table
        ref={ref}
        tabLabel={tabLabel}
        users={loading ? null : users}
        loading={loading === true}
        auth={auth}
        key={tableKey}
        emptyView={(
          <NoResults text={noResultsText} />
        )}
        onEmployeeClick={this.onEmployeeClick.bind(this)}
        selectedEmployee={selectedEmployee}
      />
    );
  }

  onEmployeeClick(employee) {
    const { company, users } = this.props;
    if (!users || users.length === 0) {
      return;
    }
    this.props.actions?.openEmployee(employee.key);

    this.props.history?.push(
      `/company/${company.key}/employee/${employee.key}/${
        employee.group_id ? employee.group_id : "main"
      }`,
    );
  }

  private onExportBtnClick() {
    if (this.selectedTabLabel === this.TAB_LABEL_EMPLOYEES) {
      this.employeesTableRef.current?.exportData();
    } else if (this.selectedTabLabel === this.TAB_LABEL_ADVISORS) {
      this.advisorsTableRef.current?.exportData();
    } else if (this.selectedTabLabel === this.TAB_LABEL_DEACTIVATED_USERS) {
      this.deactivatedUsersTableRef.current?.exportData();
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
          openEmployee: employeeCreators.openEmployee,
        },
      ),
      dispatch,
    ),
  };
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const users = getGroupUsers(state, openCompany, browsingGroup);

  const hasLoadedEverything = hasLoaded([], state);

  return {
    loading: !hasLoadedEverything,
    email: state.firebase.profile.email,
    myCompanyProfile: myCompanyProfile,
    openCompany: openCompany,
    company: company,
    users: users,
    filter: userState(state, openCompany),
    auth: state.firebase.auth,
    browsingGroup: browsingGroup,
  };
};

const wrappedEmployees = firestoreConnect(props => {
  const { browsingGroup } = props;

  if (!props.email) {
    return [];
  }

  return [
    {
      collection: "companies",
      doc: props.openCompany,
    },
    getUsersQuery(props.openCompany, browsingGroup),
  ];
})(EmployeesTable);

export default compose<typeof EmployeesTable>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedEmployees);
