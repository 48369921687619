import * as React from 'react';
import * as constants from '../../../../styles/style';
import stylesheet from '../../../../tcomb/stylesheets/style';
import MultiSelect from '../../../../tcomb/templates/MultiSelect';
import SegmentedControl from '../../../../tcomb/templates/SegmentedControl';
import { MultiSelectTransformer } from '../../../../tcomb/transformers/transformers';
import { get, set, cloneDeep } from 'lodash-es';
import Boolean from '../../../../tcomb/templates/Boolean';

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

const formGroupOuterMargin = cloneDeep(formGroupOuter);
formGroupOuterMargin.formGroup.normal.marginTop = 10;

const checkBox = cloneDeep(stylesheet);
checkBox.formGroup.normal = {
  ...checkBox.formGroup.normal,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  borderBottomWidth: 0,
};
checkBox.controlLabel.normal = {
  ...checkBox.controlLabel.normal,
  color: '#000',
  fontSize: 18,
};

const templateOptions = {
  segmented: SegmentedControl,
  multiselect: MultiSelect,
  bool: Boolean,
};

export const option = (fieldConfig, state, ref) => {
  const template = get(fieldConfig, 'config.template', 'segmented');
  const multiple = get(fieldConfig, 'config.multiple', null);
  const help = get(fieldConfig, 'config.help.default');
  const helpOnValue = get(fieldConfig, 'config.help.onValue');
  const helpRef = get(fieldConfig, 'config.help.ref');

  const field = {
    label: fieldConfig.label,
    template: templateOptions[template],
  };

  const nullOption = get(fieldConfig, 'config.nullOption', null);

  if (nullOption !== null) {
    set(field, 'nullOption', nullOption);
  }

  if (template === 'multiselect') {
    set(field, 'transformer', MultiSelectTransformer);
  }

  if (multiple !== null) {
    set(field, 'config.multiple', multiple);
  }

  set(field, 'stylesheet', formGroupOuterMargin);

  if (help) {
    set(
      field,
      'config.help',
      <span
        style={{
          ...constants.styles.muted,
          ...constants.styles.extraSmall,
          ...{ marginTop: 2 },
        }}>
        {help}
      </span>,
    );
  }

  if (helpOnValue && helpRef) {
    const currentValue = get(state, `${helpRef}`);

    if (helpOnValue[currentValue]) {
      set(
        field,
        'config.help',
        <span
          style={{
            ...constants.styles.muted,
            ...constants.styles.extraSmall,
            ...{ marginTop: 2 },
          }}>
          {helpOnValue[currentValue]}
        </span>,
      );
    }
  }

  return field;
};
