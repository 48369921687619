import * as React from 'react';
// import type { Notification as NotificationType } from '../../flowTypes'
import WaitTime from './Types/WaitTime';
import AnalysisResult from './Types/AnalysisResult';
import AnalysisOrder from './Types/AnalysisOrder';
import {
  ANALYSIS_ORDER,
  ANALYSIS_RESULT,
  WAITTIME,
} from 'farmerjoe-common/lib/constants/notifications';

type Props = {
  notification: any; // NotificationType
  onClick?: (...args: Array<any>) => void;
};

export default function Notification({ notification, ...restProps }: Props) {
  switch (notification.notification_for) {
    case ANALYSIS_RESULT:
      return (
        <AnalysisResult
          key={notification.key}
          notification={notification}
          {...restProps}
        />
      );
    case ANALYSIS_ORDER:
      return (
        <AnalysisOrder
          key={notification.key}
          notification={notification}
          {...restProps}
        />
      );
    case WAITTIME:
      return (
        <WaitTime
          key={notification.key}
          notification={notification}
          {...restProps}
        />
      );
  }
  return null;
}
