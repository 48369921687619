import React from "react";
import { findDOMNode } from "react-dom";
import { isFunction } from "lodash-es";
import "./style.css";
import { classes, getScrollableAncestor } from "../../utils/dom";

type Props = {
  children?: any;
  initialNumToRender?: any;
  initialListSize?: any;
  ListHeaderComponent?: any;
  ListFooterComponent?: any;
  ItemSeparatorComponent?: any;
  renderItem: (arg0: any) => any;
  data?: Array<any>;
  ListEmptyComponent?: any;
  tabLabel?: any;
  className?: string;
  onEndReached?: () => void;
  endReachedThreshold: number;
  onScroll?: () => any;
  labelKey?: string;
  style?: React.CSSProperties;
};

export default class FlatList extends React.Component<Props> {
  ancestor?: Element;
  el?: Element;
  timeout?: NodeJS.Timeout;

  static defaultProps = {
    endReachedThreshold: 1,
  };

  componentDidMount() {
    this.updateElements();
    if (this.ancestor) {
      this.ancestor.addEventListener("scroll", this.checkIfEndReached);
    }
  }

  componentDidUpdate() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.updateElements();
    }, 0);
  }

  updateElements = () => {
    if (this.props.onEndReached) {
      const el = findDOMNode(this.refs.list);
      if (!el || !(el instanceof HTMLElement)) {
        return;
      }

      this.el = el;
      const ancestor = getScrollableAncestor(el);
      if (ancestor) {
        this.ancestor = ancestor as any;
      }
    }
  };

  componentWillUnmount() {
    if (this.ancestor) {
      this.ancestor.removeEventListener("scroll", this.checkIfEndReached);
    }
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    const {
      className,
      ListHeaderComponent,
      ListFooterComponent,
      ItemSeparatorComponent,
      renderItem,
      data,
      ListEmptyComponent,
    } = this.props;

    return (
      <div className={classes("flat-list", className)} ref="list">
        <div>
          {ListHeaderComponent && isFunction(ListHeaderComponent)
            ? ListHeaderComponent()
            : ListHeaderComponent}
        </div>
        {!data?.length && ListEmptyComponent
          ? isFunction(ListEmptyComponent)
            ? ListEmptyComponent()
            : ListEmptyComponent
          : null}
        {data?.length
          ? (
            <ul>
              {data.map((item, i) => {
                const itemEl = renderItem({ item: item });
                if (ItemSeparatorComponent) {
                  return [
                    <li key={`${i}-0`}>
                      {isFunction(itemEl) ? itemEl() : itemEl}
                    </li>,
                    <li key={`${i}-1`}>
                      {isFunction(ItemSeparatorComponent)
                        ? ItemSeparatorComponent()
                        : ItemSeparatorComponent}
                    </li>,
                  ];
                } else {
                  return (
                    <li key={`${i}-0`}>
                      {isFunction(itemEl) ? itemEl() : itemEl}
                    </li>
                  );
                }
              })}
            </ul>
          )
          : null}
        <div>
          {ListFooterComponent && isFunction(ListFooterComponent)
            ? ListFooterComponent()
            : ListFooterComponent}
        </div>
      </div>
    );
  }

  checkIfEndReached = () => {
    if (
      this.props.onEndReached &&
      this.ancestor &&
      this.el &&
      this.ancestor.getBoundingClientRect().bottom >=
        this.props.endReachedThreshold * this.el.getBoundingClientRect().bottom
    ) {
      this.props.onEndReached();
    }
  };
}
