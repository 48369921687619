import * as React from "react";
import { compose } from "redux";
import Page from "./Page";

import "./style.css";
import CompanyTitle from "./CompanyTitle";
import Companies from "../Companies/Companies";
import withRouter from "../Router/withRouter";
import ScreenTooSmallMessage from "../Common/ScreenTooSmallMessage";
import ActivationCheck from "../../containers/Activation/ActivationCheck";
import ProfileCompletedCheck from "../../containers/Profile/ProfileCompletedCheck";
import { isMapPage as isMapPageUtil } from "../../utils/page";

class ColumnLayoutPage extends React.Component<{
  children?: React.ReactNode;
  location?: Record<string, any>;
  key?: any;
}> {
  componentDidMount() {
    this.updateBodyClassName();
  }

  componentDidUpdate() {
    this.updateBodyClassName();
  }

  componentWillUnmount() {
    this.removeBodyClassName();
  }

  updateBodyClassName() {
    const isMapPage = isMapPageUtil();
    if (
      isMapPage &&
      document.body &&
      !document.body.classList.contains("map-page")
    ) {
      document.body.classList.add("map-page");
    } else if (!isMapPage) {
      this.removeBodyClassName();
    }
  }

  removeBodyClassName() {
    if (document.body && document.body.classList.contains("map-page")) {
      document.body.classList.remove("map-page");
    }
  }

  render() {
    const { children } = this.props;
    const isMapPage = isMapPageUtil();

    return (
      <Page
        before={[
          <Companies key={1} />,
          <ActivationCheck key={2} />,
          <ProfileCompletedCheck key={3} />,
        ]}
        title={<CompanyTitle />}
        footer={isMapPage ? null : null}>
        <div className="row column-layout">{children}</div>
        {/* <ScreenTooSmallMessage /> */}
      </Page>
    );
  }
}

export default compose<typeof ColumnLayoutPage>(withRouter)(ColumnLayoutPage);
