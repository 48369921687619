import { CellMeasurerCache } from 'react-virtualized/dist/commonjs/index';
import type { FixedCellMeasurerCacheProps } from './flow';

export class FixedCellMeasurerCache extends CellMeasurerCache {
  columnWidths: any[];
  defaultWidth?: number;
  _hasFixedHeight?: boolean;
  _columnCount?: number;
  _keyMapper?: (rowIndex: number, arg1: number) => any;
  _rowHeightCache?: any;

  constructor(props: FixedCellMeasurerCacheProps) {
    super(props);
    this.columnWidths = props.columnWidths || [];
  }

  setWidths(columnWidths: Array<number>) {
    this.columnWidths = [...columnWidths];
  }

  setWidth(columnIndex: number = 0, value: number) {
    this.columnWidths[columnIndex] = value;
  }

  getWidth(rowIndex: number, columnIndex: number = 0): number {
    return this.columnWidths[columnIndex] || this.defaultWidth;
  }

  columnWidth = ({ index }: { index: number }) => {
    return this.getWidth(0, index);
  };

  // copied from CellMeasurerCache
  _updateCachedColumnAndRowSizes(rowIndex: number, columnIndex: number) {
    if (!this._hasFixedHeight) {
      let rowHeight = 0;
      for (let i = 0; i < (this._columnCount as number); i++) {
        rowHeight = Math.max(rowHeight, (this as any).getHeight(rowIndex, i));
      }
      const rowKey = (this._keyMapper as any)(rowIndex, 0);
      this._rowHeightCache[rowKey] = rowHeight;
    }
  }
}
