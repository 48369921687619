import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import { get } from "lodash-es";
import md5 from "md5";
import { firestoreConnect } from "react-redux-firebase";
import { getInviteQueries } from "farmerjoe-common/lib/utils/firestoreRedux/Invites";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { isEmailVerified } from "../utils/auth";

export default compose(
  connect(state => {
    const profile = state.firebase.profile;
    let invites: any;

    if (profile.email) {
      invites = get(state, [
        "firestore",
        "data",
        "invites",
        profile.email,
        "companies",
      ]);

      // Starting from 2.1 we no longer use the md5 hash, but we do this here in order to
      // properly accept invitations made on old versions of the app
      const oldInvites = get(state, [
        "firestore",
        "data",
        "invites",
        md5(profile.email),
        "companies",
      ]);
      if (oldInvites) {
        invites = {
          ...invites,
          ...oldInvites,
        };
      }
    }

    const auth = state.firebase.auth;

    let loadingInvites = auth.emailVerified === true;

    if (isEmailVerified(auth.email, auth.emailVerified)) {
      loadingInvites = !hasLoaded(getInviteQueries(profile.email), state);
    }

    return {
      profile: profile,
      invites: invites,
      loadingInvites: loadingInvites,
      auth: auth,
    };
  }),
  firestoreConnect(props => {
    if (isEmailVerified(props.auth.email, props.auth.emailVerified)) {
      return getInviteQueries(props.profile.email);
    }

    return [];
  }),
);
