import * as React from 'react';

import Box from '../../../components/Common/Box/Box';
import stylesheet from '../../../tcomb/stylesheets/style';
import I18n from '../../../language/i18n';

import { get, map, cloneDeep } from 'lodash-es';

import { option as stringOption } from './fields/string';
import { option as dateOption } from './fields/date';
import { option as enumOption } from './fields/enum';
import { option as boolOption } from './fields/bool';
import { option as imageOption } from './fields/image';

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

const formGroupOuterMargin = cloneDeep(formGroupOuter);
formGroupOuterMargin.formGroup.normal.marginTop = 10;

const checkBox = cloneDeep(stylesheet);
checkBox.formGroup.normal = {
  ...checkBox.formGroup.normal,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  borderBottomWidth: 0,
};
checkBox.controlLabel.normal = {
  ...checkBox.controlLabel.normal,
  color: '#000',
  fontSize: 18,
};

const negativeFieldset = cloneDeep(stylesheet);
negativeFieldset.formGroup.normal = {
  ...negativeFieldset.formGroup.normal,
  marginLeft: 10,
  marginRight: 0,
};
const fieldSet = cloneDeep(stylesheet);
fieldSet.fieldset = {
  ...fieldSet.fieldset,
  margin: 10,
};

function template(locals, formConfig) {
  // in locals.inputs you find all the rendered fields
  return (
    <div>
      {map(formConfig.order, (blockName, key) => {
        const block = get(formConfig, `blocks.${blockName}`);

        // if the block is not existing for some reason return null
        if (!block) {
          return null;
        }

        return (
          <Box
            key={blockName}
            header={block.header}
            content={locals.inputs[blockName]}
          />
        );
      })}
    </div>
  );
}

/**
 *
 * @param field
 * @param key
 * @param state  - the state of the form
 * @param ref    - Ref to the rendered form component
 * @returns {{}}
 */
const getConfObject = (field, key, state, ref, companyId) => {
  if (['string', 'number'].includes(field.type)) {
    return { [key]: stringOption(field) };
  }

  if (field.type === 'date') {
    const fieldName = key;
    return { [key]: dateOption(field, fieldName) };
  }

  if (field.type === 'enum') {
    return { [key]: enumOption(field, state, ref) };
  }

  if (field.type === 'boolean') {
    return { [key]: boolOption(field) };
  }

  if (field.type === 'image') {
    return { [key]: imageOption(field, state, ref, companyId) };
  }

  throw new Error('Unexpected field type: ' + field.type);
};

export const generateOptions = ({ config, state, ref, companyId }) => {
  const currentLanguage = I18n.locale;

  const formConfig =
    config.translations[currentLanguage] ||
    config.translations[config.defaultLanguage];

  const dynConfig = {
    stylesheet: stylesheet,
    template: locals => template(locals, formConfig),

    auto: 'label',

    // go over the blocks
    fields: map(formConfig.blocks, (block, key) => {
      if (!block.fields) {
        return getConfObject(block, key, state, ref, companyId);
      }

      // go over the fields in the blocks
      return {
        [key]: {
          fields: map(block.fields, (field, fieldKey) => {
            return getConfObject(field, fieldKey, state, ref, companyId);
          }).reduce((prev, curr) => {
            return {
              ...prev,
              ...curr,
            };
          }, {}),
        },
      };
    }).reduce((prev, curr) => {
      return {
        ...prev,
        ...curr,
      };
    }, {}),
  };

  return dynConfig;
};
