import React from "react";
import moize from "moize";
import { entries, get, sortBy } from "lodash-es";

import { getColor } from "farmerjoe-common/lib/utils/Colors";

import MarkerWithLabel from "./MarkerWithLabel";
import type { ClusterFeature } from "../../flowTypes";
import { appPosToMapPos, geoJsonToAppPos } from "../../utils/Map";

type Props = {
  cluster: ClusterFeature;
  map: google.maps.Map;
  onClick?: () => void;
  key?: string;
};

export default function ClusterMarker({ cluster, onClick, map }: Props) {
  const colors = get(cluster, "properties.colors");
  const pointCount = get(cluster, "properties.point_count");
  return (
    <MarkerWithLabel
      position={appPosToMapPos(geoJsonToAppPos(cluster.geometry.coordinates))}
      noTriangle={true}
      noClustering={true}
      onClick={onClick}
      labelClass="cluster-marker"
      height={45}
      width={45}
      map={map}
    >
      <div
        style={{
          backgroundImage: "url(" + getPieChart(colors, pointCount) + ")",
        }}>
        <span>{pointCount}</span>
      </div>
    </MarkerWithLabel>
  );
}

const cos = Math.cos;
const sin = Math.sin;
const PI = Math.PI;

function getPieChart(colors, total) {
  let sum = 0;
  const svg = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    ${sortBy(entries(colors), ([color, count]) => count)
    .map(([color, count]) => {
      const radius = 32;
      const angleStart = (sum / total) * PI * 2 - PI / 2;
      const angleEnd = ((sum + count) / total) * PI * 2 - PI / 2;
      const sPointX = cos(angleStart) * radius + radius;
      const sPointY = sin(angleStart) * radius + radius;
      const largeArc = angleEnd - angleStart > PI ? 1 : 0;
      const ePointX = cos(angleEnd) * radius + radius;
      const ePointY = sin(angleEnd) * radius + radius;

      sum += count;
      if (count === total) {
        return `<circle r="${radius}" cx="50%" cy="50%" style="fill: ${getColor(
          color,
        )}; stroke-width: 1; stroke: #88888860;"/>`;
      } else {
        return `<path d="
M ${radius} ${radius} 
L ${sPointX} ${sPointY} 
A ${radius} ${radius} 0 ${largeArc} 1 ${ePointX} ${ePointY} 
Z
" style="fill: ${getColor(color)}; stroke-width: 1; stroke: #88888860;"/>`;
      }
      // return `<circle
      //   r="25%"
      //   cx="50%"
      //   cy="50%"
      //   style="
      //     fill: none;
      //     stroke-width: 32;
      //     stroke-dasharray: ${((sum / total * 100) || 0).toFixed(1)} 100;
      //     stroke: ${getColor(color)};
      // "/>`
    })
    .reverse()
    .join("\n")}
    <circle r="20" cx="50%" cy="50%" style="fill: #fff; stroke: #88888860; stroke-width: 1;"/>
  </svg>
`;
  const encoded = window.btoa(svg);
  // if (map(colors, count => count).reduce((sum, count) => sum+count, 0) === 4) {
  // }
  return "data:image/svg+xml;base64," + encoded;
}

const memoizedGetGoogleClusterInlineSvg = moize(
  color => `url(${getGoogleClusterInlineSvg(color)})`,
);
function getGoogleClusterInlineSvg(color) {
  const encoded = window.btoa(`
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-62 -62 124 124"> <!-- viewBox="-100 -100 200 200" -->
    <defs>
      <g id="a" transform="rotate(45)">
        <path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.5"/>
        <!--<path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/>-->
        <!--<path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/>-->
        <!--<path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/>-->
      </g>
    </defs>
    <g fill="${color}">
      <circle r="42"/>
      <use xlink:href="#a"/>
      <g transform="rotate(120)">
        <use xlink:href="#a"/>
      </g>
      <g transform="rotate(240)">
        <use xlink:href="#a"/>
      </g>
    </g>
  </svg>
`);
  return "data:image/svg+xml;base64," + encoded;
}
