import React from "react";
import { defaults, isNumber } from "lodash-es";
import PropTypes from "prop-types";
import { classes } from "../../utils/dom";
import FixedMarkerWithLabel from "./FixedMarkerWithLabel";

const pinDefaults = {
  width: 40,
  height: 30,
  color: "#ee4039",
};

export type MarkerProps = {
  children?: React.ReactNode;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  color?: string;
  width?: number | string;
  height?: number | string;
  labelClass?: string;
  zIndex?: number;
  labelStyle?: Record<string, any>;
  onClick?: (...args: Array<any>) => any;
  className?: string;
  pinColor?: string;
  noTriangle?: boolean;
  onMarkerRef?: (marker: google.maps.Marker) => any;
  reorderEvents?: boolean;
  noClustering?: boolean;
  layer?: string;
  map: google.maps.Map;
};

export default class Marker extends React.Component<MarkerProps> {
  static contextTypes = {
    spiderClusterer: PropTypes.bool,
  };

  static defaultProps = {
    reorderEvents: true,
  };

  onMarkerRef(marker: google.maps.Marker) {
    if (this.props.onMarkerRef) {
      this.props.onMarkerRef(marker);
    }
  }

  render() {
    const {
      position,
      color,
      width,
      height,
      zIndex,
      labelStyle,
      children,
      onClick,
      className,
      pinColor,
      noTriangle = false,
      onMarkerRef,
      labelClass,
      reorderEvents,
      ...restProps
    } = this.props;
    const pinOptions = defaults(
      {},
      {
        color,
        width,
        height,
      },
      pinDefaults,
    );

    return (
      <FixedMarkerWithLabel
        className={className}
        position={position}
        labelAnchor={{
          x: isNumber(pinOptions.width)
            ? pinOptions.width / 2
            : pinOptions.width,
          y: pinOptions.height,
        }}
        icon={{ path: "" }}
        zIndex={zIndex || 100}
        labelClass={classes("marker-label", classes(labelClass))}
        labelStyle={{
          width: isNumber(pinOptions.width)
            ? pinOptions.width + "px"
            : pinOptions.width,
          height: isNumber(pinOptions.height)
            ? pinOptions.height + "px"
            : pinOptions.height,
          ...labelStyle,
        }}
        // onSpiderClick={this.context.spiderClusterer ? onClick : null}
        onClick={e => {
          if (onClick) {
            if (reorderEvents) {
              // google maps events come before react events in the label so reorder them
              setTimeout(() => onClick(e), 0);
            } else {
              onClick(e);
            }
          }
        }}
        noRedraw={true}
        {...restProps}>
        {/* <div onClick={this.context.spiderClusterer ? null : onClick}> */}
        {children}
        {!noTriangle
          ? (
            <div
              className="triangle"
              style={{
                borderColor: `${pinColor ||
                "#fff"} transparent transparent transparent`,
              }}
            />
          )
          : null}
        {/* </div> */}
      </FixedMarkerWithLabel>
    );
  }
}
