import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { addKeyToObj } from '../../data/util';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';

import * as analysisActions from 'farmerjoe-common/lib/actions/analysis';

import { map } from 'lodash-es';

import { Loading } from '../Loading/Loading';
import NoResults from '../Common/NoResults';

import Icon from '../Common/Icon';

import { isLoaded, firestoreConnect } from 'react-redux-firebase';

import { getWarehouses } from 'farmerjoe-common/lib/selectors/warehouses';
import FlatList from '../Common/FlatList';
import FormAddWarehouse from './FormAddWarehouse';
import Modal from 'react-bootstrap-modal';
import { getCountryName } from '../../utils/Countries';
import { CreateNewButton } from '../Common/NewButton';

// TODO: improve typings
type Props = any;
type State = any;

class FormSelectWarehouse extends Component<Props, State> {
  private invoiceAddress: any;

  static propTypes = {
    company: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      labSelected: '',
      labOffer: '',
      stock: null,
    };
  }

  render() {
    const { showAddWarehouseForm } = this.state;
    let { warehouses, loading } = this.props;
    if (loading) {
      return <Loading />;
    }
    warehouses = map(warehouses, value => value);

    return [
      <Modal.Header closeButton={false} key="modal-header">
        <Modal.Title>{I18n.t('warehouse.warehouse')}</Modal.Title>
        {showAddWarehouseForm
          ? null
          : (
          <CreateNewButton
            onClick={() => this.setState({ showAddWarehouseForm: true })}
          />
            )}
      </Modal.Header>,
      <Modal.Body onClick={e => e.stopPropagation()} key="modal-body">
        <FlatList
          style={{ paddingTop: 10 }}
          data={warehouses}
          ListEmptyComponent={
            <NoResults text={I18n.t('warehouse.noWarehousesCreated')} />
          }
          renderItem={item => this._renderItem(item.item)}
          ItemSeparatorComponent={() => (
            <div>
              <hr />
            </div>
          )}
        />

        {this.state.showAddWarehouseForm
          ? (
          <FormAddWarehouse
            show={this.state.showAddWarehouseForm}
            onClose={() => {
              this.setState({ showAddWarehouseForm: false });
            }}
            onSave={warehouse => {
              this.props.actions.analysisWarehouse(warehouse);
              this.props.openView('CreateAnalysisForm', {
                stock: this.props.stock,
              });
            }}
          />
            )
          : null}
      </Modal.Body>,
      <Modal.Footer onClick={e => e.stopPropagation()} key="modal-footer">
        {this.state.showAddWarehouseForm
          ? (
          <div className="d-flex flex-grow-1">
            <button
              className="btn btn-secondary"
              onClick={() =>
                this.setState({
                  showAddWarehouseForm: false,
                  invoice_address: null,
                })
              }>
              {I18n.t('cancel')}
            </button>{' '}
            <button
              className="ml-auto btn btn-primary"
              onClick={() => {
                this.invoiceAddress.onSave();
              }}>
              {I18n.t('done')}
            </button>
          </div>
            )
          : (
          <div className="d-flex flex-grow-1">
            <button
              className="btn btn-secondary"
              onClick={this.goBack.bind(this)}>
              {I18n.t('back')}
            </button>
          </div>
            )}
      </Modal.Footer>,
    ];
  }

  goBack() {
    this.props.actions.analysisWarehouse(null);
    this.props.goBack();
  }

  _renderItem(warehouse) {
    const street = warehouse.street ? <span>{warehouse.street}</span> : null;
    const zipCity =
      warehouse.city || warehouse.zip
        ? (
        <span>
          {warehouse.zip} {warehouse.city}
        </span>
          )
        : null;
    const country = warehouse.country
      ? (
      <span>{getCountryName(warehouse.country)}</span>
        )
      : null;
    return (
      <div
        key={warehouse.key}
        className={'analysis-warehouse-item'}
        onClick={() => {
          this.props.actions.analysisWarehouse(warehouse);
          this.props.openView('CreateAnalysisForm', {
            stock: this.props.stock,
          });
        }}
        style={{ cursor: 'pointer' }}>
        <div className={'d-flex flex-row justify-content-between flex-grow-1'}>
          <div style={{ justifyContent: 'center' }}>
            <div
              style={{
                ...constants.styles.strong,
                ...{
                  fontSize: 20,
                  paddingBottom: 2,
                  paddingRight: 50,
                },
              }}>
              {warehouse.name}
            </div>

            <div>{street}</div>
            <div>{zipCity}</div>
            <div>{country}</div>
            <div>
              {warehouse.position.latitude.toFixed(6)}{' '}
              {warehouse.position.longitude.toFixed(6)}
            </div>
          </div>
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Icon
              style={{ right: 20, fontSize: 25, color: constants.FJNAVCOLOR }}
              name="ios-arrow-forward"
            />
          </div>
        </div>
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);

  const warehouses = getWarehouses(state, openCompany);
  return {
    stock: ownProps.stock,
    company: addKeyToObj(openCompany, company),
    warehouses: warehouses,
    loaded: isLoaded(warehouses),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...analysisActions,
        },
      ),
      dispatch,
    ),
  };
}

const wrappedFormSelectWarehouse = firestoreConnect(props => {
  const companyId = props.company.key;

  if (companyId) {
    return [
      {
        collection: 'warehouses',
        where: [['company_id', '==', companyId]],
        byIds: `warehouses/${companyId}`,
      },
    ];
  }

  return [];
})(FormSelectWarehouse);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFormSelectWarehouse);
