import * as React from 'react';
import imgFjLogo from '../../public/images/FJ_logo.png';

export default function Logo() {
  return (
    <div className={'mt-3 mb-5 text-center'}>
      <img style={{ width: 120 }} src={imgFjLogo} />
    </div>
  );
}
