import React from "react";
import Modal from "react-bootstrap-modal";
import Icon from "../Common/Icon";
import { FJAPPLEGREEN } from "../../styles/style";
import I18n from "../../language/i18n";

const stopPropagation = e => e.stopPropagation();

type TProps = {
  show: boolean;
  onClose: () => void;
  title: string;
  msg?: string;
};

const SuccessDialog = (props: TProps) => {
  const { show, onClose, title, msg } = props;
  return (
    <Modal
      show={show}
      dialogClassName="buy-seats-modal"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        <div
          style={{textAlign: "center"}}
        >
          <Icon
            name={"check-circle"}
            iconType={"fa"}
            light
            style={{ color: FJAPPLEGREEN, fontSize: 150 }}
          />

          <div
            style={{
              padding: 10,
            }}>
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
              }}>
              {I18n.t("billing.success")}
            </div>
          </div>
          <p>
            {msg}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessDialog;
