import React from "react";
import { numberTransformer } from "../transformers/transformers";
import { get } from "lodash-es";

import { yieldPerHa } from "farmerjoe-common";

import t from "tcomb-form";
import FormInput from "./FormInput";

const Component = t.form.Component;

class Yield extends Component {
  getTemplate() {
    return locals => {
      let value = "";

      const ha = get(locals, "config.ha", 0);

      if (locals.value) {
        value = locals.value;
      }

      const tha = yieldPerHa(numberTransformer.parse(locals.value), ha);

      return (
        <div className={"d-flex justify-content-between mt-3 align-items-end"}>
          <FormInput
            {...this.props}
            options={{
              ...this.props.options,
              label: locals.label,
              keyboardType: "numeric",

              transformer: numberTransformer,
              hasError: locals.hasError,
            }}
            ref="input"
            value={value}
          />
          {tha !== null
            ? (
              <div className={"ml-3 text-right mb-3"}>
                <div className={"fj-small fj-muted"}>t/ha</div>
                <div className={"text-right "}>{isNaN(tha) ? "" : tha}</div>
              </div>
            )
            : null}
        </div>
      );
    };
  }
}

export default Yield;
