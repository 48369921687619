import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updatePosition } from "farmerjoe-common/lib/actions/position";
import { getFieldsFilterOptions } from "farmerjoe-common/lib/utils/Filter";
import { filters } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";

import NoFields from "./NoFields";
import { Loading } from "../Loading/Loading";
import I18n from "../../language/i18n";
import { isAdmin } from "farmerjoe-common/lib/utils/User";

import SingleField from "./SingleField";
import IconHeader from "../Common/IconHeader";

import * as constants from "../../styles/style";

import type { Employee } from "../../flowTypes";
import FlatList from "../Common/FlatList";
import { AlertDialog } from "../Dialog/Dialog";

import "./style.css";
import { classes } from "../../utils/dom";
import { clearWatch, watchPosition } from "../../utils/geolocation";
import { get } from "lodash-es";
import NoResultsResetFilter from "./NoResultsResetFilter";
import NoResults from "../Common/NoResults";

type Props = {
  fields: Array<any>;
  onClick: (...args: Array<any>) => any;
  filter: Record<string, any>;
  waitTimes: [];
  myCompanyProfile: Employee;
  ListHeaderComponent: (...args: Array<any>) => any;
  onScroll?: (...args: Array<any>) => any;
  locationPermission?: boolean;
  actions?: {
    updatePosition: (...args: Array<any>) => any;
  };
  openFieldPath: Record<string, any>;
  isMapPage: boolean;
  cropState: "active" | "harvested";
  openCompany?: any;
  displaySettings?: any;
  tabLabel?: string;
  loading?: boolean;
  isActiveTab?: boolean;
};

type State = {
  hasErrorAskingLocation: boolean;
  display?: any;
};

class FieldsListNearBy extends React.PureComponent<Props, State> {
  watchPositionId?: number;

  static defaultProps = {
    ListHeaderComponent: null,
  };

  state: State = {
    hasErrorAskingLocation: false,
  };

  updateLocation(location) {
    this.props.actions?.updatePosition({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    });
  }

  componentDidMount() {
    this.watchPosition();
  }

  item = item => {
    let { onClick, waitTimes, myCompanyProfile, openFieldPath } = this.props;
    const el = item.item;

    const selected =
      openFieldPath &&
      openFieldPath.field.key === el.key &&
      (!openFieldPath.crop || openFieldPath.crop.key === el.activeCrop.key);

    waitTimes = waitTimes && waitTimes[el.key] ? waitTimes[el.key] : [];

    return (
      <div
        key={"field" + el.key + el.activeCrop.key}
        className={classes(
          "list-item nearby-field-list-item",
          selected && "selected",
        )}
        onClick={() => onClick(el.key, el.activeCrop.key)}>
        <SingleField
          field={el}
          crop={el.activeCrop}
          waitTimes={waitTimes}
          myCompanyProfile={myCompanyProfile}
          showDistance={true}
          simpleCropDisplay={false}
          lastComment={el.activeCrop.lastComment}
        />
      </div>
    );
  };

  watchPosition = () => {
    this.watchPositionId = watchPosition(
      this.updateLocation.bind(this),
      (e) => {
        this.setState({
          hasErrorAskingLocation: true,
        });
      },
      {
        timeout: 10000,
        maximumAge: 5000,
      },
    ) as any;
  };

  changeToFirstFilterOption = () => {
    const { openCompany, displaySettings, cropState } = this.props;
    const data = getFieldsFilterOptions();
    const filterOptions = data[cropState as any];

    (this.props.actions as any).filters(openCompany, {
      displaySettings: {
        ...displaySettings,
        [cropState as any]: {
          ...this.state.display,
          sort: filterOptions[0].value,
        },
      },
    });
  };

  componentWillUnmount() {
    clearWatch(this.watchPositionId);
  }

  render() {
    const { fields } = this.props;

    if (!fields) {
      return <Loading />;
    }

    return (
      <>
        <FlatList
          className="field-list-nearby"
          initialNumToRender={10}
          ListHeaderComponent={
            <>
              {this.props.ListHeaderComponent}

              <IconHeader
                icon={"ios-pin"}
                iconStyle={{
                  color: constants.FJMUTED,
                  marginRight: 10,
                  fontSize: 12,
                }}
                text={I18n.t("near.fields")}
              />
            </>
          }
          renderItem={this.item}
          data={fields}
          ListEmptyComponent={this.emptyView()}
          onScroll={this.props.onScroll}
        />
        <AlertDialog
          show={this.state.hasErrorAskingLocation}
          onClose={() => {
            this.setState({ hasErrorAskingLocation: false });
            // In case if the user rejected location permission, we should redirect to the first filter option
            this.changeToFirstFilterOption();
          }}
          title={I18n.t("alerts.needLocation.description")}
          children={I18n.t("alerts.needLocation.title")}
          key={1}
        />
      </>
    );
  }

  emptyView = () => {
    const { myCompanyProfile, filter, fields, isMapPage } = this.props;

    const showCrops = get(filter, "showCrops");
    const search = get(filter, "search");

    if (!isMapPage && fields && fields.length === 0 && search) {
      return (
        <div style={{ flex: 1 }} /* tabLabel={I18n.t('current')} */ >
          <NoResults text={I18n.t("noSearchResults")} />
        </div>
      );
    } else if (isMapPage && (search || (showCrops && showCrops.length !== 3))) {
      return <NoResultsResetFilter tabLabel={I18n.t("current")} />;
    } else {
      return (
        <NoFields
          tabLabel={I18n.t("current")}
          showCreate={isAdmin(myCompanyProfile)}
        />
      );
    }
  };
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  const displaySettings = state.filtersByCompany[openCompany]
    ? state.filtersByCompany[openCompany].displaySettings
    : {};

  return {
    locationPermission: state.locationPermission,
    openCompany,
    displaySettings,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updatePosition,
          filters,
        },
      ),
      dispatch,
    ),
  };
}

export default (connect(
  selector,
  mapDispatchToProps,
)(FieldsListNearBy as any) as any as (typeof FieldsListNearBy));
