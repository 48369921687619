import React from 'react';

type Props = {
  value?: any;
};

export default class HiddenComponent extends React.PureComponent<Props> {
  render() {
    return null;
  }

  validate() {
    return {
      value: this.getValue(),
      errors: [],
    };
  }

  getValue() {
    return this.props.value;
  }
}
