import React from 'react';
import { throttle } from 'lodash-es';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { orderWaitTimesByFieldId } from 'farmerjoe-common/lib/selectors/waittimes';
import { getColor } from 'farmerjoe-common';
import { getFieldSharingRequests } from 'farmerjoe-common/lib/selectors/fields';
import { isSharedField } from 'farmerjoe-common/lib/utils/Field';
import * as fieldSharingActions from 'farmerjoe-common/lib/actions/fieldSharing';
import { openField } from 'farmerjoe-common/lib/actions/field';
import { runLoader } from 'farmerjoe-common/lib/actions/ui';

import './style.css';

import SingleField from '../Fields/SingleField';
import FieldInfo from '../FieldsSharing/FieldInfo';
import BtnAccept from '../FieldsSharing/BtnAccept';
import BtnReject from '../FieldsSharing/BtnReject';
import CropIcon from '../Common/CropIcon';
import withRouter from '../Router/withRouter';
import { AlertDialog } from '../Dialog/Dialog';
import { Loading } from '../Loading/Loading';

import { classes } from '../../utils/dom';
import type { Crop, Field } from '../../flowTypes';

type Props = {
  isOpen?: boolean;
  children?: React.ReactNode;
  hintContentStyle?: Record<string, any>;
  className?: string;
  field: Field;
  activeCrop: Crop;
  onStateChange?: (arg0: Record<string, any>) => void;
  waitTimes?: Record<string, any/* WaitTime */>;
  myCompanyProfile?: any; // Employee
  style?: React.CSSProperties;
  onClick?: (e: any) => any;
  dispatch?: any;
  currentRequest?: any;
  userProfile?: any;
  openCompany?: string;
  actions?: any;
  history?: any;
};

type State = {
  showBalloon?: boolean;
  balloonIsClosing?: boolean | string;
  balloonIsOpening: boolean;
  alertDialog?: {
    isVisible: boolean;
    title?: string;
    message?: string | React.ReactNode;
  };
  loading?: boolean;
};

class FieldInfoBalloon extends React.Component<Props, State> {
  state = {
    showBalloon: false,
    balloonIsClosing: false,
    balloonIsOpening: false,
    alertDialog: {
      isVisible: false,
      title: '',
      message: '',
    },
    loading: false,
  };

  mounted = false;
  div = React.createRef<HTMLDivElement>();
  balloonIsClosingTimeout?: NodeJS.Timeout;
  balloonIsOpeningTimeout?: NodeJS.Timeout;

  componentWillUpdate(nextProps: Props, nextState: State) {
    if (this.props.isOpen !== nextProps.isOpen) {
      if (this.balloonIsClosingTimeout) clearTimeout(this.balloonIsClosingTimeout);

      if (!nextProps.isOpen) {
        this.changeState({
          showBalloon: false,
          balloonIsClosing: 'isOpen',
          balloonIsOpening: false,
        });
        this.balloonIsClosingTimeout = setTimeout(() => {
          if (this.mounted) {
            this.changeState({
              balloonIsClosing: false,
              balloonIsOpening: false,
            });
          }
        }, 300);
      }
    }
    if (this.state.showBalloon !== nextState.showBalloon) {
      if (this.balloonIsClosingTimeout) clearTimeout(this.balloonIsClosingTimeout);

      if (!nextState.showBalloon) {
        this.changeState({
          balloonIsClosing: 'showBalloon',
          balloonIsOpening: false,
        });
        this.balloonIsClosingTimeout = setTimeout(() => {
          if (this.mounted) {
            this.changeState({
              balloonIsClosing: false,
              balloonIsOpening: false,
            });
          }
        }, 300);
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevState.balloonIsOpening && this.state.balloonIsOpening) {
      if (this.balloonIsOpeningTimeout) clearTimeout(this.balloonIsOpeningTimeout);
      this.balloonIsOpeningTimeout = setTimeout(() => {
        if (this.mounted) {
          this.changeState({
            showBalloon: true,
            balloonIsOpening: false,
          });
        }
      }, 0);
    } else if (prevState.balloonIsOpening && !this.state.balloonIsOpening) {
      if (this.balloonIsOpeningTimeout) clearTimeout(this.balloonIsOpeningTimeout);
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    if (document.body) {
      document.body.removeEventListener('mousemove', this.onMouseMove);
    }
  }

  private showAlert(title: string, message: string | React.ReactNode) {
    this.setState({
      alertDialog: { isVisible: true, title, message },
    });
  }

  private closeAlert() {
    this.setState({
      alertDialog: { isVisible: false },
    });
  }

  render() {
    const {
      children,
      isOpen,
      className,
      hintContentStyle,
      field,
      activeCrop,
      onStateChange,
      myCompanyProfile,
      waitTimes,
      dispatch,
      openCompany,
      ...restProps
    } = this.props;

    if (!field) {
      return null;
    }

    if (this.state.loading) {
      return <Loading />;
    }

    const showHint = this.state.showBalloon || isOpen;
    const balloonIsClosing = this.state.balloonIsClosing;
    const renderHint =
      showHint || balloonIsClosing || this.state.balloonIsOpening;

    const fieldIsShared = isSharedField(field.company_id, openCompany as any);

    return (
      <div
        {...restProps}
        className={classes(
          'field-info-balloon hint hint--html',
          showHint ? 'hint--always' : 'hint--hidden',
          this.state.showBalloon && 'hint--balloon',
          isOpen && 'hint--hoverable expanded',
          className,
        )}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.div}>
        {children}
        {renderHint
          ? (
            <div style={hintContentStyle} className={classes('hint__content')}>
              {fieldIsShared && !field.accepted && this.props.currentRequest ? (
                this.renderFieldSharingRequest()
              ) : (
                this.renderFieldInfo()
              )
              }
            </div>
          )
          : null}
        <AlertDialog
          show={this.state.alertDialog.isVisible}
          onClose={this.closeAlert.bind(this)}
          title={this.state.alertDialog.title}
          children={this.state.alertDialog.message}
          key="alert"
        />
      </div>
    );
  }

  onMouseLeave = () => {
    const { isOpen } = this.props;
    const { showBalloon } = this.state;
    if (!isOpen && (showBalloon !== false || this.state.balloonIsOpening)) {
      this.changeState({ showBalloon: false, balloonIsOpening: false });
      if (document.body) {
        document.body.removeEventListener('mousemove', this.onMouseMove);
      }
    }
  };

  onMouseEnter = () => {
    if (this.state.showBalloon !== true || !this.state.balloonIsOpening) {
      this.changeState({ balloonIsOpening: true });

      if (this.div.current && document.body) {
        document.body.addEventListener('mousemove', this.onMouseMove);
      }
    }
  };

  /**
   * this function is a workaround for firefox not calling onMouseLeave in some cases
   */
  onMouseMove = throttle(({ pageX, pageY }) => {
    if (this.state.showBalloon && this.div.current) {
      const boundingBox = this.div.current.getBoundingClientRect();
      if (
        pageY < boundingBox.top ||
        pageY > boundingBox.bottom ||
        pageX < boundingBox.left ||
        pageX > boundingBox.right
      ) {
        this.onMouseLeave();
      }
    }
  }, 500);

  renderFieldInfo() {
    const { field, activeCrop, waitTimes, myCompanyProfile } = this.props;
    const bubbleColor = getColor(activeCrop.color);

    const waitTimesForField =
      waitTimes && waitTimes[field.key] ? waitTimes[field.key] : [];

    return (
      <React.Fragment>
        <div style={{ backgroundColor: bubbleColor }} className={'cropTag'}>
          <CropIcon crop={activeCrop} />
          <span>{activeCrop.name}</span>
        </div>
        <SingleField
          field={field}
          crop={activeCrop}
          waitTimes={waitTimesForField}
          myCompanyProfile={myCompanyProfile}
          lastComment={activeCrop.lastComment}
        />
      </React.Fragment>
    );
  }

  renderFieldSharingRequest() {
    const { activeCrop, currentRequest, userProfile, openCompany, actions } = this.props;
    const { field_id } = currentRequest;
    const bubbleColor = getColor(activeCrop.color);

    return (
      <React.Fragment>
        <div style={{ backgroundColor: bubbleColor }} className={'cropTag'}>
          <CropIcon crop={activeCrop} />
          <span>{activeCrop.name}</span>
        </div>
        <FieldInfo fieldSharingRequest={currentRequest} />

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
          <BtnReject
            onClick={() => {
              return actions
                .rejectFieldSharingRequest(
                  userProfile,
                  openCompany,
                  currentRequest.key,
                )
                .then(() => {
                  actions.openFieldSharingRequest(null);
                  this.props.history.push(`/company/${openCompany}/field-sharing-requests`);
                })
                .catch((e) => {
                  console.log('Error occured while rejecting the field sharing request: ', e);
                });
            }}
          />
          <BtnAccept
            onClick={() => {
              this.setState({ loading: true });
              return actions
                .acceptFieldSharingRequest(userProfile, openCompany, currentRequest.key)
                .then(() => {
                  actions.openField(field_id);
                  actions.openFieldSharingRequest(null);
                  actions.runLoader(true);
                  this.props.history.push(`/company/${openCompany}/field/${field_id}`);
                })
                .catch((e) => {
                  this.showAlert('Error', e.message);
                  console.log('Error occured while accepting the field sharing request: ', e); // TODO: modal with error?
                })
                .finally(() => this.setState({ loading: false }));
            }}
          />
        </div>
      </React.Fragment>
    );
  }

  changeState = (state: State) => {
    this.setState(state);
    if (this.props.onStateChange) {
      this.props.onStateChange(state);
    }
  };
}

const selector = (state, ownProps) => {
  const { field } = ownProps;
  const openCompany = getOpenCompanyId(state);
  const fieldSharingRequests = getFieldSharingRequests(state, openCompany);
  const currentRequest = fieldSharingRequests.find((request) => request.field.key === field.key);
  const userProfile = state.firebase.profile;
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const waitTimes = orderWaitTimesByFieldId(state, openCompany);

  return {
    myCompanyProfile,
    waitTimes,
    userProfile,
    currentRequest,
    openCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldSharingActions,
          openField,
          runLoader,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof FieldInfoBalloon>(
  connect(selector, mapDispatchToProps),
  withRouter,
)(FieldInfoBalloon);
