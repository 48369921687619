import React from 'react';
import I18n from '../../language/i18n';
import NoResults from '../Common/NoResults';

import Employee from './AddEmployeeToField';

import { Loading } from '../Loading/Loading';
import SearchInput from '../Common/SearchInput';
import TabBarSearchSort from '../Common/TabBarSearchSort';
import { hasAccessToField } from 'farmerjoe-common/lib/selectors/user';
import FlatList from '../Common/FlatList';
import ScrollableTabView from '../Common/ScrollableTabView';
import Dialog from '../Dialog/Dialog';
import './style.css';
import { sortUsersAndMoveCurrentUserToTop } from 'farmerjoe-common';
import { UserRole } from 'farmerjoe-common/lib/flow/types';

type Props = {
  users?: any[];
  field?: any;
  company?: any;
  loading?: boolean;
  actions?: any;
  search?: any;
  show?: any;
  onClose?: any;
  auth?: any;
};
type State = any;

export default class EmployeesOnField extends React.PureComponent<Props, State> {
  render() {
    let {
      users,
      field,
      company,
      loading,
      actions,
      search,
      show,
      onClose,
      auth,
    } = this.props;
    if (loading) {
      return <Loading />;
    }

    users = sortUsersAndMoveCurrentUserToTop(users as any, auth);

    return (
      <Dialog
        show={show}
        onClose={onClose}
        dialogClassName={'employees-on-field'}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-primary" onClick={onClose}>
              {I18n.t('ok')}
            </button>
          </div>
        }>
        <ScrollableTabView
          renderTabBar={() => (
            <TabBarSearchSort
              searchComponent={
                <SearchInput
                  onChange={text => {
                    actions.filters(company.key, {
                      userSearch: text,
                    });
                  }}
                  company={company}
                  search={search}
                />
              }
              onCloseSearch={() => {
                actions.filters(company.key, {
                  userSearch: '',
                });
              }}
              sortComponent={null}
            />
          )}>
          <FlatList
            tabLabel={I18n.t('employees.plural')}
            ListEmptyComponent={
              <NoResults text={I18n.t('employees.noAdvisors')} />
            }
            data={users?.filter(
              user =>
                user.role !== UserRole.Advisor &&
                (user.active ||
                  (user.active === false && user.invitation === true)),
            )}
            renderItem={({ item }) => (
              <Employee
                user={item}
                hasAccess={hasAccessToField(item, field.key)}
                company={company}
                field={field}
              />
            )}
            ItemSeparatorComponent={() => this._renderSeparator()}
            className="employees-list"
          />

          <FlatList
            tabLabel={I18n.t('employees.advisors')}
            ListEmptyComponent={
              <NoResults text={I18n.t('employees.noAdvisors')} />
            }
            data={users?.filter(user => user.role === UserRole.Advisor)}
            renderItem={({ item }) => (
              <Employee
                user={item}
                hasAccess={hasAccessToField(item, field.key)}
                company={company}
                field={field}
              />
            )}
            ItemSeparatorComponent={() => this._renderSeparator()}
            className="employees-list"
          />
        </ScrollableTabView>
      </Dialog>
    );
  }

  _renderSeparator() {
    return <hr />;
  }
}
