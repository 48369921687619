import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { debounce, get, isEqual, find } from 'lodash-es';

import { compose } from 'redux';
import MarkerImageComment from './MarkerImageComment';
import { typeIs } from '../../utils/Comment';
import { appPosToLatLng, geoJsonToAppPos } from '../../utils/Map';
import MarkerImageCommentCluster from './MarkerImageCommentCluster';
import type { ClusterFeature, GMBounds, MarkerFeature } from '../../flowTypes';
import SuperClusterWorkerForImageComments from '../../utils/supercluster-imageComments.worker';
import type { CommentMarker, LatLng } from 'farmerjoe-common/lib/flow/types';
import ImageViewer from '../Common/ImageViewer';

type Props = {
  markers: CommentMarker[];
  map: google.maps.Map;
};

type State = {
  zoom: number | null;
  center: LatLng | null;
  clusters: Array<MarkerFeature | ClusterFeature>;
  openComment: Comment | null;
  openComments: Comment[] | null;
  imageViewerOpen: boolean;
};

/**
 * Renders the comment markers on the map
 *
 */
class MarkerComments extends PureComponent<Props, State> {
  static propTypes = {
    markers: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      zoom: null,
      center: null,
      clusters: [],
      openComment: null,
      openComments: null,
      imageViewerOpen: false,
    };
    this.worker = new SuperClusterWorkerForImageComments();
    this.workerReady = false;
    this.worker.onmessage = this.workerOnMessage;
  }

  worker: Worker;
  workerReady: boolean;
  mapBoundsListener: any;
  updateMarkersTimeout: any;
  tilesLoadedListener: ((...args: Array<any>) => any) | null | undefined;

  componentDidMount() {
    if (this.props.map) {
      this.mapBoundsListener = (window as any).google.maps.event.addListener(
        this.props.map,
        'bounds_changed',
        this.debouncedOnUpdateView,
      );
    }

    this.onUpdateView();

    this.workerReady = false;
    this.worker.postMessage({ markers: this.props.markers || [] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.markers !== prevProps.markers) {
      this.workerReady = false;
      // map bounds at this time don't necessarily correspond to what the map will display, so update markers in a setTimeout
      this.updateMarkersTimeout = setTimeout(() => {
        this.worker.postMessage({ markers: this.props.markers || [] });
      }, 0);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.updateMarkersTimeout);
    if (this.mapBoundsListener) {
      (window as any).google.maps.event.removeListener(this.mapBoundsListener);
    }
    this.worker.terminate();
  }

  onClickMarker = (marker, e) => {
    const comment = marker.comment;
    this.setState({
      imageViewerOpen: true,
      openComment: comment,
      openComments: [comment],
    });
    e.stopPropagation();
    // this.props.navigation.navigate('imageViewer', {
    //   comment:          comment,
    //   fieldName:        get(comment, 'fieldMeta.name', ''),
    //   queryCommentKeys: [comment.key]
    // })
  };

  onClickCluster = (cluster, e) => {
    e.stopPropagation();

    const comment = get(cluster, 'properties.marker.comment');
    const commentKeys = get(cluster, 'properties.commentKeys');
    const comments = this.props.markers.map(m => m.comment).filter(c => c);
    this.setState({
      imageViewerOpen: true,
      openComment: comment,
      openComments: commentKeys
        .map(key => find(comments, comment => comment.key === key))
        .filter(c => c),
    });
    // this.props.navigation.navigate('imageViewer', {
    //   comment:          comment,
    //   fieldName:        get(comment, 'fieldMeta.name', ''),
    //   queryCommentKeys: commentKeys
    // })
  };

  render() {
    return (
      <React.Fragment>
        {this.state.clusters.reduce((result: any[], feature: any) => {
          if (feature.properties.cluster) {
            result.push(
              <MarkerImageCommentCluster
                key={feature.id}
                cluster={feature}
                onClick={this.onClickCluster.bind(this, feature)}
                map={this.props.map}
                zIndex={20}
              />,
            );
          } else {
            // $FlowFixMe
            result.push(this.renderMarker(feature.properties.marker));
          }
          return result;
        }, [])}
        <ImageViewer
          comment={this.state.openComment}
          comments={this.state.openComments}
          show={this.state.imageViewerOpen}
          onHide={() =>
            this.setState({
              imageViewerOpen: false,
              openComment: null,
              openComments: null,
            })
          }
        />
      </React.Fragment>
    );
  }

  renderMarker = marker => {
    if (typeIs('image', marker.comment.type)) {
      return (
        <MarkerImageComment
          key={marker.key}
          marker={marker}
          onClick={this.onClickMarker.bind(this, marker)}
          map={this.props.map}
          zIndex={10}
        />
      );
    }
    return null;
  };

  onUpdateView = () => {
    const mapRef = this.props.map;
    if (mapRef) {
      const center = mapRef.getCenter();
      const zoom = mapRef.getZoom();
      if (this.state.zoom !== zoom || !isEqual(center, this.state.center)) {
        this.setState({
          center: center as any,
          zoom: zoom as any,
        });
        this.workerUpdate();
      }
    }
  };

  debouncedOnUpdateView = debounce(this.onUpdateView, 500);

  workerOnMessage = e => {
    const map = this.props.map as google.maps.Map;
    if (e.data.ready) {
      this.workerReady = true;
      this.workerUpdate();
    } else if (e.data.expansionZoom) {
      map.setZoom(e.data.expansionZoom);
      map.panTo(appPosToLatLng(geoJsonToAppPos(e.data.center)) as any);
    } else {
      this.setState({
        clusters: e.data,
      });
    }
  };

  workerUpdate = () => {
    if (!this.workerReady) {
      return;
    }
    if (this.tilesLoadedListener) {
      (window as any).google.maps.event.removeListener(this.tilesLoadedListener);
    }

    const map = this.props.map;
    const bounds = map?.getBounds();

    if (!bounds) {
      this.tilesLoadedListener = (window as any).google.maps.event.addListenerOnce(
        map,
        'tilesloaded',
        this.workerUpdate,
      );
      return;
    }

    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    this.worker.postMessage({
      bbox: [
        southWest.lng(),
        southWest.lat(),
        northEast.lng(),
        northEast.lat(),
      ],
      zoom: map?.getZoom(),
    });
  };
}

export default compose<typeof MarkerComments>()(MarkerComments);
