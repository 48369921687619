import * as React from 'react';
import DefaultHeader from '../Header/Header';
import { classes } from '../../utils/dom';
import Card from './Card';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { Profile } from '../../flowTypes';
import { get, throttle } from 'lodash-es';
import { Component } from 'react';
import Help from '../Help/Help';
import UpdateMessage from '../ServiceWorker/UpdateMessage';

/* eslint-disable no-script-url */

type Props = {
  children: React.ReactNode;
  className?: string;
};

export class DefaultWrapper extends React.Component<Props> {
  ref: any = React.createRef();

  render() {
    const { children, className } = this.props;
    return (
      <div className={classes('page-wrapper', className)} ref={this.ref}>
        {/* <div className="container-fluid"> */}
        {children}
        {/* </div> */}
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.updateHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  // we resize to window height to deal with address bars on mobile devices
  updateHeight = () => {
    if (this.ref.current) {
      this.ref.current.style.height = window.innerHeight + 'px';
    }
  };

  onResize = throttle(this.updateHeight, 1000);
}

export class DefaultFooter extends Component<{}, {}> {
  render() {
    return (
      <footer className="footer">
        <small>farmsupport GmbH</small>
        {' | '}
        <small>
          <Help />
        </small>
        {' | '}
        <a href="https://farmerjoe.com/cookies.html" target={'blank'}>
          <small>Cookies</small>
        </a>
        {' | '}
        <a href="https://farmerjoe.com/datenschutz.html" target={'blank'}>
          <small>Privacy</small>
        </a>
        {' | '}
        <a href="https://farmerjoe.com/agb.html" target={'blank'}>
          <small>Terms of service</small>
        </a>
        {' | '}
        <a href="https://farmerjoe.com/impressum.html" target={'blank'}>
          <small>Impressum</small>
        </a>
      </footer>
    );
  }
}

class Page extends React.Component<{
  children?: React.ReactNode;
  title?: React.ReactNode | null | void;
  className?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
  wrapper?: React.ReactNode;
  container?: string;
  wrapperClassName?: string;
  footer?: React.ReactNode;
  card?: boolean;
  cardTitle?: string | null;
  openCompanies?: boolean | void;
  profile?: Profile;
  header?: React.ReactNode;
}> {
  render() {
    const {
      children,
      title = null,
      className = null,
      before = null,
      after = null,
      wrapperClassName,
      card = false,
      cardTitle = null,
      wrapper: Wrapper = DefaultWrapper,
      footer: Footer = DefaultFooter,
      header: Header = DefaultHeader,
      openCompanies = false,
      profile,
    } = this.props;

    return (
      // $FlowFixMe
      <div
        id="main-wrapper"
        className={classes(
          profile && profile.isLoaded && !profile.isEmpty && 'logged-in',
          className,
        )}>
        {Header ? <DefaultHeader title={title} /> : null}
        <UpdateMessage />
        {before}
        <DefaultWrapper
          className={classes(
            wrapperClassName,
            !Footer && 'no-footer',
            openCompanies && 'open-companies',
          )}>
          {card ? <Card title={cardTitle}>{children}</Card> : children}
        </DefaultWrapper>
        {after}
        {Footer ? <DefaultFooter /> : null}
        <div id="modal-portal"></div>
        <div id="react-select-portal"></div>
      </div>
    );
  }
}

export default compose(
  connect((state: any) => ({
    openCompanies: state.openCompanies,
    profile: get(state, 'firebase.profile'),
  })),
)(Page);
