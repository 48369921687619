/**
 * Created by danieldimitrov on 26/12/2016.
 */
import * as React from 'react';

import I18n from '../../language/i18n';

import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import type { Company } from '../../flowTypes';
import CreateField from '../Field/CreateField';

import imgFjAvatar from '../../public/images/FJ_logo_inverted.png';

type Props = {
  showCreate: boolean;
  company?: Company;
  tabLabel?: string;
};

type State = {
  showCreateForm: boolean;
};

class NoFields extends React.PureComponent<Props, State> {
  static defaultProps = {
    showCreate: true,
  };

  state = {
    showCreateForm: false,
  };

  render() {
    const { showCreate } = this.props;

    return (
      <div style={{ flex: 1, marginTop: 50 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: 10,
          }}>
          <img
            src={imgFjAvatar}
            style={{ width: 60, height: 60, marginRight: 10 }}
            alt=""
          />
          <span
            style={{
              ...constants.styles.big,
              ...constants.styles.muted,
              ...constants.styles.alignCenter,
              ...{
                fontSize: 25,
              },
            }}>
            {I18n.t('field.noFieldsCreated')}
          </span>
        </div>

        {showCreate ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              onClick={() => this.setState({ showCreateForm: true })}
              style={{
                minWidth: 290,
                borderRadius: 25,
                // fontWeight:   'bold'
              }}
              className={'btn btn-primary'}>
              <Icon iconType={'fj'} name={'plus'} /> {I18n.t('field.createNow')}
            </button>
          </div>
        ) : null}

        {this.state.showCreateForm
          ? (
          <CreateField
            show={this.state.showCreateForm}
            onClose={() => this.setState({ showCreateForm: false })}
          />
            )
          : null}
      </div>
    );
  }
}

export default compose<typeof NoFields>(
  connect((state: any) => {
    const openCompany = selectors.getOpenCompanyId(state);
    const company = getCompany(state.firestore.data, openCompany);

    return { company };
  }),
)(NoFields);
