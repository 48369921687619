import { get } from 'lodash-es';
import { latLngToAppPos } from './Map';
import GeocoderParser from 'google-geocode-parser';

export function geocodeAddress(address) {
  return new Promise((resolve, reject) => {
    const Geocoder = get(window, 'google.maps.Geocoder');
    if (!Geocoder) {
      reject(new Error('no Geocoder'));
      return;
    }

    const geocoder = new Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status !== 'OK') {
        reject(new Error('Geocoder failed due to: ' + status));
        return;
      }

      if (!results.length) {
        reject('no results');
        return;
      }

      resolve(convertResults(results));
    });
  });
}

function convertResults(results) {
  return results.map(r => {
    const parser = new GeocoderParser({ results: [r], status: 'OK' });
    return {
      position: latLngToAppPos(r.geometry.location),
      street: addressPart(
        parser.getComponent('route'),
        null,
        (addressPart as any)(parser.getStreetNumber(), ' '),
      ),
      zip: addressPart(parser.getZip()),
      city: addressPart(
        parser.getComponent('postal_town') ||
          parser.getComponent('locality') ||
          parser.getComponent('sublocality'),
      ),
      country: addressPart(parser.getComponent('country', true)),
    };
  });
}

function addressPart(value, prefix = null, suffix = null) {
  if (value == null) {
    return '';
  } else {
    let str = '';
    if (prefix != null) {
      str += prefix;
    }
    str += value;
    if (suffix != null) {
      str += suffix;
    }
    return str;
  }
}
