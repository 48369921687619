import React, { useState } from "react";
import Stripe from "./Stripe";
import RequestInvoice from "./RequestInvoice";
import CreditCard from "./CreditCard";
import { Loading } from "../Loading/Loading";

import I18n from "../../language/i18n";

const PaymentOptions = (props) => {
  const { useCreditCard, companyId, quantity, onSuccessCardPayment, onErrorCardPayment, onInvoiceRequest } = props;
  const [ loading, setLoading ] = useState(false);
  const [ showStripe, setShowStripe ] = useState(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {showStripe ? (
        <Stripe 
          companyId={companyId}
          quantity={quantity}
          onSuccess={onSuccessCardPayment}
          onError={onErrorCardPayment}
        />
      ) : (
        <div>
          <div style={{marginBottom: "0.5em"}}>{I18n.t("billing.payWith")}{":"}</div>
          <div style={{}}>
            {useCreditCard ? (
              <div style={{marginBottom: "0.5em"}}>
                <CreditCard onClick={() => setShowStripe(true)} />
              </div>
            ) : null
            }
            <RequestInvoice onInvoiceRequest={onInvoiceRequest} setLoading={setLoading} />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentOptions;
