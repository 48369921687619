import 'firebase/auth';

import {
  login,
  register,
  accountEdit,
  changePassword,
  requestAccountRemoval,
  createSignup,
  updateSignupStatus,
  setCredentialsProvider,
} from 'farmerjoe-common/lib/actions/profile';

export const logout = () => (dispatch, getState, getFirebase) => {
  const firebase = getFirebase();
  return firebaseLogout(firebase, dispatch);
};

const firebaseLogout = (firebase, dispatch) => {
  return firebase.logout().then(() => {
    dispatch({ type: 'app/logout' });
  });
};

export {
  login,
  register,
  accountEdit,
  changePassword,
  requestAccountRemoval,
  createSignup,
  updateSignupStatus,
  setCredentialsProvider,
};
