import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { NotACropState, CropState } from "farmerjoe-common/lib/flow/types";
import { NO_CROP_NAME } from "farmerjoe-common/lib/constants/crops";
import { outputDate, toDate } from "farmerjoe-common";
import { getViewForField } from "farmerjoe-common/lib/selectors/fields";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";

import "./style.css";
import Icon from "../Common/Icon";
import CropHeaderContainer from "../Common/CropHeaderContainer";
import CropIcon from "../Common/CropIcon";
import { LoadingIcon } from "../Loading/Loading";

import * as constants from "../../styles/style";
import I18n from "../../language/i18n";

const styles = constants.styles;

// TODO: improve typings
type Props = any;
type State = any;

class CommentsHeader extends Component<Props, State> {
  static propTypes = {
    crop: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
  };

  getDiffInDays(crop) {
    const first = moment(toDate(crop.sown_on));
    const last = !crop.harvested_on ? moment() : moment(toDate(crop.harvested_on));

    return last.diff(first, "days");
  }

  render() {
    const { crop, open, onPrint, disablePrint, printInProgress } = this.props;

    const cropIconStyle = {
      fontSize: 15,
      color: "#000",
      marginLeft: 0,
      marginRight: 5,
      paddingTop: 2,
    };

    return (
      <CropHeaderContainer
        color={crop.color}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 10,
          borderRadius: 10,
          borderBottomWidth: 1,
          borderBottomColor: "#FFFFFF",
          cursor: "pointer",
          width: "100%",
        }}>
        {onPrint
          ? (
            printInProgress
              ? (
                <LoadingIcon style={{ height: 37 }} />
              )
              : (
                <button
                  className="print btn btn-secondary"
                  onClick={onPrint}
                  disabled={disablePrint}>
                  <i className="fa fa-print" />
                </button>
              )
          )
          : null}

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
            }}>
            <CropIcon
              crop={crop}
              activeStyle={cropIconStyle}
              harvestedStyle={cropIconStyle}
              planStyle={cropIconStyle}
            />
            <span
              style={{
                ...styles.stdSize,
                ...{
                  fontStyle: crop.not_a_crop === NotACropState.PlannedCrop ? "italic" : "normal",
                  backgroundColor: "transparent",
                },
              }}>
              {crop.not_a_crop === NotACropState.NotACrop && crop.name === NO_CROP_NAME ? I18n.t("crop.without") : crop.name}
            </span>
          </div>
          {crop.art
            ? (
              <div style={{ marginLeft: 19 }}>
                <span
                  style={{
                    ...styles.small,
                    ...{
                      textAlign: "left",
                    },
                  }}>
                  {crop.art}
                </span>
              </div>
            )
            : null}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // width: crop.harvested_on ? 130 : 80,
            backgroundColor: "transparent",
            // justifyContent: crop.harvested_on ? 'space-between' : 'flex-end',
            alignItems: "center",
            marginLeft: "auto",
          }}>
          {this.renderHarvestedYear(crop.harvested_on)}

          {this.renderCropAge(crop, open)}
        </div>
      </CropHeaderContainer>
    );
  }

  renderHarvestedYear(harvested_on) {
    if (!harvested_on) {
      return null;
    }

    return (
      <span
        style={{
          ...styles.small,
          ...{
            marginLeft: 10,
            marginRight: 10,
            textAlign: "left",
            backgroundColor: "transparent",
          },
        }}>
        {harvested_on ? outputDate(harvested_on, "YYYY") : null}
      </span>
    );
  }

  renderCropAge(crop, open) {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span
          style={{
            ...styles.small,
            ...{
              textAlign: "right",
              marginRight: 5,
              backgroundColor: "transparent",
              fontWeight: "bold",
              width: 60,
            },
          }}>
          {I18n.t("x_days", { days: this.getDiffInDays(crop) })}
        </span>
        <div style={{ width: 20 }}>
          {open
            ? (
              <Icon
                iconType={"fj"}
                style={{
                  fontSize: 14,
                  marginTop: 2,
                  marginLeft: 5,
                  backgroundColor: "transparent",
                }}
                name="arrow_down"
              />
            )
            : (
              <Icon
                iconType={"fj"}
                style={{
                  fontSize: 14,
                  marginTop: 2,
                  marginLeft: 5,
                  backgroundColor: "transparent",
                }}
                name="arrow_up"
              />
            )}
        </div>
      </div>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  let { crop } = ownProps;

  if (crop) {
    const cropId = crop.state === CropState.Abandoned ? crop.realCropId : crop.key;
    const fieldId = crop.state === CropState.Abandoned ? crop.field.realFieldId : crop.field_id;

    const fieldView = getViewForField(state, openCompany, fieldId);

    if (fieldView) {
      const { activeCrops } = fieldView;
      crop = { ...crop, ...activeCrops[cropId] };
    }
  }

  return {
    crop,
  };
};
export default connect(selector)(CommentsHeader);
