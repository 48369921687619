import React, { useState } from "react";

import FormCompany from "../Company/FormCompany";
import I18n from "../../language/i18n";
import { TBillingDetails } from "./types";

type Props = {
  companyId: string;
  actions: {
    editCompany: (arg0: string) => void;
  };
  billingDetails: TBillingDetails;
};

const BillingDetails = (props: Props) => {
  const { billingDetails: { contact, company }} = props;
  const [showCompanyForm, setShowCompanyForm] = useState(false);

  return (
    <div style={{
      margin: "1em 2em",
    }}>
      <div style= {{
        fontSize: "32px",
        borderBottom: "1px solid grey",
        margin: "1em 0",
      }}>
        {I18n.t("billing.billingInfo")}
      </div>
      <div style={{display: "flex", flexDirection: "row"}}>
        <div>
          <div style={{fontWeight: "bold"}}> {I18n.t("billing.contact")} </div>
          <div>
            {contact.firstName} {contact.lastName}
          </div>
          <div>
            {contact.email}
          </div>
          <div>
            {contact.phoneNumber}
          </div>
          <div style={{fontSize: 16}}>
            <div> {contact.address.address_line_1} </div>
            <div> {contact.address.address_line_2} </div>
            <div>
              <div>
                {contact.address.postalCode}{", "}
                {contact.address.city}{", "}
                {contact.address.region && (<>{contact.address.region}{", "}</>)}
                {contact.address.country}
              </div>
            </div>
          </div>
        </div>
        {company ? (
          <div style={{marginLeft: "2em"}}>
            <div style={{fontWeight: "bold"}}> {I18n.t("billing.billingCompany")} </div>
            <div>
              {company.name}
            </div>
            <div>
              {company.taxId}
            </div>
            <div style={{fontSize: 16}}>
              <div> {company.address.address_line_1} </div>
              <div> {company.address.address_line_2} </div>
              <div>
                <div>
                  {company.address.postalCode}{", "}
                  {company.address.city}{", "}
                  {company.address.region && (<>{company.address.region}{", "}</>)}
                  {company.address.country}
                </div>
              </div>
            </div>
          </div>
        ) : null }
      </div>
      <div style={{marginTop: "1em"}}>
        <span style={{cursor: "pointer", fontWeight: "bold", color: "rgb(138,180,88)", fontSize: 14}} onClick={() => {
          setShowCompanyForm(true);
          props.actions.editCompany(props.companyId);
        }}>{I18n.t("billing.editBillingDetails")}</span>
      </div>
      {showCompanyForm
        ? (
          <FormCompany
            show={showCompanyForm}
            activeTab={"COMPANY_BUSINESS_ADDRESS"}
            onClose={() => setShowCompanyForm(false)}
          />
        )
        : null}
    </div>
  );
};

export default BillingDetails;
