import * as React from 'react';
import I18n from '../../language/i18n';
import type { Crop } from '../../flowTypes';
import Icon from '../Common/Icon';
import { classes } from '../../utils/dom';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PlanCrop from '../Crop/PlanCrop';
import FormCrop from '../Crop/FormCrop';
import './style.css';
import { NotACropState } from 'farmerjoe-common/lib/flow/types';

type Props = {
  crop: Crop;
  plant?: boolean;
  openCompanyId: string;
  openFieldId: string;
};

type State = {
  showFormCropForm: boolean;
  showPlanCropForm: boolean;
};

class NewCrop extends React.Component<Props, State> {
  state = {
    showFormCropForm: false,
    showPlanCropForm: false,
  };

  render() {
    const { crop, plant } = this.props;

    if (crop.not_a_crop === NotACropState.NotACrop) {
      return this.renderNewCrop(plant as boolean);
    }

    return this.renderNewCrop(true, true);
  }

  /**
   * Render the crop button. Either plan or plant
   *
   * @param plant
   * @param form
   * @returns {*}
   */
  renderNewCrop(plant: boolean, form?: boolean) {
    const style = {};

    return [
      <button
        onClick={() =>
          this.setState({
            showFormCropForm: !!form,
            showPlanCropForm: !form,
          })
        }
        key={0}
        className={classes(
          'fj-btn fj-btn-with-icon btn',
          plant
            ? 'btn-primary plant-crop-button'
            : ' plan-crop-button btn-secondary',
        )}
        // style={{
        //   ...style, ...(plant ? {} : {
        //     , fontWeight: 'bold'
        //   })
        // }}
      >
        <Icon iconType="fj" name={plant ? 'leaf' : 'time'} />{' '}
        {plant ? I18n.t('crop.plant') : I18n.t('crop.plan')}
      </button>,
      this.state.showFormCropForm
        ? (
        <FormCrop
          key={1}
          show={this.state.showFormCropForm}
          onClose={() => this.setState({ showFormCropForm: false })}
        />
          )
        : null,
      this.state.showPlanCropForm
        ? (
        <PlanCrop
          key={2}
          show={this.state.showPlanCropForm}
          onClose={() => this.setState({ showPlanCropForm: false })}
          plant={plant}
        />
          )
        : null,
    ];
  }
}

export default compose(
  connect((state: any) => ({
    openCompanyId: selectors.getOpenCompanyId(state),
    openFieldId: selectors.getOpenFieldId(state),
  })),
)(NewCrop);
