import * as React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash-es';
import { appPosToLatLng } from '../../utils/Map';
import { Circle, Marker } from '@react-google-maps/api';
import { clearWatch, watchPosition } from '../../utils/geolocation';
import { updatePosition } from 'farmerjoe-common/lib/actions/position';

type Props = {
  children?: React.ReactNode;
  userPosition?: any;
  actions?: {
    updatePosition: (...args: Array<any>) => any;
  };
  locationPermission?: boolean;
  map?: Record<string, any>;
};

class CurrentLocationMarker extends React.Component<Props> {
  watchPositionId?: number | null;
  private marker?: google.maps.Marker;

  constructor(props: any, context?: any) {
    super(props, context);
    if (this.props.locationPermission) {
      this.watchPosition();
    }
  }

  componentWillUpdate(nextProps: Props) {
    if (!this.props.locationPermission && nextProps.locationPermission) {
      this.watchPosition();
    } else if (this.props.locationPermission && !nextProps.locationPermission) {
      this.stopWatchingPosition();
    }
  }

  componentWillUnmount() {
    this.stopWatchingPosition();
  }

  watchPosition() {
    if (this.watchPositionId != null) {
      return;
    }

    this.watchPositionId = watchPosition(
      position => {
        this.props.actions?.updatePosition(position.coords);
      },
      e => {
        console.warn('watch position error', e);
      },
      { enableHighAccuracy: false, timeout: 20000, maximumAge: 1000 },
    );
  }

  stopWatchingPosition() {
    if (this.watchPositionId != null) {
      clearWatch(this.watchPositionId);
    }
  }

  render() {
    const { userPosition } = this.props;

    if (!userPosition) {
      return null;
    }

    return (
      <Marker
        position={appPosToLatLng(userPosition) as any}
        icon={{
          path: (window as any).google.maps.SymbolPath.CIRCLE,
          fillColor: '#2c7df0',
          strokeColor: '#ffffff',
          scale: 7,
          fillOpacity: 0.6,
          strokeWeight: 1.5,
        }}
        zIndex={100}
        // class="marker-label current-location-marker"
        onLoad={marker => this.marker = marker}
        onUnmount={marker => this.marker = void 0}
        onClick={(e) => {
          const map = this.marker?.getMap() as google.maps.Map;
          if (map) {
            map.panTo(appPosToLatLng(userPosition) as any);
            map.setZoom(20);
          }
        }}>
        {userPosition.accuracy != null
          ? (
          <Circle
            options={{
              clickable: false,
              strokeColor: '#1bb6ff',
              strokeOpacity: 0.4,
              fillColor: '#61a0bf',
              fillOpacity: 0.4,
              strokeWeight: 1,
              zIndex: 1,
            }}
            radius={userPosition.accuracy}
            center={appPosToLatLng(userPosition) as any}
          />
            )
          : null}
      </Marker>
    );
  }
}

export default compose<typeof CurrentLocationMarker>(
  connect(
    state => ({
      userPosition: get(state, 'userPosition'),
      locationPermission: get(state, 'locationPermission'),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          updatePosition,
        },
        dispatch,
      ),
    }),
  ),
)(CurrentLocationMarker);
