import React from "react";
import { slice } from "ramda";
import isJSON from "is-json";
import { get } from "lodash-es";
import "firebase/firestore";

import {
  dummy,
  isSystem,
  typeIs,
  getFertilizerSum,
  canDeleteComment,
  canEditComment,
  yieldComment,
  getYieldCommentText,
} from "farmerjoe-common/lib/utils/Comment";
import Badge from "../components/FieldMark/Badge";
import { outputDate } from "farmerjoe-common";
import { AnalysisState } from "farmerjoe-common/lib/flow/types";

import { truncate } from "./String";
import I18n, { tWithComponent } from "../language/i18n";
import Icon from "../components/Common/Icon";
import * as constants from "../styles/style";
import type { Comment, CommentContent } from "../flowTypes";

export {
  dummy,
  isSystem,
  typeIs,
  getFertilizerSum,
  canDeleteComment,
  canEditComment,
  yieldComment,
};

export function getUid(type) {
  const parts = type.split(".");

  return parts[parts.length - 1];
}

export function getCommentsForSum(
  comment: Comment,
  comments: Array<Comment>,
  edit: boolean = false,
) {
  if (comments.length === 1) {
    return edit ? [] : comments;
  }

  let index = comments.findIndex((x: any) => x.comment_id === (comment as any).comment_id) + 1;

  if (edit) {
    index = index - 1;
  }

  return slice(0, index, comments);
}

export function getFertilizerComments(comments, active_crop_uid) {
  return comments.filter(comment => {
    if (
      comment.active_crop_uid === active_crop_uid &&
      typeIs("fertilizing", comment.type)
    ) {
      return true;
    }
    return false;
  });
}

/**
 * Function to extract the text from a comment. Not all comments have plain text. Some comments
 * need to be translated, for other we need to extract values from the comment object etc.
 *
 * @param comment - the whole comment object
 * @param truncateLength - optional truncate length. If not specified no truncate is going to take place
 * @returns {*} - returns either text or React.Component
 */
export function getCommentText(
  comment: Comment,
  truncateLength?: number,
): string | React.ReactElement<any> | any {
  const isJson = isJSON(comment.text, true);
  let text: string = "";

  if (typeIs("image", comment.type)) {
    return [
      <Icon
        name={"image"}
        key="image"
        iconType={"fal"}
        style={{ color: constants.FJMUTED, fontSize: 18 }}
      />,
      comment.text ? ` ${String(comment.text)}` : "",
    ];
  }

  if (typeIs("crop.yield", comment.type)) {
    return getYieldCommentText(comment);
  }

  if (typeIs("bonitur", comment.type)) {
    return <span>Bonitur {(comment as any).extraData.name}</span>;
  }

  if (typeIs("user.file", comment.type)) {
    return [
      <Icon
        name={"file-pdf"}
        key="file-pdf"
        iconType={"fal"}
        style={{ color: constants.FJMUTED, fontSize: 18 }}
      />,
      comment.text ? ` ${String(comment.text)}` : "",
    ];
  }

  // if we don't have JSON, we are dealing with legacy comments that had the strings hardcoded.
  if (isJson) {
    if (typeIs("crop", comment.type)) {
      text = getCropCommentText(comment);
    }

    if (typeIs("field_mark_add", comment.type)) {
      const reason = get(comment, "text.reason");

      return tWithComponent("fieldMark.marked", [
        <Badge mark={comment.text} key="badge" />,
        truncateLength
          ? truncate(reason, truncateLength)
          : reason
            ? reason.split("\n")[0]
            : null,
      ]);
    } else if (typeIs("field_mark_remove", comment.type)) {
      text = I18n.t("fieldMark.unmarked");
    } else if (
      typeIs("field", comment.type) ||
      typeIs("archive", comment.type) ||
      typeIs("unarchive", comment.type)
    ) {
      text = I18n.t(comment.text.languageConstant);
    }

    if (typeIs("wait_time", comment.type)) {
      text =
        comment.text.template +
        " " +
        I18n.t("x_days", { days: comment.text.wait_time });
    }

    if (typeIs("fertilizing", comment.type)) {
      text = comment.text.name + " " + comment.text.kg + " kg/ha";
    }

    if (typeIs("analysis", comment.type)) {
      text = I18n.t("analysis.ordered") + ": " + comment.text.analyse_number;
      if (comment.text.state === AnalysisState.Completed) {
        text = I18n.t("analysis.result_for_analysis_from_ready", {
          date: outputDate(comment.text.created),
        });
      }
    }

    if (typeIs("system.fieldSharing", comment.type)) {
      text = I18n.t(comment.text.languageConstant, comment.text.data);
    }

    return truncateLength ? truncate(text, truncateLength) : text.split("\n")[0];
  }

  return truncateLength ? truncate(comment.text, truncateLength) : comment.text;
}

export const formatCommentTextForSharing = (comment: Comment) => {
  if (typeIs("fertilizing", comment.type)) {
    const json = comment.text;
    return `${json.name} kg: ${json.kg}, N: ${json.n.kg}, P₂O₅: ${json.p2o5.kg}, K₂O: ${json.k2o.kg}, S: ${json.s.kg}, Mg: ${json.mg.kg}`;
  }

  return getCommentText(comment);
};

function getCropCommentText(comment: Comment): string {
  const isJson = isJSON(comment.text, true);
  let text = "";

  // if we don't have JSON, we are dealing with legacy comments that had the strings hardcoded.
  if (!isJson) {
    return comment.text;
  }
  if (
    typeIs("withoutSince", comment.type) ||
    typeIs("harvested", comment.type) ||
    get(comment, "text.date", null)
  ) {
    text = I18n.t((comment.text as CommentContent).languageConstant, {
      date: outputDate(comment.text.date),
    });
  } else {
    text = I18n.t((comment.text as CommentContent).languageConstant);
  }

  return text;
}

export const URL_IN_COMMENT_REGEX = /(\b((?:https?|ftp):\/\/|www\.)\S+\b)/g;
