export function saveFieldTableState(companyId, tableState) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    const state = getState();
    const profile = state.firebase.profile;

    if (!tableState) {
      return db
        .collection('users')
        .doc(profile.uid)
        .set(
          {
            fieldTableState: firebase.firestore.FieldValue.delete(),
          },
          { merge: true },
        );
    } else {
      return db
        .collection('users')
        .doc(profile.uid)
        .set(
          {
            fieldTableState: {
              ...profile.fieldTableState,
              [companyId]: tableState,
            },
          },
          { merge: true },
        );
    }
  };
}

export function setFieldsView(companyId, view) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    const state = getState();
    const profile = state.firebase.profile;

    return db
      .collection('users')
      .doc(profile.uid)
      .set(
        {
          fieldsView: {
            ...profile.fieldsView,
            [companyId]: view,
          },
        },
        { merge: true },
      );
  };
}
