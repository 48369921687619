import stylesheet from '../stylesheets/farmerjoe';
import React from 'react';
import '../templates/style.css';

export function positionTemplate(locals) {
  if (locals.hidden) {
    return null;
  }

  const label = locals.label ? <div>{locals.label}</div> : null;
  const error =
    locals.hasError && locals.error
      ? (
      <div style={stylesheet.errorBlock}>{locals.error}</div>
        )
      : null;

  const rows = locals.order.map(function(name) {
    return locals.inputs[name];
  });

  return (
    <div className="position-form-field">
      <div className={'mb-2'}>{label}</div>
      {error}
      <div className="position-props">{rows}</div>
    </div>
  );
}
