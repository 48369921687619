import stylesheet from '../stylesheets/style';

import Textbox from '../templates/Textbox';
import I18n from '../../language/i18n';
import t from 'tcomb-form';
import { cloneDeep } from 'lodash-es';

import * as constants from '../../styles/style';

import { defaultOptions } from '../utils/options';
import { positionTemplate } from '../templates/Position';
import FormInput from '../templates/FormInput';
import { numberTransformer } from '../transformers/transformers';
import React from 'react';
import modalCountry from '../templates/Country/Country';

// Our company model
const model = t.struct({
  company_id: t.String,
  name: t.String,
  street: t.maybe(t.String),
  zip: t.maybe(t.String),
  city: t.maybe(t.String),
  country: t.maybe(t.String),
  position: t.struct({
    latitude: t.Number,
    longitude: t.Number,
  }),
});

const controlLabelStyle = cloneDeep(stylesheet);
controlLabelStyle.controlLabel.normal.color = constants.FJNAVCOLOR;
controlLabelStyle.controlLabel.normal.position = 'relative';
controlLabelStyle.controlLabel.normal.paddingLeft = 10;

const multiline = cloneDeep(stylesheet);
multiline.textbox.normal.height = 50;

function template(locals, defaults) {
  // in locals.inputs you find all the rendered fields
  return (
    <div>
      {locals.inputs.company_id}
      {locals.inputs.name}
      {locals.inputs.street}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}>
        <div style={{ width: 150 }}>{locals.inputs.zip}</div>
        <div style={{ flex: 1 }}>{locals.inputs.city}</div>
      </div>

      {locals.inputs.country}
      {defaults.PolygonComponent}
      <div style={{}}>{locals.inputs.position}</div>
    </div>
  );
}

const options = function(defaults) {
  const options = {
    template: locals => template(locals, defaults),
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      company_id: {
        type: 'hidden',
      },
      name: {
        ...defaultOptions(I18n.t('warehouse.name'), true),
        autoFocus: true,
      },

      street: {
        ...defaultOptions(I18n.t('street')),
        returnKeyType: 'next',
        blurOnSubmit: false,
        // onSubmitEditing: () => component.refs.form.getComponent('zip').refs.input.focus()
      },
      zip: {
        ...defaultOptions(I18n.t('zip')),
        returnKeyType: 'next',
        blurOnSubmit: false,
        // onSubmitEditing: () => component.refs.form.getComponent('city').refs.input.focus()
      },
      city: {
        ...defaultOptions(I18n.t('city')),
        returnKeyType: 'next',
        template: Textbox,
        // onSubmitEditing: () => component.refs.form.getComponent('country').refs.input.focus()
      },
      country: {
        ...defaultOptions(I18n.t('country')),
        factory: modalCountry,
        // onSubmitEditing: () => component.refs.form.getComponent('tel').refs.input.focus()
      },
      position: {
        template: positionTemplate,
        fields: {
          latitude: {
            label: I18n.t('latitude'),
            factory: FormInput,
            transformer: numberTransformer,
          },
          longitude: {
            label: I18n.t('longitude'),
            factory: FormInput,
            transformer: numberTransformer,
          },
        },
      },
    },
  };

  return options;
};

export default { model, options };
