import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import t from 'tcomb-form';
import { get } from 'lodash-es';
import { bindActionCreators } from 'redux';

import { translateError } from 'farmerjoe-common/lib/actions/errorTranslations';

import {
  ERROR_CODE_INVALID_FORM_ENTRY,
  ERROR_CODE_LOGIN_ERROR,
} from './constants';

import Dialog, { AlertDialog } from '../Dialog/Dialog';
import { Loading } from '../Loading/Loading';

import * as actionCreators from '../../actions/profile';
import * as constants from '../../styles/style';
import AccountModel from '../../tcomb/models/account';
import I18n from '../../language/i18n';
import firebase from '../../data/firebase';
import { Profile } from '../../flowTypes';

const Form = t.form.Form;

type Props = {
  profile?: Profile;
  owner?: any;
  show?: any;

  onClose?: () => any;
  actions: {
    accountEdit: (user: any) => any;
  };
};

const AccountEdit = (props: Props) => {
  const { owner, show, onClose, profile } = props;
  const [ editedUser, setEditedUser ] = useState({
    email: profile?.email,
    currentPassword: '',
    firstname: profile?.firstname,
    lastname: profile?.lastname,
    phoneNumber: profile?.phoneNumber,
  });

  const [alert, setAlert] = useState({
    alertTitle: '',
    alertMessage: '',
  });
  const [mailChanged, setMailChanged] = useState<boolean>(false);
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<string>('');
  const [ accountChanged, setAccountChanged ] = useState<boolean>(false);
  const [ isAPILoading, setIsAPILoading ] = useState<boolean>(false);

  const ref = useRef(null);

  const onChange = (value) => {
    const profileEmail = get(props, 'profile.email', '');
    if (value.email !== profileEmail) {
      setMailChanged(true);
    }
    setEditedUser({
      ...editedUser,
      ...value,
    });
    setAccountChanged(true);
  };

  const onSave = () => {
    const promises: Promise<any>[] = [];

    if (accountChanged) {
      promises.push(saveAccountForm());
      setAccountChanged(false);
    }

    setIsAPILoading(true);
    return Promise
      .all(promises)
      .then(props.onClose)
      .catch(e => {
        console.log(e);
        switch(e.message) {
        case ERROR_CODE_INVALID_FORM_ENTRY:
          setAlert({
            alertMessage: I18n.t('please_correct_your_entry'),
            alertTitle: I18n.t('error'),
          });
          return;
        case ERROR_CODE_LOGIN_ERROR:
          setLoginError(translateError(e));
          return;
        default:
          setAlert({
            alertMessage: e.message,
            alertTitle: I18n.t('error'),
          });
        }
      })
      .finally(() => {
        setIsAPILoading(false);
      });
  };

  const saveAccountForm = () : Promise<any> => {
    const value = (ref as any).current.getValue();
    // If value is null, then the form has errors
    if (value === null) {
      return Promise.reject(new Error(ERROR_CODE_INVALID_FORM_ENTRY));
    }

    const user = {
      user_id: profile?.uid,
      ...value,
    };

    if (mailChanged) {
      setLoggingIn(true);
      return firebase
        .auth()
        .signInWithEmailAndPassword(profile?.email as string, user.currentPassword)
        .then(() => {
          setLoggingIn(false);
          return props.actions.accountEdit({...user, password: user.currentPassword});
        })
        .catch(e => {
          setLoggingIn(false);
          return Promise.reject(new Error(ERROR_CODE_LOGIN_ERROR));
        });
    }

    return props.actions.accountEdit(user);
  };

  return (
    <Dialog
      show={show}
      onClose={onClose}
      className={'account-edit'}
      title={I18n.t('account.edit')}
      footer={
        <div className="d-flex flex-grow-1">
          <button
            className="ml-auto btn btn-secondary"
            disabled={owner.isFetching}
            onClick={onClose}>
            {I18n.t('cancel')}
          </button>{' '}
          <button
            className="btn btn-primary"
            disabled={owner.isFetching}
            onClick={onSave}>
            {I18n.t('save')}
          </button>
        </div>
      }>
      {loginError && <div className="text-danger mb-4">{loginError}</div>}
      <div style={{ ...styles.box, ...{ padding: 0 } }}>
        <Form
          ref={ref}
          type={AccountModel.model(mailChanged)}
          options={() => AccountModel.options(this, mailChanged)}
          value={editedUser}
          onChange={onChange}
        />
      </div>


      {owner.isFetching || loggingIn || isAPILoading
        ? (
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              position: 'absolute',
              justifyContent: 'center',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Loading />
          </div>
        )
        : null}
      <AlertDialog
        show={!!alert.alertMessage}
        onClose={() => {
          setAlert({ alertMessage: '', alertTitle: '' });
        }}
        title={alert.alertTitle}
      >
        {alert.alertMessage}
      </AlertDialog>
    </Dialog>
  );
};

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  box: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: constants.FJBORDERCOLOR,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 20,
  },
};

const selector = state => {
  return {
    profile: state.firebase.profile,
    owner: state.owner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...actionCreators,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(AccountEdit);
