import * as React from 'react';
import { FJBACKGROUNDLIGHT } from '../../styles/style';
import { ActivationLoadingScreen } from './ActivationLoadingScreen';
import { get } from 'lodash-es';
import { ActivationNewEmailBox } from './ActivationNewEmailBox';
// import type { Profile, FirebaseAuth } from 'farmerjoe-common'
import AlertBox from '../Common/AlertBox';

type Props = {
  email: string;
  currentPassword?: string;
  onChangePassword: (...args: Array<any>) => any;
  onChangeEmail: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
  profile: any; // Profile
  auth: any; // FirebaseAuth
  error?: null | string;
  loading: boolean;
};
export const ActivationView = ({
  email,
  currentPassword,
  onChangePassword,
  onChangeEmail,
  onSubmit,
  profile,
  error,
  auth,
  loading,
}: Props) => {
  return (
    <div>
      {loading && <ActivationLoadingScreen />}

      {error && <AlertBox text={error} type={'alert'} />}
      <div>
        {get(auth, 'uid') && (
          <ActivationNewEmailBox
            email={email}
            currentPassword={currentPassword}
            onChangePassword={onChangePassword}
            onChangeEmail={onChangeEmail}
            profile={profile}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </div>
  );
};
