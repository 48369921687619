import * as React from 'react';
import { age } from 'farmerjoe-common/lib/utils/Crop';
import I18n from '../../language/i18n';

type Props = {
  sownOn: Date;
  harvestedOn?: Date;
  style: React.CSSProperties;
  fullText?: boolean;
};

export default class CropAge extends React.PureComponent<Props> {
  static defaultProps = {
    style: {},
    fullText: false,
  };

  render() {
    const { sownOn, harvestedOn, style, fullText } = this.props;
    let time = '';

    if (fullText) {
      time = I18n.t('x_days_full', { count: age(sownOn, harvestedOn) });
    } else {
      time = I18n.t('x_days', { days: age(sownOn, harvestedOn) });
    }

    return (
      <div className="crop-age">
        <span style={style}>{time}</span>
      </div>
    );
  }
}
