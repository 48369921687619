import React, { Component } from 'react';

import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as profileActions from '../../actions/profile';
import Icon from '../Common/Icon';
import languages from 'languages';
import { find, get, sortBy } from 'lodash-es';
import { supportedLanguages } from '@farmerjoeorg/farmerjoe-language';
import * as constants from '../../styles/style';
import Select, { components } from 'react-select';
import './style.css';

import I18n from '../../language/i18n';

// TODO: improve typings
type Props = any;
type State = any;

class Settings extends Component<Props, State> {
  private languages: any[];

  constructor(props) {
    super(props);

    // Get the languages the app currently supports
    this.languages = sortBy(
      supportedLanguages.map(cca2 => {
        const lang = languages.getLanguageInfo(cca2);

        return {
          key: cca2,
          name: lang.name,
          native: lang.nativeName,
        };
      }),
      ['name'],
    );

    this.state = {};
  }

  componentWillMount() {
    this.update();
  }

  componentWillUpdate(props) {
    if (this.props.profile.language !== props.profile.language) {
      this.update(props);
    }
  }

  update(props = this.props) {
    this.setState({
      language: props.profile.language,
    });
  }

  render() {
    return (
      <div style={{ flex: 1 }}>
        <Select
          className={'select-language'}
          value={find(this.languages, { key: this.state.language })}
          options={this.languages}
          getOptionValue={item => item.key}
          onChange={this.onChange.bind(this)}
          isSearchable={true}
          isClearable={false}
          menuPortalTarget={document.getElementById('react-select-portal')}
          components={{
            Option: props => (
              <components.Option {...props}>
                {this.renderItem(false, props.data)}
              </components.Option>
            ),
            SingleValue: ({ children, ...props }) => {
              return (
                <components.SingleValue {...props}>
                  {this.renderItem(true, get(props.getValue(), '0'))}
                </components.SingleValue>
              );
            },
          }}
          styles={{
            valueContainer: (provided, state) => ({
              ...provided,
              minHeight: 50,
            }),
          }}
        />
      </div>
    );
  }

  onChange(item) {
    if (
      window.confirm(I18n.t('languages.really_change', { language: item.name }))
    ) {
      this.setState(
        {
          language: item.key,
        },
        () => this.onSave(),
      );
    }
  }

  onSave() {
    const { firebase, profile } = this.props;

    firebase
      .firestore()
      .collection('users')
      .doc(profile.uid)
      .update({ language: this.state.language });
  }

  renderItem(noIcon, item) {
    const icon =
      !noIcon && item.key === this.props.profile.language
        ? (
        <Icon name={'md-checkmark'} />
          )
        : null;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: '4px',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{item.native}</span>

          <span
            style={{
              ...constants.styles.muted,
              ...constants.styles.extraSmall,
            }}>
            {item.name}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          {icon}
        </div>
      </div>
    );
  }
}

const wrappedSettings = firestoreConnect(props => {
  return [];
})(Settings);

const selector = state => {
  return {
    profile: state.firebase.profile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedSettings);
