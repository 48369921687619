import React from "react";
import Modal from "react-bootstrap-modal";
import Icon from "../Common/Icon";
import { FJAPPLERED } from "../../styles/style";
import I18n from "../../language/i18n";
const stopPropagation = e => e.stopPropagation();

const getErrorMessage = (error) => {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "string") {
    return error;
  }

  // Stripe returns object
  if (typeof error === "object") {
    return error.message;
  }

  return I18n.t("error"); // generic
};

const ErrorDialog = (props) => {
  const { show, onClose, error } = props;
  const errorMsg = getErrorMessage(error);

  return (
    <Modal
      show={show}
      dialogClassName="error-modal"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={stopPropagation}>{I18n.t("error")}</Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        <div>
          <div
            style={{textAlign: "center"}}
          >
            <Icon
              name={"times-circle"}
              iconType={"fa"}
              light
              style={{ color: FJAPPLERED, fontSize: 150 }}
            />

            <div
              style={{
                padding: 10,
              }}>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}>
                {errorMsg}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorDialog;
