import React from "react";
import PlanUpgradeModal from "./PlanUpgradeModal";
import SubscriptionPlan from "./SubscriptionPlan";
import { TCurrentUsage, TSubscriptionPlan } from "./types";
import I18n from "../../language/i18n";

type Props = {
  show: boolean;
  onClose: (arg0: any) => any;
  subscriptionPlan: TSubscriptionPlan | null;
  currentUsage: TCurrentUsage;
  onSelect: (arg0: any) => any;
};

const FreePlanDialog = (props: Props) => {
  const { show, onClose, subscriptionPlan, currentUsage, onSelect } = props;
  return (
    <PlanUpgradeModal
      show={show}
      onClose={onClose}
      title={"Free plan"}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="btn btn-secondary ml-auto" onClick={onClose}>
            {I18n.t("cancel")}
          </button>{" "}
          <button
            className="btn btn-primary"
            onClick={onSelect}>
            {I18n.t("yes")}
          </button>
        </div>
      }
    >
      <div style={{
        maxWidth: "300px",
        margin: "0 auto",
      }}>
        <SubscriptionPlan subscriptionPlan={subscriptionPlan} isCurrent={false} showLabel={false} />
      </div>
      <>
        <div style={{ margin: "20px"}}>
          {
            //  {currentUsage.totalUsers > 2  ? (
            //    <>
            //      <div style={{color: "red", fontStyle: "italic"}}>
            //        <b>IMPORTANT:</b>
            //      </div>
            //      <div>
            //        {I18n.t("billing.downgradeHintUsers", { part: currentUsage.totalUsers - 2, total: currentUsage.totalUsers - 1})}
            //      </div>
            //    </>
            //  ) : null}
          }
          <div style={{ textAlign: "center", margin: "10px"}}>
            {I18n.t("billing.downgradeAreYouSure")}
          </div>
          <div style={{ textAlign: "center", margin: "10px", fontSize: "12px", fontWeight: "bold"}}>
            {I18n.t("billing.downgradeHint")}
          </div>
        </div>
      </>
    </PlanUpgradeModal>
  );
};

export default FreePlanDialog;
