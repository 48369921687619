import stylesheet from '../stylesheets/style';

import I18n from '../../language/i18n';
import modalCountry from '../templates/Country/CountryTel';

import t from 'tcomb-form';
import Email from '../validators/email';
import Tel from '../validators/tel';
import FormInput from '../templates/FormInput';

type ModelResult = {
  email: any;
  currentPassword?: any;
  firstname: any;
  lastname: any;
  phoneNumber: any;
};

// Our account model
const model = (mailChanged: boolean) => {
  const structure: ModelResult = {
    email: Email,
    currentPassword: t.String,
    firstname: t.String,
    lastname: t.String,
    phoneNumber: Tel,
  };

  if (!mailChanged) {
    delete structure.currentPassword;
  }

  return t.struct(structure);
};

const options = function(component, mailChanged: boolean) {
  return {
    stylesheet: stylesheet,
    // auto: 'none',
    fields: {
      email: {
        label: I18n.t('email') + ' *',
        returnKeyType: 'next',
        blurOnSubmit: false,
        keyboardType: 'email-address',
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('firstname').refs.input.focus(),
      },
      currentPassword: {
        label: I18n.t('password.current') + ' *',
        config: {
          password: true,
        },
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('firstname').refs.input.focus(),
      },
      firstname: {
        label: I18n.t('first_name') + ' *',
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () =>
          component.refs.form.getComponent('lastname').refs.input.focus(),
      },
      lastname: {
        label: I18n.t('last_name') + ' *',
        returnKeyType: 'send',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        onSubmitEditing: () => component.onRight(),
      },
      phoneNumber: {
        label: `${I18n.t('phone')} *`,
        placeholder: `${I18n.t('phones.enterNumber')} *`,
        factory: modalCountry,
      },
    },
  };
};

export default { model, options };
