import * as React from 'react';
import * as constants from '../../../styles/style';

type Props = {
  header: string | React.ReactNode;
  content: React.ReactNode;
  style?: Record<string, any>;
};

export default class Box extends React.Component<Props> {
  render() {
    let { header, content, style } = this.props;
    const styles = constants.styles;

    if (typeof header === 'string') {
      header = <div style={styles.boxHeader}>{header}</div>;
    }

    return (
      <div className="fj-box">
        {header}
        <div className="content" style={{ ...styles.box, ...style }}>
          {content}
        </div>
      </div>
    );
  }
}
