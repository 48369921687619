import Image from '../../../../tcomb/templates/Image';

export const option = (fieldConfig, state, ref, companyId) => {
  return {
    label: '',
    factory: Image,
    config: {
      company_id: companyId,
    },
  };
};
