import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { Link } from "react-router-dom";
import { get } from "lodash-es";
import fire from "firebase";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getOpenFormId } from "farmerjoe-common/lib/selectors/forms";
import { openField } from "farmerjoe-common/lib/actions/field";
import { openEmployee } from "farmerjoe-common/lib/actions/employee";
import { openAnalysis } from "farmerjoe-common/lib/actions/analysis";
import { openGroup } from "farmerjoe-common/lib/actions/group";
import { isAdmin, canViewUsers, canViewGroups } from "farmerjoe-common/lib/utils/User";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import {
  COMPANY_MAIN_GROUP_KEY,
  getBrowsingGroupKey,
  getOpenGroupId,
} from "farmerjoe-common/lib/selectors/groups";
import { getFeature } from "farmerjoe-common/lib/selectors/features";
import {
  analysisNotifications,
  fieldNotifications,
} from "farmerjoe-common/lib/constants/notifications";
import { openForm } from "farmerjoe-common/lib/actions/form";
import { getFieldSharingRequests } from "farmerjoe-common/lib/selectors/fields";
import { getCompany, getLatestActivityCount } from "farmerjoe-common/lib/selectors/companies";
import { setLatestActivityCountForCompany } from "farmerjoe-common/lib/actions/company";
import { setActivitySeenAt } from "farmerjoe-common/lib/actions/latestActivities";
import { getLatestCommentsCount } from "farmerjoe-common/lib/selectors/comments";

import "./style.css";
import withRouter from "../Router/withRouter";
import Icon from "../Common/Icon";
import CountBadge from "../Common/CountBadge";
import UnreadCompanyNotificationsBadge from "../Notifications/UnreadCompanyNotificationsBadge";
import { withLatestActivityCount } from "../../containers//HOC/WithLatestActivityForCompany";
import I18n from "../../language/i18n";
import { classes } from "../../utils/dom";

const NavigationBar = ({
  companyId,
  location,
  fieldId,
  employeeId,
  analysisId,
  myCompanyProfile,
  browsingGroup,
  producerId,
  traderId,
  formId,
  hasProducers,
  hasTraders,
  hasBonitur,
  fieldSharingRequestsCount,
  showFieldSharingRequests,
  actions: {
    openField,
    openEmployee,
    openAnalysis,
    openGroup,
    openForm,
    setLatestActivityCountForCompany,
    setActivitySeenAt,
  },
  restrictedAccess,
  latestActivitiesCount,
}) => {
  const fieldsSelected = /^\/company\/[^/]+\/field(?!(-table)|(-sharing-requests)).*/.test(
    location.pathname,
  );
  const latestSelected = /^\/company\/[^/]+\/latest.*/.test(location.pathname);
  const analysesSelected = /^\/company\/[^/]+\/analysis.*/.test(
    location.pathname,
  );
  const employeesSelected = /^\/company\/[^/]+\/employee.*/.test(
    location.pathname,
  );
  const mapSelected = /^\/company\/[^/]+\/map.*/.test(location.pathname);
  const producersSelected = /^\/company\/[^/]+\/producer.*/.test(
    location.pathname,
  );
  const tradersSelected = /^\/company\/[^/]+\/trader.*/.test(location.pathname);
  const formsSelected = /^\/company\/[^/]+\/form.*/.test(location.pathname);

  const fieldSharingRequestsSelected = /^\/company\/[^/]+\/field-sharing-requests.*/.test(location.pathname);

  const canViewProducers = canViewGroups(myCompanyProfile, "producer");
  const canViewTraders = canViewGroups(myCompanyProfile, "trader");

  const nav = () => {
    return (
      <nav className="sidebar-nav">
        <ul id="sidebarnav">
          {!!companyId && (
            <li className={classes(fieldsSelected && "active")}>
              <Link
                to={`/company/${companyId}/field${
                  fieldId ? `/${fieldId}` : ""
                }`}
                onClick={() => openField(fieldId)}>
                <Icon iconType={"fj"} name={"fields"} />{" "}
                <span>{I18n.t("field.fields")}</span>
                <UnreadCompanyNotificationsBadge types={fieldNotifications} />
              </Link>
            </li>
          )}
          {!!companyId && (
            <li className={classes(mapSelected && "active")}>
              <Link
                to={`/company/${companyId}/map${
                  fieldId ? `/${fieldId}` : ""
                }`}>
                <Icon iconType={"fa"} name={"map"} />{" "}
                <span>{I18n.t("map")}</span>
              </Link>
            </li>
          )}
          {!!companyId && (
            <li className={classes(latestSelected && "active")}>
              <Link to={`/company/${companyId}/latest`}
                onClick={() => {
                  setLatestActivityCountForCompany(companyId, 0);
                  setActivitySeenAt(companyId, fire.firestore.Timestamp.now());
                }}>
                <Icon
                  iconType={"ion"}
                  name={"ios-time"}
                  style={{ lineHeight: 0 }}
                />{" "}
                <span>{I18n.t("latestActivities.tabLabel")}</span>
                {latestActivitiesCount > 0 ? <CountBadge count={latestActivitiesCount} /> : null}
              </Link>
            </li>
          )}
          {!!companyId && isAdmin(myCompanyProfile) && (
            <li className={classes(analysesSelected && "active")}>
              <Link
                to={`/company/${companyId}/analysis${
                  analysisId ? `/${analysisId}` : ""
                }`}
                onClick={() => openAnalysis(analysisId)}>
                <Icon iconType={"fj"} name={"analysis"} />{" "}
                <span>{I18n.t("analysis_plural")}</span>
                <UnreadCompanyNotificationsBadge
                  types={analysisNotifications}
                />
              </Link>
            </li>
          )}
          {!!companyId && hasBonitur && (
            <li className={classes(formsSelected && "active")}>
              <Link
                to={`/company/${companyId}/form${formId ? `/${formId}` : ""}`}
                onClick={() => openForm(formId)}>
                <Icon iconType={"fal"} name={"analytics"} />{" "}
                <span>Bonitur</span>
              </Link>
            </li>
          )}
          {!!companyId &&
           myCompanyProfile &&
           canViewUsers(myCompanyProfile) && (
            <li className={classes(employeesSelected && "active")}>
              <Link
                to={`/company/${companyId}/employee${
                  employeeId ? `/${employeeId}` : ""
                }`}
                onClick={() => {
                  openEmployee(employeeId);
                }}>
                <Icon iconType={"fj"} name={"user"} />{" "}
                <span>{I18n.t("employees.plural")}</span>
              </Link>
            </li>
          )}

          {!!companyId &&
           browsingGroup === COMPANY_MAIN_GROUP_KEY &&
           hasProducers && canViewProducers && (
            <li className={classes(producersSelected && "active")}>
              <Link
                to={`/company/${companyId}/producer`}
                onClick={() => openGroup(COMPANY_MAIN_GROUP_KEY)}>
                <Icon
                  iconType={"fa"}
                  name={"tractor"}
                  style={{ fontSize: 18 }}
                />{" "}
                <span>{I18n.t("producers.plural")}</span>
              </Link>
            </li>
          )}

          {!!companyId &&
           browsingGroup === COMPANY_MAIN_GROUP_KEY &&
           hasTraders && canViewTraders && (
            <li className={classes(tradersSelected && "active")}>
              <Link
                to={`/company/${companyId}/trader`}
                onClick={() => openGroup(COMPANY_MAIN_GROUP_KEY)}>
                <Icon
                  iconType={"fa"}
                  name={"user-tie"}
                  style={{ fontSize: 18 }}
                />{" "}
                <span>{I18n.t("traders.plural")}</span>
              </Link>
            </li>
          )}

          {!!companyId &&
           browsingGroup === COMPANY_MAIN_GROUP_KEY &&
           showFieldSharingRequests && (
            <li className={classes(fieldSharingRequestsSelected && "active")}>
              <Link to={`/company/${companyId}/field-sharing-requests`}>
                <Icon
                  iconType={"fa"}
                  name={"globe-americas"}
                  style={{ fontSize: 18 }}
                />{" "}
                <span>{I18n.t("fieldSharing.navigationTabLabel")}</span>
                {fieldSharingRequestsCount > 0 ? <CountBadge count={fieldSharingRequestsCount} /> : null}
              </Link>
            </li>
          )}

        </ul>
      </nav>
    );
  };

  return [
    <aside key={1} className="navigation-bar">
      <div className="scroll-sidebar">
        {!restrictedAccess ? (nav()): null}
      </div>
    </aside>,
  ];
};

export default compose(
  withRouter,
  connect(
    (state: any, ownProps: any) => {
      const openCompanyId = selectors.getOpenCompanyId(state);
      const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
      const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(state, openCompanyId);

      const fieldSharingRequests = getFieldSharingRequests(state, openCompanyId);
      const fieldSharingRequestsCount =
        fieldSharingRequests.length > 0
          ? fieldSharingRequests.length
          : null;

      const company = getCompany(state.firestore.data, openCompanyId);
      const restrictedAccess = get(company, "restrictedAccess", false);
      const latestComments = getLatestCommentsCount(state, openCompanyId, state.firebase.auth.uid);

      return {
        companyId: openCompanyId,
        fieldId: selectors.getOpenFieldId(state),
        employeeId: selectors.getOpenEmployeeId(state),
        analysisId: selectors.getOpenAnalysisId(state),
        producerId: getOpenGroupId(state),
        traderId: getOpenGroupId(state),
        location: ownProps.location,
        hasProducers: getFeature(state, openCompanyId, "producers"),
        hasTraders: getFeature(state, openCompanyId, "traders"),
        browsingGroup,
        formId: getOpenFormId(state),
        hasBonitur: getFeature(state, openCompanyId, "bonitur"),
        myCompanyProfile,
        fieldSharingRequestsCount,
        showFieldSharingRequests: isAdmin(myCompanyProfile),
        restrictedAccess,
        latestActivitiesCount: getLatestActivityCount(state, openCompanyId),
        latestComments,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          openField,
          openEmployee,
          openAnalysis,
          openGroup,
          openForm,
          setLatestActivityCountForCompany,
          setActivitySeenAt,
        },
        dispatch,
      ),
    }),
  ),
  withLatestActivityCount,
)(NavigationBar);
