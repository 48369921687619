import React from "react";
import { Route, Redirect } from "react-router-dom";
import { get } from "lodash-es";
import firebase from "firebase/app";
import "firebase/auth";

import { classes } from "../../utils/dom";

const isEmptyChildren = children => React.Children.count(children) === 0;

export default class CachingPrivateRoute extends Route<any> {
  cache = null;
  previousCompany = get((this.context as any).router, "route.match.params.companyKey");
  previousFieldsPath = getFieldsPath((this.context as any).router);

  forceRerenderProp = Math.random();

  /*
   * Here we check if
   *  - we are switching between companies
   *  - navigating between active and archived fields
   * If one of the condition is met, we do not rely on
   * cached data, but reload.
   */
  componentDidUpdate() {
    const currentCompany = get(
      (this.context as any).router,
      "route.match.params.companyKey",
    );
    if (this.previousCompany && this.previousCompany !== currentCompany) {
      this.cache = null;
      this.forceUpdate();
    }
    this.previousCompany = currentCompany;

    const currentFieldsPath = getFieldsPath((this.context as any).router);
    if (this.previousFieldsPath && this.previousFieldsPath !== currentFieldsPath) {
      this.cache = null;
      this.forceUpdate();
    }
    this.previousFieldsPath = currentFieldsPath;
  }

  render() {
    const { match } = this.state;
    const {
      children,
      component,
      render,
      className,
      classNameBasedOnProps,
      renderFresh,
    } = this.props;

    const { history, route, staticContext } = (this.context as any).router;
    const location = this.props.location || route.location;
    const props = { match, location, history, staticContext };
    const user = firebase.auth().currentUser;

    if (!user) {
      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }

    let el: any = null;
    let forceRerenderProp = this.forceRerenderProp;

    if (this.cache && match) {
      this.forceRerenderProp = forceRerenderProp = Math.random();
    }

    if (match) {
      if (component) {
        el = React.createElement(component, props);
      }

      if (render) {
        el = render(props);
      }

      if (typeof children === "function") {
        el = children(props);
      }

      if (children && !isEmptyChildren(children)) {
        el = React.Children.only(children);
      }

      this.cache = el || null;
    } else {
      el = renderFresh ? null : this.cache;
    }

    if (!el) {
      return null;
    }

    return React.Children.map(el, child => {
      return (
        <div
          className={classes(
            match && "show",
            className,
            classNameBasedOnProps && classNameBasedOnProps(props),
          )}>
          {React.cloneElement(child, { forcererenderprop: forceRerenderProp })}
        </div>
      );
    });
  }
}

/*
 * Since we have a caching routes, when switching between
 * archived fields and active fields, the path in redux is
 * not repopulated, so the fields are not updated.
 * This method checks for path updates related to the fields.
 */
const getFieldsPath = (router) => {
  const pathName = get(router, "route.location.pathname", null);
  if (!pathName) {
    return null;
  }

  if (/company\/.+\/(archive|field)(.+)?/.test(pathName)) {
    return pathName.split("/")[3];
  }
  return null;
};
