import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as constants from '../../styles/style';
import Icon from '../Common/Icon';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { uploadImages } from '../../actions/image';
import ImagePicker from '../ImagePicker/ImagePicker';

// TODO: improve typings
type Props = any;
type State = any;

class CameraImage extends PureComponent<Props, State> {
  static propTypes = {
    field: PropTypes.object.isRequired,
  };

  render() {
    return (
      <ImagePicker
        cropping={false}
        width={1920}
        height={1920}
        imageQuality={0.6}
        onPick={image => {
          const { field } = this.props;
          this.props.actions.uploadImages([image], field);
        }}>
        <Icon
          name="camera"
          iconType={'fj'}
          style={{
            marginRight: 11,
            marginBottom: 1,
            marginLeft: 18,
            fontSize: 30,
            color: constants.FJNAVCOLOR,
            cursor: 'pointer',
          }}
        />
      </ImagePicker>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          uploadImages,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  state => ({}),
  mapDispatchToProps,
)(CameraImage);
