import React from "react";
import { DefaultTabBar } from "./ScrollableTabView";
import "./style.css";

type Props = {
  /**
   * triggered when the search is closed
   */
  onCloseSearch?: (...args: Array<any>) => any;
  searchVisible?: boolean;
  showSort?: boolean;
  searchComponent?: React.ReactNode;
  sortComponent?: React.ReactNode;
  afterTabBarComponent?: React.ReactNode;
  beforeTabBarComponent?: React.ReactNode;
  beforeSearchComponent?: React.ReactNode;
};

type State = {
  searchVisible?: boolean;
};

export default class TabBarSearchSort extends React.Component<Props, State> {
  static defaultProps = {
    showSort: true,
  };

  state = {
    searchVisible: this.props.searchVisible,
  };

  render() {
    const {
      showSort,
      searchComponent,
      sortComponent,
      beforeSearchComponent,
      beforeTabBarComponent,
      afterTabBarComponent,
      ...restProps
    } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <div className="page-card-top-bar-container">
          {beforeTabBarComponent || null}
          <DefaultTabBar style={{ flex: 1 }} {...restProps} />
          {afterTabBarComponent || null}
        </div>

        <div className="toolbar-container">
          {beforeSearchComponent || null}
          {searchComponent}
          {showSort
            ? (
              <div
                style={{
                  display: "flex",
                  width: 50,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "auto",
                }}>
                {sortComponent}
              </div>
            )
            : null}
        </div>
      </div>
    );
  }

  close() {
    this.setState({ searchVisible: !this.state.searchVisible });

    if (this.state.searchVisible && this.props?.onCloseSearch) {
      this.props.onCloseSearch();
    }
  }
}
