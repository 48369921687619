import * as React from 'react';
import { classes } from '../../utils/dom';

type Props = {
  children?: React.ReactNode;
  bodyProps?: Record<string, any>;
  className?: string;
  title?: any;
};

export default ({
  children,
  bodyProps,
  className,
  ...restProps
}: Props) => (
  <div className={classes('card', className)} {...restProps}>
    <div className="card-body" {...bodyProps}>
      {children}
    </div>
  </div>
);
