import React from "react";
import { includes, isArray } from "lodash-es";
import { compose } from "redux";
import connect from "react-redux/es/connect/connect";
import type { Notification } from "farmerjoe-common/lib/flow/types";
import { getUnreadCompanyNotifications } from "farmerjoe-common/lib/selectors/notifications";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";

import CountBadge from "../Common/CountBadge";

type Props = {
  loadingNotifications?: boolean;
  notifications: Array<Notification>;
  companyId?: string;
  types?: Array<String> | any; // notification types
};

const UnreadCompanyNotificationsBadge = ({
  loadingNotifications,
  notifications,
  types,
}: Props) => {

  const _notifications = types
    ? notifications.filter(n =>
      includes(
        isArray(types) ? types : [types],
        n.notification_for,
      ),
    )
    : notifications;

  return !loadingNotifications && _notifications.length > 0
    ? (
      <CountBadge count={_notifications.length } />
    )
    : null;
};

export default compose(
  connect((state) => {
    const openCompany = getOpenCompanyId(state);
    const profile = state.firebase.profile;
    const notifications = getUnreadCompanyNotifications(
      state,
      profile.uid,
      openCompany,
    );

    return {
      notifications,
    };
  })(UnreadCompanyNotificationsBadge),
);
