import React from 'react';

export {
  truncate,
  replaceNullWithEmpty,
  acronym,
  collatorSort,
} from 'farmerjoe-common';

/**
 * Output a text with no fontScaling and on 1 line.
 * Used with stuff that shouldn't be made bigger by the user's settings
 *
 * @param text
 * @returns {*}
 */
export const textNoScaling = text => <span>{text}</span>;

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    (getTextWidth as any).canvas ||
    ((getTextWidth as any).canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}
