import stylesheet from '../../../../tcomb/stylesheets/style';
import { defaultOptions } from '../../../../tcomb/utils/options';
import { cloneDeep, get } from 'lodash-es';

const classes = {
  'mt-5': { marginTop: 5 },
  'mb-5': { marginBottom: 5 },
  'mb-10': { marginBottom: 10 },
  'mb-15': { marginBottom: 15 },
};

export const option = fieldConfig => {
  const required = get(fieldConfig, 'required', true);
  const cssClasses = get(fieldConfig, 'config.cssStyles', []);
  const multiline = get(fieldConfig, 'multiline', false);

  let customStylesheet = {};

  if (cssClasses.length) {
    const cssValues = cssClasses.reduce((prev, cur) => {
      const values = classes[cur] || {};
      return {
        ...prev,
        ...values,
      };
    }, {});

    const defaultStyle = cloneDeep(stylesheet);

    customStylesheet = {
      stylesheet: {
        ...defaultStyle,
        formGroup: {
          ...defaultStyle.formGroup,
          normal: {
            ...defaultStyle.formGroup.normal,
            ...cssValues,
          },
          error: {
            ...defaultStyle.formGroup.error,
            ...cssValues,
          },
        },
      },
    };
  }

  return {
    ...defaultOptions(fieldConfig.label, required),
    ...customStylesheet,
    multiline: multiline,
  };
};
