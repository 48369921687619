import * as React from 'react';
import { get } from 'lodash-es';
import I18n from '../../../language/i18n';
import type { Comment } from '../../../flowTypes';

type Props = {
  comment: Comment;
};

export default class YieldComment extends React.PureComponent<Props> {
  render() {
    const { comment } = this.props;
    const totalYield = get(comment, 'extraData.total');
    const perHa = get(comment, 'extraData.perHa');

    return (
      <div className={'d-flex flex-row'}>
        <span style={{ paddingLeft: 5, marginRight: 50 }}>
          {I18n.t('crop.yield')}: {totalYield} t
        </span>
        <span>{perHa} t/ha</span>
      </div>
    );
  }
}
