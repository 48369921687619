import * as React from "react";
import { yieldComment } from "../../utils/Comment";
import { get } from "lodash-es";
import I18n from "../../language/i18n";
import YieldModel from "../../tcomb/models/cropYield";
import t from "tcomb-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCropByFieldId } from "farmerjoe-common/lib/selectors/crops";
import type { Crop, Comment, Profile } from "../../flowTypes";
import { yieldPerHa } from "farmerjoe-common";
import { deleteComment } from "farmerjoe-common/lib/actions/comment";

import firebase from "../../data/firebase";
import Dialog, { AlertDialog } from "../Dialog/Dialog";
import DeleteButton from "../Common/DeleteButton";

const Form = t.form.Form;

type Props = {
  crop: Crop;
  comment?: Comment;
  editMode: boolean;
  onClose: (...args: Array<any>) => any;
  auth: Profile;
  show: boolean;
  actions: {
    deleteComment: typeof deleteComment;
  };
};

type State = {
  yield: null | number;
  alertMessage: null | string;
  alertTitle: null | string;
};

class YieldForm extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      yield: null,
      alertMessage: null,
      alertTitle: null,
    };

    if (props.editMode) {
      const total = get(props, "crop.yield.total", null);

      this.state = {
        ...this.state,
        yield: total,
      };
    }
  }

  onSave() {
    const { crop, auth, editMode, comment } = this.props;
    const value = (this.refs.form as any).getValue();

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    const field = get(crop, "field");
    const ha = get(crop, "field.size", 0);

    const totalYield = value.yield;
    const perHaYield = yieldPerHa(totalYield, ha);

    const cropYield = {
      total: totalYield,
      perHa: perHaYield,
    };

    const db = firebase.firestore();

    const batch = db.batch();
    let commentRef = firebase
      .firestore()
      .collection("comments")
      .doc();

    // edit mode? We need to update the comment then
    if (editMode) {
      commentRef = firebase
        .firestore()
        .collection("comments")
        .doc(comment?.key);
    }

    const commentForDb = yieldComment(
      commentRef.id,
      field,
      { ...crop, yield: cropYield },
      auth,
    );

    batch.update(
      firebase
        .firestore()
        .collection("crops")
        .doc(crop.key),
      { yield: cropYield },
    );
    batch.set(commentRef, commentForDb, { merge: true });

    batch.commit();

    this.props.onClose();
  }

  onChange(value) {
    this.setState({
      ...value,
    });
  }

  render() {
    const { crop, editMode } = this.props;

    const { show, onClose } = this.props;
    if (!show) {
      return null;
    }

    if (!crop) {
      return null;
    }

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t("crop.yield")}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t("cancel")}
            </button>{" "}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t("save")}
            </button>
          </div>
        }>
        <div style={{ flex: 1 }}>
          <div style={{ marginTop: 30 }}>
            <div>
              <Form
                ref="form"
                type={YieldModel.model}
                options={() =>
                  YieldModel.options(this.state as any, get(crop, "field.size"))
                }
                value={this.state}
                onChange={this.onChange.bind(this)}
              />

              {editMode
                ? (
                  <div
                    style={{
                      flexDirection: "row",
                      marginTop: 40,
                      marginBottom: 40,
                      marginLeft: 20,
                      marginRight: 20,
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    {editMode
                      ? (
                        <div className="text-center">
                          <DeleteButton
                            buttonText={I18n.t("crop.deleteYield")}
                            alertTitle={I18n.t("crop.deleteYield")}
                            alertMessage={I18n.t(
                              "crop.doYouReallyWantToDeleteYield",
                            )}
                            onDelete={() => {
                              const { comment, actions } = this.props;

                              actions.deleteComment(comment);

                              this.props.onClose();
                            }}
                          />
                        </div>
                      )
                      : null}
                  </div>
                )
                : null}

              <AlertDialog
                show={!!this.state.alertMessage}
                onClose={() =>
                  this.setState({ alertMessage: null, alertTitle: null })
                }
                title={this.state.alertTitle}
                children={this.state.alertMessage}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const selector = (state, ownProps) => {
  let crop = ownProps.crop;
  const comment = ownProps.comment;

  if (comment && !crop) {
    crop = getCropByFieldId(
      state.firestore.data,
      comment.company_id,
      comment.field_id,
      comment.active_crop_uid,
    );
  }

  // Loading from persistence?
  if (!crop) {
    return {};
  }

  return {
    crop: crop,
    editMode: !!comment,
    comment: comment,
    auth: state.firebase.profile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          deleteComment,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  selector,
  mapDispatchToProps,
)(YieldForm);
