import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as labActions from 'farmerjoe-common/lib/actions/labs';
import I18n from '../../language/i18n';

import { firebaseConnect } from 'react-redux-firebase';
import Modal from 'react-bootstrap-modal';

// TODO: improve typings
type Props = any;
type State = any;

class FormSelectResidueType extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object,
    openView: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  };

  render() {
    const content = (
      <div>
        <button
          className={'btn btn-secondary'}
          onClick={() => this.props.openView('FormFieldSelect')}>
          {I18n.t('crop.from_field')}
        </button>{' '}
        <button
          className={'btn btn-secondary'}
          onClick={() =>
            this.props.openView('FormSelectWarehouse', { stock: 'trading' })
          }>
          {I18n.t('crop.commodityItem')}
        </button>{' '}
        <button
          className={'btn btn-secondary'}
          onClick={() =>
            this.props.openView('FormSelectWarehouse', { stock: 'warehouse' })
          }>
          {I18n.t('crop.stockItem')}
        </button>
      </div>
    );

    return [
      <Modal.Body onClick={e => e.stopPropagation()} key={0}>
        {content}
      </Modal.Body>,
      <Modal.Footer onClick={e => e.stopPropagation()} key={1}>
        <div className="d-flex flex-grow-1">
          <button className="btn btn-secondary" onClick={this.props.goBack}>
            {I18n.t('cancel')}
          </button>
        </div>
      </Modal.Footer>,
    ];
  }
}

const selector = (state, ownProps) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...labActions,
        },
      ),
      dispatch,
    ),
  };
}

const wrappedFormSelectResidueType = firebaseConnect(props => {
  return [{ path: 'labs' }];
})(FormSelectResidueType);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFormSelectResidueType);
