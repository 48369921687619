import React from 'react';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import hoistStatics from 'hoist-non-react-statics';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getCompanyCollaboratorsQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Fields';
import { getCompanyCollaborators }from 'farmerjoe-common/lib/selectors/collaborators';

/**
 * HOC that loads shared fields with the company
 *
 *
 * @param WrappedComponent
 */
const WithFieldCollaborators = (WrappedComponent) => {
  const FieldCollaboratorsLoader = (props) => {
    return <WrappedComponent {...props} />;
  };

  const connectedToFirestore = firestoreConnect((props) => {
    const { openCompany } = props;

    const paths: any = [];

    if (openCompany) {
      paths.push(getCompanyCollaboratorsQuery(openCompany));
    }

    return paths;
  })(FieldCollaboratorsLoader);

  const selector = (state) => {
    const openCompany = getOpenCompanyId(state);
    const companyCollaborators = getCompanyCollaborators(state, openCompany);
    const paths: any = [];

    if (openCompany) {
      paths.push(getCompanyCollaboratorsQuery(openCompany));
    }

    return {
      openCompany,
      loaded: hasLoaded(paths, state),
      companyCollaborators,
    };
  };

  const ConnectedToRedux = connect(selector)(connectedToFirestore);

  return hoistStatics(ConnectedToRedux, WrappedComponent);
};

export default WithFieldCollaborators;
