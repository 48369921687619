import React from "react";
import Icon from "./Icon";
import * as constants from "../../styles/style";

type TProps = {
  onClick: (args: any) => any;
  text: string;
  disabled?: boolean;
  style?: any;
};

const LineWithArrow = ({onClick, text, disabled, style}: TProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${constants.FJMUTEDLIGHTER}`,
        margin: "1em auto 0 auto",
        cursor: `${disabled ? "default" : "pointer"}`,
        width: "90%",
        ...style,
      }}
      onClick={onClick}
    >
      <div style={{color: disabled ? constants.FJMUTEDLIGHTER : constants.FJMUTEDDARK}}>{text}</div>
      <Icon
        iconType={"fa"}
        name={"arrow-right"}
        style={{
          fontSize: 18,
          color: disabled ? constants.FJMUTEDLIGHTER : constants.FJAPPLEGREEN,
        }}
      />
    </div>
  );
};

export default LineWithArrow;
