import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import { map, get } from 'lodash-es';

import * as companySelector from 'farmerjoe-common/lib/selectors/companies';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { redirectedToCompany } from 'farmerjoe-common/lib/actions/company';
import { getUserPermissionObject } from 'farmerjoe-common/lib/selectors/user';

import Card from '../Page/Card';
import Icon from '../Common/Icon';
import FormCompany from '../Company/FormCompany';

import I18n from '../../language/i18n';
import type { Company } from '../../flowTypes';
import * as constants from '../../styles/style';
import withRouter from '../Router/withRouter';
import { isProfileCompleted } from '../../utils/auth';


interface Props {
  children?: React.ReactNode;
  companies?: Array<Company>;
  loading?: boolean;
  actions?: Record<string, any>;
  redirectedToCompany?: boolean;
  history?: Record<string, any>;
  signup?: any;
};

const NoCompanySelected = (props: Props) => {
  const { redirectedToCompany, loading, companies, actions, history, signup } = props;
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    if (!redirectedToCompany && !loading && companies?.length) {
      actions?.redirectedToCompany(true);
      history?.push(`/company/${companies[0].key}`);
    }

  }, [redirectedToCompany, loading, companies, actions, history]);


  let component = I18n.t('no_company_selected');
  if (loading) {
    component = null;
  } else if (!companies || companies.length === 0) {
    component = noCompanyCreatedYet(signup, setShowCreateForm);
  }

  return (
    <div
      style={{
        maxWidth: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {component ? <Card>{component}</Card> : null}
      {showCreateForm
      ? (
        <FormCompany
          show={showCreateForm}
          onClose={() => setShowCreateForm(false)}
        />
      )
      : null}
    </div>
  );
};

const noCompanyCreatedYet = (signup, showForm) => {
  const onClick = (e) => {
    if  (isProfileCompleted(signup)) {
      showForm(true);
    }
    e.preventDefault();
  };

  const isBtnDisabled = !isProfileCompleted(signup);

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <Icon
        name="barn"
        iconType={'fj'}
        style={{
          fontSize: 100,
          marginBottom: 20,
          color: constants.FJMUTEDDARK,
        }}
      />

      <div style={{ ...constants.styles.stdSize, marginBottom: 60 }}>
        {I18n.t('company.create_first_company')}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button
          className="btn btn-primary"
          disabled={isBtnDisabled}
          onClick={onClick}>
          <Icon
            iconType={'fj'}
            style={{ right: 15, fontSize: 16 }}
            name="plus"
          />{' '}
          {I18n.t('add_company')}
        </button>
      </div>
    </div>
  );
};

export default compose<typeof NoCompanySelected>(
  connect(
    (state: any) => {
      const profile = state.firebase.profile;
      const userPermissions = getUserPermissionObject(state);
      const activeInCompanies = get(userPermissions, 'isActiveInCompanies', []);
      const companies = companySelector.getCompanies(
        state.firestore.data,
        true,
        activeInCompanies,
      );

      let dataForCompaniesHasLoaded = false;

      if (typeof profile !== 'undefined') {
        dataForCompaniesHasLoaded = hasLoaded(
          map(activeInCompanies, v => `companies/${v}`),
          state,
        );
      }

      return {
        companies,
        loading: !isLoaded(
          state.firebase,
          dataForCompaniesHasLoaded ? true : undefined,
        ),
        redirectedToCompany: state.redirectedToCompany,
        signup: state.signup,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          redirectedToCompany,
        },
        dispatch,
      ),
    }),
  ),
  withRouter,
)(NoCompanySelected);
