import React from 'react';

import I18n from '../../language/i18n';
import './style.css';
import Card from '../Page/Card';

// TODO: improve typings
type Props = any;

export default class AnalysisPDF extends React.Component<Props> {
  /**
   * Get the filePath from the analysis Result
   * @param a
   */
  getFilePath(a) {
    let uri = '';
    if (a.result) {
      const key = Object.keys(a.result.file)[0];

      uri = a.result.file[key].downloadURL;
    }

    return uri;
  }

  render() {
    const { analysis } = this.props;
    const uri = this.getFilePath(analysis);

    return (
      <div className="analysis-pdf">
        <a className="link" href={uri} target="_blank" rel="noreferrer">
          <Card>
            <i className="fa fa-file-pdf" />
            <div>{I18n.t('analysis.result')}</div>
          </Card>
        </a>
      </div>
    );
  }
}
