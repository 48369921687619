import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { setEmployeesView, getEmployeesView } from '../../actions/employeesTable';
import { TableListViewSwitch } from '../Common/TableListViewSwitch';
import { TableListViewState } from '../../flowTypes';

type Props = {
  employeesView: TableListViewState;
  openCompany: string;
  actions: {
    setEmployeesView: typeof setEmployeesView;
  };
};

function EmployeesViewSwitch({
  employeesView,
  openCompany,
  actions: { setEmployeesView },
}: Props) {
  return (
    <TableListViewSwitch
      currentState={employeesView}
      onStateChange={(newViewState) => setEmployeesView(openCompany, newViewState)}
    />
  );
}

export default compose(
  connect(
    (state: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      return {
        openCompany,
        employeesView: getEmployeesView(state, openCompany),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          setEmployeesView,
        },
        dispatch,
      ),
    }),
  ),
)(EmployeesViewSwitch);
