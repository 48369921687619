import React from 'react';
import { ShareButtonProps } from './types';
import { FJWHITE } from '../../styles/style';
import I18n from '../../language/i18n';

const ShareButton = ({ onClick }: ShareButtonProps) => {
  return (
    <div style={{ marginLeft: 'auto' }} onClick={onClick}>
      <div className="btn btn-primary">
        <span
          style={{
            fontSize: 16,
            color: FJWHITE,
          }}
        >
          {I18n.t('profile.openProfileBtn')}
        </span>
      </div>
    </div>
  );
};

export default ShareButton;
