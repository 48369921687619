import * as React from "react";
import type { Employee as CompanyUser } from "../../flowTypes";
import Text from "../Common/Text";
import * as constants from "../../styles/style";
import { getRoleTranslation } from "./utils";

type Props = {
  user: CompanyUser;
};

export default class Role extends React.PureComponent<Props> {
  render() {
    const { user } = this.props;

    const roleLabel = getRoleTranslation(user.role);

    return (
      <Text style={{ ...constants.styles.small, ...constants.styles.muted }}>
        {user.department ? user.department + " - " : ""}
        {roleLabel}
      </Text>
    );
  }
}
