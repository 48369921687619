import * as React from 'react';
import './style.css';
import { captureException } from '../../utils/sentry';
import Card from '../Page/Card';
import I18n from '../../language/i18n';

export default class ErrorBoundary extends React.Component<
  {
    children?: React.ReactNode;
  },
  {
    error?: any;
  }
> {
  state = {
    error: null,
  };

  componentDidCatch(
    error: unknown,
    info: {
      componentStack: string;
    },
  ) {
    this.setState({ error });
    captureException(error, { extra: info });
  }

  render() {
    const { children } = this.props;
    return this.state.error
      ? (
      <div className="boundary-error">
        <Card>
          <h4 className="card-title">{I18n.t('error')}</h4>
          <p>{I18n.t('errorMessage')}</p>
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => window.location.reload()}>
              {I18n.t('refresh')}
            </button>
          </div>
        </Card>
      </div>
        )
      : (
          children
        );
  }
}
