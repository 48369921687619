import * as React from 'react';
import I18n from '../../language/i18n';
import Box from '../Common/Box/Box';
// import Button from '../Common/Button'
import { FJNEWGREEN, styles } from '../../styles/style';
// import type { Profile } from 'farmerjoe-common'

const divInputStyle = {
  flex: 1,
  borderWidth: 1,
  borderColor: FJNEWGREEN,
  borderRadius: 5,
  paddingLeft: 5,
  minHeight: 35,
  paddingBottom: 0,
  marginBottom: 0,
};

type Props = {
  profile: any; // Profile
  emailChangeError?: string;
  email: string;
  currentPassword?: string;
  onChangeEmail: (...args: Array<any>) => any;
  onChangePassword: (...args: Array<any>) => any;
  onSubmit: (...args: Array<any>) => any;
};
export const ActivationNewEmailBox = ({
  profile,
  emailChangeError,
  email,
  currentPassword,
  onChangeEmail,
  onChangePassword,
  onSubmit,
}: Props) => {
  return (
    <div>
      <div style={{ ...styles.muted }}>
        {I18n.t('activation.confirmAccountDesc')}
      </div>
      <div>
        <div className={'mt-3 mb-3 text-danger'}>{emailChangeError}</div>
        <div
          style={{
            flexDirection: 'row',
          }}>
          <div className={'form-material mt-3'}>
            <div className={'form-group'}>
              <label htmlFor="email">
                {I18n.t('email')}
                <span className={'text-muted'}>
                  ({I18n.t('activation.changeEmailIfNotCorrect')})
                </span>
              </label>
              <input
                name={'email'}
                className={'form-control'}
                autoCapitalize={'none'}
                value={profile.email !== email ? email : profile.email}
                onChange={event => {
                  onChangeEmail(event.target.value);
                }}
              />
            </div>

            {profile.email !== email
              ? (
              <div className={'form-group'}>
                <input
                  placeholder={I18n.t('password.current')}
                  className={'form-control'}
                  autoCapitalize={'none'}
                  value={currentPassword}
                  onChange={event => {
                    onChangePassword(event.target.value);
                  }}
                />
              </div>
                )
              : null}
          </div>
        </div>
      </div>
      <div className={'mt-5 text-center'}>
        <button
          className={'btn btn-primary'}
          onClick={() => {
            onSubmit();
          }}>
          {profile.email !== email
            ? I18n.t('activation.changeAccountAndSendVerification')
            : I18n.t('activation.sendVerification')}
        </button>
      </div>
    </div>
  );
};
