import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { get } from "lodash-es";

import { openField } from "farmerjoe-common/lib/actions/field";
import * as actionCreators from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getComments } from "farmerjoe-common/lib/selectors/comments";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { getCommentsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Comments";

const DEFAULT_LIMIT = 5;

const selector = (state, ownProps) => {
  const { field } = ownProps;
  const openCompanyId = selectors.getOpenCompanyId(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompanyId,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
  const comments = field ? getComments(state, field.key, field.activeCrop.key) : [];
  const query = field ? getCommentsQuery(field, browsingGroup, myCompanyProfile, openCompanyId, DEFAULT_LIMIT) : null;
  const currentFieldsTab = get(state, `currentFieldsTab.${openCompanyId}`, null);

  return {
    openCompanyId,
    myCompanyProfile,
    loading: query ? !hasLoaded([query], state) : false,
    commentsReadyForPrint: state.commentsReadyForPrint,
    browsingGroup,
    comments,
    currentFieldsTab,
    field,
  };
};

const FetchCommentsComponent = firestoreConnect(props => {
  const { myCompanyProfile, browsingGroup, openCompanyId, field } = props;
  if (!field) {
    return [];
  }
  return  [
    getCommentsQuery(field,browsingGroup, myCompanyProfile, openCompanyId, DEFAULT_LIMIT),
  ];
});

export default compose<any>(
  connect(
    selector,
    dispatch => ({
      actions: bindActionCreators(
        {
          ...actionCreators,
          openField,
        },
        dispatch,
      ),
    }),
  ),
  FetchCommentsComponent,
);
