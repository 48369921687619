import React from 'react';
import stylesheet from '../stylesheets/style';

import I18n from '../../language/i18n';
import t from 'tcomb-form';
import FormInput from '../templates/FormInput';
import Boolean from '../templates/Boolean';
import IpAddressListValidator from '../validators/ipAddressList';

const model = () => {
  return t.struct({
    name: t.String,
    ip_filtering: t.Boolean,
    ip_filter: IpAddressListValidator,
  });
};

class Factory extends t.form.Struct {
  getInputs() {
    const fieldOptions = this.props.options.fields;
    fieldOptions.ip_filter = {
      ...fieldOptions.ip_filter,
      disabled: !this.state.value.ip_filtering, // disable filter field if filtering not on
    };
    return super.getInputs();
  }
}

const options = function() {
  return {
    stylesheet: stylesheet,
    auto: 'none',
    factory: Factory,
    fields: {
      name: {
        label: `${I18n.t('apiKeys.name')} *`,
        factory: FormInput,
        autoCorrect: false,
      },
      ip_filtering: {
        label: `${I18n.t('apiKeys.ipFiltering')}`,
        factory: Boolean,
        autoCorrect: false,
      },
      ip_filter: {
        label: `${I18n.t('apiKeys.ipFilter')}`,
        factory: FormInput,
        autoCorrect: false,
        help: `${I18n.t('apiKeys.ipFilterHelp')}`,
      },
    },
  };
};

export default { model, options };
