import React from "react";
import {
  useLoadScript,
  GoogleMap,
  GoogleMapProps,
} from "@react-google-maps/api";
import { MAPS_API_KEY } from "../../constants";
import { Loading } from "../Loading/Loading";

const GOOGLE_MAPS_LIBRARIES = ["geometry", "drawing", "places"];
export const DEFAULT_MAP_CENTER = { lat: -34.397, lng: 150.644 };
export const DEFAULT_MAP_ZOOM = 12;

/**
 * Loads Google Maps API JS SDK.
 * Please see `useLoadScript()` docs for details.
 */
export const useLoadGoogleMapsApiScript = () => {
  return useLoadScript({
    googleMapsApiKey: MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES as any,
  });
};

/**
 * Wraps the Google Maps script loading and the Map rendering.
 */
export default function GoogleMapWithLoader(props: GoogleMapProps) {
  const { isLoaded, loadError } = useLoadGoogleMapsApiScript();

  const renderMap = () => {
    return (
      <GoogleMap
        {...props}
        zoom={props.zoom || DEFAULT_MAP_ZOOM}
        center={props.center || DEFAULT_MAP_CENTER}
      />
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <Loading />;
}
