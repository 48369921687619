import * as React from 'react';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';

import { firestoreConnect } from 'react-redux-firebase';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';

import {
  userSearchWord,
  userState,
  getGroupUsers,
  getCompanyGroupProfileForLoggedInUser,
} from 'farmerjoe-common/lib/selectors/user';
import { getGroup, getOpenGroupId } from 'farmerjoe-common/lib/selectors/groups';

import GroupEmployeesComponent from '../../components/Group/GroupEmployeesComponent';
type Props = {
  loading: boolean;
};

class GroupEmployees extends React.PureComponent<Props> {
  render() {
    return <GroupEmployeesComponent {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const groupId = getOpenGroupId(state)
    ? getOpenGroupId(state)
    : ownProps.groupId;
  const users = getGroupUsers(state, openCompany, groupId);

  const search = userSearchWord(state, openCompany);
  const group = getGroup(state, openCompany, groupId);

  const hasLoadedEverything = hasLoaded(
    [`companies/${openCompany}/users`],
    state,
  );

  return {
    loading: !hasLoadedEverything,
    group: group,
    email: state.firebase.profile.email,
    myCompanyProfile: myCompanyProfile,
    openCompany: openCompany,
    groupId: groupId,
    company: company,
    users: users,
    search: search,
    filter: userState(state, openCompany),
    auth: state.firebase.auth,
  };
};

const wrappedGroupEmployees = firestoreConnect(props => {
  const { groupId } = props;

  if (!groupId) {
    return [];
  }
  return [
    {
      collection: 'groups',
      doc: props.groupId,
      subcollections: [{ collection: 'users' }],
      storeAs: `usersByCompanyAndGroup-${props.openCompany}-${props.groupId}`,

      byIds: `usersByGroup/${props.openCompany}/${props.groupId}`,
    },
  ];
})(GroupEmployees);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedGroupEmployees);
