import React, { useState, useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import fire from "firebase/app";
import "firebase/firestore";
import { firestoreConnect } from "react-redux-firebase";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import t from "tcomb-form";

import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { canDo, UserObj } from "farmerjoe-common/lib/utils/User";
import * as waitTimeActions from "farmerjoe-common/lib/actions/waitTime";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";
import { getWaitTime } from "farmerjoe-common/lib/selectors/waittimes";
import { getFavorites } from "farmerjoe-common/lib/selectors/favorites";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getFavoritesQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Favorites";
import { getWaitTimeQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Waittimes";

import PlantProtectionFieldsSelector from "./PlantProtectionFieldsSelector";
import PlantProtectionMarkArea from "./PlantProtectionMarkArea";

import DeleteButton from "../Common/DeleteButton";
import Favorites from "../Common/Favorites";
import Icon from "../Common/Icon";
import withRouter from "../Router/withRouter";
import Dialog, { AlertDialog } from "../Dialog/Dialog";
import { Loading } from "../Loading/Loading";

import WaitModel from "../../tcomb/models/wait";
import * as constants from "../../styles/style";
import firebase from "../../data/firebase";
import { dummy } from "../../utils/Comment";
import fetch from "../../utils/fetch";
import { getUidToken } from "../../utils/auth";
import { BASE_API_URL } from "../../constants";
import I18n from "../../language/i18n";

const Form = t.form.Form;
const API_PLANT_PROTECTION = `${BASE_API_URL}/plant-protection/get`;

// TODO: improve typings
type Props = {
  onClose: (...args: any) => any;
  show: boolean;
  editMode?: boolean;
  waitTime?: any;
  actions: {
    editWaitTime: (arg1: any) => any;
  };
  field: any;
  auth: any;
  browsingGroup: string;
  comment: any;
  loading: boolean;
  favorites: any;
  myCompanyProfile: any;
  editWaitTime: any;
  openCompany: string;
};

const WaitTime = (props: Props) => {
  const {
    loading,
    favorites,
    myCompanyProfile,
    show,
    onClose,
    editMode,
    field,
    waitTime,
    auth,
    comment,
    browsingGroup,
  } = props;

  const sprayers = useSprayers(props.openCompany);

  const [prevWaitTime, setWaitTime] = useState(waitTime);
  const [state, setState] = useState({
    markedArea: {
      polygon: [],
      center: null,
      areaSize: 0,
    },
    selectedFields: {},
    showMapAreaDialog: false,
    showFieldsSelectorDialog: false,
    alertMessage: "",
    alertTitle: "",
    template: null,
    wait_time: null,
    ...waitTime,
  });

  const formRef = React.createRef();

  if (prevWaitTime !== waitTime) {
    setWaitTime(waitTime);
    setState({ ...state, ...waitTime });
  }
  useEffect(() => {
    return () => {
      props.actions.editWaitTime(null);
    };
  }, [props.actions]);

  if (!show) {
    return null;
  }

  const onSave = () => {
    const form = formRef.current;

    if (!form) {
      return;
    }

    const value = (form as any).getValue();

    // If value is null, then the form has errors
    if (value === null) {
      setState({
        ...state,
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    const db = firebase.firestore();
    const batch = db.batch();

    if (editMode) {
      updateWaitTime(batch, value);
    } else {
      const selectedFields = Object.keys(state.selectedFields);
      if (selectedFields.length) {
        selectedFields.map((fieldId) =>
          createWaitTime(batch, value, state.selectedFields[fieldId]),
        );
      }
      createWaitTime(batch, value, field);
    }

    if (value.favorite) {
      createFavoriteWaitTime(batch, value);
    }

    batch.commit();
    onClose();
  };

  const updateWaitTime = (batch, formValue) => {
    const db = firebase.firestore();
    const waitTimeRef = db.collection("waittimes").doc(waitTime.key);
    const commentRef = db.collection("comments").doc(comment.key);

    const template = String(formValue.template).trim();
    const days = formValue.wait_time || 0;
    const endsOn = moment(formValue.applied_on).add(days, "days");

    // Update the waittime
    let updatedWaitTime = {
      ...waitTime,
      company_id: field.company_id,
      applied_on: moment(formValue.applied_on).toDate(),
      ends_on: endsOn.toDate(),
      template: template,
      wait_time: days,
      favorite: formValue.favorite,
      modified_by: UserObj(auth),
      modified: fire.firestore.FieldValue.serverTimestamp(),
      // We show the wait time for 10 days after it has ended, that's why keep it active
      active: !!moment().isBefore(moment(endsOn).add(10, "days")),
      notes: formValue.notes,
      sprayer: formValue.sprayer,
    };

    if (state.markedArea.polygon.length > 2) {
      updatedWaitTime = {
        ...updatedWaitTime,
        markedArea: state.markedArea,
      };
    }

    if (!state.markedArea.polygon.length) {
      updatedWaitTime = {
        ...updatedWaitTime,
        markedArea: fire.firestore.FieldValue.delete(),
      };
      batch.update(waitTimeRef, updatedWaitTime);
      delete updatedWaitTime.markedArea;
    }

    const updatedComment = {
      ...comment,
      text: updatedWaitTime,
      modified_by: UserObj(auth),
      modified: fire.firestore.FieldValue.serverTimestamp(),
    };

    batch.update(waitTimeRef, updatedWaitTime);
    batch.update(commentRef, updatedComment);
  };

  const createWaitTime = (batch, formValue, field) => {
    const db = firebase.firestore();
    const waitTimeRef = db.collection("waittimes").doc();
    const commentRef = db.collection("comments").doc();

    const template = String(formValue.template).trim();
    const days = formValue.wait_time || 0;
    const endsOn = moment(formValue.applied_on).add(days, "days");

    let waitTime = {
      key: waitTimeRef.id,
      company_id: field.company_id,
      field_id: field.key,
      crop_id: field.activeCrop.key,
      applied_on: moment(formValue.applied_on).toDate(),
      ends_on: endsOn.toDate(),
      // We show the wait time for 10 days after it has ended, that's why keep it active
      active: !!moment().isBefore(moment(endsOn).add(10, "days")),
      template: template,
      wait_time: days,
      group_id: browsingGroup,
      created_by: UserObj(auth),
      favorite: formValue.favorite,
      created: fire.firestore.FieldValue.serverTimestamp(),
      notes: formValue.notes,
      sprayer: formValue.sprayer,
    };

    if (state.markedArea.polygon.length > 2) {
      waitTime = {
        ...waitTime,
        ...{
          markedArea: state.markedArea,
        },
      };
    }

    const comment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        field.activeCrop,
        auth,
        "system.wait_time",
        waitTimeRef.id,
      ),
      text: waitTime,
    };

    batch.set(waitTimeRef, waitTime);
    batch.set(commentRef, comment);
  };

  const createFavoriteWaitTime = (batch, formValue) => {
    const db = firebase.firestore();
    const favoriteRef = db.collection("favorites").doc();

    const days = formValue.wait_time || 0;

    const favorite = {
      name: String(formValue.template).trim(),
      type: "waittimes",
      replace: {
        template: formValue.template,
        wait_time: days,
        notes: formValue.notes,
        sprayer: formValue.sprayer,
      },
      group_id: browsingGroup,
      company_id: field.company_id,
      key: favoriteRef.id,
      created: fire.firestore.FieldValue.serverTimestamp(),
    };

    batch.set(favoriteRef, favorite);
  };

  const onChange = (value) => {
    setState({
      ...state,
      ...value,
    });
  };

  const container = (
    <div>
      {favorites ? (
        <div className={"d-flex justify-content-center flex-row"}>
          <Favorites
            favorites={favorites}
            onClick={(rowData) => {
              setState({
                ...state,
                template: rowData.replace.template,
                wait_time: rowData.replace.wait_time,
                notes: rowData.replace.notes,
                sprayer: rowData.replace.sprayer,
              });
            }}
            simpleContent={false}
            renderCustomRow={(rowData) => (
              <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
                <span style={{ ...constants.styles.stdSize, ...{ flex: 1 } }}>
                  {rowData.replace.template}
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Icon
                    iconType={"fj"}
                    name="hourglass_red"
                    style={{
                      ...{
                        fontSize: 14,
                        marginRight: 5,
                      },
                    }}
                  />
                  <span
                    style={{
                      ...constants.styles.stdSize,
                      ...{ textAlign: "right" },
                    }}
                  >
                    {I18n.t("x_days", { days: rowData.replace.wait_time })}
                  </span>
                </div>
              </div>
            )}
            getCustomRowText={(rowData) =>
              `${rowData.replace.template} ${I18n.t("x_days", {
                days: rowData.replace.wait_time,
              })}`
            }
          />
        </div>
      ) : null}

      <div className={"mt-5"}>
        <div style={{ marginTop: 0 }}>
          <Form
            ref={formRef as any}
            type={WaitModel.model}
            options={() =>
              WaitModel.options({
                ...state,
                fieldsSelector: () => {
                  return (
                    <PlantProtectionFieldsSelector
                      excludedField={field}
                      selectedFields={state.selectedFields}
                      onSave={(fields) => {
                        setState({
                          ...state,
                          ...{
                            selectedFields: fields,
                            showFieldsSelectorDialog: false,
                          },
                        });
                      }}
                      showDialog={state.showFieldsSelectorDialog}
                      onOpen={() => {
                        setState({ ...state, showFieldsSelectorDialog: true });
                      }}
                      onClose={() => {
                        setState({ ...state, showFieldsSelectorDialog: false });
                      }}
                      disabled={!!state.markedArea.polygon.length}
                    />
                  );
                },
                markAreaComponent: () => {
                  return (
                    <PlantProtectionMarkArea
                      markedArea={state.markedArea}
                      field={field}
                      showDialog={state.showMapAreaDialog}
                      onOpen={() => {
                        setState({ ...state, showMapAreaDialog: true });
                      }}
                      onClose={() => {
                        setState({ ...state, showMapAreaDialog: false });
                      }}
                      onSave={(value) => {
                        setState({
                          ...state,
                          ...{ markedArea: value, showMapAreaDialog: false },
                        } as any);
                      }}
                      disabled={
                        !!Object.keys(state.selectedFields).filter(
                          (k) => state.selectedFields[k],
                        ).length
                      }
                    />
                  );
                },
                sprayers,
              })
            }
            value={state}
            onChange={onChange}
          />
        </div>

        {editMode && canDo("waittime", "delete.own", myCompanyProfile) ? (
          <div className="text-center" style={{ marginTop: "1em" }}>
            <DeleteButton
              buttonText={I18n.t("waittime.delete")}
              alertTitle={I18n.t("waittime.delete")}
              alertMessage={I18n.t("waittime.doYouReallyWantToDelete")}
              onDelete={() => {
                const { comment, editWaitTime } = props;
                const { wait_time_id } = editWaitTime;

                const db = firebase.firestore();
                const batch = db.batch();
                const waitTimeRef = db
                  .collection("waittimes")
                  .doc(wait_time_id);
                const commentKey = db.collection("comments").doc(comment.key);

                batch.delete(waitTimeRef);
                batch.delete(commentKey);
                batch.commit();

                onClose();
              }}
            />
          </div>
        ) : null}
      </div>
      <AlertDialog
        show={!!state.alertMessage}
        onClose={() =>
          setState({ ...state, alertMessage: null, alertTitle: null } as any)
        }
        title={state.alertTitle}
      >
        {state.alertMessage}
      </AlertDialog>
    </div>
  );

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={"Pflanzenschutz"} // TODO: translation
      footer={
        <div className="d-flex flex-grow-1">
          <button className="btn btn-secondary ml-auto" onClick={onClose}>
            {I18n.t("cancel")}
          </button>{" "}
          <button className="btn btn-primary" onClick={onSave}>
            {I18n.t("save")}
          </button>
        </div>
      }
    >
      <div style={{ flex: 1 }}>
        <div
          style={{
            flex: 1,
          }}
        >
          {loading ? <Loading /> : container}
        </div>
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...waitTimeActions,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const editWaitTime = selectors.getEditWaitTime(state);

  const field = searchForFieldAmongTheUniverse(
    state,
    openCompany,
    user.uid,
    openField,
  );
  const favorites = getFavorites(state, openCompany, "waittimes");
  const waitTime = editWaitTime
    ? getWaitTime(state.firestore.data, (editWaitTime as any).wait_time_id)
    : null;
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  const queries: any[] = [];
  if (editWaitTime) {
    queries.push(getWaitTimeQuery((editWaitTime as any).wait_time_id));
  }

  queries.push(getFavoritesQuery(openCompany, "waittimes", browsingGroup));

  return {
    auth: state.firebase.profile,
    browsingGroup,
    comment: ownProps.comment ? ownProps.comment : null,
    editMode: !!editWaitTime,
    editWaitTime,
    favorites,
    field,
    loading: !hasLoaded(queries, state),
    myCompanyProfile,
    openCompany,
    waitTime,
  };
};

const wrappedWaitTime = firestoreConnect((props) => {
  const { openCompany, browsingGroup } = props;
  const queries: any[] = [];

  if (props.editWaitTime) {
    queries.push(getWaitTimeQuery(props.editWaitTime.wait_time_id));
  }

  queries.push(
    getFavoritesQuery(openCompany, "waittimes", browsingGroup),
  );

  return queries;
})(WaitTime);

export default compose<React.ComponentClass<any>>(
  connect(selector, mapDispatchToProps),
  withRouter,
)(wrappedWaitTime);

const useSprayers = (companyId: string) => {
  const [sprayers, setSprayers] = useState([]);

  useEffect(() => {
    let ignore = false;
    getUidToken()
      .then(uidToken => {
        return fetch(API_PLANT_PROTECTION, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${uidToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({companyId}),
        })
          .then((res) => res.json())
          .then((res) => {
            if (!ignore) {
              setSprayers(res);
            }
          });
      })
      .catch((e) => {
        console.error("Unable to fetch sprayers: ", e);
      });
    return () => {
      ignore = true;
    };
  }, [companyId]);

  return sprayers;
};
