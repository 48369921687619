import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { find, get } from "lodash-es";

import { getSortedFields } from "farmerjoe-common/lib/selectors/fields";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getColor } from "farmerjoe-common/lib/utils/Colors";
import type { Favorite } from "farmerjoe-common/lib/flow/types";

import Favorites from "../Common/Favorites";
import I18n from "../../language/i18n";

import "./style.css";

// TODO: improve typings
type TProps = {
  onClick: (...args: any) => any;
  activeCrops?: [Favorite];
};

const ActiveCropsDropDown = (props: TProps) => {
  const { onClick, activeCrops } = props;

  return (
    <Favorites
      className="active-crops-drop-down"
      headerText={I18n.t("crop.activeCrops")}
      headerIcon={"leaf"}
      iconType={"fj"}
      favorites={activeCrops as any}
      onClick={rowData => {
        onClick &&
            onClick({
              color: rowData.color,
              name: rowData.name,
              art: get(rowData, "fields.0.activeCrop.art") || "",
            });
      }}
      simpleContent={false}
      renderCustomRow={rowData => {
        return (
          <div
            className="crop-favorite-row"
            style={{
              backgroundColor: getColor(rowData.color),
            }}>
            <span>{rowData.name} - { rowData.art }</span>
          </div>
        );
      }}
      getCustomRowText={rowData => rowData.name}
      hasDeleteButton={false}
    />
  );
};

export default compose<typeof ActiveCropsDropDown>(
  connect((state: any) => {
    const openCompany = selectors.getOpenCompanyId(state);
    const sortedFields = getSortedFields(
      state,
      openCompany,
      // @ts-ignore
      state.firebase.auth.uid,
      {},
    );
    const activeFields = find(sortedFields.sections, o => o.title === "crop");
    let activeCrops = [];
    if (activeFields) {
      activeCrops = activeFields.data[0];
    }
    return {
      activeCrops,
    };
  }),
)(ActiveCropsDropDown);
