import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose , bindActionCreators } from "redux";
import { get } from "lodash-es";

import { getSubscriptionPlanDefinitions, getCompanySubscription } from "farmerjoe-common/lib/selectors/subscriptions";
import { getSubscriptionPlansQuery, getSubscriptionForCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Subscriptions";
import { updateSubscription, storeCompanySubscription } from "farmerjoe-common/lib/actions/subscriptions";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { getBillingDetailsForCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/BillingDetails";
import { getBillingDetailsForCompany } from "farmerjoe-common/lib/selectors/billingDetails";
import {
  editCompany,
} from "farmerjoe-common/lib/actions/company";

import { Loading } from "../Loading/Loading";

import I18n from "../../language/i18n";

import NoBillingDetails from "./NoBillingDetails";
import Billing from "./Billing";
import { TSubscription } from "./types";

type Props = {
  companyId: string;
  currentSubscriptionPlan: string;
  subscriptionPlans: any[];
  loading?: boolean;
  actions: any;
  currentSubscription: null | TSubscription;
  company?: any;
  billingDetails?: any;
};

const BillingPageContainer = (props: Props) => {
  const { loading, billingDetails } = props;

  if (!loading) {
    return  <Loading />;
  }

  return (
    <div style={{
      width: "100%",
      overflowY: "auto",
    }}>
      <div style= {{
        margin: "1em",
        fontSize: "32px",
        borderBottom: "1px solid grey",
      }}>
        {I18n.t("billing.billing")}
      </div>
      {billingDetails ? ( <Billing {...props} />) : (<NoBillingDetails {...props} />)}
    </div>
  );
};

const selector = (state, ownProps) => {
  const { companyId } = ownProps;
  const company = getCompany(state.firestore.data, companyId);
  const subscriptionPlans = getSubscriptionPlanDefinitions(state);
  const currentSubscription = getCompanySubscription(state, companyId);
  const currentSubscriptionPlan = get(currentSubscription, "planName", "2023_free");
  const billingDetails = getBillingDetailsForCompany(state, companyId);
  const queryPaths = [
    getSubscriptionPlansQuery(),
    getSubscriptionForCompanyQuery(companyId),
    getBillingDetailsForCompanyQuery(companyId),
  ];

  return {
    loading: hasLoaded(queryPaths, state),
    currentSubscriptionPlan,
    subscriptionPlans,
    companyId,
    currentSubscription,
    company,
    billingDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updateSubscription,
          editCompany,
          storeCompanySubscription,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedBilling = firestoreConnect(props => {
  const { companyId } = props;
  if (!companyId) {
    return [];
  }
  return [
    getSubscriptionPlansQuery(),
    getSubscriptionForCompanyQuery(companyId),
    getBillingDetailsForCompanyQuery(companyId),
  ];
})(BillingPageContainer);

export default compose(connect(selector, mapDispatchToProps))(wrappedBilling);
