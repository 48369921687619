import React from "react";
import Label from "./Label";
import LabelWithIcon from "./LabelWithIcon";
import ErrorMessage from "./ErrorMessage";

const styles = (disabled, previewInForm) => 
  disabled ? { pointerEvents: "none" as "none", opacity: 0.6 } 
    : previewInForm ? {marginBottom: 20 } 
      : { marginBottom: 0 };


const EXCLUDED_FIELD_TYPES = [
  "text",
  "number",
  "fj-conditional-field",
  "image-with-info",
  "fj-slider",
  "fj-math",
  "fj-math-hectare",

  "fj-amount", // NOTE: not needed for new boniturs but required for backward compatibility/existing companies with 'fj-amount' enabled.
  "fj-boolean",
  "fj-bonitur-age",
];

const FieldTemplate = props => {
  const {
    disabled = false,
    name,
    label,
    labelClass = "",
    formGroupClass = "form-group",
    children,
    fieldType,
    options = {},
  } = props;

  const {previewInForm = true } = options;

  return (
    <div className={formGroupClass} style={styles(disabled ,previewInForm)}>
      {renderLabelIfNeeded(fieldType)}
      {children}
      <ErrorMessage name={name} />
    </div>
  );

  function renderLabelIfNeeded(fieldType) {
    if (!label) return null;

    if (fieldType === "image-with-info") {
      return (
        <LabelWithIcon
          htmlFor={name}
          className={labelClass}
          infoUrl={options.infoImageUrl}>
          {label}
        </LabelWithIcon>
      );
    } else if (!EXCLUDED_FIELD_TYPES.includes(fieldType)) {
      return (
        <Label htmlFor={name} className={labelClass}>
          {label}
          {labelClass === "label--colon" ? ":" : null}
        </Label>
      );
    }
    return null;
  }
};

export default FieldTemplate;
