import * as React from 'react';
import * as constants from '../../styles/style';
import { COMPANY_MAIN_GROUP_KEY } from 'farmerjoe-common/lib/selectors/groups';
import Icon from '../Common/Icon';

type Props = {
  name: string;
  groupId?: string;
};

const { styles } = constants;

export default function Name({ name, groupId }: Props) {
  return (
    <React.Fragment>
      <span
        style={{
          ...styles.muted,
          ...styles.extraSmall,
          ...{ color: constants.FJMUTEDLIGHT },
        }}>
        {name}
      </span>
      {groupId === COMPANY_MAIN_GROUP_KEY && (
        <Icon
          iconType={'fal'}
          name={'industry-alt'}
          className={'light'}
          style={{
            marginLeft: 5,
            ...styles.muted,
            ...styles.extraSmall,
            ...{ color: constants.FJMUTEDLIGHT },
          }}
        />
      )}
    </React.Fragment>
  );
}
