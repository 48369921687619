import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import t from "tcomb-form";
import { isEqual } from "lodash-es";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";

import InvoiceModel from "../../tcomb/models/analysis/invoice-address";
import I18n from "../../language/i18n";

import { AlertDialog } from "../Dialog/Dialog";

const Form = t.form.Form;

// TODO: improve typings
type Props = {
  company?: any;
  onSave: (params: any) => any;
  invoice_address?: any;
  firebase?: any;
  componentRef?: any;
  labSelected?: any;
};
type State = any;

class InvoiceAddress extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      invoice_address: {
        ...this.props.invoice_address,
      },
      alertMessage: null,
      alertTitle: null,
    };

    if (props.componentRef) {
      props.componentRef(this);
    }
  }

  componentWillUpdate(nextProps) {
    if (!isEqual(this.props.invoice_address, nextProps.invoice_address)) {
      this.setState({
        invoice_address: {
          ...this.props.invoice_address,
        },
      });
    }
  }

  /**
   * Handle saving when the user clicks the "save" button
   */
  onSave() {
    const value = (this.refs.form as any).getValue();

    const { firebase, company } = this.props;
    const db = firebase.firestore();

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    let key;
    if (this.state.invoice_address.key) {
      key = this.state.invoice_address.key;
    } else {
      const favRef = db.collection("favorites").doc();
      key = favRef.id;
    }

    const address = {
      ...this.state.invoice_address,
      ...value,
      key: key,
      type: "invoiceAddress",
      created: firebase.firestore.FieldValue.serverTimestamp(),
      company_id: company.key,
    };

    Object.keys(address).forEach(
      key => address[key] === undefined && delete address[key],
    );

    db.collection("favorites")
      .doc(key)
      .set(address, { merge: true });

    this.props.onSave(address);
  }

  onChange(value) {
    this.setState({
      invoice_address: {
        ...this.state.form,
        ...value,
      },
    });
  }

  render() {
    const isBolap = this.props.labSelected && ["lab1", "farmerJoeTestLab"].includes(this.props.labSelected.id);
    return (
      <div>
        <div style={{ marginTop: 20 }}>
          <Form
            ref="form"
            type={isBolap ? InvoiceModel.modelBolap : InvoiceModel.model}
            options={() => InvoiceModel.options(isBolap)}
            value={this.state.invoice_address}
            onChange={this.onChange.bind(this)}
          />
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
        >
          {this.state.alertMessage}
        </AlertDialog>
      </div>
    );
  }
}

const wrappedInvoiceAddress = firestoreConnect(() => {
  return [];
})(InvoiceAddress);

const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  return {
    company,
  };
};

type InvoiceAddressExternalProps = {
  onSave: (params: any) => any;
  invoice_address: any;
  componentRef: any;
  labSelected?: any;
};

export default (connect(
  selector as any,
)(wrappedInvoiceAddress) as any as (React.ComponentClass<InvoiceAddressExternalProps, any>));
