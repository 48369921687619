import React, { Component } from "react";
import PropTypes from "prop-types";

import { getCompany } from "farmerjoe-common/lib/selectors/companies";

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";

import { addKeyToObj } from "../../data/util";

import * as labActions from "farmerjoe-common/lib/actions/labs";

import { isLoaded, firestoreConnect } from "react-redux-firebase";
import { getWarehouses } from "farmerjoe-common/lib/selectors/warehouses";
import WarehouseModel from "../../tcomb/models/warehouse";
import I18n from "../../language/i18n";
import Dialog, { AlertDialog } from "../Dialog/Dialog";
import { numberTransformer } from "../../tcomb/transformers/transformers";
import { appPosToLatLng, isValidPosition } from "../../utils/Map";
import ModalMap from "../Map/ModalMap";
import PinPositionMarker from "../Map/PinPositionMarker";
import PolygonAndMarkerPlacementMapDialog from "../Map/PolygonAndMarkerPlacementMapDialog";
import withGeocoderFilledProps from "farmerjoe-common/lib/tcomb/formAddons/withGeocoderFilledProps";
import t from "tcomb-form";
import { Marker as MarkerType } from "../../flowTypes";

// TODO: improve typings
type Props = any;
type State = any;

class FormSelectWarehouse extends Component<Props, State> {
  static propTypes = {
    company: PropTypes.object,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      warehouse: {
        company_id: this.props.company.key,
        name: "",
        position: {
          latitude: null,
          longitude: null,
        },
        state: 1,
      },
      alertMessage: null,
      alertTitle: null,
      modalVisible: false,
    };
  }

  /**
   * From now on use the refresh action to modify the NavBar props
   */
  componentDidMount() {
    // Actions.refresh({
    //   rightTitle: I18n.t('done'),
    //   onRight:    () => this.save()
    // })
  }

  async save() {
    const { firebase } = this.props;
    let warehouse = (this.refs.form as any).getValue();
    // const {actions, company, field, firebase} = this.props

    // If value is null, then the form has errors
    if (warehouse === null) {
      this.setState({
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    // getValue returns Struct custom objects which firestore doesn't like so turn them into plain objects
    warehouse = JSON.parse(JSON.stringify(warehouse));

    const db = firebase.firestore();

    const warehouseRef = db.collection("warehouses").doc();

    warehouse = {
      ...warehouse,
      key: warehouseRef.id,
    };
    await warehouseRef.set(warehouse);

    this.props.onSave(warehouse);
  }

  onChange(type, value) {
    this.setState({
      ...this.state,
      [type]: value,
    });
  }

  onPolygonChange({ polygon, areaSize, center }) {
    this.setState({
      warehouse: {
        ...this.state.warehouse,
        position: center,
      },
    });
  }

  render() {
    const { show, onClose } = this.props;
    return (
      <Dialog
        show={show}
        title={I18n.t("warehouse.add")}
        onClose={onClose}
        footer={
          <div className="d-flex flex-grow-1">
            <button className="ml-auto btn btn-secondary" onClick={onClose}>
              {I18n.t("back")}
            </button>{" "}
            <button className="btn btn-primary" onClick={() => this.save()}>
              {I18n.t("done")}
            </button>
          </div>
        }>
        <AddWarehouseForm
          ref="form"
          type={WarehouseModel.model}
          options={() =>
            WarehouseModel.options({
              company: this.props.company,
              warehouse: this.state.warehouse,
              // markers: this.props.markers
              PolygonComponent: this.renderPolygonComponent(),
            })
          }
          value={this.state.warehouse}
          onChange={this.onChange.bind(this, "warehouse")}
          geocoderAutoFilledProps={["position", "country"]}
        />
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
        />
      </Dialog>
    );
  }

  renderPolygonComponent() {
    // position can have strings or numbers inside so make sure we have the number version here
    const position: any = this.state.warehouse.position
      ? {
        latitude: numberTransformer.parse(
          String(this.state.warehouse.position.latitude),
        ),
        longitude: numberTransformer.parse(
          String(this.state.warehouse.position.longitude),
        ),
      }
      : null;
    const validPosition = isValidPosition(position);
    const company = this.props.company;
    const markers: MarkerType[] = [
      {
        key: company.key,
        title: company.name,
        position: company.position,
        type: "company",
      },
    ];

    return [
      <div
        onClick={() => this.setState({ modalVisible: true })}
        key="preview-map">
        <ModalMap
          markers={markers}
          position={validPosition ? position : null}
          zoom={16}
          hideControls={true}
          containerStyle={{ height: "180px" }}
          fitBounds={!validPosition}
          noTabIndex={true}
          options={{
            disableDefaultUI: true,
            gestureHandling: "none",
            zoomControl: false,
          }}>
          {validPosition
            ? (
              <PinPositionMarker position={appPosToLatLng(position)} />
            )
            : null}
        </ModalMap>
      </div>,
      <PolygonAndMarkerPlacementMapDialog
        key="polygonandmarkerplacement"
        markers={markers}
        position={validPosition ? position : null}
        title={I18n.t("warehouse.position_it")}
        label={`${I18n.t("warehouse.position_it")} *`}
        onPolygonChange={this.onPolygonChange.bind(this)}
        fitBounds={!validPosition}
        noTabIndex={true}
        modalVisible={this.state.modalVisible}
        onOpen={() => this.setState({ modalVisible: true })}
        onClose={() => this.setState({ modalVisible: false })}
      />,
    ];
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);

  const warehouses = getWarehouses(state, openCompany);
  return {
    company: addKeyToObj(openCompany, company),
    warehouses: warehouses,
    loaded: isLoaded(warehouses),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...labActions,
        },
      ),
      dispatch,
    ),
  };
}

const wrappedFormSelectWarehouse = firestoreConnect(props => {
  const companyId = props.company.key;

  if (companyId) {
    return [
      {
        collection: "warehouses",
        where: [["company_id", "==", companyId]],
        byIds: `warehouses/${companyId}`,
      },
    ];
  }

  return [];
})(FormSelectWarehouse);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFormSelectWarehouse);

const AddWarehouseForm = compose<typeof t.form.Form>(withGeocoderFilledProps)(t.form.Form) as any;
