import * as React from 'react';
import I18n from '../../language/i18n';
import Dialog from '../Dialog/Dialog';
import 'firebase/auth';
import Help from '../Help/Help';

export default class ShortHelp extends React.PureComponent<
  {},
  { showHelp: boolean, chatClosed: boolean }
> {
  state = {
    showHelp: false,
    chatClosed: true,
  };

  render() {
    return (
      <div>
        <p className={'mt-5 mb-5 small text-secondary'}>
          ©2015–{new Date().getFullYear()} All Rights Reserved. farmerJoe® is a registered trademark of farmsupport GmbH.{' '}
          <a
            href="https://farmerjoe.com/cookies.html"
            className="text-secondary"
            target={'blank'}>
            <u>Cookies</u>
          </a>
          ,{' '}
          <a
            href="https://farmerjoe.com/datenschutz.html"
            className="text-secondary"
            target={'blank'}>
            <u>Privacy</u>
          </a>
          ,{' '}
          <a
            href="https://farmerjoe.com/agb.html"
            className="text-secondary"
            target={'blank'}>
            <u>Terms of service</u>
          </a>
          {' '}
          and{' '}
          <a
            href="https://farmerjoe.com/impressum.html"
            className="text-secondary"
            target={'blank'}>
            <u>Impressum</u>
          </a>
          .
        </p>
        <p className={'text-center small'}>
          <Help />
        </p>
      </div>
    );
  }
}
