import React from 'react';
import TextInput from './TextInput';

export default function textbox(locals) {
  if (locals.hidden) {
    return null;
  }

  return (
    <TextInput
      {...locals}
      ariaLabel={locals.label}
      onChange={value => locals.onChange(value)}
    />
  );
}
