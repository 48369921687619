import React, { useState } from "react";

import Dialog from "../Dialog/Dialog";
import LineWithArrow from "../Common/LineWithArrow";
import SummaryLine from "../Common/SummaryLine";
import FieldsSelector from "../Common/FieldsSelector";
import I18n from "../../language/i18n";

type TFieldsSelectorProps = {
  showDialog: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSave: (value: any) => void;
  selectedFields: any;
  disabled: boolean;
  excludedField? : any;
};

const SHOW_SEARCH_BAR = true;

const PlantProtectionFieldsSelector = ({
  onOpen,
  showDialog,
  onClose,
  onSave,
  selectedFields,
  disabled,
  excludedField,
}: TFieldsSelectorProps) => {
  const [change, setChange] = useState(selectedFields);

  const selectedFieldsCount = Object.keys(selectedFields).length;

  return (
    <div>
      <LineWithArrow
        onClick={() => {
          if (disabled) {
            return;
          }
          onOpen();
        }}
        text={I18n.t("waittime.applyOverMultipeFields")}
        disabled={disabled}
      />
      {selectedFieldsCount > 0 ? (
        <SummaryLine
          onClear={() => {
            setChange({});
            onSave({});
          }}
          text={I18n.t("waittime.appliedAcrossFieldsSummary", { count: selectedFieldsCount })} />
      ) : null}
      <Dialog
        show={showDialog}
        title={
          <div className="header">
            <span>{I18n.t("waittime.applyOverMultipeFields")}</span>
          </div>
        }
        onClose={onClose}
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              onClick={onClose}
            >
              {I18n.t("cancel")}
            </button>{" "}
            <button
              className="btn btn-primary"
              onClick={() => onSave(change)}
              disabled={false}>
              {I18n.t("done")}
            </button>
          </div>
        }>
        <div>
          <FieldsSelector
            excludedField={excludedField}
            selected={change}
            onSelectField={(item) => {
              if (change[item.key]) {
                delete change[item.key];
                const newFields = {...change};
                setChange(newFields);
                return;
              }
              setChange({
                ...change,
                ...{[item.key]: item},
              });
            }}
            showSearchBar={SHOW_SEARCH_BAR}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default PlantProtectionFieldsSelector;
