import { AnalysisType } from 'farmerjoe-common/lib/flow/types';
import { get } from 'lodash-es';
import I18n from '../../language/i18n';

type Prices = {
  meta: {
    currency: string;
    vat: number;
  };
  fields: Record<string, string>;
};

export const getTranslatedValue = (
  value: string,
  config: Record<string, any>,
) => {
  const locale = I18n.locale;

  return get(
    config,
    `translations.${locale}.${value}`,
    get(config, `translations.${config.defaultLanguage}.${value}`, null),
  );
};

export const calculatePrice = (
  form: Record<string, string>,
  prices: Prices,
) => {
  if (!prices) {
    return {
      netPrice: 0,
      grossPrice: 0,
      vat: 0,
      vatSum: 0,
      currency: '',
    };
  }

  const netPrice = Object.keys(prices.fields).reduce((prev, current) => {
    let serviceCost = 0;

    const priceValue = get(form, current.split('.'));
    // if this type of service set on the form get the price
    if (priceValue) {
      if (Array.isArray(priceValue)) {
        serviceCost = priceValue
          .map(selected => {
            return get(prices.fields, [current, selected], 0);
          })
          .reduce((p, c) => {
            return p + c;
          }, 0);
      } else {
        serviceCost = get(prices, `${current}`, 0);
      }
    }

    return prev + serviceCost;
  }, 0);

  const vatPercent = prices.meta.vat;
  const multiplier = vatPercent / 100 + 1;
  const gross = netPrice * multiplier;
  const vatSum = (vatPercent / 100) * netPrice;

  return {
    netPrice: netPrice,
    grossPrice: gross,
    vat: prices.meta.vat,
    vatSum: vatSum,
    currency: prices.meta.currency,
  };
};

export function getOrderAnalysisTranslation(analysisType: AnalysisType) {
  switch (analysisType) {
    case AnalysisType.Soil:
      return I18n.t('analysis.orderSoil');
    case AnalysisType.Residue:
      return I18n.t('analysis.orderResidue');
    case AnalysisType.Drone:
      return I18n.t('analysis.orderDrone');
    default:
      return 'Unknown';
  }
}

export function getAnalysisTypeShortTranslation(analysisType: AnalysisType) {
  if ([AnalysisType.Soil, AnalysisType.Residue, AnalysisType.Drone].includes(analysisType)) {
    return I18n.t(`analysis.${analysisType}`);
  }
  return 'Unknown';
}
