import React, { useState } from "react";
import Modal from "react-bootstrap-modal";

import * as constants from "../../styles/style";
import "./style.css";
import I18n from "../../language/i18n";
import NoResults from "./NoResults";

const ButtonWithDialog = (props) => {
  const { style, buttonLabel, onChange, options, modalHeader, modalNoResultsText } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 0.5em",
          borderWidth: "1px",
          borderColor: "rgb(126, 181, 73)",
          borderRadius: "5px",
          backgroundColor: "rgb(126, 181, 73)",
          cursor: "pointer",
          ...style,
        }}

        onClick={() => setShowModal(true)}
      >
        <span
          style={{
            color: constants.FJWHITE,
            padding: "6px 3px 3px",
          }}
        >
          {buttonLabel}
        </span>
      </div>
      {showModal ? (
        <OptionsModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onChange={onChange}
          options={options}
          modalHeader={modalHeader}
          modalNoResultsText={modalNoResultsText}
        />
      ) : null}
    </div>
  );
};

export default ButtonWithDialog;


const OptionsModal = (props) => {
  const { show, onClose, onChange, options, modalHeader, modalNoResultsText } = props;
  return (
    <Modal
      show={show}
      dialogClassName="update-payment-modal"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={(e) => e.stopPropagation()}>
          {modalHeader}
        </Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        <div>
          {!options.length ? (
            <NoResults text={modalNoResultsText} />
          ) : (
            OptionsList({options, onChange, onClose})
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const OptionsList = (props) => {
  const {options, onChange, onClose} = props;
  const renderItem = (item, idx) => {
    return (
      <div
        className={"clickable-option"}
        key={idx}
        onClick={() => {
          onChange(item);
          onClose();
        }}>
        {item}
      </div>
    );
  };

  const renderItems = (items) => {
    return items.map(renderItem);
  };

  return (
    <div className='modal-body-content'>
      {renderItems(options)}
    </div>
  );
};
