import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { throttle } from 'lodash-es';

import { getCompanyUser } from 'farmerjoe-common/lib/selectors/user';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getUserQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Users';

import MarkerWithLabel from './MarkerWithLabel';
import EmployeeInfoBalloon from './EmployeeInfoBalloon';
import { appPosToLatLng } from '../../utils/Map';

const DEFAULT_WIDTH = 35;
const DEFAULT_HEIGHT = 35;

// TODO: improve typings
type Props = {
  marker: any;
  icon?: string;
  onClick: (...args: Array<any>) => any;
  map: google.maps.Map;
  user?: any; // the user object from the state;
  openCompany?: string;
  browsingGroup?: string;
  userId?: string;
};

type State = any;

class MarkerWorker extends PureComponent<Props, State> {
  state = {
    showBalloon: false,
    balloonIsClosing: false,
    balloonIsOpening: false,
  };

  mounted = false;
  div = React.createRef<HTMLDivElement>();
  balloonIsClosingTimeout?: NodeJS.Timeout;
  balloonIsOpeningTimeout?: NodeJS.Timeout;


  componentWillUpdate(nextProps: Props, nextState: State) {
    if (this.state.showBalloon !== nextState.showBalloon) {
      if (this.balloonIsClosingTimeout) clearTimeout(this.balloonIsClosingTimeout);

      if (!nextState.showBalloon) {
        this.changeState({
          balloonIsClosing: 'showBalloon',
          balloonIsOpening: false,
        });
        this.balloonIsClosingTimeout = setTimeout(() => {
          if (this.mounted) {
            this.changeState({
              balloonIsClosing: false,
              balloonIsOpening: false,
            });
          }
        }, 300);
      }
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevState.balloonIsOpening && this.state.balloonIsOpening) {
      if (this.balloonIsOpeningTimeout) clearTimeout(this.balloonIsOpeningTimeout);
      this.balloonIsOpeningTimeout = setTimeout(() => {
        if (this.mounted) {
          this.changeState({
            showBalloon: true,
            balloonIsOpening: false,
          });
        }
      }, 0);
    } else if (prevState.balloonIsOpening && !this.state.balloonIsOpening) {
      if (this.balloonIsOpeningTimeout) clearTimeout(this.balloonIsOpeningTimeout);
    }
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
    if (document.body) {
      document.body.removeEventListener('mousemove', this.onMouseMove);
    }
  }

  changeState = (state: State) => {
    this.setState(state);
  };

  onMouseLeave = () => {
    const { showBalloon } = this.state;
    if (showBalloon !== false || this.state.balloonIsOpening) {
      this.changeState({ showBalloon: false, balloonIsOpening: false });
      if (document.body) {
        document.body.removeEventListener('mousemove', this.onMouseMove);
      }
    }
  };

  onMouseEnter = () => {
    if (this.state.showBalloon !== true || !this.state.balloonIsOpening) {
      this.changeState({ balloonIsOpening: true });

      if (this.div.current && document.body) {
        document.body.addEventListener('mousemove', this.onMouseMove);
      }
    }
  };

  /**
   * this function is a workaround for firefox not calling onMouseLeave in some cases
   */
  onMouseMove = throttle(({ pageX, pageY }) => {
    if (this.state.showBalloon && this.div.current) {
      const boundingBox = this.div.current.getBoundingClientRect();
      if (
        pageY < boundingBox.top ||
        pageY > boundingBox.bottom ||
        pageX < boundingBox.left ||
        pageX > boundingBox.right
      ) {
        this.onMouseLeave();
      }
    }
  }, 500);


  render() {
    const { marker, onClick, icon, map, user } = this.props;
    const emoji = icon && icon.split(':')[1];
    const renderHint =
      this.state.showBalloon || this.state.balloonIsClosing || this.state.balloonIsOpening;

    return (
      <MarkerWithLabel
        position={appPosToLatLng(marker.coords) as any}
        noTriangle={true}
        onClick={onClick}
        zIndex={9999}
        width={DEFAULT_WIDTH}
        height={DEFAULT_HEIGHT}
        labelClass="marker-label worker-marker"
        map={map}
      >
        <div
          className="marker-container"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          ref={this.div}>
          <div className="marker-bubble">
            <span style={{ fontSize: 22 }}>{emoji}</span>
          </div>
          {renderHint ? (<EmployeeInfoBalloon user={user} />) : null}
        </div>
      </MarkerWithLabel>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const userId = ownProps.marker?.uid;
  const user = getCompanyUser(state, userId, openCompany);
  return {
    openCompany,
    browsingGroup,
    userId,
    user,
  };
};

const wrappedWorker = firestoreConnect(props => {
  const { openCompany, browsingGroup, userId } = props;
  return [
    getUserQuery(openCompany, userId, browsingGroup),
  ];
})(MarkerWorker);

export default compose<typeof MarkerWorker>(
  connect(
    selector,
  ),
)(wrappedWorker);
