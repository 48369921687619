import * as React from 'react';
import type { Notification } from 'farmerjoe-common/lib/flow/types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import './style.css';
import NotificationCard from './NotificationCard';
import { openField } from 'farmerjoe-common/lib/actions/field';
import I18n from '../../../language/i18n';

type Props = {
  notification: Notification;
  history?: Record<string, any>;
  openCompany?: string;
  actions?: Record<string, any>;
  onClick?: (arg0: Notification) => void;
};

function WaitTime({
  notification,
  history,
  openCompany,
  actions,
  onClick,
}: Props) {
  const { field_id, fieldName, cropName, pesticideName } = notification;
  return (
    <NotificationCard
      onClick={() => {
        actions?.openField(field_id);
        history?.push(`/company/${openCompany}/field/${field_id}`);
        if (onClick) {
          onClick(notification);
        }
      }}
      title={I18n.t('notifications.waittime.title')}
      body={I18n.t('notifications.waittime.body', {
        field_name: fieldName,
        crop_name: cropName,
        pesticide_name: pesticideName,
      })}
      notification={notification}
      userType="system"
    />
  );
}

export default compose<typeof WaitTime>(
  withRouter,
  connect(
    (state: any) => {
      return {
        openCompany: getOpenCompanyId(state),
      };
    },
    dispatch => ({ actions: bindActionCreators({ openField }, dispatch) }),
  ),
)(WaitTime);
