import * as React from 'react';
import { min } from 'lodash-es';
import MarkerWithLabel from './MarkerWithLabel';
import { getDistanceFromLatitudeLongitudeInM } from '../../utils/Map';
import type { LatLng } from '../../flowTypes';

type Props = {
  map: google.maps.Map;
  start: LatLng;
  end: LatLng;
};

export default function DistanceMarker({ start, end, map }: Props) {
  const startLatitude = min([start.latitude, end.latitude]);
  const startLongitude = min([start.longitude, end.longitude]);

  const labelPosition = {
    lat: startLatitude + Math.abs(start.latitude - end.latitude) / 2,
    lng: startLongitude + Math.abs(start.longitude - end.longitude) / 2,
  };
  return (
    <MarkerWithLabel
      position={labelPosition}
      noTriangle={true}
      width="auto"
      labelStyle={{ transform: 'translateX(-50%)' }}
      noClustering={true}
      layer={'markerLayer'}
      map={map}
    >
      <div
        style={{
          whiteSpace: 'nowrap',
          backgroundColor: 'rgba(0,0,0,0.8)',
          borderRadius: 5,
          padding: 3,
          color: 'white',
        }}>
        {getDistanceFromLatitudeLongitudeInM(start, end)}
        {' m'}
      </div>
    </MarkerWithLabel>
  );
}
