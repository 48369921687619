import { ParsedNumber, parseNumber } from "libphonenumber-js";
import {
  getCountries as getCountryUtil,
} from "../language/i18n";

/**
 * Return a list of countries with name translated eventually into a language
 * that we support
 *
 * @returns {name: string, key: string, cca2: string, callCode: number}[]
 */
export const getCountries = () => getCountryUtil().getCountries();
export const getCountriesTel = () => getCountryUtil().getCountriesTel();
export const findCountryByCCA2 = (cca2: string) =>
  getCountryUtil().findCountryByCCA2(cca2);
export const findCountryByName = (name: string) =>
  getCountryUtil().findCountryByName(name);
export const getCountryName = (name: string | null | undefined) =>
  getCountryUtil().getCountryName(name);

export function detectCountry(
  feature?: "tel" | "name",
  value?: string | null | undefined,
) {
  if (feature) {
    let country: any = null;

    if (feature === "tel" && value) {
      const parsed = parseNumber(value) as ParsedNumber;
      if (parsed.country) {
        country = findCountryByCCA2(parsed.country);
      }
    } else if (feature === "name" && value) {
      country = findCountryByName(value);
    }

    if (country) {
      return country;
    }
  }

  return null;
}
