import { fetch as _fetch } from 'whatwg-fetch';

const originalFetch = _fetch || window.fetch;

type ErrorWithResponse = Error & {
  response?: Record<string, any>;
};

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error: ErrorWithResponse = new Error(
      response.statusText || String(response.status),
    );
    error.response = response;
    throw error;
  }
}

export default function fetch(...args: any[]): Promise<Response> {
  return originalFetch(...args).then(checkStatus);
}

export const fetchJson = (url, options: any = {}) =>
  fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: options.body ? JSON.stringify(options.body) : options.body,
  })
    .then(checkStatus)
    .then(toJson);

const toJson = res => res.json();
