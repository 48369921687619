import * as React from 'react';
import * as constants from '../../styles/style';

import Avatar from '../../containers/Avatar';
import withRouter from '../Router/withRouter';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import Profile from '../../containers/Profile/index';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { get } from 'lodash-es';
import Name from './Name';

type Props = {
  uid: string;
  name: string;
  history?: Record<string, any>;
  openCompany?: string;
  myCompanyProfile?: Record<string, any>;
  groupId: string;
  producersOn?: boolean;
};

type State = {
  showProfile: boolean;
};

/**
 * Display the user's avatar and name on a single line
 */
class AvatarName extends React.PureComponent<Props, State> {
  state = {
    showProfile: false,
  };

  render() {
    const { uid, name, myCompanyProfile, groupId } = this.props;
    const { styles } = constants;
    const canViewUsers =
      myCompanyProfile && get(myCompanyProfile, 'views.users', true);

    return [
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          cursor: canViewUsers ? 'pointer' : 'default',
          alignItems: 'center',
        }}
        onClick={
          canViewUsers ? () => this.setState({ showProfile: true }) : (void 0)
        }
        key={0}>
        <div style={{ marginRight: 5 }}>
          <Avatar
            uid={uid}
            round={true}
            style={{ backgroundColor: '#EEE', lineHeight: 0 }}
          />
        </div>
        <Name name={name} groupId={groupId} />
      </div>,
      this.state.showProfile
        ? (
        <Profile
          uid={uid}
          groupId={groupId}
          showInDialog={true}
          show={this.state.showProfile}
          onClose={() => {
            this.setState({ showProfile: false });
          }}
          key={1}
        />
          )
        : null,
    ];
  }
}

export default compose<typeof AvatarName>(
  connect((state: any) => {
    const openCompany = selectors.getOpenCompanyId(state);
    const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
      state,
      openCompany,
    );
    return {
      openCompany,
      myCompanyProfile,
    };
  }),
  withRouter,
)(AvatarName);
