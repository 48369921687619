import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { cloneDeep } from "lodash-es";

import { updatePosition } from "farmerjoe-common/lib/actions/position";

import Icon from "../Common/Icon";
import ModalInsertMap from "../Map/ModalInsertMap";
import Dialog from "../Dialog/Dialog";
import { classes } from "../../utils/dom";
import { getCurrentPosition } from "../../utils/geolocation";
import type { LatLng, Marker } from "../../flowTypes";
import { isValidPosition } from "../../utils/Map";
import I18n from "../../language/i18n";

type Props = {
  userPosition?: LatLng | null;
  position?: LatLng | null;
  polygon?: Array<LatLng> | null;
  actions?: Record<string, any>;
  onPolygonChange: (arg0: any) => void;
  markerId?: string;
  markers?: Array<Marker | any>;
  displayMarkers?: boolean;
  polygonDrawing?: boolean;
  label: string;
  hasError?: boolean;
  title: string;
  fitBounds?: boolean | any;
  modalVisible?: boolean;
  onOpen: () => void;
  onClose: (arg0: string) => void;
  noTabIndex?: boolean;
};

type State = {
  center?: LatLng | null;
  polygon?: Array<LatLng> | null;
  areaSize?: Record<string, any> | null;
};

class PolygonAndMarkerPlacementMapDialog extends React.PureComponent<
  Props,
  State
> {
  state = {
    center: cloneDeep(this.props.position),
    polygon: cloneDeep(this.props.polygon),
    areaSize: null,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.modalVisible &&
      this.props.modalVisible !== prevProps.modalVisible
    ) {
      this.getPosition();
    }
  }

  componentWillUpdate(nextProps) {
    if (
      this.props.position !== nextProps.position ||
      this.props.polygon !== nextProps.polygon ||
      (!this.props.modalVisible && nextProps.modalVisible)
    ) {
      this.setState({
        center: cloneDeep(nextProps.position),
        polygon: cloneDeep(nextProps.polygon),
      });
    }
  }

  render() {
    const {
      markers,
      displayMarkers,
      markerId,
      polygonDrawing,
      label,
      hasError,
      title,
      fitBounds,
      noTabIndex,
      modalVisible,
      onOpen,
    } = this.props;

    const { polygon, center: position } = this.state;
    return (
      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 10,
            }}>
            <button
              className={classes(
                "btn btn-secondary",
                hasError && "invalid-label",
              )}
              onClick={onOpen}
              tabIndex={noTabIndex ? -1 : void 0}>
              <Icon
                name={"md-pin"}
                style={{
                  paddingRight: 5,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: 16,
                }}
              />
              <span>{label}</span>
            </button>
          </div>
        </div>
        {modalVisible
          ? (
            <Dialog
              show={modalVisible}
              title={title}
              onClose={this.cancel}
              className="modal-xl position-on-map-with-nav-map"
              footer={
                <div className="d-flex flex-grow-1">
                  <button
                    className="ml-auto btn btn-secondary"
                    onClick={this.cancel}>
                    {I18n.t("cancel")}
                  </button>{" "}
                  <button
                    className="btn btn-primary"
                    onClick={this.save}
                    disabled={
                      !(
                        isValidPosition(position) &&
                      (!polygon || polygon.length !== 2)
                      )
                    }>
                    {I18n.t("done")}
                  </button>
                </div>
              }>
              <div style={{ backgroundColor: "#000" }}>
                <ModalInsertMap
                  displayMarkers={displayMarkers}
                  markers={markers || []}
                  pinPosition={
                    isValidPosition(position)
                      ? {
                        id: markerId || null,
                        position,
                      }
                      : null
                  }
                  onChange={({ center, polygon, areaSize }) =>
                    this.setState({ center, polygon, areaSize })
                  }
                  polygonDrawing={polygonDrawing}
                  polygon={polygon}
                  fitBounds={fitBounds}
                />
              </div>
            </Dialog>
          )
          : null}
      </div>
    );
  }

  cancel = () => {
    this.props.onClose("cancel");
  };

  save = () => {
    this.props.onPolygonChange({
      center: this.state.center,
      polygon: this.state.polygon,
      areaSize: this.state.areaSize,
    });
    this.props.onClose("done");
  };

  /**
   * Get the user's position and update the region state if necessary
   */
  getPosition() {
    if (this.props.userPosition && !this.props.position) {
      getCurrentPosition(
        position => {
          this.props.actions?.updatePosition(position.coords);
        },
        error => console.log(error),
        { enableHighAccuracy: false, timeout: 20000, maximumAge: 1000 },
      );
    }
  }
}

export default compose<typeof PolygonAndMarkerPlacementMapDialog>(
  connect(
    (state, ownProps) => {
      return {
        userPosition: (state as any).userPosition,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          updatePosition,
        },
        dispatch,
      ),
    }),
  ),
)(PolygonAndMarkerPlacementMapDialog);
