import React, { PureComponent } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as fieldActions from 'farmerjoe-common/lib/actions/field';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import I18n from '../../language/i18n';
import SegmentedControl from '../../tcomb/templates/SegmentedControl';
import { get } from 'lodash-es';

// TODO: improve typings
type Props = any;
type State = any;

class MapFilter extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    const {
      filter: { showCrops },
    } = props;
    this.state = {
      showCrops: showCrops || [0, 1, 2],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const showCrops = get(this.props.filter, 'showCrops');
    if (showCrops && showCrops !== prevState.showCrops) {
      this.setState({ showCrops });
    }
  }

  render() {
    return (
      <SegmentedControl
        className="map-filter"
        options={[
          { text: I18n.t('crop.without'), value: 1 },
          { text: I18n.t('crop.planned'), value: 2 },
          { text: I18n.t('crop.active'), value: 0 },
        ]}
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        optionContainerStyle={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px 10px',
          flexDirection: 'column',
          height: 45,
        }}
        onChange={showCrops => {
          this.setState({ showCrops });

          this.props.actions.filters(this.props.openCompany, {
            showCrops,
          });
        }}
        value={this.state.showCrops}
        config={{ order: false, multiSelect: true }}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
}

const defaultFilter = {
  markerTitle: 'ha',
  showCrops: [0, 1, 2],
};
const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(MapFilter);
