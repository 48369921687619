import React, { useState, useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Modal from "react-bootstrap-modal";

import { getOpenCompanyId, getOpenFieldId } from "farmerjoe-common/lib/selectors/selectors";
import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { createNewShareFieldRequest } from "farmerjoe-common/lib/actions/fieldSharing";

import ModalBodySearchCompany from "./Modals/ModalSearchCompany";
import ModalConfirm from "./Modals/ModalConfirm";
import ModalFailure from "./Modals/ModalFailure";
import ModalSuccess from "./Modals/ModalSuccess";

import I18n from "../../language/i18n";


const stopPropagation = e => e.stopPropagation();

const CompanySearchDialog = (props) => {
  const { show, onClose, field, company: userCompany, userProfile, actions } = props;
  const [ currentDialog, setCurrentDialog ] = useState("INIT");
  const [ companies, setCompanies ] = useState([] as any);
  const [ selectedCompany, setSelectedCompany ]  = useState(null);
  const [ searchValue, setSearchValue ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);

  const { collaborators } = field;

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <Modal
      show={show}
      dialogClassName="company-search-dialog"
      onHide={onClose}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title onClick={stopPropagation}>{I18n.t("fieldSharing.searchDialogueTitle")}</Modal.Title>
        <button className="close" onClick={onClose}>
          <i className="fa fa-times" />
        </button>
      </Modal.Header>
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        { currentDialog === "INIT" && ModalBodySearchCompany({ userCompany, companies, setCompanies, setSelectedCompany, setCurrentDialog, searchValue, setSearchValue, collaborators }) }
        { currentDialog === "CONFIRM" && ModalConfirm({
          receiverCompany: selectedCompany,
          userCompany,
          field,
          actions,
          userProfile,
          setCurrentDialog,
          isLoading,
          setIsLoading,
        })
        }
        { currentDialog === "SUCCESS" && ModalSuccess({ onClose }) }
        { currentDialog === "FAILURE" && ModalFailure({ onClose }) }
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        { createNewShareFieldRequest },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = getOpenCompanyId(state);
  const openField = getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.id, openField);
  const company = getCompany(state.firestore.data, openCompany);
  const userProfile = state.firebase.profile;

  return {
    field,
    company,
    userProfile,
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
)(CompanySearchDialog);
