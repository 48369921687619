import * as React from 'react';

import { map, keyBy } from 'lodash-es';
import t from 'tcomb-form';
import { classes } from '../../utils/dom';
import './style.css';
import { emojis } from 'farmerjoe-common/lib/utils/emojis';
const Component = t.form.Component;

class tEmojiPicker extends Component {
  onSelected(color: string) {
    this.setState({ value: color });

    // Let other props know about the new color
    if (this.props.onChange) this.props.onChange(color);
  }

  getTemplate() {
    return locals => {
      const label = locals.label ? <div>{locals.label}</div> : null;
      return (
        <div
          className={classes(
            'emoji-picker emoji-font',
            locals.hasError && 'error',
          )}>
          {label}
          <div className="emoji-container">
            {map(emojis, emoji => {
              return (
                <div
                  key={`tp-color-${emoji}`}
                  onClick={() => this.onSelected(emoji)}
                  className={classes(
                    'item',
                    emoji === this.state.value && 'selected',
                  )}>
                  <div>
                    <span>{emoji}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    };
  }
}

export default tEmojiPicker;
