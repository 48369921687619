import React from 'react';

import { map, sortBy } from 'lodash-es';

import * as constants from '../../styles/style';

import I18n from '../../language/i18n';

import Lab from './Lab';

const LabItem = props => {
  const { lab, onClick } = props;

  const options = sortBy(
    map(lab.analysisTypes, (value, key) => {
      const locale = I18n.locale;
      let title = value.title.default;
      if (value.title.translations[locale]) {
        title = value.title.translations[locale];
      }

      return {
        text: title,
        value: key,
      };
    }),
    ['text'],
  );

  return (
    <div
      style={{
        borderWidth: 1,
        borderColor: '#CCC',
        borderRadius: 5,
        padding: 10,
        marginTop: 10,
        borderStyle: 'solid',
      }}>
      <Lab lab={lab} links={true} />

      <div style={{ marginTop: 20, marginLeft: 5, marginRight: 5 }}>
        <div
          style={{
            ...constants.styles.strong,
            ...constants.styles.muted,
            marginBottom: 10,
          }}>
          {I18n.t('analysis.ourOffers')} {lab.ourOfferLabel ? `( ${lab.ourOfferLabel} )` : ''}:
        </div>
        {options.map(option => {
          return (
            <button
              className={'btn btn-secondary d-block'}
              key={`${lab.key}-${option.value}`}
              onClick={() => onClick(lab, option.value)}
              style={{ marginBottom: 15 }}>
              {option.text}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default LabItem;
