import * as React from 'react';

import Icon, { FjIconType } from '../Common/Icon';
import * as constants from '../../styles/style';

type Props = {
  /**
   * The url to open
   */
  url: string;
  iconName: string;
  iconType?: FjIconType;
  iconStyle?: React.CSSProperties;
};

export default class CircleUrlButtonWithIcon extends React.PureComponent<Props> {
  static defaultProps = {
    iconType: 'ion',
  };

  render() {
    const { iconName, iconType, iconStyle, url } = this.props;

    return (
      <a href={url} target="_blank" rel="noreferrer">
        <span
          style={{
            display: 'flex',
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: constants.FJNEWGREEN,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Icon
            name={iconName}
            iconType={iconType}
            style={{
              color: '#FFF',
              fontSize: 25,
              backgroundColor: 'transparent' as 'transparent',
              ...iconStyle,
            }}
          />
        </span>
      </a>
    );
  }
}
