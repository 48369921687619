import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { get } from "lodash-es";
import { firestoreConnect } from "react-redux-firebase";
import t from "tcomb-form";

import { COMPANY_MAIN_GROUP_KEY } from "farmerjoe-common/lib/selectors/groups";
import withGeocoderFilledProps from "farmerjoe-common/lib/tcomb/formAddons/withGeocoderFilledProps";
import withAutoFilledPhoneNumber from "farmerjoe-common/lib/tcomb/formAddons/withAutoFilledPhoneNumber";
import {
  addTempActiveInCompanies,
  addTempUserPermissions,
  editCompany,
  openCompany,
} from "farmerjoe-common/lib/actions/company";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { generateCompanyUser, UserObj } from "farmerjoe-common/lib/utils/User";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { UserRole } from "farmerjoe-common/lib/flow/types";
import { getBillingDetailsForCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/BillingDetails";
import { getBillingDetailsForCompany } from "farmerjoe-common/lib/selectors/billingDetails";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { storeBillingDetails } from "farmerjoe-common/lib/actions/billing";
import { checkVAT } from "farmerjoe-common/lib/requests/billing";
import {
  ERROR_CODE_VAT_INVALID,
  ERROR_CODE_VAT_SERVICE_UNAVAILABLE,
} from "farmerjoe-common/lib/constants/billing";

import { Loading } from "../Loading/Loading";
import DeleteButton from "../Common/DeleteButton";
import Dialog, { AlertDialog } from "../Dialog/Dialog";
import withRouter from "../Router/withRouter";
import PinPositionMarker from "../Map/PinPositionMarker";
import PolygonAndMarkerPlacementMapDialog from "../Map/PolygonAndMarkerPlacementMapDialog";
import ModalMap from "../Map/ModalMap";
import CompanyModel from "../../tcomb/models/company";
import BillingDetailsModel from "../../tcomb/models/billingDetails";
import { numberTransformer } from "../../tcomb/transformers/transformers";
import { appPosToLatLng, isValidPosition } from "../../utils/Map";
import { getFieldPath } from "../../utils/page";
import { getUidToken } from "../../utils/auth";
import I18n from "../../language/i18n";

const Form = t.form.Form;

type Props = {
  company?: any;
  actions?: any;
  firebase?: any;
  auth?: any;
  editMode?: any;
  goToInfo?: any;
  history?: any[];
  onClose?: any;
  required?: any;
  show?: any;
  activeTab?: any;
  loading?: boolean;
  billingDetails?: any;
};

const TAB_COMPANY_INFO = "COMPANY_INFO";
const TAB_COMPANY_BUSINESS_ADDRESS = "COMPANY_BUSINESS_ADDRESS";
const ERROR_COMPANY_INFO_MISSING = "COMPANY_INFO_MISSING";

const FormCompany = (props: Props) => {
  const { company = {}, onClose, show, actions, editMode, auth, history, firebase, loading } = props;

  const [formError, setFormError] = useState({
    alertMessage: "",
    alertTitle: "",
  });

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editedCompany, setEditedCompany] = useState({
    ...company,
  });
  const [activeTab, setActiveTab] = useState<string>(props.activeTab || TAB_COMPANY_INFO);

  const [ billingDetails, setBillingDetails ] = useState(props.billingDetails);
  const [ companyChanged, setCompanyChanged ] = useState<boolean>(false);
  const [ billingDetailsChanged, setBillingDetailsChanged ] = useState<boolean>(false);
  const [ isNewCompany, setIsNewCompany ] = useState<boolean>(false);
  const [ apiLoading, setApiLoading ] = useState<boolean>(false);

  const companyFormRef = useRef(null);
  const billingDetailsRef = useRef(null);

  useEffect(() => {
    return () => {
      actions.editCompany(null);
    };
  },[actions]);


  /**
   * Handle saving when the user clicks the "save" button
   */
  const onSave = () => {
    if (companyFormRef.current) {
      const { errors, value } = (companyFormRef as any).current.validate();
      // If value is null, then the form has errors
      if (value === null || errors.length) {
        setFormError({
          alertMessage: I18n.t("please_correct_your_entry"),
          alertTitle: I18n.t("error"),
        });
        return;
      }
    }

    if (billingDetailsRef.current) {
      const { errors, value } = (billingDetailsRef as any).current.validate();
      // If value is null, then the form has errors
      if (value === null || errors.length) {
        setFormError({
          alertMessage: I18n.t("please_correct_your_entry"),
          alertTitle: I18n.t("error"),
        });
        return;
      }
    }

    return saveCompanyForm()
      .then((companyId: string) => {
        if(billingDetailsChanged) {
          return saveBillingDetailsForm(companyId).then(() => {
            return Promise.resolve(companyId);
          });
        }
        return Promise.resolve(companyId);
      }).then((companyId: string) => {
        if (props.goToInfo) {
          actions.openCompany(companyId);
          history?.push(getFieldPath(companyId));
        }
        if (isNewCompany) {
          actions.editCompany(null);
          history?.push(getFieldPath(companyId));
        }
        onClose();
      }).catch((e) => {
        console.log(e);
        switch(e.message) {
        case ERROR_COMPANY_INFO_MISSING:
          setFormError({
            alertMessage: I18n.t("billing.missingCompanyInfoError"),
            alertTitle: I18n.t("error"),
          });
          return;
        case ERROR_CODE_VAT_INVALID:
          setFormError({
            alertMessage: I18n.t("billing.vatInvalidError"),
            alertTitle: I18n.t("error"),
          });
          return;
        case ERROR_CODE_VAT_SERVICE_UNAVAILABLE:
          setFormError({
            alertMessage: I18n.t("billing.checkVatIdError"),
            alertTitle: I18n.t("error"),
          });
          return;
        default:
          setFormError({
            alertMessage: e.message,
            alertTitle: I18n.t("error"),
          });
        }
      });
  };

  const renderPolygonComponent = () => {
    // position can have strings or numbers inside so make sure we have the number version here
    const position = editedCompany.position
      ? {
        latitude: numberTransformer.parse(String(editedCompany.position.latitude)),
        longitude: numberTransformer.parse(String(editedCompany.position.longitude)),
      }
      : null;
    const validPosition = isValidPosition(position);
    return [
      <div
        onClick={() => setModalVisible(true)}
        key="preview-map">
        <ModalMap
          markers={[]}
          position={validPosition ? position : null}
          zoom={16}
          hideControls={true}
          containerStyle={{ height: "180px" }}
          noTabIndex={true}
          options={{
            disableDefaultUI: true,
            gestureHandling: "none",
            zoomControl: false,
            mapTypeControl: false,
          }}>
          {validPosition
            ? (
              <PinPositionMarker position={appPosToLatLng(position as any)} />
            )
            : null}
        </ModalMap>
      </div>,
      <PolygonAndMarkerPlacementMapDialog
        key="polygonandmarkerplacement"
        position={validPosition ? position : null}
        title={I18n.t("position_company")}
        label={`${I18n.t("position_company")} *`}
        onPolygonChange={onPolygonChange}
        noTabIndex={true}
        modalVisible={modalVisible}
        onOpen={() => setModalVisible(true)}
        onClose={() => setModalVisible(false)}
      />,
    ];
  };

  const onChange = (value) => {
    const _value = JSON.parse(JSON.stringify(value));
    setEditedCompany({
      ...editedCompany,
      ..._value,
    });


    setCompanyChanged(true);
  };

  const onChangeBillingDetails = (value) => {
    const _value = JSON.parse(JSON.stringify(value));
    setBillingDetails({
      ...billingDetails,
      ..._value,
    });

    if (_value.contact) {
      setBillingDetailsChanged(true);
    }
  };

  const onPolygonChange = ({ polygon, areaSize, center }) => {
    setEditedCompany({
      ...editedCompany,
      ...{
        position: center,
      },
    });
    setCompanyChanged(true);
  };

  const saveCompanyForm = () : Promise<any> => {
    if (!companyChanged) {
      return Promise.resolve(editedCompany.key);
    }
    // TODO: figure out where the best place to do this is
    // Trim the name of the company here.
    // I"m not sure if this really belongs here or we should validate the user input in tcomb-form
    const value = {
      ...editedCompany,
      name: editedCompany.name.trim(),
    };

    // that comes from redux... Delete it here, we have a collection in firestore for this
    delete value.users;

    if (editMode) {
      const updates = {
        ...value,
        modified_by: UserObj(auth),
        modified: firebase.firestore.FieldValue.serverTimestamp(),
      };

      return firebase
        .firestore()
        .collection("companies")
        .doc(value.key)
        .update(updates)
        .then(() => {
          return Promise.resolve(value.key);
        });
    }

    const db = firebase.firestore();
    const ref = db.collection("companies").doc();
    const id = ref.id;
    const batch = db.batch();

    const company = {
      name: value.name,
      ...value,
      key: id,
      created_by: UserObj(auth),
      created: firebase.firestore.FieldValue.serverTimestamp(),
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const companyUsersCollectionRef = db
      .collection("companies")
      .doc(id)
      .collection("users")
      .doc(auth.uid);

    const user = generateCompanyUser(
     {
       active: true,
       key: auth.uid,
       role: UserRole.Admin,
       name: auth.displayName,
       email: auth.email,
       phoneNumber: auth.phoneNumber,
     } as any,
     null,
     { key: COMPANY_MAIN_GROUP_KEY, name: COMPANY_MAIN_GROUP_KEY },
     id,
    );

    batch.set(companyUsersCollectionRef, user, { merge: true });
    batch.set(ref, company);
    return batch.commit().then(() => {
      setIsNewCompany(true);
      actions.addTempUserPermissions(id, user);
      actions.addTempActiveInCompanies(id);
      return Promise.resolve(id);
    });
  };

  const saveBillingDetailsForm = (companyId: string) : Promise<any> => {
    if (!companyId) {
      return Promise.reject(new Error(ERROR_COMPANY_INFO_MISSING));
    }

    const _billingDetails = {
      contact: billingDetails.contact,
      company: billingDetails.company.name ? billingDetails.company : null,
    };

    if (billingDetails.company.taxId) {
      return checkTaxId(billingDetails.company.taxId).then((result) => {
        if (result.status === 200 ) {
          return result.json().then((response) => {
            const { valid } = response;
            if (valid) {
              return props.actions.storeBillingDetails(companyId, _billingDetails);
            }
            return Promise.reject(new Error(ERROR_CODE_VAT_INVALID));
          });
        }
        if (result.status === 400 ) {
          return Promise.reject(new Error(ERROR_CODE_VAT_INVALID));
        }
        return Promise.reject(new Error(ERROR_CODE_VAT_SERVICE_UNAVAILABLE));
      });
    }

    return props.actions.storeBillingDetails(companyId, _billingDetails);
  };

  const checkTaxId = (taxId: string) : Promise<any> => {
    setApiLoading(true);
    return getUidToken()
      .then((uidToken: string) => {
        return checkVAT(uidToken, taxId);
      }).finally(() => setApiLoading(false));
  };

  if (!show) {
    return null;
  }

  if (!loading) {
    return  <Loading />;
  }

  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={editMode ? I18n.t("company.edit") : I18n.t("add_company")}
      footer={
        <div className="d-flex flex-grow-1">
          <button className="btn btn-secondary ml-auto" onClick={onClose}>
            {I18n.t("cancel")}
          </button>{" "}
          <button
            className="btn btn-primary"
            onClick={onSave}>
            {I18n.t("save")}
          </button>
        </div>
      }>
      {apiLoading ? (  
        <Loading />
      ) : (
        <div>
          <div className={"tabbar"}>
            <div className={"tab " + (activeTab === TAB_COMPANY_INFO ? "active" : "")}>
              <span
                style={{cursor: "pointer"}}
                onClick={() => setActiveTab(TAB_COMPANY_INFO)}>
                {I18n.t("billing.enterpriseName")}
              </span>
            </div>
            <div className={"tab " + (activeTab === TAB_COMPANY_BUSINESS_ADDRESS ? "active" : "")}>
              <span style={{cursor: "pointer"}} onClick={() => setActiveTab(TAB_COMPANY_BUSINESS_ADDRESS)}>
                {I18n.t("billing.billingInfo")}
              </span>
            </div>
          </div>

          {activeTab === TAB_COMPANY_INFO ? (
            <>
              <CompanyForm
                ref={companyFormRef}
                type={CompanyModel.model(true)}
                options={() =>
                  CompanyModel.options(this, true, renderPolygonComponent())
                }
                value={editedCompany}
                onChange={onChange}
                geocoderAutoFilledProps={["position", "country"]}
                autoFillPhoneNumber={!props.editMode}
              />
              {editMode && auth.uid === get(company, "created_by.uid", null) ? (
                // Only the person who created the company can delete it
                <div className="text-center">
                  <DeleteButton
                    onDelete={() => {
                      history?.push("/");
                      setTimeout(() => {
                        const db = firebase.firestore();
                        actions.editCompany(null);
                        actions.openCompany(null);

                        const batch = firebase.firestore().batch();

                        // mark the company for deletion
                        batch.delete(db.collection("companies").doc(editedCompany.key));

                        // remove the company from the user"s company array
                        batch.update(
                          db.collection("users").doc(auth.uid),
                          {
                            [`companies.${editedCompany.key}`]: firebase.firestore.FieldValue.delete(),
                          },
                        );

                        batch.commit();
                      }, 0);
                    }}
                    buttonText={I18n.t("company.delete")}
                    alertTitle={I18n.t("company.delete")}
                    alertMessage={I18n.t("company.doYouWantToDeleteCompany")}
                  />
                </div>
              ) : null}
            </>
          ) : null}

          {activeTab === TAB_COMPANY_BUSINESS_ADDRESS ? (
            <Form
              ref={billingDetailsRef}
              type={BillingDetailsModel.model()}
              options={() => BillingDetailsModel.options(this)}
              value={billingDetails}
              onChange={onChangeBillingDetails}
            />
          ) : null}
          <AlertDialog
            show={!!formError.alertMessage}
            onClose={() =>
              setFormError({ alertMessage: "", alertTitle: "" })
            }
            title={formError.alertTitle}
          >
            {formError.alertMessage}
          </AlertDialog>
        </div>
      ) }
    </Dialog>
  );
};

const selector = (state, ownProp) => {
  const companyId = selectors.getEditCompanyId(state);

  const queryPaths: any[] = [];
  if (companyId) {
    queryPaths.push(getBillingDetailsForCompanyQuery(companyId));
  }

  const billingDetails = getBillingDetailsForCompany(state, companyId);

  return {
    editMode: !!companyId,
    company: companyId ? getCompany(state.firestore.data, companyId) : undefined,
    auth: state.firebase.profile,
    required: ownProp.required ? ownProp.required : false,
    companyId,
    loading: hasLoaded(queryPaths, state),
    billingDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          addTempActiveInCompanies,
          editCompany,
          openCompany,
          addTempUserPermissions,
          storeBillingDetails,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedForm = firestoreConnect(props => {
  const { companyId } = props;
  if (!companyId) {
    return [];
  }
  return [
    getBillingDetailsForCompanyQuery(companyId),
  ];
})(FormCompany);

export default compose<typeof FormCompany>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(wrappedForm);

const CompanyForm = compose<typeof t.form.Form>(
  withAutoFilledPhoneNumber,
  withGeocoderFilledProps,
)(t.form.Form) as any;
