import { get } from "lodash-es";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import {
  getHarvestedCropsQuery,
  getHarvestedCropForSharedFieldQuery,
  getAbandonedCropsQuery,
} from "farmerjoe-common/lib/utils/firestoreRedux/Crops";

type HarvestedStateFilter = "active" | "harvested";

/**
 * The selected Fields sorting is stored in the state. This function helps to retrieve it.
 * E.g.: state.filtersByCompany.${companyId}.displaySettings.${harvestedStateFilter}.sortFieldsBy
 */
export function getFieldsSorting(state, openCompanyId, harvestedStateFilter: HarvestedStateFilter, fallbackFilter: string) {
  return get(
    state,
    ["filtersByCompany", openCompanyId, "displaySettings", harvestedStateFilter, "sortFieldsBy"],
    fallbackFilter,
  );
}

export function getFieldsGroup(state, openCompanyId, harvestedStateFilter: HarvestedStateFilter, fallbackFilter: string) {
  return get(
    state,
    ["filtersByCompany", openCompanyId, "displaySettings", harvestedStateFilter, "groupFieldsBy"],
    fallbackFilter,
  );
}

export const canSeeHarvested = (myCompanyProfile) => {
  return (isAdmin(myCompanyProfile) ||
    (get(myCompanyProfile, "views.fields") === true &&
      get(myCompanyProfile, "views.crops") === true));
};

export const calculateFieldsArea = (fieldSets: Array<any>) => {
  return fieldSets
    .map(fieldSet => fieldSet.fields.reduce((acc, val) => acc + (val.size || 0), 0))
    .reduce((acc, val) => acc + val, 0).toFixed(2);
};

export const getQueries = (openCompany, browsingGroup, myCompanyProfile, dateRange, sharedFields) => {
  const queries: any = [];
  // harvested crops on own fields
  queries.push({
    ...getHarvestedCropsQuery(
      openCompany,
      browsingGroup,
      myCompanyProfile,
      dateRange as any,
    ),
  });

  // harvested crops on shared fields
  if (sharedFields && sharedFields.length) {
    sharedFields.forEach((sharedField) => {
      queries.push({
        ...getHarvestedCropForSharedFieldQuery(
          openCompany,
          sharedField.key,
          sharedField.company_id,
        ),
      });
    });
  }

  // harvested crops on abandoned fields
  queries.push(getAbandonedCropsQuery(openCompany));
  return queries;
};
