import React from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../styles/style';
import imgFjAvatar from '../../public/images/FJ_logo_inverted.png';

type Props = {
  text: string;
};

const NoResults = ({ text }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        <img
          src={imgFjAvatar}
          style={{ width: 60, height: 60, marginBottom: 20 }}
          alt=""
        />
        <span
          style={{
            ...constants.styles.extraBig,
            ...constants.styles.muted,
            ...constants.styles.alignCenter,
          }}>
          {text}
        </span>
      </div>
    </div>
  );
};

NoResults.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NoResults;
