import * as React from 'react';

import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
import moment from 'moment';
import { toDate } from 'farmerjoe-common';

type Props = {
  result_on: Date;
};

const ResultOn = (props: Props) => {
  const { result_on } = props;
  const days = calculate(result_on);
  return (
    <div
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Icon
        name={'hourglass_red'}
        iconType={'fj'}
        style={{ fontSize: 11, color: constants.FJAPPLERED }}
      />
      <span style={{ color: constants.FJAPPLERED }}>{days} T</span>
    </div>
  );
};

const calculate = (date) => {
  const today = moment().startOf('day');
  const past_date = moment(toDate(date));
  const res = -today.diff(past_date, 'days');
  return res;
};

export default ResultOn;
