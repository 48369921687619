import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../language/i18n';
import Box from '../Common/Box/Box';
import FormTextRow from '../Common/FormTextRow';
import FormPositionRow from '../Common/FormPositionRow';

const Field = ({ analysis }) => {
  const field = analysis.field;
  return (
    <Box
      header={I18n.t('field.field')}
      content={
        <div>
          <FormTextRow value={field.name} label={I18n.t('field.field')} />
          <FormTextRow
            value={`${field.size || 0} ha`}
            label={I18n.t('field.area')}
          />
          <FormPositionRow
            value={field.position}
            label={I18n.t('coordinates')}
          />
        </div>
      }
    />
  );
};

Field.propTypes = {
  analysis: PropTypes.object.isRequired,
};

export default Field;
