import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import type { firebase } from 'react-redux-firebase';
import { get } from 'lodash-es';
import { bindActionCreators } from 'redux';
import * as urlParser from 'url';

import { sendEmail } from 'farmerjoe-common/lib/utils/Activation';
import { getProfileQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Profiles';
import { translateError } from 'farmerjoe-common/lib/actions/errorTranslations';

import AlertBox from '../../components/Common/AlertBox';
import { ActivationSuccess } from '../../components/Activation/ActivationSuccess';
import { ActivationLoadingScreen } from '../../components/Activation/ActivationLoadingScreen';
import { ActivationView } from '../../components/Activation/ActivationView';
import I18n from '../../language/i18n';
import { REQUEST_EMAIL_ACTIVATION_URL } from '../../constants';
import { accountEdit } from '../../actions/profile';

type Props = {
  url?: string;
  profile: any; // Profile
  firebase: any; // firebase
  auth: any; // FirebaseAuth
  owner: any;
  actions: {
    accountEdit: typeof accountEdit;
  };
};

type State = {
  email: string;
  newVerificationMailSent: boolean;
  currentPassword?: string;
  validationInProgress: boolean;
  verificationSuccess?: boolean;
  emailChangeError?: null | string;
  verificationError?: null | string;
  loading: boolean;
  error?: null | string;
};

class Activation extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.profile.email,
      newVerificationMailSent: false,
      validationInProgress: false,
      loading: false,
      emailChangeError: null,
      verificationError: null,
    };
  }

  componentDidMount() {
    const { url, auth, firebase } = this.props;

    if (url) {
      this.setState({ validationInProgress: true });
      this.validate();
    }

    // It can happen that the verification was performed in another place
    // like browser or another instance of the app
    // to make sure that we are working with the latest user obj, we reload the auth here
    if (get(auth, 'uid')) {
      firebase.reloadAuth();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    const { url, auth, owner } = this.props;

    if (url !== prevProps.url && url) {
      this.setState({ validationInProgress: true });
      this.validate();
    }

    // handle updates to the owner
    if (
      prevProps.owner !== owner &&
      prevProps.owner.isFetching &&
      !owner.isFetching
    ) {
      if (owner.error) {
        this.setState({ newVerificationMailSent: false, loading: false });
      } else {
        this.setState({ loading: false, newVerificationMailSent: true });
      }
    }
  }

  getParsedUrl() {
    const { url } = this.props;
    return urlParser.parse(url as string, true);
  }

  validate() {
    const url = this.getParsedUrl();
    const { oobCode } = url.query;
    const { firebase, auth } = this.props;

    this.setState({ validationInProgress: true, error: null });
    firebase
      .auth()
      .applyActionCode(oobCode)
      .then(async () => {
        if (auth && auth.uid) {
          await firebase.reloadAuth();
        }

        this.setState({ validationInProgress: false });
      })
      .catch(e => {
        if (e.code === 'auth/no-current-user') {
          // the current user is not logged in, but verification most probably succeeded.

          this.setState({
            verificationSuccess: true,
            validationInProgress: false,
          });

          return;
        }
        this.setState({
          verificationError: translateError(e),
          validationInProgress: false,
        });
      });
  }

  updateProfileMailAndSendEmail() {
    const { firebase, profile } = this.props;
    const { email, currentPassword } = this.state;

    if (!currentPassword) {
      this.setState({
        emailChangeError: I18n.t('password.youNeedToEnterCurrent'),
      });
      return;
    }

    this.setState({ loading: true, error: null });

    firebase
      .auth()
      .signInWithEmailAndPassword(profile.email, currentPassword)
      .then(() => {
        this.updateEmail(email);
      })
      .catch(e => {
        this.setState({ emailChangeError: translateError(e), loading: false });
      });
  }

  updateEmail(newEmail) {
    const { profile } = this.props;
    this.setState({ loading: true, error: null });

    return this.props.actions.accountEdit({
      ...profile,
      email: newEmail,
      password: this.state.currentPassword,
    });
  }

  sendEmail(email) {
    this.setState({ loading: true, error: null });
    return sendEmail(email, I18n.locale, REQUEST_EMAIL_ACTIVATION_URL)
      .then(response => {
        if (response.success) {
          this.setState({ newVerificationMailSent: true, loading: false });
          return;
        }

        this.setState({ newVerificationMailSent: false, loading: false });
      })
      .catch(error => {
        this.setState({
          newVerificationMailSent: false,
          loading: false,
          error: error.message,
        });
      });
  }

  render() {
    const { profile, auth } = this.props;

    const {
      newVerificationMailSent,
      validationInProgress,
      loading,
      verificationError,
      verificationSuccess,
      error,
    } = this.state;

    if (validationInProgress) {
      return (
        <ActivationLoadingScreen validationInProgress={validationInProgress} />
      );
    }

    if (auth.emailVerified || verificationSuccess) {
      return <ActivationSuccess />;
    }

    if (newVerificationMailSent) {
      return (
        <AlertBox
          text={I18n.t('activation.sentNewEmail', {
            email: this.state.email,
          })}
          type={'success'}
        />
      );
    }

    return (
      <ActivationView
        auth={auth}
        error={verificationError || error}
        loading={loading}
        email={this.state.email}
        currentPassword={this.state.currentPassword}
        onChangePassword={text => this.setState({ currentPassword: text })}
        onChangeEmail={text => this.setState({ email: text })}
        profile={profile}
        onSubmit={() => {
          if (profile.email !== this.state.email) {
            this.updateProfileMailAndSendEmail();
          } else {
            this.sendEmail(profile.email);
          }
        }}
      />
    );
  }
}

const selector = (state, ownProps) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    // url: ownProps.navigation.getParam('url'),
    owner: state.owner,
  };
};

const wrappedActivation = firestoreConnect(props => {
  if (!props.uid) {
    return [];
  }

  return [
    getProfileQuery(props.uid),
  ];
})(Activation);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          accountEdit,
        },
      ),
      dispatch,
    ),
  };
}
export default connect(
  selector,
  mapDispatchToProps,
)(wrappedActivation);
