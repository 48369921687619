import React from "react";
import PropTypes from "prop-types";
import hoistStatics from "hoist-non-react-statics";
import { RouteChildrenProps } from "react-router";

export default function withRouter(Component) {
  const C = (props: any, context) => {
    const { wrappedComponentRef, ...remainingProps } = props;

    const { history, route, staticContext, match } = context.router;
    const location = props.location || route.location;
    const routeProps = { match, location, history, staticContext };

    return (
      <Component
        {...remainingProps}
        {...routeProps}
        ref={wrappedComponentRef}
      />
    );
  };

  C.displayName = `withRouter(${Component.displayName || Component.name})`;
  C.WrappedComponent = Component;
  C.propTypes = {
    wrappedComponentRef: PropTypes.func,
  };
  C.contextTypes = {
    router: PropTypes.object,
  };

  return hoistStatics(C, Component);
}

/**
 * When a component is wrapped using the above "withRouter",
 * the props are populated with the additional props.
 * Concatenate this type with your component props for improved typings.
 */
export type FjRouteChildrenProps = RouteChildrenProps & {
  // it is unclear why this property is present.
  staticContext?: any;
};
