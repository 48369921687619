import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { bindActionCreators, compose } from 'redux';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';

import {
  getGroupUsers,
  getCompanyUser,
  getGlobalSearchUser,
} from 'farmerjoe-common/lib/selectors/user';
import {
  getBrowsingGroupKey,
  getOpenGroupId,
  getGroup,
  COMPANY_MAIN_GROUP_KEY,
} from 'farmerjoe-common/lib/selectors/groups';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import * as companySelectors from 'farmerjoe-common/lib/selectors/companies';
import { generateCompanyUser } from 'farmerjoe-common';
import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';
import { getUserQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Users';

import Icon from '../../Common/Icon';
import { Loading } from '../../Loading/Loading';
import Avatar from '../../../containers/Avatar';

import InviteButton from '../InviteButton';
import { ModalProfileProps } from '../types';
import { generateInvitee } from '../utils';
import DefaultStandardUser from '../DefaultStandardUser';

type Props = ModalProfileProps & PropsFromRedux;

const ModalProfile = (props: Props) => {
  const {
    selectedUser,
    setCurrentDialog,
    searchedUser,
    loading,
    isCompanyUser,
    company,
    group,
    auth,
    actions,
    groupType,
  } = props;

  if(loading) {
    return <Loading />;
  }

  const onClick = () => {
    const userGroup = group || {
      key: COMPANY_MAIN_GROUP_KEY,
      name: COMPANY_MAIN_GROUP_KEY,
    };
    const invitee = generateInvitee(searchedUser, DefaultStandardUser, auth);

    const companyUser = generateCompanyUser(
      invitee,
      invitee,
      userGroup,
      company.key,
    );

    try {
      actions.addEmployee(company, companyUser, groupType);
    } catch (e) {
      console.log(e);
      setCurrentDialog('FAILURE');
      return;
    }

    setCurrentDialog('SUCCESS');
  };


  return (
    <>
      <div style={{ backgroundColor: '#FFF' }}>
        <div onClick={() => setCurrentDialog('INIT')} style={{cursor: 'pointer'}}>
          <Icon
            name="arrow-left"
            iconType="fa"
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            flexDirection: 'column',
            position: 'relative',
          }}>
          <div
            style={{
              backgroundColor: '#FFF',
              borderRadius: '5px',
              marginBottom: '5px',
            }}>
            <Avatar
              uid={selectedUser.uid}
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#FFF',
              flexDirection: 'column',
            }}>
            <span
              style={{
                fontSize: '22px',
                marginTop: '10px',
                marginBottom: '10px',
              }}>
              {`${selectedUser.firstname} ${selectedUser.lastname}`}
            </span>
          </div>
          {!isCompanyUser ? (
            <div style={{marginTop: '20px' }}>
              <InviteButton onClick={onClick} />
            </div>
          ) : (
            <div style={{marginTop: '14px' }}>
              <span style={{ textAlign: 'center', fontSize: 18 }}>
                {company.name}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const selector = (state, ownProps) => {
  const { selectedUser: { uid } } = ownProps;
  const searchedUser = getGlobalSearchUser(state, uid);
  const companyId = selectors.getOpenCompanyId(state);
  const company = companySelectors.getCompany(state.firestore.data, companyId);

  let openGroupId = getOpenGroupId(state);
  const browsingGroup = getBrowsingGroupKey(state, companyId);

  if (browsingGroup !== COMPANY_MAIN_GROUP_KEY) {
    openGroupId = browsingGroup;
  }

  const group = getGroup(state, companyId, openGroupId);
  const users = getGroupUsers(state, companyId, openGroupId);

  let isCompanyUser = false;
  if (searchedUser) {
    isCompanyUser =
      users.find((user) => user.email === searchedUser.email) ||
      getCompanyUser(state, searchedUser.uid, companyId);
  }

  const queries = [
    `globalUserSearch/${uid}`,
    getUserQuery(companyId, uid, browsingGroup),
  ];

  return {
    searchedUser,
    isCompanyUser,
    company,
    auth: state.firebase.profile,
    group,
    loading: !hasLoaded(queries, state),
    companyId,
    browsingGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
};

const wrappedProfile = firestoreConnect((props) => {
  const { selectedUser: { uid }, companyId, browsingGroup } = props;

  if (!uid) {
    return [];
  }

  const paths: any = [
    {
      collection: 'users',
      doc: uid,
      storeAs: `globalUserSearch/${uid}`,
    },
  ];

  if (companyId) {
    paths.push(getUserQuery(companyId, uid, browsingGroup));
  }

  return paths;
})(ModalProfile);

const connector = connect(selector, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default compose(connector)(wrappedProfile);
