import * as React from 'react';
import I18n from '../../language/i18n';
import Dialog from '../Dialog/Dialog';
import 'firebase/auth';

export default class Help extends React.PureComponent<
  {},
  { showHelp: boolean, chatClosed: boolean }
> {
  state = {
    showHelp: false,
    chatClosed: true,
  };

  render() {
    return (
      <span>
        <a
          href="javascript:void 0"
          onClick={() => this.setState({ showHelp: true })}>
          {I18n.t('help')}
        </a>
        <Dialog
          show={this.state.showHelp}
          onClose={() => this.setState({ showHelp: false })}
          title={I18n.t('help')}>
          <div>
            <span>{I18n.t('email')}:</span>{' '}
            <a
              href="mailto:support@farmerjoe.com"
              target="_blank"
              rel="noopener noreferrer">
              support@farmerjoe.com
            </a>
          </div>
          <div>
            <span>{I18n.t('phone')}:</span>{' '}
            <a
              href="tel:+493057714726"
              target="_blank"
              rel="noopener noreferrer">
              +49 30 57714726
            </a>
          </div>
          <div></div>
        </Dialog>
      </span>
    );
  }
}
