import React, { useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { isEqual, pick } from "lodash-es";
import Modal from "react-bootstrap-modal";
import { getFavoritesQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Favorites";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import { getFavorites } from "farmerjoe-common/lib/selectors/favorites";


import InvoiceAddress from "./InvoiceAddress";
import FlatList from "../Common/FlatList";
import Icon from "../Common/Icon";
import { CreateNewButton } from "../Common/NewButton";
import { EditButton } from "../Common/EditButton";

import * as constants from "../../styles/style";
import I18n from "../../language/i18n";
import { getCountryName } from "../../utils/Countries";

// TODO: improve typings
type Props = {
  company: any;
  openView: () => any;
  goBack: (params: any) => any;
  saveState?: (state: State) => any;
  favorites?: any[];
  firebase?: any;
  labSelected?: any;
};
type State = any;

const SelectInvoiceAddress = (props) => {
  const { goBack, company, firebase, favorites } = props;
  const [state, setState] = useState({
    address: props.address,
    editAddress: null,
    showNewAddressForm: false,
  });

  let invoiceAddress: any;
  const addresses = favorites?.map(favorite => favorite);

  const _renderCompanyHeader = () => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          // Reset this
          firebase
            .firestore()
            .collection("companies")
            .doc(company.key)
            .update({
              "preferences.lastUsedInvoiceAddress": firebase.firestore.FieldValue.delete(),
            });
          setState({ ...state, address: pickAddressProps(company) });
        }}>
        <div
          className={"d-flex flex-row flex-grow-1"}
          style={{
            padding: 15,
            backgroundColor: "#EFEFEF",
          }}>
          <div
            className={"d-flex align-items-center justify-content-center pr-3"}>
            <Icon
              iconType={"fj"}
              name={"barn"}
              style={{ fontSize: 35, color: "#000" }}
            />
          </div>
          <div
            className={"d-flex justify-content-center flex-column flex-grow-1"}>
            <span
              style={{
                ...constants.styles.strong,
                ...{
                  fontSize: 20,
                  paddingBottom: 2,
                  paddingRight: 50,
                },
              }}>
              {company.name}
            </span>
            <span>{company.street}</span>
            <span>
              {company.zip} {company.city}
            </span>
            <span>{getCountryName(company.country)}</span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            {isEqual(
              pickAddressProps(state.address),
              pickAddressProps(company),
            )
              ? (
                <Icon
                  name={"ios-checkmark-circle"}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )
              : (
                <Icon
                  name={"ios-radio-button-off"}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  const _renderAddress = (item) => {
    return (
      <div
        className={"analysis-invoice-item"}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setState({ ...state, address: item });
        }}>
        <div className={"d-flex flex-row flex-grow-1"}>
          <div className={"d-flex flex-column"}>
            <span
              style={{
                ...constants.styles.strong,
                ...{
                  fontSize: 20,
                  paddingBottom: 2,
                  paddingRight: 50,
                },
              }}>
              {item.name}
            </span>
            <span>{item.street}</span>
            <span>
              {item.zip} {item.city}
            </span>
            <span>{getCountryName(item.country)}</span>
            {props.labSelected.id === "lab1"
              ? (<span>
                {"BOLAP Kundennummer:"} {item.bolapClientId}
              </span> 
              ) : null}
          </div>
          {item.company
            ? null
            : (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: 15,
                  display: "flex",
                  cursor: "pointer",
                  marginLeft: "auto",
                }}
                onClick={(e) => e.stopPropagation()}>
                <EditButton
                  onClick={() => {
                    setState({ ...state, editAddress: item, showNewAddressForm: true });
                  }}
                />
              </div>
            )}

          <div className={"d-flex flex-row align-items-center"}>
            {isEqual(
              pickAddressProps(state.address),
              pickAddressProps(item),
            )
              ? (
                <Icon
                  name={"ios-checkmark-circle"}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )
              : (
                <Icon
                  name={"ios-radio-button-off"}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  const onSave = () => {
    const { address } = state;

    if (address.key) {
      firebase
        .firestore()
        .collection("companies")
        .doc(company.key)
        .update({
          "preferences.lastUsedInvoiceAddress": state.address.key,
        });
    } else {
      firebase
        .firestore()
        .collection("companies")
        .doc(company.key)
        .update({
          "preferences.lastUsedInvoiceAddress": firebase.firestore.FieldValue.delete(),
        });
    }

    goBack({
      invoice_address: state.address,
    });
  };

  const pickAddressProps = (address) => {
    return pick(address, [
      "name",
      "street",
      "zip",
      "city",
      "country",
      "email",
      "tel",
    ]);
  };

  return [
    <Modal.Header closeButton={false} key="header">
      <Modal.Title>{I18n.t("invoice_address")}</Modal.Title>
      {state.showNewAddressForm
        ? null
        : (
          <CreateNewButton
            onClick={() => setState({ ...state, showNewAddressForm: true })}
          />
        )}
    </Modal.Header>,
    <Modal.Body onClick={e => e.stopPropagation()} key={"body"}>
      {state.showNewAddressForm
        ? (
          <InvoiceAddress
            componentRef={instance => (invoiceAddress = instance)}
            labSelected={props.labSelected}
            onSave={address =>
              setState({
                ...state,
                showNewAddressForm: false,
                address: address,
                editAddress: null,
              })
            }
            invoice_address={state.editAddress}
          />
        )
        : (
          <FlatList
            data={addresses}
            renderItem={item => _renderAddress(item.item)}
            ListHeaderComponent={() => _renderCompanyHeader()}
            className={"analyses-invoice-list"}
            ItemSeparatorComponent={() => (
              <div style={{ marginLeft: 15 }}>
                <hr />
              </div>
            )}
          />
        )}
    </Modal.Body>,
    <Modal.Footer onClick={e => e.stopPropagation()} key="footer">
      {state.showNewAddressForm
        ? (
          <div className="text-right">
            <button
              className="btn btn-secondary"
              onClick={() => setState({ ...state, showNewAddressForm: false, editAddress: null})
              }>
              {I18n.t("cancel")}
            </button>{" "}
            <button
              className="ml-auto btn btn-primary"
              onClick={() => {
                invoiceAddress.onSave();
              }}>
              {I18n.t("save")}
            </button>
          </div>
        )
        : (
          <div className="d-flex flex-grow-1">
            <button className="btn btn-secondary" onClick={goBack}>
              {I18n.t("back")}
            </button>{" "}
            <button
              className="ml-auto btn btn-primary"
              onClick={onSave}>
              {I18n.t("done")}
            </button>
          </div>
        )}
    </Modal.Footer>,
  ];
};

const wrappedSelectInvoiceAddress = firestoreConnect(props => {
  const paths = [
    getFavoritesQuery(props.openCompany, "invoiceAddress", props.browsingGroup),
  ];

  return paths;
})(SelectInvoiceAddress);

const selector = (state) => {
  const openCompany = selectors.getOpenCompanyId(state);

  const favorites = getFavorites(state, openCompany, "invoiceAddress");
  const company = getCompany(state.firestore.data, openCompany);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  return {
    openCompany,
    company,
    favorites,
    browsingGroup,
  };
};

export default connect(
  selector,
)(wrappedSelectInvoiceAddress);
