import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";

import * as actionCreators from "farmerjoe-common/lib/actions/actions";
import { translateError } from "farmerjoe-common/lib/actions/errorTranslations";

import Logo from "./Logo";
import { LoadingIcon } from "../Loading/Loading";
import Page from "../Page/Page";
import { AlertDialog } from "../Dialog/Dialog";
import withRouter from "../Router/withRouter";
import firebase from "../../data/firebase";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import { captureException } from "../../utils/sentry";

type Props = {
  actionCode: string;
  history?: any;
};

const VerifyEmail = (props: Props) => {
  const { actionCode, history } = props;

  const [hasSuccess, setHasSuccess] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertTitle: null,
  });
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const verify = async () => {
    setIsFetching(true);
    const auth = firebase.auth();
    try {
      await auth.applyActionCode(actionCode);
      setIsFetching(false);
      setHasSuccess(true);
    } catch (e) {
      setIsFetching(false);
      setError(e as any);
      console.warn(e);
      captureException(e);
    }
  };

  let errorMessage: any = null;
  if (error) {
    if (typeof error === "object") {
      const { code } = error;
      if (code) {
        errorMessage = I18n.t("verifyEmailLinkExpired");
      }
    } else {
      errorMessage = translateError(error);
    }
  }

  const container = (
    <div style={{ ...styles.container }}>
      <Logo />

      {hasSuccess ? (
        <div>
          <div
            style={{
              ...constants.styles.box,
              ...{
                borderRadius: 5,
                borderColor: "#CCC",
                borderWidth: 1,
              },
            }}>
            <span style={{ marginBottom: 10, fontSize: 16 }}>
              <span style={{ color: "#707274" }}>
                {I18n.t("emailVerified")}
              </span>
            </span>

            <div
              style={{
                margin: "1rem 0",
                display: "flex",
              }}>
              <Link to="/login" style={{display: "block", width: "100%"}}>
                <div className="btn btn-primary btn-block">
                  {I18n.t("login")}
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : null}


      {error ? (
        <div>
          <div
            style={{
              ...constants.styles.box,
              ...{
                borderRadius: 5,
                borderColor: "#CCC",
                borderWidth: 1,
              },
            }}>
            <span style={{ marginBottom: 10, fontSize: 16 }}>
              <span style={{ color: "#707274" }}>
                {errorMessage}
              </span>
            </span>
            <div
              style={{
                margin: "1rem 0",
                display: "flex",
              }}>
              <Link to="/login" style={{display: "block", width: "100%"}}>
                <div className="btn btn-default btn-block">
                  {I18n.t("back")}
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      {!hasSuccess && !error ? (
        <div style={{textAlign: "center"}}>
          <button
            className={"btn btn-primary"}
            onClick={() => verify()}
            style={{ fontWeight: "bold" }}>
            {I18n.t("clickHereToActivate")}
          </button>
        </div>
      ) : null}
    </div>
  );

  return (
    <Page
      wrapperClassName="d-flex align-content-center justify-content-center flex-column login request-password"
      header={null}>
      <div className="login-register login-sidebar">
        <div className="login-box card">
          <div className="card-body" style={{ overflowX: "hidden" }}>
            {container}
          </div>

          {isFetching
            ? (
              <div
                style={{
                  display: "flex",
                  backgroundColor: "rgba(255,255,255,0.6)",
                  position: "absolute",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  zIndex: 9999,
                }}>
                <LoadingIcon style={{ transform: [{ scale: 1.5 }] }} />
              </div>
            )
            : null}
        </div>
      </div>
      <AlertDialog
        show={!!alert.alertMessage}
        onClose={() =>
          setAlert({ alertMessage: null, alertTitle: null })
        }
        title={alert.alertTitle}
      >
        {alert.alertMessage}
      </AlertDialog>
    </Page>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    flex: 1,
    justifyContent: "flex-end",
  },
};

export default compose<typeof VerifyEmail>(
  connect(
    null,
    actionCreators,
  ),
  withRouter,
)(VerifyEmail);
