import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { get } from "lodash-es";
import { toDate } from "farmerjoe-common";
import { COMPANY_MAIN_GROUP_KEY } from "farmerjoe-common/lib/selectors/groups";

import "./style.css";

import CommentMenu from "./Menus/Comment";
import Fertilizing from "./CommentTypes/Fertilizing";
import WaitTime from "./CommentTypes/WaitTime";
import Analysis from "./CommentTypes/Analysis";
import Crop from "./CommentTypes/Crop";
import Field from "./CommentTypes/Field";
import Bonitur from "./CommentTypes/Bonitur";
import Image from "./CommentTypes/Image";
import FieldMark from "./CommentTypes/FieldMark";
import File from "./CommentTypes/File";
import Text from "./CommentTypes/Text";
import TextWithUrls from "./CommentTypes/TextWithUrls";
import Icon from "../Common/Icon";
import ImageViewer from "../Common/ImageViewer";
import AvatarName from "../Users/AvatarName";
import type { Comment as CommentType } from "../../flowTypes";
import * as constants from "../../styles/style";
import * as commentUtils from "../../utils/Comment";
import I18n from "../../language/i18n";
import { classes } from "../../utils/dom";

type Props = {
  comment: CommentType;
  displayMenu?: boolean;
  onClick?: (...args: Array<any>) => any;
  activeCrops?: any;
};

type State = {
  imageViewerOpen: boolean;
};

const imageSize = { height: 300 };

export default class Comment extends React.PureComponent<Props, State> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  static defaultProps = {
    displayMenu: true,
  };

  state = {
    imageViewerOpen: false,
  };

  getAuthor(comment: CommentType) {
    let name = comment.created_by.email;

    if (comment.created_by.name) {
      name = comment.created_by.name;
    }

    return (
      <AvatarName
        uid={comment.created_by.uid}
        groupId={
          (comment.created_by as any).group
            ? (comment.created_by as any).group
            : COMPANY_MAIN_GROUP_KEY
        }
        name={name}
      />
    );
  }

  render() {
    const { comment, displayMenu, onClick } = this.props;

    if (!displayMenu && onClick) {
      return (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (onClick) onClick(comment);
          }}>
          {this.renderComment()}
        </div>
      );
    }

    return this.renderComment();
  }

  renderComment() {
    const { displayMenu } = this.props;
    const comment = this.props.comment;
    const cropSownOn = get(comment, "cropMeta.sown_on", null);
    const styles = constants.styles;

    if (!comment) {
      return null;
    }

    const author = this.getAuthor(comment);
    const synced = {
      color: constants.FJNEWGREEN,
      marginLeft: 2,
      fontSize: 20,
      marginBottom: -5,
      backgroundColor: "transparent",
    };
    if (!comment.created) {
      synced.color = constants.FJMUTEDLIGHT;
    }

    const cropType =
      commentUtils.typeIs("crop.sown", comment.type) ||
      commentUtils.typeIs("crop.harvested", comment.type) ||
      commentUtils.typeIs("withoutSince", comment.type);

    const commentCreated = toDate(comment.created);
    return (
      <div
        className={classes(
          "comment",
          comment.type &&
            `comment-type-${comment.type}`.replace(/[^\w\d-]/g, "_"),
        )}>
        <div
          style={{
            marginTop: 10,
            padding: 10,
            borderRadius: 5,
            paddingBottom: 10,
            backgroundColor: cropType ? "transparent" : "#F1F0F5",
          }}>
          <div>{this.renderContent(comment)}</div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: -5,
            }}>
            <span
              style={{
                ...styles.muted,
                ...{
                  color: constants.FJMUTEDLIGHT,
                  backgroundColor: "transparent",
                  alignSelf: "flex-end",
                  fontSize: 12,
                },
              }}>
              {comment.created
                ? moment
                  .utc(toDate(commentCreated))
                  .local()
                  .format("HH:mm")
                : moment().format("HH:mm")}
            </span>

            <Icon name={"ios-checkmark"} style={synced} />
            <Icon
              name={"ios-checkmark"}
              style={{ ...synced, marginLeft: -2 }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 15,
            paddingTop: 5,
            marginLeft: 10,
            marginRight: 10,
          }}>
          {author}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <span
              style={{
                ...styles.muted,
                ...styles.extraSmall,
                ...{ color: constants.FJMUTEDLIGHT },
              }}>
              {comment.created
                ? moment
                  .utc(commentCreated)
                  .local()
                  .format("D.M.YYYY")
                : moment().format("D.M.YYYY")}
            </span>
            <span
              style={{
                ...{ color: constants.FJMUTEDLIGHT },
                ...styles.extraSmall,
                ...{ paddingLeft: 5, paddingRight: 5 },
                marginLeft: 5,
                marginRight: 5,
              }}>
              |
            </span>
            <span style={{ ...styles.muted, ...styles.extraSmall }}>
              {cropSownOn
                ? I18n.t("x_days", {
                  days: (comment.created
                    ? moment(toDate(commentCreated))
                    : moment()
                  ).diff(toDate(cropSownOn), "days"),
                })
                : null}
            </span>
          </div>
        </div>

        {displayMenu
          ? (
            <CommentMenu
              trigger={
                <div className="comment-menu-arrow">
                  <Icon name={"arrow_down"} iconType={"fj"} />
                </div>
              }
              comment={comment}
            />
          )
          : null}
      </div>
    );
  }

  renderContent(comment: CommentType) {
    if (commentUtils.typeIs("image", comment.type) && comment.image) {
      return (
        <React.Fragment>
          <Image
            comment={comment}
            onClick={this.openImageViewer}
            size={imageSize}
          />
          <ImageViewer
            comment={comment}
            show={this.state.imageViewerOpen}
            onHide={this.closeImageViewer}
          />
        </React.Fragment>
      );
    }

    if (commentUtils.typeIs("user.file", comment.type) && comment.file) {
      return <File comment={comment} />;
    }

    if (commentUtils.typeIs("crop", comment.type)) {
      return <Crop comment={comment} />;
    }

    if (commentUtils.typeIs("fertilizing", comment.type)) {
      return <Fertilizing comment={comment} />;
    }

    if (commentUtils.typeIs("wait_time", comment.type)) {
      return <WaitTime comment={comment} />;
    }

    if (commentUtils.typeIs("analysis", comment.type)) {
      return <Analysis comment={comment} />;
    }

    if (commentUtils.typeIs("field_mark", comment.type)) {
      return <FieldMark comment={comment} />;
    } else if (commentUtils.typeIs("field", comment.type)) {
      return <Field comment={comment} />;
    }

    if (commentUtils.typeIs("bonitur", comment.type)) {
      return <Bonitur comment={comment} />;
    }

    const commentText = commentUtils.getCommentText(comment);

    if (commentUtils.URL_IN_COMMENT_REGEX.test(commentText)) {
      return <TextWithUrls text={commentText} />;
    }

    return <Text text={commentText} />;
  }

  openImageViewer = () => {
    this.setState({ imageViewerOpen: true });
  };

  closeImageViewer = () => {
    this.setState({ imageViewerOpen: false });
  };
}
