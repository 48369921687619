import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";

import Icon from "./Icon";
import { styles } from "../../styles/style";
import withRouter from "../Router/withRouter";

type Props = {
  fieldName: string;
  isSharedField?: boolean;
  openCompany?: string;
  history?: any;
  fieldKey?: string;
};

const titleStyle = {
  ...styles.strong,
  ...styles.small,
  flex: 1,
  whiteSpace: "pre-line" as "pre-line",
  maxWidth: "220px",
};

const FieldNameComponent = ({
  fieldName,
  isSharedField,
}: Props) => {

  const fieldTitle = () => {
    return (
      <div style={titleStyle} key="fieldTitle">
        {isSharedField ? (
          <Icon
            name={"md-share"}
            iconType={"ion"}
            light
            style={{ fontSize: 12, marginRight: 5 }}
          />
        ) : null}
        <span style={{ ...styles.small }} key={0}>
          {fieldName}
        </span>
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {[fieldTitle()]}
    </div>
  );
};

const selector = (state) => {
  const openCompany = getOpenCompanyId(state);

  return {
    openCompany,
  };
};

export const FieldName = compose<typeof FieldNameComponent>(
  connect(selector),
  withRouter,
)(FieldNameComponent);
