import React from "react";
import { classes } from "../../utils/dom";
import type { DataCellProps } from "./flow";

export default class DataCell extends React.PureComponent<DataCellProps> {
  div: any = React.createRef();

  componentDidMount() {
    this.updateTitleAttribute();
  }

  componentDidUpdate() {
    this.updateTitleAttribute();
  }

  updateTitleAttribute() {
    const el = this.div.current;
    if (el) {
      const text = this.getTextContent();
      if (text != null) {
        el.setAttribute("title", text);
      }
    }
  }

  render() {
    const {
      column,
      columnClassName,
      columnIndex,
      additionalProps,
      onClick,
      style,
      openId,
      row,
    } = this.props;

    const value = (column.accessor as any)(row);
    const selected = openId === row.key;

    return (
      <div
        className={classes(
          "cell",
          columnClassName,
          columnIndex === 0 && "cell-column-0",
          column.fixed && "fixed",
          selected && "selected",
        )}
        style={style as any}
        onClick={onClick && onClick.bind(null, row)}
        ref={this.div}>
        {column.Cell
          ? (
            column.Cell(value, row, additionalProps)
          )
          : (
            <React.Fragment>{value}</React.Fragment>
          )}
      </div>
    );
  }

  getText() {
    const { column, row, additionalProps } = this.props;
    let text: any = null;

    if ((column as any).getDataText) {
      const value = (column.accessor as any)(row);
      text = (column as any).getDataText(value, row, additionalProps);
    } else {
      text = this.getTextContent();
    }
    return text;
  }

  getTextContent() {
    const el = this.div.current;

    if (el) {
      return el.textContent;
    }
    return null;
  }
}
