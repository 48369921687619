import * as React from 'react';
import Box from '../Common/Box/Box';

import CostBox from '../Common/CostBox';

type Props = {
  /**
   * The total price of the analysis
   */
  netPrice: number;

  /**
   * The VAT in percent
   */
  vat: number;

  /**
   * The calculated VAT sum
   */
  vatSum: number;

  /**
   * the groß price
   */
  grossPrice: number;

  /**
   * Currency symbol
   */
  currency: string;
};

class Cost extends React.PureComponent<Props> {
  render() {
    const { netPrice } = this.props;

    if (netPrice === 0) {
      return (
        <Box
          content={
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <span>Sie haben noch keine Untersuchung ausgewählt.</span>
            </div>
          }
          header={'Kosten'}
        />
      );
    }

    return <CostBox {...this.props} />;
  }
}

export default Cost;
