import * as React from 'react';
import Modal from 'react-bootstrap-modal';
import FormSelectLab from './FormSelectLab';
import FormFieldSelect from './FormFieldSelect';
import FormSelectResidueType from './FormSelectResidueType';
import CreateAnalysisForm from './Form';
import SelectInvoiceAddress from './SelectInvoiceAddress';
import { bindActionCreators, compose } from 'redux';
import * as labActions from 'farmerjoe-common/lib/actions/labs';
import * as analysisActions from 'farmerjoe-common/lib/actions/analysis';
import { connect } from 'react-redux';
import FormSelectWarehouse from './FormSelectWarehouse';
import './style.css';
import { classes } from '../../utils/dom';
import OrderSuccess from './OrderSuccess';
import type { Analysis } from '../../flowTypes';
import I18n from '../../language/i18n';
import FormFieldMultiSelect from './FormFieldMultiSelect';
import MultiAnalysisForm from './MultiForm';
import OrderSuccessMultiple from './OrderSuccessMultiple';

// prevent the clicks from bubbling outside of the Dialog, this causes problems
const stopPropagation = e => e.stopPropagation();

type Props = {
  show: boolean;
  actions: Record<string, any>;
  onCancel: (...args: Array<any>) => any;
  onCloseAfterCreatingAnalysis: (...args: Array<any>) => any;
};
type State = {
  history: Array<Record<string, any>>;
  analysis?: Analysis | null;
};

class NewAnalysisDialog extends React.Component<Props, State> {
  constructor(props: Props, context?) {
    super(props, context);

    this.state = {
      history: [this.makeViewObject('FormSelectLab')],
      analysis: null,
    };
  }

  componentWillUnmount() {
    this.props.actions.selectedLab(null);
    this.props.actions.selectedOffer(null);
    this.props.actions.analysisFromField(false);
    this.props.actions.analysisField(null);
    this.props.actions.analysisWarehouse(null);
  }

  makeViewObject(view: string, props?: any) {
    const newView = {
      view,
      props: {
        ...props,
        goBack: this.goBack.bind(this),
        openView: this.openView.bind(this),
        saveState: state => (newView.props.state = state),
      },
    };
    return newView;
  }

  openView(view: string, props: any) {
    this.setState({
      history: [...this.state.history, this.makeViewObject(view, props)],
    });
  }

  goBack(props: Record<string, any> | null | undefined) {
    const history = this.state.history.slice(0, -1);

    if (props) {
      const previousView = history[history.length - 1];
      previousView.props = { ...previousView.props, ...props };
    }

    this.setState({
      history,
    });
  }

  getCurrentView() {
    const currentView = this.state.history[this.state.history.length - 1];
    const { view, props } = currentView;
    let config: any = null;

    switch (view) {
      case 'FormSelectLab':
        config = {
          el: <FormSelectLab {...props} />,
          title: I18n.t('analysis.analysis'),
        };
        break;
      case 'FormFieldSelect':
        config = {
          el: <FormFieldSelect {...props} />,
          title: I18n.t('select_field'),
        };
        break;
      case 'FormFieldMultiSelect':
        config = {
          el: <FormFieldMultiSelect {...props}
          selected={{}}
          />,
          title: I18n.t('select_field'),
        };
        break;
      case 'FormSelectWarehouse':
        config = {
          el: <FormSelectWarehouse {...props} />,
          title: null,
        };
        break;
      case 'FormSelectResidueType':
        config = {
          el: <FormSelectResidueType {...props} />,
          title: I18n.t('crop.origin'),
        };
        break;
      case 'CreateAnalysisForm':
        config = {
          el: (
            <CreateAnalysisForm
              {...props}
              onCreatedAnalysis={analysis => {
                this.setState({ analysis });
                this.openView('OrderSuccess', {
                  analysis,
                });
              }}
              onCancel={this.close.bind(this)}
            />
          ),
        };
        break;
      case 'CreateAnalysisFormMulti':
        config = {
          el: (
            <MultiAnalysisForm
              {...props}
              onCreatedAnalysis={analysis => {
                this.setState({ analysis });
                this.openView('OrderSuccessMultiple', {
                  analysis,
                });
              }}
              onCancel={this.close.bind(this)}
            />
          ),
        };
        break;
      case 'SelectInvoiceAddress':
        config = {
          el: <SelectInvoiceAddress {...props} />,
          title: null,
        };
        break;
      case 'OrderSuccess':
        config = {
          el: <OrderSuccess {...props} />,
          title: '',
        };
        break;
      case 'OrderSuccessMultiple':
        config = {
          el: <OrderSuccessMultiple {...props} />,
          title: '',
        };
        break;
      default:
        throw new Error(`view ${view} is not defined`);
    }
    // $FlowFixMe
    config.view = view;

    return config;
  }

  render() {
    const { show } = this.props;
    // $FlowFixMe
    const { title, el, view } = this.getCurrentView();

    return (
      <Modal
        show={show}
        dialogClassName={classes(
          'new-analysis-dialog',
          (view === 'CreateAnalysisForm' || view === 'CreateAnalysisFormMulti') && 'form',
        )}
        onHide={this.close.bind(this)}
        enforceFocus={false}>
        {title != null
          ? (
          <Modal.Header closeButton={false}>
            <Modal.Title onClick={stopPropagation}>{title}</Modal.Title>
            <button className="close" onClick={this.close.bind(this)}>
              <i className="fa fa-times" />
            </button>
          </Modal.Header>
            )
          : null}
        {el}
      </Modal>
    );
  }

  close() {
    const { onCancel, onCloseAfterCreatingAnalysis } = this.props;
    const analysis = this.state.analysis;

    this.setState({
      history: [this.makeViewObject('FormSelectLab')],
      analysis: null,
    });

    if (analysis) {
      onCloseAfterCreatingAnalysis(analysis);
    } else {
      onCancel && onCancel(null);
    }
  }
}

export default compose(
  connect(
    state => state,
    function mapDispatchToProps(dispatch) {
      return {
        actions: bindActionCreators(
          Object.assign(
            {},
            {
              ...labActions,
              ...analysisActions,
            },
          ),
          dispatch,
        ),
      };
    },
  ),
)(NewAnalysisDialog);
