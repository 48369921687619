import * as React from 'react';
import ImageLightbox from 'react-image-lightbox';
import Icon from './Icon';
import { LoadingIcon } from '../Loading/Loading';
import FileSaver from 'file-saver';
import firebase from '../../data/firebase';
import type { Comment } from '../../flowTypes';
import { bindActionCreators, compose } from 'redux';
import { findIndex, get } from 'lodash-es';
import { connect } from 'react-redux';
import {
  clearImageViewerData,
  setImageViewerCurrentIndex,
} from 'farmerjoe-common/lib/actions/imageViewer';

type Props = {
  comment: Comment;
  comments: Comment[];
  show?: boolean;
  onHide?: () => void;
  currentIndex: number;
  originalCommentIndex: number;
  actions: {
    setImageViewerCurrentIndex: (...args: Array<any>) => any;
    clearImageViewerData: (...args: Array<any>) => any;
  };
};

type State = {
  downloadingFile: boolean;
};

const reactModalStyle = {
  overlay: {
    zIndex: 10000,
  },
};

class ImageViewer extends React.Component<Props, State> {
  state = {
    downloadingFile: false,
  };

  constructor(props, context?) {
    super(props, context);
    this.props.actions.setImageViewerCurrentIndex(null);
    this.props.actions.clearImageViewerData();
    this.getImageMetadata();
  }

  componentWillUnmount() {
    this.props.actions.setImageViewerCurrentIndex(null);
    this.props.actions.clearImageViewerData();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.show && !this.props.show) {
      this.props.actions.setImageViewerCurrentIndex(null);
      this.props.actions.clearImageViewerData();
    }
  }

  render() {
    const { comments, show, onHide } = this.props;

    if (!show) {
      return null;
    }

    const currentIndex = this.getCurrentIndex();

    return (
      <ImageLightbox
        mainSrc={comments[currentIndex].image.uri}
        nextSrc={
          comments.length > 1
            ? comments[(currentIndex + 1) % comments.length].image.uri
            : (void 0)
        }
        prevSrc={
          comments.length > 1
            ? comments[(currentIndex + comments.length - 1) % comments.length]
              .image.uri
            : (void 0)
        }
        onCloseRequest={onHide as any}
        onImageLoad={() => {
          this.getImageMetadata();
        }}
        reactModalStyle={reactModalStyle}
        toolbarButtons={[
          !this.state.downloadingFile
            ? (
            <a
              className="download-button ril__toolbarItemChild"
              onClick={this.download}>
              <Icon iconType="fal" name="download" />
            </a>
              )
            : (
            <LoadingIcon style={{ height: 35 }} />
              ),
        ]}
        onMovePrevRequest={this.prevImage}
        onMoveNextRequest={this.nextImage}
      />
    );
  }

  async getImageMetadata() {
    const { comment } = this.props;
    const imageRef = get(comment, 'extraData.image.ref');
    if (imageRef) {
      const metaRef = firebase.storage().ref().child(imageRef.path);
      metaRef.getMetadata()
        .then((metadata) => {
          if (metadata.customMetadata && metadata.customMetadata.rotation) {
            const image = document.querySelectorAll<HTMLElement>('.ril-image-current')[0];
            if (image) {
              if (metadata.customMetadata.rotation) {
                const { transform } = image.style;
                image.style.transform = transform.concat(`rotate(${metadata.customMetadata.rotation}deg)`);
              }
            };
          }
        });
    }
  }

  prevImage = () => {
    const {
      currentIndex,
      originalCommentIndex,
      comments,
      actions: { setImageViewerCurrentIndex },
    } = this.props;
    let newCurrentIndex = currentIndex - 1;
    if (newCurrentIndex + originalCommentIndex < 0) {
      newCurrentIndex = comments.length - originalCommentIndex - 1;
    }
    setImageViewerCurrentIndex(newCurrentIndex);
  };

  nextImage = () => {
    const {
      currentIndex,
      originalCommentIndex,
      comments,
      actions: { setImageViewerCurrentIndex },
    } = this.props;
    let newCurrentIndex = currentIndex + 1;
    if (newCurrentIndex + originalCommentIndex >= comments.length) {
      newCurrentIndex = -originalCommentIndex;
    }
    setImageViewerCurrentIndex(newCurrentIndex);
  };

  getCurrentIndex() {
    return this.props.currentIndex + this.props.originalCommentIndex;
  }

  download = () => {
    const { comment } = this.props;
    const uri = comment.image.uri;

    let fileName: string;
    try {
      fileName = firebase.storage().refFromURL(uri).name;
    } catch (e) {
      console.log('Unable to get filename! Setting default filename to `image.jpg`');
      fileName = 'image.jpg';
    }

    this.setState({ downloadingFile: true });

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      this.setState({ downloadingFile: false });

      const blob = xhr.response;
      FileSaver.saveAs(blob, fileName);
    };
    xhr.onerror = () => {
      this.setState({ downloadingFile: false });
    };
    xhr.open('GET', uri);
    xhr.send();
  };
}

export default compose(
  connect(
    (state, ownProps: any) => {
      const comment = ownProps.comment;

      let comments;
      let originalCommentIndex;

      if (ownProps.comments && ownProps.comments.length) {
        comments = ownProps.comments;
        originalCommentIndex = findIndex(comments, c => c.key === comment.key);
      } else {
        comments = [comment];
        originalCommentIndex = 0;
      }

      return {
        comment,
        comments,
        originalCommentIndex,
        currentIndex: get(state, 'imageViewer.currentIndex') || 0,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          setImageViewerCurrentIndex,
          clearImageViewerData,
        },
        dispatch,
      ),
    }),
  ),
)(ImageViewer);
