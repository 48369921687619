import React from 'react';
import IconHeader from '../Common/IconHeader';
import * as constants from '../../styles/style';

// TODO: improve typings
type Props = any;
type State = any;

export default class AnalysisSectionHeader extends React.PureComponent<Props> {
  render() {
    const { sectionId } = this.props;

    return (
      <IconHeader
        icon="analysis"
        iconType={'fj'}
        iconStyle={{
          fontSize: 10,
          marginRight: 5,
          lineHeight: '15px',
          color: constants.FJMUTED,
        }}
        text={sectionId}
        key={sectionId}
        containerStyle={{
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: '#FFF',
        }}
      />
    );
  }
}
