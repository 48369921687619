import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import moment from "moment";
import { get, clone } from "lodash-es";
import { Polygon } from "@react-google-maps/api";
import t from "tcomb-form";

import {
  openField,
  updateField,
  editField,
} from "farmerjoe-common/lib/actions/field";
import { deleteCrop, cancelCrop } from "farmerjoe-common/lib/actions/crop";
import { addComment } from "farmerjoe-common/lib/actions/comment";
import { getNoCrop } from "farmerjoe-common/lib/actions/actionConstants";
import * as fieldSharingActions from "farmerjoe-common/lib/actions/fieldSharing";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { getFieldMarkers } from "farmerjoe-common/lib/selectors/markers";
import { getCompany } from "farmerjoe-common/lib/selectors/companies";
import {
  getActiveFields,
  isFieldCollaborator,
  getViewForField,
} from "farmerjoe-common/lib/selectors/fields";
import { getCropByFieldId, getCrop } from "farmerjoe-common/lib/selectors/crops";
import {
  getBrowsingGroupKey,
  getGroup,
} from "farmerjoe-common/lib/selectors/groups";
import { getFeature } from "farmerjoe-common/lib/selectors/features";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { getFavorites } from "farmerjoe-common/lib/selectors/favorites";
import { canDo, isAdmin, UserObj } from "farmerjoe-common/lib/utils/User";
import {
  isFieldOwner,
  hasCollaborators,
} from "farmerjoe-common/lib/utils/Field";
import { isNotMainGroup } from "farmerjoe-common/lib/utils/firestoreRedux/Utils";
import { getColor } from "farmerjoe-common/lib/utils/Colors";
import {
  LatLng,
  NotACropState,
  CropState,
  FieldState,
  UserRole,
} from "farmerjoe-common/lib/flow/types";
import { getUserPermissionsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/UsersPermissions";
import { getFavoritesQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Favorites";
import { getCompanyQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Companies";

import CropArea from "./CropArea";
import Icon from "../Common/Icon";
import DeleteButton from "../Common/DeleteButton";
import Favorites from "../Common/Favorites";
import ActiveCropsDropDown from "../Crop/ActiveCropsDropDown";
import withRouter from "../Router/withRouter";
import Dialog, { AlertConfirmDialog, AlertDialog } from "../Dialog/Dialog";
import ModalMap from "../Map/ModalMap";
import PinPositionMarker from "../Map/PinPositionMarker";
import PolygonAndMarkerPlacementMapDialog from "../Map/PolygonAndMarkerPlacementMapDialog";
import { fieldPolygonOptions } from "../Map/Map";

import FieldModel from "../../tcomb/models/field";
import CropModel from "../../tcomb/models/crop";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import { dummy } from "../../utils/Comment";
import { appPosToLatLng, getBounds, isValidPosition } from "../../utils/Map";
import { numberTransformer } from "../../tcomb/transformers/transformers";
import type {
  Company,
  Crop,
  Field,
  Marker,
  Profile,
  User,
  Marker as MarkerType,
} from "../../flowTypes";
import fbase from "firebase/app";

import FieldCollaboratorsList from "./FieldCollaboratorsList";
import PrecropsFromField from "./Precrops";

const Form = t.form.Form;
const styles = constants.styles;

const box = {
  ...styles.box,
  paddingLeft: 0,
  paddingRight: 0,
};

const boxHeader = {
  ...styles.boxHeader,
  marginLeft: -15,
  marginRight: -15,
  backgroundColor: constants.FJBACKGROUND,
};

const DIALOG_CONFIRM_RESULT = "yes";

type CropWithArea = Crop & {
  markedArea: {
  polygon: any[];
  center: {
    latitude: number;
    longitude: number;
  } | null;
  areaSize?: number;
  };
} & {
  precrops?: string[];
};

type Props = {
  field?: Field & {
    precrops?: any[]; // XXX: Precrop is being rendered under the crop model, but it belongs to the field
  };
  crop?: CropWithArea & {
    precrops?: any[]; // XXX: Precrop is being rendered under the crop model, but it belongs to the field
  };
  company?: Company;
  onClose: (type?: string, id?: string) => any;
  show: boolean;
  editMode?: boolean;
  myCompanyProfile?: Profile;
  auth?: User;
  firebase?: any;
  browsingAsGroup?: string;
  actions?: {
    editField: typeof editField;
    openField: typeof openField;
    updateField: typeof updateField;
    deleteCrop: typeof deleteCrop;
    cancelCrop: typeof cancelCrop;
    addComment: typeof addComment;
    stopFieldSharing: typeof fieldSharingActions.stopFieldSharing;
  };
  producersOn?: boolean;
  tradersOn?: boolean;
  markers?: Marker[];
  history?: any;
  fieldValues?: {
    position: LatLng;
    polygon: LatLng[];
    areaSize: number;
  };
  isCollaborator?: boolean;
  isOwnerOfField?: boolean;
  anyCollaboratorsOnField?: boolean;
  favorites?: any;
  openCompany?: string;
  collaborators?: any[];
};

type State = {
  field: Field;
  crop?: CropWithArea;
  alertMessage: null | string;
  alertTitle: null | string;
  showGoBackDialog: boolean;
  modalVisible: boolean;
  collaborators?: any[];
  showMapAreaDialog: boolean;
  precropsToDelete: any[];
};

class CreateField extends React.Component<Props, State> {
  stateChanged: boolean = false;
  fieldFormRef: any;
  cropFormRef: any;

  constructor(props, context) {
    super(props, context);
    this.state = {
      field: {
        name: "",
        size: null,
        land_id: null,
        position: {
          latitude: null,
          longitude: null,
        },
        group_id: props.browsingAsGroup,
        state: FieldState.Active,
        ...this.props.field,
        company_id: this.props.company?.key,
        ...this.props.fieldValues,
      } as any,
      crop: {
        ...clone(this.props.crop),
        precrops: [],
      },
      alertMessage: null,
      alertTitle: null,
      showGoBackDialog: false,
      modalVisible: false,
      collaborators: this.props.collaborators,
      showMapAreaDialog: false,
      precropsToDelete: [],
    };
    this.fieldFormRef = React.createRef();
    this.cropFormRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.actions?.editField(null as any);
  }

  /**
   * If we happen to delete the active crop from the field we'll get new props
   * We have to update our state, otherwise saving would still point to the old
   * activeCrop
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.field &&
      get(this.props, "field.activeCrop.key") !==
        get(nextProps.field, "activeCrop.key")
    ) {
      this.setState({
        field: {
          ...this.state.field,
          ...nextProps.field,
        },
      });
    }
  }

  goBack() {
    if (this.stateChanged) {
      this.setState({ showGoBackDialog: true });
    } else {
      this.reallyGoBack();
    }
  }

  reallyGoBack() {
    this.props.onClose();
  }

  onSave() {
    const fieldValue = this.getFieldValue();
    if (!fieldValue) {
      return;
    }
    const { editMode } = this.props;

    return editMode ? this.updateField(fieldValue) : this.createField(fieldValue);
  }

  createField(fieldValue) {
    const { firebase, company, auth } = this.props;
    const db = firebase.firestore();

    const fieldRef = db.collection("fields").doc();
    const cropRef = db.collection("crops").doc();
    const commentRef = db.collection("comments").doc();

    const activeCrop = {
      ...getNoCrop(cropRef.id, auth, fieldValue.group_id),
      field_id: fieldRef.id,
      company_id: company?.key,
    };

    const newField: any = {
      ...fieldValue,
      state: FieldState.Active,
      activeCrop: activeCrop,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      created_by: UserObj(auth as any),
      key: fieldRef.id,
    };

    const comment = {
      ...dummy(
        commentRef.id,
        company?.key as any,
        newField,
        activeCrop,
        auth as any,
        "system.field",
      ),
      text: { languageConstant: "field.created" },
    };

    const batch = db.batch();
    batch.set(fieldRef, newField);
    batch.set(cropRef, activeCrop);
    batch.set(commentRef, comment);
    batch.commit();

    this.props.actions?.openField(fieldRef.id);
    this.props.history.push(`/company/${company?.key}/field/${fieldRef.id}`);
    this.props.onClose("saved", fieldRef.id);
  }

  updateField(fieldValue) {
    const { field, isOwnerOfField } = this.props;
    if (!field) {
      return;
    }

    return isOwnerOfField
      ? this.updateFieldAsOwner(fieldValue)
      : this.updateFieldAsCollaborator(fieldValue);
  }

  updateFieldAsOwner(fieldValue) {
    const { field } = this.props;
    const db = this.props.firebase.firestore();
    const batch = db.batch();

    // Case 1: field with no crop
    if (
      typeof this.state.crop !== "undefined" &&
      this.state.crop.not_a_crop === NotACropState.NotACrop &&
      this.state.crop.state === CropState.Planted
    ) {
      this.updateFieldWithNoCrop(fieldValue, batch);
    }

    // Case 2: field with planted crop
    if (
      typeof this.state.crop !== "undefined" &&
      this.state.crop.not_a_crop === NotACropState.HarvestedCrop &&
      this.state.crop.state === CropState.Planted
    ) {
      this.updateFieldWithPlantedCrop(fieldValue, batch);
    }

    // Case 3: field with future crop
    if (
      typeof this.state.crop !== "undefined" &&
      this.state.crop.not_a_crop === NotACropState.PlannedCrop &&
      this.state.crop.state === CropState.Planted
    ) {
      this.updateFieldWithPlantedCrop(fieldValue, batch);
    }

    // Case 4: field with harvested crop
    if (
      typeof this.state.crop !== "undefined" &&
      this.state.crop.not_a_crop === NotACropState.HarvestedCrop &&
      this.state.crop.state === CropState.Harvested
    ) {
      this.updateFieldWithHarvestedCrop(fieldValue, batch);
    }

    // If we have set any new favorite crop, store it.
    this.saveFavoriteCrop(fieldValue, batch);

    batch.commit();
    this.props.onClose("saved", field?.key);
  }

  updateFieldAsCollaborator(fieldValue) {
    const { openCompany, field, firebase } = this.props;
    if (!field || !this.state.crop) {
      return;
    }

    const { name, land_id, notes, polygon, position, size, areaSize, irrigation, waterOrigin } = fieldValue;
    let activeCrop;

    const formValues = this.getCropValueFromForm();
    const { precrops, ...cropValue } = formValues || {};

    if (cropValue) {
      const { art: cropArt, color: cropColor, name: cropName } = cropValue;
      activeCrop = {
        art: cropArt,
        color: cropColor,
        name: cropName,
      };
    }

    const db = this.props.firebase.firestore();
    const batch = db.batch();
    const fieldRef = db.collection("fields").doc(field.key);
    const fieldViewsRef = db.collection("fieldViews").doc(openCompany);
    // update the field view
    batch.set(
      fieldViewsRef,
      {
        [field.key]: {
          name,
          notes,
          land_id,
          activeCrops: {
            [this.state.crop.key]: {
              ...activeCrop,
            },
          },
        },
      },
      { merge: true },
    );

    // update the field without the crop
    const { activeCrop: _activeCrop, ...fieldWithoutCrop } = field;
    const updatedField = {
      ...fieldWithoutCrop,
      name,
      polygon,
      position,
      size,
      areaSize,
      irrigation,
      waterOrigin,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };
    batch.update(fieldRef, updatedField);

    batch.commit();
    this.props.onClose("saved", field?.key);
  }

  getFieldValueFromForm() {
    const form = this.fieldFormRef.current;
    if (!form) {
      return;
    }

    const formValues = form.getValue();

    // If value is null, then the form has errors
    if (formValues === null) {
      this.setState({
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    // structs come out as Struct custom objects which firestore doesn't like so turn them into plain objects
    return JSON.parse(JSON.stringify(formValues));
  }

  getFieldValue() {
    const { myCompanyProfile } = this.props;

    const canEditField =
      isAdmin(myCompanyProfile as any) ||
      canDo("edit", "field", myCompanyProfile as any);

    return canEditField ? this.getFieldValueFromForm() : this.state.field;
  }

  getCropValueFromForm() {
    const form = this.cropFormRef.current;
    if (!form) { // we don't have any crop yet
      return;
    }

    const cropValue = form.getValue();

    // If value is null, then the form has errors
    if (cropValue === null) {
      this.setState({
        alertMessage: I18n.t("please_correct_your_entry"),
        alertTitle: I18n.t("error"),
      });
      return;
    }

    // structs come out as Struct custom objects which firestore doesn't like so turn them into plain objects
    return JSON.parse(JSON.stringify(cropValue));
  }

  updateFieldWithNoCrop(fieldValue, batch) {
    const { field } = this.props;
    if (!field) {
      return;
    }


    const updatedField = {
      ...field,
      ...fieldValue,
    };

    const db = this.props.firebase.firestore();
    const fieldRef = db.collection("fields").doc(field.key);
    batch.update(fieldRef, updatedField);
  }

  updateFieldWithPlantedCrop(fieldValue, batch) {
    const { field, firebase } = this.props;
    if (!field) {
      return;
    }

    const formValues = this.getCropValueFromForm();

    // XXX: We need to extract
    // precrops from the state and the form
    // so we can store it in the field...
    const {precrops: precropsFromForm, ...cropValue} = formValues || {};
    const { precrops, ...stateCrop } = this.state.crop || {};

    let updatedCrop = {
      ...stateCrop,
      ...cropValue,
      company_id: field.company_id,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };
    
    const markedArea = get(this.state, "crop.markedArea", {
      polygon: [],
      center: null,
      areaSize: 0,
    });

    if (!markedArea.polygon.length) {
      updatedCrop = {
        ...updatedCrop,
        markedArea: firebase.firestore.FieldValue.delete(),
      };
    }

    const updatedField = {
      ...field,
      ...fieldValue,
      activeCrop: updatedCrop,
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const db = this.props.firebase.firestore();
    const fieldRef = db.collection("fields").doc(field.key);
    batch.set(fieldRef, updatedField, { merge: true });

    if (precropsFromForm) {
      const { extraPrecrops } = precropsFromForm;
      extraPrecrops.forEach(p => {
        batch.set(fieldRef, {
          precrops: firebase.firestore.FieldValue.arrayUnion(p),
        }, { merge: true});
      });
    }

    if (this.state.precropsToDelete.length) {
      this.state.precropsToDelete.forEach(p => {
        batch.set(fieldRef, {
          precrops: firebase.firestore.FieldValue.arrayRemove(p),
        }, { merge: true});
      });
    }

    const cropRef = db.collection("crops").doc(updatedCrop.key);
    batch.set(cropRef, updatedCrop, { merge: true });
  }

  updateFieldWithHarvestedCrop(fieldValue, batch) {
    const { field, firebase } = this.props;
    if (!field) {
      return;
    }

    const formValues = this.getCropValueFromForm();
    const {precrops: precropsFromForm, ...cropValue} = formValues || {};
    const { precrops, ...stateCrop } = this.state.crop || {};
    const { activeCrop, ...fieldOfHarvestedCrop } = field; // Ignore activeCrop. We care only about the rest.

    const updatedCrop = {
      ...stateCrop,
      ...cropValue,
      field: {
        ...fieldOfHarvestedCrop,
        ...fieldValue,
      },
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const db = this.props.firebase.firestore();
    const cropRef = db.collection("crops").doc(updatedCrop.key);
    batch.update(cropRef, updatedCrop);
  }

  saveFavoriteCrop(fieldValue, batch) {
    const formValues = this.getCropValueFromForm();
    const { precrops, ...cropValue } = formValues || {};
    if(!cropValue || !cropValue.favorite) {
      return;
    }

    const db = this.props.firebase.firestore();
    const favoriteRef = db.collection("favorites").doc();

    const favorite = {
      name: cropValue.name + (cropValue.art ? ` - ${cropValue.art}` : ""),
      replace: {
        name: cropValue.name,
        color: cropValue.color,
        art: cropValue.art,
      },
      company_id: fieldValue.company_id,
      type: "crops",
      key: favoriteRef.id,
      created: fbase.firestore.FieldValue.serverTimestamp(),
    };
    batch.set(favoriteRef, favorite);
  }

  onChange(type, value) {
    this.setState({
      ...this.state,
      [type]: value,
    });

    this.stateChanged = true;
  }

  renderCreateFieldForm() {
    const { browsingAsGroup, myCompanyProfile, isOwnerOfField } =
      this.props;

    const canCreateField = canDo("create", "field", myCompanyProfile as any);
    const canEditField = canDo("edit", "field", myCompanyProfile as any);

    const isStandardUser = [UserRole.Standard, UserRole.Advisor].includes(
      myCompanyProfile?.role as any,
    );

    const hideGroupIdSelection = this.props.producersOn === false ||
                               isStandardUser ||
                               (this.props.producersOn &&
                                isNotMainGroup(browsingAsGroup as any));

    const hideTraderIdSelection =
                          this.props.tradersOn === false ||
                          isStandardUser ||
                          (this.props.tradersOn &&
                           isNotMainGroup(browsingAsGroup as any));

    if (!(canEditField || (canCreateField && !this.props.editMode))) {
      return null;
    }

    return (
      <div>
        <div style={{ ...boxHeader, marginTop: -15 }}>
          {I18n.t("field.field")}
        </div>
        <div style={box}>
          <Form
            ref={this.fieldFormRef}
            type={FieldModel.model()}
            options={() =>
              FieldModel.options({
                company: this.props.company,
                field: this.state.field,
                markers: this.props.markers,
                hideGroupIdSelection,
                hideTraderIdSelection,
                PolygonComponent: this.renderPolygonComponent(),
                isOwnerOfField,
              })
            }
            value={this.state.field}
            onChange={this.onChange.bind(this, "field")}
          />
          {isOwnerOfField ? this.renderArchiveButton() : null}
        </div>
      </div>
    );
  }

  renderArchiveButton() {
    const { editMode, myCompanyProfile } = this.props;
    const canEditField = canDo("edit", "field", myCompanyProfile as any);

    if (!(canEditField && editMode && this.state.field.state !== FieldState.Archived)) {
      return null;
    }

    return (
      <div className="text-center" style={{ marginTop: "15px" }}>
        <DeleteButton
          onDelete={() => {
            const field = {
              ...this.props.field,
              modified: moment().toDate(),
              modified_by: UserObj(this.props?.auth as any),
              state: FieldState.Archived,
            };

            this.props.actions?.updateField(field as any);

            // Add comment
            const comment = {
              ...dummy(
                null as any,
                field.company_id as any,
                field as any,
                field.activeCrop,
                this.props.auth as any,
                "system.archive",
              ),
              text: { languageConstant: "field.movedToArchive" },
            };

            this.props.actions?.addComment(comment);
            this.goBack();
          }}
          buttonText={I18n.t("field.sendToArchive")}
          alertTitle={I18n.t("field.sendToArchive")}
          alertMessage={I18n.t("field.moveToArchive")}
        />
      </div>
    );
  }

  renderCropForm() {
    const { myCompanyProfile, isOwnerOfField } = this.props;
    const canDeleteCrop = canDo("delete", "crop", myCompanyProfile as any);

    if (!this.state.crop) {
      return;
    }
    // @ts-ignore
    const { precrops, ...crop } = this.state.crop;

    if (!Object.keys(crop).length) {
      return null;
    }

    if (!(crop && crop.not_a_crop !== NotACropState.NotACrop)) {
      return null;
    }

    return (
      <div>
        <div style={boxHeader}>
          {crop.not_a_crop === NotACropState.PlannedCrop ? (
            <Icon
              style={{
                marginLeft: 0,
                marginRight: 10,
                paddingTop: 0,
                fontSize: 14,
              }}
              name="time"
              iconType={"fj"}
            />
          ) : null}
          {crop.not_a_crop === NotACropState.HarvestedCrop ? (
            <Icon
              style={{
                marginLeft: 0,
                marginRight: 10,
                paddingTop: 0,
                fontSize: 15,
              }}
              name="leaf"
              iconType={"fj"}
            />
          ) : null}
          {crop.not_a_crop === NotACropState.PlannedCrop
            ? I18n.t("crop.plannedSingle")
            : I18n.t("crop.crop")}
        </div>

        <div
          className="d-flex flex-row justify-content-center crop-fast-selection"
          style={{marginTop: "1em"}}
        >
          {this.renderFavorites()}

          <div className={"ml-2"}>
            <ActiveCropsDropDown
              onClick={rowData => {
                // @ts-ignore
                const { precrops, ...stateCrop } = this.state.crop;
                this.setState({
                  crop: {
                    ...stateCrop,
                    name: rowData.name,
                    color: rowData.color,
                    art: rowData.art,
                  },
                });
              }}
            />
          </div>
        </div>

        <div style={box}>
          <Form
            ref={this.cropFormRef}
            type={
              crop?.not_a_crop === NotACropState.PlannedCrop
                ? CropModel.planModel()
                : CropModel.model()
            }
            options={() => CropModel.options({
              crop: this.state.crop,
              isOwnerOfField,
              markAreaComponent: () => {
                if (!this.state.crop) {
                  return;
                }
                const markedArea = get(this.state, "crop.markedArea", {
                  polygon: [],
                  areaSize: 0,
                  center: null,
                });
                return (
                  <CropArea 
                    markedArea={markedArea}
                    field={this.props.field}
                    showDialog={this.state.showMapAreaDialog}
                    onOpen={() => {
                      this.setState({...this.state, showMapAreaDialog: true });
                    }}
                    onClose={() => {
                      this.setState({...this.state, showMapAreaDialog: false });
                    }}
                    onSave={(value) => {
                      // @ts-ignore
                      this.setState({
                        ...this.state,
                        ...{
                          ...{
                            showMapAreaDialog: false,
                          },
                          ...{
                            crop: {
                              ...this.state.crop,
                              markedArea: value,
                            },
                          },
                        },
                      });
                    }}
                  />
                );
              },
              precropsComponent: () => {
                // @ts-ignore
                const { precrops } =  this.props.field;
                const _precrops = this.state.precropsToDelete.length
                  ? [
                    ...(precrops as any).filter(p => !this.state.precropsToDelete.includes(p)),
                  ]
                  : precrops;
                return (
                  <PrecropsFromField
                    precrops={_precrops}
                    onRemoveUserCrop={(cropName) => {
                      this.setState({...this.state, precropsToDelete: [...this.state.precropsToDelete, cropName]});
                    }}/>
                );
              },
            })
            }
            value={this.state.crop}
            onChange={this.onChange.bind(this, "crop")}
          />

          <div className="text-center" style={{ marginBottom: 20 }}>
            {crop?.not_a_crop === NotACropState.PlannedCrop ? (
              <DeleteButton
                alertTitle={I18n.t("crop.cancelCultivation")}
                alertMessage={I18n.t(
                  "crop.doYouReallyWantToCancelCultivation",
                )}
                buttonText={I18n.t("crop.cancelCultivation")}
                onDelete={() => this.cancelCrop()}
              />
            ) : null}

            {crop?.not_a_crop === NotACropState.HarvestedCrop &&
             canDeleteCrop ? (
                <DeleteButton
                  alertTitle={I18n.t("crop.delete")}
                  alertMessage={I18n.t(
                    "crop.doYouReallyWantToDeleteCrop",
                  )}
                  buttonText={I18n.t("crop.deleteAndWithout")}
                  onDelete={() => this.deleteCrop()}
                />
              ) : null}
          </div>
        </div>
      </div>
    );
  }

  renderFavorites() {
    const { favorites } = this.props;

    if (!favorites) {
      return null;
    }

    return (
      <Favorites
        favorites={favorites}
        onClick={rowData => {
          // @ts-ignore
          const { precrops, ...stateCrop } = this.state.crop;
          this.setState({
            crop: {
              ...stateCrop,
              name: rowData.replace.name,
              color: rowData.replace.color,
              art: rowData.replace.art,
            },
          });
        }}
        simpleContent={false}
        renderCustomRow={rowData => (
          <div
            className="crop-favorite-row"
            style={{
              backgroundColor: getColor(rowData.replace.color),
            }}>
            <span>{rowData.name}</span>
          </div>
        )}
        getCustomRowText={rowData => rowData.name}
      />
    );
  }

  renderLeaveSharedField() {
    const { myCompanyProfile, isCollaborator } = this.props;
    const companyAdmin = isAdmin(myCompanyProfile as any);

    if (!(isCollaborator && companyAdmin)) {
      return null;
    }

    return (
      <div>
        <div style={boxHeader}>
          {I18n.t("fieldSharing.fieldOptions.sectionTitle")}
        </div>
        <div style={box}>
          <div className="text-center" style={{ marginBottom: 20 }}>
            <DeleteButton
              alertTitle={I18n.t(
                "fieldSharing.fieldOptions.leaveFieldDialogueTitle",
              )}
              alertMessage={I18n.t(
                "fieldSharing.fieldOptions.leaveFieldDialogueMessage",
              )}
              buttonText={I18n.t(
                "fieldSharing.fieldOptions.leaveFieldDialogueTitle",
              )}
              onDelete={() => this.leaveField()}
            />
          </div>
        </div>
        {this.renderFieldCollaborators()}
      </div>
    );
  }

  renderStopFieldSharing() {
    const { isOwnerOfField, anyCollaboratorsOnField } = this.props;

    if (!(isOwnerOfField && anyCollaboratorsOnField)) {
      return null;
    }

    return (
      <div>
        <div style={boxHeader}>
          {I18n.t("fieldSharing.fieldOptions.sectionTitle")}
        </div>
        <div style={box}>
          <div className="text-center" style={{ marginBottom: 20 }}>
            <DeleteButton
              alertTitle={I18n.t(
                "fieldSharing.fieldOptions.stopSharingDialogueTitle",
              )}
              alertMessage={I18n.t(
                "fieldSharing.fieldOptions.stopSharingDialogueMessage",
              )}
              buttonText={I18n.t(
                "fieldSharing.fieldOptions.stopSharingDialogueTitle",
              )}
              onDelete={() => this.stopFieldSharing()}
            />
          </div>
        </div>
        {this.renderFieldCollaborators()}
      </div>
    );
  }

  renderFieldCollaborators() {
    const { actions, field, openCompany } = this.props;
    const { collaborators } = this.state;
    if (!collaborators || !collaborators.length) {
      return null;
    }

    const onClick = (companyId) => {
      if (!actions || !field) {
        return;
      }
      const c = collaborators.filter(c => c.key !== companyId);

      this.setState({...this.state, collaborators: c });
      actions.stopFieldSharing(field.key, [companyId]);
    };

    return <FieldCollaboratorsList collaborators={collaborators} onClick={onClick} openCompany={openCompany} />;
  }

  render() {
    const { show, onClose, editMode } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Dialog
        show={show}
        onClose={onClose as any}
        title={I18n.t(editMode ? "edit" : "add")}
        footer={
          <div className="d-flex flex-grow-1">
            <button
              className="ml-auto btn btn-secondary"
              onClick={this.goBack.bind(this)}
            >
              {I18n.t("cancel")}
            </button>{" "}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}
            >
              {I18n.t("save")}
            </button>
          </div>
        }
        className="form create-field-dialog"
      >
        <div style={{ flex: 1, flexGrow: 1 }}>
          <div style={{ flex: 1, flexGrow: 1 }}>
            {this.renderCreateFieldForm()}
            {this.renderCropForm()}
            {this.renderLeaveSharedField()}
            {this.renderStopFieldSharing()}
          </div>
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
        >
          {this.state.alertMessage}
        </AlertDialog>
        <AlertConfirmDialog
          show={this.state.showGoBackDialog}
          title={I18n.t("saveChanges")}
          buttons={[
            { value: "no", label: I18n.t("no"), className: "btn-secondary" },
            {
              value: DIALOG_CONFIRM_RESULT,
              label: I18n.t("yes"),
              className: "btn-primary",
            },
          ]}
          onClose={(reason) => {
            this.setState({ showGoBackDialog: false });
            if (reason === DIALOG_CONFIRM_RESULT) {
              this.onSave();
            } else {
              this.reallyGoBack();
            }
          }}
        />
      </Dialog>
    );
  }

  renderPolygonComponent() {
    // position can have strings or numbers inside so make sure we have the number version here
    let position = this.state.field.position
      ? {
        latitude: numberTransformer.parse(
          String(this.state.field.position.latitude),
        ),
        longitude: numberTransformer.parse(
          String(this.state.field.position.longitude),
        ),
      }
      : null;
    const validPosition = isValidPosition(position);
    if (!validPosition) {
      position = null;
    }

    const company = this.props.company;
    const fieldMarkers = this.props.markers?.filter((marker) => {
      return (
        marker.activeCrop &&
        marker.activeCrop.not_a_crop !== NotACropState.NotACrop
      );
    });
    const markers: MarkerType[] = [
      // ...this.props.markers.filter(m => m.id !== fieldKey),
      {
        key: company?.key as string,
        title: company?.name as string,
        position: company?.position as any,
        type: "company",
      },
    ];

    const bounds = new (window as any).google.maps.LatLngBounds();
    if (validPosition) {
      bounds.extend(appPosToLatLng(position as any) as any);
      if (this.state.field.polygon && this.state.field.polygon.length) {
        const polygonBounds = getBounds(this.state.field.polygon);
        bounds.extend(polygonBounds.getNorthEast() as any);
        bounds.extend(polygonBounds.getSouthWest() as any);
      }
    } else {
      bounds.extend(appPosToLatLng(company?.position as any) as any);
    }

    return [
      <div
        onClick={() => this.setState({ modalVisible: true })}
        key="preview-map"
      >
        <ModalMap
          markers={markers}
          position={position}
          zoom={12}
          hideControls={true}
          containerStyle={{ height: "180px" }}
          fitBounds={bounds}
          options={{
            disableDefaultUI: true,
            gestureHandling: "none",
            zoomControl: false,
          }}
        >
          {validPosition ? (
            <PinPositionMarker position={appPosToLatLng(position as any)} />
          ) : null}
          {this.state.field.polygon ? (
            <Polygon
              path={this.state.field.polygon.map(appPosToLatLng) as any}
              key={"polygon"}
              options={fieldPolygonOptions}
            />
          ) : null}
        </ModalMap>
      </div>,
      <PolygonAndMarkerPlacementMapDialog
        key="polygonandmarkerplacement"
        polygon={this.state.field.polygon}
        markers={fieldMarkers}
        markerId={get(this.state, "field.key")}
        polygonDrawing={true}
        displayMarkers={true}
        position={position}
        title={I18n.t("field.position_it")}
        label={`${I18n.t("field.position_it")} *`}
        onPolygonChange={({ polygon, areaSize, center }) => {
          this.onChange("field", {
            ...this.state.field,
            polygon,
            areaSize,
            position: center,
          });
        }}
        fitBounds={bounds}
        modalVisible={this.state.modalVisible}
        onOpen={() => this.setState({ modalVisible: true })}
        onClose={() => this.setState({ modalVisible: false })}
      />,
    ];
  }

  cancelCrop() {
    this.props.actions?.cancelCrop(this.state.crop);

    // Reset the component state
    this.setState({ crop: undefined });
  }

  deleteCrop() {
    const { actions } = this.props;
    const { crop, field } = this.state;

    actions?.deleteCrop(crop, false, field);

    // Reset the component state
    this.setState({ crop: undefined });
  }

  leaveField() {
    const { field, company, actions, auth } = this.props;

    // XXX: F*ck the initial type implementation...
    if (!field || !company || !auth || !actions) {
      return null;
    }

    const { company_id: fieldCompanyId, activeCrop } = field;

    // Add comment
    const comment = {
      ...dummy(
        "",
        fieldCompanyId as any,
        field as any,
        activeCrop as any,
        auth,
        `system.fieldSharing.leave_field.${field.key}`,
      ),
      text: {
        languageConstant: "fieldSharing.commentCollaboratorLeft",
        data: {
          companyName: company.name,
        },
      },
    };

    return actions
      .stopFieldSharing(field.key, [company.key])
      .then(() => {
        actions.addComment(comment);
        actions.openField(null as any);
        this.props.history.push(`/company/${company?.key}/field`);
        this.props.onClose();
      })
      .catch((err) => console.error(err));
  }

  stopFieldSharing() {
    const { field, actions, auth, company } = this.props;

    // XXX: F*ck the initial type implementation...
    if (!field || !auth || !actions) {
      return null;
    }

    // Add comment
    const comment = {
      ...dummy(
        "",
        field.company_id,
        field,
        field.activeCrop,
        auth,
        `system.fieldSharing.stop_field_sharing.${field.key}`,
      ),
      text: {
        languageConstant: "fieldSharing.commentFieldSharingStopped",
      },
    };

    return actions
      .stopFieldSharing(field.key, field.collaborators as any)
      .then(() => {
        actions.addComment(comment);
        this.props.history.push(`/company/${company?.key}/field`);
      })
      .catch((err) => console.error(err));
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openField,
          updateField,
          editField,
          deleteCrop,
          cancelCrop,
          addComment,
          ...fieldSharingActions,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const fields = getActiveFields(state, openCompany, state.firebase.auth.uid);

  const field = ownProps.field;
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingAsGroup = getBrowsingGroupKey(state, openCompany);
  const currentGroup = getGroup(state, openCompany, browsingAsGroup);
  const producersOn = getFeature(state, openCompany, "producers") === true;
  const tradersOn = getFeature(state, openCompany, "traders") === true;

  const favorites = getFavorites(state, openCompany, "crops");

  const isCollaborator = field
    ? isFieldCollaborator(state, openCompany, field.company_id)
    : false;
  const isOwnerOfField = field
    ? isFieldOwner(field.company_id, openCompany)
    : true;
  const anyCollaboratorsOnField = hasCollaborators(field);

  let notModifiedCrop = field
    ? getCropByFieldId(
      state.firestore.data,
      field.company_id,
      field.key,
      field.activeCrop.key,
    )
    : undefined;

  // XXX: When editing shared field `getCropByFieldId` returns undefined
  if (!notModifiedCrop) {
    notModifiedCrop = field
      ? getCrop(
        state.firestore.data,
        field.company_id,
        field.key,
        field.activeCrop.key,
      )
      : undefined;
  }

  const fieldView = field ? getViewForField(state, openCompany, field.key) : null;
  const cropView = fieldView ? get(fieldView, `activeCrops[${field.activeCrop.key}]`, {}) : {};
  const crop = notModifiedCrop ? {...notModifiedCrop, ...cropView} : undefined;

  const collaborators = anyCollaboratorsOnField ? field.collaborators.map(c => get(state.firestore.data, `companies[${c}]`)).filter(c => c) : [];

  return {
    editMode: !!selectors.getEditFieldId(state),
    markers: getFieldMarkers(fields || []),
    auth: {
      ...state.firebase.auth,
      ...state.firebase.profile,
    },
    company,
    field,
    crop,
    browsingAsGroup,
    myCompanyProfile,
    currentGroup,
    producersOn,
    tradersOn,
    isCollaborator,
    isOwnerOfField,
    anyCollaboratorsOnField,
    favorites,
    openCompany,
    collaborators,
  };
};

export default compose<typeof CreateField>(
  connect(selector, mapDispatchToProps),
  firestoreConnect(({ auth, openCompany, browsingGroup, field }) => {
    const userId = auth.uid;
    const paths = [
      getUserPermissionsQuery(userId),
      getFavoritesQuery(openCompany, "crops", browsingGroup),
    ];
    if (hasCollaborators(field)) {
      field.collaborators.forEach(c => paths.push(getCompanyQuery(c)));
    }
    return paths;
  }),
  withRouter,
)(CreateField);

