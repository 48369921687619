import { useEffect, useState } from "react";
import { getUidToken } from "../../utils/auth";
import { getUsage } from "farmerjoe-common/lib/requests/billing";
import { TCurrentUsage } from "./types";

const useCurrentUsage = (companyId: string | undefined, forceUpdate: boolean) : [TCurrentUsage, boolean] => {
  const [ currentUsage, setCurrentUsage ] = useState<TCurrentUsage>({
    users: 0,
    producers: 0,
    totalUsers: 0,
    maxAllowedUsers: 0,
  });
  const [ apiLoading, setApiLoading ] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = () => {
      if (!companyId) {
        return;
      }
      setApiLoading(true);
      return getUidToken()
        .then((uidToken) => {
          return getUsage(uidToken, companyId, abortController)
            .then((response) => {
              return response.json().then((result) => {
                setCurrentUsage(result);
              });
            });
        }).catch((e) => {
          console.error(e);
        }).finally(() => {
          setApiLoading(false);
        });
    };
    if (companyId || forceUpdate) {
      getData();
    }

    return () => {
      abortController.abort();
    };
  }, [companyId, forceUpdate]);

  return [currentUsage, apiLoading];
};

export default useCurrentUsage;
