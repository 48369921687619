import React from 'react';

import * as constants from '../../styles/style';
import * as FertilizingUtils from '../../utils/Fertilizing';
import TextInput from './TextInput';

export default function textbox(locals) {
  if (locals.hidden) {
    return null;
  }

  const styles = locals.config.styles;
  const textboxStyle = {
    ...constants.styles.stdSize,
    height: 36,
    textAlign: 'center',
    color: constants.FJNAVCOLOR,
    paddingRight: 0,
    padding: 0,
    margin: 0,
    fontWeight: 'bold',
  };

  const kg = FertilizingUtils.calculateKg(locals.config.kg, locals.value);
  const sum = Number((locals.config.sum + kg).toFixed(1));
  const label = locals.label ? <span>{locals.label}</span> : null;

  return (
    <div
      className="fertilizings-table"
      style={{
        ...(locals.hasError
          ? { borderBottomColor: constants.FJAPPLERED }
          : null),
      }}>
      <div className="header-row">
        <span style={{ ...styles.inputFieldLabel }}>{label}</span>
      </div>
      <div className="input-field-percent">
        <TextInput {...locals} label={null} style={textboxStyle} />
      </div>
      <div className="column">
        <span>{kg}</span>
      </div>
      <div className="column">
        <span>{sum}</span>
      </div>
    </div>
  );
}
