import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { update } from '../../utils/service-worker/serviceWorker';
import './style.css';
import I18n from '../../language/i18n';

class UpdateMessage extends React.Component<{
  serviceWorkerUpdateAvailable: boolean;
}> {
  render() {
    const { serviceWorkerUpdateAvailable } = this.props;

    if (!serviceWorkerUpdateAvailable) {
      return null;
    }

    return (
      <div className="service-worker-update-message">
        {I18n.t('webUpdate.updateAvailable')}{' '}
        <button className="btn btn-primary btn-sm" onClick={update}>
          {I18n.t('webUpdate.update')}
        </button>
      </div>
    );
  }
}

export default compose(
  connect((state: any) => ({
    serviceWorkerUpdateAvailable: state.serviceWorkerUpdateAvailable,
  })),
)(UpdateMessage);
