import algoliasearch from 'algoliasearch';
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_API_KEY,
  ALGOLIA_INDEX_USERS,
  ALGOLIA_INDEX_COMPANIES,
} from '../../constants';


const setIndex = (indexName, client) => {
  switch (indexName) {
    case ALGOLIA_INDEX_USERS:
      return client.initIndex(indexName);
    case ALGOLIA_INDEX_COMPANIES:
      return client.initIndex(indexName);
    default:
      throw new Error(`Unsupported index: ${indexName}`);
  }
};

export const getAlgoliaInstance = (index) => {
  const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_API_KEY);
  const algoliaIndex = setIndex(index, client);
  return {
    searchIndex: algoliaIndex.search.bind(algoliaIndex),
  };
};
