import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as commentUtils from '../../../utils/Comment';

// TODO: improve typings
type Props = any;
type State = any;

export default class Field extends PureComponent<Props, State> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  render() {
    const { comment } = this.props;
    const text = commentUtils.getCommentText(comment);

    return (
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <div style={{ flex: 1 }}>
          <span>{text}</span>
        </div>
      </div>
    );
  }
}
