import React, { useEffect, useRef } from "react";
import { createSubscription } from "farmerjoe-common/lib/requests/billing";
import {
  TEAM_SUBSCRIPTION_PRICE,
  TEAM_SUBSCRIPTION_CURRENCY_EUR,
} from "farmerjoe-common/lib/constants/billing";

import I18n from "../../language/i18n";
import { getUidToken } from "../../utils/auth";
import { STRIPE_CLIENT_PK } from "../../constants";

const stripe = (window as any).Stripe(STRIPE_CLIENT_PK);

type TProps = {
    quantity: number;
    companyId: string;
    onError: (arg1: any) => void;
    onSuccess: (arg1: any) => void;
};

const Stripe = (props: TProps) => {
  const { quantity, companyId, onError, onSuccess } = props;

  const options = {
    mode: "subscription",
    // XXX: We need to multiple by 100, becouse stripe treats each single digit number as cent
    amount: (quantity * TEAM_SUBSCRIPTION_PRICE) * 100, 
    currency: TEAM_SUBSCRIPTION_CURRENCY_EUR,
    appearance: {},
    options: {},
  };
  const elements = stripe.elements(options);
  const paymentElement = elements.create("payment");

  useEffect(() => {
    paymentElement.mount("#payment-element");
  }, [paymentElement]);


  const submitBtnRef = useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    if ((submitBtnRef as any).current.disabled) {
      return;
    }

    (submitBtnRef as any).current.disabled = true;

    // Trigger form validation and wallet collection
    if (!elements) {
      return;
    }

    try {
      const {error: submitError} = await (elements as any).submit();
      if (submitError) {
        console.log(submitError);
        return;
      }

      const uidToken = await getUidToken();
      const res = await createSubscription(uidToken, companyId, quantity);
      const { type, clientSecret, data: { stripeSubscriptionId, stripeCustomerId } } = await res.json();
      const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: window
            .location
            .toString(),
        },
        redirect: "if_required",
      });

      if (error) {
        console.log(error);
        onError(error);
        return;
      } 
      onSuccess({ stripeSubscriptionId, stripeCustomerId });
    } catch(err) {
      onError(err);
    }
  };

  return (
    <div>
      <form id="payment-form" onSubmit={onSubmit}>
        <div id="payment-element">
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}>
          <button
            className={"btn btn-primary"}
            id="submit"
            ref={submitBtnRef}
            style={{
              textTransform: "uppercase",
            }}
          >
            {I18n.t("billing.buy")}
          </button>
        </div>
        <div id="error-message">
        </div>
      </form>
    </div>
  );
};

export default Stripe;
