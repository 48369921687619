import React from 'react';
import { createFilter } from 'react-select';

import CountryTelItem from './CountryTelItem';
import CountryList from './CountryList';
import CountryItemOnlyCode from './CountryItemOnlyCode';
import './style.css';

import Icon from '../../../components/Common/Icon';
import * as constants from '../../../styles/style';
import { getCountriesTel } from '../../../utils/Countries';

type Props = {
  /**
   * the cca2 code for the country
   */
  cca2: string;

  /**
   * the country code
   */
  callCode: string;
  onChange: (...args: Array<any>) => any;
  onBlur?: React.FocusEventHandler;
  editable: boolean;
  hasError: boolean;
  style?: Record<string, any>;
};

export default class CountryTelList extends React.PureComponent<Props> {
  countryList = React.createRef();

  render() {
    const { cca2, callCode, hasError, editable, onChange, onBlur, style } = this.props;
    return (
      <CountryList
        ref={this.countryList}
        editable={editable}
        hasError={hasError}
        cca2={cca2}
        countries={getCountriesTel()}
        onChange={onChange}
        onBlur={onBlur}
        containerStyle={null}
        filterOption={createFilter({
          stringify: ({ data: { name, cca2, callCode } }) =>
            `${name} ${cca2} +${callCode}`,
        })}
        ListItem={CountryTelItem}
        ValueItem={CountryItemOnlyCode}
        renderValue={() => {
          let errorStyle: any = null;
          if (hasError) {
            errorStyle = { color: 'red' };
          }
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <span
                style={{
                  ...errorStyle,
                  ...(editable ? null : { color: 'grey' }),
                  ...{ color: 'grey' },
                }}>
                {cca2}
              </span>
              <Icon
                name={'arrow_down'}
                iconType={'fj'}
                style={{
                  ...{
                    fontSize: 8,
                    marginTop: 5,
                    marginLeft: 5,
                    marginRight: 10,
                    color: constants.FJMUTED,
                  },
                  ...errorStyle,
                }}
              />
              <span
                style={{
                  ...errorStyle,
                  ...(editable ? null : { color: 'grey' }),
                }}>
                +{callCode}
              </span>
            </div>
          );
        }}
        style={style}
      />
    );
  }

  setModalVisible(visible: boolean) {
    (this.refs.countryList as any).current.setModalVisible(visible);
  }
}
