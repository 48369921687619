import React, { ReactElement, ReactNode } from "react";
import type { ModelListDataObject } from "../../flowTypes";
import { classes } from "../../utils/dom";
import Icon from "../Common/Icon";
import RSwitch from "../Common/RSwitch/RSwitch";
import DropDown from "../DropDown/DropDown";
import "./style.css";

type FilterListProps = {
  button: ReactNode;
  children?: ReactElement<ListSectionProps>[];
  open: boolean;
  onClose: () => void;

  menuPosition?: string;
  menuAnimation?: string;
  showMenuWhereClicked?: boolean;
  showInPortal?: boolean;
  dropdownClassName?: string;
  closeOnClickInside?: boolean;
};

export function FilterList({
  button,
  children,
  open = false,
  onClose,
  menuPosition,
  menuAnimation,
  showMenuWhereClicked,
  showInPortal,
  dropdownClassName,
}: FilterListProps) {
  return (
    <DropDown
      button={button}
      onHide={() => {
        onClose?.();
      }}
      show={open}
      menuPosition={menuPosition}
      menuAnimation={menuAnimation}
      showMenuWhereClicked={showMenuWhereClicked}
      showInPortal={showInPortal}
      className={classes(dropdownClassName, open && "show")}
    >
      <div className="d-flex flex-column" style={{ gap: "1.5rem" }}>
        {children}
      </div>
    </DropDown>
  );
}

type ListSectionProps = {
  headline?: string;
  children?: ReactElement<ListItemCheckProps | ListItemCheckProps>[];
};

export function ListSection({
  headline,
  children,
}: ListSectionProps) {
  return (
    <div className="d-flex flex-column list-section">
      {headline && <div className="px-3 section-headline">{headline}</div>}
      <div className="d-flex flex-column section-sublist">
        {children}
      </div>
    </div>
  );
}

type ListItemCheckProps = {
  option: ModelListDataObject;
  onChange?: (value: string) => void;
  selected?: boolean;
  showSelected?: boolean;
  showIcons?: boolean;
};

export function ListItemCheck({
  option,
  onChange,
  selected = false,
  showSelected = true,
  showIcons = false,
}: ListItemCheckProps) {
  return (
    <div
      key={"options" + option.value}
      className={`d-flex flex-row justify-content-between align-items-center dropdown-item ${showSelected && selected ? "item-active" : "item"}`}
      onClick={() => {
        onChange?.(option.value);
      }}>
      <div className="option-label">{option.label}</div>
      {showSelected && selected && (
        <Icon
          name={"md-checkmark"}
        />
      )}
    </div>
  );
};

type ListItemToggleProps = {
  option: ModelListDataObject;
  checked?: boolean;
  onChange?: (value: boolean) => void;
};

export function ListItemToggle({
  option,
  checked = false,
  onChange,
}: ListItemToggleProps) {
  return (
    <div
      key={"options" + option.value}
      className={"d-flex flex-row justify-content-between align-items-center dropdown-item"}
    >
      <div className="option-label mr-3">{option.label}</div>
      <RSwitch
        name={String(option.value)}
        value={checked}
        onChange={updatedChecked => {
          onChange?.(updatedChecked);
        }}
      />
    </div>);
}
