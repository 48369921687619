import { fetchJson } from '../../utils/fetch';

export function inviteSupport(token: string | undefined, companyId: string) {
  if (!token) {
    return Promise.reject(new Error('Token is undefined!'));
  }
  return fetchJson(
    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/support/invite`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: { companyId },
    },
  );
};

export function leaveCompany(token: string | undefined, companyId: string) {
  if (!token) {
    return Promise.reject(new Error('Token is undefined!'));
  }
  return fetchJson(
    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/support/leave`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: { companyId },
    },
  );
};
