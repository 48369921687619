import { PositionErrorCode } from './Map';
import { store } from '../stores/store';
import { changeLocationPermission } from 'farmerjoe-common/lib/actions/position';

let currentPosition: any = null;
let watchCount = 0;

export function watchPosition(successCallback, failureCallback, options) {
  if (!('geolocation' in navigator)) {
    failureCallback(makeGeolocationUnavailableError());
    return null;
  }

  watchCount++;
  const id = navigator.geolocation.watchPosition(
    position => {
      changePermission(true);
      currentPosition = position;
      successCallback(position);
    },
    e => {
      if (e.code === PositionErrorCode.PERMISSION_DENIED) {
        clearWatch(id);
        changePermission(false);
      }
      failureCallback(e);
    },
    options,
  );
  return id;
}

export function clearWatch(id) {
  if (!('geolocation' in navigator)) {
    return;
  }

  watchCount--;
  navigator.geolocation.clearWatch(id);
}

export function getCurrentPosition(successCallback, failureCallback, options) {
  if (!('geolocation' in navigator)) {
    failureCallback(makeGeolocationUnavailableError());
    return;
  }

  if (watchCount > 0 && currentPosition !== null) {
    successCallback(currentPosition);
  } else {
    return navigator.geolocation.getCurrentPosition(
      position => {
        changePermission(true);
        currentPosition = position;
        successCallback(position);
      },
      e => {
        if (e.code === PositionErrorCode.PERMISSION_DENIED) {
          changePermission(false);
        }
        failureCallback(e);
      },
      options,
    );
  }
}

function makeGeolocationUnavailableError() {
  const error = new Error('geolocation is unavailable');
  (error as any).code = PositionErrorCode.POSITION_UNAVAILABLE;
  return error;
}

window.addEventListener('load', function() {
  // update store's location permission
  if ('permissions' in navigator) {
    const change = permission => {
      if (permission.state === 'granted') {
        changePermission(true);
      } else if (permission.state === 'prompt') {
        changePermission(null);
      } else {
        changePermission(false);
      }
    };

    navigator.permissions.query({ name: 'geolocation' }).then(permission => {
      change(permission);
      permission.addEventListener('change', () => change(this));
    });
  }
});

function changePermission(state) {
  if (store == null) {
    console.warn('store not initialized yet'); // shouldn't happen
    return;
  }

  // prevent spamming the redux store with unnecessary actions
  if (store.getState().locationPermission !== state) {
    store.dispatch(changeLocationPermission(state));
  }
}
