import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash-es";

import { filters } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import {
  getGroupFieldsByOptions,
  getFertilizationOptions,
  getSortFieldsByOptions,
  getShowProducerNamesOptions,
  getSharedByCompanyOptions,
} from "farmerjoe-common/lib/utils/Filter";

import Icon from "../Common/Icon";
import { FilterList, ListItemCheck, ListItemToggle, ListSection } from "../Modal/FilterList";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import "./style.css";

type FiltersProps = {
  openCompany: string;

  displaySettings: {
    fertilizing: boolean;
  };

  cropState: "active" | "harvested";
  sortFieldsBy: string;
  groupFieldsBy: string;
  showFertilizationResult: boolean;
  showProducerNames: boolean;
  showSharedByCompany: boolean;

  actions: {
    filters: any; // typeof filters
  };
};

type FiltersState = {
  openFilterList: boolean;
  sortFieldsBy: string;
  groupFieldsBy: string;
  showFertilizationResult: boolean;
  showProducerNames: boolean;
  showSharedByCompany: boolean;
};

class Filters extends React.PureComponent<FiltersProps, FiltersState> {
  constructor(props) {
    super(props);
    this.state = {
      openFilterList: false,
      sortFieldsBy: props.sortFieldsBy ?? "fieldName",
      groupFieldsBy: props.groupFieldsBy ?? "cropName",
      showFertilizationResult: props.showFertilizationResult ?? false,
      showProducerNames: props.showProducerNames ?? false,
      showSharedByCompany: props.showSharedByCompany ?? false,
    };
  }

  updateFilterState(key: keyof FiltersState, value: string | boolean) {
    const { cropState, openCompany, displaySettings } = this.props;
    const updatedState: FiltersState = {
      ...this.state,
      [key]: value,
    };
    this.setState({ ...updatedState });
    const updatedDisplaySettings = {
      displaySettings: {
        ...displaySettings,
        [cropState]: {
          ...updatedState,
        },
      },
    };
    this.props.actions.filters(openCompany, updatedDisplaySettings);
  }

  render() {
    const { cropState, sortFieldsBy, groupFieldsBy, showFertilizationResult, showProducerNames, showSharedByCompany } = this.props;
    const sortFieldsByOptions = getSortFieldsByOptions(cropState) || [];
    const groupFieldsByOptions = getGroupFieldsByOptions(cropState) || [];
    const fertilizingOptions = getFertilizationOptions() || [];
    const showProducerNamesOptions = getShowProducerNamesOptions() || [];
    const showSharedByCompanyOptions = getSharedByCompanyOptions() || [];

    return (
      <FilterList
        open={this.state.openFilterList}
        onClose={() => this.setState({ openFilterList: false })}
        button={<button
          className="sort-filter btn btn-secondary"
          onClick={() => this.setState({ openFilterList: !this.state.openFilterList })}>
          <Icon
            name="sort"
            iconType={"fj"}
            style={{
              color: constants.FJMUTEDDARK,
            }}
          />
        </button>}
      >
        <ListSection headline={I18n.t("sortFieldsBy")}>
          {sortFieldsByOptions.map(option => (
            <ListItemCheck
              key={option.label}
              option={option}
              selected={option.value === sortFieldsBy}
              onChange={(value) => this.updateFilterState("sortFieldsBy", value)}
            />
          ))}
        </ListSection>

        <ListSection headline={I18n.t("groupFieldsBy")}>
          {groupFieldsByOptions.map(option => (
            <ListItemCheck
              key={option.label}
              option={option}
              selected={option.value === groupFieldsBy}
              onChange={(value) => this.updateFilterState("groupFieldsBy", value)}
            />
          ))}
        </ListSection>

        <div>
          <ListSection>
            {showSharedByCompanyOptions.map(option => (
              <ListItemToggle
                key={option.label}
                option={option}
                checked={showSharedByCompany}
                onChange={(checked) => this.updateFilterState("showSharedByCompany", checked)} />
            ))}
          </ListSection>

          <ListSection>
            {showProducerNamesOptions.map(option => (
              <ListItemToggle
                key={option.label}
                option={option}
                checked={showProducerNames}
                onChange={(checked) => this.updateFilterState("showProducerNames", checked)} />
            ))}
          </ListSection>

          <ListSection>
            {fertilizingOptions.map(option => (
              <ListItemToggle
                key={option.label}
                option={option}
                checked={showFertilizationResult}
                onChange={(checked) => this.updateFilterState("showFertilizationResult", checked)} />
            ))}
          </ListSection>
        </div>

      </FilterList>
    );
  }
}

const selector = (state, ownProps) => {
  const cropState = ownProps.cropState || "active";
  const openCompany = selectors.getOpenCompanyId(state);
  const settings = get(
    state,
    ["filtersByCompany", openCompany, "displaySettings", cropState],
  );
  const sortFieldsBy = settings?.sortFieldsBy ?? "fieldName";
  const groupFieldsBy = settings?.groupFieldsBy ?? "cropName";
  const showFertilizationResult = settings?.showFertilizationResult ?? false;
  const showProducerNames = settings?.showProducerNames ?? false;
  const showSharedByCompany = settings?.showSharedByCompany ?? false;
  const displaySettings = state.filtersByCompany[openCompany]
    ? state.filtersByCompany[openCompany].displaySettings
    : {};

  return {
    openCompany,
    cropState,
    displaySettings,
    sortFieldsBy,
    groupFieldsBy,
    showFertilizationResult,
    showProducerNames,
    showSharedByCompany,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(Filters as any);
