import React from "react";
import { getColor } from "farmerjoe-common/lib/utils/Colors";

const PrecropsFromField = ({ precrops, onRemoveUserCrop }: {precrops?: any[], onRemoveUserCrop?: (...args: any) => any}) => {
  if (!precrops || !precrops.length) {
    return null;
  }
  const userCrops = precrops.map((precrop, idx) => {
    return (
      <div key={idx + 8888} style={{
        borderRadius: "10px",
        backgroundColor: getColor("noCrop"),
        color: "#3D312E",
        padding: "0 10px",
        margin: "3px 1px",
      }}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <div>
            {precrop}
          </div>
          <div
            style={{
              marginLeft: "5px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#cbcbcb",
              cursor: "pointer",
            }}
            onClick={() => {
              if (onRemoveUserCrop) {
                onRemoveUserCrop(precrop);
              }
            }}
          >
            <i className="fa fa-times" />
          </div>
        </div>
      </div>
    );
  });
  return (
    <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
      {userCrops}
    </div>
  );
};

export default PrecropsFromField;
