import React from "react";
import { compose } from "redux";

import TableFilter from "./TableFilter";
import DateRangeFilter from "../DateRangeFilter";
import FieldsViewSwitch from "../FieldsViewSwitch";
import Icon from "../../Common/Icon";
import SearchInput from "../../Common/SearchInput";
import { CreateNewButton } from "../../Common/NewButton";
import { DefaultTabBar } from "../../Common/ScrollableTabView";
import withRouter from "../../Router/withRouter";
import I18n from "../../../language/i18n";
import "./style.css";

// TODO: improve typings
type Props = any;
type State = any;

const TabBar = (props) => {
  const {
    showNew,
    onShowCreateForm,
    search,
    company,
    onSearch,
    onColumnConfigClick,
    tab,
    history,
    location,
    isMapPage,
    onExportClick,
    ...restProps
  } = props;
  return (
    <React.Fragment>
      <div className="page-card-top-bar-container">
        <FieldsViewSwitch key="fields-view-switch" />
        <DefaultTabBar {...restProps} />
        {showNew
          ? (
            <CreateNewButton onClick={onShowCreateForm} />
          )
          : (
            <div style={{ width: 35 }}></div>
          )}
      </div>
      <div className="toolbar-container">
        {tab === "harvested" ? <DateRangeFilter /> : null}
        {tab === "active" && !isMapPage ? <TableFilter /> : null}
        {!isMapPage
          ? (
            <SearchInput
              onChange={onSearch}
              company={company}
              search={search}
            />
          )
          : null}
        <button
          className="btn btn-secondary column-config-button"
          onClick={onColumnConfigClick}>
          <Icon iconType="fa" name="cogs" style={{ fontSize: 20 }} />{" "}
          {I18n.t("columnConfigurator.title")}
        </button>
        <button
          className="btn btn-secondary column-config-button"
          onClick={onExportClick}>
          <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
          {I18n.t("export")}
        </button>
      </div>
    </React.Fragment>
  );
};

export default compose<typeof TabBar>(withRouter)(TabBar);
