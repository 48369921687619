import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Modal from "react-bootstrap-modal";

import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import * as analysisActions from "farmerjoe-common/lib/actions/analysis";
import { getLabsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Labs";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import * as labActions from "farmerjoe-common/lib/actions/labs";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";

import I18n from "../../language/i18n";
import FieldsSelector from "../Common/FieldsSelector";

type fieldSelection = Record<string, boolean>;

// TODO: improve typings
type Props = {
  company: any;
  goBack: (...args: any) => any;
  actions: {
    analysisField: (...args: any) => any;
    selectedLab: (...args: any) => any;
  };
  selectedLab: any;
  openView: (...args: any) => any;
};

type State = {
  selected: fieldSelection;
};

const ANALYSIS_FORM_MULTIPLE_FIELDS = "CreateAnalysisFormMulti";
const ANALYSIS_FORM_SINGLE_FIELD = "CreateAnalysisForm";


class FormFieldMultiSelect extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selected: {},
    };
  }

  render() {
    return [
      <Modal.Body onClick={e => e.stopPropagation()} key={0}>
        <FieldsSelector
          selected={this.state.selected}
          onSelectField={(item) =>
            this.setState({
              selected: {
                ...this.state.selected,
                [item.key]: !this.state.selected[item.key],
              },
            })}
        />
      </Modal.Body>,
      <Modal.Footer onClick={e => e.stopPropagation()} key={1}>
        <div className="d-flex flex-grow-2">
          <button
            className="btn btn-secondary"
            onClick={this.goBack.bind(this)}>
            {I18n.t("back")}
          </button>
          <button
            className="btn btn-primary"
            onClick={key => {
              const selectedFields = Object.keys(this.state.selected).filter((key) => this.state.selected[key] === true);

              if (!selectedFields.length) {
                return;
              }

              if (selectedFields.length === 1) {
                // single field selected opens a default form
                this.props.actions.analysisField(selectedFields[0]);
              }

              if (selectedFields.length > 1){
                // Multiform navigation goes here
                this.props.actions.analysisField(selectedFields);
              }

              const formName = selectedFields.length > 1 ? ANALYSIS_FORM_MULTIPLE_FIELDS : ANALYSIS_FORM_SINGLE_FIELD;
              this.props.actions.selectedLab(this.props.selectedLab);
              this.props.openView(formName);
            }}
          >
            {I18n.t("continue")}
          </button>
        </div>
      </Modal.Footer>,
    ];
  }

  goBack() {
    this.props.actions.analysisField(null);
    this.props.goBack();
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  const { labs } = state.firestore.ordered;
  const selectedId = state.selectedLab.id;
  const selectedLab = labs.filter(lab => lab.id === selectedId)[0];

  const loading = !hasLoaded(
    [
      getLabsQuery(),
    ],
    state,
  );

  return {
    openCompany,
    filter: state.filtersByCompany[openCompany],
    loading,
    browsingGroup,
    myCompanyProfile,
    selectedLab,
  };
};

const wrappedFormFieldSelect = firestoreConnect(props => {
  return [
    getLabsQuery(),
  ];
})(FormFieldMultiSelect);

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...labActions,
          ...analysisActions,
        },
      ),
      dispatch,
    ),
  };
};

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedFormFieldSelect);

