import React from "react";
import { calculateAreaDifference } from "farmerjoe-common/lib/utils/Field";
import { classes } from "../../utils/dom";
import "./style.css";

export default function FieldSizeDifference(props: {
  children?: React.ReactNode;
  size: number;
  areaSize: number;
  className?: string;
}) {
  const { size, areaSize, className, children } = props;
  const areaDiff = calculateAreaDifference(areaSize, size);
  const sign = areaDiff > 0 ? "+" : "";
  return (
    <div className={classes("field-size-container", className)}>
      {children}
      {size && areaSize
        ? (
          <span
            className={classes(
              "field-size-difference",
              areaDiff > 5 || areaDiff < -5 ? "difference" : "equal",
            )}>
            <span>
              {sign}
              {areaDiff.toFixed(0)} %
            </span>
          </span>
        )
        : null}
    </div>
  );
}
