import * as React from 'react';
import type { ModelListDataObject } from '../../flowTypes';
import Icon from '../Common/Icon';
import ModalList from '../Modal/ModalList';
import './style.css';

import * as constants from '../../styles/style';

type Props = {
  onClick: (value: any) => void;
  showSelected?: boolean;
  selected?: any;
  data: Array<ModelListDataObject>;
};

type State = {};

export default class SortFilter extends React.PureComponent<Props, State> {
  static defaultProps = {
    showSelected: false,
  };

  render() {
    const { data, showSelected, selected } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <ModalList
          data={data}
          showSelected={showSelected}
          selected={selected}
          onClick={({ value }) => {
            this.props.onClick(value);
          }}>
          <button className="sort-filter btn btn-secondary">
            <Icon
              name="sort"
              iconType={'fj'}
              style={{
                color: constants.FJMUTEDDARK,
              }}
            />
          </button>
        </ModalList>
      </div>
    );
  }
}
