/**
 * Copied from the "react-google-maps" library sources.
 * https://github.com/tomchentw/react-google-maps/blob/master/src/utils/MapChildHelper.js
 *
 * We had to do this to ease the migration to the new "@react-google-maps/api" library.
 */
import { has, forEach, reduce, isFunction, lowerFirst, bind } from 'lodash-es';

function rdcUncontrolledAndControlledProps(acc, value, key) {
  if (has(acc.prevProps, key)) {
    const match = key.match(/^default(\S+)/);
    if (match) {
      const unprefixedKey = lowerFirst(match[1]);
      if (!has(acc.nextProps, unprefixedKey)) {
        acc.nextProps[unprefixedKey] = acc.prevProps[key];
      }
    } else {
      acc.nextProps[key] = acc.prevProps[key];
    }
  }
  return acc;
}

function applyUpdaterToNextProps(updaterMap, prevProps, nextProps, instance) {
  forEach(updaterMap, (fn, key) => {
    const nextValue = nextProps[key];
    if (nextValue !== prevProps[key]) {
      fn(instance, nextValue);
    }
  });
}

export function construct(propTypes, updaterMap, prevProps, instance) {
  const { nextProps } = reduce(propTypes, rdcUncontrolledAndControlledProps, {
    nextProps: {},
    prevProps,
  });
  applyUpdaterToNextProps(
    updaterMap,
    {
      /* empty prevProps for construct */
    },
    nextProps,
    instance,
  );
}

export function componentDidMount(component, instance, eventMap) {
  registerEvents(component, instance, eventMap);
}

export function componentDidUpdate(
  component,
  instance,
  eventMap,
  updaterMap,
  prevProps,
) {
  component.unregisterAllEvents();
  applyUpdaterToNextProps(updaterMap, prevProps, component.props, instance);
  registerEvents(component, instance, eventMap);
}

export function componentWillUnmount(component) {
  component.unregisterAllEvents();
}

function registerEvents(component, instance, eventMap) {
  const registeredList = reduce(
    eventMap,
    (acc, googleEventName, onEventName) => {
      if (isFunction(component.props[onEventName])) {
        acc.push(
          google.maps.event.addListener(
            instance,
            googleEventName,
            component.props[onEventName],
          ),
        );
      }
      return acc;
    },
    [] as google.maps.MapsEventListener[],
  );

  component.unregisterAllEvents = bind(
    forEach,
    null,
    registeredList,
    unregisterEvent,
  );
}

function unregisterEvent(registered) {
  google.maps.event.removeListener(registered);
}
