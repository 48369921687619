import React from 'react';
import t from 'tcomb-form';
import CountryInput from '../../../components/Common/Country/CountryInput';
import { styles } from '../../../styles/style';

const Component = t.form.Textbox;

export default class Country extends Component {
  getTemplate() {
    return locals => {
      const value = locals.value;
      let errorStyle = {};
      if (locals.hasError) {
        errorStyle = styles.danger;
      }

      const label = <span style={errorStyle}>{locals.label}</span>;

      return (
        <div>
          {label}
          <CountryInput
            ref={'input' as any} // TODO: fix typing
            editable={locals.editable}
            hasError={locals.hasError}
            value={locals.value}
            // Set the phone number
            onChange={({ value }) => {
              if (this.props.onChange) this.props.onChange(value);
            }}
          />
        </div>
      );
    };
  }
}
