import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import MapSearch from './MapSearch';
import MapControl, {
  ControlButton,
  ControlContainer,
  RIGHT_BOTTOM,
  TOP_LEFT,
} from './MapControl';
import './style.css';
import MapFilter from './MapFilter';
import MapMarkerTitles from './MapMarkerTitles';
import MapCenterOnUserPosition from './MapCenterOnUserPosition';
import MapCenterOnMarkers from './MapCenterOnMarkers';
import MapGeoSearch from './MapGeoSearch';
import Icon from '../Common/Icon';
import { classes } from '../../utils/dom';
import I18n from '../../language/i18n';

// TODO: improve typings
type Props = {
  displayFilter?: boolean;
  displayMarkers?: boolean;
  hideSearch?: boolean;
  hideMarkers?: boolean;
  onHideMarkers?: () => void;
  displayGeoSearch?: boolean;
  markers?: any[];
  map: google.maps.Map;
};
type State = any;

export default class MapControlOverlay extends PureComponent<Props, State> {
  static propTypes = {
    displayFilter: PropTypes.bool,
    zoomedIn: PropTypes.bool,
    displayMarkers: PropTypes.bool,
    hideSearch: PropTypes.bool,
    hideMarkers: PropTypes.bool,
    markers: PropTypes.array,
  };

  static defaultProps = {
    fitBoundaries: false,
    displayFilter: true,
    zoomedIn: false,
    hideSearch: false,
    hideMarkers: true,
    displaySettings: true,
    displayMarkers: true,
    displayGeoSearch: false,
  };

  render() {
    const { displayFilter, hideSearch, displayMarkers, displayGeoSearch } =
      this.props;

    const search = !hideSearch ? <MapSearch /> : null;
    const map = this.props.map;

    return [
      /*      <MapControl position={TOP_LEFT} key="top-control-2">
              <ControlContainer>
                {displaySettings
                  ? <MapSettings map={map}/>
                  : null
                }
              </ControlContainer>
            </MapControl>, */
      <MapControl
        position={TOP_LEFT}
        key="top-control"
        style={{ right: '50px' }}
        mergeControls={false}
        map={map}
      >
        <ControlContainer className="map-toolbar-container map-toolbar-control-overlay-container">
          {displayFilter ? (
            <div className="map-toolbar-filter-container">
              {displayFilter ? <MapFilter /> : null}
              {displayFilter ? <MapMarkerTitles /> : null}
            </div>
          ) : null}
          {displayGeoSearch || search ? (
            <div className="map-toolbar-search-container">
              {displayGeoSearch ? <MapGeoSearch map={map} /> : null}
              {search}
            </div>
          ) : null}
        </ControlContainer>
      </MapControl>,
      <MapControl
        position={RIGHT_BOTTOM}
        key={1}
        mergeControls={false}
        map={map}
      >
        <ControlContainer className="map-toolbar">
          <MapCenterOnUserPosition map={map} />
          {displayMarkers ? (
            <MapCenterOnMarkers map={map} markers={this.props.markers} />
          ) : null}
          <ControlButton
            className={classes(this.props.hideMarkers && 'active')}
            onClick={this.props.onHideMarkers}
            title={I18n.t('polygon.hideMarkers')}
          >
            <Icon
              iconType="fal"
              name={this.props.hideMarkers ? 'map-marker-slash' : 'map-marker'}
            />
          </ControlButton>
        </ControlContainer>
      </MapControl>,
    ];
  }
}
