import { get } from 'lodash-es';
import React, { Component } from 'react';

import { firebaseConnect } from 'react-redux-firebase';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { uploadAvatar } from '../../actions/image';

import { Loading } from '../Loading/Loading';
import ButtonCamera from '../Common/ButtonCamera';
import ImagePicker from '../ImagePicker/ImagePicker';
import imgFjAvatar from '../../public/images/fj_avatar.png';

import './style.css';

const pickerSettings = {
  width: 512,
  height: 512,
  imageQuality: 0.5,
  cropping: true,
};

// TODO: improve typings
type Props = any;
type State = any;

class Avatar extends Component<Props, State> {
  render() {
    const placeholder = get(this.props.owner, 'avatar.placeholder', false);
    const avatar = get(this.props.owner, 'avatar.original.downloadURL', null);
    let source = imgFjAvatar;

    if (avatar) {
      source = avatar;
    }

    return (
      <div
        className="avatar"
        style={{
          padding: 2,
          backgroundColor: '#FFF',
          borderRadius: 5,
          marginBottom: 5,
          position: 'relative',
          cursor: 'pointer',
        }}>
        <ImagePicker
          {...pickerSettings}
          onPick={image => {
            this.props.actions.uploadAvatar(image);
          }}>
          {placeholder
            ? (
            <div
              style={{
                background: `url(${source
                  .replace(/\(/g, '%28')
                  .replace(/\)/g, '%29')}) no-repeat center center/cover`,
                backgroundSize: 'cover',
                width: 100,
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                filter: 'blur(3px)',
              }}>
              <Loading />
            </div>
              )
            : (
            <div
              style={{
                background: `url(${source
                  .replace(/\(/g, '%28')
                  .replace(/\)/g, '%29')}) no-repeat center center/cover`,
                backgroundSize: 'cover',
                width: 100,
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: 5,
              }}
            />
              )}

          <ButtonCamera style={{ borderBottomRightRadius: 5 }} />
        </ImagePicker>
      </div>
    );
  }
}

const wrappedAvatar = firebaseConnect(props => {
  return [];
})(Avatar);

const selector = state => {
  return {
    owner: state.firebase.profile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          uploadAvatar,
        },
      ),
      dispatch,
    ),
  };
}

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedAvatar);
