import phoneNumberMetadata from "libphonenumber-js/metadata.min.json";
import { ParsedPhoneInput } from "../types/phone";

const PHONE_REGEX = /^(?<callCode>\+\d{1,3})(?<phone>\s+[0-9\s]+)?$/;

export const getCallCodeAndPhone = (value: string | null): ParsedPhoneInput | null => {
  if (!value) {
    return null;
  }

  if (PHONE_REGEX.test(value)) {
    const { groups: { callCode: callCodeParsed, phone=null } } = value.match(PHONE_REGEX) as any;
    const callCode = parseInt(callCodeParsed.slice(1));
    const countries = phoneNumberMetadata.country_calling_codes[callCode];
    if (countries && countries.length) {
      return {
        country: {
          cca2: countries[0],
          callCode,
        },
        phone: phone ? phone.trim() : null,
      };
    }
  }
  return null;
};
