import * as React from 'react';
import { TableListViewState } from '../../flowTypes';
import I18n from '../../language/i18n';
import Icon from './Icon';
import './TableListViewSwitch.css';

type Props = {
  currentState: TableListViewState;
  onStateChange: (newState: TableListViewState) => void;
};

export function TableListViewSwitch(props: Props) {
  const { currentState, onStateChange } = props;
  const nextState =
    currentState === TableListViewState.Table
      ? TableListViewState.Classic
      : TableListViewState.Table;

  const defaultClassname: string = 'top-bar-button table-list-view-switch';
  const activeClassname: string = defaultClassname + ' active';

  return (
    <div>
      <div
        className={
          currentState === TableListViewState.Classic
            ? activeClassname
            : defaultClassname
        }
        onClick={() => onStateChange(nextState)}
      >
        <Icon name="list" iconType="fal" />
        <span>{I18n.t('list')}</span>
      </div>
      <div
        className={
          currentState === TableListViewState.Table
            ? activeClassname
            : defaultClassname
        }
        onClick={() => onStateChange(nextState)}
      >
        <Icon name="th-list" iconType="fal" />
        <span>{I18n.t('table')}</span>
      </div>
    </div>
  );
}
