import React from 'react';

import LabItem from './LabItem';

type Props = {
  labs: any[];
  onClick?: (lab: any, labOffer: any) => any;
};

const Labs = (props: Props) => {
  const { labs, onClick } = props;

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      {labs.map(lab => {
        return (
          <div key={lab.key}>
            <LabItem lab={lab} onClick={onClick} />
          </div>
        );
      })}
    </div>
  );
};

export default Labs;
