import stylesheet from '../../../../tcomb/stylesheets/style';
import { cloneDeep, get } from 'lodash-es';
import Boolean from '../../../../tcomb/templates/Boolean';

const checkBox = cloneDeep(stylesheet);
checkBox.formGroup.normal = {
  ...checkBox.formGroup.normal,
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  borderBottomWidth: 0,
};
checkBox.controlLabel.normal = {
  ...checkBox.controlLabel.normal,
  color: '#000',
  fontSize: 18,
};

export const option = fieldConfig => {
  const stylesheet = get(fieldConfig, 'config.style');
  const field: any = {
    label: fieldConfig.label,
  };

  if (stylesheet === 'checkbox') {
    field.stylesheet = checkBox;
  }

  field.factory = Boolean;

  return field;
};
