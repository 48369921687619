import axios from 'axios';
import { ProducerPayload } from '../../containers/Producers/producerPayload';

// TODO: cleanup
export async function sendCreateProducerRequest(producerData: ProducerPayload, user) {
  const token = await user?.getIdToken(true);

  return axios.post(
    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/producers`,
    producerData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

interface ProducerFieldStatistics {
  area: number;
  count: number;
}

interface ProducersFieldStatisticsResponseRow {
  id: string;
  name: string;
  activeFields: ProducerFieldStatistics;
  harvestedFields: ProducerFieldStatistics;
  plannedFields: ProducerFieldStatistics;
}

export async function sendGetProducersStatisticsRequest(companyId: string, user): Promise<ProducersFieldStatisticsResponseRow[]> {
  const token = await user?.getIdToken(true);
  const noCacheToken = new Date().getTime();

  const response = await axios.get(
    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API_URL}/producers/fieldStatistics?_time=${noCacheToken}&companyId=${companyId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
}
