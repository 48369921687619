import * as React from 'react';

import firebase from '../../data/firebase';
import { Loading, LoadingIcon } from '../Loading/Loading';
import './style.css';
import { classes } from '../../utils/dom';
import ImageLightbox from 'react-image-lightbox';
import Icon from '../Common/Icon';
import FileSaver from 'file-saver';

type Props = {
  imageRef: string;
  className?: string;
};
type State = {
  imageSource: string;
  visible: boolean;
  downloadingFile: boolean;
};

export default class FirebaseImage extends React.PureComponent<Props, State> {
  state = {
    imageSource: '',
    visible: false,
    downloadingFile: false,
  };

  componentDidMount() {
    const { imageRef } = this.props;

    const storage = firebase.storage();

    if (imageRef) {
      storage
        .ref(imageRef)
        .getDownloadURL()
        .then(url => {
          this.setState({ imageSource: url });
        });
    }
  }

  render() {
    const { className, imageRef, ...restProps } = this.props;
    const { imageSource } = this.state;

    let size = { width: 250, height: 150 };

    if (window.innerWidth > 320) {
      size = { width: 300, height: 250 };
    }

    if (!imageSource) {
      return (
        <div style={size}>
          <Loading />
        </div>
      );
    }

    const close = () => {
      this.setState({ visible: false });
    };
    return (
      <div>
        <img
          className={classes('firebase-image', className)}
          style={size}
          src={imageSource}
          onClick={() => this.setState({ visible: !this.state.visible })}
          alt=""
          {...restProps}
        />
        {this.state.visible
          ? (
          <ImageLightbox
            mainSrc={imageSource}
            onCloseRequest={close}
            reactModalStyle={{ overlay: { zIndex: 10000 } }}
            toolbarButtons={[
              !this.state.downloadingFile
                ? (
                <a
                  className="download-button ril__toolbarItemChild"
                  onClick={this.download.bind(this)}>
                  <Icon iconType="fal" name="download" />
                </a>
                  )
                : (
                <LoadingIcon style={{ height: 35 }} />
                  ),
            ]}
          />
            )
          : null}
      </div>
    );
  }

  download() {
    const uri = this.state.imageSource;

    this.setState({ downloadingFile: true });

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      this.setState({ downloadingFile: false });

      const blob = xhr.response;
      FileSaver.saveAs(blob, firebase.storage().refFromURL(uri).name);
    };
    xhr.onerror = () => {
      this.setState({ downloadingFile: false });
    };
    xhr.open('GET', uri);
    xhr.send();
  }
}
