import React, { useRef } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { find, get } from "lodash-es";
import Select from "react-select";
import { firestoreConnect } from "react-redux-firebase";

import { convertStringToRange } from "farmerjoe-common";
import { displaySettings } from "farmerjoe-common/lib/actions/actions";
import { editForm, openForm } from "farmerjoe-common/lib/actions/form";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getCompanyGroupProfileForLoggedInUser, getFieldTableStateForCompany } from "farmerjoe-common/lib/selectors/user";
import { getFormSchemas, getFormSubmissions, getOpenFormId } from "farmerjoe-common/lib/selectors/forms";
import { getFormSchemasQuery, getFormSubmissionsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Forms";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { openField } from "farmerjoe-common/lib/actions/field";

import DateRangeFilter from "./DateRangeFilter";
import Table from "./Table/Table";
import Icon from "../Common/Icon";
import NavbarBasic from "../Common/NavbarBasic";
import NoResults from "../Common/NoResults";
import withRouter from "../Router/withRouter";
import I18n from "../../language/i18n";
import type { FieldTableState } from "../../flowTypes";
import { saveFieldTableState } from "../../actions/fieldTable";

import "./style.css";

type Props = {
  formSchemas?: any;
  formSubmissions?: any;
  loading?: boolean;
  actions?: Record<string, any>;
  openCompany?: string;
  history?: Record<string, any>;
  tableState?: FieldTableState;
  openFormId?: string | null;
  boniturSettings?: Record<string, any>;
};

const EmptyView = () => (
  <NoResults text={"Keine Bonituren in den Eingegeben Zeitraum."} />
);


const Bonituren = (props: Props) => {
  const {
    formSchemas,
    formSubmissions,
    loading,
    tableState,
    openFormId,
    boniturSettings,
    openCompany,
  } = props;

  const schemaId = get(boniturSettings, "schema");

  const formSchema = schemaId
    ? find(formSchemas, { key: schemaId })
    : formSchemas[0];

  const onDataRowClick = (submission) => {
    props.actions?.openForm(submission.key);
    props.actions?.openField(submission.field_id);
    props.history?.push(
      `/company/${openCompany}/form/${submission.key}`,
    );
  };

  const onTableStateChange = (state) => {
    props.actions?.saveFieldTableState(openCompany, {
      ...tableState,
      "form-table": state,
    });
  };

  const selectBoniturOnChange = (formSchema) => {
    if (!formSchema) {
      return;
    }
    props.actions?.displaySettings(openCompany, "bonitur", {
      schema: formSchema.key,
    });
  };

  const selectBoniturGetOptionLabel = ({ name }) => `${name}`;
  const selectBoniturGetOptionValue = ({ key }) => key;

  const onExportClick = () => {
    tableRef.current?.exportData();
  };

  const tableRef = useRef<Table>(null);

  return (
    <div className="bonitur-table">
      <NavbarBasic title="Bonitur" />
      <div className="filters toolbar-container">
        <DateRangeFilter />
        <Select
          className="bonitur-select"
          value={formSchema}
          options={formSchemas}
          onChange={selectBoniturOnChange}
          getOptionLabel={selectBoniturGetOptionLabel}
          getOptionValue={selectBoniturGetOptionValue}
        />
        <button
          className="btn btn-secondary column-config-button"
          onClick={onExportClick}>
          <Icon iconType="fa" name="file-csv" style={{ fontSize: 20 }} />{" "}
          {I18n.t("export")}
        </button>
      </div>
      <Table
        ref={tableRef}
        formSchema={formSchema}
        formSubmissions={
          formSchema
            ? formSubmissions.filter(
              formSubmission => formSubmission.schema_id === formSchema.key,
            )
            : []
        }
        emptyView={<EmptyView />}
        tableState={tableState}
        onTableStateChange={onTableStateChange}
        onClick={onDataRowClick}
        openFormId={openFormId as any}
        loading={loading}
      />
    </div>
  );

};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openForm,
          editForm,
          saveFieldTableState,
          displaySettings,
          openField,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state) => {
  const openCompany = getOpenCompanyId(state);
  const formSchemas = getFormSchemas(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  const boniturSettings = get(state, [
    "filtersByCompany",
    openCompany,
    "displaySettings",
    "bonitur",
  ]);
  const schema = get(boniturSettings, "schema", "all");
  const date = get(boniturSettings, "date", "last:7");
  const range = convertStringToRange(date);
  const formSubmissions = getFormSubmissions(
    state,
    openCompany,
    browsingGroup,
    schema,
    range,
  );

  const loading = !hasLoaded(
    [
      getFormSubmissionsQuery(openCompany, browsingGroup, schema, range as any),
      getFormSchemasQuery(openCompany),
    ],
    state,
  );
  const tableState = getFieldTableStateForCompany(state, openCompany)[
    "form-table"
  ] || { columnIds: [], columnWidths: {}, sorting: [] };

  return {
    openCompany: openCompany,
    formSchemas: formSchemas,
    boniturSettings,
    browsingGroup,
    formSubmissions,
    myCompanyProfile: myCompanyProfile,
    loading,
    tableState,
    openFormId: getOpenFormId(state),
  };
};

export default compose<typeof Bonituren>(
  withRouter,
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const { openCompany, browsingGroup, boniturSettings } = props;

    const schema = get(boniturSettings, "schema", "all");
    const date = get(boniturSettings, "date", "last:7");
    const range = convertStringToRange(date);

    return [
      getFormSubmissionsQuery(openCompany, browsingGroup, schema, range as any),
      getFormSchemasQuery(openCompany),
    ];
  }),
)(Bonituren);
