import * as React from "react";
import { classes } from "../../utils/dom";
import "./style.css";

type Props = {
    hasError?: boolean;
    hasDanger?: boolean;
    hasFeedbackIcon?: boolean;
    multiline?: boolean;
    label?: string | React.ReactNode;
    containerClassName?: string;
    onFocus?: (...args: Array<any>) => any;
    onBlur?: (...args: Array<any>) => any;
    help?: string | React.ReactNode;
    error?: string | React.ReactNode;
    minRows?: number;
    maxRows?: number;

    autoCapitalize?: string;
    autoComplete?: string;
    autoCorrect?: string;
    autoFocus?: any;
    placeholder?: string;
    onChange?: (...args: Array<any>) => any;
    onKeyUp?: (...args: Array<any>) => any;

    id?: any;
    className?: any;
    type?: any;
    rows?: any;
    value?: any;
    name?: any;
    readOnly?: any;
    disabled?: any;
    style?: any;
    children?: React.ReactNode;
  };
type State = {
  focused: boolean;
  id: string;
};

export default class TextInput extends React.Component<Props, State> {
  constructor(props: any, context?: any) {
    super(props, context);

    this.state = {
      focused: false,
      id:
        this.props.id != null
          ? this.props.id
          : `input-${Math.floor(Math.random() * 10 ** 8)}`,
    };
  }

  componentWillUpdate(nextProps: Props) {
    if (this.props.id !== nextProps.id && nextProps.id != null) {
      this.setState({
        id: nextProps.id,
      });
    }
  }

  render() {
    const {
      className,
      containerClassName,
      hasError,
      hasDanger,
      hasFeedbackIcon,
      multiline,
      label,
      id,
      type,
      rows,
      help,
      error,
      minRows = 1,
      maxRows = 5,
      children,
      onFocus,
      onBlur,
      ...restProps
    } = this.props;

    const _className = classes("form-control", className);

    return (
      <div
        className={classes(
          "text-input-container form-group",
          hasError && "has-error",
          (hasError || hasDanger) && "has-danger",
          hasFeedbackIcon && "has-feedback",
          (this.state.focused || this.props.value) && "focused",
          containerClassName,
        )}>
        {multiline
          ? (
            <textarea
              className={_className}
              id={this.state.id}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}
              ref="input"
              rows={
                rows != null
                  ? rows
                  : Math.max(
                    minRows,
                    Math.min(
                      maxRows,
                      String(restProps.value).split("\n").length,
                    ),
                  )
              }
              {...restProps}
            />
          )
          : (
            <input
              className={_className}
              id={this.state.id}
              onFocus={this.onFocus.bind(this)}
              onBlur={this.onBlur.bind(this)}
              ref="input"
              type={type}
              {...restProps}
            />
          )}
        <span className="bar" />
        {label ? <label htmlFor={this.state.id}>{label}</label> : null}
        {help ? <div className={"help-block small"}>{help}</div> : null}
        {error ? <div className={"error-block small"}>{error}</div> : null}
      </div>
    );
  }

  onFocus() {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  onBlur() {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  blur() {
    (this.refs.input as any).blur();
  }

  focus() {
    (this.refs.input as any).focus();
  }
}
