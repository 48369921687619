import * as React from 'react';
import type { FieldMark } from '../../flowTypes';
import Icon from '../Common/Icon';
import { getColor } from 'farmerjoe-common/lib/utils/Colors';

type Props = {
  mark: FieldMark | null | undefined;
  style?: {};
};

export default function Badge({ mark, style }: Props) {
  if (mark) {
    if (mark.color) {
      return (
        <Icon
          name={'circle'}
          iconType={'fa'}
          className="field-mark-badge badge-color"
          style={{
            color: getColor(mark.color),
            ...style,
          }}
        />
      );
    } else if (mark.emoji) {
      return (
        <span className="field-mark-badge emoji-font" style={style}>
          {mark.emoji}
        </span>
      );
    }
  }
  return null;
}
