import React from "react";

import Icon from "./Icon";
import "./NewButton.css";
import { FJMUTED } from "../../styles/style";

type Props = {
  onClick: (...args: Array<any>) => any;
  hint?: string;
};
export const EditButton = ({ onClick, hint }: Props) => {
  return (
    <div
      className="top-bar-button top-bar-edit-button"
      onClick={() => onClick()}
      key="edit-item">
      <Icon
        name="cog"
        iconType={"fal"}
        style={{ color: FJMUTED, fontSize: 22 }}
      />
      {hint ? (
        <div
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "rgb(74, 74, 74)",
            marginLeft: "4px",
          }}>
          {hint}
        </div>
      ) : null }
    </div>
  );
};
