import React from "react";

import * as constants from "../../styles/style";
import Icon from "../../components/Common/Icon";
import "./style.css";

export default function favorite(locals) {
  if (locals.hidden) {
    return null;
  }

  const controlLabelStyle = {
    ...constants.styles.stdSize,
    color: constants.FJNEWGREEN,
  };

  if (locals.hasError) {}

  delete (controlLabelStyle as any).position;
  delete (controlLabelStyle as any).right;

  return (
    <div className="favorite">
      <button
        className="btn"
        onClick={() => locals.onChange(!locals.value)}
        title={locals.config.label}>
        {locals.value
          ? (
            <Icon name="star" iconType="fa" />
          )
          : (
            <Icon name="star" iconType="fal" />
          )}
      </button>
    </div>
  );
}
