import * as React from 'react';

import Icon from './Icon';

type Props = {
  onClick?: () => {};
  style?: {};
};

const defaultStyle: React.CSSProperties = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: 30,
  paddingTop: 2,
  paddingBottom: 2,
  borderTopLeftRadius: 5,
  backgroundColor: 'rgba(255,255,255,0.9)',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
};
export default class ButtonCamera extends React.PureComponent<Props> {
  render() {
    const { onClick, style } = this.props;

    const icon = (
      <Icon
        name={'camera'}
        iconType={'fj'}
        style={{ fontSize: 18, alignSelf: 'center' }}
      />
    );

    const styles = { ...defaultStyle, style };

    if (onClick) {
      return (
        <div style={styles} onClick={() => onClick && onClick()}>
          {icon}
        </div>
      );
    }

    return <div style={styles}>{icon}</div>;
  }
}
