import React, { Component } from 'react';

import { connect } from 'react-redux';

import firebase from '../../data/firebase';

import { translateError } from 'farmerjoe-common/lib/actions/errorTranslations';
import I18n from '../../language/i18n';
import { LoadingIcon } from '../Loading/Loading';
import Logo from './Logo';
import Icon from '../Common/Icon';
import TextInput from '../Common/TextInput';
import Page from '../Page/Page';
import { AlertDialog } from '../Dialog/Dialog';
import { get } from 'lodash-es';
import { bindActionCreators, compose } from 'redux';
import withRouter from '../Router/withRouter';
import * as constants from '../../styles/style';
import { captureException } from '../../utils/sentry';
import ShortHelp from './ShortHelp';
import { login } from '../../actions/profile';
import ShowHidePassword from './ShowHidePassword';
import { KeyCodes } from '../../flowTypes';
import { MIN_PASSWORD_LENGTH } from 'farmerjoe-common/lib/constants/registration';

// TODO: improve typings
type Props = any;
type State = any;

class RequestPassword extends Component<Props, State> {
  mounted?: boolean;

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      success: false,
      alertMessage: null,
      alertTitle: null,
      email: null,
      passwordType: true,
    };
  }

  resetPassword() {
    if (this.validate()) {
      this.setState({ isFetching: true });

      const auth = firebase.auth();

      auth
        .confirmPasswordReset(this.props.actionCode, this.state.password)
        .then(
          () => {
            this.setState({
              isFetching: false,
              success: true,
            });

            if (this.state.email) {
              this.props.actions
                .login(this.state.email, this.state.password)
                .then(() => {
                  this.props.history.replace('/');
                });
            }
          },
          error => {
            this.setState({
              isFetching: false,
              error,
            });
            console.warn(error);
            captureException(error, void 0);
          },
        );
    }
  }

  validate() {
    const password = this.state.password;
    if (!password) {
      this.setState({
        alertMessage: I18n.t('no_password_entered'),
        alertTitle: I18n.t('error'),
      });
      return false;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
      this.setState({
        alertMessage: I18n.t('password.minXchars', { length: MIN_PASSWORD_LENGTH }),
        alertTitle: I18n.t('error'),
      });
      return false;
    }

    return true;
  }

  componentDidMount() {
    const { actionCode } = this.props;
    if (actionCode) {
      this.verifyPasswordResetCode(actionCode);
    }
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.actionCode &&
      this.props.actionCode !== prevProps.actionCode
    ) {
      this.verifyPasswordResetCode(this.props.actionCode);
    }
  }

  verifyPasswordResetCode(actionCode) {
    this.setState({ isFetching: true, error: null });
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(email => {
        if (this.mounted) {
          this.setState({ email });
          this.setState({ isFetching: false });
        }
      })
      .catch(error => {
        if (this.mounted) {
          this.setState({ isFetching: false, error, invalidActionCode: true });
        }
      });
  }

  render() {
    let errorMessage: any = null;
    if (this.state.error) {
      if (
        this.state.error.code === 'auth/invalid-action-code' ||
        this.state.error.code === 'auth/expired-action-code'
      ) {
        errorMessage = I18n.t('resetPasswordLinkExpired');
      } else {
        errorMessage = translateError(this.state.error);
      }
    }

    const container = (
      <div style={{ position: 'relative' }}>
        <Logo />

        <h1 className="text-center mb-4">{I18n.t('password.reset')}</h1>

        {this.state.success || this.state.error
          ? (
          <div>
            <div
              style={{
                ...constants.styles.box,
                ...{
                  borderRadius: 5,
                  borderColor: '#CCC',
                  borderWidth: 1,
                },
              }}>
              <span style={{ marginBottom: 10, fontSize: 16 }}>
                <span style={{ color: '#707274' }}>
                  {this.state.error
                    ? errorMessage
                    : I18n.t('password.yourPasswordWasUpdated')}
                </span>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}>
              {this.state.success
                ? (
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                  disabled={!!this.state.isFetching}>
                  {I18n.t('back')}
                </button>
                  )
                : (
                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    this.props.history.push('/forgotpassword');
                  }}
                  disabled={!!this.state.isFetching}>
                  {I18n.t('password.forgotten')}
                </button>
                  )}
            </div>
          </div>
            )
          : (
          <p>{I18n.t('password.resetHelp')}</p>
            )}

        {!this.state.success && !this.state.invalidActionCode ? (
          <div>
            <div style={{ position: 'relative' }}>
              <TextInput
                placeholder={I18n.t('password.password')}
                autoFocus={true}
                style={{
                  paddingLeft: 45,
                }}
                containerClassName={'mt-4 mb-5'}
                id="password"
                name="password"
                autoComplete="off"
                type={this.state.passwordType ? 'password' : 'text'}
                onChange={e => this.setState({ password: e.target.value })}
                onKeyUp={e => {
                  if (e.keyCode === KeyCodes.Enter) {
                    this.resetPassword();
                  }
                }}
                label={
                  <Icon
                    name="ios-lock-outline"
                    style={{
                      position: 'absolute',
                      zIndex: 9999,
                      left: 15,
                      top: -1,
                      fontSize: 28,
                    }}
                  />
                }
                //   label={I18n.t('password.password')}
                disabled={!!this.state.isFetching}
              />

              <ShowHidePassword
                passwordType={this.state.passwordType}
                onPress={value => {
                  this.setState({ passwordType: value });
                  document.getElementById('password')?.focus();
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className="mt-5">
              <button
                className="btn btn-primary ml-auto"
                onClick={() => this.resetPassword()}
                disabled={!!this.state.isFetching}>
                {I18n.t('password.change')}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );

    return (
      <Page
        wrapperClassName="d-flex align-content-center justify-content-center flex-column login request-password"
        header={null}
        footer={null}>
        <div className="login-register login-sidebar">
          <div className="login-box card">
            <div className="card-body" style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}>
                {container}

                <ShortHelp />
              </div>
            </div>

            {this.state.isFetching
              ? (
              <div
                style={{
                  display: 'flex',
                  backgroundColor: 'rgba(255,255,255,0.6)',
                  position: 'absolute',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  zIndex: 9999,
                }}>
                <LoadingIcon style={{ transform: [{ scale: 1.5 }] }} />
              </div>
                )
              : null}
          </div>
        </div>
        <AlertDialog
          show={!!this.state.alertMessage}
          onClose={() =>
            this.setState({ alertMessage: null, alertTitle: null })
          }
          title={this.state.alertTitle}
          children={this.state.alertMessage}
        />
      </Page>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          login,
        },
      ),
      dispatch,
    ),
  };
}

const selector = state => {
  return {
    profile: get(state, 'firebase.profile'),
  };
};

export default compose<typeof RequestPassword>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(RequestPassword);
