import React from 'react';
import * as constants from '../../styles/style';
import I18n from '../../language/i18n';

import './styles.css';

const BtnReject = (props) => {
    const { onClick } = props;
    return (
        <div
            className='handle-request-btn-container'
            style={{
                backgroundColor: constants.FJAPPLERED,
            }}
            onClick={onClick}
        >
            <span className='handle-request-btn-text'>
                {I18n.t('fieldSharing.footerBtnReject')}
            </span>
        </div>
    );
};

export default BtnReject;
