import { cloneDeep } from "lodash-es";
import t from "tcomb-form";

import stylesheet from "../stylesheets/style";
import ModalDate from "../templates/ModalDate";

import {
  dateTransformer,
  numberTransformer,
} from "../transformers/transformers";
import { defaultDateFormat } from "../../tcomb/utils/options";
import I18n from "../../language/i18n";
import Yield from "../templates/Yield";

// Our form model
const model = t.struct({
  harvested_on: t.Date,
  yield: t.maybe(t.Number),
});

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

const multiline = cloneDeep(stylesheet);
multiline.textbox.normal.height = 36;
multiline.textbox.normal.marginTop = 60;
multiline.formGroup.normal.marginBottom = 50;

const options = function(state, ha, activeCropSownOnDate: Date) {
  return {
    stylesheet: stylesheet,
    auto: "none",
    fields: {
      harvested_on: {
        label: I18n.t("field.when_was_the_field_harvested"),
        maximumDate: new Date(),
        transformer: dateTransformer,
        config: {
          mode: "date",
          format: date => defaultDateFormat(date),
          minimumDate: activeCropSownOnDate,
        },
        stylesheet: formGroupOuter,
        template: ModalDate,
      },
      yield: {
        label: I18n.t("crop.yieldInT"),
        factory: Yield,
        value: state.yield,
        transformer: numberTransformer,
        config: {
          ha: ha,
        },
      },
    },
  };
};

// export let planModel = {planCropModel, options};
export default { model, options };
