import * as React from 'react';
import { isAbandonedField } from 'farmerjoe-common/lib/utils/Field';
import CommentForm from '../Comments/CommentForm';

import CameraImage from './CameraImage';
import SubMenu from './Menus/Plus';

type Props = {
  field: any;
  activeCrops: any;
  onInteraction?: (...args: Array<any>) => any;
  myCompanyProfile: any;
};

type State = {
  waitTime: boolean;
  currentComment: string;
};

const styles = {
  footer: {
    padding: 5,
    paddingTop: 7,
    paddingBottom: 7,
    borderTopColor: '#CCC',
    borderTopWidth: 1,
    zIndex: 1,
    backgroundColor: '#fbfbfb',
  },
};

export default class Footer extends React.Component<Props, State> {
  state = {
    waitTime: true,
    currentComment: '',
  };

  render() {
    const { field, activeCrops, myCompanyProfile } = this.props;

    return (
      <div
        className={'comment-footer d-flex flex-row justify-content-center align-items-center'}
        style={styles.footer}>
        {isAbandonedField(field.state)
          ? null
          : (
            <SubMenu
              field={field}
              activeCrops={activeCrops}
              myCompanyProfile={myCompanyProfile}
            />
          )
        }
        <CommentForm
          field={field}
          onChange={comment => {
            if (comment.length) {
              this.setState({
                waitTime: false,
                currentComment: comment,
              });
            } else {
              this.setState({
                waitTime: true,
                currentComment: comment,
              });
            }
          }}
          onFocus={() => {
            if (this.state.currentComment.length) {
              this.setState({ waitTime: false });
              return;
            }
            this.setState({ waitTime: true });
          }}
          onBlur={() => {
            if (this.state.currentComment.length) {
              this.setState({ waitTime: false });
              return;
            }
            this.setState({ waitTime: true });
          }}
        />

        {this.state.waitTime ? <CameraImage field={field} /> : null}
      </div>
    );
  }
}
