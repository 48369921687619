import { fetchJson } from "../../utils/fetch";

export function generateKey(token, body) {
  return fetchJson(
    `${process.env.REACT_APP_CLOUD_FUNCTIONS_API2_URL}/keys/generate`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    },
  );
}
