import I18n from '../../language/i18n';
import { isAdmin } from 'farmerjoe-common/lib/utils/User';
import {
  findIndex,
  get,
  sortBy,
} from 'lodash-es';
import { find, insert, propEq, remove } from 'ramda';

export function getRoleTranslation(role?: string | null) {
  const roles = {
    admin: I18n.t('acl.role.admin'),
    standard: I18n.t('acl.role.standard'),
    advisor: I18n.t('acl.role.advisor'),
  };

  return (role && roles[role]) ? roles[role] : I18n.t('acl.role.employee');
}

export function canAddNewEmployees(myCompanyProfile, auth) {
  return isAdmin(myCompanyProfile) && auth.emailVerified === true;
}

/**
 * Sorts users by their name and moves the current user to top.
 */
export function convertUserObjects(users, currentUserUid) {
  // Clone the users otherwise we modify the redux object
  // Find the index of the current user in the array
  const index = findIndex(users, user => user.key === currentUserUid);
  // Grab the current user out of the users array
  const you = find(propEq('key', currentUserUid), users);

  // Remove the user from the array only if found
  if (index !== -1 && you) {
    users = remove(index, 1, users);
  }

  // Transform the users object to an array and sort by name
  let sorted = sortBy(users, o => {
    return String(o.name).toLowerCase();
  });

  // Add the current user to the top of the list
  if (you) {
    sorted = insert(0, you, sorted);
  }

  return sorted;
}
