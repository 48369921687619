import React from "react";
import * as constants from "../../styles/style";
import { FJAPPLERED, styles } from "../../styles/style";
import ResultOn from "./ResultOn";
import I18n from "../../language/i18n";
import { get } from "lodash-es";
import { getColor } from "farmerjoe-common/lib/utils/Colors";
import { getAnalysisType } from "farmerjoe-common/lib/utils/Analyses";
import { classes } from "../../utils/dom";
import "./style.css";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { openAnalysis } from "farmerjoe-common/lib/actions/analysis";
import { getAnalysisNotifications } from "farmerjoe-common/lib/selectors/notifications";
import Icon from "../Common/Icon";
import { outputDate } from "farmerjoe-common";
import { AnalysisState } from "farmerjoe-common/lib/flow/types";
import { getAnalysisTypeShortTranslation } from "../../utils/analysis/Analysis";

// TODO: improve typings
type Props = any;

class AnalysesListItemField extends React.PureComponent<Props> {
  static defaultProps = {
    tags: false,
  };

  render() {
    const { ana, tags, selected, notifications } = this.props;

    const anaState = this._renderState(ana);
    return (
      <div
        className={classes(
          "list-item analyses-list-item",
          selected && "selected",
        )}
        onClick={() => {
          this.props.actions.openAnalysis(ana.key);
          this.props.history.push(
            `/company/${this.props.openCompany}/analysis/${ana.key}`,
          );
        }}
        key={"analyses" + ana.key}>
        <div
          style={{
            borderBottomWidth: 1,
            borderBottomColor: constants.FJBORDERCOLOR,
          }}>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}>
            <div>
              <span
                style={{ ...styles.strong, ...styles.stdSize, ...{ flex: 1 } }}>
                {ana.field.name}
              </span>
              {tags ? this.renderTags(ana) : null}
            </div>
            <span style={{ ...styles.stdSize }}>{ana.field.size || 0} ha</span>
          </div>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}>
            <span style={{ ...styles.small, ...styles.muted, ...{ flex: 1 } }}>
              {anaState}
            </span>
            {ana.state === AnalysisState.Inbox
              ? (
                <ResultOn result_on={ana.form.offers.result_on} />
              )
              : null}
            {ana.state === AnalysisState.Cancelled
              ? (
                <span style={{ color: FJAPPLERED }}>
                  {I18n.t("analysis.cancelled")}
                </span>
              )
              : null}
          </div>

          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}>
            <span style={{ ...styles.small, ...styles.muted, ...{ flex: 1 } }}>
              {I18n.t("analysis.number")}
            </span>
            <span style={{ ...styles.small, ...styles.muted }}>
              {ana.analyse_number}
            </span>
          </div>
        </div>
        {notifications && notifications.length
          ? (
            <div className="notification-badge-container">
              <Icon
                name={"circle"}
                iconType={"fa"}
                className="notification-badge"
              />
            </div>
          )
          : null}
      </div>
    );
  }

  private renderTags(ana) {
    const crop = get(ana, "form.crop");
    const analysisType = getAnalysisType(ana);
    const analysisTypeLabel = getAnalysisTypeShortTranslation(analysisType);

    const tagStyle = {
      backgroundColor: getColor(crop.color || "noCrop"),
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 3,
      paddingBottom: 3,
      flexDirection: "row" as "row",
      // justifyContent: 'flex-start',
      alignItems: "center",
      borderRadius: 5,
      maxWidth: 200,
      display: "flex",
    };
    return (
      <div style={{ flexDirection: "row", display: "flex" }}>
        <div
          style={{
            ...tagStyle,
            ...{ backgroundColor: getColor("noCrop"), marginRight: 5 },
          }}>
          <span>{analysisTypeLabel}</span>
        </div>
        <div style={tagStyle}>
          <span>{crop.name}</span>
        </div>
      </div>
    );
  }

  _renderState(a: any) {
    if (a.lab_accepted === true) {
      if (get(a, "lab_probe_taken_on", null) !== null) {
        return (
          <div>
            {I18n.t("analysis.probeTaken", {
              date: outputDate(a.lab_probe_taken_on),
            })}{" "}
            {/* <Emoji name={":+1:"} /> */}
          </div>
        );
      }

      return I18n.t("analysis.acceptedFromLab", {
        date: outputDate(a.lab_accepted_on),
      });
    }

    if (a.state === AnalysisState.Cancelled) {
      return outputDate(a.cancelled_on);
    }

    return I18n.t("analysis.orderFrom", { date: outputDate(a.created) });
  }
}

export default compose(
  connect(
    (state: any, ownProps: any) => ({
      notifications: getAnalysisNotifications(
        state,
        state.firebase.profile.uid,
        ownProps.ana.company_id,
        ownProps.ana.key,
      ),
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          openAnalysis,
        },
        dispatch,
      ),
    }),
  ),
)(AnalysesListItemField);
