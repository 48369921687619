import React from "react";
import Icon from "../Common/Icon";
import * as constants from "../../styles/style";

type TProps = {
  onClear: (args: any) => any;
  text: string;
};

const SummaryLine = ({onClear, text}: TProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "90%",
        margin: "0 auto",
        fontSize: "14px",
        fontStyle: "italic",
      }}
    >
      <div style={{color: constants.FJMUTEDDARK}}>{text}</div>
      <div onClick={onClear}>
        <Icon
          name={"ios-trash-outline"}
          style={{
            fontSize: 18,
            color: constants.FJAPPLERED,
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

export default SummaryLine;
