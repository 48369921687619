import React from "react";
import UsersListItem from "./UsersListItem";
import { UsersListProps } from "./types";

const UsersList = ({ users, setSelectedUser, setCurrentDialog }: UsersListProps) => {
  return users.map((c) => {
    return <UsersListItem user={c} key={c.uid} setSelectedUser={setSelectedUser} setCurrentDialog={setCurrentDialog} />;
  });
};

export default UsersList;
