import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as commentUtils from '../../../utils/Comment';

const style = { flexDirection: 'row' as 'row', flex: 1 };
const textStyle = { marginLeft: 5 };

// TODO: improve typings
type Props = any;
type State = any;

export default class FieldMark extends PureComponent<Props, State> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  render() {
    const { comment } = this.props;
    const text = commentUtils.getCommentText(comment);

    return (
      <div style={style}>
        <div style={{ flex: 1 }}>
          <span style={textStyle}>{text}</span>
        </div>
      </div>
    );
  }
}
