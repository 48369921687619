import * as React from 'react';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import { addKeyToObj } from '../../data/util';

import { Loading } from '../Loading/Loading';

import { isLoaded, firestoreConnect } from 'react-redux-firebase';

import Modal from 'react-bootstrap-modal';

import { getFormSchemas } from 'farmerjoe-common/lib/selectors/forms';
import { getFormSchemasQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Forms';
import { openFormSchema, editForm } from 'farmerjoe-common/lib/actions/form';

// import type { Company, FormSchema } from 'farmerjoe-common'
type Props = {
  company: any; // Company
  openView: (...args: Array<any>) => any;
  goBack: (...args: Array<any>) => any;
  loaded: boolean;
  templates: any[]; // FormSchema[]
  actions: {
    editForm: typeof editForm;
    openFormSchema: typeof openFormSchema;
  };
};
class FormSelectBonitur extends React.PureComponent<Props> {
  render() {
    const { loaded, templates, actions, openView } = this.props;

    if (!loaded) {
      return <Loading />;
    }

    const content = templates.map((template) => {
      return (
        <div
          key={template.key}
          className={'card fj-pointer'}
          onClick={() => {
            actions.editForm(null);
            actions.openFormSchema(template.key);
            openView('CreateBoniturForm');
          }}
        >
          <div className="card-body">
            <h4 className={'card-title'}>{template.name}</h4>
            <div className="card-text">{template.description}</div>
          </div>
        </div>
      );
    });

    return (
      <Modal.Body onClick={(e) => e.stopPropagation()}>
        <div style={{ flex: 1, paddingTop: 10 }}>{content}</div>
      </Modal.Body>
    );
  }
}

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);

  const templates = getFormSchemas(state);
  templates.sort(templatesSortFn);

  return {
    openCompany: openCompany,
    company: addKeyToObj(openCompany, company),
    loaded: isLoaded(templates),
    templates: templates,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          openFormSchema,
          editForm,
        },
      ),
      dispatch,
    ),
  };
}

const templatesSortFn = (templA, templB) => {
  if (templA.typeId < templB.typeId) {
    return -1;
  }

  if (templA.typeId > templB.typeId) {
    return 1;
  }

  return 0;
};

const wrappedFormSelectLab = firestoreConnect((props) => {
  const { openCompany } = props;

  return [getFormSchemasQuery(openCompany)];
})(FormSelectBonitur);

export default connect(selector, mapDispatchToProps)(wrappedFormSelectLab);
