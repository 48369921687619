import * as React from 'react';
import I18n from '../../language/i18n';
import { FJAPPLEGREEN, FJWHITE } from '../../styles/style';
import AlertBox from '../Common/AlertBox';
import Icon from '../Common/Icon';

export const ActivationSuccess = () => {
  return (
    <div
      style={{
        flex: 1,
        alignItems: 'center',
        paddingTop: 100,
      }}>
      <Icon
        name={'check-circle'}
        iconType={'fa'}
        light
        style={{ color: FJAPPLEGREEN, fontSize: 150, marginBottom: 50 }}
      />

      <AlertBox
        text={
          <div
            style={{
              padding: 10,
            }}>
            <div
              style={{
                color: FJWHITE,
                fontSize: 18,
                fontWeight: 'bold',
              }}>
              {I18n.t('activation.accountVerified')}
            </div>
            <div
              style={{
                color: FJWHITE,
                fontSize: 18,
                fontWeight: 'bold',
                marginTop: 10,
              }}>
              {I18n.t('activation.haveFunWithFJ')}
            </div>
          </div>
        }
        type={'success'}
      />
    </div>
  );
};
