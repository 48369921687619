import { get } from "lodash-es";
import { GroupType } from "farmerjoe-common/lib/flow/types";
import { TableListViewState } from "../flowTypes";

const SETTINGS_KEY_SUFFIX = "sView";

/**
 * Stores the current type of the Groups (advisors, producers) view in the database.
 * EN-255. To optimize database IO, maybe we could store this setting in the browser?
 */
export function setGroupsView(companyId, groupType: GroupType, view: TableListViewState) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    const state = getState();
    const profile = state.firebase.profile;

    const settingsKey = getSettingsKey(groupType);

    return db
      .collection("users")
      .doc(profile.uid)
      .set(
        {
          [settingsKey]: {
            ...profile[settingsKey],
            [companyId]: view,
          },
        },
        { merge: true },
      );
  };
}

export function getGroupsView(state, groupType: GroupType, inputCompanyId): TableListViewState {
  const settingsKey = getSettingsKey(groupType);

  // XXX: We have removed the classical view for traders and producers
  // https://farmerjoe.atlassian.net/browse/EN-1576
  if (["producer", "trader"].includes(groupType)) {
    return TableListViewState.Table;
  }

  return get(state, `firebase.profile.${settingsKey}.${inputCompanyId}`, "classic");
}

function getSettingsKey(type: GroupType) {
  return type + SETTINGS_KEY_SUFFIX;
}
