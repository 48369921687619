import * as React from 'react';
import Switch from 'react-switch';
import './style.css';

type Props = {
  name: string;
  onChange: (...args: Array<any>) => any;
  value: boolean;
};
export default class RSwitch extends React.PureComponent<Props> {
  render() {
    const { name, value, onChange } = this.props;

    return (
      <Switch
        id={name}
        onChange={onChange}
        checked={value}
        checkedIcon={false}
        uncheckedIcon={false}
        offColor={'#FFF'}
        onColor={'#7EB549'}
        className={`fj-switch ${value ? 'active' : ''}`}
      />
    );
  }
}
