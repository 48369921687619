import * as React from 'react';
import type { Employee, Field } from '../../flowTypes';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import { editEmployee } from 'farmerjoe-common/lib/actions/employee';

import { firestoreConnect } from 'react-redux-firebase';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import {
  getActiveFields,
  searchForFieldAmongTheUniverse,
} from 'farmerjoe-common/lib/selectors/fields';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';

import {
  getCompanyGroupProfileForLoggedInUser,
  getGroupUsers,
  userSearchWord,
  userState,
} from 'farmerjoe-common/lib/selectors/user';

import EmployeesOnFieldComponent from '../../components/Employees/EmployeesOnField';
import { getUsersQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Users';
import {
  COMPANY_MAIN_GROUP_KEY,
} from 'farmerjoe-common/lib/selectors/groups';
import { getQueryName } from 'farmerjoe-common/lib/utils/firestoreRedux/Utils';
import { get } from 'lodash-es';

type Props = {
  loading: boolean;
  myCompanyProfile: Employee;
  actions: {
    editEmployee: (...args: Array<any>) => any;
  };
  field: Field;
};

class Employees extends React.Component<Props> {
  render() {
    const { loading } = this.props;

    if (loading) {
      return null;
    }

    return <EmployeesOnFieldComponent {...this.props} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          filters,
          editEmployee,
        },
      ),
      dispatch,
    ),
  };
}

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  let fields = getActiveFields(state, openCompany, state.firebase.auth.uid);

  const openField = selectors.getOpenFieldId(state);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.id, openField);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  const search = userSearchWord(state, openCompany);

  const groupId = get(field, 'group_id', COMPANY_MAIN_GROUP_KEY);

  const users = getGroupUsers(state, openCompany, groupId);

  const hasLoadedEverything = hasLoaded(
    [getQueryName(getUsersQuery(openCompany, groupId))],
    state,
  );
  fields = hasLoadedEverything && !fields ? [] : fields;

  return {
    loading: !hasLoadedEverything,
    email: state.firebase.profile.email,
    myCompanyProfile: myCompanyProfile,
    openCompany: openCompany,
    company: company,
    fields: fields,
    field: field,
    users: users,
    search: search,
    filter: userState(state, openCompany),
    auth: state.firebase.auth,
    groupId,
  };
};

const wrappedEmployees = firestoreConnect(props => {
  const { openCompany, groupId } = props;

  if (!props.email) {
    return [];
  }

  return [
    {
      collection: 'companies',
      doc: props.openCompany,
    },
    getUsersQuery(openCompany, groupId),
  ];
})(Employees);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedEmployees);
