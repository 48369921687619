import React, { useState, useEffect } from "react";
import { updateSubscription, requestInvoice } from "farmerjoe-common/lib/requests/billing";
import {
  SUBSCRIPTION_STATE_CANCELLED,
  SUBSCRIPTION_STATE_ACTIVE,
  SUBSCRIPTION_STATE_IN_REVIEW,
  BILLING_CYCLE_MONTHLY,
} from "farmerjoe-common/lib/constants/billing";
import {
  PAYMENT_METHOD_CARD,
} from "farmerjoe-common/lib/flow/types";

import useCurrentUsage from "./useCurrentUsage";
import usePaymentMethod from "./usePaymentMethod";
import usePaymentMethods from "./usePaymentMethods";

import SubscriptionPlans from "./SubscriptionPlans";
import ErrorDialog from "./ErrorDialog";
import SuccessDialog from "./SuccessDialog";
import BuySeatsModal from "./BuySeatsModal";
import BuySeatsInput from "./BuySeatsInput";
import RemoveSeatsModal from "./RemoveSeatsModal";
import RemoveSeatsInput from "./RemoveSeatsInput";
import FreePlanDialog from "./FreePlanDialog";
import PaidPlanDialog from "./PaidPlanDialog";
import EnterprisePlanDialog from "./EnterprisePlanDialog";
import CurrentPlan from "./CurrentPlan";
import UpdatePaymentsMethodModal from "./UpdatePaymentMethodModal";
import BillingDetails from "./BillingDetails";
import PaymentOptions from "./PaymentOptions";

import { TSubscription, TSubscriptionPlan } from "./types";

import { getUidToken } from "../../utils/auth";
import I18n from "../../language/i18n";
import { Loading } from "../Loading/Loading";
import withRouter from "../Router/withRouter";
import UpdatePaymentMethod from "./UpdatePaymentMethod";

type Props = {
  companyId: string;
  currentSubscriptionPlan: string;
  subscriptionPlans: {
    [key: string]: TSubscriptionPlan;
  };
  loading?: boolean;
  actions: any;
  currentSubscription: TSubscription;
  company?: any;
  billingDetails?: any;
  history?: any;
};

const Billing = (props: Props) => {
  const { loading, subscriptionPlans, currentSubscriptionPlan, companyId, currentSubscription, billingDetails } = props;
  const [ showBuySeatsDialog, setShowBuySeatsDialog ] = useState(false);
  const [ showReduceSeatsDialog, setShowReduceSeatsDialog ] = useState(false);

  const [ newSubscriptionPlan, setNewSubscriptionPlan ] = useState<TSubscriptionPlan | null>(null);
  const [ newSeats, setNewSeats ] = useState(1);
  const [ showSuccessDialog, setShowSuccessDialog ] = useState(false);
  const [ successContent, setSuccessContent ] = useState({ title: "", msg: "" });
  const [ showErrorDialog, setShowErrorDialog ] = useState(false);
  const [ paymentsError, setPaymentsError ] = useState(null);

  const [ showFreePlanDialog, setShowFreePlanDialog ] = useState(false);
  const [ showPaidPlanDialog, setShowPaidPlanDialog ] = useState(false);
  const [ showEnterprisePlanDialog, setShowEnterprisePlanDialog ] = useState(false);
  const [ showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal ] = useState(false);
  const [ updateFromApi, setUpdateFromApi ] = useState(false);
  const [ currentUsage, currentUsageLoading ] = useCurrentUsage(companyId, updateFromApi);
  const [ paymentMethods, paymentMethodsLoaing ] = usePaymentMethods(companyId);

  const { stripeCustomerId } = billingDetails;
  const [paymentMethod, paymentMethodLoading] = usePaymentMethod(companyId, stripeCustomerId);

  useEffect(() => {
    let ignore = false;
    const handlePaymentStatus = () => {
      const paymentStatus = new URLSearchParams(window.location.search).get("redirect_status");
      if (paymentStatus === "succeeded") {
        setSuccessContent({
          title: I18n.t("billing.successUpgradeTitle"),
          msg: I18n.t("billing.successUpgradeMsg"),
        });
        setShowSuccessDialog(true);
      }
      if (paymentStatus === "requires_payment_method") {
        setShowErrorDialog(true);
      }
    };
    if (!ignore) handlePaymentStatus();
    return () => { ignore = true; };
  }, []);

  useEffect(() => {
    return () => {
      setUpdateFromApi(false);
    };
  });

  if (!loading || currentUsageLoading || paymentMethodLoading || paymentMethodsLoaing) {
    return  <Loading />;
  }

  const useCreditCard = paymentMethods.includes(PAYMENT_METHOD_CARD);
  const currentPlan = subscriptionPlans[currentSubscriptionPlan];
  const subscriptionInReview = currentSubscription ? currentSubscription.state === SUBSCRIPTION_STATE_IN_REVIEW : false;
  const quotaReached = currentUsage.totalUsers > currentUsage.maxAllowedUsers;
  const isPaid = currentSubscription && currentSubscription.type === "paid";

  const confirmSubscriptionUpdate = (seatsQty: number) => {
    const { stripeSubscriptionId } = currentSubscription;
    return getUidToken()
      .then((uidToken) => {
        return updateSubscription(uidToken, stripeSubscriptionId as string, seatsQty);
      }).catch((e) => {
        console.log(e);
        setShowErrorDialog(true);
      }).finally(() => {
        setShowBuySeatsDialog(false);
        setShowReduceSeatsDialog(false);
      });
  };


  const onInvoiceRequest = (seatsQty: number) => {
    return getUidToken()
      .then((uidToken) => {
        return requestInvoice(uidToken, companyId, seatsQty);
      });
  };

  return (
    <>
      {subscriptionInReview ? (
        <div
          style={{
            margin: "1em",
            background: "#707274",
            borderRadius: "15px",
            padding: "0 1em",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {I18n.t("billing.inReviewBanner")}
        </div>
      ) : null}

      {quotaReached && !subscriptionInReview ? (
        <div
          style={{
            margin: "1em",
            background: "rgb(197, 53, 32)",
            borderRadius: "15px",
            padding: "0 1em",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {I18n.t("billing.upgradeNeededBanner")}
        </div>
      ) : null}

      <SubscriptionPlans
        subscriptionPlans={subscriptionPlans}
        currentSubscription={currentSubscription}
        currentSubscriptionPlan={currentSubscriptionPlan}
        onSelect={(_newSubscriptionPlan) => {
          setNewSubscriptionPlan(_newSubscriptionPlan);
          switch ((_newSubscriptionPlan as any).type) {
          case "free":
            setShowFreePlanDialog(true);
            break;
          case "paid":
            setShowPaidPlanDialog(true);
            break;
          case "enterprise":
            setShowEnterprisePlanDialog(true);
            break;
          default:
            break;
          }
        }}
      />

      <CurrentPlan
        currentPlan={currentPlan}
        currentUsage={currentUsage}
        onBuySeats={() => setShowBuySeatsDialog(true)}
        onReduceSeats={() => setShowReduceSeatsDialog(true)}
        currentSubscriptionInReview={subscriptionInReview}
      />

      <BillingDetails billingDetails={billingDetails} actions={props.actions} companyId={companyId} />

      {paymentMethod && !subscriptionInReview && isPaid ? (
        <div style={{ 
          margin: "1em 2em",
        }}>
          <div style={{ 
            fontSize: "32px",
            borderBottom: "1px solid grey",
            margin: "1em 0",
          }}>
            <span style={{ fontSize: 28 }}>
              {I18n.t("billing.paymentMethod")}
            </span>
          </div>
          <div style={{ }}>
            <span>
              {showPaymentMethodInfo(paymentMethod)}
            </span>
          </div>
          <div style={{ }}>
            <div style={{}}>
              <span style={{cursor: "pointer", fontWeight: "bold", color: "rgb(138,180,88)", fontSize: 14}} onClick={() => {
                setShowUpdatePaymentMethodModal(true);
              }}>{I18n.t("billing.updateYourCard")}</span>
            </div>
          </div>
        </div>
      ) : null}


      {showFreePlanDialog ? (
        <FreePlanDialog
          show={showFreePlanDialog}
          onClose={() => setShowFreePlanDialog(false)}
          subscriptionPlan={newSubscriptionPlan}
          currentUsage={currentUsage}
          onSelect={() => {
            return props
              .actions
              .updateSubscription(currentSubscription.key, { state: SUBSCRIPTION_STATE_CANCELLED })
              .then(() => {
                setShowFreePlanDialog(false);
                setSuccessContent({
                  title: I18n.t("billing.successDowngradeTitle"),
                  msg: I18n.t("billing.successDowngradeMsg"),
                });
                setShowSuccessDialog(true);
              });
          }}
        />
      ) : null}

      {showPaidPlanDialog ? (
        <PaidPlanDialog
          show={showPaidPlanDialog}
          onClose={() => setShowPaidPlanDialog(false)}
          subscriptionPlan={newSubscriptionPlan}
          currentUsage={currentUsage}
        >
          <div style={{margin: "20px"}}>
            <PaymentOptions
              useCreditCard={useCreditCard}
              companyId={companyId}
              quantity={quotaReached ? currentUsage.totalUsers : (newSubscriptionPlan as any).users}
              onSuccessCardPayment={(subscriptionData) => {
                const quantity = quotaReached ? currentUsage.totalUsers : (newSubscriptionPlan as any).users;
                return props
                  .actions
                  .storeCompanySubscription(companyId, quantity, SUBSCRIPTION_STATE_ACTIVE, subscriptionData, (newSubscriptionPlan as any).key, (newSubscriptionPlan as any).type, PAYMENT_METHOD_CARD, BILLING_CYCLE_MONTHLY)
                  .then(() => {
                    setShowPaidPlanDialog(false);
                    setSuccessContent({
                      title: I18n.t("billing.successUpgradeTitle"),
                      msg: I18n.t("billing.successUpgradeMsg"),
                    });
                    setShowSuccessDialog(true);
                  });
              }}
              onErrorCardPayment={(error) => {
                setPaymentsError(error);
                setShowErrorDialog(true);
                setShowPaidPlanDialog(false);
              }}
              onInvoiceRequest={() => {
                const quantity = quotaReached ? currentUsage.totalUsers : (newSubscriptionPlan as any).users;
                return onInvoiceRequest(quantity)
                  .then(() => {
                    setSuccessContent({
                      title: I18n.t("billing.requestToTeamTitle"),
                      msg: I18n.t("billing.requestToTeamMsg"),
                    });
                    setShowSuccessDialog(true);
                  })
                  .catch((e) => {
                    console.log(e);
                    setShowErrorDialog(true);
                  }).finally(() => {
                    setShowPaidPlanDialog(false);
                  });
              }}
            />
          </div>
        </PaidPlanDialog>
      ) : null}

      {showEnterprisePlanDialog ? (
        <EnterprisePlanDialog
          show={showEnterprisePlanDialog}
          onClose={() => setShowEnterprisePlanDialog(false)}
          companyId={companyId}
          billingDetails={billingDetails}
          onSuccess={() => {
            setSuccessContent({
              title: I18n.t("billing.requestToEnterpriseTitle"),
              msg: I18n.t("billing.requestToEnterpriseMsg"),
            });
            setShowSuccessDialog(true);
          }}
        />
      ): null}

      {showBuySeatsDialog ? (
        <BuySeatsModal
          show={showBuySeatsDialog}
          onClose={() => setShowBuySeatsDialog(false)}
        >
          <div style={{}}>
            <BuySeatsInput
              value={newSeats}
              onChange={setNewSeats}
              price={currentPlan.price}
              currentQuantity={currentSubscription.quantity}
              onConfirm={(quantity: number) => {
                const subscriptionPaymentType = currentSubscription.paymentMethod;
                if (subscriptionPaymentType) {
                  if (subscriptionPaymentType === PAYMENT_METHOD_CARD) {
                    return confirmSubscriptionUpdate(quantity)
                      .then(() => {
                        setSuccessContent({
                          title: I18n.t("billing.responseToBuySeatsTitle"),
                          msg: I18n.t("billing.responseToBuySeatsMsg", {quantity}),
                        });
                        setShowSuccessDialog(true);
                      })
                      .catch((e) => {
                        console.log(e);
                        setPaymentsError(e);
                        setShowErrorDialog(true);
                      }).finally(() => {
                        setNewSeats(1);
                      });
                  }
                  return onInvoiceRequest(quantity)
                    .then(() => {
                      setSuccessContent({
                        title: I18n.t("billing.requestToBuySeatsTitle"),
                        msg: I18n.t("billing.requestToBuySeatsMsg"),
                      });
                      setShowSuccessDialog(true);
                      setShowBuySeatsDialog(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setPaymentsError(e);
                      setShowErrorDialog(true);
                    }).finally(() => {
                      setNewSeats(1);
                    });
                }

                // We don't have the payment method in the subscription
                // so we check if we can use credit card and we have already set one

                if (paymentMethod && useCreditCard) {
                  return confirmSubscriptionUpdate(quantity)
                    .then(() => {
                      setSuccessContent({
                        title: I18n.t("billing.responseToBuySeatsTitle"),
                        msg: I18n.t("billing.responseToBuySeatsMsg", {quantity}),
                      });
                      setShowSuccessDialog(true);
                    })
                    .catch((e) => {
                      console.log(e);
                      setPaymentsError(e);
                      setShowErrorDialog(true);
                    }).finally(() => {
                      setNewSeats(1);
                    });
                }

                // We don't have the payment method in the subscription
                // We cannot use credit card (not a EU VAT country)
                return onInvoiceRequest(quantity)
                  .then(() => {
                    setSuccessContent({
                      title: I18n.t("billing.requestToBuySeatsTitle"),
                      msg: I18n.t("billing.requestToBuySeatsMsg"),
                    });
                    setShowSuccessDialog(true);
                    setShowBuySeatsDialog(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setPaymentsError(e);
                    setShowErrorDialog(true);
                  }).finally(() => {
                    setNewSeats(1);
                  });
              }}
            />
          </div>
        </BuySeatsModal>
      ) : null}

      {showReduceSeatsDialog ? (
        <RemoveSeatsModal
          show={showReduceSeatsDialog}
          onClose={() => setShowReduceSeatsDialog(false)}
        >
          <div style={{}}>
            <RemoveSeatsInput
              value={newSeats}
              onChange={setNewSeats}
              price={currentPlan.price}
              currentQuantity={currentSubscription.quantity}
              minUsage={currentUsage.totalUsers}
              onConfirm={(quantity: number) => {


                const subscriptionPaymentType = currentSubscription.paymentMethod;
                if (subscriptionPaymentType) {
                  if (subscriptionPaymentType === PAYMENT_METHOD_CARD) {
                    return confirmSubscriptionUpdate(quantity)
                      .then(() => {
                        setSuccessContent({
                          title: I18n.t("billing.responseToReduceSeatsTitle"),
                          msg: I18n.t("billing.responseToReduceSeatsMsg"),
                        });
                        setShowSuccessDialog(true);
                      })
                      .catch((e) => {
                        console.log(e);
                        setPaymentsError(e);
                        setShowErrorDialog(true);
                      }).finally(() => {
                        setNewSeats(1);
                      });
                  }
                  return onInvoiceRequest(quantity)
                    .then(() => {
                      setSuccessContent({
                        title: I18n.t("billing.requestToReduceSeatsTitle"),
                        msg: I18n.t("billing.requestToReduceSeatsMsg"),
                      });
                      setShowSuccessDialog(true);
                      setShowReduceSeatsDialog(false);
                    })
                    .catch((e) => {
                      console.log(e);
                      setPaymentsError(e);
                      setShowErrorDialog(true);
                    }).finally(() => {
                      setNewSeats(1);
                    });
                }

                // We don't have the payment method in the subscription
                // so we check if we can use credit card and we have already set one

                if (paymentMethod && useCreditCard) {
                  return confirmSubscriptionUpdate(quantity)
                    .then(() => {
                      setSuccessContent({
                        title: I18n.t("billing.responseToReduceSeatsTitle"),
                        msg: I18n.t("billing.responseToReduceSeatsMsg"),
                      });
                      setShowSuccessDialog(true);
                    })
                    .catch((e) => {
                      console.log(e);
                      setPaymentsError(e);
                      setShowErrorDialog(true);
                    }).finally(() => {
                      setNewSeats(1);
                    });
                }
                return onInvoiceRequest(quantity)
                  .then(() => {
                    setSuccessContent({
                      title: I18n.t("billing.requestToReduceSeatsTitle"),
                      msg: I18n.t("billing.requestToReduceSeatsMsg"),
                    });
                    setShowSuccessDialog(true);
                    setShowReduceSeatsDialog(false);
                  })
                  .catch((e) => {
                    console.log(e);
                    setPaymentsError(e);
                    setShowErrorDialog(true);
                  }).finally(() => {
                    setNewSeats(1);
                  });
              }}
            />
          </div>
        </RemoveSeatsModal>
      ) : null}

      { showSuccessDialog ? (
        <SuccessDialog
          show={showSuccessDialog}
          onClose={() => {
            setShowSuccessDialog(false);
            setUpdateFromApi(true);
          }}
          title={successContent.title}
          msg={successContent.msg}
        />
      ) : null }

      { showErrorDialog ? (
        <ErrorDialog show={showErrorDialog} onClose={() => setShowErrorDialog(false)} error={paymentsError} />
      ) : null }

      { showUpdatePaymentMethodModal ? (
        <UpdatePaymentsMethodModal
          show={showUpdatePaymentMethodModal}
          onClose={() => {
            setShowUpdatePaymentMethodModal(false);
            setUpdateFromApi(true);
          }}
        >
          <UpdatePaymentMethod
            companyId={companyId}
            onError={(error) => {
              setPaymentsError(error);
              setShowUpdatePaymentMethodModal(false);
              setShowErrorDialog(true);
            }}
            onSuccess={() => {
              setSuccessContent({title: "", msg: I18n.t("billing.updateCardSuccessMsg")});
              setShowUpdatePaymentMethodModal(false);
              setShowSuccessDialog(true);
            }}/>
        </UpdatePaymentsMethodModal>
      ) : null }
    </>
  );
};

export default withRouter(Billing);

const showPaymentMethodInfo = (paymentMethod: {
  card?: {
    brand: string;
    last4: string;
    exp_month: number;
    exp_year: number;
  };
}): string|null => {
  if (!paymentMethod) {
    return null;
  }

  const { card } = paymentMethod;
  if (!card) {
    return null;
  }
  return `${card.brand} ****${card.last4}, ${card.exp_month}/${card.exp_year}`;
};

