import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Common/Icon';
import * as constants from '../../styles/style';
import BoxProbeNumber from './BoxProbeNumber';
import Lab from './Lab';
import { get } from 'lodash-es';
import I18n from '../../language/i18n';
import Field from './Field';
import Box from '../Common/Box/Box';
import FormTextRow from '../Common/FormTextRow';
import AnalysisState from './AnalysisState';
import CostBox from '../Common/CostBox';
import { AnalysisState as AnalysisStateTypes } from 'farmerjoe-common/lib/flow/types';

import { render as renderAnalysisForm } from '../../utils/analysis/resultDisplay/render';
import { outputDate } from 'farmerjoe-common';
import { noLabelingReminder } from 'farmerjoe-common/lib/utils/Analyses';

const SERVICE_VALUE_A = 'a';

// TODO: improve typings
type Props = any;

class Order extends Component<Props> {
  private _scroll: any;

  static propTypes = {
    analysis: PropTypes.object.isRequired,
    showField: PropTypes.bool,
    labLinks: PropTypes.bool,
  };

  static get defaultProps() {
    return {
      showField: true,
      labLinks: true,
    };
  }

  componentWillReceiveProps(next) {
    const { analysis } = this.props;
    if (analysis.state !== next.analysis.state && next.analysis.state === AnalysisStateTypes.Cancelled) {
      this._scroll && this._scroll.scrollTo({ x: 0, y: 0 });
    }
  }

  render() {
    const { analysis, showField, labLinks } = this.props;
    const lab = analysis.labSelected;

    let probeDate = get(analysis, 'form.offers.probe_date');
    const needFast = get(analysis, 'form.offers.need_fast');
    let resultOn = get(analysis, 'form.offers.result_on');
    const service = get(analysis, 'form.offers.service', SERVICE_VALUE_A);

    if (probeDate) {
      probeDate = outputDate(probeDate);
    }
    if (needFast) {
      const needOptions = {
        a: I18n.t('analysis.needFastToday'),
        b: I18n.t('analysis.needFastTomorrow'),
      };
      probeDate = probeDate + ' / ' + needOptions[needFast];
    }
    if (resultOn) {
      resultOn = outputDate(resultOn);
    }

    if (!analysis.formConfig) {
      return (
        <div>
          <span>Old Analysis. No formConfig</span>
        </div>
      );
    }

    return (
      <div
        ref={component => (this._scroll = component)}
        style={{
          flex: 1,
          backgroundColor: constants.FJBACKGROUND,
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
        }}>
        <AnalysisState analysis={analysis} />

        <Box
          header={I18n.t('analysis.sample')}
          content={
            <div>
              <FormTextRow
                value={probeDate}
                label={I18n.t('analysis.sample_date')}
              />
              <FormTextRow
                value={resultOn}
                label={I18n.t('analysis.result_on')}
              />
              <FormTextRow
                value={
                  <div>
                    <div>{analysis.created_by.name}</div>
                    <div>{analysis.created_by.email}</div>
                    <a
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      href={`tel:${analysis.created_by.phoneNumber}`}>
                      <Icon
                        name={'ios-call'}
                        style={{
                          color: constants.FJNEWGREEN,
                          backgroundColor: 'transparent',
                          fontSize: 17,
                          marginTop: 2,
                        }}
                      />{' '}
                      <span
                        style={{
                          color: constants.FJNEWGREEN,
                          paddingLeft: 4,
                        }}>
                        {analysis.created_by.phoneNumber}
                      </span>
                    </a>
                  </div>
                }
                label={I18n.t('analysis.sample_taker')}
              />
            </div>
          }
        />

        <BoxProbeNumber
          probeNumber={analysis.analyse_number}
          note={this.isLabelingReminderNeeded() ? I18n.t('analysis.pleaseLabelProbe') : null}
        />

        <Box
          header={I18n.t('laboratory')}
          content={
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Lab lab={lab} links={labLinks} />
            </div>
          }
        />

        {showField ? <Field analysis={analysis} /> : null}

        {renderAnalysisForm(analysis.form, analysis.formConfig)}

        {this.renderCustomEmailsIfNeeded()}

        {analysis.analysisCost ? <CostBox {...analysis.analysisCost} /> : null}
      </div>
    );
  }

  private isLabelingReminderNeeded() {
    const { analysis } = this.props;
    const service = get(analysis, 'form.offers.service', SERVICE_VALUE_A);
    const formConfig = get(analysis, 'formConfig', {});

    return service === SERVICE_VALUE_A && !noLabelingReminder(formConfig);
  }

  private renderCustomEmailsIfNeeded() {
    const { analysis } = this.props;
    const recipients = analysis.form?.offers?.custom_notification_recipients;
    if (!Array.isArray(recipients) || !recipients) return null;

    return (
      <Box
        header={I18n.t('analysis.shareResultWith.title')}
        content={
          <ul>
            {recipients.map(
              (recipient) => (<li>{recipient.email}</li>),
            )}
          </ul>
        }
      />
    );
  }
}

export default Order;
