import React from "react";

import I18n from "../../language/i18n";
import Icon from "../Common/Icon";

const CreditCard = (props) => {
  const { onClick } = props;

  return (
    <div>
      <button
        className={"btn btn-primary"}
        style={{
          textTransform: "uppercase",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={onClick}
      >
        <Icon
          name="credit-card"
          iconType="fa"
          style={{marginRight: "3px"}}
        />
        {I18n.t("billing.creditCard")}
      </button>
    </div>
  );
};

export default CreditCard;
