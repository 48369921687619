import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import I18n from '../../language/i18n';
import { sendEmail } from 'farmerjoe-common/lib/utils/Activation';
import { REQUEST_EMAIL_ACTIVATION_URL } from '../../constants';
import { isProfileCompleted, isEmailCredentials } from '../../utils/auth';
// import type { auth, firebase as firebaseType } from 'react-redux-firebase'
// import type { Profile } from 'farmerjoe-common'

type Props = {
  auth: any; // auth
  profile: any; // Profile
  firebase: any; // firebaseType
  signup: any;
  credentialsProvider: string;
};

/**
 * When we started forcing users to validate their email addresses
 * we already had few thousand registrations. Because of that we
 * had to "force" users to validate their accounts
 *
 * This class checks whether the user has been sent a verification email (normally sent
 * by a cloud function on registration or when changing the email address)
 * and if not triggers the sending of a mail to the user
 */
const ActivationEmail = (props) => {
  const { auth: { uid, emailVerified, email: authEmail }, profile: { verificationEmailSent, email: profileEmail }, firebase, signup, credentialsProvider } = props;

  // If we use PHONE as cred provider, the email does not exist on the auth yet;
  //
  const email = isEmailCredentials(credentialsProvider) ? authEmail : profileEmail;

  const sendActivationEmail = useCallback(() => {
    execSendEmail(email)
      .then(() => {
        // Email sent, then add the date when we sent the email for later
        // reference
        firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .update({
            verificationEmailSent: new Date(),
          });
      })
      .catch(e => {
        console.log('error', e);
      });
  }, [email, firebase, uid]);

  useEffect(() => {
    if (isProfileCompleted(signup) && email && !emailVerified && !verificationEmailSent) {
      sendActivationEmail();
    }
  }, [signup, email, emailVerified, verificationEmailSent, sendActivationEmail]);

  return null;
};

const execSendEmail = (email: string | undefined) => {
  if (!email) {
    return Promise.reject(new Error('Email is null'));
  }
  return sendEmail(email, I18n.locale, REQUEST_EMAIL_ACTIVATION_URL);
};

const selector = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    signup: state.signup,
    credentialsProvider: state.credentialsProvider.provider,
  };
};

const wrappedActivationEmail = firestoreConnect(props => {
  return [];
})(ActivationEmail);

export default connect(
  selector,
  () => ({}),
)(wrappedActivationEmail);
