export function isHomeScreen() {
  return window.matchMedia('(display-mode: standalone)').matches;
}

export function isPrintPage(path: string = getPathname()) {
  return /\/company\/[^/]+\/field\/[^/]+\/print\/[^/]+/.test(path);
}

export function getPathname() {
  return isHomeScreen()
    ? window.location.hash.substring(1)
    : window.location.pathname;
}

export function isIframe() {
  return window.top !== window;
}

export function isMapPage(path: string = getPathname()) {
  return /^\/company\/[^/]+\/map/.test(path);
}

export function getFieldPath(
  companyId: string,
  fieldId?: string | null,
  cropId?: string | null,
) {
  let path = `/company/${companyId}/${isMapPage() ? 'map' : 'field'}`;
  if (fieldId) {
    path += '/' + fieldId;
  }
  if (fieldId && cropId) {
    path += '/' + cropId;
  }
  return path;
}

export function getEmployeePath(
  companyId: string,
  employeeId?: string,
  groupId?: string,
) {
  let path = `/company/${companyId}/employee`;
  if (employeeId && groupId) {
    path += '/' + employeeId + '/' + groupId;
  }

  return path;
}

export function isFieldPage(path = getPathname(), companyId, fieldId) {
  return new RegExp(
    `/company/${companyId || '[^/]+'}/(field|map)${
      fieldId ? '/' + fieldId : ''
    }`,
  ).test(path);
}

export function isAnalysisPage(path = getPathname(), companyId, analysisId) {
  return new RegExp(
    `/company/${companyId || '[^/]+'}/analysis${
      analysisId ? '/' + analysisId : ''
    }`,
  ).test(path);
}
