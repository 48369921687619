import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { analysisFromField } from "farmerjoe-common/lib/actions/analysis";
import { openField, copyField } from "farmerjoe-common/lib/actions/field";
import { addComment } from "farmerjoe-common/lib/actions/comment";
import { editFertilizing } from "farmerjoe-common/lib/actions/actions";
import { resetCropAge } from "farmerjoe-common/lib/actions/crop";
import { isAdmin, canDo } from "farmerjoe-common/lib/utils/User";
import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";
import { getFeature } from "farmerjoe-common/lib/selectors/features";
import { CropType } from "farmerjoe-common/lib/flow/types";
import * as waitTimeActions from "farmerjoe-common/lib/actions/waitTime";
import { isAbandonedField } from "farmerjoe-common/lib/utils/Field";

import Icon from "../../Common/Icon";
import ModalList from "../../Modal/ModalList";
import WaitTime from "../../WaitTime/Form";
import FormFertilizing from "../../Fertilizers/Form";
import NewAnalysisDialog from "../../Analysis/NewAnalysisDialog";
import MarkFieldForm from "../../FieldMark/Form";
import NewBoniturDialog from "../../Bonitur/NewBoniturDialog";
import CompanySearchDialog from "../../FieldsSharing/CompanySearchDialog";
import withRouter from "../../Router/withRouter";
import { getFieldPath } from "../../../utils/page";

import { uploadImages } from "../../../actions/image";
import type { Employee, Field } from "../../../flowTypes";

import * as constants from "../../../styles/style";
import I18n from "../../../language/i18n";


type Props = {
  field: Field;
  activeCrops: {};
  actions?: {
    resetCropAge: (...args: Array<any>) => any;
    uploadImages: (...args: Array<any>) => any;
    editWaitTime: (...args: Array<any>) => any;
    editFertilizing: (...args: Array<any>) => any;
    analysisFromField: (...args: Array<any>) => any;
    openField: (fieldKey: string) => any;
    copyField: (fieldKey: string, cb: (arg0: string) => any) => any;
  };
  // The company profile of the current user
  myCompanyProfile: Employee;
  openCompany?: string;
  history?: Record<string, any>;
  bonitur?: any;
};

type State = {
  showWaitTimeForm: boolean;
  showFertilizingForm: boolean;
  showSelectLabForm: boolean;
  showMarkFieldForm: boolean;
  showNewBoniturForm: boolean;
  showShareFieldForm: boolean;
};

class Plus extends React.PureComponent<Props, State> {
  state = {
    showWaitTimeForm: false,
    showFertilizingForm: false,
    showSelectLabForm: false,
    showMarkFieldForm: false,
    showNewBoniturForm: false,
    showShareFieldForm: false,
  };

  ageBeginsAt0() {
    const { activeCrops, field, actions } = this.props;
    actions?.resetCropAge(field, activeCrops);
  }

  waitTime() {
    this.props.actions?.editWaitTime(null);
    this.setState({ showWaitTimeForm: true });
  }

  fertilization() {
    this.props.actions?.editFertilizing(null);
    this.setState({ showFertilizingForm: true });
  }

  analysis() {
    this.props.actions?.analysisFromField(true);
    this.setState({ showSelectLabForm: true });
  }

  markField() {
    this.setState({ showMarkFieldForm: true });
  }

  bonitur() {
    this.setState({ showNewBoniturForm: true });
  }

  shareField() {
    this.setState({ showShareFieldForm: true });
  }

  copyField() {
    const { field, actions } = this.props;
    actions?.copyField(field.key, (fieldCopyKey: string) => {
      actions?.openField(fieldCopyKey);
      this.props.history?.push(getFieldPath(this.props.openCompany as string, fieldCopyKey));
    });
  }

  getData() {
    const { field, myCompanyProfile, bonitur } = this.props;
    const iconStyle = {
      width: 30,
      fontSize: 22,
      color: constants.FJNAVCOLOR,
      textAlign: "center" as "center",
      marginRight: 10,
    };
    const data: any[] = [];

    if (
      isAdmin(myCompanyProfile) &&
      parseInt(field.activeCrop.type, 10) === parseInt(CropType.Permanent, 10)
    ) {
      data.push({
        label: I18n.t("crop.ageBeginsAt0"),
        value: "ageBeginsAt0",
        icon: (
          <span style={{ ...iconStyle, ...{ fontSize: 20 } }}>
            {I18n.t("x_days", { days: 0 })}
          </span>
        ),
      });
    }

    data.push({
      label: I18n.t("fieldMark.markField"),
      value: "markField",
      icon: (
        <div
          style={{
            height: 20,
            width: 20,
            backgroundColor: "#7EB549",
            borderRadius: 10,
            marginRight: 15,
            marginLeft: 5,
          }}></div>
      ),
    });

    if (canDo("create", "waittime", myCompanyProfile)) {
      data.push({
        label: I18n.t("crop.protection"),
        value: "waitTime",
        icon: <Icon name="shield" iconType={"far"} style={iconStyle} />,
      });
    }

    if (canDo("create", "fertilizing", myCompanyProfile)) {
      data.push({
        label: I18n.t("fertilizer.fertilization"),
        value: "fertilization",
        icon: <Icon name="fertilization" iconType={"fj"} style={iconStyle} />,
      });
    }

    if (canDo("create", "analysis", myCompanyProfile)) {
      data.push({
        label: I18n.t("analysis.analysis"),
        value: "analysis",
        icon: (
          <Icon
            name="analysis"
            iconType={"fj"}
            style={{ ...iconStyle, ...{ fontSize: 26 } }}
          />
        ),
      });
    }

    if (bonitur) {
      data.push({
        label: "Bonitur",
        value: "bonitur",
        icon: (
          <Icon
            name="analytics"
            iconType={"fal"}
            style={{ ...iconStyle, ...{ fontSize: 22 } }}
          />
        ),
      });
    }

    if (
      isAdmin(myCompanyProfile) &&
      !isAbandonedField(field.state)
    ) {
      data.push({
        label: `${I18n.t("fieldSharing.fieldMenuString")} (Beta)`,
        value: "shareField",
        icon: (
          <Icon
            name="md-share"
            light
            iconType={"ion"}
            style={{ ...iconStyle, ...{ fontSize: 22 } }}
          />
        ),
      });
    }

    data.push({
      label: I18n.t("field.copyField"),
      value: "copyField",
      icon: (
        <Icon
          name="md-copy"
          light
          iconType={"ion"}
          style={{ ...iconStyle, ...{ fontSize: 22 } }}
        />
      ),
    });

    return data;
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
        <ModalList
          data={this.getData()}
          onClick={({ value }) => {
            if (this[value]) {
              this[value]();
            }
          }}
          className={"withIcon"}
          menuPosition={"dropdown-menu-bottom-left"}
          menuAnimation={"scale-up-bottom-left"}>
          <Icon
            name="plus"
            iconType={"fj"}
            style={{
              color: constants.FJNAVCOLOR,
              fontSize: 20,
              paddingBottom: 5,
              paddingLeft: 10,
              paddingRight: 15,
              // paddingTop: 5
            }}
          />
        </ModalList>

        {this.state.showWaitTimeForm
          ? (
            <WaitTime
              show={this.state.showWaitTimeForm}
              onClose={() => this.setState({ showWaitTimeForm: false })}
            />
          )
          : null}
        {this.state.showFertilizingForm
          ? (
            <FormFertilizing
              show={this.state.showFertilizingForm}
              onClose={() => this.setState({ showFertilizingForm: false })}
            />
          )
          : null}
        {this.state.showSelectLabForm
          ? (
            <NewAnalysisDialog
              show={this.state.showSelectLabForm}
              onCancel={() => this.setState({ showSelectLabForm: false })}
              onCloseAfterCreatingAnalysis={() =>
                this.setState({ showSelectLabForm: false })
              }
            />
          )
          : null}
        {this.state.showMarkFieldForm
          ? (
            <MarkFieldForm
              show={this.state.showMarkFieldForm}
              onClose={() => this.setState({ showMarkFieldForm: false })}
            />
          )
          : null}
        {this.state.showNewBoniturForm
          ? (
            <NewBoniturDialog
              show={this.state.showNewBoniturForm}
              onCancel={() => {
                this.setState({ showNewBoniturForm: false });
              }}
            />
          )
          : null}
        {this.state.showShareFieldForm
          ? (
            <CompanySearchDialog
              show={this.state.showShareFieldForm}
              onClose={() => {
                this.setState({ showShareFieldForm: false });
              }}
            />
          )
          : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...waitTimeActions,
          editFertilizing,
          addComment,
          openField,
          analysisFromField,
          uploadImages,
          resetCropAge,
          copyField,
        },
      ),
      dispatch,
    ),
  };
};

export default compose<typeof Plus>(
  connect(
    (state: any, ownProps: any) => {
      const openCompany = getOpenCompanyId(state);
      return {
        openCompany: openCompany,
        bonitur: getFeature(state, openCompany, "bonitur") === true,
      };
    },
    mapDispatchToProps,
  ),
  withRouter,
)(Plus);
