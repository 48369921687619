import React, { useEffect, useState } from "react";
import fetch from "../utils/fetch";
import { getUidToken } from "../utils/auth";
import { BASE_API_URL } from "../constants";

const API_GET_COMPANIES_BY_ID= `${BASE_API_URL}/companies/getByIds`;
const useFieldCollaborators = (fieldCollaborators: string[], runHook: boolean) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchCollaborators = async () => {
      const collaborators = fieldCollaborators
        ? fieldCollaborators instanceof Set
          ? Array.from(fieldCollaborators)
          : fieldCollaborators
        : [];

      if (!collaborators.length) {
        setCompanies([]);
        return;
      }
      try {
        if (!runHook) {
          return;
        }
        const uidToken = await getUidToken();
        const response = await fetch(API_GET_COMPANIES_BY_ID, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${uidToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({companyIds: collaborators}),
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch companies");
        }
        const json = await response.json();
        setCompanies(json);
          
      } catch (error: any) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      }
    };
    fetchCollaborators();
    return () => {
      controller.abort();
    };
  }, [fieldCollaborators, runHook]);

  return companies;
};

export default useFieldCollaborators;
