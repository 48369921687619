import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import { hasLoaded } from 'farmerjoe-common/lib/selectors/loading';
import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getAnalyses } from 'farmerjoe-common/lib/selectors/analyses';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';
import { getAnalysesQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Analysis';
import { getCompanyGroupProfileForLoggedInUser } from 'farmerjoe-common/lib/selectors/user';
import { convertStringToRange } from 'farmerjoe-common/lib/utils/Date';
import { AnalysisState } from 'farmerjoe-common/lib/flow/types';

import AnalysesList from './AnalysesList';
import { getDateRange, getSorting } from './utils';
import { Loading } from '../Loading/Loading';
import NoResults from '../Common/NoResults';
import I18n from '../../language/i18n';
import WithAnalyses from '../../containers/HOC/WithAnalyses';

type Props = {
  loading?: boolean;
  analyses?: any;
  selectedAnalysis?: any;
  sort?: any;
  tabKey?: any;
  tabLabel?: any;
};

class Open extends React.Component<Props> {
  render() {
    const { loading, analyses, sort, selectedAnalysis } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (!Object.keys(analyses).length) {
      return <NoResults text={I18n.t('analysis.noResultsAvailable')} />;
    }

    return (
      <AnalysesList
        analyses={analyses}
        sort={sort}
        selectedAnalysis={selectedAnalysis}
      />
    );
  }
}

const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const dateRange = getDateRange(state, openCompany, 'analysis_open');
  const sort = getSorting(state, openCompany, 'analysis_open');
  const analyses = getAnalyses(
    state,
    openCompany,
    AnalysisState.Inbox,
    convertStringToRange(dateRange),
  );
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );

  return {
    company,
    sort: sort,
    analyses: analyses || [],
    loading: !hasLoaded(
      [
        (getAnalysesQuery as any)(
          openCompany,
          browsingGroup,
          myCompanyProfile,
        ),
      ],
      state,
    ),
    browsingGroup,
    myCompanyProfile,
    dateRange,
  };
};

export default compose<typeof Open>(
  connect(selector),
  WithAnalyses,
)(Open);
