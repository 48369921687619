import * as React from 'react';
import * as constants from '../../styles/style';
import Text from './Text';

type Props = {
  inputValue: any;
  label: string;
};
const styles = {
  labelText: {
    fontSize: 14,
    color: constants.FJLABEL,
  },
};

export default class FormRow extends React.PureComponent<Props> {
  render() {
    const { label, inputValue } = this.props;
    if (!label) {
      return null;
    }

    let renderLabel = (
      <Text style={{ ...styles.labelText, ...{ color: constants.FJMUTED } }}>
        {label}
      </Text>
    );

    if (inputValue === null) {
      renderLabel = (
        <Text style={{ flexShrink: 2, color: constants.FJLABEL }}>{label}</Text>
      );
    }

    return renderLabel;
  }
}
