import React, { Component } from 'react';
import I18n from '../../language/i18n';
import SectionList from '../Common/SectionList';
import FlatList from '../Common/FlatList';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import withRouter from '../Router/withRouter';
import AnalysisSectionHeader from './AnalysisSectionHeader';
import AnalysesListItem from './AnalysesListItem';
import AnalysesListItemField from './AnalysesListItemField';
import type { Analysis } from 'farmerjoe-common/lib/flow/types';

type Props = {
  analyses: Analysis[];
  sort: string;
  selectedAnalysis: string | null;
  history?: Record<string, any>;
  openCompany?: string;
};

class AnalysesList extends Component<Props> {
  render() {
    const { analyses, sort, selectedAnalysis } = this.props;

    if (sort === 'latest') {
      return (
        <FlatList
          className="analyses-list"
          data={analyses}
          ListHeaderComponent={() => (
            <AnalysisSectionHeader sectionId={I18n.t('analysis.latest')} />
          )}
          renderItem={item => (
            <AnalysesListItemField
              ana={item.item}
              tags={true}
              history={this.props.history}
              openCompany={this.props.openCompany}
              selected={selectedAnalysis === item.item.key}
            />
          )}
        />
      );
    }

    return (
      <SectionList
        className="analyses-list"
        renderItem={item => (
          <AnalysesListItem
            item={item.item}
            history={this.props.history}
            openCompany={this.props.openCompany}
            selectedAnalysis={selectedAnalysis}
          />
        )}
        renderSectionHeader={({ section }) => (
          <AnalysisSectionHeader sectionId={section.title} />
        )}
        sections={analyses}
        SectionSeparatorComponent={sep => {
          // Don't display this with top separators
          if (sep.trailingItem) {
            return null;
          }
          return (
            <div style={{ height: 15, backgroundColor: 'transparent' }}></div>
          );
        }}
        stickySectionHeadersEnabled={true}
      />
    );
  }
}

export default compose<typeof AnalysesList>(
  connect((state: any) => ({
    openCompany: selectors.getOpenCompanyId(state),
  })),
  withRouter,
)(AnalysesList);
