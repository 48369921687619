import * as React from 'react';
import TextInputBase from '../../components/Common/TextInput';
import { get } from 'lodash-es';
import { classes } from '../../utils/dom';

type Props = {
  children?: React.ReactNode;
  multiline?: any;
  attrs?: any;
  hasError?: any;
  numberOfLines?: any;
  autoCapitalize?: any;
  autoCorrect?: any;
  autoFocus?: any;
  disabled?: any;
  onChange: any;
  placeholder?: any;
  maxLength?: any;
  value: any;
  password?: any;
  onFocus?: () => void;
  onBlur?: () => void;
  config?: Record<string, any>;
  label?: string | React.ReactNode;
  floatingLabels?: boolean;
  style?: React.CSSProperties;
  type?: React.InputHTMLAttributes<any>;

  placeholderTextColor?: any;
  errorPlaceholderTextColor?: any;
  isPristine?: any;
  typeInfo?: any;
};

export default class TextInput extends React.Component<
  Props,
  {
    value: string | void;
  }> {
  static defaultProps = {
    floatingLabels: true,
  };

  render() {
    const {
      children,
      placeholderTextColor,
      errorPlaceholderTextColor,
      isPristine,
      typeInfo,
      floatingLabels,
      ...restProps
    } = this.props;
    return (
      <TextInputBase
        {...restProps}
        multiline={get(this.props, 'config.multiline')}
        ref="input"
        id={get(this.props, 'attrs.id')}
        name={get(this.props, 'attrs.name')}
        rows={this.props.numberOfLines}
        autoCapitalize={this.props.autoCapitalize ? 'sentences' : 'off'}
        autoCorrect={this.props.autoCorrect ? 'on' : 'off'}
        onBlur={this._onBlur.bind(this, this.props)}
        onFocus={this._onFocus.bind(this, this.props)}
        onChange={e => {
          const value = e.target.value;
          this.props.onChange(value);
        }}
        label={get(this.props, 'attrs.label') || this.props.label}
        value={this.props.value || ''}
        type={this.getType()}
        containerClassName={classes(floatingLabels && 'floating-labels')}
        readOnly={get(this.props, 'config.readOnly')}
        disabled={get(this.props, 'config.disabled')}
      />
    );
  }

  _onFocus(locals: Props) {
    if (locals.onFocus) {
      locals.onFocus();
    }
  }

  _onBlur(locals: Props) {
    if (locals.onBlur) {
      locals.onBlur();
    }
  }

  private getType(): React.InputHTMLAttributes<string> {
    if (get(this.props, 'config.password')) {
      return 'password' as React.InputHTMLAttributes<string>;
    }
    return this.props.type || 'text' as React.InputHTMLAttributes<string>;
  }
}
