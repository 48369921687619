import React from "react";
import { debounce } from "lodash-es";
import UsersList from "../UsersList";
import { ModalSearchEmployeeProps } from "../types";
import SearchInput from "../../Common/SearchInput";
import NoResults from "../../Common/NoResults";
import I18n from "../../../language/i18n";
import { getAlgoliaInstance } from "../../../services/algolia";
import { ALGOLIA_INDEX_USERS } from "../../../constants";

const algoliaInstance = getAlgoliaInstance(ALGOLIA_INDEX_USERS);

const ModalSearchUser = ({
  users,
  setUsers,
  setSelectedUser,
  setCurrentDialog,
  searchValue,
  setSearchValue,
}: ModalSearchEmployeeProps) => {

  const searchForUsers = debounce((value) => {
    if (!value) {
      setUsers([]);
      return;
    }

    setSearchValue(value);

    return algoliaInstance.searchIndex(value).then((result) => {
      setUsers(result.hits);
    });
  }, 1000);

  return (
    <div className='modal-body-content'>
      <SearchInput
        onChange={text => {
          searchForUsers(text);
        }}
        search={searchValue}
        placeholder={I18n.t("searchByNameEmailPhone")}
      />
      <div>
        {!users.length && searchValue.length ? (
          <NoResults text={I18n.t("noSearchResults")} />
        ) : (
          UsersList({users, setSelectedUser, setCurrentDialog})
        )
        }
      </div>
    </div>
  );
};

export default ModalSearchUser;
