import Select from 'react-select';
import DropDown from '../DropDown/DropDown';
import * as React from 'react';
import { isObject, isFunction } from 'lodash-es';
import I18n from '../../language/i18n';

// TODO: improve typings
type Props = any;

/**
 * uses DropDown as the menu container
 * also fixes a problem with a custom menu component in which selecting a value from menu would make the input lose focus
 * and the menu would close before a value was actually selected
 */
class FixedSelect extends (Select as React.ComponentClass<Props>) {
  _select = undefined;
  isMenuBeingClicked = false;

  constructor(props: Props, context) {
    super(props, context);

    // overwrite the onInputBlur function to not close the menu when selecting
    // it's done this way because Select is wrapped in a HOC
    // $FlowFixMe
    Object.defineProperty(this, 'select', {
      set: ref => {
        this._select = ref;
        const outsideThis = this;
        if (ref) {
          ref.onInputBlur = function(
            this: any,
            event: React.FocusEvent<HTMLInputElement>,
          ) {
            if (
              (this.menuListRef &&
                this.menuListRef.contains(document.activeElement)) ||
              outsideThis.isMenuBeingClicked
            ) {
              this.inputRef.focus();
              return;
            }
            if (this.props.onBlur) {
              this.props.onBlur(event);
            }
            this.onInputChange('', { action: 'input-blur' });
            this.onMenuClose();
            this.setState({
              focusedValue: null,
              isFocused: false,
            });
          }.bind(ref);
        }
      },

      get: () => {
        return this._select;
      },
    });
  }

  menuIsBeingClicked = state => {
    this.isMenuBeingClicked = state;
  };
}

export default React.forwardRef<unknown, any>(function FixedSelectWrapper(
  props,
  forwardedRef,
) {
  const selectRef = React.createRef();
  const newProps: any = { ...props };
  newProps.components = { ...newProps.components } || {};
  newProps.placeholder =
    newProps.placeholder || I18n.t('selectPlaceholderDropdown');
  newProps.components.Menu =
    newProps.components.Menu ||
    (({ children }) => (
      <DropDown
        show={true}
        showInPortal={true}
        menuClassName={'react-select-dropdown-menu'}
        offsetToAncestor={{ top: 0, left: 0, right: 0, bottom: 0 }}
        sameWidthAsAncestor={true}
        menuAnimation={null}
        menuProps={{
          onMouseUp: () => {
            if (selectRef.current) {
              (selectRef.current as any).menuIsBeingClicked(false);
            }
          },
          onMouseDown: () => {
            if (selectRef.current) {
              (selectRef.current as any).menuIsBeingClicked(true);
            }
          },
        }}>
        {children}
      </DropDown>
    ));

  return (
    <FixedSelect
      {...newProps}
      ref={ref => {
        (selectRef as any).current = ref;
        if (isFunction(forwardedRef)) {
          (forwardedRef as any)(ref);
        } else if (isObject(forwardedRef)) {
          (forwardedRef as any).current = ref;
        }
      }}
    />
  );
});
