import { DEFAULT_MAP_ZOOM } from '../components/Map/GoogleMapWithLoader';

export const setMapView = (center, zoom = DEFAULT_MAP_ZOOM) => {
  return {
    type: 'app/setMapView',
    payload: {
      center,
      zoom,
    },
  };
};
