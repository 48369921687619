import React, { useState } from "react";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";

import { connect } from "react-redux";
import * as companyActions from "farmerjoe-common/lib/actions/company";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import * as companySelectors from "farmerjoe-common/lib/selectors/companies";
import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import Icon from "../Common/Icon";
import Map from "../Map/Map";
import CompanyLogo from "./CompanyLogo";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import NavbarBasic from "../Common/NavbarBasic";
import { bindActionCreators, compose } from "redux";
import withRouter from "../Router/withRouter";
import FormCompany from "./FormCompany";
import "./style.css";
import { getCountryName } from "../../utils/Countries";
import { isNotMainGroup } from "farmerjoe-common/lib/utils/firestoreRedux/Utils";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { EditButton } from "../Common/EditButton";
import { InviteSupportButton } from "./InviteSupportButton";
import { LeaveCompanyButton } from "./LeaveCompanyButton";
import { isFJSupportUser } from "../../utils/support";

// TODO: improve typings
type Props = {
  company?: any;
  actions?: any;
  myCompanyProfile?: any;
  browsingGroup?: any;
  history?: any[];
  fjSupportUser?: boolean;
};

const Company = (props: Props) => {
  const { myCompanyProfile, company, browsingGroup, fjSupportUser } = props;
  const [ showCreateForm, setShowCreateForm ] = useState(false);

  if (!company) {
    return null;
  }

  const marker = {
    key: company.key,
    position: company.position,
    type: "company",
    title: company.name,
  };

  return (
    <div className="company">
      <NavbarBasic
        title={company.name}
        rightButton={
          isAdmin(myCompanyProfile)
            ? (
              <EditButton
                onClick={() => {
                  if (isAdmin(myCompanyProfile)) {
                    props.actions.editCompany(company.key);
                    setShowCreateForm(true);
                  }
                }}
                hint={I18n.t("edit")}
              />
            )
            : null
        }
      />
      <div
        style={{
          ...{ flex: 1, overflowY: "auto", WebkitOverflowScrolling: "touch" },
        }}>
        <div style={{ height: 150, position: "relative" }}>
          <Map
            mapStyles={{}}
            position={company.position}
            filterNotACrop={false}
            zoomedIn={true}
            // navigate={true}
            onClick={() => {}}
            markers={[marker]}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: -50,
            zIndex: 100,
            position: "relative",
          }}>
          <CompanyLogo
            company={company}
            isAdmin={isAdmin(myCompanyProfile)}
          />
        </div>
        <div style={{ paddingBottom: 20, marginTop: -60, paddingTop: 50 }}>
          <div style={{ padding: "0 20px" }}>
            <span style={constants.styles.headerText}>{company.name}</span>
            <div style={{ marginTop: 20 }}>
              {company.company_number
                ? (
                  <React.Fragment>
                    <span>{company.company_number}</span>
                    <br />
                  </React.Fragment>
                )
                : null}

              <span>{company.street}</span>
              <br />
              <span>
                {company.zip} {company.city}
              </span>
              <br />
              <span>{getCountryName(company.country)}</span>
            </div>
          </div>

          <div
            style={{ backgroundColor: "#FFF", padding: 20}}>
            <a href={`mailto:${company.email}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Icon
                  name={"ios-mail-outline"}
                  style={{
                    fontSize: 18,
                    color: "#7EB549",
                    marginTop: 3,
                  }}
                />
                <span style={{ marginLeft: 5, color: "#7EB549" }}>
                  {company.email}
                </span>
              </div>
            </a>
            <a href={`tel:${company.tel}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Icon
                  name={"ios-call-outline"}
                  style={{
                    fontSize: 18,
                    color: "#7EB549",
                    marginTop: 3,
                  }}
                />
                <span style={{ marginLeft: 5, color: "#7EB549" }}>
                  {company.tel}
                </span>
              </div>
            </a>
            {company.website
              ? (
                <div
                  style={{ backgroundColor: "#FFF" }}>
                  <Icon
                    iconType={"fa"}
                    name={"globe"}
                    style={{
                      fontSize: 18,
                      color: "#7EB549",
                    }}
                  />
                  <span style={{ marginLeft: 5, color: "#7EB549" }}>
                    <a
                      href={company.website}
                      referrerPolicy="no-referrer" target="_blank" rel="nofollow, noreferrer"
                    >
                      {company.website}
                    </a>
                  </span>
                </div>
              )
              : null}

          </div>

          {company.ggn_number || company.qs_number
            ? (
              <div
                style={{ backgroundColor: "#FFF", padding: "0 20px" }}>
                {company.ggn_number
                  ? (
                    <span style={{ display: "block" }}>
                      {I18n.t("ggn_gln_number")}: {company.ggn_number}
                    </span>
                  )
                  : null}

                {company.ggn_number
                  ? (
                    <span style={{ display: "block" }}>
                      {I18n.t("company.qs_number")}: {company.qs_number}
                    </span>
                  )
                  : null}
              </div>
            )
            : null}

          {company.notes
            ? (
              <div
                style={{ backgroundColor: "#FFF", padding: "0 20px" }}>
                <span style={constants.styles.muted}>{I18n.t("notes")}{":"}</span>
                <p>{company.notes}</p>
              </div>
            )
            : null}


          <div
            style={{ backgroundColor: "#FFF", padding: 20, marginTop: 10 }}>

            {isAdmin(myCompanyProfile) && !isNotMainGroup(browsingGroup)
              ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #707274",
                    margin: "1em 0",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    props.history?.push(
                      `/company/${props.company.key}/billing`,
                    )
                  }
                >
                  <div style={{fontWeight: "bold", color: constants.FJMUTEDDARK}}> {I18n.t("billing.billing")} </div>
                  <Icon
                    iconType={"fa"}
                    name={"arrow-right"}
                    style={{
                      fontSize: 18,
                      color: "#7EB549",
                    }}
                  />
                </div>
              )
              : null}


            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #707274",
                margin: "1em 0",
                cursor: "pointer",
              }}
              onClick={() =>
                props.history?.push(
                  `/company/${props.company.key}/archive`,
                )
              }
            >
              <div style={{fontWeight: "bold", color: constants.FJMUTEDDARK}}> {I18n.t("archive.archivedFields")} </div>
              <Icon
                iconType={"fa"}
                name={"arrow-right"}
                style={{
                  fontSize: 18,
                  color: "#7EB549",
                }}
              />
            </div>


            {isAdmin(myCompanyProfile) && !isNotMainGroup(browsingGroup)
              ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #707274",
                    margin: "1em 0",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    props.history?.push(
                      `/company/${props.company.key}/apikeys`,
                    )
                  }
                >
                  <div style={{fontWeight: "bold", color: constants.FJMUTEDDARK}}> {I18n.t("apiKeys.header")} </div>
                  <Icon
                    iconType={"fa"}
                    name={"arrow-right"}
                    style={{
                      fontSize: 18,
                      color: "#7EB549",
                    }}
                  />
                </div>
              )
              : null}

            <div
              style={{
                ...constants.styles.containerRow,
                ...{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                },
              }}>
              {fjSupportUser
                ? <LeaveCompanyButton companyId={company.key} history={props.history}/>
                : <InviteSupportButton companyId={company.key} />
              }
            </div>
          </div>
        </div>
      </div>
      {showCreateForm
        ? (
          <FormCompany
            show={showCreateForm}
            onClose={() => setShowCreateForm(false)}
            goToInfo={false}
          />
        )
        : null}
    </div>
  );
};

const selector = (state, ownProps) => {
  const openCompanyId = selectors.getOpenCompanyId(state);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(state, openCompanyId);
  const company = companySelectors.getCompany(state.firestore.data, openCompanyId);
  const browsingGroup = getBrowsingGroupKey(state, openCompanyId);
  const fjSupportUser = isFJSupportUser(state.firebase.profile.email);

  return {
    company,
    myCompanyProfile,
    browsingGroup,
    fjSupportUser,
  };
};

export default compose<typeof Company>(
  connect(
    selector,
    dispatch => ({
      actions: bindActionCreators(
        {
          ...companyActions,
        },
        dispatch,
      ),
    }),
  ),
  withRouter,
)(Company);
