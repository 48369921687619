import * as React from 'react';
import type { FieldMark } from '../../flowTypes';
import { get } from 'lodash-es';
import Badge from './Badge';

import './style.css';

type Props = {
  mark: FieldMark | null | undefined;
  style?: {};
};

export default function Mark({ mark, style }: Props) {
  if (mark) {
    const reason = get(mark, 'reason');
    return (
      <div className="mark-container" style={style}>
        <div className="badge-container" title={reason || ''}>
          <Badge mark={mark} />
        </div>
        <span className="reason">{reason}</span>
      </div>
    );
  }

  return null;
}
