import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Common/Icon';
import I18n from '../../language/i18n';
import { AlertDialog } from '../Dialog/Dialog';
import { appPosToLatLng, PositionErrorCode } from '../../utils/Map';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { updatePosition } from 'farmerjoe-common/lib/actions/position';
import { getCurrentPosition } from '../../utils/geolocation';

// TODO: improve typings
type Props = {
  map: google.maps.Map;
  userPosition?: any;
  actions?: any;
};
type State = any;

class MapCenterOnUserPosition extends PureComponent<Props, State> {
  static propTypes = {
    map: PropTypes.object.isRequired,
  };

  state = {
    alertMessage: null,
    alertTitle: null,
  };

  render() {
    return [
      <button
        title={I18n.t('position')}
        className="fj-gm-btn map-toolbar-button user-location"
        key={0}
        onClick={() => {
          this.centerOnUserPosition();
        }}>
        <Icon name="md-locate" />
      </button>,
      <AlertDialog
        show={!!this.state.alertMessage}
        onClose={() => this.setState({ alertMessage: null, alertTitle: null })}
        title={this.state.alertTitle}
        children={this.state.alertMessage}
        key={1}
      />,
    ];
  }

  /**
   * Get the user's position and update the region state if necessary
   */
  centerOnUserPosition() {
    if (!this.props.userPosition) {
      getCurrentPosition(
        position => {
          this.setPosition(position.coords);
          this.props.actions.updatePosition(position.coords);
        },
        error => {
          console.log(error);
          if (error.code === PositionErrorCode.PERMISSION_DENIED) {
            this.setState({
              alertMessage: I18n.t('alerts.needLocation.description'),
              alertTitle: I18n.t('alerts.needLocation.title'),
            });
          }

          if (error.code === PositionErrorCode.POSITION_UNAVAILABLE) {
            this.setState({
              alertMessage: I18n.t('alerts.cantDetermineLocation.description'),
              alertTitle: I18n.t('alerts.cantDetermineLocation.title'),
            });
          }
        },
        { enableHighAccuracy: false, timeout: 20000, maximumAge: 0 },
      );
    } else {
      this.setPosition(this.props.userPosition);
    }
  }

  setPosition(position) {
    this.props.map?.panTo(appPosToLatLng(position) as any);
    this.props.map?.setZoom(20);
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          updatePosition,
        },
      ),
      dispatch,
    ),
  };
}

export default compose<typeof MapCenterOnUserPosition>(
  connect(
    (state: any) => ({
      userPosition: state.userPosition,
    }),
    mapDispatchToProps,
  ),
)(MapCenterOnUserPosition);
