import React from 'react';
import Page from '../Page/Page';
import './style.css';

export const LoadingIcon = ({ style = {} }) => (
  <svg
    className="circular"
    viewBox="25 25 50 50"
    style={{ position: 'static', ...style }}>
    <circle
      className="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="10"></circle>
  </svg>
);

type LoadingProps = {
  isLoading?: boolean;
  error?: any;
  containerStyle?: any;
  scale?: number;
  size?: string;
  color?: string;
};

export const Loading = ({ isLoading = true, error = null, containerStyle = {} }: LoadingProps) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <div className="loading-container" style={containerStyle}>
        <LoadingIcon />
        {/* <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i> */}
      </div>
    );
  }
  // Handle the error state
  else if (error) {
    return (
      <div style={containerStyle}>
        Sorry, there was a problem loading the page.
      </div>
    );
  } else {
    return null;
  }
};

export const LoadingWholePage = ({ isLoading, error }) => {
  return (
    <Page>
      <Loading isLoading={isLoading} error={error} />
    </Page>
  );
};
