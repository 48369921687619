import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { keys, get } from "lodash-es";

import { commentsToMarkers } from "farmerjoe-common/lib/utils/Comment";
import { updateUi } from "farmerjoe-common/lib/actions/ui";
import { getUi } from "farmerjoe-common/lib/selectors/drawUi";
import { getBoniturisImageKey, getFormSubmissionsForField } from "farmerjoe-common/lib/selectors/forms";
import { formsToMarkers } from "farmerjoe-common/lib/utils/Bonitur";

import ModalMap from "./ModalMap";
import MarkerComments from "./MarkerComments";
import MapControl, {
  ControlButton,
  ControlContainer,
  RIGHT_BOTTOM,
} from "./MapControl";
import MarkerBonitur from "./MarkerBonitur";
import Polygon from "./Polygon";
import Icon from "../Common/Icon";
import BoniturDialog from "../Bonitur/BoniturDialog";
import Comments from "../../containers/Comments";
import type { Comment as CommentType, Field, Marker } from "../../flowTypes";
import { classes } from "../../utils/dom";
import { getColor } from "farmerjoe-common/lib/utils/Colors";


import {
  getPolygonOptions,
  preparePolygonPath,
} from "../../utils/Map";

type Props = {
  comments?: CommentType[];
  field: Field;
  commentMarkers?: Marker[];
  actions?: any;
  ui?: Record<string, any>;
  markers?: any;
  position?: any;
  zoomedIn?: boolean;
  displayFilter?: boolean;
  hideSearch?: boolean;
  containerStyle?: React.CSSProperties;
  hideResetFilter?: boolean;
  ignoreFilter?: boolean;
  history?: Record<string, any>;
  formMarkers?: any[];
};

type State = {
  map: google.maps.Map | null;
  showModal: boolean;
  selectedSchemaId: string;
  selectedCommentId: string;
};

class ImageCommentMap extends React.Component<Props, State> {
  state: State = {
    map: null,
    showModal: false,
    selectedSchemaId: "",
    selectedCommentId: "",
  };

  render() {
    const {
      comments,
      field,
      commentMarkers,
      actions,
      ui,
      ...restProps
    } = this.props;

    const useBaseColors = get(field, "activeCrop.markedArea", false);

    return (
      <ModalMap
        {...restProps}
        baseColors={useBaseColors}
        onMapRef={map => this.setState({ map })}
      >
        {this.renderMapDependentChildrenIfMapReady()}
      </ModalMap>
    );
  }

  private renderMapDependentChildrenIfMapReady() {
    if (!this.state.map) {
      return [];
    }

    const {
      commentMarkers,
      actions: { updateUi },
      ui,
      field,
    } = this.props;
    const { selectedCommentId, selectedSchemaId, showModal } = this.state;

    return [
      (ui?.markerCommentsVisible)
        ? (
          <MarkerComments
            key="icm-0"
            markers={commentMarkers as any}
            map={this.state.map}
          />
        )
        : null,
      (ui?.bonitursVisible)
        ? (
          this.props.formMarkers?.map(item => {
            return <MarkerBonitur
              map={this.state.map as google.maps.Map}
              key={item.key}
              marker={{ type: item.type, key: item.key, position: item.position, title: item.form.meta.group_name }}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  showModal: true,
                  selectedSchemaId: item.form.schema_id,
                  selectedCommentId: item.form.key,
                });
              }}
            />;
          })
        )
        : null,
      (showModal)
        ? (
          <BoniturDialog
            formId={selectedCommentId}
            formSchemaId={selectedSchemaId}
            onClose={() => this.setState({ showModal: false })}
          />
        )
        : null,
      <MapControl
        key="icm-1"
        position={RIGHT_BOTTOM}
        mergeControls={false}
        map={this.state.map}
      >
        <ControlContainer className="map-toolbar-container vertical">
          <ControlButton
            className={classes(
              "marker-comments-visible",
              ui?.markerCommentsVisible && "active",
            )}
            onClick={() =>
              updateUi("imageCommentMap", {
                markerCommentsVisible: !ui?.markerCommentsVisible,
              })
            }>
            <Icon name={"image"} iconType={"fal"} />
          </ControlButton>
          <ControlButton
            className={classes(ui?.bonitursVisible && "active")}
            onClick={() =>
              updateUi("imageCommentMap", {
                bonitursVisible: !ui?.bonitursVisible,
              })
            }>
            <Icon name={"analytics"} iconType={"fal"} />
          </ControlButton>
        </ControlContainer>
      </MapControl>,
      <div key={3}>
        {renderMarkedAreaPolygons(field, this.state.map)}
      </div>,
    ];
  }
}

const renderMarkedAreaPolygons = (field: Field & { baseField?: boolean }, mapRef) => {
  let polygons: any[] = [];
  if (field.baseField) {
    // render all polygons;
    const usedCropArea = get(field, "usedCropArea", null);
    if (!usedCropArea) {
      return null;
    }

    polygons = [...polygons, ...Object.keys(usedCropArea).map((key, idx) => {
      const { markedArea, color = "noCrop" } = usedCropArea[key];
      const polygonPath = preparePolygonPath(markedArea.polygon);
      const polygonOptions = getPolygonOptions({
        color: getColor(color),
      });

      return (
        <Polygon
          key={`${key}-{idx}`}
          path={polygonPath}
          options={polygonOptions}
          map={mapRef}
          onClick={() => {}}
        />
      );
    })];
  }

  // field has crop that is not occuppying the whole area
  const shouldRender = get(field, "activeCrop.markedArea", false);
  if (shouldRender) {

    const markedArea = get(field, "activeCrop.markedArea");
    const polygonPath = preparePolygonPath(markedArea.polygon);
    const polygonOptions = getPolygonOptions({
      color: getColor(get(field, "activeCrop.color", "noCrop")),
    });

    const polygon = 
    <Polygon
      key={field.activeCrop.key}
      path={polygonPath}
      options={polygonOptions}
      map={mapRef}
      onClick={() => {}}
    />;

    polygons = [...polygons, polygon];
  }

  return polygons;
};

export default compose<typeof ImageCommentMap>(
  Comments,
  connect(
    (state :any, ownProps: any) => {
      const ui = getUi(state, "imageCommentMap");
      const { field, browsingGroup, openCompanyId } = ownProps;
      const forms = field
        ? getFormSubmissionsForField(
          state,
          openCompanyId,
          browsingGroup,
          field.key,
          field.activeCrop.key,
        )
        : [];
      const boniturImages: any[] = [];
      forms.forEach(form => {
        const boniturImagesKeys = getBoniturisImageKey(state, { formSchemaId: form.schema_id });
        keys(form?.formValues).forEach(key => {
          if (boniturImagesKeys.includes(key)) {
            const img = new Image();
            img.src = form.formValues[key]?.uri;
            boniturImages.push({ key: form.key, comment: { key: form.comment_id, type: "user.image", extraData: { image: form.formValues[key] }, image: { dimensions: { width: img.width, height: img.height }, ...form.formValues[key] } }, position: form.position });
          }
        });
      });
      return {
        commentMarkers: [...commentsToMarkers(ownProps.comments), ...boniturImages.filter(item => item.position)],
        ui,
        formMarkers: formsToMarkers(forms),
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          updateUi,
        },
        dispatch,
      ),
    }),
  ),
  withRouter,
)(ImageCommentMap);
