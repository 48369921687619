import * as React from "react";
import type { ModelListDataObject } from "../../flowTypes";
import DropDown from "../DropDown/DropDown";
import Icon from "../Common/Icon";
import * as constants from "../../styles/style";
import { classes } from "../../utils/dom";

import "./style.css";

/* eslint-disable no-script-url */

type Props = {
  /**
   * The list header
   */
  header?: string;

  /**
   * An array of options
   *

   *
   */
  data: Array<ModelListDataObject>;

  /**
   * Function to execute when the user presses an item in the list
   */
  onClick: (value: any) => void;
  classStyle?: string;
  className?: string;

  /**
   * Specifies if we should use a longPress to trigger the modal
   */
  longPress?: boolean;

  /**
   * The trigger for the modal
   */
  children: React.ReactNode;

  /**
   * Any content that should be shown before the children
   */
  contentBefore?: React.ReactNode;

  /**
   * A heading for the list
   */
  listHeading?: React.ReactNode;

  /**
   * Whether or not to show a checkbox next to the currently selected value
   */
  showSelected?: boolean;

  /**
   * The currently selected option
   */
  selected?: any;

  /**
   * controls the open state of the menu from the outside
   */
  menuOpen?: boolean;

  /**
   * triggered when the modal is closed
   */
  onClose?: (...args: Array<any>) => any;
  menuPosition?: string;
  menuAnimation?: string;
  stopPropagationOnTrigger?: boolean;
  showMenuWhereClicked?: boolean;
  showInPortal?: boolean;
  dropdownClassName?: string;
  closeOnClickInside?: boolean;
  modalContainerStyle?: React.CSSProperties;
};

type State = {
  menuOpen: boolean;
  selected?: any;
};

export default class ModalList extends React.Component<Props, State> {
  static defaultProps = {
    closeOnClickInside: true,
  };

  constructor(props: Props, context?) {
    super(props, context);
    this.state = {
      menuOpen: !!this.props.menuOpen,
    };
  }

  render() {
    const {
      data,
      className,
      showSelected,
      selected,
      menuPosition,
      menuAnimation,
      stopPropagationOnTrigger,
      showMenuWhereClicked,
      showInPortal,
      dropdownClassName,
      contentBefore,
      closeOnClickInside,
      listHeading,
    } = this.props;
    const styles = constants.styles;

    return (
      <DropDown
        button={
          <span
            style={{ cursor: "pointer" }}
            onClick={e => {
              this.setState({ menuOpen: !this.state.menuOpen });
              if (stopPropagationOnTrigger) {
                e.stopPropagation();
              }
            }}>
            {this.props.children}
          </span>
        }
        onHide={() => {
          this.setState({ menuOpen: false });
          this.props.onClose && this.props.onClose();
        }}
        show={this.state.menuOpen}
        menuPosition={menuPosition}
        menuAnimation={menuAnimation}
        showMenuWhereClicked={showMenuWhereClicked}
        showInPortal={showInPortal}
        className={classes(dropdownClassName, this.state.menuOpen && "show")}>
        {contentBefore}

        {listHeading}

        {data.map(option => {
          const highlightSelected = showSelected && selected === option.value;
          return (
            <a
              key={"options" + option.value}
              className="dropdown-item"
              href="javascript: void 0"
              onClick={() => {
                closeOnClickInside && this.close();
                this.props.onClick(option);
              }}>
              {className === "withIcon"
                ? (
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}>
                    <>
                      {option.icon}
                      <span
                        style={{
                          ...styles.buttonMenuTextWithIcon,
                          ...option.labelStyle,
                        }}>
                        {option.label}
                      </span>
                    </>
                  </span>
                )
                : (
                  <span>
                    {React.isValidElement(option.label)
                      ? (
                        option.label
                      )
                      : (
                        <span
                          style={{ ...option.labelStyle }}
                          className={`fj-label-no-icon ${
                            highlightSelected ? "active" : ""
                          }`}>
                          {option.label}
                        </span>
                      )}
                    {highlightSelected
                      ? (
                        <Icon
                          name={"md-checkmark"}
                          style={{
                            ...styles.buttonMenuText,
                            ...(highlightSelected ? styles.strong : null),
                            ...option.labelStyle,
                            ...{ marginLeft: 25, position: "absolute", right: 20 },
                          }}
                        />
                      )
                      : null}
                  </span>
                )}
            </a>
          );
        })}
      </DropDown>
    );
  }

  close() {
    this.setState({ menuOpen: false });

    this.props.onClose && this.props.onClose();
  }
}
