import { asyncLoader } from "farmerjoe-common/lib/components/AsyncLoader/AsyncLoader";
import { clone, orderBy } from "lodash-es";
import FieldTemplate from "./Fields/FieldTemplate";

const SliderRenderer = asyncLoader(() => import("./Renderers/Slider"));
const SwitchRenderer = asyncLoader(() => import("./Renderers/Switch"));
const NonSupportedRenderer = asyncLoader(
  () => import("./Renderers/NonSupported"),
);
const BooleanRenderer = asyncLoader(() => import("./Renderers/Boolean/Boolean"));
const LabelRenderer = asyncLoader(() => import("./Renderers/Label"));
const ImageRenderer = asyncLoader(() => import("./Renderers/Image"));
const StaticImageRenderer = asyncLoader(
  () => import("./Renderers/StaticImage"),
);
const SmileyFaceRenderer = asyncLoader(
  () => import("./Renderers/SmileyFaceRating"),
);
const ColorPickerRenderer = asyncLoader(
  () => import("./Renderers/ColorPicker"),
);
const CropAgeRenderer = asyncLoader(() => import("./Renderers/CropAge"));
const FieldSizeRenderer = asyncLoader(() => import("./Renderers/FieldSizeRenderer"));
const TextRenderer = asyncLoader(() => import("./Renderers/Text/Text"));
const MathCalculationRenderer = asyncLoader(() => import("./Renderers/MathCalculationRenderer"));
const MathCalculationHectare = asyncLoader(() => import("./Renderers/MathCalculationHectare"));
const AmountRenderer = asyncLoader(() => import("./Renderers/Amount/Amount"));
const TextAreaRenderer = asyncLoader(() => import("./Renderers/TextArea/TextArea"));
const SegmentedControlRenderer = asyncLoader(() => import("./Renderers/SegmentedControl/SegmentedControl"));
const RadioRenderer = asyncLoader(() => import("./Renderers/RadioRenderer"));
const BoniturAgeRenderer = asyncLoader(() => import("./Renderers/BoniturAge"));
const UniqueIdRenderer = asyncLoader(() => import("./Renderers/UniqueIdRenderer"));

const supportedRenderers = {
  "fj-slider": SliderRenderer,
  "fj-boolean": BooleanRenderer,
  "fj-switch": SwitchRenderer,
  "fj-text": TextRenderer,
  "fj-label": LabelRenderer,
  "fj-image": ImageRenderer,
  "fj-static-image": StaticImageRenderer,
  "fj-smiley-face-rating": SmileyFaceRenderer,
  "fj-color-picker": ColorPickerRenderer,
  // NOTE: not needed for new boniturs but required for backward
  // compatibility/existing companies with 'fj-non-animated-input' enabled.
  "fj-non-animated-input": TextRenderer,
  "fj-crop-age": CropAgeRenderer,
  "fj-math": MathCalculationRenderer,
  "fj-math-hectare": MathCalculationHectare,
  "fj-amount": AmountRenderer,
  "fj-textarea": TextAreaRenderer,
  "fj-segmented-control": SegmentedControlRenderer,
  "fj-radio": RadioRenderer,
  "fj-bonitur-age": BoniturAgeRenderer,
  "fj-unique-id": UniqueIdRenderer,
  "fj-field-size": FieldSizeRenderer,
};
/**
 * The schema that we store in firebase is somewhat different than
 * what formikJSON needs. For example firebase doesn't support nested arrays [['required']]
 * but we need those for the validation. So we store this valus with JSON.stringify and need to
 * transform them to valid js data.
 */

export const firebaseToFormikJSON = (firebaseSchema, metaParams) => {
  let elements = firebaseSchema.elements;
  const { companyId, fieldId } = metaParams;
  elements = orderBy(
    Object.keys(elements).map((key) => {
      const element = clone(elements[key]);
      element.companyId = companyId;
      element.fieldId = fieldId;
      // NOTE: for backward compatiblity
      const isAmountRenderer = element.options?.isSmallTextInput;
      const isTextAreaRenderer = element.options?.multiline;

      if (isAmountRenderer) {
        element.renderer = AmountRenderer;
      } else if (isTextAreaRenderer) {
        element.renderer = TextAreaRenderer;
      } else {
        // set the correct renderer
        element.renderer =
          supportedRenderers[element.renderer] || NonSupportedRenderer;
      }

      // set the correct validation
      element.validation = JSON.parse(element.validation);
      element.template = FieldTemplate;
      // element.value = element.hasOwnProperty("defaultValue")
      //   ? element.defaultValue
      //   : undefined;

      return { key, value: element, position: element.position };
    }),
    "position",
  ).reduce((prev, curr) => {
    prev[curr.key] = curr.value;
    return prev;
  }, {});

  return { ...firebaseSchema, elements };
};
