import React, { Component } from 'react';

import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

import { getCompany } from 'farmerjoe-common/lib/selectors/companies';
import { getAnalysis } from 'farmerjoe-common/lib/selectors/analyses';
import I18n from '../../language/i18n';

import { Loading } from '../Loading/Loading';

import AnalysisPDF from './AnalysisPDF';

import AnalysisOrder from './AnalysisOrder';
import ScrollableTabView from '../Common/ScrollableTabView';
import NavbarBasic from '../Common/NavbarBasic';
import { getUnreadNotificationsQuery } from 'farmerjoe-common/lib/utils/firestoreRedux/Notifications';
import { getAnalysisType } from 'farmerjoe-common/lib/utils/Analyses';
import { getAnalysisNotifications } from 'farmerjoe-common/lib/selectors/notifications';
import { markNotificationsAsRead } from 'farmerjoe-common/lib/actions/notifications';
import { bindActionCreators } from 'redux';
import { isAnalysisPage } from '../../utils/page';
import { get } from 'lodash-es';
import { getOrderAnalysisTranslation } from '../../utils/analysis/Analysis';

// TODO: improve typings
type Props = any;

class Analysis extends Component<Props> {
  private needsToMarkNotificationsAsRead?: boolean;

  state = {
    title: I18n.t('analysis.analysis'),
  };

  componentDidUpdate(prevProps) {
    const { analysis } = this.props;

    if (typeof analysis !== 'undefined' && prevProps.analysis !== analysis) {
      const title = this.getTitle();
      this.setState({ title });
    }
    if (this.props.notifications.length !== prevProps.notifications.length) {
      this.needsToMarkNotificationsAsRead = true;
    }

    // we need to check if we're on the right page because of cached routes
    if (
      this.needsToMarkNotificationsAsRead &&
      isAnalysisPage(void 0, this.props.companyId, this.props.analysisId)
    ) {
      this.markNotificationsAsRead();
    }
  }

  componentDidMount() {
    this.markNotificationsAsRead();
  }

  markNotificationsAsRead() {
    if (this.props.notifications.length) {
      this.props.actions.markNotificationsAsRead(this.props.notifications);
    }

    this.needsToMarkNotificationsAsRead = false;
  }

  render() {
    const { analysis } = this.props;

    if (!analysis) {
      return <Loading />;
    }

    const order = (
      <AnalysisOrder
        tabLabel={I18n.t('analysis.order')}
        analysis={analysis}
        labLinks={true}
      />
    );

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minHeight: 1,
          position: 'relative',
        }}>
        <NavbarBasic title={this.state.title} />
        {analysis.result
          ? (
          <ScrollableTabView tabBarPosition={'bottom'}>
            <AnalysisPDF
              tabLabel={I18n.t('analysis.result')}
              analysis={analysis}
            />
            {order}
          </ScrollableTabView>
            )
          : (
              order
            )}
      </div>
    );
  }

  private getTitle() {
    const { analysis } = this.props;
    const analysisType = getAnalysisType(analysis);

    return `${getOrderAnalysisTranslation(analysisType)} ${analysis.analyse_number}`;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        markNotificationsAsRead,
      },
      dispatch,
    ),
  };
}

const selector = (state, { analysisId, companyId }) => {
  const openCompany = companyId || selectors.getOpenCompanyId(state);
  const company = getCompany(state.firestore.data, openCompany);
  const profile = state.firebase.profile;
  const notifications = getAnalysisNotifications(
    state,
    profile.uid,
    openCompany,
    analysisId,
  );

  const analysis = getAnalysis(state, openCompany, analysisId);

  return {
    openCompany,
    company: company,
    analysisId: analysisId,
    companyId: openCompany,
    analysis: analysis,
    notifications,
    profile,
  };
};

const wrappedAnalysis = firestoreConnect(props => {
  const analysisId = props.analysisId;
  const { profile, openCompany } = props;
  const paths: any[] = [];

  if (get(profile, 'uid') && openCompany) {
    paths.push((getUnreadNotificationsQuery as any)(profile.uid, openCompany));
  }

  if (analysisId) {
    paths.push({
      collection: 'analyses',
      doc: analysisId,
    });
  }

  return paths;
})(Analysis);

export default connect(
  selector,
  mapDispatchToProps,
)(wrappedAnalysis);
