import React from "react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";

import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { getCompanyAsCollaboratorQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Companies";
import { getCompanyAsCollaborator } from "farmerjoe-common/lib/selectors/companies";

import Icon from "../Common/Icon";
import Map from "../Map/Map";
import CompanyLogo from "../Company/CompanyLogo";
import { getCountryName } from "../../utils/Countries";
import NavbarBasic from "../Common/NavbarBasic";

import { Loading } from "../Loading/Loading";

import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import "./style.css";

import withRouter from "../../components/Router/withRouter";

type Props = {
  companyId: string;
  fieldId: string;
  collaboratorId: string;
  company?: any;
  loaded?: boolean;
  history?: any;
};

const FieldCollaboratorPage = (props: Props) => {
  const { company, loaded } = props;

  if (!loaded) {
    return <Loading />;
  }

  if (!company) {
    return null;
  }

  const marker = {
    key: company.key,
    position: company.position,
    type: "company",
    title: company.name,
  };

  return (
    <div className="company">
      <NavbarBasic
        title={company.name}
        leftButton={
          <div
            className="top-bar-button gray-text"
            onClick={() =>
              props.history.replace(
                `/company/${props.companyId}/field/${props.fieldId}`,
              )
            }
          >
            <Icon iconType={"fa"} name="times" style={{ fontSize: 25 }} />
          </div>
        }
      />
      <div
        style={{
          ...{ flex: 1, overflowY: "auto", WebkitOverflowScrolling: "touch" },
        }}
      >
        <div className="company-map">
          <Map
            mapStyles={{}}
            position={company.position}
            filterNotACrop={false}
            zoomedIn={true}
            markers={[marker]}
          />
        </div>

        <div className="company-logo">
          <CompanyLogo company={company} isAdmin={false} />
        </div>

        <div style={{ paddingBottom: 20, marginTop: -60, paddingTop: 50 }}>
          <div style={{ padding: "0 20px" }}>
            <span style={constants.styles.headerText}>{company.name}</span>
            <div style={{ marginTop: 20 }}>
              {company.company_number ? (
                <React.Fragment>
                  <span>{company.company_number}</span>
                  <br />
                </React.Fragment>
              ) : null}

              <span>{company.street}</span>
              <br />
              <span>
                {company.zip} {company.city}
              </span>
              <br />
              <span>{getCountryName(company.country)}</span>
            </div>
          </div>

          <div style={{ backgroundColor: "#FFF", padding: 20}}>
            <a href={`mailto:${company.email}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon
                  name={"ios-mail-outline"}
                  style={{
                    fontSize: 18,
                    color: "#7EB549",
                    marginTop: 3,
                  }}
                />
                <span style={{ marginLeft: 5, color: "#7EB549" }}>
                  {company.email}
                </span>
              </div>
            </a>
            <a href={`tel:${company.tel}`}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon
                  name={"ios-call-outline"}
                  style={{
                    fontSize: 18,
                    color: "#7EB549",
                    marginTop: 3,
                  }}
                />
                <span style={{ marginLeft: 5, color: "#7EB549" }}>
                  {company.tel}
                </span>
              </div>
            </a>
            {company.website
              ? (
                <div
                  style={{ backgroundColor: "#FFF"}}>
                  <Icon
                    iconType={"fa"}
                    name={"globe"}
                    style={{
                      fontSize: 18,
                      color: "#7EB549",
                    }}
                  />
                  <span style={{ marginLeft: 5, color: "#7EB549" }}>
                    <a
                      href={company.website}
                      referrerPolicy="no-referrer" target="_blank" rel="nofollow, noreferrer"
                    >
                      {company.website}
                    </a>
                  </span>
                </div>
              )
              : null}
          </div>

          {company.ggn_number || company.qs_number ? (
            <div
              style={{ backgroundColor: "#FFF", padding: "0 20px" }}
            >
              {company.ggn_number ? (
                <span style={{ display: "block" }}>
                  {I18n.t("ggn_gln_number")}: {company.ggn_number}
                </span>
              ) : null}

              {company.ggn_number ? (
                <span style={{ display: "block" }}>
                  {I18n.t("company.qs_number")}: {company.qs_number}
                </span>
              ) : null}
            </div>
          ) : null}

        </div>
      </div>
    </div>
  );
};

const selector = (state, ownProps) => {
  const { collaboratorId } = ownProps;
  const collaborator = getCompanyAsCollaborator(state, collaboratorId);
  const loaded = hasLoaded(
    [getCompanyAsCollaboratorQuery(collaboratorId)],
    state,
  );

  return {
    company: collaborator,
    loaded,
  };
};

const wrappedFieldCollaborator = firestoreConnect((props) => {
  const { collaboratorId } = props;

  if (!collaboratorId) {
    return [];
  }

  return [getCompanyAsCollaboratorQuery(collaboratorId)];
})(FieldCollaboratorPage);

export default compose<React.ComponentClass<Props>>(
  connect(selector),
  withRouter,
)(wrappedFieldCollaborator);
