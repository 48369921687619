import React from 'react';
import { getCountryNameFromISOCode } from 'farmerjoe-common/lib/constants/countries';

const FieldInfo = (props) => {
  const { fieldSharingRequest } = props;
  if ( !fieldSharingRequest ) {
      return null;
  }
  const { field, company } = fieldSharingRequest;
  const { activeCrop: crop } = field;

  const textForCropAndField = `${crop.name} - ${crop.art}, ${parseFloat(field.size).toFixed(2)} ha`;
  const textCompanyName = `${company.name}`;
  const textCompanyAddress = `${company.city}, ${getCountryNameFromISOCode(company.country)}`;
  return (
      <div
          style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
          }}>

          <div>
              <div>
                  <span>{textForCropAndField}</span>
              </div>

              <div>
                  <span>{textCompanyName}</span>
              </div>

              <div >
                  <span>{textCompanyAddress}</span>
              </div>
          </div>
      </div>
  );
};

export default FieldInfo;
