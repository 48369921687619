import * as React from 'react';
import type { Crop } from '../../flowTypes';
import I18n from '../../language/i18n';
import YieldForm from './YieldForm';

type Props = {
  crop: Crop;
};
type State = {
  showForm: boolean;
};
class YieldButton extends React.PureComponent<Props, State> {
  state = {
    showForm: false,
  };

  render() {
    const { crop } = this.props;
    return (
      <div style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <button
          onClick={() => this.setState({ showForm: true })}
          className={'btn btn-primary'}
          style={{
            minWidth: 250,
            borderRadius: 25,
            paddingTop: 5,
            paddingBottom: 5,
            fontWeight: 'bold',
          }}>
          <span>{I18n.t('crop.addYield')}</span>
        </button>

        <YieldForm
          crop={crop}
          show={this.state.showForm}
          onClose={() => this.setState({ showForm: false })}
        />
      </div>
    );
  }
}

export default YieldButton;
