import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get, keys } from 'lodash-es';
import { firebaseConnect } from 'react-redux-firebase';
import './style.css';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';

import Icon from '../Common/Icon';
import DropDown from '../DropDown/DropDown';
import Settings from '../Settings/Settings';
import withRouter from '../Router/withRouter';
import NavigationBar from '../NavigationBar/NavigationBar';
import CountBadge from '../Common/CountBadge';
import type { Profile } from '../../flowTypes';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';
import Avatar from '../../containers/Avatar/Avatar';
import { classes } from '../../utils/dom';
import Invites from '../../containers/Invites';
import imgFjAvatar from '../../public/images/FJ_logo_inverted.png';

/* eslint-disable no-script-url */

class _UserProfile extends React.Component<
  {
    profile: Profile;
    firebase: Record<string, any>;
    openCompanies?: boolean | void;
    history?: Record<string, any>;
  },
  {
    showSettings: boolean;
  }
> {
  state = {
    showSettings: false,
  };

  logout() {
    this.props.history?.push('/logout');
  }

  render() {
    const {
      profile: { uid, displayName, isLoaded, isEmpty },
      profile,
    } = this.props;
    return [
      !isLoaded || isEmpty
        ? null
        : (
          <DropDown
            key={0}
            tagName="li"
            className="nav-item"
            button={
              <a
                className="nav-link dropdown-toggle text-muted waves-effect waves-dark user-profile-button"
                href="javascript: void 0">
                <Avatar uid={uid} size={'small'} style={{ lineHeight: 0 }} />
              </a>
            }>
            <ul className="dropdown-user">
              <li>
                <div className="dw-user-box">
                  <div className="u-img">
                    <Avatar uid={uid} size={'medium'} />
                  </div>
                  <div className="u-text">
                    <h4>{displayName}</h4>
                  </div>
                </div>
              </li>
              <li>
                <a
                  href="javascript:void 0"
                  onClick={() => this.setState({ showSettings: true })}>
                  <Icon
                    name="md-person"
                    style={{ fontSize: 26, color: constants.FJMUTEDDARK }}
                  />{' '}
                  {I18n.t('account.account')}
                </a>
              </li>
              <li>
                <a href="javascript: void 0" onClick={this.logout.bind(this)}>
                  <i className="fa fa-power-off" /> {I18n.t('logout')}
                </a>
              </li>
            </ul>
          </DropDown>
        ),
      <Settings
        key={1}
        show={this.state.showSettings}
        onClose={() => this.setState({ showSettings: false })}
        uid={profile.uid}
      />,
    ];
  }
}

const UserProfile = compose<typeof _UserProfile>(withRouter)(_UserProfile);

type HeaderProps = {
  children?: React.ReactNode;
  title?: React.ReactNode | null;
  usersWithPrivileges?: Array<any>;
  profile?: Profile;
  firebase?: Record<string, any>;
  dispatch?: (...args: Array<any>) => any;
  openCompanies?: boolean;
  invites?: Record<string, any>;
};

class Header extends React.Component<HeaderProps> {
  static contextTypes = {
    router: PropTypes.object,
  };

  render() {
    const {
      title,
      profile,
      firebase,
      openCompanies,
      invites,
    } = this.props;
    const inviteCount = keys(invites).length;

    return (
      <header className={classes('topbar', openCompanies && 'open-companies')}>
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          {/* logo */}
          <div className="navbar-header">
            <div className="branding navbar-brand">
              <img className="logo" src={imgFjAvatar} alt="" />
            </div>
          </div>

          <ul className="navbar-nav mr-auto mt-md-0"></ul>

          {profile?.isLoaded && !profile.isEmpty ? (
            <div className="top-nav navbar-header flex-grow-1">
              <h3 className={'title'}>{title as React.ReactNode}</h3>

              <NavigationBar />
            </div>
          ) : null}

          <div className="buttons-right navbar-collapse flex-grow-0">
            <ul className="navbar-nav my-lg-0">
              {inviteCount
                ? (
                  <li className="nav-item">
                    <Link
                      className="open-invites waves-effect waves-dark nav-link"
                      to="/invites">
                      <span className="icon">
                        <Icon
                          iconType="fa"
                          name="envelope"
                          style={{ verticalAlign: 'text-top' }}
                        />
                        <CountBadge count={inviteCount} />
                      </span>
                    </Link>
                  </li>
                )
                : null}
              {/* {openCompany */}
              {/* ? <li className="nav-item"> */}
              {/* <NotificationsPopup */}
              {/* button={ */}
              {/* <a className="notifications waves-effect waves-dark nav-link" href="javascript: void 0"> */}
              {/* <span className="icon"> */}
              {/* <Icon iconType="fa" name="bell" style={{verticalAlign: 'text-top'}}/> */}
              {/* <UnreadCompanyNotificationsBadge/> */}
              {/* </span> */}
              {/* </a> */}
              {/* } */}
              {/* /> */}
              {/* </li> */}
              {/*: null */}
              {/* } */}
              <UserProfile profile={profile as any} firebase={firebase as any} />
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default compose<typeof Header>(
  firebaseConnect(),
  connect(
    (state: AnalyserOptions) => ({
      profile: get(state, 'firebase.profile'),
      openCompany: getOpenCompanyId(state as any),
    }),
    dispatch => ({ dispatch }),
  ),
  Invites,
)(Header);
