import React, { useEffect, useState, useRef } from "react";
import { compose } from "redux";

import {
  createSetupIntent,
  attachPaymentMethod,
} from "farmerjoe-common/lib/requests/billing";

import { Loading } from "../Loading/Loading";
import withRouter from "../Router/withRouter";

import { STRIPE_CLIENT_PK } from "../../constants";
import { getUidToken } from "../../utils/auth";

import I18n from "../../language/i18n";

import {
  TEAM_SUBSCRIPTION_CURRENCY_EUR,
} from "farmerjoe-common/lib/constants/billing";

const stripe = (window as any).Stripe(STRIPE_CLIENT_PK);

type Props = {
  companyId: string;
  onError: (err: any) => void;
  onSuccess: () => void;
};

const UpdatePaymentMethod = (props: Props) => {
  const { companyId, onError, onSuccess } = props;
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState(null);


  useEffect(() => {
    const options = {
      mode: "setup",
      currency: TEAM_SUBSCRIPTION_CURRENCY_EUR,
      paymentMethodTypes: ["card"],
    };

    const mountStripe = () => {
      const _elements = stripe.elements(options);
      const paymentElement = _elements.create("payment");
      paymentElement.mount("#payment-element");
      setElements(_elements);
    };
    if (!elements) mountStripe();
  }, [elements]);


  const submitBtnRef = useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();

    if ((submitBtnRef as any).current.disabled) {
      return;
    }

    (submitBtnRef as any).current.disabled = true;

    // Trigger form validation and wallet collection
    if (!elements) {
      return;
    }

    const {error: submitError} = await (elements as any).submit();
    if (submitError) {
      console.log(submitError);
      return;
    }

    setLoading(true);
    try {
      const uidToken = await getUidToken();
      const resultCreateSetupIntent = await createSetupIntent(
        companyId,
        uidToken,
      );
      if (resultCreateSetupIntent.status !== 200) {
        throw new Error(
          `Server responded with: ${resultCreateSetupIntent.status}`,
        );
      }

      const { client_secret } = await resultCreateSetupIntent.json();

      const {error, setupIntent} = await stripe.confirmSetup({
        elements,
        clientSecret: client_secret,
        confirmParams: {
          return_url: window.location.toString(),
        },
        redirect: "if_required",
      });

      if (error) {
        console.error(error);
        onError(error);
        return;
      }

      const { payment_method } = setupIntent;

      const result = await attachPaymentMethod(
        companyId,
        payment_method,
        uidToken,
      );
      if (result.status !== 200) {
        throw new Error(
          `Server responded with: ${resultCreateSetupIntent.status}`,
        );
      }
      onSuccess();
    } catch(err) {
      console.error(err);
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      margin: "0 auto",
    }}>
      <form id="payment-form" onSubmit={onSubmit}>
        <div id="payment-element">
        </div>
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}>
            <button
              className={"btn btn-primary"}
              id="submit"
              ref={submitBtnRef}
              style={{
                textTransform: "uppercase",
              }}
            >
              {I18n.t("billing.save")}
            </button>
          </div>
        )}
        <div id="error-message">
        </div>
      </form>
    </div>
  );
};
export default compose<typeof UpdatePaymentMethod>(withRouter)(UpdatePaymentMethod);
