import * as React from 'react';
import { classes } from '../../../utils/dom';
import './style.css';
import { Avatar as AvatarComponent } from '../../Common/Avatar';
import { get } from 'lodash-es';
import Avatar from '../../../containers/Avatar/Avatar';
import Name from '../../Users/Name';
import { COMPANY_MAIN_GROUP_KEY } from 'farmerjoe-common/lib/selectors/groups';
import * as constants from '../../../styles/style';
import moment from 'moment';
import { toDate } from 'farmerjoe-common';

import imgFjLogo from '../../../public/images/FJ_logo.png';

type Props = {
  onClick?: () => void;
  className?: string;
  title: React.ReactNode;
  body: React.ReactNode;
  notification: any;
  userType: 'user' | 'lab' | 'system';
};

const styles = constants.styles;

export default function NotificationCard({
  onClick,
  className,
  notification,
  userType,
  title,
  body,
}: Props) {
  const { created } = notification;
  let image: any = null;
  let name: any = null;

  switch (userType) {
    case 'user':
      image = <UserImage notification={notification} />;
      name = <UserName notification={notification} />;
      break;
    case 'lab':
      image = <LabImage notification={notification} />;
      name = <LabName notification={notification} />;
      break;
    default:
      image = <SystemImage notification={notification} />;
      name = <SystemName notification={notification} />;
      break;
  }

  return (
    <div
      className={classes(
        'notification',
        notification.read ? 'read' : 'unread',
        className,
      )}
      onClick={onClick}>
      {image}

      <div className="notification-container">
        <h5 className="notification-title">{title}</h5>
        <div className="notification-body">{body}</div>
        <div className="notification-footer">
          <div className="name">{name}</div>
          <div className="date">
            <span
              style={{
                ...styles.muted,
                ...styles.extraSmall,
                ...{ color: constants.FJMUTEDLIGHT },
              }}>
              {created
                ? moment
                  .utc(toDate(created))
                  .local()
                  .format('D.M.YYYY')
                : moment().format('D.M.YYYY')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function UserImage({ notification }) {
  const uid = get(notification, 'created_by.uid');
  return uid ? <Avatar uid={uid} size="small" round={true} /> : <SystemImage />;
}

function LabImage({ notification }) {
  const logo = get(notification, 'labSelected.logo.uri');
  return logo
    ? (
    <AvatarComponent
      downloadUrl={logo}
      isLoaded={true}
      size="small"
      round={true}
    />
      )
    : (
    <SystemImage />
      );
}

type SystemImageProps = {
  notification?: any;
};

function SystemImage(props: SystemImageProps = {}) {
  return (
    <AvatarComponent
      downloadUrl={imgFjLogo}
      isLoaded={true}
      size="small"
      round={true}
    />
  );
}

function UserName({ notification }) {
  let name = get(notification, 'created_by.email');

  if (get(notification, 'created_by.name')) {
    name = get(notification, 'created_by.name');
  }

  return (
    <Name
      groupId={get(notification, 'created_by.group') || COMPANY_MAIN_GROUP_KEY}
      name={name}
    />
  );
}

function LabName({ notification }) {
  const name = get(notification, 'labSelected.name');
  return name;
}

function SystemName({ notification }) {
  return null;
}
