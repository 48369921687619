import React from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { findIndex, get } from "lodash-es";
import PropTypes from "prop-types";

import { canDo, isAdmin } from "farmerjoe-common/lib/utils/User";
import { filters } from "farmerjoe-common/lib/actions/actions";
import
* as fieldActions from "farmerjoe-common/lib/actions/field";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import * as companySelectors from "farmerjoe-common/lib/selectors/companies";
import {
  getSortedByNearestBy,
  getSortedFields,
} from "farmerjoe-common/lib/selectors/fields";
import { orderWaitTimesByFieldId } from "farmerjoe-common/lib/selectors/waittimes";
import { getCropByFieldId } from "farmerjoe-common/lib/selectors/crops";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { SortedSections } from "farmerjoe-common/lib/utils/FieldsGrouper";
import { FieldState } from "farmerjoe-common/lib/flow/types";
import { setFieldsTab } from "farmerjoe-common/lib/actions/ui";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";

import CropFieldList from "./CropFieldsList";
import FieldListNearBy from "./FieldListNearBy";
import Harvested from "./Harvested";
import Filters from "./Filters";
import FieldsViewSwitch from "./FieldsViewSwitch";
import DateRangeFilter from "./DateRangeFilter";
import { getFieldsSorting, canSeeHarvested } from "./utils";
import { FIELDS_TAB_HARVESTED, FIELDS_TAB_ACTIVE } from "./constants";
import "./style.css";

import TabBarSearchSort from "../Common/TabBarSearchSort";
import SearchInput from "../Common/SearchInput";
import ScrollableTabView from "../Common/ScrollableTabView";
import { CreateNewButton } from "../Common/NewButton";
import CreateField from "../Field/CreateField";
import withRouter, { FjRouteChildrenProps } from "../Router/withRouter";

import I18n from "../../language/i18n";
import * as constants from "../../styles/style";
import fieldsContainer from "../../containers/Fields";
import { getFieldPath } from "../../utils/page";
import type { Company, Employee } from "../../flowTypes";

type OwnProps = {
  isMapPage?: boolean;
};

/**
 * Typings for Props parsed from redux store
 */
type StateProps = {
  company?: Company;
  myCompanyProfile?: Employee;
  sortedFields?: Array<any>;
  openFieldPath?: Record<string, any>;
  waitTimes?: any;
  filter?: Record<string, any>;
  sort?: string | null;
  loading?: boolean;
  currentFieldsTab: typeof FIELDS_TAB_ACTIVE | typeof FIELDS_TAB_HARVESTED;
  openCompany: string;
  fieldState: FieldState;
};

/**
 * Typings for reducers from the redux store
 */

type FieldActionsType = typeof fieldActions;
type SetFieldsTabType = typeof setFieldsTab;
type FiltersType = typeof filters;
type DispatchProps = {
  actions: FieldActionsType & { setFieldsTab: SetFieldsTabType } & {filters: FiltersType};
};

type Props = StateProps & DispatchProps & OwnProps & FjRouteChildrenProps;

type State = {
  showCreateForm: boolean;
  renderMap: boolean;
  company?: Company;
  render: boolean;
  showModalMap: boolean;
};

class Fields extends React.PureComponent<Props, State> {
  static contextTypes = {
    router: PropTypes.object,
  };

  // Initialize the hardcoded data
  constructor(props, context) {
    super(props, context);
    this.state = {
      renderMap: false,
      company: this.props.company,
      render: false,
      showCreateForm: false,
      showModalMap: false,
    };
  }

  componentDidMount() {
    if (!this.props.currentFieldsTab) {
      this.props.actions.setFieldsTab(this.props.openCompany, FIELDS_TAB_ACTIVE);
    }
  }

  render() {
    const { company, myCompanyProfile, isMapPage, filter, currentFieldsTab, openCompany } = this.props;
    const styles = constants.styles;
    const search = get(filter, "search");

    let showNew = currentFieldsTab === FIELDS_TAB_ACTIVE;

    if (!isAdmin(myCompanyProfile as any) && !canDo("create", "field", myCompanyProfile as any)) {
      showNew = false;
    }

    return (
      <div className="fields">
        <div style={styles.containerColumn}>
          {/* <ModalDateComponent locals={{value: new Date()}} /> */}
          <ScrollableTabView
            onChangeTab={(index) => {
              this.props.actions.setFieldsTab(openCompany, index.i === 0 ? FIELDS_TAB_ACTIVE : FIELDS_TAB_HARVESTED);
            }}
            renderTabBar={() => (
              <TabBarSearchSort
                searchVisible={!!search}
                onCloseSearch={() => {
                  this.props.actions?.filters((company as any).key, {
                    search: "",
                  });
                }}
                searchComponent={
                  <SearchInput
                    onChange={text => {
                      this.props.actions?.filters((company as any).key, {
                        search: text,
                      });
                    }}
                    company={company}
                    search={search}
                  />
                }
                sortComponent={
                  <Filters
                    key={currentFieldsTab}
                    cropState={currentFieldsTab}
                  />
                }
                beforeTabBarComponent={
                  <FieldsViewSwitch key="fields-view-switch" />
                }
                afterTabBarComponent={[
                  showNew
                    ? (
                      <CreateNewButton
                        key="create-new-button"
                        onClick={() => this.setState({ showCreateForm: true })}
                      />
                    )
                    : (
                      <div style={{ width: 35 }} key="create-new-spacer"></div>
                    ),
                ]}
                beforeSearchComponent={
                  currentFieldsTab === FIELDS_TAB_HARVESTED
                    ? (
                      <>
                        <DateRangeFilter />
                        <div style={{ flexBasis: "100%", height: 0 }}></div>
                      </>
                    )
                    : null
                }
              />
            )}>
            {this.renderFields()}

            {!isMapPage && canSeeHarvested(myCompanyProfile) ? (
              <Harvested
                tabLabel={I18n.t("harvested")}
                harvestedIsShown={currentFieldsTab === FIELDS_TAB_HARVESTED}
                isActiveTab={currentFieldsTab === FIELDS_TAB_HARVESTED}
              />
            )
              : null}
          </ScrollableTabView>
        </div>

        {this.state.showCreateForm
          ? (
            <CreateField
              show={this.state.showCreateForm}
              onClose={() => this.setState({ showCreateForm: false })}
            />
          )
          : null}
      </div>
    );
  }

  renderFields() {
    const {
      company,
      sortedFields,
      loading,
      waitTimes,
      myCompanyProfile,
      sort,
      currentFieldsTab,
    } = this.props;

    if (sort === "nearBy") {
      return (
        <FieldListNearBy
          tabLabel={I18n.t("current") as any}
          isActiveTab={currentFieldsTab === FIELDS_TAB_ACTIVE}
          fields={(loading ? null : sortedFields) as any}
          waitTimes={waitTimes}
          loading={loading}
          filter={this.props.filter as any}
          myCompanyProfile={myCompanyProfile as any}
          onClick={(key, cropKey) => {
            this.props.actions?.openField(key, cropKey);
            this.props.history?.push(getFieldPath(company?.key as any, key));
          }}
          openFieldPath={this.props.openFieldPath as any}
          isMapPage={this.props.isMapPage as any}
          cropState={currentFieldsTab}
        />
      );
    }

    return (
      <CropFieldList
        tabLabel={I18n.t("current")}
        isActiveTab={currentFieldsTab === FIELDS_TAB_ACTIVE}
        fields={loading ? null : sortedFields}
        waitTimes={waitTimes}
        loading={loading}
        filter={this.props.filter as any}
        myCompanyProfile={myCompanyProfile}
        onClick={this.onClickListItem}
        openFieldPath={this.props.openFieldPath}
        isMapPage={this.props.isMapPage}
      />
    );
  }

  onClickListItem = (key, cropKey) => {
    const { company } = this.props;
    this.props.actions?.openField(key, cropKey);
    this.props.history?.push(getFieldPath(company?.key as any, key));
  };
}

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
          setFieldsTab,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps: OwnProps): StateProps => {
  const openCompany = selectors.getOpenCompanyId(state);
  const company = companySelectors.getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const sortFieldsBy = getFieldsSorting(state, openCompany, "active", "fieldName");
  const browsingGroup = getBrowsingGroupKey(state, openCompany);

  let sortedFields: any[] = [];
  let openFieldPath: any = null;

  if (sortFieldsBy === "nearBy") {
    sortedFields = getSortedByNearestBy(
      state,
      openCompany,
      // @ts-ignore
      state.firebase.auth.uid,
      state.locationPermission,
      state.userPosition,
      { showCrops: ownProps.isMapPage, cropAge: ownProps.isMapPage },
    );
  } else {
    const sortedFieldSections: SortedSections = getSortedFields(
      state,
      openCompany,
      // @ts-ignore
      state.firebase.auth.uid,
      { showCrops: ownProps.isMapPage, cropAge: ownProps.isMapPage },
    );
    openFieldPath = sortedFieldSections.openFieldPath;
    sortedFields = sortedFieldSections.sections;
  }

  if (sortFieldsBy === "nearBy" && state.openFieldId) {
    const index = findIndex(sortedFields, { key: state.openFieldId });

    if (index !== -1) {
      openFieldPath = {
        field: sortedFields[index],
        crop: getCropByFieldId(
          state.firestore.data,
          openCompany,
          sortedFields[index].key,
          state.openCropId,
        ),
      };
    }
  }

  const currentFieldsTab = get(state, `currentFieldsTab.${openCompany}`, null);

  return {
    company,
    myCompanyProfile,
    sortedFields,
    openFieldPath,
    waitTimes: orderWaitTimesByFieldId(state, openCompany),
    filter: state.filtersByCompany[openCompany],
    sort: sortFieldsBy,
    currentFieldsTab,
    openCompany,
    fieldState: FieldState.Active,
  };
};

export default compose<React.ComponentClass<OwnProps>>(
  connect<StateProps, DispatchProps, OwnProps>(
    selector,
    mapDispatchToProps,
  ),
  fieldsContainer,
  withRouter,
)(Fields);
