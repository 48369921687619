import React from "react";
import { includes, isString } from "lodash-es";
import { classes } from "../../../utils/dom";

type Props = {
  className?: string;
  error?: boolean;
  label?: string;
  field?: {
    value: any;
    name: string;
  };
  form?: {
    setFieldValue: (arg0: string, arg1: any) => {};
  };
  options: Array<{
    text: string;
    value: any;
  }>;
  multiSelect?: boolean;
  help?: null | string;
};
export default class SegmentedControl extends React.PureComponent<Props> {
  render() {
    const {
      className,
      label,
      error,
      field,
      form,
      multiSelect = false,
      help,
    } = this.props;

    let { options } = this.props;

    options = options.map((a: any) => (!isString(a) ? a : { text: a, value: a }));

    return (
      <div
        className={classes(
          "segmented-control-wrapper-1",
          className,
          error && "error",
        )}>
        {label ? <div className="segmented-control-label">{label}</div> : null}
        <div className="segmented-control-wrapper-2">
          <div className={classes("segmented-control-container")}>
            <div className="list-group segmented-control">
              {options.map(({ text, value }, i) => (
                <a
                  href="javascript:void 0"
                  key={i}
                  className={classes(
                    "list-group-item",
                    multiSelect
                      ? includes(field?.value, value) && "active"
                      : // eslint-disable-next-line eqeqeq
                      field?.value === value && "active", // needs to be == and not ===
                  )}
                  onClick={() => {
                    form?.setFieldValue(field?.name as string, value);
                  }}
                  title={text}>
                  <span style={{whiteSpace: "nowrap"}}>{text}</span>
                </a>
              ))}
            </div>
            {help || null}
          </div>
        </div>
      </div>
    );
  }
}
