import React, { useState, useEffect } from 'react';
import I18n from '../../../language/i18n';

const ModalFailure = ({ onClose }) => {
  return (
    <div>
      <div className='modal-body-content'>
        <p>{I18n.t('fieldSharing.genericErrorMessage')}</p>
      </div>
      <div className="d-flex flex-grow-1">
        <button className="ml-auto btn btn-secondary" onClick={onClose}>
          {I18n.t('ok')}
        </button>
      </div>
    </div>
  );
};

export default ModalFailure;
