import * as React from 'react';
import { Loading } from '../components/Loading/Loading';
import Page from '../components/Page/Page';
import type { Profile } from '../flowTypes';
import Invites from './Invites';

type Props = {
  children?: React.ReactNode;
  profile?: Profile;
  invites?: Record<string, any>;
  loadingInvites?: boolean;
  key?: any;
};

class Loader extends React.Component<Props> {
  render() {
    const { children, profile, loadingInvites } = this.props;
    if (!profile?.isLoaded || loadingInvites) {
      return (
        <Page wrapperClassName="d-flex align-content-center justify-content-center flex-column">
          <Loading isLoading={true} />
        </Page>
      );
    } else {
      return children || null;
    }
  }
}

export default (Invites as any)(Loader) as (typeof Loader);
