import React from 'react';
import { connect } from 'formik';
import { getError } from '@flipbyte/formik-json/lib/utils';

// TODO: improve typings
type Props = any;
type State = any;

class ErrorMessage extends React.Component<Props, State> {
  shouldComponentUpdate(nextProps) {
    const { name, formik } = this.props;
    const currentError = getError(name, formik);
    const nextError = getError(name, nextProps.formik);

    return currentError !== nextError;
  }

  render() {
    const { name, formik } = this.props;
    const error = getError(name, formik);
    if (typeof error === 'string') {
      return error && <div className="invalid-feedback">{error}</div>;
    }

    if (typeof error === 'object') {
      return (
        <div className={'invalid-feedback'}>
          {Object.keys(error)
            .map(key => error[key])
            .join('<br/>')}
        </div>
      );
    }

    return null;
  }
}

export default connect(ErrorMessage);
