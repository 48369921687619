import React, { useState } from "react";
import * as constants from "../../styles/style";
import Icon from "./Icon";

const Expandable = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div style={{ ...props.containerStyles }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent:"space-between",
          cursor: "pointer",
          borderBottom: `1px solid ${constants.FJMUTEDLIGHTER}`,
          margin: "0 0 1em 0",
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div
          style={{
            marginBottom: "5px",
            fontWeight: "bolder",
            ...props.labelStyle,
          }}
        >{props.label}</div>
        <div>
          <Icon
            iconType={"fa"}
            name={isCollapsed ? "arrow-up" : "arrow-down"}
            style={{
              fontSize: 16,
              color: constants.FJMUTEDLIGHTER,
            }}
          />
        </div>
      </div>
      {isCollapsed ? (
        <div>{props.children}</div>
      ) : null}
    </div>
  );
};

export default Expandable;
