import React from 'react';

import { numberTransformer } from '../transformers/transformers';
import I18n from '../../language/i18n';
import Yield from '../templates/Yield';

const t = require('tcomb-form');

// Our crop model
const model = t.struct({
  yield: t.Number,
});

const options = function(state: { yield: number }, ha: number) {
  return {
    auto: 'none',
    fields: {
      yield: {
        label: I18n.t('crop.yieldInT'),
        factory: Yield,
        value: state.yield,
        transformer: numberTransformer,
        config: {
          ha: ha,
        },
      },
    },
  };
};

export default { model, options };
