/*

 a bootstrap like style

 */

import * as constants from '../../styles/style';

const LABEL_COLOR = constants.FJMUTED;
const INPUT_COLOR = '#000000';
const ERROR_COLOR = constants.FJAPPLERED;
const HELP_COLOR = '#999999';
const BORDER_COLOR = constants.FJBORDERCOLOR;
const DISABLED_COLOR = '#777777';
const FONT_SIZE = 18;

export default Object.freeze({
  fieldset: {},
  // the style applied to the container of all inputs
  formGroup: {
    normal: {
      marginBottom: 5,
      marginLeft: 20,
      marginRight: 20,
      paddingBottom: 0,
      paddingTop: 5,
      borderBottomWidth: 1,
      borderColor: BORDER_COLOR,
    },
    error: {
      marginBottom: 5,
      marginLeft: 20,
      marginRight: 20,
      paddingBottom: 0,
      paddingTop: 5,
      borderBottomWidth: 1,
      borderColor: ERROR_COLOR,
    },
  },
  formGroupOuter: {
    normal: {
      backgroundColor: '#FFFFFF',
    },
  },
  controlLabel: {
    normal: {
      color: LABEL_COLOR,
      fontSize: 14,
      marginBottom: 0,
    },
    // the style applied when a validation error occours
    error: {
      padding: 0,
      color: ERROR_COLOR,
      marginBottom: 0,
    },
  },
  helpBlock: {
    normal: {
      color: HELP_COLOR,
      fontSize: FONT_SIZE,
      marginBottom: 2,
    },
    // the style applied when a validation error occours
    error: {
      color: HELP_COLOR,
      fontSize: FONT_SIZE,
      marginBottom: 2,
    },
  },
  errorBlock: {
    fontSize: FONT_SIZE,
    marginBottom: 2,
    color: ERROR_COLOR,
  },
  textbox: {
    normal: {
      color: INPUT_COLOR,
      fontSize: FONT_SIZE,
      height: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 3,
      paddingBottom: 1,
      borderWidth: 0,
      marginBottom: 0,
    },
    // the style applied when a validation error occours
    error: {
      color: ERROR_COLOR,
      fontSize: FONT_SIZE,
      paddingTop: 3,
      height: 'auto',
    },
    // the style applied when the textbox is not editable
    notEditable: {
      fontSize: FONT_SIZE,
      height: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 3,
      paddingBottom: 1,
      marginBottom: 5,
      color: DISABLED_COLOR,
    },
  },
  checkbox: {
    normal: {
      marginBottom: 4,
    },
    // the style applied when a validation error occours
    error: {
      marginBottom: 4,
    },
  },
  select: {
    normal: {
      marginBottom: 4,
    },
    // the style applied when a validation error occours
    error: {
      marginBottom: 4,
    },
  },
  pickerContainer: {
    normal: {
      marginBottom: 4,
      borderRadius: 4,
      borderColor: BORDER_COLOR,
      borderWidth: 1,
    },
    error: {
      borderColor: ERROR_COLOR,
    },
    open: {
      // Alter styles when select container is open
    },
  },
  pickerTouchable: {
    normal: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
    },
    error: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  pickerValue: {
    normal: {
      fontSize: FONT_SIZE,
      paddingLeft: 7,
    },
    error: {
      fontSize: FONT_SIZE,
      paddingLeft: 7,
    },
  },
  datepicker: {
    normal: {
      marginBottom: 4,
    },
    // the style applied when a validation error occours
    error: {
      marginBottom: 4,
    },
  },
  dateTouchable: {
    normal: {},
    error: {},
  },
  dateValue: {
    normal: {
      color: INPUT_COLOR,
      fontSize: FONT_SIZE,
      padding: 7,
      marginBottom: 5,
    },
    error: {
      color: ERROR_COLOR,
      fontSize: FONT_SIZE,
      padding: 7,
      marginBottom: 5,
    },
  },
  buttonText: {
    fontSize: 18,
    color: 'white',
    alignSelf: 'center',
  },
  button: {
    height: 36,
    backgroundColor: '#48BBEC',
    borderColor: '#48BBEC',
    borderWidth: 1,
    borderRadius: 8,
    marginBottom: 10,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  textboxView: {
    normal: {},
    error: {},
    notEditable: {},
  },
});
