import stylesheet from '../stylesheets/style';

import I18n from '../../language/i18n';
import FormInput from '../templates/FormInput';

const t = require('tcomb-form');

// Our crop model
const model = t.struct({
  currentPassword: t.String,
  password1: t.String,
  password2: t.String,
});

const options = function(component) {
  return {
    stylesheet: stylesheet,
    auto: 'none',
    fields: {
      currentPassword: {
        label: `${I18n.t('password.current')} *`,
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        config: {
          password: true,
        },
        secureTextEntry: true,
        onSubmitEditing: () =>
          component.refs.form.getComponent('password1').refs.input.focus(),
      },
      password1: {
        label: `${I18n.t('password.password')} *`,
        returnKeyType: 'next',
        blurOnSubmit: false,
        factory: FormInput,
        autoCorrect: false,
        config: {
          password: true,
        },
        secureTextEntry: true,
        onSubmitEditing: () =>
          component.refs.form.getComponent('password2').refs.input.focus(),
      },
      password2: {
        label: `${I18n.t('password.repeat')} *`,
        returnKeyType: 'send',
        blurOnSubmit: false,
        autoCorrect: false,
        factory: FormInput,
        config: {
          password: true,
        },
        secureTextEntry: true,
        onSubmitEditing: () => component.onRight(),
      },
    },
  };
};

export default { model, options };
