import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import fire from "firebase/app";
import "firebase/firestore";
import { slice } from "ramda";
import t from "tcomb-form";
import { get } from "lodash-es";

import { UserObj } from "farmerjoe-common/lib/utils/User";
import { editFertilizing } from "farmerjoe-common/lib/actions/actions";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { searchForFieldAmongTheUniverse } from "farmerjoe-common/lib/selectors/fields";
import { getCropByFieldId } from "farmerjoe-common/lib/selectors/crops";
import { getBrowsingGroupKey } from "farmerjoe-common/lib/selectors/groups";
import { getFavoritesQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Favorites";
import { getFertilizingsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Fertilizings";
import { getFavorites } from "farmerjoe-common/lib/selectors/favorites";
import { getFertilizings } from "farmerjoe-common/lib/selectors/fertilizings";
import { getCropQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Crops";

import FertilizersFieldsSelector from "./FertilizersFieldsSelector";
import FertilizerMarkArea from "./FertilizerMarkArea";

import DeleteButton from "../Common/DeleteButton";
import Favorites from "../Common/Favorites";
import withRouter from "../Router/withRouter";
import Dialog from "../Dialog/Dialog";

import * as commentUtils from "../../utils/Comment";
import { dummy } from "../../utils/Comment";
import * as FertilizingUtils from "../../utils/Fertilizing";
import FertilizingModel from "../../tcomb/models/fertilizing";
import firebase from "../../data/firebase";
import I18n from "../../language/i18n";

import * as constants from "../../styles/style";
import "./style.css";

const Form = t.form.Form;

// TODO: improve typings
type Props = {
  onClose: (...args: any) => any;
  show: boolean;
  editMode: boolean;
  comment: any;
  actions: {
    editFertilizing: (...args: any) => any;
  };
  field: any;
  profile: any;
  browsingGroup: any;
  fertilizings: any;
  favorites: any;
};
type State = any;

class FormFertilizing extends Component<Props, State> {
  form: any;

  constructor(props) {
    super(props);

    this.state = {
      name: null,
      kg: null,
      n: null,
      p2o5: null,
      k2o: null,
      s: null,
      mg: null,
      applied_on: new Date(),
      alertMessage: null,
      markedArea: {
        polygon: [],
        center: null,
        areaSize: 0,
      },
      selectedFields: {},
      showMapAreaDialog: false,
      showFieldsSelectorDialog: false,
    };

    if (this.props.editMode) {
      const json = this.props.comment.text;

      const _state = {
        name: json.name,
        kg: json.kg,
        n: json.n.percentage,
        p2o5: json.p2o5.percentage,
        k2o: json.k2o.percentage,
        s: json.s.percentage,
        mg: json.mg.percentage,
        applied_on: json.applied_on,
        error: false,
        alertMessage: null,
        selectedFields: get(json, "selectedFields", {}),
        markedArea: get(json, "markedArea", {
          polygon: [],
          center: null,
        }),
        showMapAreaDialog: false,
        showFieldsSelectorDialog: false,
      };

      this.state = { ..._state };
    }

    this.form = React.createRef();
  }

  componentWillUnmount() {
    this.props.actions.editFertilizing(null);
  }

  onSave() {
    const form = this.form.current;
    if (!form) {
      return;
    }

    const value = form.getValue();

    // If value is null, then the form has errors
    if (value === null) {
      this.setState({ alertMessage: I18n.t("please_correct_your_entry") });
      return;
    }

    const db = firebase.firestore();
    const batch = db.batch();

    if (this.props.editMode) {
      this.editFertilizer(batch);
    } else {
      const selectedFields = Object.keys(this.state.selectedFields);
      if (selectedFields.length) {
        selectedFields.map((fieldId) => this.createFertilizer(batch, this.state.selectedFields[fieldId]));
      }
      this.createFertilizer(batch, this.props.field);
    }

    if (value.favorite) {
      this.createFavoriteFertilizer(batch);
    }

    batch
      .commit()
      .catch(e => console.log(e));

    this.props.onClose();
  }

  createFertilizer(batch, field) {
    const { profile, browsingGroup, fertilizings } = this.props;
    const db = firebase.firestore();
    const fertilizerRef = db.collection("fertilizings").doc();
    const commentRef = db.collection("comments").doc();
    const fertilizerKey = fertilizerRef.id;

    let fertilizing: any = {
      key: fertilizerKey,
      field_id: field.key,
      active_crop_uid: field.activeCrop.key,
      company_id: field.company_id,
      name: this.state.name,
      kg: this.state.kg,
      group_id: browsingGroup,
      modified: null,
      modified_by: null,
      n: {
        percentage: this.state.n,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.n),
      },
      p2o5: {
        percentage: this.state.p2o5,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.p2o5),
      },
      k2o: {
        percentage: this.state.k2o,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.k2o),
      },
      s: {
        percentage: this.state.s,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.s),
      },
      mg: {
        percentage: this.state.mg,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.mg),
      },
      applied_on: this.state.applied_on,
      created: fire.firestore.FieldValue.serverTimestamp(),
      created_by: UserObj(profile),
    };

    if (this.state.markedArea.polygon.length > 2) {
      fertilizing = {
        ...fertilizing,
        ...{
          markedArea: this.state.markedArea,
        },
      };
    }

    const sum = commentUtils.getFertilizerSum([...fertilizings, fertilizing]);

    const lastComment = {
      ...dummy(
        commentRef.id,
        field.company_id,
        field,
        field.activeCrop,
        profile,
        "system.fertilizing",
        fertilizerKey,
      ),
      text: fertilizing,
      extraData: {
        total: sum,
      },
    };
    batch.set(fertilizerRef, fertilizing);
    batch.set(commentRef, lastComment);
  }

  editFertilizer(batch) {
    const { comment, profile, fertilizings } = this.props;

    const db = firebase.firestore();
    const oldFertilizing = comment.text;
    const fertilizerRef = db.collection("fertilizings").doc(oldFertilizing.key);
    const commentRef = db.collection("comments").doc(comment.key);

    // if we are here, then we don't have any errors
    let fertilizing: any = {
      ...oldFertilizing,
      name: this.state.name,
      kg: this.state.kg,
      n: {
        percentage: this.state.n,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.n),
      },
      p2o5: {
        percentage: this.state.p2o5,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.p2o5),
      },
      k2o: {
        percentage: this.state.k2o,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.k2o),
      },
      s: {
        percentage: this.state.s,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.s),
      },
      mg: {
        percentage: this.state.mg,
        kg: FertilizingUtils.calculateKg(this.state.kg, this.state.mg),
      },
      applied_on: this.state.applied_on,
      modified: fire.firestore.FieldValue.serverTimestamp(),
      modified_by: UserObj(profile),
    };

    if (this.state.markedArea.polygon.length > 2) {
      fertilizing = {
        ...fertilizing,
        markedArea: this.state.markedArea,
      };
    }

    if (!this.state.markedArea.polygon.length) {
      fertilizing = {
        ...fertilizing,
        markedArea: fire.firestore.FieldValue.delete(),
      };
      batch.update(fertilizerRef, fertilizing);
      delete fertilizing.markedArea;
    }

    const index = fertilizings.findIndex(x => x.key === fertilizing.key);
    let fertilizingsForSum = [];
    if (index !== 0 && index !== -1) {
      fertilizingsForSum = slice(0, index, fertilizings);
    }

    const sum = commentUtils.getFertilizerSum([
      ...fertilizingsForSum,
      fertilizing,
    ]);

    batch.set(fertilizerRef, fertilizing, {merge: true});
    batch.update(commentRef, {
      text: fertilizing,
      extraData: { total: sum },
      modified: fire.firestore.FieldValue.serverTimestamp(),
      modified_by: UserObj(profile),
    });
  }

  createFavoriteFertilizer(batch) {
    const { field, browsingGroup } = this.props;
    const db = firebase.firestore();
    const favoriteRef = db.collection("favorites").doc();
    
    const favorite = {
      name: this.state.name,
      replace: {
        name: this.state.name,
        n: this.state.n,
        p2o5: this.state.p2o5,
        k2o: this.state.k2o,
        s: this.state.s,
        mg: this.state.mg,
      },
      type: "fertilizings",
      company_id: field.company_id,
      group_id: browsingGroup,
      key: favoriteRef.id,
      created: fire.firestore.FieldValue.serverTimestamp(),
    };
    batch.set(favoriteRef, favorite);
  }

  onChange(value) {
    this.setState({
      ...this.state,
      ...value,
    });
  }

  render() {
    const { comment, show, onClose, fertilizings, favorites } = this.props;

    if (!show) {
      return null;
    }

    let forSum = fertilizings;

    if (comment) {
      const index = fertilizings.findIndex(x => x.key === comment.text.key);
      forSum = slice(0, index, fertilizings);
    }

    const sum = commentUtils.getFertilizerSum(forSum);

    return (
      <Dialog
        show={show}
        onClose={onClose}
        title={I18n.t("fertilizer.fertilization")}
        className="fertilizers-form"
        footer={
          <div className="d-flex flex-grow-1">
            <button className="btn btn-secondary ml-auto" onClick={onClose}>
              {I18n.t("cancel")}
            </button>{" "}
            <button
              className="btn btn-primary"
              onClick={this.onSave.bind(this)}>
              {I18n.t("save")}
            </button>
          </div>
        }>
        <div style={{ flex: 1 }}>
          <div style={{ flex: 1 }}>
            <div>
              {favorites
                ? (
                  <div className={"d-flex flex-row justify-content-center"}>
                    <Favorites
                      favorites={favorites}
                      onClick={rowData => {
                        this.setState({
                          name: rowData.replace.name,
                          n: rowData.replace.n,
                          p2o5: rowData.replace.p2o5,
                          k2o: rowData.replace.k2o,
                          s: rowData.replace.s,
                          mg: rowData.replace.mg,
                        });
                      }}
                      simpleContent={false}
                      renderCustomRow={rowData => (
                        <div style={{ flex: 1, flexDirection: "row" }}>
                          <span
                            style={{
                              ...constants.styles.stdSize,
                              ...{ flex: 1 },
                            }}>
                            {rowData.replace.name}
                          </span>
                        </div>
                      )}
                      getCustomRowText={rowData => rowData.replace.name}
                    />
                  </div>
                )
                : null}
              <div
                style={{
                  marginTop: 20,
                  backgroundColor: "#FFF",
                  paddingTop: 10,
                }}>
                <Form
                  ref={this.form}
                  type={FertilizingModel.model}
                  options={() => FertilizingModel.options({
                    ...this,
                    fieldsSelector: () => {
                      return (
                        <FertilizersFieldsSelector
                          selectedFields={this.state.selectedFields}
                          onSave={(fields) => {
                            this.setState({
                              ...this.state,
                              ...{
                                selectedFields: fields,
                                showFieldsSelectorDialog: false,
                              },
                            });
                          }}
                          showDialog={this.state.showFieldsSelectorDialog}
                          onOpen={() => {
                            this.setState({...this.state, showFieldsSelectorDialog: true });
                          }}
                          onClose={() => {
                            this.setState({...this.state, showFieldsSelectorDialog: false });
                          }}
                          disabled={this.state.markedArea.polygon.length}
                          excludedField={this.props.field}
                        />
                      );
                    },
                    markAreaComponent: () => {
                      return (
                        <FertilizerMarkArea 
                          markedArea={this.state.markedArea}
                          field={this.props.field}
                          showDialog={this.state.showMapAreaDialog}
                          onOpen={() => {
                            this.setState({...this.state, showMapAreaDialog: true });
                          }}
                          onClose={() => {
                            this.setState({...this.state, showMapAreaDialog: false });
                          }}
                          onSave={(value) => {
                            this.setState({
                              ...this.state,
                              ...{ markedArea: value, showMapAreaDialog: false}});
                          }}
                          disabled={!!Object.keys(this.state.selectedFields).filter(k => this.state.selectedFields[k]).length}
                        />
                      );
                    },
                    sum,
                  })}
                  value={this.state}
                  onChange={this.onChange.bind(this)}
                />
              </div>

              {this.props.editMode
                ? (
                  <div className="text-center" style={{marginTop: "1em" }}>
                    <DeleteButton
                      buttonText={I18n.t("fertilizer.delete")}
                      alertTitle={I18n.t("fertilizer.delete")}
                      alertMessage={I18n.t("fertilizer.doYouReallyWantToDelete")}
                      onDelete={() => {
                        const { comment } = this.props;
                        const db = firebase.firestore();

                        db.collection("fertilizings")
                          .doc(comment.text.key)
                          .delete();
                        db.collection("comments")
                          .doc(comment.key)
                          .delete();

                        this.props.onClose();
                      }}
                    />
                  </div>
                )
                : null}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          editFertilizing,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const user = state.firebase.profile;
  const comment = ownProps.comment ? ownProps.comment : null;
  const openCompany = selectors.getOpenCompanyId(state);
  const openField = selectors.getOpenFieldId(state);
  const favorites = getFavorites(state, openCompany, "fertilizings");
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const field = searchForFieldAmongTheUniverse(state, openCompany, user.uid, openField);


  // If we have a comment, we are editing
  const crop = comment ? getCropByFieldId(
    state.firestore.data,
    comment.company_id,
    comment.field_id,
    comment.active_crop_uid,
  ) : getCropByFieldId(
    state.firestore.data,
    field.company_id,
    field.key,
    field.activeCrop.key,
  );

  return {
    browsingGroup,
    comment,
    crop,
    editMode: !!selectors.getEditFertilizing(state),
    favorites,
    fertilizings: crop ? getFertilizings(state, crop.key) : [],
    field,
    openCompany,
    profile: state.firebase.profile,
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
  firestoreConnect(props => {
    const { crop, field, browsingGroup } = props;
    const paths: any[] = [];

    if (field) {
      paths.push(getFavoritesQuery(props.openCompany, "fertilizings", browsingGroup));
      paths.push(getCropQuery(field.activeCrop.key));
    }

    if (crop) {
      paths.push(
        getFertilizingsQuery(
          crop.company_id,
          crop.field_id,
          crop.key,
          browsingGroup,
        ),
      );
    }

    return paths;
  }),
  withRouter,
)(FormFertilizing);
