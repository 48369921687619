import React, { useState } from 'react';
import { connect } from 'react-redux';
import ProfileCompletedCheckLine from '../../components/Profile/ProfileCompletedCheckLine';
import { isProfileCompleted } from '../../utils/auth';

interface Props {
  auth: any; // FirebaseAuth
  profile: any; // Profile
  signup: any;
};

const ProfileCompletedCheck = (props: Props) => {
  const { auth, signup } = props;

  if (auth && !isProfileCompleted(signup)) {
    return <ProfileCompletedCheckLine />;
  }

  return null;
};

const selector = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    signup: state.signup,
  };
};

export default connect(
  selector,
  () => ({}),
)(ProfileCompletedCheck as any);
