import * as React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getOpenBrowserPath } from 'farmerjoe-common/lib/selectors/selectors';
import { openBrowserPath } from 'farmerjoe-common/lib/actions/actions';
import withRouter from './withRouter';
import { get } from 'lodash-es';

type Props = {
  history?: Record<string, any>;
  openBrowserPath?: string | null;
  actions?: {
    openBrowserPath: typeof openBrowserPath;
  };
  location?: Record<string, any>;
};

class RouteChangeWatcher extends React.Component<Props> {
  listener: ((...args: Array<any>) => any) | null | undefined;

  componentDidMount() {
    const { history, openBrowserPath, location } = this.props;

    this.listener = history?.listen(location => {
      const oldPath = get(this.props, 'location.pathname');
      const newPath = get(location, 'pathname');
      if (
        oldPath !== newPath &&
        newPath !== this.props.openBrowserPath &&
        /^\/company/.test(newPath)
      ) {
        this.props.actions?.openBrowserPath(newPath);
      }
    });

    const path = get(location, 'pathname');
    if (openBrowserPath && path === '/' && /^\/company/.test(openBrowserPath)) {
      history?.push(openBrowserPath);
    }
  }

  componentWillUnmount() {
    this.listener && this.listener();
  }

  render() {
    return null;
  }
}

export default compose<typeof RouteChangeWatcher>(
  connect(
    (state: any) => ({
      openBrowserPath: getOpenBrowserPath(state),
    }),
    dispatch => ({ actions: bindActionCreators({ openBrowserPath }, dispatch) }),
  ),
  withRouter,
)(RouteChangeWatcher);
