import { get } from 'lodash-es';
import { TableListViewState } from '../flowTypes';

const SETTINGS_KEY_NAME = 'employeesView';

/**
 * Stores the current type of the Employees view in the database.
 * EN-255. To optimize database IO, maybe we could store this setting in the browser?
 */
export function setEmployeesView(companyId, view: TableListViewState) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const db = firebase.firestore();
    const state = getState();
    const profile = state.firebase.profile;

    return db
      .collection('users')
      .doc(profile.uid)
      .set(
        {
          [SETTINGS_KEY_NAME]: {
            ...profile[SETTINGS_KEY_NAME],
            [companyId]: view,
          },
        },
        { merge: true },
      );
  };
}

export function getEmployeesView(state, inputCompanyId): TableListViewState {
  return get(state, `firebase.profile.${SETTINGS_KEY_NAME}.${inputCompanyId}`, 'classic');
}
