import { asyncLoader } from "farmerjoe-common/lib/components/AsyncLoader/AsyncLoader";

const SliderRenderer = asyncLoader(() => import("./Slider"));
const BooleanRenderer = asyncLoader(() => import("./Boolean"));
const TextRenderer = asyncLoader(() => import("./TextRenderer"));
const ImageRenderer = asyncLoader(() => import("./Image"));
const SmileyRatingRenderer = asyncLoader(
  () => import("./SmileyRatingRenderer"),
);
const ColorPickerRenderer = asyncLoader(() => import("./ColorPickerRenderer"));
const StaticImageRenderer = asyncLoader(() => import("./StaticImageRenderer"));
const CropAgeRenderer = asyncLoader(() => import("./CropAgeRenderer"));
const BoniturAgeRenderer = asyncLoader(() => import("./BoniturAgeRenderer"));
const MathCalculationRenderer = asyncLoader(() => import("./MathCalculationRenderer"));
const LabelRenderer = asyncLoader(() => import("./Label"));
const SegmentedControlRenderer = asyncLoader(() => import("./SegmentedControlRenderer"));
const RadioRenderer = asyncLoader(() => import("./RadioRenderer"));
const UniqueIdRenderer = asyncLoader(() => import("./UniqueIdRenderer"));
const FieldSizeRenderer = asyncLoader(() => import("./FieldSizeRenderer"));

export const supportedContentRenderers = {
  "fj-slider": SliderRenderer,
  "fj-boolean": BooleanRenderer,
  "fj-text": TextRenderer,
  "fj-image": ImageRenderer,
  "fj-smiley-face-rating": SmileyRatingRenderer,
  "fj-color-picker": ColorPickerRenderer,
  "fj-static-image": StaticImageRenderer,
  "fj-crop-age": CropAgeRenderer,
  "fj-bonitur-age": BoniturAgeRenderer,
  // NOTE: not needed for new boniturs but required for backward
  // compatibility/existing companies with 'fj-non-animated-input' enabled.
  "fj-non-animated-input": TextRenderer,
  "fj-math": MathCalculationRenderer,
  "fj-math-hectare": MathCalculationRenderer,
  "fj-label": LabelRenderer,
  "fj-amount": TextRenderer,
  "fj-textarea": TextRenderer,
  "fj-segmented-control": SegmentedControlRenderer,
  "fj-radio": RadioRenderer,
  "fj-unique-id": UniqueIdRenderer,
  "fj-field-size": FieldSizeRenderer,
};

export const NonSupportedRenderer = asyncLoader(() => import("./NonSupported"));
