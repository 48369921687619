import * as React from 'react';
import { get, head } from 'lodash-es';
import { FJMUTED } from '../../styles/style';
import type { LatLng } from '../../flowTypes';
import {
  calculateAreaSize,
  getDistanceFromLatitudeLongitudeInM,
} from '../../utils/Map';
import Icon from '../Common/Icon';

type Props = {
  stack: LatLng[];
  ui: {
    isDrawing: boolean;
    drawing: 'polyline' | 'polygon';
  };
  center: null | LatLng;
};

export default class InformationContainer extends React.PureComponent<Props> {
  getPolylineLength(stack: Array<LatLng>) {
    const length = stack
      .map((line, index) => {
        // this works for polyline, but no for polygon...
        if (index === stack.length - 1) {
          return 0;
        }

        const start = line;
        let end = stack[index + 1];

        if (!end) {
          end = head(stack);
        }

        return parseFloat(getDistanceFromLatitudeLongitudeInM(start, end));
      })
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);

    return length;
  }

  render() {
    const { stack, center, ui } = this.props;
    const isDrawing = get(ui, 'isDrawing', false);
    const drawing = get(ui, 'drawing', false);

    // don't render if we don't have what to show
    if (
      !isDrawing ||
      stack.length === 0 ||
      (drawing === 'polygon' && calculateAreaSize(stack).hectare === 0) ||
      (drawing === 'polyline' && this.getPolylineLength(stack) === 0)
    ) {
      return null;
    }

    let content: JSX.Element[] = [];

    // TODO: clean up the code down from here
    if (isDrawing && drawing === 'polyline') {
      content = [
        <Icon
          key="ruler"
          name={'ruler'}
          iconType={'fal'}
          light
          style={{
            fontSize: 12,
            marginRight: 5,
          }}
        />,
      ];

      if (stack.length) {
        const currentMeters = this.getPolylineLength(stack);
        content = [
          ...content,
          <div key="currentMeters">{currentMeters.toFixed(2)} m</div>,
        ];
      } else {
        content = [...content, <div key="currentSize">0 m</div>];
      }
    }

    if (isDrawing && drawing === 'polygon') {
      content = [
        <Icon
          key="draw-polygon"
          name={'draw-polygon'}
          iconType={'fal'}
          light
          style={{
            fontSize: 18,
            marginRight: 5,
          }}
        />,
      ];

      if (stack.length) {
        const currentPolygonSize = calculateAreaSize(stack);
        content = [
          ...content,
          <div key="currentSize">
            {currentPolygonSize.hectare.toFixed(1)} ha
          </div>,
        ];
      } else {
        content = [...content, <div key={'currentSize'}>0 ha</div>];
      }
    }

    return <div className="information-container">{content}</div>;
  }
}
