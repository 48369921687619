import React, { PureComponent } from 'react';

import * as constants from '../../styles/style';
import Icon from '../Common/Icon';
import { appPosToLatLng } from '../../utils/Map';
import MarkerWithLabel from './MarkerWithLabel';
import { Marker as MarkerType } from '../../flowTypes';

const DEFAULT_WIDTH = 40;
const DEFAULT_HEIGHT = 30;

// TODO: improve typings
type Props = {
  marker: MarkerType;
  onClick?: (...args: Array<any>) => any;
  map: google.maps.Map;
};
type State = any;

export default class MarkerCompany extends PureComponent<Props, State> {
  render() {
    const { marker, onClick, map } = this.props;
    return (
      <MarkerWithLabel
        map={map}
        position={appPosToLatLng(marker.position) as any}
        noTriangle={true}
        onClick={onClick}
        width={DEFAULT_WIDTH}
        height={DEFAULT_HEIGHT}
        labelClass="marker-label company-marker">
        <div className="marker-container">
          <div className="marker-bubble">
            <Icon name="barn" iconType={'fj'} style={{ fontSize: 18 }} />
          </div>
          <div style={{ ...styles.arrowBorder }} />
          <div style={{ ...styles.arrow }} />
        </div>
      </MarkerWithLabel>
    );
  }
}

const styles = {
  arrow: {
    backgroundColor: 'transparent',
    borderWidth: 4,
    borderColor: 'transparent',
    borderTopColor: '#FFFFFF',
    alignSelf: 'center',
    marginTop: -1,
    borderStyle: 'solid',
  },
  arrowBorder: {
    backgroundColor: 'transparent',
    borderWidth: 4,
    borderColor: 'transparent',
    borderTopColor: constants.FJBORDERCOLOR,
    alignSelf: 'center',
    marginTop: -0.5,
  },
};
