import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import SegmentedControl from '../../tcomb/templates/SegmentedControl';
import { invert } from 'lodash-es';
import I18n from '../../language/i18n';
import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';

type Props = {
  actions: Record<string, any>;
  openCompany: string;
};

type State = {
  markerTitle: string;
};

class MapMarkerTitles extends React.Component<Props, State> {
  markerTitle: Record<string, any>;

  constructor(props) {
    super(props);

    const {
      filter: { markerTitle },
    } = props;
    this.state = {
      markerTitle: markerTitle || 'ha',
    };

    this.markerTitle = {
      ha: 'ha',
      [I18n.t('crop.age')]: 'cropAge',
      [I18n.t('waittime.waitTimeShort')]: 'waitTime',
      [I18n.t('field.name')]: 'markerTitle',
    };
  }

  render() {
    const options = Object.keys(this.markerTitle);
    const selOptions = invert(this.markerTitle);
    return (
      <SegmentedControl
        className="map-marker-titles"
        options={options}
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        optionContainerStyle={{
          display: 'flex',
          justifyContent: 'center',
          padding: '5px 10px',
          flexDirection: 'column',
          height: 45,
        }}
        onChange={option => {
          const filters: any = {
            markerTitle: this.markerTitle[option],
          };
          if (
            this.markerTitle[option] !== 'cropAge' &&
            this.state.markerTitle === 'cropAge'
          ) {
            filters.cropAgeMin = null;
            filters.cropAgeMax = null;
          }

          this.setState({
            markerTitle: this.markerTitle[option],
          });
          this.props.actions.filters(this.props.openCompany, filters);
        }}
        value={selOptions[this.state.markerTitle]}
        config={{ order: false }}
      />
    );
  }
}

const defaultFilter = {
  markerTitle: 'ha',
};
export default compose(
  connect(
    (state: any) => {
      const openCompany = selectors.getOpenCompanyId(state);
      return {
        openCompany,
        filter: state.filtersByCompany[openCompany]
          ? state.filtersByCompany[openCompany]
          : defaultFilter,
      };
    },
    dispatch => ({
      actions: bindActionCreators(
        {
          filters,
        },
        dispatch,
      ),
    }),
  ),
)(MapMarkerTitles);
