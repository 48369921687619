import * as React from 'react';
import { AnalysisType, Notification } from 'farmerjoe-common/lib/flow/types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import './style.css';
import NotificationCard from './NotificationCard';
import { openAnalysis } from 'farmerjoe-common/lib/actions/analysis';
import I18n from '../../../language/i18n';

type Props = {
  notification: Notification;
  history?: Record<string, any>;
  openCompany?: string;
  actions?: Record<string, any>;
  onClick?: (arg0: Notification) => void;
};

function AnalysisOrder({
  notification,
  history,
  openCompany,
  actions,
  onClick,
}: Props) {
  const {
    analysis_id,
    created_by,
    labOffer,
    fieldName,
    cropName,
  } = notification;
  return (
    <NotificationCard
      onClick={() => {
        actions?.openAnalysis(analysis_id);
        history?.push(`/company/${openCompany}/analysis/${analysis_id}`);
        if (onClick) {
          onClick(notification);
        }
      }}
      title={I18n.t('notifications.analysis_order.title', {
        analysis_type: getAnalysisOrderTranslation(labOffer),
        user_name: created_by.name,
      })}
      body={I18n.t('notifications.analysis_order.body', {
        field_name: fieldName,
        crop_name: cropName,
      })}
      notification={notification}
      userType="user"
    />
  );

  function getAnalysisOrderTranslation(analysisType: AnalysisType) {
    if ([AnalysisType.Soil, AnalysisType.Residue, AnalysisType.Drone].includes(analysisType)) {
      return I18n.t(`notifications.analysis_order.${analysisType}`);
    }
    return 'Unknown';
  }
}

export default compose<typeof AnalysisOrder>(
  withRouter,
  connect(
    (state: any) => {
      return {
        openCompany: getOpenCompanyId(state),
      };
    },
    dispatch => ({ actions: bindActionCreators({ openAnalysis }, dispatch) }),
  ),
)(AnalysisOrder);
