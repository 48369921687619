import { NotACropState, CropState } from 'farmerjoe-common/lib/flow/types';
import * as React from 'react';
import type { Crop } from '../../flowTypes';
import Icon from './Icon';

type Props = {
  crop: Crop;
  defaultIconStyle?: Record<string, any>;
  activeStyle?: Record<string, any>;
  noCropStyle?: Record<string, any>;
  planStyle?: Record<string, any>;
  harvestedStyle?: Record<string, any>;
};

/**
 * Renders the correct icon for a crop based on it's not_a_crop type
 */
export default class CropIcon extends React.PureComponent<Props> {
  render() {
    const {
      crop,
      activeStyle,
      harvestedStyle,
      noCropStyle,
      planStyle,
      defaultIconStyle,
    } = this.props;

    const iconStyle = {
      marginLeft: 0,
      marginRight: 5,
      fontSize: 16,
      color: '#000',
      ...defaultIconStyle,
    };

    let icon: any = null;
    if (
      crop.not_a_crop === NotACropState.HarvestedCrop &&
      (typeof crop.harvested_on === 'undefined' || crop.harvested_on === null)
    ) {
      icon = (
        <Icon
          style={{ ...iconStyle, ...activeStyle }}
          name="leaf"
          iconType={'fj'} /* testID={"plantedCropIcon"} */
        />
      );
    } else if (crop.harvested_on) {
      icon = (
        <Icon
          style={{ ...iconStyle, ...harvestedStyle }}
          name="md-checkmark-circle-outline" /* testID={"harvestedCropIcon"} */
        />
      );
    } else if (crop.not_a_crop === NotACropState.NotACrop) {
      icon = <div style={{ ...{ width: 19 }, ...noCropStyle }}></div>;
    } else if (crop.not_a_crop === NotACropState.PlannedCrop) {
      icon = (
        <Icon
          iconType={'fj'}
          style={{ ...iconStyle, ...planStyle }}
          name="time" /* testID={"plannedCropIcon"} */
        />
      );
    }

    return icon;
  }
}
