import React from "react";
import { cloneDeep } from "lodash-es";
import t from "tcomb-form";

import stylesheet from "../stylesheets/style";
import ModalDate from "../templates/ModalDate";
import Favorite from "../templates/Favorite";
import FormInput from "../templates/FormInput";
import { defaultDateFormat } from "../utils/options";
import { dateTransformer } from "../transformers/transformers";
import { latitude, longitude } from "../validators/coordinates";
import Expandable from "../../components/Common/Expandable";
import Sprayer from "../templates/Sprayer";

import I18n from "../../language/i18n";
import * as constants from "../../styles/style";

const tPosition = t.struct({
  latitude: latitude,
  longitude: longitude,
});

// Our waittime model
const model = t.struct({
  template: t.String,
  wait_time: t.maybe(t.Number),
  applied_on: t.Date,
  favorite: t.Boolean,
  notes: t.maybe(t.String),
  sprayer: t.maybe(t.String),
  advancedSettings: t.maybe(t.struct({
    applyToFields: t.maybe(t.list(t.String)),
    markedArea: t.maybe(t.list(tPosition)),
  })),
});

const formGroupOuter = cloneDeep(stylesheet);
formGroupOuter.formGroup.normal.borderBottomWidth = 0;

function template(locals, defaults) {
  return (
    <fieldset>
      <div className="d-flex">
        {locals.inputs.template}
        {locals.inputs.favorite}
      </div>
      {locals.inputs.wait_time}
      {locals.inputs.notes}
      {locals.inputs.sprayer}
      {locals.inputs.applied_on}
      {locals.inputs.advancedSettings}
    </fieldset>
  );
}

const options = function(defaults) {
  const multiline = cloneDeep(stylesheet);
  multiline.textbox.normal = {
    ...multiline.textbox.normal,
    height: "auto",
  };

  const { fieldsSelector, markAreaComponent, sprayers } = defaults;

  return {
    stylesheet: stylesheet,
    auto: "none",
    template: locals => template(locals, defaults),
    fields: {
      applied_on: {
        label: I18n.t("waittime.application_day"),
        transformer: dateTransformer,
        maximumDate: new Date(),
        config: {
          hideBorderTop: false,
          format: date => defaultDateFormat(date),
          mode: "date",
        },
        stylesheet: formGroupOuter,
        template: ModalDate,
      },
      template: {
        stylesheet: multiline,
        factory: FormInput,
        placeholderTextColor: constants.FJLABEL,
        label: `${I18n.t("waittime.pesticide")} *`,
        onContentSizeChange: () => null,
        config: {
          multiline: true,
        },
      },
      wait_time: {
        label: `${I18n.t("waittime.waiting_period_in_days")}`,
        placeholderTextColor: constants.FJLABEL,
        factory: FormInput,
      },
      favorite: {
        config: {
          label: `${I18n.t("save_as_favorite")}`,
        },
        template: Favorite,
      },
      notes: {
        label: I18n.t("notes"),
        placeholder: I18n.t("notes"),
        stylesheet: multiline,
        textAlignVertical: "bottom",
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
        factory: FormInput,
        config: {
          multiline: true,
        },
      },
      sprayer: {
        label: I18n.t("waittime.sprayer"),
        placeholder: I18n.t("waittime.sprayer"),
        stylesheet: multiline,
        textAlignVertical: "bottom",
        placeholderTextColor: constants.FJLABEL,
        autoCorrect: false,
        factory: Sprayer,
        config: {
          multiline: true,
          options: sprayers,
        },
      },
      advancedSettings: {
        label: I18n.t("advancedSettings"),
        template: locals => templateAdvancedSettings(locals, fieldsSelector, markAreaComponent),
      },
    },
  };
};

const templateAdvancedSettings = (locals, fieldsSelector, markAreaComponent) => {
  return (
    <div>
      <Expandable label={locals.label}>
        {fieldsSelector()}
        {markAreaComponent()}
      </Expandable>
    </div>
  );
};

export default { model, options };
