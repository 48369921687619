import * as React from 'react';

import I18n from '../../language/i18n';
import Select from '../../components/Common/Select';

import { get, find } from 'lodash-es';
import { styles } from '../../styles/style';

export function ReactSelect(locals) {
  let value = locals.value;
  let errorStyle = {};

  if (locals.hasError) {
    errorStyle = styles.danger;
  }

  if (locals.config.hidden) {
    return null;
  }

  let label = <span>{' '}</span>;
  if (locals.label) {
    label = <span style={errorStyle}>{locals.label}</span>;
  }

  const options = get(locals, 'options', []).map(option => {
    return { label: option.text, value: option.value };
  });

  const placeholder = get(
    locals,
    'config.placeholder',
    I18n.t('selectPlaceholderDropdown'),
  );
  const filterOption = get(locals, 'config.filterOption', false);

  if (value) {
    value = find(options, ['value', value]);
  }

  return (
    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 5 }}>
      {label}
      <Select
        onChange={value => {
          locals.onChange(value ? value.value : null);
        }}
        searchable={true}
        // autoload={false}
        labelKey="label"
        valuekey="value"
        isClearable={true}
        placeholder={placeholder}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        value={value}
        filterOption={filterOption}
        options={options}
        // menuPortalTarget={document.getElementById('react-select-portal')}
        menuPlacement={'auto'}
      />
    </div>
  );
}
