import { SIGNUP_STATUS_DONE } from 'farmerjoe-common/lib/constants/signup';
import { CREDENTIALS_PROVIDER_EMAIL } from 'farmerjoe-common/lib/constants/credentialsProviders';
import firebase from '../data/firebase';

export function isEmailVerified(email: string, emailVerified: boolean): boolean {
  return !!email && emailVerified;
}

export function isProfileCompleted(signup: any) : boolean {
  return signup.status === SIGNUP_STATUS_DONE;
};

export function isEmailCredentials(credentialsProvider: string | undefined): boolean {
  return credentialsProvider === CREDENTIALS_PROVIDER_EMAIL;
}

export async function getUidToken(): Promise<string> {
  const user = firebase.auth().currentUser;
  if (!user) {
    return '';
  }
  const uidToken = await user.getIdToken(true);
  return uidToken;
};
