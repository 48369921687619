import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import firebase from '../../data/firebase';
import LoadUserPermissions from '../../containers/Permissions/UserPermissions';
import RouteChangeWatcher from './RouteChangeWatcher';
import ActivationEmail from '../../containers/Activation/ActivationEmail';

// TODO: improve typings
type Props = any;
type State = any;

export default class PrivateRoute extends Component<Props, State> {
  render() {
    const { component: Component, ...rest } = this.props;
    const user = firebase.auth().currentUser;

    return (
      <Route
        {...rest}
        render={props =>
          user
            ? (
              <LoadUserPermissions user={user}>
                <ActivationEmail />
                <Component {...props} />
                <RouteChangeWatcher />
              </LoadUserPermissions>
            )
            : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
        }
      />
    );
  }
}
