import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { get, debounce } from "lodash-es";
import moment from "moment";
import sanitizeFilename from "sanitize-filename";

import * as companySelectors from "farmerjoe-common/lib/selectors/companies";
import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import { FieldState } from "farmerjoe-common/lib/flow/types";
import { filters } from "farmerjoe-common/lib/actions/actions";
import { getCompanyGroupProfileForLoggedInUser, getFieldTableStateForCompany } from "farmerjoe-common/lib/selectors/user";
import { getFeature } from "farmerjoe-common/lib/selectors/features";
import { getFilteredActiveFields } from "farmerjoe-common/lib/selectors/fields";
import { getFormSchemas } from "farmerjoe-common/lib/selectors/forms";
import { getFormSchemasQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Forms";
import { getGroupsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Groups";
import { hasLoaded } from "farmerjoe-common/lib/selectors/loading";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import { orderWaitTimesByFieldId } from "farmerjoe-common/lib/selectors/waittimes";
import { setFieldsTab } from "farmerjoe-common/lib/actions/ui";
import { updatePosition } from "farmerjoe-common/lib/actions/position";

import HarvestedTable from "./HarvestedTable";
import TabBar from "./TabBar";
import Table from "./Table";
import TableColumnConfigurator from "./TableColumnConfigurator";
import NoFields from "../NoFields";
import "../style.css";
import NoResultsResetFilter from "../NoResultsResetFilter";
import { canSeeHarvested } from "../utils";
import CreateField from "../../Field/CreateField";
import ScrollableTabView from "../../Common/ScrollableTabView";
import withRouter from "../../Router/withRouter";
import * as constants from "../../../styles/style";
import fieldsContainer from "../../../containers/Fields";
import { saveFieldTableState } from "../../../actions/fieldTable";
import { clearWatch, watchPosition } from "../../../utils/geolocation";
import { getFieldPath } from "../../../utils/page";
import I18n from "../../../language/i18n";

import useFieldCollaborators from "../../../hooks/useFieldCollaboratos";

type Props = {
  company?: any;
  fieldTableState?: any;
  myCompanyProfile?: any;
  filter?: any;
  isMapPage?: any;
  actions?: any;
  fields?: any;
  loading?: any;
  waitTimes?: any;
  openFieldId?: any;
  locationPermission?: any;
  userPosition?: any;
  formSchemas?: any;
  history?: any;
  currentFieldsTab: string;
  openCompany: string;
  search: string;
  fieldsCollaborators: string[];
};

const COLUMN_SHARED_WITH = "sharedWith";

const FieldTable = (props: Props) => {
  const { myCompanyProfile, isMapPage, currentFieldsTab, openCompany } = props;
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showColumnConfigurator, setShowColumnConfigurator] = useState(false);
  const [search, setSearch] = useState(props.search);

  // XXX: since the table rerenders a lot, thus triggering the hook to fetch
  // collaborators, we can limit it by checking if the column `sharedWith` is
  // being enabled.
  const loadFieldCollaborators = get(props, "fieldTableState.current.columnIds", []).includes(COLUMN_SHARED_WITH);
  const fieldsCollaborators = useFieldCollaborators(props.fieldsCollaborators, loadFieldCollaborators);

  const updateSearch = debounce((text: string) => {
    setSearch(text);
    props.actions.filters(openCompany, {
      search: text,
    });
  }, 500);

  const currentTableRef = useRef<Table>(null);
  const harvestedTableRef = useRef<Table>(null);

  const styles = constants.styles;

  const tableKey = isMapPage
    ? currentFieldsTab === "active"
      ? "current-map"
      : "harvested-map"
    : currentFieldsTab === "active"
      ? "current"
      : "harvested";

  useEffect(() => {
    const updateLocation = (location) => {
      props.actions.updatePosition({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      });
    };

    const watcherId = watchPosition(
      updateLocation,
      (e) => {
        console.error("An location error occurred: ", e);
      },
      {
        timeout: 10000,
        maximumAge: 5000,
      },
    );
    return () => {
      clearWatch(watcherId);
    };
  }, [props.actions]);


  const renderTabBar = () => {
    const { company } = props;
    let showNew = currentFieldsTab === "active";

    // Only admins can add new fields for now
    if (!isAdmin(myCompanyProfile)) {
      showNew = false;
    }
    return (
      <TabBar
        showNew={showNew}
        onShowCreateForm={() => setShowCreateForm(true)}
        search={search}
        company={company}
        onSearch={(text: string) => {
          updateSearch(text);
        }}
        onColumnConfigClick={() =>
          setShowColumnConfigurator(true)
        }
        tab={currentFieldsTab}
        isMapPage={isMapPage}
        onExportClick={() => {
          if (currentFieldsTab === "active") {
            currentTableRef.current?.exportData();
          } else {
            harvestedTableRef.current?.exportData();
          }
        }}
      />
    );
  };

  const renderFields = () => {
    const {
      company,
      fields,
      loading,
      waitTimes,
      openFieldId,
      locationPermission,
      userPosition,
      formSchemas,
    } = props;

    const _tableKey = isMapPage ? "current-map" : "current";

    return (
      <Table
        ref={currentTableRef}
        tabLabel={I18n.t("current")}
        isActiveTab={currentFieldsTab === "active"}
        fields={loading ? null : fields}
        waitTimes={waitTimes}
        loading={loading}
        onClick={onFieldClick}
        onTableStateChange={(state) => {
          isMapPage
            ? onTableStateChange("current-map", state)
            : onTableStateChange("current", state);
        }}
        openFieldId={openFieldId}
        locationPermission={locationPermission}
        userPosition={userPosition}
        isAdmin={isAdmin(myCompanyProfile)}
        emptyView={emptyView(company, props.filter)}
        fieldTableState={props.fieldTableState[tableKey]}
        tableKey={_tableKey}
        formSchemas={formSchemas}
        fieldsCollaborators={fieldsCollaborators}
        exportFileName={`farmerjoe-${sanitizeFilename(
          I18n.t("current"),
        )}-${moment().format("DD.MM.YYYY")}`}
      />
    );
  };

  const emptyView = (company, filter) => {
    const showCrops = get(filter, "showCrops");
    const search = get(filter, "search");
    return search || (showCrops && showCrops.length !== 3)
      ? (
        <NoResultsResetFilter />
      )
      : (
        <NoFields showCreate={true} company={company} />
      );
  };

  const onFieldClick = (key, cropKey) => {
    props.actions.openField(key, cropKey);
    props.history.push(getFieldPath(openCompany, key));
  };

  const onTableStateChange = (prop, state) => {
    props.actions.saveFieldTableState(openCompany, {
      ...props.fieldTableState,
      [prop]: state,
    });
  };

  return (
    <div className="field-table">
      <div style={styles.containerColumn}>
        <ScrollableTabView
          onChangeTab={index => {
            props.actions.setFieldsTab(openCompany, index.i === 0 ? "active" : "harvested");
          }}
          renderTabBar={renderTabBar}
        >
          {renderFields()}

          {!isMapPage && canSeeHarvested(myCompanyProfile) ? (
            <HarvestedTable
              ref={harvestedTableRef}
              tabLabel={I18n.t("harvested")}
              isActiveTab={currentFieldsTab === "harvested"}
              fieldTableState={props.fieldTableState[tableKey]}
              onTableStateChange={(state) => onTableStateChange("harvested", state)}
            />
          )
            : null}
        </ScrollableTabView>
      </div>

      {showCreateForm
        ? (
          <CreateField
            show={showCreateForm}
            onClose={() => setShowCreateForm(false)}
          />
        )
        : null}
      {showColumnConfigurator
        ? (
          <TableColumnConfigurator
            show={showColumnConfigurator}
            onClose={() => setShowColumnConfigurator(false)}
            tableKey={tableKey}
          />
        )
        : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
          saveFieldTableState,
          setFieldsTab,
          updatePosition,
        },
      ),
      dispatch,
    ),
  };
};

const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);
  const withProducers = getFeature(state, openCompany, "producers") === true;
  const fields = getFilteredActiveFields(
    state,
    openCompany,
    state.firebase.auth.uid,
    { showCrops: true, cropAge: ownProps.isMapPage },
    withProducers,
  );

  const company = companySelectors.getCompany(state.firestore.data, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const boniturOn = getFeature(state, openCompany, "bonitur") === true;
  const formSchemas = getFormSchemas(state);

  const currentFieldsTab = get(state, `currentFieldsTab.${openCompany}`, "active");

  const queryPaths: any = [];
  if (boniturOn) {
    queryPaths.push(getFormSchemasQuery(openCompany));
  }
  if (withProducers) {
    queryPaths.push(getGroupsQuery(openCompany, myCompanyProfile));
  }

  const searchWord = get(
    state,
    ["filtersByCompany", openCompany, "search"],
    "",
  );

  const fieldsCollaborators = fields instanceof Array ? new Set(fields.filter(f => f.collaborators).map(f => f.collaborators).reduce((curr, val) => [...curr, ...val], [])) : [];

  return {
    company: company,
    myCompanyProfile: myCompanyProfile,
    fields: fields || [],
    // @ts-ignore
    waitTimes: orderWaitTimesByFieldId(state, openCompany),
    openFieldId: state.openFieldId,

    filter: state.filtersByCompany[openCompany] || null,
    loading: ownProps.loading || !hasLoaded(queryPaths, state),
    fieldTableState: getFieldTableStateForCompany(state, openCompany),
    userPosition: state.userPosition,
    locationPermission: state.locationPermission,
    boniturOn,
    formSchemas,
    currentFieldsTab,
    openCompany,
    fieldState: FieldState.Active,
    withProducers,
    search: searchWord,
    fieldsCollaborators,
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
  fieldsContainer,
  firestoreConnect(({ openCompany, boniturOn, withProducers, myCompanyProfile }) => {
    const paths: any[] = [];
    if (boniturOn) {
      paths.push(getFormSchemasQuery(openCompany));
    }

    if (withProducers) {
      paths.push(getGroupsQuery(openCompany, myCompanyProfile));
    }
    return paths;
  }),
  withRouter,
)(FieldTable);
