import React from 'react';

import Icon from '../../components/Common/Icon';
import { numberTransformer } from '../transformers/transformers';

import t from 'tcomb-form';
import FormInput from './FormInput';

const Component = t.form.Component;

class TextBoxWithAddMinusButtons extends Component {
  getTemplate() {
    return locals => {
      let value = '';

      if (locals.value) {
        value = locals.value;
      }

      return (
        <div className="textbox-plus-minus">
          <FormInput
            {...this.props}
            options={{
              ...this.props.options,
              label: locals.label,
              keyboardType: 'numeric',

              transformer: numberTransformer,
              hasError: locals.hasError,
            }}
            ref="input"
            value={value}
          />
          <div className="buttons">
            <div
              className="minus"
              onClick={() => {
                const less = parseInt(value || '0', 10) - 10;
                locals.onChange(String(less < 0 ? 0 : less));
              }}>
              <Icon name={'ios-remove-outline'} />
            </div>
            <div
              className="plus"
              onClick={() => {
                locals.onChange(String(parseInt(value || '0', 10) + 10));
              }}>
              <Icon name={'ios-add-outline'} />
            </div>
          </div>
        </div>
      );
    };
  }
}

export default TextBoxWithAddMinusButtons;
