import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { getOpenCompanyId } from 'farmerjoe-common/lib/selectors/selectors';
import { getActiveFields } from 'farmerjoe-common/lib/selectors/fields';

import IconHeader from '../Common/IconHeader';
import I18n from '../../language/i18n';
import * as constants from '../../styles/style';
import './style.css';
import { FjIconType } from '../Common/Icon';
import { NotACropState } from 'farmerjoe-common/lib/flow/types';

interface Props {
  sectionId?: any;
  fields?: any;
  expanded?: any;
  onClick?: (sectionId: any) => any;
}

interface IconStyle {
  color: string;
  marginRight: number;
  fontSize: number;
}

interface SectionHeader {
  iconName: string;
  iconType: FjIconType;
  iconStyle: IconStyle;
  text: string;
  className?: string;
}

const getDefaultIconStyle = (): IconStyle => {
  const iconStyle: IconStyle = {
    color: constants.FJMUTEDLIGHTER,
    marginRight: 10,
    fontSize: 12,
  };
  return iconStyle;
};

const getSectionHeaderForType = (sectionId: string, sectionExpanded: boolean): SectionHeader => {
  const iconStyle = getDefaultIconStyle();
  const defaultSectionHeader: SectionHeader = {
    iconName: sectionExpanded ? 'arrow_down' : 'arrow_up',
    iconType: 'fj',
    iconStyle,
    text: I18n.t('crop.active'),
  };

  switch (sectionId) {
    case 'crop':
      return defaultSectionHeader;
    case 'planned':
      return { ...defaultSectionHeader, text: I18n.t('crop.planned'), className: 'justify-content-start' };
    case 'noCrop':
      return { ...defaultSectionHeader, text: I18n.t('crop.without') };
    default:
      throw new Error(`Unable to get header for header type: ${sectionId}`);
  }
};

const getSectionHeader = (sectionId: string, sectionExpanded: boolean) => {
  const headerForType: SectionHeader = getSectionHeaderForType(sectionId, sectionExpanded);
  return <IconHeader {...headerForType} />;
};

const getHektarForSection = (sectionId: string, fields: Array<any>) => {
  return getFilteredFields(sectionId, fields)
    .reduce((acc, val) => {
      return acc + (val.size || 0);
    }, 0)
    .toFixed(2);
};

const getFilteredFields = (sectionId: string, fields: Array<any>) => {
  switch (sectionId) {
    case 'noCrop':
      return fields
        .filter(field => field.activeCrop.not_a_crop === NotACropState.NotACrop);
    case 'crop':
      return fields
        .filter(field => field.activeCrop.not_a_crop === NotACropState.HarvestedCrop);
    case 'planned':
      return fields
        .filter(field => field.activeCrop.not_a_crop === NotACropState.PlannedCrop);
    default:
      return [];
  }
};
class FieldsListSectionHeader extends React.PureComponent<Props> {
  render() {
    const { sectionId, fields } = this.props;
    const sectionExpanded = this.props.expanded;
    const ha = getHektarForSection(sectionId, fields);
    const sectionHeader = getSectionHeader(sectionId, sectionExpanded);
    return (
      <div
        className="fields-list-section-header"
        onClick={() => {
          if (this.props.onClick) this.props.onClick(sectionId);
        }}>
        <div className="uncultivated-section-header">
          <div>{sectionHeader}</div>
          <div className="area">
            <span>{ha} ha</span>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<typeof FieldsListSectionHeader>(
  connect((state: any) => {
    const openCompany = getOpenCompanyId(state);
      // @ts-ignore
    const fields = getActiveFields(state, openCompany, state.firebase.auth.uid);

    return {
      fields: fields || [],
    };
  }),
)(FieldsListSectionHeader);
