import * as React from 'react';
import { filter, get, isEqual, isObject, without } from 'lodash-es';
import { classes } from '../../utils/dom';
import './style.css';

type Props = {
  children?: React.ReactNode;
  onChangeTab?: (arg0: Record<string, any>) => void;
  renderTabBar?: () => React.ReactElement<any>;
  className?: string;
  tabBarPosition?: string;
};

type TabType =
  | string
  | {
      text: string;
      icon: string;
      iconType: string;
      labelKey: string;
      render?: () => React.ReactNode;
    };

type State = {
  tabs: Array<TabType>;
  currentTab: string | null;
  tabWasRendered: Record<string, any>;
};

type TabProps = {
  name: string;
  pageIndex: number;
  isActive: boolean;
  onClick: (arg0: string, arg1: number) => void;
  render?: () => React.ReactNode;
};

export default class ScrollableTabView extends React.Component<Props, State> {
  state = {
    tabs: [],
    currentTab: null,
    tabWasRendered: {},
  };

  componentWillMount() {
    const tabs = this.indexScenes();
    const activeTab = this.getActiveTab();
    const currentTab = activeTab ? getTabName(getTabLabel(activeTab)) : (tabs.length > 0 ? getTabName(tabs[0]) : null);
    this.setState({
      tabs,
      currentTab,
    });
  }

  componentWillUpdate(newProps: Props, newState: State) {
    if (!isEqual(this.props.children, newProps.children)) {
      const currentTab = newState.currentTab;
      const tabs = this.indexScenes(newProps.children);
      const activeTab = this.getActiveTab(newProps.children);
      const newCurrentTab = currentTab && filter(tabs, tab => getTabName(tab) === currentTab).length ? currentTab : tabs.length > 0 ? getTabName(tabs[0]) : null;

      const nextCurrentTab = getTabName(getTabLabel(activeTab)) || newCurrentTab;

      this.setState({
        tabs,
        currentTab: nextCurrentTab,
        tabWasRendered: {
          ...newState.tabWasRendered,
          ...(nextCurrentTab ? { [nextCurrentTab]: true } : null),
        },
      });
    }
  }

  indexScenes(children: React.ReactNode = this.props.children) {
    return this.getChildren(children).map(c =>
      c !== null ? getTabLabel(c) : null,
    );
  }

  getChildren(children: React.ReactNode = this.props.children) {
    return without(React.Children.toArray(children), null);
  }

  getActiveTab(children: React.ReactNode = this.props.children) {
    return this.getChildren(children).find(c => c.props.isActiveTab);
  }

  goToPage(name: string, pageIndex: number) {
    const { onChangeTab } = this.props;

    this.setState({
      currentTab: name,
    });
    onChangeTab && onChangeTab({ name, i: pageIndex });
  }

  render() {
    const { renderTabBar, className } = this.props;

    return [
      renderTabBar
        ? (
            React.cloneElement(renderTabBar(), {
              tabs: this.state.tabs,
              goToPage: this.goToPage.bind(this),
              currentTab: this.state.currentTab,
              key: -1.1,
            })
          )
        : (
          <DefaultTabBar
            tabs={this.state.tabs}
            goToPage={this.goToPage.bind(this)}
            currentTab={this.state.currentTab}
            className={className}
            key={-1.2}
          />
        ),
      ...this.getChildren().map((c, i) => {
        const tabName = getTabName(getTabLabel(c));
        const isActive = (this.state.currentTab === tabName) || c.props.isActiveTab;
        return isActive || this.state.tabWasRendered[tabName]
          ? (
          <div
            className={classes('scrollable-tab', isActive && 'active')}
            key={tabName}>
            {c}
          </div>
            )
          : null;
      }),
    ];
  }
}

export class DefaultTabBar extends React.Component<{
  currentTab?: string | null;
  goToPage?: (arg0: string, arg1: number) => void;
  renderTab?: (arg0: TabProps) => React.ReactNode;
  tabs?: Array<TabType>;
  className?: string | null;
  style?: Record<string, any> | null;
}> {
  renderTab({ name, pageIndex, isActive, onClick, render }: TabProps) {
    return (
      <Tab
        name={name}
        pageIndex={pageIndex}
        isActive={isActive}
        onClick={onClick}
        render={render}
      />
    );
  }

  render() {
    const currentTab = this.props.currentTab;
    return (
      <ul
        className={classes(
          'nav nav-tabs tab-view-list customtab',
          this.props.className,
        )}
        style={this.props.style as any}>
        {this.props.tabs?.map((tab, pageIndex) => {
          const name = getTabName(tab);
          const currentTabName = getTabName(currentTab);
          const isActive = currentTabName === name;
          const renderTab = this.props.renderTab || this.renderTab;
          return (
            <li className="nav-item" key={pageIndex}>
              {renderTab.call(this, {
                name,
                pageIndex,
                isActive,
                onClick: this.props.goToPage as any,
                render: (tab as any).render || null,
              })}
            </li>
          );
        })}
      </ul>
    );
  }
}

export function Tab({ name, pageIndex, isActive, onClick, render }: TabProps) {
  return (
    <a
      key={pageIndex}
      className={classes('nav-link show', isActive && 'active')}
      onClick={() => onClick(name, pageIndex)}>
      <span>{render ? render() : name}</span>
      <span className="width-spacer">{render ? render() : name}</span>
    </a>
  );
}

function getTabLabel(c) {
  return get(c, 'props.tabLabel');
}

function getTabName(tab) {
  return isObject(tab) ? tab.text : tab;
}
