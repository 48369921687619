import * as React from 'react';

import CountryList from './CountryList';
import './style.css';
import { detectCountry, findCountryByCCA2 } from '../../../utils/Countries';

type Props = {
  value: null | string;
  onChange: (...args: Array<any>) => any;
  editable: boolean;
  hasError: boolean;
  style?: Record<string, any>;
  countryListStyle?: Record<string, any>;
  forwardedRef?: React.Ref<CountryInput>;
};

type State = {
  selected: string | null;
};

class CountryInput extends React.PureComponent<Props, State> {
  resetValue = false;

  constructor(props: Props, context?) {
    super(props, context);
    const country = this.valueToCountry();
    let selected: any = null;

    if (country) {
      selected = country.cca2;
    }
    this.state = {
      selected,
    };
  }

  componentDidMount() {
    if (this.resetValue) {
      // if the user entered the country manually and we can't find it in world-countries
      // then reset the value to force them to change it
      this.props.onChange({ value: null });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      const country = this.valueToCountry();
      if (country) {
        this.setState({ selected: country.cca2 });
      }
    }
  }

  render() {
    const { editable, hasError, style, countryListStyle } = this.props;
    const { selected } = this.state;
    const editableValue = typeof editable !== 'undefined' ? editable : true;

    return (
      <div style={{ flex: 1, ...style }} className="country-input">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <CountryList
            ref={this.props.forwardedRef}
            editable={editableValue}
            hasError={hasError}
            cca2={selected}
            onChange={item => {
              this.setState({ selected: item.cca2 });
              this.props.onChange({ value: item.cca2 });
            }}
            style={{ flex: 1, marginBottom: 20, ...countryListStyle }}
          />
        </div>
      </div>
    );
  }

  valueToCountry(value = this.props.value) {
    let country: any = null;
    if (value) {
      // new values are country codes
      country = findCountryByCCA2(value);
    }
    if (!country && value) {
      // old values are whatever the user entered manually, try to find it by name
      country = detectCountry('name', value);
      if (!country) {
        this.resetValue = true;
      }
    }
    return country;
  }
}

// $FlowFixMe
export default React.forwardRef<CountryInput, Props>(function(props: Props, ref) {
  return <CountryInput {...props} forwardedRef={ref} />;
});
