import * as React from 'react';
import { get } from 'lodash-es';
import './style.css';
import { formatSum } from 'farmerjoe-common/lib/utils/Fertilizing';

type Props = {
  fertilizing?: {};
  sum: null | {};
  displayHeader?: boolean;
  displayFertilizing?: boolean;
  onlyTextRowSum?: boolean;
};
class Fertilizing extends React.PureComponent<Props> {
  static defaultProps = {
    displayHeader: true,
    displayFertilizing: true,
    onlyTextRowSum: false,
  };

  renderTextRowSum() {
    const { sum } = this.props;

    // tabs are for csv export
    return (
      <div className={'fertilizing-single-row-sum'}>
        <span className="">&sum;</span>
        {'\t'}
        <span className="">{formatSum(get(sum, 'n.kg', 0))}</span>
        {'\t'}
        <span className="">{formatSum(get(sum, 'p2o5.kg', 0))}</span>
        {'\t'}
        <span className="">{formatSum(get(sum, 'k2o.kg', 0))}</span>
        {'\t'}
        <span className="">{formatSum(get(sum, 's.kg', 0))}</span>
        {'\t'}
        <span className="">{formatSum(get(sum, 'mg.kg', 0))}</span>
        {'\t'}
        <span className="">kg/ha</span>
      </div>
    );
  }

  render() {
    const {
      fertilizing,
      sum,
      displayHeader,
      displayFertilizing,
      onlyTextRowSum,
    } = this.props;

    if (onlyTextRowSum) {
      return this.renderTextRowSum();
    }

    return (
      <div className="table-container">
        <div className="table">
          <div className="f-column">
            {displayHeader && <span className="f-header"> </span>}
            {displayFertilizing && <span className="f-header">+</span>}
            <div className="f-footer">
              <span className="f-content">&sum;</span>
            </div>
          </div>

          <div className="f-column">
            {displayHeader && <span className="f-header">N</span>}
            {displayFertilizing && (
              <span className="f-content">
                {formatSum(get(fertilizing, 'n.kg', 0))}
              </span>
            )}

            <div className="f-footer">
              <span className="f-content">
                {formatSum(get(sum, 'n.kg', 0))}
              </span>
            </div>
          </div>
          <div className="f-column">
            {displayHeader && <span className="f-header">P₂O₅</span>}
            {displayFertilizing && (
              <span className="f-content">
                {formatSum(get(fertilizing, 'p2o5.kg', 0))}
              </span>
            )}
            <div className="f-footer">
              <span className="f-content">
                {formatSum(get(sum, 'p2o5.kg', 0))}
              </span>
            </div>
          </div>
          <div className="f-column">
            {displayHeader && <span className="f-header">K₂O</span>}
            {displayFertilizing && (
              <span className="f-content">
                {formatSum(get(fertilizing, 'k2o.kg'))}
              </span>
            )}
            <div className="f-footer">
              <span className="f-content">
                {formatSum(get(sum, 'k2o.kg', 0))}
              </span>
            </div>
          </div>
          <div className="f-column">
            {displayHeader && <span className="f-header">S</span>}
            {displayFertilizing && (
              <span className="f-content">
                {formatSum(get(fertilizing, 's.kg', 0))}
              </span>
            )}
            <div className="f-footer">
              <span className="f-content">
                {formatSum(get(sum, 's.kg', 0))}
              </span>
            </div>
          </div>
          <div className="f-column">
            {displayHeader && <span className="f-header">Mg</span>}
            {displayFertilizing && (
              <span className="f-content">
                {formatSum(get(fertilizing, 'mg.kg', 0))}
              </span>
            )}
            <div className="f-footer">
              <span className="f-content">
                {formatSum(get(sum, 'mg.kg', 0))}
              </span>
            </div>
          </div>
          <div className="f-column">
            {displayHeader && <span className="f-content"> </span>}
            {displayFertilizing && <span className="f-content">kg/ha</span>}
            <div className="f-footer">
              <span className="f-content">kg/ha</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Fertilizing;
