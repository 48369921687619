import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from '../Common/Icon';
import I18n from '../../language/i18n';
import { formatCommentDate, toDate } from 'farmerjoe-common';
import './style.css';
import { classes } from '../../utils/dom';

// TODO: improve typings
type Props = any;
type State = any;

/**
 * Displays the wait time widget
 *
 * If we still have to wait then, we display the days in red
 *
 * If we are past the wait time period, then the days are in green with a minus sign in front of them
 *
 * If we are past 10 days the wait period, then we don't show anything
 */
export default class Waittime extends PureComponent<Props, State> {
  static propTypes = {
    waitTimes: PropTypes.array.isRequired,
    showDates: PropTypes.bool,
  };

  static getMaxWaitTime(waitTimes) {
    if (waitTimes.length >= 1) {
      return waitTimes.reduce(function(pre, cur) {
        if (moment(toDate(pre.ends_on)).isBefore(toDate(cur.ends_on))) {
          return cur;
        }

        return pre;
      });
    }

    return false;
  }

  /**
   * Calculates the wait time
   *
   * @param appliedOn
   * @param waitTime
   * @returns integer - positive when we still have to wait, negative when the wait time has expired
   */
  static calculateRestTime(waitTime) {
    // Mutate the date to beginning of the day. Otherwise we get wrong results
    const today = moment().startOf('day');

    return -today.diff(moment(toDate(waitTime.ends_on)), 'days');
  }

  render() {
    const waitTime = Waittime.getMaxWaitTime(this.props.waitTimes);
    const showDates = this.props.showDates;

    if (!waitTime) {
      return null;
    }

    const restTime = Waittime.calculateRestTime(waitTime);
    let hourglass = 'hourglass_red';

    if (restTime <= 0) {
      hourglass = 'hourglass_green';
    }

    // tabs are for csv export
    return (
      <div className={classes('waittime', restTime <= 0 && 'done-waiting')}>
        <Icon name={hourglass} iconType={'fj'} style={this.props.iconStyle} />
        <span className="days-left" style={this.props.style}>
          {I18n.t('x_days', { days: restTime })}
        </span>
        {'\t'}
        {showDates
          ? (
          <React.Fragment>
            <span className="applied-on" style={this.props.dateStyle}>
              {formatCommentDate(toDate(waitTime.applied_on))}
            </span>
            {'\t'}
            <span className="ends-on" style={this.props.dateStyle}>
              {formatCommentDate(toDate(waitTime.ends_on))}
            </span>
          </React.Fragment>
            )
          : null}
      </div>
    );
  }
}
