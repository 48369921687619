import * as React from 'react';
import Box from '../Common/Box/Box';

import * as constants from '../../styles/style';

type Props = {
  /**
   * The total price of the analysis
   */
  netPrice: number;

  /**
   * The VAT in percent
   */
  vat: number;

  /**
   * The calculated VAT sum
   */
  vatSum: number;

  /**
   * the groß price
   */
  grossPrice: number;

  /**
   * Currency symbol
   */
  currency: string;
};

class CostBox extends React.PureComponent<Props> {
  render() {
    return <Box content={this.boxContent()} header={'Kosten'} />;
  }

  boxContent() {
    const { netPrice, vatSum, grossPrice, vat, currency } = this.props;

    return (
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
          }}>
          <span style={constants.styles.strong}>Analyse</span>
          <span style={constants.styles.strong}>
            {' '}
            {netPrice.toFixed(2)} {currency}
          </span>
        </div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
          }}>
          <span style={constants.styles.strong}>MwSt. ({vat} %)</span>
          <span style={constants.styles.strong}>
            {vatSum.toFixed(2)} {currency}
          </span>
        </div>
        <div
          style={{
            borderTopWidth: 1,
            marginTop: 5,
            marginBottom: 10,
            borderColor: '#CCC',
          }}></div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
          }}>
          <span style={constants.styles.strong}>Gesamtkosten</span>
          <span style={constants.styles.strong}>
            {grossPrice.toFixed(2)} {currency}
          </span>
        </div>
      </div>
    );
  }
}

export default CostBox;
