import * as React from 'react';
import Activation from '../../containers/Activation/Activation';
import Dialog from '../Dialog/Dialog';
import I18n from '../../language/i18n';

export const ActivationViewDialog = ({ show, onClose }) => {
  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={I18n.t('activation.confirmAccountTitle')}>
      <Activation />
    </Dialog>
  );
};
