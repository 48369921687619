import React, { useState, useEffect, useCallback } from "react";

import MapMarkers from "../Map/MapMarkers";
import { GoogleMap } from "../Map/Map";
import Polygon from "../Map/Polygon";
import DistanceMarker from "../Map/DistanceMarker";
import MapControl, {
  ControlButton,
  ControlContainer,
  TOP_LEFT,
} from "../Map/MapControl";
import Icon from "../Common/Icon";

import {
  getBounds,
  getPolygonOptions,
  preparePolygonPath,
} from "../../utils/Map";

type Props = {
  markers: any[];
  onChange?: (...args: any) => any;
  currentMarkedArea: {
    polygon: any[];
    center: any;
  };
  position: any;
  polygon: any[];
  pinPosition: any;
  polygonDrawing?: any;
  mapContainerStyle?: any;
  googleMapsOptions?: {
    disableDefaultUI: boolean;
  };
  enlargeMap?: boolean;
  onEnlargeMap?: (...args: any) => any;
};

const DEFAULT_MAP_TYPE = "satellite";
const navigate = false;
const hideMarkers = true;
const hideResetFilter = true;

const ViewOnlyMarkMapArea = (props: Props) => {
  const { markers: propMarkers, pinPosition, mapContainerStyle, googleMapsOptions } = props;
  const markers = filterPin(propMarkers, pinPosition);

  const [fitBounds, setFitBounds] = useState(false);
  const [ mapRef, setMapRef ] = useState(null);
  const [ updatingMapPosition, setUpdatingMapPosition ] = useState(false);
  const [ userChangedView, setUserChangedView ] = useState(false);

  const onUserChangedView = useCallback(() => {
    if (!updatingMapPosition) {
      setUserChangedView(true);
    }
  }, [updatingMapPosition]);

  useEffect(() => {
    return () => {
      if (mapRef) {
        (mapRef as any).getDiv().removeEventListener("wheel", onUserChangedView);
      }
    };
  }, [mapRef, onUserChangedView]);

  const onMapRef = ref => {
    if (!mapRef) {
      setMapRef(ref);

      if (!fitBounds) {
        let bounds;
        const markerPositions = propMarkers.map(
          marker => marker.position,
        );
        if (markerPositions.length) {
          bounds = getBounds(markerPositions);
        }
        if (bounds) {
          const map = ref;
          (window as any).google.maps.event.addListenerOnce(
            map,
            "zoom_changed",
            () => {
              if ((map?.getZoom() as number) > 20) {
                setUpdatingMapPosition(true);
                map?.setZoom(16);
                setUpdatingMapPosition(false);
              }
            },
          );
          ref.fitBounds(bounds);
        }
        setFitBounds(true);
      }
      ref
        .getDiv()
        .addEventListener("wheel", onUserChangedView, { passive: true });
    }
  };

  const onClick = () => {};
  const onMarkerPress = () => {};

  const polygonPath = preparePolygonPath(props.currentMarkedArea.polygon);
  const polygonOptions = getPolygonOptions({});

  return (
    <div>
      <GoogleMap
        mapRef={(ref) => onMapRef(ref)}
        mapContainerClassName="map-container"
        mapContainerStyle={{
          height: "200px",
          ...mapContainerStyle,
        }}
        options={{
          gestureHandling: "greedy",
          disableDefaultUI: true,
          ...googleMapsOptions,
        }}
        defaultCenter={(window as any).google.maps.LatLng({...props.position})}
        defaultZoom={16}
        onDrag={onUserChangedView}
        onClick={onClick}
        mapTypeId={DEFAULT_MAP_TYPE}
      >
        {mapRef ? (
          <MapMarkers
            map={mapRef}
            markers={markers}
            navigate={navigate}
            onMarkerPress={onMarkerPress}
            hideMarkers={hideMarkers}
            hideDistanceMarkers={true}
            hideResetFilter={hideResetFilter}
          />
        ) : null}
        {mapRef ? (
          <div>
            {renderDistanceMarkers(props.currentMarkedArea.polygon, mapRef)}
          </div>
        ) : null}
        {mapRef ? (
          <Polygon
            path={polygonPath}
            options={polygonOptions}
            map={mapRef}
            onClick={onClick}
          />
        ) : null}
        {mapRef && props.enlargeMap ? (
          <MapControl
            position={TOP_LEFT}
            key="controls1"
            map={mapRef}
          >
            <ControlContainer className="map-toolbar-container">
              <ControlButton
                className={"map-control-button"}
                onClick={() => {
                  if (props.onEnlargeMap) props.onEnlargeMap();
                }}
                disabled={false}
                title={"expand"}>
                <Icon iconType="ion" name="ios-expand-outline" />
              </ControlButton>
            </ControlContainer>
          </MapControl>
        ) : null}
      </GoogleMap>
    </div>
  );
};

export default ViewOnlyMarkMapArea;

const filterPin = (markers, pinPosition) => {
  if (pinPosition) {
    return markers.filter(m => {
      return m.id !== pinPosition.key;
    });
  }

  return markers;
};

const renderDistanceMarkers = (polygonMarkers, mapRef) => {
  return polygonMarkers.length >= 2
    ? polygonMarkers.map((m, i) => {
      if (polygonMarkers.length === 2 && i === 1) {
        return null;
      }

      const start = polygonMarkers[i];
      const end = polygonMarkers[(i + 1) % polygonMarkers.length];

      return (
        <DistanceMarker
          key={"distance-" + i}
          start={start}
          end={end}
          map={mapRef}
        />
      );
    })
    : [];
};
