import { get, isNumber, has, find, set, isEqual } from "lodash-es";
import type {
  GeoJsonCoord,
  GMBounds,
  GMLatLng,
  GMLatLngLiteral,
  GMPoint,
  LatLng,
  Marker,
  Region,
} from "../flowTypes";
import moize from "moize";

const EARTH_RADIUS = 6371000;
const INITIAL_AREA_SIZE = {
  squareMeter: 0,
  hectare: 0,
  acre: 0,
};

/**
 *
 * @param points [{latitude: number, longitude: number}]
 * @returns {{latitude: number, longitude: number, latitudeDelta: number, longitudeDelta: number}}
 */
export function getRegionForCoordinates(points: Array<LatLng>) {
  // points should be an array of { latitude: X, longitude: Y }
  let minX,
    maxX,
    minY,
    maxY

  // init first point
  ;(point => {
    minX = point.latitude;
    maxX = point.latitude;
    minY = point.longitude;
    maxY = point.longitude;
  })(points[0]);

  // calculate rect
  points.forEach(point => {
    minX = Math.min(minX, point.latitude);
    maxX = Math.max(maxX, point.latitude);
    minY = Math.min(minY, point.longitude);
    maxY = Math.max(maxY, point.longitude);
  });

  const midX = (minX + maxX) / 2;
  const midY = (minY + maxY) / 2;
  // $FlowFixMe
  const deltaX = maxX - minX;
  // $FlowFixMe
  const deltaY = maxY - minY;

  return {
    latitude: midX,
    longitude: midY,
    latitudeDelta: deltaX,
    longitudeDelta: deltaY,
  };
}

export function getBounds(positions: Array<LatLng>): GMBounds {
  const bounds = new (window as any).google.maps.LatLngBounds();
  positions.forEach(pos => bounds.extend(appPosToLatLng(pos)));
  return bounds;
}

export function getBoundsLatLng(positions: Array<GMLatLng>): GMBounds {
  const bounds = new (window as any).google.maps.LatLngBounds();
  positions.forEach(pos => bounds.extend(pos));
  return bounds;
}

export function appPosToLatLng({ latitude, longitude }: LatLng): GMLatLng {
  return new (window as any).google.maps.LatLng(latitude, longitude);
}

export function appPosToMapPos({
  latitude,
  longitude,
}: LatLng): GMLatLngLiteral {
  return {
    lat: latitude,
    lng: longitude,
  };
}

export function latLngToMapPos(latLng: GMLatLng): GMLatLngLiteral {
  return { lat: latLng.lat(), lng: latLng.lng() };
}

export function mapPosToAppPos({ lat, lng }: GMLatLngLiteral): LatLng {
  return { latitude: lat, longitude: lng };
}

export function latLngToAppPos(
  latLng: GMLatLng,
  decimals: number | null | undefined = 6,
): LatLng {
  if (decimals == null) {
    return { latitude: latLng.lat(), longitude: latLng.lng() };
  } else {
    return {
      latitude: Number(Number(latLng.lat()).toFixed(decimals)),
      longitude: Number(Number(latLng.lng()).toFixed(decimals)),
    };
  }
}

export function appPosToGeoJson({ latitude, longitude }: LatLng): GeoJsonCoord {
  return [longitude, latitude];
}

export function geoJsonToAppPos([longitude, latitude]: GeoJsonCoord): LatLng {
  return { latitude, longitude };
}

export function wrapMarkerInGeoJson(marker: Marker) {
  return {
    type: "Feature",
    geometry: { type: "Point", coordinates: appPosToGeoJson(marker.position) },
    properties: {
      marker,
    },
  };
}

function fixLat(lat) {
  return ((((lat + 90) % 180) + 180) % 180) - 90;
}

function fixLng(lng) {
  return ((((lng + 180) % 360) + 360) % 360) - 180;
}

export function regionToBounds({
  latitude,
  longitude,
  latitudeDelta,
  longitudeDelta,
}: Region): GMBounds {
  const bounds = new (window as any).google.maps.LatLngBounds();
  bounds.extend(
    new (window as any).google.maps.LatLng(
      fixLat(latitude - (latitudeDelta as number)),
      fixLng(longitude - (longitudeDelta as number)),
    ),
  );
  bounds.extend(
    new (window as any).google.maps.LatLng(
      fixLat(latitude + (latitudeDelta as number)),
      fixLng(longitude + (longitudeDelta as number)),
    ),
  );
  return bounds;
}

export function boundsToRegion(bounds: GMBounds): Region {
  const center = bounds.getCenter();
  const ne = bounds.getNorthEast();
  return {
    latitude: center.lat(),
    longitude: center.lng(),
    latitudeDelta: Math.abs(ne.lat() - center.lat()),
    longitudeDelta: Math.abs(ne.lng() - center.lng()),
  };
}

export function calcZoom(mapPx: number, worldPx: number, fraction: number) {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
}

export function regionToMapCenterAndZoom(
  map: google.maps.Map,
  region: Region,
) {
  const projection = map.getProjection();
  const bounds = regionToBounds(region);
  const ne = projection?.fromLatLngToPoint(bounds.getNorthEast() as any) as any;
  const sw = projection?.fromLatLngToPoint(bounds.getSouthWest() as any) as any;
  const latFraction = Math.abs(ne.y - sw.y) / 256;
  const lngFraction = Math.abs(ne.x - sw.x) / 256;

  const mapHeight = map?.getDiv().clientHeight as number; // offsetHeight
  const mapWidth = map?.getDiv().clientWidth as number; // offsetWidth

  const latZoom = calcZoom(mapHeight, 256, latFraction);
  const lngZoom = calcZoom(mapWidth, 256, lngFraction);

  const zoom = Math.min(latZoom, lngZoom, 21);
  const center = bounds.getCenter();
  return { zoom, center };
}

export const PositionErrorCode = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
};

const HINT_BALLOON_WIDTH = 388;
const HINT_BALLOON_MAX_HEIGHT = 240;
const HINT_BALLOON_SIDE_MARGIN = 10;

export function getHintBalloonVerticalPosClass(y: number /*, mapHeight */) {
  return y > HINT_BALLOON_MAX_HEIGHT ? "hint--top" : "hint--bottom";
}

export function getHintBalloonHorizontalPosStyle(
  x: number,
  markerOffset: number,
  mapWidth: number,
) {
  // limit width according to the available map size
  const balloonWidth = Math.min(
    HINT_BALLOON_WIDTH,
    mapWidth - 2 * HINT_BALLOON_SIDE_MARGIN,
  );

  // calculate position on the map
  const left = Math.min(
    mapWidth - HINT_BALLOON_SIDE_MARGIN - balloonWidth,
    Math.max(HINT_BALLOON_SIDE_MARGIN, x - balloonWidth / 2),
  );
  const right = Math.min(
    mapWidth - HINT_BALLOON_SIDE_MARGIN,
    left + balloonWidth,
  );
  const offsetFromCenter = Math.min(
    balloonWidth / 2,
    Math.max(-balloonWidth / 2, left - x + balloonWidth / 2),
  );

  return {
    width: `${right - left}px`,
    left: `calc(${markerOffset * 100}% + ${offsetFromCenter}px)`,
    marginLeft: "0px",
  };
}

export function boundsToPixels(map: Record<string, any>, bounds: GMBounds) {
  const SW = bounds.getSouthWest();
  const NE = bounds.getNorthEast();

  const proj = map.getProjection();
  const swPx = proj.fromLatLngToPoint(SW);
  const nePx = proj.fromLatLngToPoint(NE);
  const pixelWidth = Math.abs((nePx.x - swPx.x) * Math.pow(2, map.getZoom()));
  const pixelHeight = Math.abs((nePx.y - swPx.y) * Math.pow(2, map.getZoom()));

  return {
    width: pixelWidth,
    height: pixelHeight,
  };
}

export function pixelsToBounds(
  map: Record<string, any>,
  p1: GMPoint,
  p2: GMPoint,
) {
  const mapBounds = map.getBounds();
  const SW = mapBounds.getSouthWest();
  const NE = mapBounds.getNorthEast();

  const proj = map.getProjection();
  const swPx = proj.fromLatLngToPoint(SW);
  const nePx = proj.fromLatLngToPoint(NE);
  const scale = Math.pow(2, map.getZoom());

  const p1LatLng = proj.fromPointToLatLng(
    new (window as any).google.maps.Point(p1.x / scale + swPx.x, p1.y / scale + nePx.y),
  );
  const p2LatLng = proj.fromPointToLatLng(
    new (window as any).google.maps.Point(p2.x / scale + swPx.x, p2.y / scale + nePx.y),
  );

  const bounds = new (window as any).google.maps.LatLngBounds();
  bounds.extend(p1LatLng);
  bounds.extend(p2LatLng);

  return bounds;
}

export function compouteBoundsAreaInMeters(bounds: GMBounds) {
  return (window as any).google.maps.geometry.spherical.computeArea([
    bounds.getSouthWest(),
    new (window as any).google.maps.LatLng(
      bounds.getSouthWest().lat(),
      bounds.getNorthEast().lng(),
    ),
    bounds.getNorthEast(),
    new (window as any).google.maps.LatLng(
      bounds.getNorthEast().lat(),
      bounds.getSouthWest().lng(),
    ),
  ]);
}

export function isValidPosition(position: LatLng | null | undefined) {
  return (
    isNumber(get(position, "latitude")) && isNumber(get(position, "longitude"))
  );
}

/**
 * Calculates the distance in km between two points with longitude and latitude
 *
 * @param point1
 * @param point2
 * @returns {string}
 */
export function getDistanceFromLatitudeLongitudeInM(
  point1: LatLng,
  point2: LatLng,
) {
  const degLatitude = deg2rad(point2.latitude - point1.latitude);
  const degLongitude = deg2rad(point2.longitude - point1.longitude);

  const a =
    Math.sin(degLatitude / 2) * Math.sin(degLatitude / 2) +
    Math.cos(deg2rad(point1.latitude)) *
      Math.cos(deg2rad(point2.latitude)) *
      Math.sin(degLongitude / 2) *
      Math.sin(degLongitude / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = EARTH_RADIUS * c;
  return distance.toFixed(1);
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

/**
 * Calculates the area size of a polygon
 *
 * @param coordinates
 * @returns {{squareMeter: number, hectare: number, acre: number}}
 */
export function calculateAreaSize(coordinates: Array<LatLng>) {
  const size = coordinates.length;
  if (size < 3) {
    return INITIAL_AREA_SIZE;
  }

  let total = 0;
  const prev = coordinates[size - 1];
  let prevTanLat = Math.tan((Math.PI / 2 - deg2rad(prev.latitude)) / 2);

  let prevLng = deg2rad(prev.longitude);

  coordinates.forEach(point => {
    const tanLat = Math.tan((Math.PI / 2 - deg2rad(point.latitude)) / 2);
    const lng = deg2rad(point.longitude);
    total += polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
    prevTanLat = tanLat;
    prevLng = lng;
  });

  const squareMeter = Math.abs(total * (EARTH_RADIUS * EARTH_RADIUS));
  const hectare = squareMeter / 10000;
  const acre = squareMeter / 4046.86;

  return {
    squareMeter,
    hectare,
    acre,
  };
}

/**
 * Returns the area of an given triangle that uses the North Pole as a vertex
 *
 * @param tan1
 * @param lng1
 * @param tan2
 * @param lng2
 * @returns {number}
 */
function polarTriangleArea(tan1, lng1, tan2, lng2) {
  const deltaLng = lng1 - lng2;
  const t = tan1 * tan2;
  return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
}

const reorderEventDebouncePeriod = 16;

/**
 * This function makes map events map play nicer with React's events. Map event handlers are executed before React components' handlers making stopPropagation()
 * in a component non-functional. An example of this is a field info bubble which is on top of a Polygon. When clicked it would execute the event handler of
 * the polygon as well as the bubble. The function fixes this by delaying the map event and keeping track of stopPropagation() calls.
 * @param map
 * @param event
 * @param handler
 * @returns {Function}
 */

export function reorderMapEvent(
  map: google.maps.Map,
  event: string,
  handler: (...args: Array<any>) => any,
) {
  const listeningToEvent = has(map, ["eventReordering", event]);

  const getEventMap = (positionKey?) => {
    if (positionKey) {
      return get(map, ["eventReordering", event, positionKey]);
    } else {
      return get(map, ["eventReordering", event]);
    }
  };

  if (!listeningToEvent) {
    const div = map.getDiv();

    const setEventMap = (eventKeyOrValue: any, valueOrUndefined?: any) => {
      const value = valueOrUndefined || eventKeyOrValue;
      const eventKey = valueOrUndefined ? eventKeyOrValue : undefined;

      if (eventKey) {
        return set(map, ["eventReordering", event, eventKey], value);
      } else {
        return set(map, ["eventReordering", event], value);
      }
    };

    // listen to events, this gets executed before react's events
    div.addEventListener(event, e => {
      const timeStamp = e.timeStamp;
      const eventKey = makeEventKey(e);
      const events = getEventMap();

      if (!events) {
        setEventMap(getEventMap() || {});
      }

      setEventMap(eventKey, getEventMap(eventKey) || []);
      getEventMap(eventKey).push(timeStamp);

      const removeTimeStamp = () => {
        const eventMap = getEventMap(eventKey);
        if (eventMap) {
          setEventMap(eventKey, eventMap.filter(t => t !== timeStamp));
          if (eventMap.length === 0) {
            setEventMap(eventKey, undefined);
          }
        }
      };

      // cleanup after the delay period
      setTimeout(removeTimeStamp, reorderEventDebouncePeriod + 1);

      // overwrite stopPropagation
      const originalStopPropagation = e.stopPropagation;
      e.stopPropagation = function() {
        removeTimeStamp();
        return originalStopPropagation.call(this);
      };
    });

    (map as any).eventReordering = (map as any).eventReordering || {};
    setEventMap({});
  }

  return (e: Record<string, any>) => {
    const event = getMapEvent(e);

    if (!event) {
      let jsonEvent: any = null;
      try {
        // do it in a try catch block just in case we can't stringify it
        jsonEvent = JSON.stringify(event);
      } catch (e) {}
      console.error("could not get event object from map event", jsonEvent);
      handler(e);
      return;
    }

    const timeStamp = event.timeStamp;
    const positionKey = makeEventKey(event);
    setTimeout(() => {
      const timeStamps = getEventMap(positionKey);
      if (timeStamps) {
        // check if we had a propagating event shortly before this map event
        const sameEvent = find(
          timeStamps,
          t => timeStamp - t < reorderEventDebouncePeriod && timeStamp - t >= 0,
        );
        if (sameEvent) {
          handler(e);
        }
      }
    }, reorderEventDebouncePeriod);
  };
}

const mapEventKey: any = null;

function getMapEvent(event) {
  if (mapEventKey) {
    return event[mapEventKey];
  }

  for (const key in event) {
    if (event.hasOwnProperty(key) && get(event, [key, "timeStamp"])) {
      return event[key];
    }
  }

  return null;
}

function makeEventKey(event) {
  return event.clientX + ":" + event.clientY;
}


export const preparePolygonPath = moize(polygon => {
  return polygon.map(pos => appPosToLatLng(pos));
});

export const getPolygonOptions = moize((opts: { color?: string }) => {
  return {
    strokeColor: opts.color || "red",
    fillColor: opts.color || "rgba(0,0,255,0.5)",
    clickable: false,
    strokeWeight: 2,
  };
});

export const memoizedMarkers = moize(
  (markers, company) => {
    const companyMarker = {
      key: company.key,
      position: company.position,
      type: "company",
      title: company.name,
    };
    const transformedMarked = markers.map(m => {
      if (m.activeCrop.markedArea) {
        return {
          ...m,
          position: m.activeCrop.markedArea.center,
        };
      }
      return m;
    });
    return [...transformedMarked, companyMarker];
  },
  {
    maxSize: 1,
    matchesKey: ([newMarkers, newCompany], [oldMarkers, oldCompany]) => {
      return newMarkers === oldMarkers && isEqual(newCompany, oldCompany);
    },
  },
);
//
// XXX: Marker is usually field
export const getAreaSizeFromMarker = (marker) => {
  const markedArea = get(marker, "activeCrop.markedArea", false);
  if (markedArea) {
    return markedArea.areaSize.toFixed(2);
  }
  return marker.size;
};
