import React, { PureComponent } from 'react';
import MarkerWorker from './MarkerWorker';

import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { filters } from 'farmerjoe-common/lib/actions/actions';
import * as fieldActions from 'farmerjoe-common/lib/actions/field';
import * as selectors from 'farmerjoe-common/lib/selectors/selectors';
import firebase from '../../data/firebase';
import withRouter from '../Router/withRouter';
import { getBrowsingGroupKey } from 'farmerjoe-common/lib/selectors/groups';

// TODO: improve typings
type Props = {
  browsingGroup?: any;
  openCompany?: any;
  history?: string[];
  map: google.maps.Map;
};
type State = any;

class MarkerWorkers extends PureComponent<Props, State> {
  private mounted?: boolean;
  private locationsRef: any;

  constructor(props) {
    super(props);
    this.state = {
      markers: [],
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { openCompany, browsingGroup } = this.props;
    if (openCompany) {
      this.locationsRef = firebase
        .firestore()
        .collection('userLocations')
        .doc(openCompany)
        .collection('users')
        .where('group_id', '==', browsingGroup)
        .onSnapshot(snap => {
          const markers = snap.docs.map(doc => {
            const marker = doc.data();

            return {
              uid: marker.uid,
              name: marker.name,
              group_id: marker.group_id,
              icon: marker.icon,
              company_id: marker.company_id,
              coords: marker.coords,
            };
          });

          if (this.mounted) {
            this.setState({ markers: markers });
          }
        });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.locationsRef && this.locationsRef();
  }

  render() {
    const { openCompany, history, map } = this.props;
    const { markers } = this.state;

    return markers.map(marker => (
      <MarkerWorker
        key={marker.uid}
        marker={marker}
        icon={marker.icon}
        map={map}
        onClick={() =>
          history?.push(
            `/company/${openCompany}/employee/${marker.uid}/${marker.group_id}`,
          )
        }
      />
    ));
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
}

const selector = state => {
  const openCompany = selectors.getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  return {
    openCompany: openCompany,
    browsingGroup,
  };
};

export default compose<typeof MarkerWorkers>(
  connect(
    selector,
    mapDispatchToProps,
  ),
  withRouter,
)(MarkerWorkers);
