export const SUBSCRIPTION_FEATURES = {
  '2023_free': [
    'Map (field & live location)',
    'Field activity log',
    'Share a field',
  ],
  '2023_team': [
    'Map (field & live location)',
    'Field activity log',
    'Share a field',
    'Table View',
  ],
  '2023_enterprise': [
    'Map (field & live location)',
    'Field activity log',
    'Share a field',
    'Table View',
    'Crop Ratings',
    'Technical Support',
    'Customer Support',
  ],
};
