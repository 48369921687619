import React, { Component } from "react";
import PropTypes from "prop-types";

import MapMarkers from "./MapMarkers";

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

import { filters } from "farmerjoe-common/lib/actions/actions";
import * as fieldActions from "farmerjoe-common/lib/actions/field";
import * as selectors from "farmerjoe-common/lib/selectors/selectors";
import I18n from "../../language/i18n";
import { GoogleMap } from "./Map";
import CurrentLocationMarker from "./CurrentLocationMarker";
import { get, isEqual, throttle } from "lodash-es";
import MapControlOverlay from "./MapControlOverlay";
import PolygonAndMarkerPlacement from "./PolygonAndMarkerPlacement";
import "./style.css";
import {
  appPosToLatLng,
  appPosToMapPos,
  getBounds,
  isValidPosition,
  latLngToAppPos,
  latLngToMapPos,
  mapPosToAppPos,
} from "../../utils/Map";
import { Marker as MarkerType } from "../../flowTypes";

// TODO: improve typings
type Props = any;
type State = {
  mapRef?: google.maps.Map;
  showsUserLocation: boolean;
  zoom: number;
  center: google.maps.LatLngLiteral;
  marker: MarkerType;
  openMarker: any;
  filter: string;
  hideControls: boolean;
  hideMarkers: boolean;
  hideDistanceMarkers: boolean;
  areaSize?: any;
  polygon?: any;
};

class ModalInsertMap extends Component<Props, State> {
  userChangedView?: boolean;
  updatingMapPosition?: boolean;
  fitBounds?: boolean;

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    position: PropTypes.object,
    markers: PropTypes.array.isRequired,
    displayFilter: PropTypes.bool,
    displayMarkers: PropTypes.bool,
    polygonDrawing: PropTypes.bool,
  };

  static defaultProps = {
    fitBoundaries: false,
    displayFilter: true,
    fitBounds: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...ModalInsertMap.getCenterAndZoom(this.props, true),
      showsUserLocation: true,
      openMarker: 0,
      filter: "",
      hideControls: false,
      marker: props.pinPosition,
      hideMarkers: false,
      hideDistanceMarkers: true,
    };
  }

  componentWillUpdate(nextProps) {
    if (
      !isEqual(this.props.position, nextProps.position) ||
      (!isEqual(this.props.userPosition, nextProps.userPosition) &&
        !this.userChangedView)
    ) {
      const centerAndZoom = ModalInsertMap.getCenterAndZoom(nextProps, false);
      this.setState(centerAndZoom);

      const map = this.state.mapRef;

      if (
        map &&
        (map.getZoom() !== centerAndZoom.zoom ||
          !isEqual(
            latLngToMapPos(map.getCenter() as any),
            centerAndZoom.center,
          ))
      ) {
        this.updatingMapPosition = true;
        map.setZoom(centerAndZoom.zoom);
        map.panTo(
          appPosToLatLng(mapPosToAppPos(centerAndZoom.center)) as any,
        );
        this.updatingMapPosition = false;
      }
    }
  }

  componentWillUnmount() {
    if (this.state.mapRef && this.state.mapRef.getDiv()) {
      this.state.mapRef.getDiv().removeEventListener("wheel", this.onUserChangedView);
    }
  }

  static getCenterAndZoom(props, fitBounds) {
    let center = props.position;
    let zoom = props.zoom != null ? props.zoom : 12;
    if (!isValidPosition(center)) {
      center = get(props.pinPosition, "position");
      zoom = isValidPosition(center) ? 18 : 12;
    }
    if (fitBounds && props.fitBounds && props.markers.length) {
      // this will only set the center; fitBounds is called after map is rendered to set the proper zoom level as well
      if (props.fitBounds.getNorthEast) {
        const boundsCenter = props.fitBounds.getCenter();
        if (boundsCenter) {
          center = latLngToAppPos(boundsCenter);
        }
      } else {
        const boundsCenter = getBounds(
          props.markers.map(marker => marker.position),
        ).getCenter();
        if (boundsCenter) {
          center = latLngToAppPos(boundsCenter);
        }
      }
    }
    if (!isValidPosition(center)) {
      center = props.userPosition;
    }
    if (!isValidPosition(center)) {
      center = { latitude: 0, longitude: 0 };
      zoom = 1;
    }
    return { center: appPosToMapPos(center), zoom };
  }

  /**
   * Remove the map pin from the array with Markers
   * Otherwise the normal marker that we render renders on top of the map marker and
   * this leads to confusion
   *
   * @param markers
   * @returns {*}
   */
  filterPin(markers) {
    const { marker } = this.state;

    if (marker) {
      return markers.filter(m => {
        return m.id !== marker.key;
      });
    }

    return markers;
  }

  onUpdateView = () => {
    if (this.state.mapRef) {
      const center = this.state.mapRef.getCenter()?.toJSON();
      const zoom = this.state.mapRef.getZoom();
      if (this.state.zoom !== zoom || !isEqual(center, this.state.center)) {
        this.setState({
          center: center as any,
          zoom: zoom as number,
        });
      }
    }
  };

  onUserChangedView = () => {
    if (!this.updatingMapPosition) {
      this.userChangedView = true;
    }
  };

  throttledOnUpdateView = throttle(this.onUpdateView, 500);

  render() {
    let {
      markers,
      displayMarkers,
      filter: { mapType },
    } = this.props;

    // map type is not always set
    if (!mapType) {
      mapType = "satellite";
    }

    markers = this.filterPin(markers);

    return (
      <div>
        <div className="map-message-container">
          {this.state.marker ? null : (
            <div className="map-message">
              <span>{I18n.t("maps.pressToSetPin")}</span>
            </div>
          )}
        </div>
        <GoogleMap
          mapRef={(ref) => this.onMapRef(ref)}
          mapContainerClassName="map-container"
          mapContainerStyle={{
            height: "calc(100vh - 60px - 56px - 86px - 2px)",
          }}
          options={{ gestureHandling: "greedy" }}
          defaultCenter={this.state.center}
          defaultZoom={this.state.zoom}
          onDrag={this.onUserChangedView}
          /* disabled this callback as it is causing the dragging to be interrupted unexpectedly. */
          /* Moreover, this callback was not being fired here with the previous mapping library */
          // onBoundsChanged={this.throttledOnUpdateView}
          onClick={this.onClick}
          mapTypeId={mapType}
          onMapTypeIdChanged={this.onMapTypeIdChanged}
        >
          {(this.state.marker && this.state.marker.key) ||
          !this.state.mapRef ? null : (
              <MapMarkers
                map={this.state.mapRef}
                markers={markers}
                openMarker={this.state.openMarker}
                navigate={false}
                onMarkerPress={this.onMarkerPress}
                hideMarkers={this.state.hideMarkers}
                hideDistanceMarkers={this.state.hideDistanceMarkers}
                hideResetFilter={true}
              />
            )}
          <CurrentLocationMarker />
          {this.state.mapRef ? (
            <PolygonAndMarkerPlacement
              polygonDrawing={this.props.polygonDrawing}
              polygon={this.props.polygon}
              onChange={this.onPolygonChange}
              center={this.state.marker ? this.state.marker.position : null}
              showSearch={true}
              hideMarkers={this.state.hideMarkers}
              onHideMarkers={this.onHideMarkers}
              hideToolUi={true}
              hideDistanceMarkers={this.state.hideDistanceMarkers}
              onHideDistanceMarkers={this.onHideDistanceMarkers}
              map={this.state.mapRef}
            />
          ) : null}
          {this.state.mapRef && !this.state.hideControls ? (
            <MapControlOverlay
              displayFilter={false}
              hideSearch={true}
              hideMarkers={this.state.hideMarkers}
              onHideMarkers={this.onHideMarkers}
              displayMarkers={
                this.state.marker && this.state.marker.key
                  ? false
                  : displayMarkers
              }
              map={this.state.mapRef}
              markers={markers}
              displayGeoSearch={false}
            />
          ) : null}
        </GoogleMap>
      </div>
    );
  }

  onMarkerPress = marker => {
    this.setState({ openMarker: marker && marker.key });
  };

  onClick = () => {
    // Close an open marker
    if (this.state.openMarker) {
      this.setState({ openMarker: 0 });
    }
  };

  onMapRef = ref => {
    if (!this.state.mapRef) {
      this.setState({ mapRef: ref });

      if (this.props.fitBounds && !this.fitBounds) {
        let bounds;
        if (this.props.fitBounds.getNorthEast) {
          bounds = this.props.fitBounds;
        } else {
          const markerPositions = this.props.markers.map(
            marker => marker.position,
          );
          if (markerPositions.length) {
            bounds = getBounds(markerPositions);
          }
        }
        if (bounds) {
          const map = ref;
          (window as any).google.maps.event.addListenerOnce(
            map,
            "zoom_changed",
            () => {
              if ((map?.getZoom() as number) > 20) {
                this.updatingMapPosition = true;
                map?.setZoom(20);
                this.updatingMapPosition = false;
              }
            },
          );
          ref.fitBounds(bounds);
        }
        this.fitBounds = true;
      }
      ref
        .getDiv()
        .addEventListener("wheel", this.onUserChangedView, { passive: true });
    }
  };

  onPolygonChange = value => {
    const { center, areaSize, polygon } = value;
    this.setState({
      marker: {
        ...this.state.marker,
        position: center || null,
      },
      areaSize,
      polygon,
    });
    // The parent needs to know about the change
    this.props.onChange(value);
  };

  onHideMarkers = () => this.setState({ hideMarkers: !this.state.hideMarkers });

  onHideDistanceMarkers = () =>
    this.setState({ hideDistanceMarkers: !this.state.hideDistanceMarkers });

  onMapTypeIdChanged = type => {
    this.props.actions.filters(this.props.openCompany, {
      mapType: type,
    });
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...fieldActions,
          filters,
        },
      ),
      dispatch,
    ),
  };
}

const defaultFilter = { search: "", mapType: "satellite" };
const selector = (state, ownProps) => {
  const openCompany = selectors.getOpenCompanyId(state);

  return {
    openCompany: openCompany,
    filter: state.filtersByCompany[openCompany]
      ? state.filtersByCompany[openCompany]
      : defaultFilter,
    userPosition: state.userPosition,
  };
};

export default compose<typeof ModalInsertMap>(
  connect(
    selector,
    mapDispatchToProps,
  ),
)(ModalInsertMap);
