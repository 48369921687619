import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { bindActionCreators } from 'redux';
import * as employeeCreators from 'farmerjoe-common/lib/actions/employee';
import Icon from '../Common/Icon';
import Text from '../Common/Text';
import { get } from 'lodash-es';
import * as constants from '../../styles/style';
import Avatar from '../../containers/Avatar';
import Role from './Role';
import I18n from '../../language/i18n';
import { AlertConfirmDialog } from '../Dialog/Dialog';
// import { editEmployee, updateEmployee } from 'farmerjoe-common'
import type {
  Company,
  Field,
  Employee as EmployeeType,
} from 'farmerjoe-common/lib/flow/types';
import CreateEmployee from '../../containers/Employees/CreateEmployee';
import { UserRole } from 'farmerjoe-common/lib/flow/types';

const DIALOG_EDIT_RESULT = 'edit';
const DIALOG_CONFIRM_RESULT = 'yes';

type Props = {
  user: EmployeeType;
  isOnline: boolean;
  hasAccess: boolean;
  company: Company;
  field: Field;
  actions: {
    editEmployee: any; // typeof editEmployee
    updateEmployee: any; // typeof updateEmployee
  };
};

type State = {
  confirm: Record<string, any> | null;
  showCreateEmployeeDialog: boolean;
  editEmployeeGroupId: string | null;
};

class Employee extends Component<Props, State> {
  state = {
    confirm: null,
    showCreateEmployeeDialog: false,
    editEmployeeGroupId: null,
  };

  render() {
    const { user, isOnline, hasAccess, company, field } = this.props;
    let invited: any = null;

    if (user.active !== true && !user.accepted_on) {
      invited = true;
    }

    const standardUserWithAccessToAllFields =
      [UserRole.Standard, UserRole.Advisor].includes(user.role as UserRole) &&
      get(user, 'views.fields', false);
    const hasUnrestrictedAccess =
      user.role === UserRole.Admin || standardUserWithAccessToAllFields;

    const color = hasAccess ? constants.FJMUTEDLIGHT : '#000';
    return (
      <div
        key={user.key}
        style={{ margin: 10, paddingLeft: 10, paddingRight: 10 }}>
        <div
          style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
          onClick={() => {
            if (user.role === UserRole.Admin) {
              this.setState({
                confirm: {
                  title: I18n.t('employees.admin'),
                  children: I18n.t('acl.access.fieldAdminAlert.message'),
                  buttons: [
                    {
                      label: I18n.t('cancel'),
                      value: 'cancel',
                      className: 'btn-secondary',
                    },
                    {
                      label: I18n.t('edit'),
                      value: DIALOG_EDIT_RESULT,
                      className: 'btn-primary',
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_EDIT_RESULT) {
                      this.props.actions.editEmployee(company.key, user.key);
                      this.setState({
                        showCreateEmployeeDialog: true,
                        editEmployeeGroupId: user.group_id,
                      });
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
              return;
            }

            if (hasAccess) {
              this.setState({
                confirm: {
                  title: user.name,
                  children: I18n.t('acl.access.forbidFieldAlert.message', {
                    name: user.name,
                  }),
                  buttons: [
                    {
                      label: I18n.t('no'),
                      value: 'no',
                      className: 'btn-secondary',
                    },
                    {
                      label: I18n.t('yes'),
                      value: DIALOG_CONFIRM_RESULT,
                      className: 'btn-primary',
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_CONFIRM_RESULT) {
                      const fields = user.fields ? { ...user.fields } : {};

                      if (fields) {
                        delete fields[field.key];
                      }

                      this.props.actions.updateEmployee(company, {
                        ...user,
                        fields: fields,
                      });
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
            } else {
              this.setState({
                confirm: {
                  title: user.name,
                  children: I18n.t('acl.access.grantFieldAlert.message', {
                    name: user.name,
                  }),
                  buttons: [
                    {
                      label: I18n.t('no'),
                      value: 'no',
                      className: 'btn-secondary',
                    },
                    {
                      label: I18n.t('yes'),
                      value: DIALOG_CONFIRM_RESULT,
                      className: 'btn-primary',
                    },
                  ],
                  onClose: result => {
                    if (result === DIALOG_CONFIRM_RESULT) {
                      this.props.actions.updateEmployee(company, {
                        ...user,
                        fields: {
                          ...user.fields,
                          [field.key]: true,
                        },
                      });
                    }
                    this.setState({ confirm: null });
                  },
                },
              });
            }
          }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: 10 }}>
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: -2,
                      right: 0,
                      width: 12,
                      height: 12,
                      borderRadius: 6,
                      zIndex: 2,
                      borderColor: '#FFF',
                      borderWidth: 1,
                      backgroundColor: isOnline
                        ? constants.FJAPPLEGREEN
                        : constants.FJMUTEDLIGHTER,
                    }}
                  />
                  <Avatar
                    uid={user.key}
                    size={'small'}
                    style={{ backgroundColor: '#EEE' }}
                    round={true}
                  />
                </div>
              </div>
              <div
                style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                  }}>
                  <Text style={{ ...{ fontWeight: 'bold', color: color } }}>
                    {user.name}
                  </Text>
                  {invited
                    ? (
                    <Icon
                      name={'ios-mail-outline'}
                      style={{
                        fontSize: 25,
                        marginLeft: 5,
                        height: 28,
                        lineHeight: '22px',
                      }}
                    />
                      )
                    : null}
                </div>
                <Role user={user as any} />
                {hasAccess
                  ? (
                  <Text
                    style={{
                      ...constants.styles.small,
                      ...constants.styles.muted,
                      ...constants.styles.italic,
                    }}>
                    {user.invitation
                      ? I18n.t('acl.access.accessOnceInvitationAccepted')
                      : I18n.t('acl.access.alreadyHasAccess')}
                  </Text>
                    )
                  : null}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Icon
                  name={
                    hasAccess ? 'ios-checkmark-circle' : 'ios-radio-button-off'
                  }
                  style={{
                    fontSize: 30,
                    color: hasUnrestrictedAccess
                      ? constants.FJMUTEDLIGHT
                      : constants.FJNEWGREEN,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <AlertConfirmDialog
          {...this.state.confirm}
          show={!!this.state.confirm}
          key={4}
        />
        {this.state.showCreateEmployeeDialog
          ? (
          <CreateEmployee
            show={this.state.showCreateEmployeeDialog}
            groupId={this.state.editEmployeeGroupId}
            onClose={() =>
              this.setState({
                showCreateEmployeeDialog: false,
                editEmployeeGroupId: null,
              })
            }
          />
            )
          : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...employeeCreators,
        },
      ),
      dispatch,
    ),
  };
}

const wrappedEmployee = firebaseConnect(props => {
  if (!props.user) {
    return [];
  }

  const { user } = props;
  // on firestore we are allowed to use use @ in keys
  // our invitations are the user's email. If we try to do a query for firebase with this, then we get an exception...
  if (String(user.key).includes('@')) {
    return [];
  }
  return [
    {
      path: '/presence/' + props.user.key,
    },
  ];
})(Employee);

export default connect(
  (state: any, ownProps: any) => {
    const isOnline =
      get(
        state,
        `firebase.data.presence.${ownProps.user.key}.state`,
        'offline',
      ) === 'online';
    const auth = state.firebase.auth;
    return {
      user: ownProps.user,
      isOnline: isOnline,
      auth: auth,
    };
  },
  mapDispatchToProps,
)(wrappedEmployee);
