import * as React from 'react';
import I18n from '../../language/i18n';
import Icon from '../Common/Icon';
import { LatLng } from '../../flowTypes';
import { clearWatch, watchPosition } from '../../utils/geolocation';
import { Marker } from '@react-google-maps/api';
import GoogleMapWithLoader, { DEFAULT_MAP_ZOOM, DEFAULT_MAP_CENTER } from '../Map/GoogleMapWithLoader';

type MyMapComponentProps = {
  position?: LatLng;
  mapContainerClassName?: string;
  mapContainerStyle?: React.CSSProperties;
};

class MyMapComponent extends React.Component<MyMapComponentProps> {
  render() {
    const {
      position,
      mapContainerClassName,
      mapContainerStyle,
    } = this.props;
    let latLng: google.maps.LatLngLiteral = DEFAULT_MAP_CENTER;
    if (position) {
      latLng = {
        lat: position.latitude,
        lng: position.longitude,
      };
    }

    return (
      <GoogleMapWithLoader
        mapContainerClassName={ mapContainerClassName }
        mapContainerStyle={mapContainerStyle}
        zoom={DEFAULT_MAP_ZOOM}
        center={latLng}
        mapTypeId={'hybrid'}
        options={{ disableDefaultUI: true }}
      >
        <Marker position={latLng} />
      </GoogleMapWithLoader>
    );
  }
}

type Props = {
  position?: LatLng;
  onChange: (...args: Array<any>) => any;
};

type State = {
  position: null | LatLng;
  hasLocationPermission: boolean;
};

class Position extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      position: null,
      hasLocationPermission: false,
    };
  }

  watchPositionId: null | number = null;

  // map: ?Mapdiv
  mounted = false;

  componentDidMount(): void {
    this.mounted = true;
    this.centerOnUserPosition();
  }

  componentWillUnmount(): void {
    this.mounted = false;

    this.stopWatchingPosition();
  }

  centerOnUserPosition() {
    this.watchPositionId = watchPosition(
      position => {
        this.setState({ hasLocationPermission: true });
        this.updatePosition(position.coords);
      },
      e => {
        console.warn('watch position error', e);
      },
      { enableHighAccuracy: false, timeout: 20000, maximumAge: 1000 },
    );
  }

  stopWatchingPosition() {
    if (this.watchPositionId !== null) {
      clearWatch(this.watchPositionId);
    }
  }

  accuracy: number = 1000;

  updatePosition(location: {
    latitude: number;
    longitude: number;
    accuracy: number;
  }) {
    if (!this.mounted) {
      return;
    }
    const { latitude, longitude, accuracy } = location;
    const { onChange } = this.props;

    if (this.accuracy > accuracy) {
      this.accuracy = accuracy;

      const position = { latitude, longitude };

      this.setState({ position: position });
      onChange && onChange(position);
    }
  }

  render() {
    const { position, hasLocationPermission } = this.state;

    if (!hasLocationPermission) {
      return (
        <div>
          <div>{I18n.t('alerts.needLocation.description')}</div>
        </div>
      );
    }

    return (
      <div
        className={'d-flex justify-content-center align-items-center'}
        style={{ height: 200 }}>
        {position === null && (
          <div
            className={
              'd-flex flex-column justify-content-center align-items-center'
            }
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0, 0.8)',
            }}>
            <Icon
              name={'map-marker-alt'}
              iconType={'fal'}
              light
              style={{ fontSize: 45, color: '#FFF' }}
            />
            <div style={{ color: '#FFF', marginTop: 10 }}>
              Standort wird ermittelt.
            </div>
          </div>
        )}

        <MyMapComponent
          position={position as any}
          mapContainerStyle={{ height: '200px', width: '100%' }}
        />
      </div>
    );
  }
}

export default Position;
