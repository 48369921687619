import React, { useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { capitalize } from "lodash-es";

import { getOpenCompanyId } from "farmerjoe-common/lib/selectors/selectors";
import { getCompanyGroupProfileForLoggedInUser } from "farmerjoe-common/lib/selectors/user";
import { getGroupsQuery } from "farmerjoe-common/lib/utils/firestoreRedux/Groups";
import { getBrowsingGroupKey, getCompanyGroups } from "farmerjoe-common/lib/selectors/groups";

import NoResults from "../Common/NoResults";
import FlatList from "../Common/FlatList";

import Icon from "./Icon";
import * as constants from "../../styles/style";

import I18n from "../../language/i18n";
import Dialog from "../Dialog/Dialog";
import SearchInput from "./SearchInput";

import "./style.css";

type TUsersSelection = Record<string, boolean>;

type Props = {
  openCompany: string;
  selected: TUsersSelection;
  onSave: (users: TUsersSelection) => {};
  onClose: () => {};
  show?: any;
  companyGroups: any;
  groupType: "producer" | "trader";
};

type State = {
  selected: TUsersSelection;
};

/**
 * A component that displays a screen on which the user is able to
 * select different fields
 */
const GroupsSelection = (props: Props) => {
  const { show, groupType } = props;
  const [selected, setSelected] = useState(props.selected);
  const [search, setSearch] = useState("");

  const close = () => {
    const { onClose } = props;
    onClose && onClose();
  };

  const onSave = () => {
    props.onSave(selected);
    close();
  };

  const searchInput = () => {
    return (
      <SearchInput
        key={"searchField"}
        onChange={(value) => setSearch(value)}
        search={search}
      />
    );
  };

  const _companyGroups = props.companyGroups.filter((group) => {
    return group.name.toLowerCase().includes(search.toLowerCase());
  });

  const renderGroup = ({ item }) => {
    const group = item;
    return (
      <div key={"group" + group.key}>
        <div
          onClick={() => {
            setSelected({
              ...selected,
              [group.key]: !selected[group.key],
            });
          }}
          className={
            "d-flex justify-content-between cursor-pointer list-item"
          }>
          <div className={"d-flex"} style={{ width: "100%" }}>
            <div style={{ flex: 1 }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <span style={{ fontWeight: "bold" }}>{group.name}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ width: 40 }}
            className={"d-flex justify-content-center"}>
            {selected[group.key]
              ? (
                <Icon
                  name={"ios-checkmark-circle"}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )
              : (
                <Icon
                  name={"ios-radio-button-off"}
                  style={{ fontSize: 25, color: constants.FJNEWGREEN }}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      show={show}
      title={I18n.t(`acl.${groupType}s.select${capitalize(groupType)}s`)}
      onClose={close}
      dialogClassName={"employee-select-fields"}
      footer={
        <div className="buttons">
          <button
            className="ml-auto btn btn-secondary"
            onClick={close}>
            {I18n.t("cancel")}
          </button>{" "}
          <button
            className="btn btn-primary"
            onClick={onSave}>
            {I18n.t("done")}
          </button>
        </div>
      }>
      <div className={"d-flex flex-column"}>
        <div style={{ height: 50 }}>{searchInput()}</div>
        <FlatList
          className="employees-list"
          tabLabel={I18n.t("employees.plural")}
          ListEmptyComponent={
            <NoResults text={I18n.t("noSearchResults")} />
          }
          data={_companyGroups}
          renderItem={renderGroup}
        />
      </div>
    </Dialog>
  );
};

const wrappedComponent = firestoreConnect((props) => {
  const { openCompany, myCompanyProfile, groupType } = props;
  return [getGroupsQuery(
    openCompany,
    myCompanyProfile,
    groupType,
  )];
})(GroupsSelection);

const selector = (state, ownProps) => {
  const openCompany = getOpenCompanyId(state);
  const browsingGroup = getBrowsingGroupKey(state, openCompany);
  const myCompanyProfile = getCompanyGroupProfileForLoggedInUser(
    state,
    openCompany,
  );
  const companyGroups = getCompanyGroups(state, openCompany, ownProps.groupType, browsingGroup);

  return {
    openCompany,
    selected: ownProps.selected || {},
    browsingGroup,
    myCompanyProfile,
    companyGroups,
  };
};

export default connect(
  selector,
)(wrappedComponent);

