import { get } from 'lodash-es';

export const getDateRange = (state, openCompany: string, analysisTypeToDisplay: string): string => {
    return get(
        state,
        [
            'filtersByCompany',
            openCompany,
            'displaySettings',
            analysisTypeToDisplay,
            'date',
        ],
        'last:7',
    );
};

export const getSorting = (state, openCompany: string, analysisTypeToDisplay: string, defaultValue: string = 'sort' ): string => {
  return get(
    state,
    [
      'filtersByCompany',
      openCompany,
      'displaySettings',
      analysisTypeToDisplay,
      'sort',
    ],
    defaultValue,
  );
};
