import Fuse from 'fuse.js';

const fuseSearch = (
    list: any[],
    searchInput: string,
    searchOptions: any,
): any[] => {
    const fuse = new Fuse(list, searchOptions);
    return fuse.search(searchInput).map(res => res.item);
};

export default fuseSearch;
