import React from 'react';
import * as constants from '../../../styles/style';

const Text = (props) => {
  const { text } = props;

  return (
    <span
      style={{
        ...constants.styles.stdSize,
        color: '#000000',
        whiteSpace: 'pre-line',
      }}>
      {text}
    </span>
  );
};

export default Text;
