import React, { useState } from "react";


/**
 * HOC to get the latest activity count for company where the user belongs
 * since the last time the user was active.
 *
 * @param WrappedComponent
 */

export function withLatestActivityCount(WrappedComponent) {
  return function WithLatestActivityCountWrapper(props) {
    const { companyId, actions, latestComments } = props;
    const [prevLatestComments, setPrevLatestComments] = useState(latestComments);

    if (prevLatestComments !== latestComments) {
      setPrevLatestComments(latestComments);
      actions.setLatestActivityCountForCompany(companyId, latestComments);
    }

    return <WrappedComponent {...props} />;
  };
}
