import {  UserRole } from 'farmerjoe-common/lib/flow/types';

const user = {
  key: '',
  name: '',
  email: '',
  role: UserRole.Standard,
  active: false,
  invitation: true,
  invited_on: new Date(),
  invited_by: {},
  views: {
    crops: true,
    fields: true,
    comments: true,
    users: true,
  },
  acl: {
    crop: {
      create_edit: true,
      delete: false,
      'edit.harvest': true,
    },
    waittime: {
      create: true,
      'edit.own': true,
      'delete.own': true,
    },
    fertilizing: {
      create: true,
      'edit.own': true,
      'delete.own': true,
    },
  },
};

export default user;
