import React, { useState, useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { SIGNUP_STATUS_ACTIVE } from "farmerjoe-common/lib/constants/signup";

import { LOGIN_SCREEN_CREDENTIALS_PROVIDER } from "./constants";
import Logo from "./Logo";

import TextInput from "../Common/TextInput";

import * as profileActions from "../../actions/profile";

import I18n from "../../language/i18n";
import { captureException } from "../../utils/sentry";


const captchaFactory = (buttonId, callback) => {
  return new firebase.auth.RecaptchaVerifier(buttonId, {
    size: "invisible",
    callback: callback,
  });
};

const maskPhone = (phone) => {
  const firstThree = phone.substr(0,3);
  const middle = phone.substr(3, phone.length - 3);
  const lastTwo = phone.substr(phone.length - 2);
  const middleMasked = middle.replace(/\d+/, "*".repeat(middle.length));
  return `${firstThree}${middleMasked}${lastTwo}`;
};

const LoginWithPhone = (props) => {
  const { phone, onError, actions, setScreen } = props;
  const [smsCode, setSmsCode] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [allowSignup, setAllowSignup] = useState(false);

  useEffect(() => {
    const captchaObj = captchaFactory("recaptcha", (response) => {
      setAllowSignup(true);
    });
    // @ts-ignore: Unreachable code error
    setCaptcha(captchaObj);
  }, []);

  useEffect(() => {
    if (captcha && phone) {
      firebase
        .auth()
        .signInWithPhoneNumber(phone, captcha)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          // @ts-ignore: Unreachable code error
          setConfirmation(confirmationResult);
        })
        .catch(error => {
          // Error; SMS not sent
          captureException(error);
          console.log(error);
          onError({ alertMessage: error.message, // TODO: I18n
            alertTitle: I18n.t("error"),
          });
          console.log(error);
        });
    }
  }, [captcha, phone, onError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmation && allowSignup) {
      // @ts-ignore: Unreachable code error
      confirmation.confirm(smsCode).then(result => {
        const user = result.user;
        if (!user.email) {
          actions.createSignup({
            uid: user.uid,
            phoneNumber: user.phoneNumber,
          }, SIGNUP_STATUS_ACTIVE);
        }
      }).catch((error) => {
        captureException(error);
        console.log(error);
        onError({ alertMessage: error.message, // TODO: I18n
          alertTitle: I18n.t("error"),
        });
      });
    }
  };

  return (
    <div
      style={{
        ...{ flex: 1 },
      }}>
      <div style={{ position: "relative" }}>
        <Logo />
        <h1 className="text-center mb-4">{I18n.t("login")}</h1>
        <form
          onSubmit={handleSubmit}
          id={"login"}
          className="form-horizontal">
          <p style={{textAlign: "center"}}>
            {maskPhone(phone)}:
          </p>
          <TextInput
            placeholder={I18n.t("verificationCode")}
            style={{ paddingLeft: 20 }}
            containerClassName={"mt-4 mb-4"}
            autoFocus={true}
            id="sms-code"
            name="sms-code"
            type="text"
            autoCorrect="false"
            autoCapitalize="none"
            onChange={e => setSmsCode(e.target.value)}
          />

          <div
            style={{
              display: "flex",
            }}>
            <button
              id='sign-in-button'
              className="btn btn-primary btn-block">
              {I18n.t("login")}
            </button>
          </div>
          <div id="recaptcha"></div>
        </form>

        <div
          style={{
            display: "flex",
            margin: "1em 0",
          }}>
          <button
            className="btn btn-default btn-block"
            onClick={() => setScreen(LOGIN_SCREEN_CREDENTIALS_PROVIDER)}
          >
            {I18n.t("back")}
          </button>
        </div>

        <div
          style={{
            margin: "1rem 0",
            display: "flex",
          }}>
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        {
          ...profileActions,
        },
      ),
      dispatch,
    ),
  };
}

const selector = (state) => {
  const { credentialsProvider: { meta: { phone }}} = state;
  return {
    phone,
  };
};

export default compose(
  connect(
    selector,
    mapDispatchToProps,
  ),
)(LoginWithPhone);
