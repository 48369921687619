import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './fonts/FarmerJoe.css';
import 'ionicons/dist/css/ionicons.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './themeCSS/bootstrap-grid-xxl.css';
import 'react-tabs/style/react-tabs.css';
import * as Waves from 'node-waves/dist/waves';
import 'react-bootstrap-modal/lib/css/rbm-complete.css';
import 'rc-calendar/assets/index.css';
import 'html-hint/dist/html-hint.css';
import 'react-image-lightbox/style.css';
import 'rc-pagination/assets/index.css';
import './themeCSS/style.css';
import './themeCSS/farmerjoe.css';
import './index.css';
import 'string.prototype.includes';
import './data/firebase'; // import here because some modules rely on firebase app being initialized
import App from './App';
import { unregister as serviceWorkerUnregister } from './utils/service-worker/serviceWorker';
import { Provider } from 'react-redux';
import { Loading } from './components/Loading/Loading';
import { Router } from 'react-router-dom';
import { createBrowserHistory, createHashHistory } from 'history';
import { isObject } from 'lodash-es';
import { isHomeScreen, isIframe, isPrintPage } from './utils/page';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Analytics from 'react-router-ga';
import I18n from './language/i18n';
import { store, persistor } from './stores/store';
import { getScrollableAncestor } from './utils/dom';
import firebase from './data/firebase';
import { setOptions } from 'farmerjoe-common';
import { PersistGate } from 'redux-persist/integration/react';
import * as countriesUtil from './utils/Countries';
import { geocodeAddress } from './utils/geocoding';
import { initializeSentry, updateSentryUser } from './utils/sentry';
import {
  REQUEST_REGISTRATION_URL,
  REQUEST_UPDATE_PROFILE_URL,
  REQUEST_PASSWORD_RESET,
  REQUEST_ACCOUNT_REMOVAL,
  BASE_API_URL,
} from './constants';

setOptions({
  store,
  firebase,
  language: I18n,
  type: 'web',
  geocodeAddress,
  countriesUtil,
  urls: {
    api: BASE_API_URL,
    profile: REQUEST_UPDATE_PROFILE_URL,
    registration: REQUEST_REGISTRATION_URL,
    passwordReset: REQUEST_PASSWORD_RESET,
    requestAccountRemoval: REQUEST_ACCOUNT_REMOVAL,
  },
  persistor,
});

const isIOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

function init() {
  initializeSentry();
  const history = createHistory();

  serviceWorkerUnregister();

  const gaId = process.env.REACT_APP_GA_ID;
  const gaDebug = String(process.env.REACT_APP_GA_DEBUG) !== 'false';

  const isIE = /* @cc_on!@ */ false || !!(document as any).documentMode;

  interface Props {}

  interface State {
    userReady: boolean;
  }

  class FarmerJoe extends Component<Props, State> {
    constructor(props: Props, context?) {
      super(props, context);

      this.state = {
        userReady: false,
      };
    }

    componentWillMount() {
      store.firebase.auth().onAuthStateChanged((user) => {
        this.setState({ userReady: true });
        updateSentryUser(user);
      });
    }

    // go through analytics if it is set in the settings
    ga(content) {
      if (gaId) {
        return (
          <Analytics id={gaId} debug={gaDebug}>
            {content}
          </Analytics>
        );
      }

      return content;
    }

    render() {
      const isPrint = isPrintPage();

      if (isIE) {
        return (
          <div className="alert alert-danger">
            {I18n.t('browserNotSupported')}
          </div>
        );
      }

      if (isPrint && !this.state.userReady) {
        return null;
      }

      let content: any = null;

      if (!isPrint && !this.state.userReady) {
        content = this.ga(
          <div style={{ height: '100vh', display: 'flex' }}>
            <Loading isLoading={true} />
          </div>,
        );
      } else {
        content = this.ga(<App />);
      }

      return (
        <ErrorBoundary>
          <Provider store={store} /* persistor={persistor} */>
            <PersistGate loading={<Loading />} persistor={persistor}>
              <Router history={history}>{content}</Router>
            </PersistGate>
          </Provider>
        </ErrorBoundary>
      );
    }
  }

  function createHistory() {
    return isHomeScreen() ? createHashHistory() : createBrowserHistory();
  }

  // enable hot module reloading
  if (process.env.NODE_ENV !== 'production' && (module as any).hot) {
    ((module as any).hot as any).accept();
  }

  if (process.env.NODE_ENV === 'development') {
    document.title = 'DEV - FarmerJoe';
  }

  Waves.displayEffect();

  if (isIOS) {
    const root = document.querySelectorAll('#root')[0];
    if (root) {
      const PI = Math.PI;
      const quarterPI = PI / 4;
      const twoPI = 2 * PI;
      const directions = [
        'right',
        'up-right',
        'up',
        'up-left',
        'left',
        'down-left',
        'down',
        'down-right',
      ];
      let startY: any = null;
      let startX: any = null;
      root.addEventListener('touchstart', (e: any) => {
        startY = e.touches[0].clientY;
        startX = e.touches[0].clientX;
      });
      root.addEventListener('touchend', (e) => {
        startY = null;
        startX = null;
      });
      root.addEventListener('touchmove', (e: any) => {
        if (e.touches.length === 1) {
          const scrollable: any = getScrollableAncestor(e.target);
          if (scrollable) {
            const deltaY = startY - e.touches[0].clientY;
            const deltaX = e.touches[0].clientX - startX;
            const angle = (Math.atan2(deltaY, deltaX) + twoPI) % twoPI;
            const swipeDirection =
              directions[
                Math.floor(
                  ((angle + quarterPI / 2 + twoPI) % twoPI) / quarterPI,
                )
              ];

            const canScrollDown =
              scrollable.scrollTop + scrollable.getBoundingClientRect().height <
              scrollable.scrollHeight;
            const canScrollUp = scrollable.scrollTop > 0;
            const canScrollLeft = scrollable.scrollLeft > 0;
            const canScrollRight =
              scrollable.scrollLeft + scrollable.getBoundingClientRect().width <
              scrollable.scrollWidth;
            if (
              (swipeDirection === 'down' && !canScrollUp) ||
              (swipeDirection === 'up' && !canScrollDown) ||
              (swipeDirection === 'right' && !canScrollLeft) ||
              (swipeDirection === 'left' && !canScrollRight) ||
              (swipeDirection === 'up-right' &&
                !canScrollLeft &&
                !canScrollDown) ||
              (swipeDirection === 'up-left' &&
                !canScrollRight &&
                !canScrollDown) ||
              (swipeDirection === 'down-right' &&
                !canScrollLeft &&
                !canScrollUp) ||
              (swipeDirection === 'down-left' &&
                !canScrollRight &&
                !canScrollUp)
            ) {
              e.preventDefault();
            }
          }
        }
      });
    }

    document.body.className += ' is-ios';
  }

  ReactDOM.render(<FarmerJoe />, document.getElementById('root'));
}

if (isIframe() && isPrintPage()) {
  // wait for initial store state from parent iframe
  window.addEventListener('message', (message) => {
    const data = message.data;
    if (isObject(data) && data.event === 'initialStore') {
      store.dispatch({
        type: 'REPLACE_WHOLE_STATE',
        payload: data.data,
      });
      init();
    }
  });
  window.postMessage('ready', '*');
} else {
  init();
}
