import * as React from "react";
import { getQueryVariable } from "../../utils/utils";
import withRouter from "../Router/withRouter";
import { compose } from "redux";

class AuthAction extends React.Component<{}> {
  constructor(props) {
    super(props);
    const mode = getQueryVariable("mode");
    const actionCode = getQueryVariable("oobCode");
    // const apiKey  = getQueryVariable('apiKey')
    // const lang    = getQueryVariable('lang')
    // const continueUrl    = getQueryVariable('continueUrl')

    switch (mode) {
    case "recoverEmail":
      props.history.replace(`/recoveremail/${actionCode}`);
      break;
    case "resetPassword":
      props.history.replace(`/resetpassword/${actionCode}`);
      break;
    case "verifyEmail":
      props.history.replace(`/verifyemail/${actionCode}`);
      break;
    default:
      throw new Error("unknown action mode");
    }
  }

  render() {
    return null;
  }
}

export default compose(withRouter)(AuthAction);
