import React from 'react';
import * as constants from '../../styles/style';
import I18n from '../../language/i18n';

const BtnAccept = (props) => {
    const { onClick } = props;
    return (
        <div
            className='handle-request-btn-container'
            style={{
                backgroundColor: constants.FJAPPLEGREEN,
            }}
            onClick={onClick}
        >
            <span className='handle-request-btn-text'>
                {I18n.t('fieldSharing.footerBtnAccept')}
            </span>
        </div>
    );
};

export default BtnAccept;
