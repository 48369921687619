import React from "react";
import * as constants from "../../styles/style";

type Props = {
  title: string | React.ReactNode;
  subtitle?: string;
  rightText?: string | React.ReactNode;
  rightButton?: React.ReactNode;
  leftButton?: React.ReactNode;
  onRight?: (...args: Array<any>) => any;
  leftText?: any;
  rightTextStyle?: React.CSSProperties;
};

export default class NavbarBasic extends React.PureComponent<Props> {
  static defaultProps = {
    rightText: "",
    rightButton: null,
  };

  render() {
    const { title, subtitle, rightText, rightButton, onRight, leftButton } =
      this.props;

    return (
      <div
        className="page-card-top-bar-container"
        style={{...constants.styles.navBar}}
      >
        {leftButton}
        <div
          className={["navbar-basic-title", rightButton ? "navbar-basic-title--left-aligned" : ""].join(" ")}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: constants.FJMUTEDDARK,
            }}
          >
            {title}
          </span>
          {subtitle ? (
            <span style={{ fontSize: 12, color: constants.FJMUTEDDARK }}>
              {subtitle}
            </span>
          ) : null}
        </div>
        {rightButton}
        {!!onRight && (
          <div className="top-bar-button" onClick={onRight}>
            {rightText}
          </div>
        )}
      </div>
    );
  }
}
